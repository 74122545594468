import { Component, OnInit } from '@angular/core';
import {
  MinimalProfessionGroupData, MinimalProfessionSubGroupData, MinimalProfessionTypeData, ProfessionData,
  ProfessionGroupData,
  ProfessionMainGroupData,
  ProfessionSubGroupData
} from "../../../generated/data";
import {MatTableDataSource} from "@angular/material/table";
import {UtilityService} from "../../../utils/utility.service";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {ProfessionSubGroupDetailsSheetComponent} from "../profession-sub-group-details-sheet/profession-sub-group-details-sheet.component";
import {ProfessionGroupDetailsSheetComponent} from "../profession-group-details-sheet/profession-group-details-sheet.component";
import {ProfessionAreaDetailsSheetComponent} from "../profession-area-details-sheet/profession-area-details-sheet.component";
import {ProfessionTypeDetailsSheetComponent} from "../profession-type-details-sheet/profession-type-details-sheet.component";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";

@Component({
  selector: 'app-profession-main-group-details-sheet',
  templateUrl: './profession-main-group-details-sheet.component.html',
  styleUrls: ['./profession-main-group-details-sheet.component.scss']
})
export class ProfessionMainGroupDetailsSheetComponent implements OnInit {

  public professionMainGroupId: number
  public professionMainGroupTitle: string
  public professionMainGroupData: ProfessionMainGroupData
  public professionGroupDataSource = new MatTableDataSource()
  public professionSubGroupDataSource = new MatTableDataSource()
  public professionTypeDataSource = new MatTableDataSource()
  public professionDataSource = new MatTableDataSource()
  public loading: boolean = true

  public displayedProfessionGroupColumns = ['agencyKey', 'professionGroup']
  public displayedProfessionSubGroupColumns = ['agencyKey', 'professionSubGroup']
  public displayedProfessionTypeColumns = ['agencyKey', 'professionType']
  public displayedProfessionColumns = ['agencyKey', 'profession','numSubjectGroups', 'numSubjects']

  constructor(
      public utilityService: UtilityService,
      private professionResource: AdminProfessionResource,
      private adminSubjectResource: AdminSubjectResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.professionDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.professionTypeDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.professionSubGroupDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.professionGroupDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadProfession()
  }

  loadProfession(): void {
    this.loading = true
    this.professionResource.getProfessionMainGroup(this.professionMainGroupId).then(mgroup => {
      this.professionMainGroupData = mgroup
      this.professionGroupDataSource.data = mgroup.professionGroups
      this.professionSubGroupDataSource.data = mgroup.professionSubGroups
      this.professionTypeDataSource.data = mgroup.professionTypes
      this.professionDataSource.data = mgroup.professions
      this.loading = false;
    })
  }

  openProfessionAreaDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionAreaDetailsSheetComponent)
    cref.instance.professionAreaId = this.professionMainGroupData.areaId
    cref.instance.professionAreaTitle = this.professionMainGroupData.areaTitle;
  }

  openProfessionGroupDetailsSheet(professionGroup: MinimalProfessionGroupData) {
    let cref = this.dialogService.openOverlay(ProfessionGroupDetailsSheetComponent)
    cref.instance.professionGroupId = professionGroup.id;
    cref.instance.professionGroupTitle = professionGroup.title;
  }

  openProfessionSubGroupDetailsSheet(professionSubGroup: MinimalProfessionSubGroupData) {
    let cref = this.dialogService.openOverlay(ProfessionSubGroupDetailsSheetComponent)
    cref.instance.professionSubGroupId = professionSubGroup.id;
    cref.instance.professionSubGroupTitle = professionSubGroup.title;
  }

  openProfessionTypeDetailsSheet(professionType: MinimalProfessionTypeData) {
    let cref = this.dialogService.openOverlay(ProfessionTypeDetailsSheetComponent)
    cref.instance.professionTypeId = professionType.id;
    cref.instance.professionTypeTitle = professionType.title;
  }

  openProfessionDetailsSheet(profession: ProfessionData) {
    let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
    cref.instance.professionId = profession.id;
    cref.instance.professionTitle = profession.title;
  }

  applyFilter(dataSource: MatTableDataSource<any>, filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    dataSource.filter = filterValue;
  }
}
