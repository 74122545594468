import {Component, OnInit} from '@angular/core';
import {TermsAndConditionsFileData} from "../generated/data";
import {AdminTermsAndConditionsResource} from "../generated/resources";
import {FileService} from "../services/file.service";
import {AbstractControl, FormControl, ValidatorFn} from "@angular/forms";

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {


    fileEvent;
    file = new FormControl(null, this.formControlValidator());
    name;
    saving: boolean = false;

    datasource: TermsAndConditionsFileData[] = []
    loading: boolean = true;
    displayedColumns = [
        'name',
        'date',
        'user',
        'actions'
    ];

    constructor(
        private adminTermsAndConditionsResource: AdminTermsAndConditionsResource,
        private fileService: FileService
    ) {
    }

    ngOnInit(): void {
        this.getUploadedTermsAndConditions()
    }

    set(fileEvent: Event) {
        this.fileEvent = fileEvent;
    }

    formControlValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return {isNull: true};
            }
            if (control.value[0].size >= 50000000) {
                return {sizeTooBig: true};
            }

            return null;
        }
    }

    getUploadedTermsAndConditions() {
        this.loading = true
        this.adminTermsAndConditionsResource.getAllUploadedTermsAndConditions().then(result => {
                this.datasource = result
                this.loading = false
            }
        )
    }

    uploadTermsAndConditions() {
        this.saving = true;
        let file = this.file.value[0];
        this.name = file.name;
        this.fileService.uploadTermsAndConditions(file).subscribe(
            (response) => {
                this.saving = false;
                this.getUploadedTermsAndConditions()
                this.file.setValue(null)
            }
        )
    }

    getFileName(): string {
        if (this.file.value) return this.file?.value[0]?.name?.toString()?.substring(0, 20) + '...';
        else return 'Datei auswählen';
    }

    download(fileId: number) {
        this.fileService.downloadTermsAndConditions(fileId)
    }
}

