<div fxLayout="column" fxLayoutAlign="start center" id="filter-group-wrapper">
    <div fxLayout="column" *ngFor="let filter of filters; index as i" class="filter-wrapper width-100">
        <talent-filter
                (copyFilter)="copyFilter(filter)"
                (removeFilter)="removeFilter(filter)"
                [index]="i+1"
                [filterLength]="filters.length"
                [filter]="filter"
                [isSpecialTargeting]="isSpecialTargeting"
                (filterChange)="onFilterChange(filter, $event)">
        </talent-filter>

        <div *ngIf="i+1 < filters.length" fxLayout="column" fxLayoutAlign="center center" class="or-column">
            <span>- ODER -</span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-column m-bottom50" (click)="addFilter()">
        <i class="mdi mdi-plus"></i>
    </div>
</div>
