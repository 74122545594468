<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <mat-tab-group>
        <mat-tab label="Bewerbungsverarbeitung">
            <app-application-processing-controlling></app-application-processing-controlling>
        </mat-tab>
        <mat-tab label="Stellencontent">
            <position-content-controlling></position-content-controlling>
        </mat-tab>
    </mat-tab-group>
</div>
