import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {SelectionModel} from "@angular/cdk/collections";
import {FormControl} from "@angular/forms";
import {debounce, debounceTime} from "rxjs/operators";
import {AdminKomboResource} from "../../../generated/resources";
import {AdminKomboImportJobData} from "../../../generated/data";
import {
    PositionDetailsDialogComponent
} from "../../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";

class AdminKomboImportJobInternData {
    content: string | null = null;
    komboJobId: string | null = null;
    remoteId: string | null = null;
    title: string | null = null;
    uniwunderPositionId: number | null = null;
    control: FormControl
}

@Component({
    selector: 'app-import-ats-positions-dialog',
    templateUrl: './import-ats-positions-dialog.component.html',
    styleUrls: ['./import-ats-positions-dialog.component.scss']
})
export class ImportAtsPositionsDialogComponent implements OnInit {


    //inputs
    companyId: number

    loading: boolean = true
    importPositionsLoading: boolean = false
    positionsDatasource : AdminKomboImportJobInternData[] = []
    selection = new SelectionModel<AdminKomboImportJobInternData>(true, );
    allPositions: AdminKomboImportJobInternData[] = []
    searchControl: FormControl = new FormControl()
    displayedColumns: string[] = ['select', 'title', 'id', 'content', 'ourPosition'];

    constructor(
        public sideSheetRef: SideSheetRef,
        private adminKomboResource: AdminKomboResource,
        private dialogService: SideSheetService
    ) {
    }

    ngOnInit(): void {
        this.getAtsPositions()
        this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(next => {
            this.loading = true
            this.positionsDatasource = this.allPositions.filter(p => p.title.toLowerCase().includes(next.toLowerCase()))
            this.loading = false
        })
    }

    getAtsPositions() {
        this.loading = true
        this.adminKomboResource.getJobs(this.companyId).then(result => {
            this.allPositions = result.map( p => <AdminKomboImportJobInternData>{title: p.title, content: p.content, control: new FormControl(null), komboJobId: p.komboJobId, uniwunderPositionId: p.uniwunderPositionId, remoteId: p.remoteId})
            this.positionsDatasource = this.allPositions
        })


        this.positionsDatasource = this.allPositions
        this.loading = false
    }

    importPositions() {
        this.importPositionsLoading = true
        let data: AdminKomboImportJobData[] = this.selection.selected.map(it => <AdminKomboImportJobData>{
                content: it.content,
                komboJobId: it.komboJobId,
                remoteId: it.remoteId,
                title: it.title,
                uniwunderPositionId: it.control.value
            }
        )
        this.adminKomboResource.importJobsForCompany(data, this.companyId).then(result => {
            this.importPositionsLoading = true
            this.close()
        })

    }

    close() {
        this.sideSheetRef.sheetClosed.next()
    }

    openPositionDetailsDialog(positionId) {
        let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = positionId

    }
}
