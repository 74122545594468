<dialog-header dialogHeadline="Event bearbeiten/erstellen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content layout-padding">
        <form [formGroup]="form" class="width-100" fxLayout="column" fxLayoutAlign="start start"
              fxLayoutGap="20px">

            <mat-form-field *ngIf="data?.type != 'PerfectMatchEvent'" class="width-100" appearance="fill">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title">
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <date-time-select
                        [minDate]="today"
                        placeholder="start"
                        class="width-50"
                        [control]="form.controls.start">
                </date-time-select>

                <date-time-select
                        [minDate]="today"
                        placeholder="ende"
                        class="width-50"
                        [control]="form.controls.end">
                </date-time-select>
            </div>


            <mat-form-field *ngIf="data?.type != 'PerfectMatchEvent'" class="width-100" appearance="fill">
                <mat-label>videoLinkUnsafe</mat-label>
                <input matInput formControlName="videoLinkUnsafe">
            </mat-form-field>

            <mat-form-field *ngIf="data?.type != 'PerfectMatchEvent'" class="width-100" appearance="fill">
                <mat-label>tileText</mat-label>
                <textarea matInput rows="5" formControlName="tileText"></textarea>
            </mat-form-field>

<!--            <mat-label>tileText</mat-label>-->
<!--            <ngx-codemirror formControlName="tileText"-->
<!--                            style="height:300px; width: 100%"-->
<!--                            [options]="{-->
<!--                                                lineNumbers: true,-->
<!--                                                theme: 'material',-->
<!--                                                mode:'htmlmixed'}"-->
<!--            ></ngx-codemirror>-->

<!--            <mat-form-field class="width-100" appearance="fill">-->
<!--                <mat-label>shortDescription</mat-label>-->
<!--                <textarea matInput rows="5" formControlName="shortDescription"></textarea>-->
<!--            </mat-form-field>-->

            <mat-label *ngIf="data?.type != 'PerfectMatchEvent'">shortDescription</mat-label>
            <ngx-codemirror *ngIf="data?.type != 'PerfectMatchEvent'" formControlName="shortDescription"
                            style="height:300px; width: 100%"
                            [options]="{
                                                lineNumbers: true,
                                                theme: 'material',
                                                mode:'htmlmixed'}"
            ></ngx-codemirror>

            <!--            <mat-form-field class="width-100" appearance="fill">-->
<!--                <mat-label>longDescription</mat-label>-->
<!--                <textarea matInput rows="5" formControlName="longDescription"></textarea>-->
<!--            </mat-form-field>-->

            <mat-label *ngIf="data?.type != 'PerfectMatchEvent'">longDescription</mat-label>
            <ngx-codemirror *ngIf="data?.type != 'PerfectMatchEvent'" formControlName="longDescription"
                            style="height:300px; width: 100%"
                            [options]="{
                                                lineNumbers: true,
                                                theme: 'material',
                                                mode:'htmlmixed'}"
            ></ngx-codemirror>

            <mat-form-field *ngIf="data?.type != 'PerfectMatchEvent'" class="width-100" appearance="fill">
                <mat-label>streetName</mat-label>
                <input matInput formControlName="streetName">
            </mat-form-field>

            <mat-form-field *ngIf="data?.type != 'PerfectMatchEvent'" class="width-100" appearance="fill">
                <mat-label>streetNumber</mat-label>
                <input matInput formControlName="streetNumber">
            </mat-form-field>

            <mat-form-field *ngIf="data?.type == 'PerfectMatchEvent'"  class="width-100" appearance="fill">
                <mat-label>Vollständige Adresse</mat-label>
                <input matInput formControlName="fullAddress">
            </mat-form-field>


            <app-searchable-city-dropdown
                    class="width-100"
                    [multiple]="false"
                    [required]="false"
                    [control]="form.controls['cityId']"
                    [placeholder]="'Stadt'">
            </app-searchable-city-dropdown>

            <info-box *ngIf="data?.type != 'PerfectMatchEvent'" message="Bitte Bei GroupedEvents und Events, auf welche sich über das Event-Onboarding direkt angemeldet werden soll, unbedingt eine Adresse für das Unternehmen auf dem Unternehmensprofil hinterlegen!"></info-box>

            <searchable-company-dropdown [required]="false" appearance="fill" placeholder="Teilnehmende Unternehmen" [multiple]="true" [control]="this.form.controls.companies">
            </searchable-company-dropdown>

            <div style="font-weight: bold; color: #b60000" *ngIf="data?.type != 'PerfectMatchEvent'">ACHTUNG! nicht mehr die Format ID !</div>
            <mat-form-field *ngIf="data?.type != 'PerfectMatchEvent'"  appearance="fill">
                <mat-label>Promotion ID</mat-label>
                <input matInput formControlName="promotionId" type="number">
            </mat-form-field>


            <mat-checkbox [disabled]="!form?.controls['promotionId']?.value" formControlName="showEvent">Wird angezeigt</mat-checkbox>


            <div *ngIf="data?.id && data?.type != 'PerfectMatchEvent'"><b>Event-Bilder</b></div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="data?.type != 'PerfectMatchEvent'">
                <div class="image"  *ngFor="let i of data?.images"
                     [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrlMain + '/public/event/' + data?.id + '/image/' + i + ')'}">
                    <div class="delete-image" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="removeImage(i)"><i class="mdi mdi-close"></i></button>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="data?.id && data?.type != 'PerfectMatchEvent'">
                <button mat-flat-button
                        color="accent"
                        (click)="imageInput.click()">
                    <span>Event Bild Hinzufügen</span>
                </button>
                <input
                    #imageInput type="file"
                    accept=".jpg, .jpeg, .png"
                    style="display: none"
                    (change)="addImage($event)"/>
            </div>

            <div *ngIf="data?.id && data?.type != 'PerfectMatchEvent'"><b>Partner-Logos</b></div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="data?.type != 'PerfectMatchEvent'">
                <div class="image"  *ngFor="let i of data?.partnerLogos"
                     [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrlMain + '/public/event/' + data?.id + '/partnerImages/' + i + ')'}">
                    <div class="delete-image" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="removePartnerLogo(i)"><i class="mdi mdi-close"></i></button>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="data?.id && data?.type != 'PerfectMatchEvent'">
                <button mat-flat-button
                        color="accent"
                        (click)="partnerInput.click()">
                    <span>Partner-Logo Hinzufügen</span>
                </button>
                <input
                    #partnerInput type="file"
                    accept=".jpg, .jpeg, .png"
                    style="display: none"
                    (change)="addPartnerLogo($event)"/>
            </div>

            <div *ngIf="data?.id && data?.type != 'PerfectMatchEvent'"><b>Event-Header</b></div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="data?.type != 'PerfectMatchEvent'">
                <div class="image"  *ngIf="data?.eventHeader"
                     [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrlMain + '/public/event/' + data?.id + '/headerImage)'}">
                    <div class="delete-image" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="removeHeader()"><i class="mdi mdi-close"></i></button>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="data?.id && !data?.eventHeader && data?.type != 'PerfectMatchEvent'">
                <button mat-flat-button
                        color="accent"
                        (click)="partnerInput.click()">
                    <span>Header Hinzufügen</span>
                </button>
                <input
                    #partnerInput type="file"
                    accept=".jpg, .jpeg, .png"
                    style="display: none"
                    (change)="addHeader($event)"/>
            </div>
        </form>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true" buttonClass="mat-primary" [loadingBool]="saving"
                        [disabledCondition]="form.invalid" (clickEvent)="createOrUpdateEvent()">Speichern
        </loading-button>
    </div>
</div>