<dialog-header dialogHeadline="Bewerbungsdetails"></dialog-header>

<div class="dialog-main-content" style="height: calc(100% - 50px)">
    <loading-spinner [loadingBool]="loading" class="width-100">
        <div style="height: calc(100% - 50px)">
            <mat-tab-group style="height: calc(100vh - 130px);">

                <!--Must be the first Tab-->
                <mat-tab label="Bewerbungs-Dossier">
                    <p *ngIf="!cvFile ||cvFile.size == 0" style="color: grey" class="width-100 text-center m-top30">Kein
                        Lebenslauf vorhanden</p>

                    <ngx-extended-pdf-viewer
                            style="width: 100%"
                            *ngIf="cvFile && cvFile.size != 0"
                            [src]="cvFile"
                            [height]="'100%'"
                            [useBrowserLocale]="true">
                    </ngx-extended-pdf-viewer>

                </mat-tab>

                <mat-tab label="Informationen">
                    <div class="content" fxLayout="column" fxLayoutGap="30px" #content>
                        <div class="content-upper">
                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
                                <information-property name="Talent"
                                                      [text]="application?.talentName"></information-property>
                                <information-property name="Stelle"
                                                      [text]="application?.position.title"></information-property>
                                <information-property name="Firma" [text]="application?.company"></information-property>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
                                <information-property name="Datum"
                                                      [text]="application?.creationDate | date: 'dd.MM.yy'"></information-property>
                                <information-property name="Status Bewerbung"
                                                      [text]="application?.state | applicationState"></information-property>
                            </div>
                        </div>
                    </div>

                    <table *ngIf="requestedDocumentsData.length > 0" class="w-100" mat-table [dataSource]="requestedDocumentsData">
                        <ng-container matColumnDef="docType">
                            <th mat-header-cell *matHeaderCellDef>Nachgeforderte Dokumente</th>
                            <td mat-cell *matCellDef="let element">{{element.requestedDocumentType}}</td>
                        </ng-container>

                        <ng-container matColumnDef="isUploaded">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center">Status</th>
                            <td mat-cell *matCellDef="let element">
                                <i class="mdi mdi-check green mdi-24px" *ngIf="element.hasTalentUploadedDocumentsSinceRequest" matTooltip="Upload erfolgt" fxLayoutAlign="center center"></i>
                                <i class="mdi mdi-close red mdi-24px" *ngIf="!element.hasTalentUploadedDocumentsSinceRequest" matTooltip="Upload ausstehend" fxLayoutAlign="center center"></i>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="['docType','isUploaded']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['docType','isUploaded'];"></tr>
                    </table>

                    <div class="layout-padding">Fragen an das Talent bei der Bewerbung</div>
                    <div fxLayout="column" fxLayoutGap="30px" class="layout-padding">
                        <div *ngIf="!application?.positionQuestions?.length" style="color: grey"
                             class="width-100 text-center m-top30">Das Talent hat keine Fragen beantwortet
                        </div>
                        <information-property *ngFor="let question of application?.positionQuestions"
                                              [name]="question?.question"
                                              [text]="question?.answer"></information-property>
                    </div>

                </mat-tab>

                <mat-tab label="History">
                    <table mat-table [dataSource]="application?.historyEntry" style="width: 100%">

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>Datum</th>
                            <td mat-cell *matCellDef="let element">{{element.date | date: 'dd.MM.yy HH:mm'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="event">
                            <th mat-header-cell *matHeaderCellDef>Ereignis</th>
                            <td mat-cell *matCellDef="let element">{{element.logName | applicationHistory}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-tab>

            </mat-tab-group>
        </div>


        <div class="action-bar" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="application-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <mat-form-field style="width: 200px" *ngIf="states.includes(applicationStateCtr.value, 0)">
                    <mat-label>Bewerbungsstatus</mat-label>
                    <mat-select [multiple]="false" [formControl]="applicationStateCtr">
                        <mat-option (click)="openConfirmApplicationStateDialog(state)" *ngFor="let state of states" [value]="state">{{state | applicationState}} ({{state}})</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-flat-button style="color: white" color="primary" (click)="openRequestAdditionalApplicationDocumentsDialog()">Dokumente
                    nachfordern
                </button>
                <button mat-flat-button style="color: white" color="primary" (click)="openTalentProfile()">Talent
                    Profil
                </button>
                <button mat-flat-button style="color: white" color="primary" (click)="openPositionDetailsDialog()">
                    Stellendetails
                </button>
            </div>
        </div>
    </loading-spinner>
</div>
