import {Component, Input} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver/FileSaver';
import {TalentService} from '../../../services/talent.service';
import {FileService} from '../../../services/file.service';
import {AdminApplicationData, AdminEmploymentPositionData,} from '../../../generated/data';
import {
    AdminApplicationResource,
    TalentApplicationResource,
    TalentTalentProfileResource
} from '../../../generated/resources';
import {UtilityService} from '../../../utils/utility.service';
import {Paginator} from '../../../utils/paginator';
import {ApplicationOverviewDialogComponent} from "../../../applications/application-overview-dialog/application-overview-dialog.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {ApplicationDetailsDialogComponent} from "../../../applications/application-details-dialog/application-details-dialog.component";

@Component({
  selector: 'position-applications',
  templateUrl: './position-applications.component.html',
  styleUrls: ['./position-applications.component.css']
})
export class PositionApplicationsComponent extends Paginator {

  @Input() positionId: number;
  @Input() position: AdminEmploymentPositionData;

  downloading: boolean = false;
  exporting: boolean = false;

  sortBy: string = 'matchingScore';
  sortAsc: boolean = false;

  dataSource: AdminApplicationData[] = [];
  loading: boolean = false;

  displayedColumns = [
    'talentName',
    'creationDate',
    'state',
      'failedReasonTalent',
      'failedReasonUniwunder',
    'lastStateUpdate',
    'actions'
  ];

  constructor(
    public router: Router,
    public talentService: TalentService,
    private dialog: MatDialog,
    private talentTalentProfileResource: TalentTalentProfileResource,
    private http: HttpClient,
    private fileService: FileService,
    private utilityService: UtilityService,
    private talentApplicationResource: TalentApplicationResource,
    private adminApplicationResource: AdminApplicationResource,
    private dialogService: SideSheetService
  ) {
    super("matchingScore", () => {
      this.loadData();
    })
  }

  ngOnInit() {
    this.loadData();
  }


  openApplicationDetails(application: AdminApplicationData) {
    let cref = this.dialogService.openOverlay(ApplicationDetailsDialogComponent);
    cref.instance.applicationId = application.applicationId;
  }

  loadData() {
    this.loading = true;

    this.adminApplicationResource.getApplicationsForEmploymentPosition(this.positionId).then(data => {
      this.dataSource = data;
      this.loading = false;
    })
  }
}
