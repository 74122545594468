import { Component, OnInit } from '@angular/core';
import {FormControl} from "@angular/forms";
import {AdminSubjectResource, ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {LabelData, SubjectGroupData} from "../../../generated/data";
import {PageEvent} from "@angular/material/paginator";
import {SubjectGroupDetailsSheetComponent} from "../subject-group-details-sheet/subject-group-details-sheet.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";

@Component({
  selector: 'subject-groups',
  templateUrl: './subject-groups.component.html',
  styleUrls: ['./subject-groups.component.scss']
})
export class SubjectGroupsComponent implements OnInit {

  public loading: boolean = true;
  public subjectGroups: SubjectGroupData[] = []
  public subjectGroupFilterControl: FormControl = new FormControl()

  public displayedColumns = ['agencyKey', 'subjectGroupTitle', 'numSubjects', 'numProfessions']

  public pageIndex: number = 0;
  public pageSize: number = 15;
  public totalSize: number = 0;
  public pageSizeOptions: number[] = [15, 30, 60, 100]

  constructor(
      private adminSubjectResource: AdminSubjectResource,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.loadSubjectGroups()
  }

  loadSubjectGroups(): void {
    this.loading = true;
    this.adminSubjectResource.getAllSubjectGroups({
      query: this.subjectGroupFilterControl.value,
      page: this.pageIndex,
      pageSize: this.pageSize
    }).then(result => {
      this.subjectGroups = result.content
      this.totalSize = result.totalElements
      this.loading = false
    })
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadSubjectGroups();
  }

  openSubjectGroupDetailsSheet(subjectGroup: SubjectGroupData) {
    let cref = this.dialogService.openOverlay(SubjectGroupDetailsSheetComponent)
    cref.instance.subjectGroupId = subjectGroup.id;
    cref.instance.subjectGroupTitle = subjectGroup.title;
  }
}
