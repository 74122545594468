import { Component, ComponentRef, Input, OnInit } from '@angular/core';
import { AdminBookingResource, AdminCompanyResource } from '../../../generated/resources';
import { BookingComponent } from "../../../bookings/booking/booking.component";
import { FormControl} from "@angular/forms";
import {AdminBookingData, BookingFilterData} from "../../../generated/data";
import {
  CreateOrUpdateBookingSideSheetComponent
} from "../../../bookings/create-booking-dialog/create-or-update-booking-side-sheet.component";
import {SideSheetService} from "../../side-sheet/side-sheet.service";

@Component({
  selector: 'searchable-booking-dropdown',
  templateUrl: './searchable-booking-dropdown.component.html',
  styleUrls: ['./searchable-booking-dropdown.component.scss']
})
export class SearchableBookingDropdownComponent implements OnInit {

  @Input() control: FormControl;
  @Input() companyId?: number;

  bookings: AdminBookingData[] = [];

  constructor(
      private adminBookingResource: AdminBookingResource,
      private dialogService: SideSheetService,
      private companyResource: AdminCompanyResource
  ) { }

  ngOnInit() {
    this.loadBookings();
  }

  loadBookings() {
    const filterData: BookingFilterData = {
      partialTitle: '',
      companyId: this.companyId,
      states: ['Active'],
        dateFrom: null
    };

    this.adminBookingResource.getFilteredBookings(filterData)
        .then(bookings => {
          this.bookings = bookings.content;
        });
  }

  createNewBooking() {
    const comp: ComponentRef<CreateOrUpdateBookingSideSheetComponent> =
        this.dialogService.openOverlay(CreateOrUpdateBookingSideSheetComponent, 'Small');

    comp.instance.booking = <AdminBookingData>{ company: { id: this.companyId, name: '' }, positions: [] };

    this.companyResource.getCompany(this.companyId).then(company => {
      comp.instance.booking.company = { id: this.companyId, name: company.name };
    });

    const subscription = comp.instance.sideSheetRef.sheetClosed.subscribe((res: AdminBookingData) => {
      if (res) this.loadBookings();

      subscription.unsubscribe();
    });
  }


  openBooking(booking: AdminBookingData) {
    let comp = this.dialogService.openOverlay(BookingComponent, 'Medium')
    comp.instance.booking = booking;

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      if (res) this.loadBookings();

      subscription.unsubscribe();
    })
  }
}
