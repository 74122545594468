import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {
    LabelData,
    ProfessionData,
    SubjectData,
    SubjectGroupData,
    SubjectProfessionPathData
} from "../../../generated/data";
import {MatTableDataSource} from "@angular/material/table";
import {SubjectDetailsSheetComponent} from "../subject-details-sheet/subject-details-sheet.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SubjectGroupDetailsSheetComponent} from "../subject-group-details-sheet/subject-group-details-sheet.component";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";

@Component({
    selector: 'subject-profession-pathfinder',
    templateUrl: './subject-profession-pathfinder.component.html',
    styleUrls: ['./subject-profession-pathfinder.component.scss']
})
export class SubjectProfessionPathfinderComponent implements OnInit {

    public subjectControl = new FormControl(null)
    public professionControl = new FormControl(null)
    public matchingLevelFilterControl = new FormControl(null)
    public subjectProfessionPaths: SubjectProfessionPathData[] = []
    public loading: boolean = false

    public displayedColumns = ['subject', 'subjectGroup', 'subjectProfession', 'matchingLevel', 'matchingProperty'  ,'profession']

    constructor(
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private dialogService: SideSheetService
    ) {
    }

    ngOnInit(): void {
    }

    findAllPaths() {
        this.loading = true;
        this.professionMatchingOptimizingResource.getAllPathsBetweenSubjectAndProfession(
            {subjectId: this.subjectControl.value, professionId: this.professionControl.value}
        ).then(paths => {
            this.subjectProfessionPaths = paths
            this.loading = false
        })
    }

    getMatchingProperty(path: SubjectProfessionPathData) {
        switch (path.matchingLevel) {
            case "Area": return path.profession.professionAreaTitle
            case "MainGroup": return path.profession.professionMainGroupTitle
            case "Group": return path.profession.professionGroupTitle
            case "SubGroup": return path.profession.professionSubGroupTitle
            case "ProfessionType": return path.profession.professionTypeTitle
            case "Profession": return path.profession.title
        }
    }

    openSubjectDetailsSheet(subject: LabelData) {
        let cref = this.dialogService.openOverlay(SubjectDetailsSheetComponent)
        cref.instance.subjectId = subject.id;
        cref.instance.subjectTitle = subject.name;
    }

    openSubjectGroupDetailsSheet(subjectGroup: LabelData) {
        let cref = this.dialogService.openOverlay(SubjectGroupDetailsSheetComponent)
        cref.instance.subjectGroupId = subjectGroup.id;
        cref.instance.subjectGroupTitle = subjectGroup.name;
    }

    openProfessionDetailsSheet(profession: LabelData) {
        let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
        cref.instance.professionId = profession.id;
        cref.instance.professionTitle = profession.name;
    }
}
