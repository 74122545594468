import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AdminEmploymentPositionResource} from "../../generated/resources";
import {FormControl, Validators} from "@angular/forms";
import {PositionPrivacyDisclaimerData} from "../../generated/data";
import {UtilityService} from "../../utils/utility.service";
import {FileService} from "../../services/file.service";

@Component({
  selector: 'app-privacy-disclaimer-dialog',
  templateUrl: './position-privacy-disclaimer-dialog.component.html',
  styleUrls: ['./position-privacy-disclaimer-dialog.component.scss']
})
export class PositionPrivacyDisclaimerDialogComponent implements OnInit {

  public privacyDisclaimerData: PositionPrivacyDisclaimerData
  public disclaimerPartnerNameControl: FormControl = new FormControl(null, Validators.required);
  public disclaimerPartnerPrivacyUrlControl: FormControl = new FormControl(null, Validators.required);
  public showDisclaimerControl: FormControl = new FormControl(null);
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PositionPrivacyDisclaimerDialogComponent>,
    private adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private utilityService: UtilityService,
    private fileService: FileService
  ) {
  }

  ngOnInit() {
    this.getLatestPrivacyDisclaimer()
    this.disclaimerPartnerNameControl.setValue(this.data.companyName)
  }

  getLatestPrivacyDisclaimer() {
    this.adminEmploymentPositionResource.getLatestPrivacyDisclaimerInformation(this.data).then(result => {
      if (result) {
        this.privacyDisclaimerData = result
        this.showDisclaimerControl.setValue(result.showDisclaimer)
        this.disclaimerPartnerNameControl.setValue(result.partnerName)
        this.disclaimerPartnerPrivacyUrlControl.setValue(result.partnerPrivacyUrl)
      }
    })
  }

  updatePrivacyDisclaimer() {
    const privacyDisclaimerData: PositionPrivacyDisclaimerData = {
      partnerName: this.disclaimerPartnerNameControl.value,
      partnerPrivacyUrl: this.disclaimerPartnerPrivacyUrlControl.value,
      showDisclaimer: this.showDisclaimerControl.value,
      validSince: null
    }

    this.adminEmploymentPositionResource.updatePrivacyDisclaimer(privacyDisclaimerData, this.data).then(() => {
      this.getLatestPrivacyDisclaimer();
      this.utilityService.showSnackBar("Datenschutzerklärung erfolgreich gespeichert!")
    })
  }

  showPrivacyAgreement() {
    this.fileService.downloadRecruitingPrivacyDisclaimerForPosition(this.data)
  }
}
