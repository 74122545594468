import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TalentFilterResultListSheetComponent} from "../talent-filter-group/talent-filter-result-list-sheet/talent-filter-result-list-sheet.component";
import {AdminEmploymentPositionResource} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {TalentFilterData} from "../../../generated/data";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'prime-talent-filters',
  templateUrl: './prime-talent-filters.component.html',
  styleUrls: ['./prime-talent-filters.component.scss']
})
export class PrimeTalentFiltersComponent implements OnInit {

  @Input() positionId: number;

  filters: TalentFilterData[] = []

  savingTalentFilters: Boolean = false;
  filtersLoaded: Boolean = false;

  constructor(
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private sideSheetService: SideSheetService,
      private cdRef: ChangeDetectorRef,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.loadTalentFilters();
  }

  loadTalentFilters() {
    this.adminEmploymentPositionResource.getPrimeTalentFiltersOfPosition(this.positionId).then(filters => {
      this.filters = filters
      this.filtersLoaded = true
    })
  }

  saveTalentFilters() {
    this.savingTalentFilters = true
    this.adminEmploymentPositionResource.updateTalentFiltersForPosition(this.filters, this.positionId).then(() => {
      this.loadTalentFilters();
      this.savingTalentFilters = false;
    })
  }

  openTalentFilterSideSheet(): void {
    let sref = this.sideSheetService.openOverlay(TalentFilterResultListSheetComponent, 'Large')
    sref.instance.positionId = this.positionId
    sref.instance.filters = this.filters
  }
}
