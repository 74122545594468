import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sliceStringLength'
})
export class SliceStringLengthPipe implements PipeTransform {

  transform(value: string, length: number): string {

    if (value == null) {
      return null
    }

    if (value.length > length) {
      return value.slice(0, length) + "...";
    } else {
      return value;
    }
  }
}
