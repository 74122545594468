<dialog-header dialogHeadline="{{professionTypeTitle}}"></dialog-header>
<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">cr
        <div *ngIf="professionTypeData" class="width-100" fxLayout="column" fxLayoutGap="4px">
            <small><b>ID:</b> <span class="copy" (click)="utilityService.showSnackBar('ID kopiert')"> {{professionTypeId}}</span></small>
            <small><b>DKZ-Kennung:</b> <span class="copy" (click)="utilityService.showSnackBar('DKZ-Kennung kopiert')"> {{professionTypeData?.agencyKey}}</span></small>
            <small><b>ProfessionSubGroup:</b> <span class="clickable" (click)="openProfessionSubGroupDetailsSheet()"> {{professionTypeData?.subGroupTitle}}</span></small>
            <small><b>ProfessionGroup:</b> <span class="clickable" (click)="openProfessionGroupDetailsSheet()"> {{professionTypeData?.groupTitle}}</span></small>
            <small><b>ProfessionMainGroup:</b> <span class="clickable" (click)="openProfessionMainGroupDetailsSheet()"> {{professionTypeData?.mainGroupTitle}}</span></small>
            <small><b>ProfessionArea:</b> <span class="clickable" (click)="openProfessionAreaDetailsSheet()"> {{professionTypeData?.areaTitle}}</span></small>
        </div>

        <mat-tab-group>
            <mat-tab label="Professions" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyProfessionFilter($event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionDataSource" class="w-100">
                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let profession">{{profession.agencyKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="professionTitle">
                        <th mat-header-cell *matHeaderCellDef>Profession</th>
                        <td mat-cell *matCellDef="let profession">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionDetailsSheet(profession)">
                                {{profession.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjectGroups">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjectGroups}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjects">
                        <th mat-header-cell *matHeaderCellDef>Subjects</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjects}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
</loading-spinner>

