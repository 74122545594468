import {TalentDeactivationSelectionsData} from "./talent-deactivation-selections-data"

export class TalentDeactivationData {
    anonymize: boolean = null;
    applicationId: number | null = null;
    coachDissatisfactionText: string | null = null;
    other: string | null = null;
    qualityDissatisfactionText: string | null = null;
    selections = new TalentDeactivationSelectionsData();
    technicalIssuesText: string | null = null;
    withdrawRequests: boolean = null;
}