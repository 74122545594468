import {Component, OnInit} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {
  AdminCompanyProfileData,
  AdminCompanyProfileFilterData, AdminCompanyProfileQuickUpdateData,
} from "../generated/data";
import {AdminCompanyResource} from "../generated/resources";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {UtilityService} from "../utils/utility.service";

@Component({
  selector: 'app-company-profiles',
  templateUrl: './company-profiles.component.html',
  styleUrls: ['./company-profiles.component.scss']
})
export class CompanyProfilesComponent implements OnInit {

  companyProfileData: AdminCompanyProfileData[]

  displayedColumns: string[] =
      ['id', 'name', 'contact', 'slogan', 'intro', 'culture', 'location', 'office', 'benefits', 'showProfile', 'prime', 'actions']

  pageSize: number = 15
  pageSizeOptions: number[] = [15, 30, 60, 100]
  pageIndex: number = 0
  totalSize: number = 0

  updatedCompanyId: number = 0

  loading: boolean = true
  disabled: boolean = false

  considerPrimeStatus: FormControl = new FormControl(false)
  considerProfileShown: FormControl = new FormControl(false)
  considerProfileNotBlank: FormControl = new FormControl(false)
  searchTextControl: FormControl = new FormControl(null)

  showProfile: FormControl = new FormControl(null)
  prime: FormControl = new FormControl(null)

  constructor(
      private adminCompanyResource: AdminCompanyResource,
      private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.loadCompanyProfiles()

    this.searchTextControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.loadCompanyProfiles()
    })
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.loadCompanyProfiles()
  }

  reloadPage(): void {
    this.pageIndex = 0
    this.loadCompanyProfiles()
  }

  updateCompanyProfile(company: AdminCompanyProfileData): void {
    this.disabled = true
    this.updatedCompanyId = company.id
    let updateData = <AdminCompanyProfileQuickUpdateData> {
      id: company.id,
      showProfile: company.showProfile,
      prime: company.primeCompany,
    }
    this.adminCompanyResource.updateCompanyShowProfile(updateData).then( () => {
      this.disabled = false
      this.utilityService.showSnackBar("Firma erfolgreich bearbeitet!")
    })
  }

  checkForEmptyProfile(company: AdminCompanyProfileData): boolean {
    return (
        company.introText == null &&
        company.slogan == null &&
        company.benefitsText == null &&
        company.cultureText == null &&
        company.officeText == null &&
        company.contactPrompt == null &&
        company.locationText == null);
  }

  getShowProfileTooltipText(company: AdminCompanyProfileData): string {
    if (this.checkForEmptyProfile(company)) return
    return company.showProfile? "Profil nicht anzeigen" : "Profil anzeigen"
  }

  getPrimeTooltipText(company: AdminCompanyProfileData): string {
    return company.primeCompany? "Prime ausschalten": "Prime anschalten"
  }

  navigateToCompany(companyId: number): void {
    window.open('companies/'+ companyId, '_blank')
  }

  loadCompanyProfiles(): void {
    this.loading = true
    let filterData = <AdminCompanyProfileFilterData> {
      onlyPrimeStatus: this.considerPrimeStatus.value ? this.considerPrimeStatus.value : null,
      onlyProfileShown: this.considerProfileShown.value ? this.considerProfileShown.value : null,
      searchQuery: this.searchTextControl.value ? this.searchTextControl.value : null,
      onlyProfileNotBlank: this.considerProfileNotBlank.value ? this.considerProfileNotBlank.value : null,
      onlyNotDisabled: true
    }
    this.adminCompanyResource.getCompaniesWithProfiles(filterData, {
      page: this.pageIndex,
      pageSize: this.pageSize
    }).then(result => {
      this.companyProfileData = result.content
      this.totalSize = result.totalElements
      this.loading = false
    })
  }

}
