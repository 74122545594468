import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminApplicationResource} from "../../generated/resources";
import {AdminApplicationBlockedData, AdminApplicationData, BlockedReason} from "../../generated/data";

@Component({
  selector: 'app-reject-application-dialog',
  templateUrl: './reject-application-dialog.component.html',
  styleUrls: ['./reject-application-dialog.component.scss']
})
export class RejectApplicationDialogComponent implements OnInit {

  loading: boolean = false
  application: AdminApplicationData

  blockedReason: BlockedReason = null
  comment: string = null

  constructor(
      private sideSheetRef: SideSheetRef,
      private applicationResource: AdminApplicationResource
  ) { }

  ngOnInit(): void {
  }

  denyApplication() {
    let blockedData: AdminApplicationBlockedData = {
      comment: this.comment,
      reason: this.blockedReason
    }

    this.applicationResource.denyTalentApplication(blockedData, this.application.applicationId).then((success) => {
      if(success) this.sideSheetRef.sheetClosed.next({type: 'Deny', success: success});
    })
  }

  cancel() {
    this.sideSheetRef.sheetClosed.next(false)
  }
}
