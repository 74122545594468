<loading-spinner [loadingBool]="loading" class="h-100 w-100">
    <div class="component-wrapper w-100 h-100">
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="filter m-top10" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                <mat-form-field style="width: 400px">
                    <input [formControl]="subjectFilterControl" matInput placeholder="Subjectsuche">
                </mat-form-field>


                <button mat-icon-button color="primary" matTooltip="Reload" (click)="loadSubjects()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <table mat-table [dataSource]="subjects" class="w-100">

            <ng-container matColumnDef="subjectTitle">
                <th mat-header-cell *matHeaderCellDef>Subject</th>
                <td mat-cell *matCellDef="let subject">
                            <span class="clickable"
                                  matTooltip="Detailansicht öffnen"
                                  (click)="openSubjectDetailsSheet(subject)">
                                {{subject.title}}
                            </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="numSubjectGroups">
                <th mat-header-cell *matHeaderCellDef>Anz.</th>
                <td mat-cell *matCellDef="let subject">{{subject.numSubjectGroups}}</td>
            </ng-container>

            <ng-container matColumnDef="subjectGroups">
                <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                <td mat-cell *matCellDef="let subject">
                    <div fxLayout="column" fxLayoutAlign="center start">
                                <span class="subjectGroup"
                                      matTooltip="Mappings ansehen"
                                      *ngFor="let group of subject.subjectGroups"
                                      (click)="openSubjectGroupDetailsSheet(group)">
                                    {{group.name}}
                                </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="numProfessions">
                <th mat-header-cell *matHeaderCellDef>Professions</th>
                <td mat-cell *matCellDef="let subject">{{subject.numProfessions}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                <td mat-cell *matCellDef="let subject">
                    <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Studiengang zuordnen" (click)="openMapSubjectSheet(subject)">
                            <i class="mdi mdi-file-tree mdi-24px"></i>
                        </button>

                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Studiengang neu zuweisen" (click)="openReplaceSubjectSheet(subject)">
                            <i class="mdi mdi-content-save-edit mdi-24px"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</loading-spinner>


