import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FileService} from "../../services/file.service";
import {AdminTalentResource} from "../../generated/resources";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminTalentCoachCriteriaData} from "../../generated/data";

@Component({
  selector: 'app-complete-coaching-data-side-sheet',
  templateUrl: './complete-coaching-data-side-sheet.component.html',
  styleUrls: ['./complete-coaching-data-side-sheet.component.scss']
})
export class CompleteCoachingDataSideSheetComponent implements OnInit {

  //Input
  talentId;

  cvFile: Blob = null;
  zipCodeControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5),Validators.pattern('[0-9]*')])
  constructor(  private fileService: FileService, private talentResource: AdminTalentResource, public sideSheetRef: SideSheetRef) {

  }
loading = false;
  ngOnInit(): void {
    this.getCVFile();
  }

  getCVFile() {
    this.loading = true;
    this.fileService.getCV(this.talentId).subscribe((cv) => {
      this.cvFile = cv;
      this.loading = false;
    })
  }

  saving = false;
  save(){
    this.saving = true;
    this.talentResource.updateTalentCoachCriteria( <AdminTalentCoachCriteriaData>{
      zipCode: this.zipCodeControl.value
    },this.talentId).then(() => {
      this.sideSheetRef.sheetClosed.next(true)
      this.saving = false;
    })
  }

}
