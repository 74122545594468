<dialog-header dialogHeadline="Studiengang-Targeting bearbeiten"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start" *ngIf="!loadingEventFilter">

    <div class="side-sheet-content layout-padding">
        <form [formGroup]="form" class="width-100" fxLayout="column" fxLayoutAlign="start start"
              fxLayoutGap="20px">
            <searchable-subject-group-dropdown style="width: 600px;" [placeholder]="'Studiengruppen'" [control]="form.controls['subjectGroupIds']" [multiple]="true" [required]="false"
            ></searchable-subject-group-dropdown>

            <searchable-subject-dropdown  style="width: 600px" [placeholder]="'Studiengang'" [control]="form.controls['subjectIds']" [multiple]="true" [required]="false"></searchable-subject-dropdown>
        </form>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true" buttonClass="mat-primary" [loadingBool]="updating"
                        [disabledCondition]="form.invalid" (clickEvent)="updateFilter()">Speichern
        </loading-button>
    </div>
</div>