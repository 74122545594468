import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PositionPerformanceData, PositionPerformancesQuartilesData, PositionPerformanceCriteriaData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.reporting.controllers.PositionPerformanceController
 */

@Injectable()
@ResourceParams({})
export class PositionPerformanceResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/performances/quartiles/latest',
        endpoint: 'com.uniwunder.talentagent.reporting'
    })
    private _getLatestPositionPerformancesQuartiles: IResourceMethodObservableStrict<null, null, null, PositionPerformancesQuartilesData> | undefined
    getLatestPositionPerformancesQuartiles(): Promise<PositionPerformancesQuartilesData> {
        if (!this._getLatestPositionPerformancesQuartiles) throw new Error("resource has not been properly initialized")
        return this._getLatestPositionPerformancesQuartiles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/performance/positions/{id}',
        endpoint: 'com.uniwunder.talentagent.reporting'
    })
    private _getPositionPerformance: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, {id: number}, PagedData<PositionPerformanceData>> | undefined
    getPositionPerformance(id: number, requestParams: {page: number, pageSize: number}): Promise<PagedData<PositionPerformanceData>> {
        if (!this._getPositionPerformance) throw new Error("resource has not been properly initialized")
        return this._getPositionPerformance(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/performance/positions/quartile',
        endpoint: 'com.uniwunder.talentagent.reporting'
    })
    private _getPositionPerformancesByQuartile: IResourceMethodObservableStrict<null, {quartile: ('First' | 'Second' | 'Third' | 'Fourth'), performanceCriteria: ('Total' | 'TopMatch' | 'LowMatch' | 'Filter' | 'Prime')}, null, PositionPerformanceCriteriaData[]> | undefined
    getPositionPerformancesByQuartile(requestParams: {quartile: ('First' | 'Second' | 'Third' | 'Fourth'), performanceCriteria: ('Total' | 'TopMatch' | 'LowMatch' | 'Filter' | 'Prime')}): Promise<PositionPerformanceCriteriaData[]> {
        if (!this._getPositionPerformancesByQuartile) throw new Error("resource has not been properly initialized")
        return this._getPositionPerformancesByQuartile(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/performances/quartiles',
        endpoint: 'com.uniwunder.talentagent.reporting'
    })
    private _getPositionPerformancesQuartiles: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<PositionPerformancesQuartilesData>> | undefined
    getPositionPerformancesQuartiles(requestParams: {page: number, pageSize: number}): Promise<PagedData<PositionPerformancesQuartilesData>> {
        if (!this._getPositionPerformancesQuartiles) throw new Error("resource has not been properly initialized")
        return this._getPositionPerformancesQuartiles(null, requestParams, null).toPromise()
    }

}