import { Component, OnInit } from '@angular/core';
import {ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SubjectLastOccurredData} from "../../../generated/data";

@Component({
  selector: 'app-map-subject-to-subject-group-sheet',
  templateUrl: './map-subject-to-subject-group-sheet.component.html',
  styleUrls: ['./map-subject-to-subject-group-sheet.component.scss']
})
export class MapSubjectToSubjectGroupSheetComponent implements OnInit {

  public subjectId: number
  public subjectTitle: string
  public loading: boolean = false
  public subjectGroupsControl: FormControl = new FormControl()

  constructor(
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private sideSheetRef: SideSheetRef
  ) {}

  ngOnInit(): void {
  }

  saveMappings(): void {
    this.professionMatchingOptimizingResource.addSubjectToGroupMapping(
        {subjectId: this.subjectId, subjectGroupIds: this.subjectGroupsControl.value}
    ).then(() => this.sideSheetRef.sheetClosed.next(true)
    )
  }
}
