import {Component, OnInit} from '@angular/core';
import {
  FieldOfActivityRelationData,
  JsonWrappedValue,
  LabelData,
  ProfessionData,
  ProfessionMatchingTypeSubjectGroupData,
  SubjectData,
  SubjectGroupData
} from "../../../generated/data";
import {UtilityService} from "../../../utils/utility.service";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {SubjectGroupDetailsSheetComponent} from "../subject-group-details-sheet/subject-group-details-sheet.component";
import {SubjectDetailsSheetComponent} from "../subject-details-sheet/subject-details-sheet.component";
import {ProfessionTypeDetailsSheetComponent} from "../profession-type-details-sheet/profession-type-details-sheet.component";
import {MatTableDataSource} from "@angular/material/table";
import {ProfessionSubGroupDetailsSheetComponent} from "../profession-sub-group-details-sheet/profession-sub-group-details-sheet.component";
import {ProfessionGroupDetailsSheetComponent} from "../profession-group-details-sheet/profession-group-details-sheet.component";
import {ProfessionMainGroupDetailsSheetComponent} from "../profession-main-group-details-sheet/profession-main-group-details-sheet.component";
import {ProfessionAreaDetailsSheetComponent} from "../profession-area-details-sheet/profession-area-details-sheet.component";
import {MapProfessionToSubjectGroupSheetComponent} from "../map-profession-to-subject-group-sheet/map-profession-to-subject-group-sheet.component";
import {ConfirmSideSheetComponent} from "../../../utils/confirm-side-sheet/confirm-side-sheet.component";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-profession-details-sheet',
  templateUrl: './profession-details-sheet.component.html',
  styleUrls: ['./profession-details-sheet.component.scss']
})
export class ProfessionDetailsSheetComponent implements OnInit {

  public professionId: number
  public professionTitle: string
  public professionData: ProfessionData
  public subjectGroupDataSource = new MatTableDataSource()
  public subjectGroupData: SubjectGroupData[] = []
  public subjectDataSource = new MatTableDataSource()
  public subjectData: SubjectData[] = []
  public loading: boolean = true
  public saving: boolean = false

  public professionMatchingTypeSubjectGroupData: ProfessionMatchingTypeSubjectGroupData[]
  originalProfessionMatchingTypes = []

  public displayedSubjectGroupColumns = ['select','subjectGroupTitle', 'numSubjects', 'numProfessions', 'actions']
  public displayedSubjectColumns = ['subjectTitle', 'numSubjectGroups', 'numProfessions']

  constructor(
      public utilityService: UtilityService,
      private professionResource: AdminProfessionResource,
      private adminSubjectResource: AdminSubjectResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) {

  }

  ngOnInit(): void {
    this.subjectDataSource.filterPredicate = (s: SubjectData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.subjectGroupDataSource.filterPredicate = (s: SubjectGroupData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadProfession()
    this.loadProfessionFields();
  }

  fields: FieldOfActivityRelationData[]
  loadProfessionFields(){
    this.professionResource.getFieldsForProfession(this.professionId).then(res =>{
      this.fields = res
    })
  }

  loadProfession(): void {
    this.loading = true
    this.professionResource.getProfessionWithMappingInfo(this.professionId).then(res => {
      this.professionData = res.profession
      this.professionMatchingTypeSubjectGroupData = res.subjectGroupToMatchingType
      this.originalProfessionMatchingTypes = this.professionMatchingTypeSubjectGroupData.map((relation)=>{
        return relation.matchingScore
      })
      Promise.all([
        this.adminSubjectResource.getSubjectGroups({subjectGroupIds: this.professionData.matchedSubjectGroups.map(sg => sg.id)}).then(subjectGroups => {
          this.subjectGroupData = subjectGroups
          this.subjectGroupDataSource.data = this.subjectGroupData
        }),
        this.adminSubjectResource.getSubjects(this.professionData.matchedSubjects.map(s => s.id)).then(subjects => {
          this.subjectData = subjects
          this.subjectDataSource.data = this.subjectData
        })
      ]).then(() => {
        this.loading = false
      })
    })
  }

  addSubjectGroupMapping() {
    let cref = this.dialogService.openOverlay(MapProfessionToSubjectGroupSheetComponent)
    cref.instance.professionId = this.professionId
    cref.instance.professionTitle = this.professionTitle

    cref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      this.loadProfession();
    })
  }

  selection = new SelectionModel<SubjectGroupData>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.subjectData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.subjectGroupData.forEach(row => this.selection.select(row));
  }

  deleteSelectedSubjectGroupMappings(){
    let cref = this.dialogService.openOverlay(ConfirmSideSheetComponent,'Small')

    cref.instance.title = "Gewählte Mappings clearen"
    cref.instance.message = "Diese Funktion löscht alle gewählten Subjectgroup-Mappings für diesen Beruf. Dieser Vorgang ist nicht umkehrbar"
    cref.instance.buttonText = "Mappings löschen"

    cref.instance.onConfirm = () => this.doDeleteSelectedSubjectGroupMappings()
  }

  loadDeleteGroupMappings = false;
  doDeleteSelectedSubjectGroupMappings(){
    var subjectIdsToClear = this.selection?.selected?.map(it => it.id)
    this.loadDeleteGroupMappings = true;
    return  this.professionMatchingOptimizingResource.removeSubjectGroupToProfessionMappingsForProfession(<JsonWrappedValue<number[]>>{value: subjectIdsToClear}, this.professionId).then(() =>{
      this.loadProfession();
      this.loadDeleteGroupMappings = false;
    })
  }

  deleteSubjectGroupMapping(subjectGroup: LabelData) {
    this.professionMatchingOptimizingResource.removeSubjectGroupToProfessionMapping({groupId: subjectGroup.id, professionId: this.professionId}).then(() => {
      this.loadProfession();
      this.utilityService.showSnackBar(`${subjectGroup.name} Mapping erfolgreich entfernt.`)
    })
  }

  openSubjectGroupDetailsSheet(subjectGroup: SubjectGroupData) {
    let cref = this.dialogService.openOverlay(SubjectGroupDetailsSheetComponent)
    cref.instance.subjectGroupId = subjectGroup.id;
    cref.instance.subjectGroupTitle = subjectGroup.title;
  }

  openSubjectDetailsSheet(subject: SubjectData) {
    let cref = this.dialogService.openOverlay(SubjectDetailsSheetComponent)
    cref.instance.subjectId = subject.id;
    cref.instance.subjectTitle = subject.title;
  }

  openProfessionTypeDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionTypeDetailsSheetComponent)
    cref.instance.professionTypeId = this.professionData.typeId;
    cref.instance.professionTypeTitle = this.professionData.typeTitle;
  }

  openProfessionSubGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionSubGroupDetailsSheetComponent)
    cref.instance.professionSubGroupId = this.professionData.subGroupId;
    cref.instance.professionSubGroupTitle = this.professionData.subGroupTitle;
  }

  openProfessionGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionGroupDetailsSheetComponent)
    cref.instance.professionGroupId = this.professionData.groupId;
    cref.instance.professionGroupTitle = this.professionData.groupTitle;
  }

  openProfessionMainGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionMainGroupDetailsSheetComponent)
    cref.instance.professionMainGroupId = this.professionData.mainGroupId;
    cref.instance.professionMainGroupTitle = this.professionData.mainGroupTitle;
  }

  openProfessionAreaDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionAreaDetailsSheetComponent)
    cref.instance.professionAreaId = this.professionData.areaId
    cref.instance.professionAreaTitle = this.professionData.areaTitle;
  }

  applySubjectFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.subjectDataSource.filter = filterValue;
  }

  applySubjectGroupFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.subjectGroupDataSource.filter = filterValue;
  }

  updateSpecializedFlag() {
    this.professionMatchingOptimizingResource.updateSpecializedFlagForProfession(this.professionId).then(()=> {
      this.utilityService.showSnackBar("'Spezialisiert' Flag updated.")
    })
  }

  blacklistProfession() {
    this.professionMatchingOptimizingResource.removeProfessionFromMatching(this.professionId).then(()=> {
      this.utilityService.showSnackBar("Blacklist Status updated.")
    })
  }

  updateMatchingType(element: ProfessionMatchingTypeSubjectGroupData, event) {
    element.matchingScore = event
  }

  saveProfessionSubjectGroupMapping() {
    this.saving = true
    this.originalProfessionMatchingTypes.map((originalMatchingType, index)=>{
      if (originalMatchingType != this.professionMatchingTypeSubjectGroupData[index].matchingScore) {
        this.professionMatchingOptimizingResource.updateProfessionSubjectGroupRelationMatchingType(
            {value: this.professionMatchingTypeSubjectGroupData[index].matchingScore},
            {
              subjectGroupId: this.professionMatchingTypeSubjectGroupData[index].subjectGroup.id,
              professionId: this.professionId
            }
        )
      }

    })
    this.saving = false
    this.utilityService.showSnackBar("Änderungen erfolgreich gespeichert")
  }
}
