<div class="standard-container" fxLayout="column">
  <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="filter-wrapper">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" >
        <mat-form-field>
          <input matInput [matDatepicker]="pickerFrom" placeholder="Anmeldungen von"
                 [(ngModel)]="startDate" name="dateFrom">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="pickerTo" placeholder="Anmeldungen bis"
                 [(ngModel)]="endDate" name="dateTo">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="10px">
          <mat-checkbox [(ngModel)]="devicesActive"></mat-checkbox>
          <mat-form-field>
            <mat-label>Gerät</mat-label>
            <mat-select [disabled]="!devicesActive" [(ngModel)]="devices" [multiple]="true">
              <mat-option [value]="'mobile'">Mobil</mat-option>
              <mat-option [value]="'desktop'">Desktop</mat-option>
              <mat-option [value]="'tablet'">Tablet</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="10px">
          <mat-checkbox [(ngModel)]="utmCampaignActive"></mat-checkbox>
          <mat-form-field>
            <mat-label>UtmCampaign</mat-label>
            <mat-select [disabled]="!utmCampaignActive" [(ngModel)]="selectedCampaigns" [multiple]="true">
              <mat-option>
                <ngx-mat-select-search [formControl]="filterControl"></ngx-mat-select-search>
              </mat-option>
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-button class="toggle-all-button" (click)="unselectAll()" color="warn">Alle abwählen</button>
                <button mat-button class="toggle-all-button" (click)="selectAll()" color="primary">Alle Auswählen</button>
              </div>
              <mat-option *ngFor="let utmCampaign of filteredCampaigns" [value]="utmCampaign">{{utmCampaign}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="10px">
          <mat-checkbox [(ngModel)]="utmSourceActive"></mat-checkbox>
          <mat-form-field>
            <mat-label>UtmSource</mat-label>
            <mat-select [disabled]="!utmSourceActive" [(ngModel)]="selectedUtmSources" [multiple]="true">
              <mat-option *ngFor="let utmSource of utmSources" [value]="utmSource">{{utmSource}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <loading-button
              matTooltip="Aktualisieren"
              buttonClass="mat-primary"
              [loadingBool]="loading"
              (clickEvent)="loadData()">
        <i class="mdi mdi-refresh dark filter-color"></i>
      </loading-button>

      <mat-form-field>
        <mat-label>Angezeigte Spalten</mat-label>
        <mat-select [(ngModel)]="displayedColumns" [multiple]="true">
          <mat-option *ngFor="let option of allColumns" [value]="option.value">{{option.display}}</mat-option>
        </mat-select>
      </mat-form-field>
      <table-download-button color="primary"
                             excelName="Anmeldungen.xlsx"
                             [buttonClass]="'main-action-icon'"
                             [matTable]="applicationStatisticsTable">
      </table-download-button>
    </div>


  </div>
</div>

<sub>Spalten mit * werden in einem Tooltip erklärt (mit Maus hovern)</sub>

<table mat-table [dataSource]="stats" class="max-table" #applicationStatisticsTable>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Tag</th>
    <td mat-cell *matCellDef="let element">

      <span *ngIf="element.date != null">{{element.date | date: 'dd.MM.'}}</span>
      <span *ngIf="element.date == null">Summe</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="numTalentCreations">
    <th mat-header-cell *matHeaderCellDef>Anmeldungen</th>
    <td mat-cell *matCellDef="let element"> {{element.numTalentCreations}}</td>
  </ng-container>

  <ng-container matColumnDef="numInvites">
    <th mat-header-cell *matHeaderCellDef>Davon Beratereinladungen</th>
    <td mat-cell *matCellDef="let element"> {{element.numInvitesByCoach}}</td>
  </ng-container>

  <ng-container matColumnDef="numTalentLanguageOk">
    <th mat-header-cell *matHeaderCellDef>Sprache OK</th>
    <td mat-cell *matCellDef="let element"> {{element.numTalentLanguageOk}}</td>
  </ng-container>

  <ng-container matColumnDef="numTalentLanguageNotOk">
    <th mat-header-cell *matHeaderCellDef>Sprache nicht OK</th>
    <td mat-cell *matCellDef="let element"> {{element.numTalentLanguageNotOk}}</td>
  </ng-container>

  <ng-container matColumnDef="numTalentLanguageUnknown">
    <th mat-header-cell *matHeaderCellDef>Sprache Unbekannt</th>
    <td mat-cell *matCellDef="let element"> {{element.numTalentLanguageUnknown}}</td>
  </ng-container>

  <ng-container matColumnDef="numOnboardingCompleted">
    <th mat-header-cell *matHeaderCellDef>OBC</th>
    <td mat-cell *matCellDef="let element"> {{element.numOnboardingCompleted}}</td>
  </ng-container>

  <ng-container matColumnDef="onboardingCompletedQuota">
    <th mat-header-cell *matHeaderCellDef matTooltip="Anz. OBC / Anz. Anmeldungen">OBC Quote*</th>
    <td mat-cell *matCellDef="let element">
      {{getQuota(element.onboardingCompletedQuota * 100) | number:'1.0-2'}}%
    </td>
  </ng-container>

  <ng-container matColumnDef="averageTalentQualityScore">
    <th mat-header-cell *matHeaderCellDef >Ø Qualityscore</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element?.averageTalentQualityScore">{{getAverageQualityScore(element?.averageTalentQualityScore) | talentQualityScore}} ({{element?.averageTalentQualityScore?.toFixed(2)}})</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="qualitytiers">
    <th mat-header-cell *matHeaderCellDef matTooltip="S-Tier: 5p | A-Tier: 4p | B-Tier: 3p | C-Tier: 2p | D-Tier: 1p | E-Tier: n.a.">
      S/A/B/C/D/E*
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.sumSTierTalents}}/{{element.sumATierTalents}}/{{element.sumBTierTalents}}/{{element.sumCTierTalents}}/{{element.sumDTierTalents}}/{{element.sumETierTalents}}
    </td>
  </ng-container>

  <ng-container matColumnDef="numCvUploads">
    <th mat-header-cell *matHeaderCellDef>CV Uploads</th>
    <td mat-cell *matCellDef="let element"> {{element.numCvUploads}}</td>
  </ng-container>

  <ng-container matColumnDef="cvUploadQuota">
    <th mat-header-cell *matHeaderCellDef matTooltip="Anz. CV Upload / Anz. OBC">CV Upload Quote*
    </th>
    <td mat-cell *matCellDef="let element">
      {{getQuota(element.cvUploadQuota * 100) | number:'1.0-2'}}%
    </td>
  </ng-container>

  <ng-container matColumnDef="numCoachRequests">
    <th mat-header-cell *matHeaderCellDef>Coach angefordert</th>
    <td mat-cell *matCellDef="let element"> {{element.numCoachRequests}}</td>
  </ng-container>

  <ng-container matColumnDef="coachRequestQuota">
    <th mat-header-cell *matHeaderCellDef matTooltip="Anz. Coach angefordert / Anz. Anmeldungen">Coach angefordert Quote*</th>
    <td mat-cell *matCellDef="let element"> {{element.coachRequestQuota * 100 | number: '1.0-2'}}%</td>
  </ng-container>

  <ng-container matColumnDef="numCoachAssigned">
    <th mat-header-cell *matHeaderCellDef>Coach erhalten</th>
    <td mat-cell *matCellDef="let element"> {{element.numCoachAssigned}}</td>
  </ng-container>


  <ng-container matColumnDef="primeStates">
    <th mat-header-cell *matHeaderCellDef>PrimeLevel 3/2/1/ None</th>
    <td mat-cell *matCellDef="let element">
      {{element.sumPrimeLevel2Talents}}/{{element.sumPrimeLevel1Talents}}/{{element.sumPrimeLevel0Talents}}/  {{element.sumPrimeNonLevelTalents}}
    </td>
  </ng-container>

  <ng-container matColumnDef="numApplicants">
    <th mat-header-cell *matHeaderCellDef>Bewerber</th>
    <td mat-cell *matCellDef="let element"> {{element.numApplicants}}</td>
  </ng-container>

  <ng-container matColumnDef="applicantsQuota">
    <th mat-header-cell *matHeaderCellDef matTooltip="Anz. Bewerber / Anz. CV Uploads">Bewerberquote*
    </th>
    <td mat-cell *matCellDef="let element">
      {{getQuota(element.applicantsQuota * 100) | number:'1.0-2'}}%
    </td>
  </ng-container>

  <ng-container matColumnDef="numApplications">
    <th mat-header-cell *matHeaderCellDef>Bewerbungen</th>
    <td mat-cell *matCellDef="let element"> {{element.totalApplications}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
