import { Component, OnInit } from '@angular/core';
import {AdminEventData, LabelData} from "../../generated/data";
import {AdminEventResource} from "../../generated/resources";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {FileService} from "../../services/file.service";
import {UpdateEventFilterSheetComponent} from "../update-event-filter-sheet/update-event-filter-sheet.component";
import {
  CreateOrUpdateEventSheetComponent
} from "../create-or-update-event-sheet/create-or-update-event-sheet.component";
import {UtilityService} from "../../utils/utility.service";
import {DatePipe} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EditEventContentSheetComponent} from "../edit-event-content-sheet/edit-event-content-sheet.component";
import {
  EditEventSubjectTargetingSheetComponent
} from "../edit-event-subject-targeting-sheet/edit-event-subject-targeting-sheet.component";

@Component({
  selector: 'app-perfect-match-events',
  templateUrl: './perfect-match-events.component.html',
  styleUrls: ['./perfect-match-events.component.scss']
})
export class PerfectMatchEventsComponent implements OnInit {

  syncPerfectMatchEvents: boolean = false
  displayedColumns = [
    'id',
    'title',
    'start',
    'showEvent',
    'companyName',
    'actions',
  ];

  data: AdminEventData[] =[];

  constructor(
      private adminEventResource: AdminEventResource,
      private dialogService: SideSheetService,
      private fileService: FileService,
      private matSnackbar: MatSnackBar,
      private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getEvents()
  }

  getEvents() {
    this.adminEventResource.getPerfectMatchEvents().then(result => {
      this.data = result
    })
  }

  getCompaniesString(data: LabelData[]): string {
    return data?.map(i => i.name)?.join(",")
  }

  downloadRegistrationsExcel(eventId) {
    this.fileService.downloadTalentEventRegistrationsExcel(eventId)
  }

  openEditEventFilterDialog(data: AdminEventData) {
    let cref = this.dialogService.openOverlay(UpdateEventFilterSheetComponent)
    cref.instance.eventId = data.id;
    cref.instance.eventType = data.type;
  }

  openEditEventSubjectTargetingSheetComponent(data: AdminEventData) {
    let cref = this.dialogService.openOverlay(EditEventSubjectTargetingSheetComponent)
    cref.instance.eventId = data.id;
    cref.instance.eventType = data.type;
  }

  openEditEventContentSheet(data: AdminEventData) {
    let cref = this.dialogService.openOverlay(EditEventContentSheetComponent)
    cref.instance.data = data;

    cref.instance.sideSheetRef.sheetClosed.subscribe((result) => {
      if (result) this.getEvents()
    })
  }

  openCreateOrUpdateDialog(data?: AdminEventData) {
    let cref = this.dialogService.openOverlay(CreateOrUpdateEventSheetComponent);
    cref.instance.data = data;

    cref.instance.sideSheetRef.sheetClosed.subscribe((result) => {
      if (result) this.getEvents()
    })
  }

  updatePerfectMatchEvents() {
    this.syncPerfectMatchEvents = true
    this.adminEventResource.updatePerfectMatchEvents().then(result => {
      this.syncPerfectMatchEvents = false
      let message = "Keine neuen Events! Talentagent ist up to date!"
      if (result?.length) {
        message = "Neue Events Syncronisiert: \n\n"
        result.forEach(event => message = message + event.address.cityName + " - " + this.datePipe.transform(event.start, "dd.MM HH:mm" + "\n"))
      }
      this.matSnackbar.open(message, null, {
        panelClass: "success-snackbar",
        duration: 7000
      })
    })
  }

}