import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubjectData} from "../../../generated/data";
import {StudyInfoResource} from '../../../generated/resources';
import {pushOrRemove} from "../../inno-utils/inno-utils.module";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector: 'searchable-study-subject-dropdown',
  templateUrl: './searchable-study-subject-dropdown.component.html',
  styleUrls: ['./searchable-study-subject-dropdown.component.css']
})
export class SearchableStudySubjectDropdownComponent implements OnInit {

  @Input() model: any;
  @Input() formControl: AbstractControl;
  @Input() disabled: boolean = false;

  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  studySubjects: SubjectData[] = [];

  constructor(
    public studyInfoResource: StudyInfoResource
  ) {
  }

  ngOnInit() {
    if (!this.formControl) {
      this.formControl = new FormControl()
      this.formControl.setValue(this.model)
    }

    this.formControl.valueChanges.subscribe(value => {
      this.modelChange.emit(value)
    })

    this.loadStudyFields();
  }


  add(id) {
    this.model.push(id);
    this.loadStudyFields();
    this.modelChange.emit(this.model)
  }

  loadStudyFields() {
    this.studyInfoResource.getSubjectsById({
      ids: this.model
    }).then(studySubjects => {
      this.studySubjects = studySubjects
      ;
    })
  }

  remove(field) {
    pushOrRemove(this.studySubjects, field);
    pushOrRemove(this.model, field.id);
    this.modelChange.emit(this.model)
  }
}
