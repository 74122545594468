import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountResource, AdminEmploymentPositionResource, AdminRecruiterResource} from "../../../generated/resources";
import {AdminEmploymentPositionData, DisplayRecruiterData, RecruiterData} from "../../../generated/data";
import {EmailAddressAvailabilityValidatorService} from "../../../services/email-address-availability-validator.service";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {UtilityService} from "../../../utils/utility.service";

@Component({
  selector: 'app-edit-recruiter-dialog',
  templateUrl: './edit-recruiter-dialog.component.html',
  styleUrls: ['./edit-recruiter-dialog.component.scss']
})
export class EditRecruiterDialogComponent implements OnInit {

  recruiterEditForm: FormGroup;
  notificationForm: FormGroup;

  submitted: boolean = false;
  loading: boolean = false;
  emailIsFree: boolean = true;
  recruiterData: DisplayRecruiterData = <DisplayRecruiterData>{};

  positions: AdminEmploymentPositionData[] = [];
  loadingPositions: boolean = false;

  assignedPositions: number[] = []
  companyId: number = 0;

  constructor(
    private accountResource: AccountResource,
    private adminRecruiterResource: AdminRecruiterResource,
    private emailAddressAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
    private formBuilder: FormBuilder,
    private sideSheetService: SideSheetService,
    private adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private sideSheetRef: SideSheetRef,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.recruiterEditForm = this.formBuilder.group({
      firstName: [this.recruiterData.firstName, Validators.required],
      lastName: [this.recruiterData.lastName, Validators.required],
      gender: [this.recruiterData.gender],
      phone: [this.recruiterData.phoneNumber],
      email: [this.recruiterData.emailAddress, [Validators.required, Validators.email]],
      position: [this.recruiterData.position]
    });

    this.notificationForm = this.formBuilder.group({
      emailNotification: [this.recruiterData.emailNotification],
      smsNotification: [this.recruiterData.smsNotification]
    });

    this.loadPositionsForCompany()
  }

  get controls() {
    return this.recruiterEditForm.controls;
  }

  loadPositionsForCompany(){
    this.loadingPositions = true
    this.adminEmploymentPositionResource.getAllEmploymentPositions(this.companyId).then(positions => {
      this.positions = positions;
      this.adminRecruiterResource.getRecruiterPositions(this.recruiterData.id).then(assignedPositions => {
        this.assignedPositions = assignedPositions.map(p => p.id)
        this.loadingPositions = false;
      })
    })
  }

  onSubmit() {
    this.submitted = true;

    if (this.recruiterEditForm.invalid) {
      return
    }

    this.loading = true;

          const createdRecruiterData: RecruiterData = {
            firstName: this.controls.firstName.value,
            lastName: this.controls.lastName.value,
            gender: this.controls.gender.value,
            phoneNumber: this.controls.phone.value,
            emailAddress: this.controls.email.value,
            position: this.controls.position.value,
            emailNotification: this.notificationForm.controls.emailNotification.value,
            smsNotification: this.notificationForm.controls.smsNotification.value,
            privacyOptIn: null
          };

          this.adminRecruiterResource.editRecruiter(createdRecruiterData, this.recruiterData.id)
            .then(() => {
              this.assignPositionsToRecruiter(this.recruiterData.id)
            })
  }

  updateAssignedPositions(positionId: number){
    if(this.assignedPositions.includes(positionId)){
      this.assignedPositions = this.assignedPositions.filter(p => p !== positionId)
    } else {
      this.assignedPositions.push(positionId)
    }
  }

  assignPositionsToRecruiter(recruiterId: number){
    this.adminRecruiterResource
        .assignEmploymentPositionsToRecruiter(recruiterId, {positionIds: this.assignedPositions})
        .then(() => {
          this.utilityService.showSnackBar("Positionen erfolgreich zugewiesen.");
          this.sideSheetRef.sheetClosed.next();
        })
  }

  toggleAllAssignedPositions(){
    if(this.assignedPositions.length === this.positions.length){
      this.assignedPositions = []
    } else {
      this.assignedPositions = this.positions.map(p => p.id)
    }
  }

  onCancel() {
    this.sideSheetRef.sheetClosed.next();
  }
}
