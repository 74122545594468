import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminEventData, EventFilterData, LocationOptionsData, LocationOptionsMode} from "../../generated/data";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AdminEventResource} from "../../generated/resources";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {
  PreviewTalentsForEventFilterSheetComponent
} from "../preview-talents-for-event-filter-sheet/preview-talents-for-event-filter-sheet.component";

@Component({
  selector: 'app-update-event-filter-sheet',
  templateUrl: './update-event-filter-sheet.component.html',
  styleUrls: ['./update-event-filter-sheet.component.scss']
})
export class UpdateEventFilterSheetComponent implements OnInit {

  updating: boolean = false
  loadingEventFilter: boolean = true
  eventId: number
  eventType: string
  data: EventFilterData
  form: FormGroup

  modes: LocationOptionsMode[] = ["Worldwide", "Nationwide", "SpecificCities"];


  constructor(
      private sideSheetRef: SideSheetRef,
      private fb: FormBuilder,
      private adminEventResource: AdminEventResource,
      private sideSheetService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.getFilterForEvent()
  }


  getFilterForEvent() {
    this.loadingEventFilter = true
    this.adminEventResource.getEventFilterForEvent(this.eventId).then(result => {
      this.data = result
      this.initForm()
      this.loadingEventFilter = false
    })
  }

  updateFilter() {
    this.updating = true
    let data: EventFilterData = {
      locationRadius: this.form.controls.locationRadius.value,
      registrationMin: this.form.controls.regFrom.value,
      registrationMax: this.form.controls.regTo.value,
      studyEndUntil: this.form.controls.studyEndUntil.value,
      subjectGroupIds: this.form.controls.subjectGroupIds.value,
      subjectIds: this.form.controls.subjectIds.value,
      activeFilter: this.form.controls.active.value,
      location: <LocationOptionsData>{
        cityIds: this.form.controls.cityIds.value,
        mode: this.form.controls.mode.value,
        federalStateIds: []
      }
    }

    this.adminEventResource.updateEventFilter(data, this.eventId).then(() => {
      this.updating = false
    })
  }

  openShowTalentsForFilterSheet() {
    let comp = this.sideSheetService.openOverlay(PreviewTalentsForEventFilterSheetComponent)
    comp.instance.eventId = this.eventId
  }

  initForm() {
    this.form = this.fb.group({
      cityIds: [this.data?.location?.cityIds],
      regFrom: [this.data?.registrationMin],
      regTo: [this.data?.registrationMax],
      mode: [this.data?.location?.mode],
      locationRadius:[ this.data?.locationRadius],
      subjectIds: [this.data?.subjectIds],
      subjectGroupIds: [this.data?.subjectGroupIds],
      studyEndUntil: [this.data?.studyEndUntil],
      active: [this.data?.activeFilter],
    })
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

}
