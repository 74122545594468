import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'joinToString'
})
export class JoinToStringPipe implements PipeTransform {

  transform(values: [], length?: number): string {

    let newLength = Math.max(length, values.length);

    let slicedList = values.slice(0, newLength - 1);

    let suffix = "";

    if (values.length > newLength) {
      suffix = "..."
    }

    return slicedList.join(', ') + suffix;
  }
}
