import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrolledToBottomDirective} from "./scrolled-to-bottom/scrolled-to-bottom.directive";
import {MediaService} from "./media.service";
import {InvoicePipe} from "../pipes/invoice.pipe";

@NgModule({
    declarations: [ScrolledToBottomDirective],
  providers: [
    MediaService
  ],
  imports: [
    CommonModule
  ],
    exports: [
        ScrolledToBottomDirective,
    ]
})
export class InnoUtilsModule {


}

export function arrayContains(array: any[], value: any) {
  return array.indexOf(value) > -1
}

export function pushOrRemove(array: any[], value: any) {
  if (arrayContains(array, value)) {
    array.splice(array.indexOf(value), 1)
  } else {
    array.push(value)
  }
}
