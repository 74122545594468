<div fxLayout="row" fxLayoutAlign="space-between">
  <div mat-dialog-title>Datenschutzerklärung</div>
  <button mat-icon-button matTooltip="Muster-Datenschutzerklärung herunterladen" (click)="showPrivacyAgreement()">
    <i class="mdi mdi-download mdi-18px"></i>
  </button>
</div>

<div mat-dialog-content fxLayout="column" class="edit-block-wrapper">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <mat-checkbox [formControl]="showDisclaimerControl" color="primary" style="color: grey">
      Aktiv
    </mat-checkbox>
    <span>Gültig seit: {{privacyDisclaimerData?.validSince | date:'dd.MM.yyyy HH:mm:ss'}}</span>
  </div>

  <mat-form-field>
    <mat-label>Name des Kooperationspartners</mat-label>
    <input matInput [formControl]="disclaimerPartnerNameControl">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Link zur Datenschutzerklärung (mit http:// oder https://)</mat-label>
    <input matInput [formControl]="disclaimerPartnerPrivacyUrlControl">
  </mat-form-field>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <loading-button mat-button
                  color="primary"
                  [loadingBool]="loading"
                  [raised]="false"
                  [disabledCondition]="disclaimerPartnerPrivacyUrlControl.invalid || disclaimerPartnerNameControl.invalid"
                  (click)="updatePrivacyDisclaimer()">
    Speichern
  </loading-button>
</div>
