import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'textIfEmpty'})
export class TextIfEmptyPipe implements PipeTransform {
  transform(value, defaultText) {

    if (Array.isArray(value)) {

      if ((value as Array<string>).length == 0) {
        return defaultText
      } else {
        return value.join(', ')
      }

    } else if (value instanceof String) {

      if (value.length == 0) {
        return defaultText
      } else {
        return value
      }

    } else {
      return value
    }
  }
}
