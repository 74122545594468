<div style="background-color: white; min-width: 1030px; margin: 20px" class="mat-elevation-z6">

  <div class="standard-container" fxLayout="column">


    <div class="button-bar" fxLayout="column" fxLayoutAlign="start start">

      <div style="width: 100%" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px">

            <div class="filter-checkbox"
                 (click)="considerCVCheckbox.setValue(!considerCVCheckbox.value)"
                 [ngClass]="{'selected': considerCVCheckbox.value == true, 'unselected': considerCVCheckbox.value == false }">
              <mat-checkbox [disabled]="true" [formControl]="considerCVCheckbox">CV vorhanden</mat-checkbox>
            </div>

            <div class="filter-checkbox"
                 (click)="considerOBCCheckbox.setValue(!considerOBCCheckbox.value)"
                 [ngClass]="{'selected': considerOBCCheckbox.value == true, 'unselected': considerOBCCheckbox.value == false }">
              <mat-checkbox [disabled]="true" [formControl]="considerOBCCheckbox">Onboarding abgeschlossen</mat-checkbox>
            </div>

            <div class="filter-checkbox"
                 (click)="considerPrimeCheckbox.setValue(!considerPrimeCheckbox.value)"
                 [ngClass]="{'selected': considerPrimeCheckbox.value == true, 'unselected': considerPrimeCheckbox.value == false }">
              <mat-checkbox [disabled]="true" [formControl]="considerPrimeCheckbox">Ist Prime</mat-checkbox>
            </div>

            <div class="filter-checkbox"
                 (click)="considerIsUncheckedCheckbox.setValue(!considerIsUncheckedCheckbox.value)"
                 [ngClass]="{'selected': considerIsUncheckedCheckbox.value == true, 'unselected': considerIsUncheckedCheckbox.value == false }">
              <mat-checkbox [disabled]="true" [formControl]="considerIsUncheckedCheckbox">Ungecheckt für Empfehlungen</mat-checkbox>
            </div>

              <div class="filter-checkbox" style="position: relative; top: 4px">
                  <mat-form-field appearance="fill">
                  <mat-label>Talent Nationalität</mat-label>
                      <mat-select [multiple]="true" [formControl]="selectedTalentOrigins">
                          <mat-option *ngFor="let o of allOrigins" [value]="o">{{o}}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>

            <div class="filter-checkbox"
                 (click)="considerCoachDatesCheckbox.setValue(!considerCoachDatesCheckbox.value)"
                 [ngClass]="{'selected': considerCoachDatesCheckbox.value == true, 'unselected': considerCoachDatesCheckbox.value == false }">
              <mat-checkbox [disabled]="true" [formControl]="considerCoachDatesCheckbox">Coachdaten vervollständigen</mat-checkbox>
            </div>

            <loading-button matTooltip="gefilterte Stellen laden"
                            buttonClass="mat-primary"
                            [loadingBool]="loading"
                            (clickEvent)="loadData()">
              <i class="mdi mdi-refresh main-action-icon filter-color"></i>
            </loading-button>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <mat-form-field appearance="outline" style="position: relative; top: 12px;">
              <input matInput [matDatepicker]="pickerFrom" placeholder="StudyEnd von"
                     [(ngModel)]="studyEndDateFrom" name="dateFrom">
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" style="position: relative; top: 12px;">
              <input matInput [matDatepicker]="pickerTo" placeholder="StudyEnd bis"
                     [(ngModel)]="studyEndDateTo" name="dateTo">
              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
          <table-download-button color="primary" [buttonClass]="'main-action-icon'" excelName="Talente.xlsx" [matTable]="table"></table-download-button>
        </div>
      </div>
    </div>

    <loading-spinner [loadingBool]="loading" >
      <table *ngIf="!recommendationTalenIdSelected" mat-table [dataSource]="dataSource" matSort matSortActive="creationDate" matSortDisableClear
             matSortDirection="desc" #dataTable>

        <ng-container matColumnDef="unchecked">
          <th mat-header-cell *matHeaderCellDef>unchecked</th>
          <td mat-cell *matCellDef="let row">
          <div style="width: 10px; height: 10px; border-radius: 10px; color: dodgerblue" *ngIf="!row.wasChecked"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let row"> {{row.id}}&nbsp;&nbsp;</td>
        </ng-container>

        <!--Hidden because the Quality Score can not be computed correctly at the moment!-->
        <!--        <ng-container matColumnDef="qualityScore">-->
        <!--          <th mat-header-cell *matHeaderCellDef>Qualität</th>-->
        <!--          <td mat-cell *matCellDef="let row" style="text-align: center"> {{row.qualityScore | talentQualityScore}}</td>-->
        <!--        </ng-container>-->

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row" (click)="copyToClipboard(row.lastName)"
              class="copy"> {{row.firstName | initial}} {{row.lastName | defaultIfNull}}</td>
        </ng-container>

        <ng-container matColumnDef="lastSubjectTitle">
          <th mat-header-cell *matHeaderCellDef>Studienfach</th>
          <td mat-cell *matCellDef="let row" (click)="copyToClipboard(row.lastSubjectTitle)"
              class="copy">{{row.lastSubjectTitle}}</td>
        </ng-container>

        <ng-container matColumnDef="cv">
          <th mat-header-cell *matHeaderCellDef>CV</th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.cvDate" matTooltip="Hochgeladen um {{row.cvDate | date: 'HH:mm'}} Uhr">
              {{row.cvDate | date: 'dd.MM.yy'}}
            </div>
            <div *ngIf="!row.cvDate">n.a.</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="hasDossier">
          <th mat-header-cell *matHeaderCellDef>Dossier</th>
          <td mat-cell *matCellDef="let row">
            <div fxLayoutAlign="center" style="height: 100%">
              <mat-checkbox [ngModel]="row.hasDossier"
                            (ngModelChange)="row.hasDossier=$event;talentService.setDossierStatus($event, row.id)"></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="studyEndDate">
          <th mat-header-cell *matHeaderCellDef>StudyEnd</th>
          <td mat-cell *matCellDef="let row">
            <div fxLayoutAlign="center" style="height: 100%">
             {{row.studyEndDate| date: 'dd.MM.yyyy'}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="numPrimePositions">
          <th mat-header-cell *matHeaderCellDef class="center">Primestellen</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            {{row.matchingPrimePositionCount}}
          </td>
        </ng-container>

        <ng-container matColumnDef="onboardingComplete">
          <th mat-header-cell *matHeaderCellDef class="center">Onboarding</th>
          <td mat-cell *matCellDef="let row">
            <div fxLayoutAlign="center" style="height: 100%"
                 matTooltip="{{row | onboardingComplete }} um {{row.creationDate | date: 'HH:mm'}}">
              <i class="mdi mdi-check space green" *ngIf="row.onboardingComplete" fxLayoutAlign="center center"></i>
              <i class="mdi mdi-close space red" *ngIf="!row.onboardingComplete" fxLayoutAlign="center center"></i>
              {{row.creationDate | date: 'dd.MM.yy'}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="utmCampaign">
          <th mat-header-cell *matHeaderCellDef>UTM Kampagne</th>
          <td mat-cell *matCellDef="let row">{{row.utmCampaign != null ? row.utmCampaign : 'n.a'}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Aktionen</th>
          <td mat-cell *matCellDef="let row">
            <div class="table-button" fxLayout="row" fxLayoutAlign="end center">

              <button mat-button mat-icon-button color="warn" *ngIf="row.needsCoachingCriteriaUpdate"
                      matTooltip="Coachdaten vervollständigen"
                      (click)="showCompleteCoachDataDialog(row)">
                <i class="mdi mdi-alert-octagram dark" style="color: #b60000" ></i>
              </button>


              <button mat-button mat-icon-button color="primary"
                      matTooltip="Notiz"
                      (click)="showTalentNoteDialog(row)">
                <i class="mdi mdi-pencil dark" [ngClass]="{'blue' : row.hasNote}"></i>
              </button>

              <button mat-button mat-icon-button color="primary"
                      matTooltip="Detailliertes Profil anzeigen"
                      (click)="openTalentProfile(row.id)">
                <i class="mdi mdi-account dark"></i>
              </button>
              <button mat-button mat-icon-button color="primary"
                      [disabled]="downloading || row.cvFileName == null || row.cvFileName == ''"
                      matTooltip="Lebenslauf herunterladen"
                      (click)="downloadCV(row.id)">
                <i class="mdi mdi-download dark"></i>
              </button>

              <button mat-icon-button color="primary" matTooltip="Empfehlungsmodus"
                      (click)="selectTalentForRecommendation(row)">
                <i class="mdi mdi-target main-action-icon"></i>
              </button>

              <button color="accent" matTooltip="Checken für Empfehlungen" mat-icon-button [disabled]="row.wasChecked" (click)="checkTalent(row.id)">
                <i class="mdi mdi-account-check m-right10"></i>
              </button>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


      <div class="recommendation-mode-wrapper" *ngIf="recommendationTalenIdSelected">
        <button mat-button (click)="closeTalentDetailsMode()" *ngIf="talentDetailsMode">
          <i class="mdi mdi-arrow-left m-right10"></i>
          zurück zur Übersicht
        </button>

        <div  fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <div class="talent-profile-wrapper" *ngIf="talentDetailsMode">
            <loading-spinner [loadingBool]="loadingRecommendationTalent">
              <div class="talent-profile" *ngIf="talentDataRecommendation" fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <div style="font-weight: bold">{{talentDataRecommendation.firstName}} {{talentDataRecommendation.lastName}}</div>
                    <div>Empfehlungen: {{talentDataRecommendation.recommendedCount}}</div>
                    <button mat-button mat-icon-button color="primary"
                            matTooltip="Detailiertes Profil anzeigen"
                            (click)="openTalentProfile(talentDataRecommendation.id)">
                      <i class="mdi mdi-account dark"></i>
                    </button>
                  </div>

                  <button color="accent" mat-flat-button [disabled]="talentDataRecommendation.wasChecked" (click)="checkTalent(talentDataRecommendation.id); talentDataRecommendation.wasChecked = true">
                    <i class="mdi mdi-account-check m-right10"></i>
                    <span *ngIf="!talentDataRecommendation.wasChecked">Talent checken</span>
                    <span *ngIf="talentDataRecommendation.wasChecked">Talent gecheckt</span>
                  </button>
                </div>

                <div class="width-100" fxLayout="row" fxLayoutGap="15px" style="border-bottom: 1px solid #d2d2d2;
    padding-bottom: 10px;">
                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Studienfach</span>
                    <span>{{mostRecentStudy?.subjectName}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Universität</span>
                    <span>{{mostRecentStudy?.universityName}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Title</span>
                    <span>{{mostRecentStudy?.degreeType}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Abschlussdatum</span>
                    <span>{{mostRecentStudy?.endDate | date: 'mm.yyy'}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Note</span>
                    <span>{{mostRecentStudy?.grade}}</span>
                  </div>
                </div>

                <div class="width-100" fxLayout="row" fxLayoutGap="15px">
                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Standort</span>
                    <span>{{talentDataRecommendation?.locationPreference?.talentLocation?.name}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Suchmodus</span>
                    <span>{{talentDataRecommendation?.locationPreference?.searchMode}}</span>
                  </div>

                  <div fxLayout="column" style="min-width: 200px">
                    <span class="value-read-only" style="min-width: 200px">In den Städten</span>
                    <div fxLayout="row wrap">
                      <span *ngFor="let city of talentDataRecommendation.locationPreference.specificCities">{{city.name}},&nbsp;</span>
                    </div>
                  </div>
                </div>

                <div>
                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Präferierte Bereiche</span>
                    <span style="color: #bfbfbf" *ngIf="!talentProfessionGroupPreferences?.length">- Keine -</span>
                    <span>{{talentProfessionGroupPreferences?.join(', ')}} </span>
                  </div>
                </div>

                <div>
                  <div fxLayout="column" fxLayoutGap="5px">
                    <span class="value-read-only">Job Expectation</span>
                    <span style="color: #bfbfbf" *ngIf="!talentDataRecommendation?.jobExpectation">- Keine Angabe -</span>
                    <span *ngIf="talentDataRecommendation?.jobExpectation">{{talentDataRecommendation.jobExpectation}}</span>
                  </div>
                </div>
                <p *ngIf="!cvFile ||cvFile.size == 0">Kein Lebenslauf vorhanden</p>

                <ngx-extended-pdf-viewer
                        *ngIf="cvFile && cvFile.size != 0"
                        [src]="cvFile"
                        [height]="'700px'"
                        [useBrowserLocale]="true">
                </ngx-extended-pdf-viewer>

              </div>
            </loading-spinner>
          </div>

          <div class="position-list-wrapper" *ngIf="talentDetailsMode">
            <div class="position-list">
              <div class="position-actions" fxLayout="row" fxLayoutAlign="space-between center">

                <div class="width-100" fxLayout="row" fxLayoutAlign="space-between center"  *ngIf="positionDetailsMode" >
                  <button mat-button (click)="closePositionDetailsMode()">
                    <i class="mdi mdi-arrow-left m-right10"></i>
                    zurück zur Stellenliste
                  </button>

                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button mat-icon-button [disabled]="!talentId || !positionId"
                            (click)="openMatch(talentId, positionId)">
                      <i class="mdi mdi-account-search mdi-24px"></i>
                    </button>
                    <button mat-icon-button (click)="openPositionDetailsDialog(employmentPositionData.id)"
                            matTooltip="Stellendetails">
                      <i style="font-size: 20px" class="mdi mdi-eye"></i></button>
                    <button mat-icon-button (click)="openRecommendPositionDialog(employmentPositionData)"
                            matTooltip="Stelle Empfehlen">
                      <i style="font-size: 20px" class="mdi mdi-email"></i></button>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px" *ngIf="!positionDetailsMode">
                  <mat-form-field>
                    <input [disabled]="!talentPositionRelationDataSource?.data?.length" matInput (keyup)="applyFilter(talentPositionRelationDataSource, $event.target.value)" placeholder="Suche">
                  </mat-form-field>

                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">

                  <div class="filter-checkbox"
                       (click)="onlySuitableForTalent = !onlySuitableForTalent"
                       [ngClass]="{'selected': onlySuitableForTalent, 'unselected': !onlySuitableForTalent }">
                    <mat-checkbox [disabled]="true" [(ngModel)]="onlySuitableForTalent">nur Primematches (mit Campusjäger Stellen)</mat-checkbox>
                  </div>

                  <div class="filter-checkbox"
                       (click)="isMatch = !isMatch"
                       [ngClass]="{'selected': isMatch, 'unselected': !isMatch }">
                    <mat-checkbox [disabled]="true" [(ngModel)]="isMatch">Ist ein Match</mat-checkbox>
                  </div>

                  <mat-form-field appearance="standard">
                    <mat-label>Status</mat-label>
                  <mat-select [(ngModel)]="relationState">
                    <mat-option [value]="null">Alle</mat-option>
                    <mat-option value="New">Neu</mat-option>
                    <mat-option value="Seen">Gesehen</mat-option>
                    <mat-option value="Clicked">Geklickt</mat-option>
                    <mat-option value="Bookmarked">Gemerkt</mat-option>
                    <mat-option value="CTAClicked">CTA Klicked</mat-option>
                    <mat-option value="Rejected">Abgelehnt</mat-option>
                    <mat-option value="Withdrawn">Zurückgezogen</mat-option>
                    <mat-option value="Accepted">Angenommen</mat-option>
                  </mat-select>
                  </mat-form-field>

                  <loading-button matTooltip="gefilterte Stellen laden"
                                  buttonClass="mat-primary"
                                  [loadingBool]="loading"
                                  (clickEvent)="getPrimePositions(recommendationSelectedTalentId)">
                    <i class="mdi mdi-refresh main-action-icon filter-color"></i>
                  </loading-button>
                  </div>
                </div>
              </div>

              <loading-spinner [loadingBool]="loadingPosition">
                <table mat-table [dataSource]="talentPositionRelationDataSource" matSort class="width-100" *ngIf="!positionDetailsMode">

                  <ng-container matColumnDef="primefilter">
                    <th mat-header-cell *matHeaderCellDef>Primefilter-Match</th>
                    <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                      <i class="mdi mdi-check space green" *ngIf="element.isPrimeFilterMatch" fxLayoutAlign="center center"></i>
                      <i class="mdi mdi-close space red" *ngIf="!element.isPrimeFilterMatch" fxLayoutAlign="center center"></i>
                  </ng-container>

                  <ng-container matColumnDef="locationSuitable">
                    <th mat-header-cell *matHeaderCellDef>Standort passt</th>
                    <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                      <i class="mdi mdi-check space green" *ngIf="element.locationSuitable" fxLayoutAlign="center center"></i>
                  </ng-container>

                  <!-- Position Column -->
                  <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> Stelle</th>
                    <td mat-cell *matCellDef="let element"> {{element.position.title}} </td>
                  </ng-container>

                  <!-- Company Column -->
                  <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef> Unternehmen</th>
                    <td mat-cell *matCellDef="let element">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        {{element.position.customerCompany.name}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef> Matching-Score</th>
                    <td mat-cell *matCellDef="let element">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <span *ngIf="element.matchingScore ">{{element.matchingScore}}%</span>
                        <span *ngIf="!element.matchingScore">n.a.</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.relationState | talentPositionRelationState}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center"></th>
                    <td mat-cell *matCellDef="let element" style="text-align: center">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <button mat-icon-button (click)="getPositionDetails(element)" matTooltip="Stellendetails">
                          <i style="font-size: 20px" class="mdi mdi-eye"></i></button>
                        <button mat-icon-button (click)="openRecommendPositionDialog(element.position)" matTooltip="Stelle Empfehlen" [disabled]="element.recommendationDate">
                          <i style="font-size: 20px" class="mdi mdi-email"></i></button>
                      </div>
                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumnsPrime"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsPrime;"></tr>
                </table>
                <div class="position-details-wrapper" *ngIf="positionDetailsMode">

                  <div fxLayout="column">
                    <h3 style="margin: 0">{{employmentPositionData?.title}}</h3>
                    <div class="font-size-11" [matTooltip]="profession?.groupTitle + ' / ' + profession?.subGroupTitle">{{profession?.title}} [{{profession.typeTitle}}]</div>
                    <h4 class="company-name">{{employmentPositionData?.hiringCompanyName}}</h4>
                  </div>

                    <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top5">
                      <div class="cities-wrapper w-100" *ngIf="positionCitiesData" fxLayout="row wrap">
                        <div class="chip" *ngFor="let city of positionCitiesData">{{city.name}}</div>
                      </div>
                    </div>

                  <div fxLayout="column" class="width-100 m-top20">
                    <span class="value-read-only">Stellendetails</span>
                    <div class="font-size-14" [innerHTML]="employmentPositionData?.positionDescription"></div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" style="border-bottom: 1px solid rgb(210, 210, 210);
    padding-bottom: 10px;">
                    <span class="value-read-only">Admin Notiz</span>
                    <div class="font-size-14">{{employmentPositionData?.adminNote? employmentPositionData?.adminNote : ' - '}}</div>
                  </div>

                  <div class="m-top10">Bewerberpersona</div>
                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.differencesToOthers">
                    <span class="value-read-only">differencesToOthers</span>
                    <div class="font-size-14">{{persona?.differencesToOthers}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.preferredStudySubjects">
                    <span class="value-read-only">preferredStudySubjects</span>
                    <div class="font-size-14">{{persona?.preferredStudySubjects}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.minimumGrade">
                    <span class="value-read-only">minimumGrade</span>
                    <div class="font-size-14">{{persona?.minimumGrade}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.minimumGraduation">
                    <span class="value-read-only">minimumGraduation</span>
                    <div class="font-size-14">{{persona?.minimumGraduation}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.blacklistedUniversities">
                    <span class="value-read-only">blacklistedUniversities</span>
                    <div class="font-size-14">{{persona?.blacklistedUniversities}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.workingExperience">
                    <span class="value-read-only">workingExperience</span>
                    <div class="font-size-14">{{persona?.workingExperience}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.requirements">
                    <span class="value-read-only">requirements</span>
                    <div class="font-size-14">{{persona?.requirements}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.requiredLanguageSkills">
                    <span class="value-read-only">requiredLanguageSkills</span>
                    <div class="font-size-14">{{persona?.requiredLanguageSkills}}</div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="5px" class="width-100 m-top20" *ngIf="persona?.perfectApplicant">
                    <span class="value-read-only">perfectApplicant</span>
                    <div class="font-size-14">{{persona?.perfectApplicant}}</div>
                  </div>

                </div>
              </loading-spinner>

            </div>
          </div>
        </div>

      </div>
    </loading-spinner>

    <mat-paginator *ngIf="!recommendationTalenIdSelected"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="true"
            [length]="totalSize"
            [hidePageSize]="false"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
      </mat-paginator>
  </div>
</div>
