import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AdminResource, AdminTalentProfileResource} from "../generated/resources";
import {JsonWrappedValue} from "../generated/data";
import {Observable} from "rxjs";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {TalentNoteDialogComponent} from "../talents/talent-note-dialog/talent-note-dialog.component";
import {FileService} from "./file.service";

@Injectable({
  providedIn: 'root'
})
export class TalentService {

  constructor(
    private dialog: MatDialog,
    private talentProfileResource: AdminTalentProfileResource,
    private http: HttpClient,
    private adminResource: AdminResource,
    private fileService: FileService,
  ) {
  }

  public setDossierStatus(hasDossier: boolean, talentId: number) {
    this.adminResource.setDossierStatus(<JsonWrappedValue<boolean>>{value: hasDossier}, talentId).then((result) => {
      hasDossier = result.value
    })
  }

  public showTalentNoteDialog(talentId: number): MatDialogRef<TalentNoteDialogComponent> {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      talentId: talentId
    };
    dialogConfig.panelClass = 'custom-dialog-container';

    return this.dialog.open(TalentNoteDialogComponent, dialogConfig);
  }

  public openCvInNewTab(talentId: number) {
    this.fileService.downloadCv(talentId).subscribe(res => {
      try {
        const b = new Blob([res], {type: 'application/pdf'});

        let fileUrl = URL.createObjectURL(b);
        window.open(fileUrl, '_blank')
      } catch (e) {
        alert("Konnte CV nicht öffnen, weil das Dateiformat nicht PDF ist, oder das Talent keinen CV hat.")
      }
    })
  }

  public downloadAndSaveCv(talentId: number): Observable<any> {
    return this.fileService.downloadCv(talentId);
  }
}
