import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[scrolledToBottom]'
})
export class ScrolledToBottomDirective implements OnInit {


  el: ElementRef;

  @Input() scrollOffset: number = 0;

  @Output() onScrolledToBottom: EventEmitter<any> = new EventEmitter<any>();

  constructor(el: ElementRef) {
    this.el = el
  }

  ngOnInit() {
    this.onScroll()
  }

  @HostListener('scroll', ['$event'])
  @HostListener('resize', ['$event'])
  @HostListener('DOMSubtreeModified', ['$event'])
  onScroll() {

    let element = this.el.nativeElement;//TODO this is necessary bc subtreemodified triggers before complete rendering of the Element. Could attempt to solve it with lifecycle hooks
    if (element.scrollHeight === 0) return;

    //If element scrolledToBottom or if content is not enough to fill parent
    if (element.scrollTop >= (element.scrollHeight - element.offsetHeight - this.scrollOffset) || element.scrollHeight < element.offsetHeight) {
      this.onScrolledToBottom.emit();
    }
  }
}
