import {CompanyPricingMode} from "./company-pricing-mode"
import {PositionContentSplitData} from "./position-content-split-data"
import {EmploymentCategory} from "./employment-category"
import {LocationOptionsData} from "./location-options-data"
import {EmploymentPositionOrigin} from "./employment-position-origin"
import {PositionPriority} from "./position-priority"
import {EmploymentPositionState} from "./employment-position-state"

export class AdminEmploymentPositionData {
    applicationRequirementsInfo: string | null = null;
    availableSlots: number = null;
    companyAlias: string | null = null;
    companyAllowGoogleJobPosting: boolean = null;
    companyPricingMode: CompanyPricingMode | null = null;
    contents: PositionContentSplitData[] = null;
    currentBookingId: number | null = null;
    customerCompanyId: number = null;
    draftForwarding: boolean = null;
    employmentCategory: EmploymentCategory = null;
    employmentPositionShortDescription: string | null = null;
    googleJobPosting: boolean = null;
    hiringCompanyId: number | null = null;
    hiringCompanyName: string | null = null;
    id: number | null = null;
    internTitle: string = null;
    isThirdPartyPosition: boolean = null;
    komboJobId: string | null = null;
    locations = new LocationOptionsData();
    origin: EmploymentPositionOrigin = null;
    prime: boolean = null;
    priority: PositionPriority | null = null;
    professionId: number | null = null;
    professionTitle: string | null = null;
    showCompany: boolean = null;
    startingDate: (Date | null) = null;
    state: EmploymentPositionState | null = null;
    webLink: string | null = null;
    weeklyHoursFrom: number | null = null;
    weeklyHoursTo: number | null = null;
    yearlySalaryFrom: number | null = null;
    yearlySalaryTo: number | null = null;
}