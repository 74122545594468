import { Component, OnInit } from '@angular/core';
import {AdminProfessionResource} from "../../generated/resources";
import {LabelData, ProfessionAreaData} from "../../generated/data";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {ProfessionAreaDetailsSheetComponent} from "./profession-area-details-sheet/profession-area-details-sheet.component";

@Component({
  selector: 'app-mapping-explorer',
  templateUrl: './mapping-explorer.component.html',
  styleUrls: ['./mapping-explorer.component.scss']
})
export class MappingExplorerComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {

  }
}
