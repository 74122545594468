<dialog-header [dialogHeadline]="'Angebot erstellen'"></dialog-header>

<div id="dialog-content-wrapper" class="layout-padding">

    <create-sales-proposal [showSelectCompany]="showSelectCompany" [companyId]="companyId" (valid)="validProposal = $event" (salesProposal)="proposal = $event" ></create-sales-proposal>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="false"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        [disabledCondition]="!validProposal"
                        (clickEvent)="createSalesProposal()">
            Angebot verschicken
        </loading-button>
    </div>
</div>
