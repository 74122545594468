import {Component, Input, OnInit} from '@angular/core';
import {AdminCompanyResource, AdminKomboResource, AdminTopJobResource} from "../../../generated/resources";
import {
    AdminEmploymentPositionDisplayData,
    AdminKomboIntegrationData,
    EmploymentPositionDisplayData,
    TopJobSlotData
} from "../../../generated/data";
import {
    PositionDetailsDialogComponent
} from "../../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {ImportAtsPositionsDialogComponent} from "../import-ats-positions-dialog/import-ats-positions-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UtilityService} from "../../../utils/utility.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../utils/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'company-ats',
    templateUrl: './company-ats.component.html',
    styleUrls: ['./company-ats.component.scss']
})
export class CompanyAtsComponent implements OnInit {

    @Input() companyId: number = 0

    magicLink: string = null
    errorMessage: string = null
    creatingLink = false
    loadingMagicLinkAndIntegrationData = true
    deletingIntegration = false

    integration: AdminKomboIntegrationData

    constructor(
        private adminKomboResource: AdminKomboResource,
        private dialogService: SideSheetService,
        private utilityService: UtilityService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.loadIntegration()
    }

    loadIntegration() {
        Promise.all([
            this.adminKomboResource.getMagicLinkForCompany(this.companyId).then(res => {
                this.errorMessage = res.errorMessage
                this.magicLink = res.link
            }),
            this.adminKomboResource.getIntegrationInfo(this.companyId).then(res => {
                this.integration = res
            })
        ]).then(() => {
            this.loadingMagicLinkAndIntegrationData = false
        })
    }


    createLink() {
        this.creatingLink = true
        this.adminKomboResource.createConnectionLink(this.companyId).then(res => {
            this.errorMessage = res.errorMessage
            this.magicLink = res.link
            this.creatingLink = false
        })
    }

    createReconnectionLink() {
        this.creatingLink = true
        this.adminKomboResource.createReconnectionLink(this.companyId).then(res => {
            this.errorMessage = res.errorMessage
            this.magicLink = res.link
            this.creatingLink = false
        })
    }

    deleteIntegrationOpenDialog() {
        this.deletingIntegration = true

        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Integration löschen',
                message: 'Möchtest du die Integration wirklich löschen?',
                confirmButtonText: 'Löschen',
                confirmButtonColor: 'warn',
                onConfirm: () => this.adminKomboResource.deleteIntegration(this.companyId)
            },
            panelClass: ['full-width-mobile-dialog', 'round-border-dialog']
        });

        let sub = dialogRef.afterClosed().subscribe(deleted => {
            this.deletingIntegration = false


            if (deleted) {
                this.utilityService.showSnackBar('Erfolgreich gelöscht');
                this.loadIntegration()
            }

            sub.unsubscribe()
        });

    }


    openImportAtsPositionsDialog() {
        let comp = this.dialogService.openOverlay(ImportAtsPositionsDialogComponent, 'Huge')
        comp.instance.companyId = this.companyId
    }


}
