<dialog-header dialogHeadline="Mapping erstellen"></dialog-header>

<div class="dialog-content" fxLayout="column">

    <p style="color: #7d7d7d; font-size: 14px">
        Mappe die Profession <b>{{professionTitle}}</b> auf eine neue Studiengruppe.<br>
    </p>

    <searchable-subject-group-dropdown placeholder="Studiengruppe" [control]="subjectGroupControl"></searchable-subject-group-dropdown>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <loading-button
                [raised]="true"
                [color]="'primary'"
                [loadingBool]="loading"
                [disabledCondition]="subjectGroupControl.invalid"
                (clickEvent)="saveMappings()">
            Speichern
        </loading-button>
    </div>

<!--    <mat-tab-group (selectedTabChange)="onTabChanged($event)">-->

<!--        <mat-tab label="Matching SubjectGroups">-->
<!--            <table mat-table [dataSource]="professionMatchingTypeSubjectGroupData" class="w-100">-->

<!--                <ng-container matColumnDef="groupName">-->
<!--                    <th mat-header-cell *matHeaderCellDef>Studiengruppe</th>-->
<!--                    <td mat-cell *matCellDef="let element">{{element.subjectGroup.name}}</td>-->
<!--                </ng-container>-->

<!--                <ng-container matColumnDef="amountOfCurrentTalents">-->
<!--                    <th mat-header-cell *matHeaderCellDef>Talente verfügbar</th>-->
<!--                    <td mat-cell *matCellDef="let element">{{element.amountOfCurrentTalents}}</td>-->
<!--                </ng-container>-->

<!--                <ng-container matColumnDef="matchingType">-->
<!--                    <th mat-header-cell *matHeaderCellDef>MatchingType</th>-->
<!--                    <td mat-cell *matCellDef="let element">-->
<!--                        {{element.matchingType}}-->
<!--                    </td>-->
<!--                </ng-container>-->

<!--                <ng-container matColumnDef="actions">-->
<!--                    <th mat-header-cell *matHeaderCellDef></th>-->
<!--                    <td mat-cell *matCellDef="let element">-->
<!--                        <div class="table-button" fxLayout="row" fxLayoutAlign="start center">-->
<!--                            <button mat-button mat-icon-button color="primary"-->
<!--                                    matTooltip="Mapping hinzufügen"-->
<!--                                    (click)="saveProfessionSubjectGroupMapping(element.groupId)">-->
<!--                                <i class="mdi mdi-plus mdi-24px"></i>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </td>-->
<!--                </ng-container>-->
<!--                <tr mat-header-row-->
<!--                    *matHeaderRowDef="['groupName','amountOfCurrentTalents','matchingType','actions']"></tr>-->
<!--                <tr mat-row-->
<!--                    *matRowDef="let row; columns: ['groupName','amountOfCurrentTalents','matchingType','actions']"></tr>-->
<!--            </table>-->
<!--        </mat-tab>-->

<!--        <mat-tab label="Gemappte SubjectGroups" class="w-100">-->
<!--            <ng-template matTabContent>-->
<!--                <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">-->
<!--                    <mat-form-field>-->
<!--                        <input matInput (keyup)="applySubjectGroupFilter($event.target.value)" placeholder="Suche">-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--                <loading-button color="primary" [loadingBool]="loadDeleteGroupMappings"-->
<!--                                (clickEvent)="deleteSelectedSubjectGroupMappings()">{{selection.selected.length}}-->
<!--                    Subject-Mappings löschen-->
<!--                </loading-button>-->


<!--                <table mat-table [dataSource]="mappedSubjectGroupsDataSource" class="w-100">-->

<!--                    <ng-container matColumnDef="select">-->
<!--                        <th mat-header-cell *matHeaderCellDef>-->
<!--                            <mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--                                          [checked]="selection.hasValue() && isAllSelected()"-->
<!--                                          [indeterminate]="selection.hasValue() && !isAllSelected()">-->
<!--                            </mat-checkbox>-->
<!--                        </th>-->
<!--                        <td mat-cell *matCellDef="let row">-->
<!--                            <mat-checkbox (click)="$event.stopPropagation()"-->
<!--                                          (change)="$event ? selection.toggle(row) : null"-->
<!--                                          [checked]="selection.isSelected(row)">-->
<!--                            </mat-checkbox>-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                    <ng-container matColumnDef="subjectGroupTitle">-->
<!--                        <th mat-header-cell *matHeaderCellDef>SubjectGroup</th>-->
<!--                        <td mat-cell *matCellDef="let subjectGroup">-->
<!--                            <span class="clickable"-->
<!--                                  matTooltip="Mappings ansehen"-->
<!--                                  (click)="openSubjectGroupDetailsSheet(subjectGroup)">-->
<!--                                {{subjectGroup.title}}-->
<!--                            </span>-->
<!--                        </td>-->
<!--                    </ng-container>-->

<!--                    <ng-container matColumnDef="numProfessions">-->
<!--                        <th mat-header-cell *matHeaderCellDef>Professions</th>-->
<!--                        <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numProfessions}}</td>-->
<!--                    </ng-container>-->

<!--                    <ng-container matColumnDef="numSubjects">-->
<!--                        <th mat-header-cell *matHeaderCellDef>Subjects</th>-->
<!--                        <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numSubjects}}</td>-->
<!--                    </ng-container>-->

<!--                    <ng-container matColumnDef="actions">-->
<!--                        <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>-->
<!--                        <td mat-cell *matCellDef="let subjectGroup">-->
<!--                            <div class="table-button" fxLayout="row" fxLayoutAlign="start center">-->
<!--                                <button mat-button mat-icon-button color="primary"-->
<!--                                        matTooltip="Mapping entfernen"-->
<!--                                        (click)="deleteSubjectGroupMapping(subjectGroup)">-->
<!--                                    <i class="mdi mdi-trash-can mdi-24px"></i>-->
<!--                                </button>-->
<!--                            </div>-->
<!--                        </td>-->
<!--                    </ng-container>-->

<!--                    <tr mat-header-row *matHeaderRowDef="['select','subjectGroupTitle', 'numSubjects', 'numProfessions', 'actions']"></tr>-->
<!--                    <tr mat-row *matRowDef="let row; columns: ['select','subjectGroupTitle', 'numSubjects', 'numProfessions', 'actions']"></tr>-->
<!--                </table>-->
<!--            </ng-template>-->
<!--        </mat-tab>-->
<!--    </mat-tab-group>-->
</div>
