import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminTalentData, ConsultantHiringData} from "../../generated/data";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";

@Component({
    selector: 'app-consultant-hirings-dialog',
    templateUrl: './consultant-hirings-dialog.component.html',
    styleUrls: ['./consultant-hirings-dialog.component.scss']
})
export class ConsultantHiringsDialogComponent implements OnInit {

    data: ConsultantHiringData[]

    displayedColumns = [
        'talent',
        'position',
        'company',
        'date',
        'actions'
    ];

    constructor(
        private sideSheetRef: SideSheetRef,
        private dialogService: SideSheetService
    ) {
    }

    ngOnInit(): void {
    }

    openTalentProfile(id: number) {
        let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
        comp.instance.talentId = id
    }

}
