import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AdminEmploymentPositionData,
    CompanyPricingMode,
    LabelData,
    SalesProductData,
    SalesProposalData
} from "../../generated/data";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {AdminEmploymentPositionResource, AdminSalesProposalResource} from "../../generated/resources";

@Component({
  selector: 'create-sales-proposal',
  templateUrl: './create-sales-proposal.component.html',
  styleUrls: ['./create-sales-proposal.component.scss']
})
export class CreateSalesProposalComponent implements OnInit, OnDestroy {

    @Input() companyId?: number
    @Input() showSelectCompany: boolean = false
    @Output() salesProposal: EventEmitter<SalesProposalData> = new EventEmitter()
    @Output() valid: EventEmitter<Boolean> = new EventEmitter()

    proposalForm: FormGroup
    products: SalesProductData[] = []
    productSubscription: Subscription
    companySubscription: Subscription
    formSubscription: Subscription
    positions: AdminEmploymentPositionData[] = [];
    assignedPositions: number[] = []

    loadingPositions: boolean = false

  constructor(
      private adminSalesProposalResource: AdminSalesProposalResource,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private fb: FormBuilder
  ) { }

  ngOnInit(): void {
      this.initForm()
      this.getProducts()
      this.loadPositionsForCompany()
  }

    ngOnDestroy(): void {
        this.productSubscription?.unsubscribe()
        this.formSubscription?.unsubscribe()
        this.companySubscription?.unsubscribe()
    }

    getTotalPrice(): number {
        return (this.proposalForm.controls.price?.value * ((100-this.proposalForm.controls.discount?.value)/100))
    }

    getTotalPriceWithTax(): number {
        return this.getTotalPrice() * ((100+this.proposalForm.controls.product.value?.salesTax)/100)
    }

    getProducts() {
        this.adminSalesProposalResource.getProducts().then(result => {
            this.products = result
        })
    }

    toggleAllAssignedPositions(){
        if(this.assignedPositions.length === this.positions.length){
            this.assignedPositions = []
        } else {
            this.assignedPositions = this.positions.map(p => p.id)
        }
        this.emitModelChange()
    }

    initForm() {
        this.proposalForm = this.fb.group({
            product: [null, Validators.required], // must include a non-whitespace character
            size: [{ value: null, disabled: false }, Validators.required],
            serviceDescription: [{ value: null, disabled: false }, Validators.required],
            discount: [{ value: null, disabled: false }, Validators.required],
            price: [{ value: null, disabled: false }, Validators.required],
            companyId: [{ value: this.companyId, disabled: false }, Validators.required],
        });

        this.companySubscription = this.proposalForm.get("companyId").valueChanges.subscribe(next => {
            debugger
            this.loadPositionsForCompany()
        })

        this.productSubscription = this.proposalForm.get("product").valueChanges.subscribe(next => {
            if (next.title != "Individuelles Paket") {
                this.proposalForm.controls.size.setValue(next.size)
                this.proposalForm.controls.serviceDescription.setValue(next.serviceDescription)
                this.proposalForm.controls.discount.setValue(next.discount)
                this.proposalForm.controls.price.setValue(next.originalPrice)
                this.proposalForm.updateValueAndValidity()
            }
        })

        this.formSubscription = this.proposalForm.valueChanges.subscribe(() => {
            this.emitModelChange()
        })
    }

    updateAssignedPositions(positionId: number){
        if(this.assignedPositions.includes(positionId)){
            this.assignedPositions = this.assignedPositions.filter(p => p !== positionId)
        } else {
            this.assignedPositions.push(positionId)
        }
        this.emitModelChange()
    }

    loadPositionsForCompany(){
        if (!this.proposalForm.controls.companyId.value) {
            this.positions = []
            return
        }
        this.loadingPositions = true
        this.adminEmploymentPositionResource.getAllEmploymentPositions(this.proposalForm.controls.companyId.value).then(positions => {
            this.positions = positions;
            this.loadingPositions = false;
        })
    }

    emitModelChange() {
        this.valid.emit(this.proposalForm.valid)

        if (this.proposalForm.valid) {
            const proposal: SalesProposalData = {
                originalPrice: this.proposalForm.controls.price.value,
                size: this.proposalForm.controls.size.value,
                discount: this.proposalForm.controls.discount.value,
                serviceDescription: this.proposalForm.controls.serviceDescription.value,
                companyId: this.proposalForm.controls.companyId.value,
                id: null,
                priceWithTax: null,
                taxRate: null,
                priceWithoutTax: this.proposalForm.controls.price.value * ((100-this.proposalForm.controls.discount.value)/100),
                title: this.proposalForm.controls.product.value.title,
                product: <LabelData>{id: this.proposalForm.controls.product.value.id, name: this.proposalForm.controls.product.value.title},
                assignedPositionIds: this.assignedPositions,
                state: "Open",
                companyDraftData: null,
                recruiterDraftData: null
            }

            this.salesProposal.emit(proposal)
        }

    }

}
