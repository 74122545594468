import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'talentQualityScore'
})
export class TalentQualityScorePipe implements PipeTransform {

  transform(value: number): string {

    let scoreString: string = '';

    switch (value) {

      case 0: {
        scoreString = 'E';
        break;
      }

      case 1: {
        scoreString = 'D';
        break;
      }

      case 2: {
        scoreString = 'C';
        break;
      }

      case 3: {
        scoreString = 'B';
        break;
      }

      case 4: {
        scoreString = 'A';
        break;
      }

      case 5: {
        scoreString = 'S';
        break;
      }

      default: {
        scoreString = 'n.a.';
        break;
      }
    }

    return scoreString;
  }

}
