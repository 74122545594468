<dialog-header [dialogHeadline]="'Stellendetails (' + positionId + ')'"></dialog-header>

<div class="dialog-main-content">

    <div class="width-100 height-100">


        <div style="background-color: white" class="mat-elevation-z6">

            <mat-tab-group [selectedIndex]="tabIndex - 1">
                <mat-tab>
                    <ng-template mat-tab-label>Stellendetails</ng-template>

                    <div class="layout-padding-24-32" fxLayout="column" fxLayoutAlign="start start">

                        <div fxLayout="row" fxLayoutAlign="end" class="width-100">

                            <button mat-icon-button color="primary" matTooltip="Datenschutzerklärung"
                                    (click)="openPrivacyDisclaimerDialog()">
                                <i class="mdi mdi-format-section main-action-icon"></i>
                            </button>
                            <button mat-icon-button color="primary" matTooltip="Stelle löschen" (click)="deletePosition()">
                                <i class="mdi mdi-delete main-action-icon"></i>
                            </button>

                            <loading-button buttonClass="mat-primary"
                                            [loadingBool]="updatePositionLoading"
                                            [disabledCondition]="!positionValid"
                                            (clickEvent)="updatePosition()">
                                <i class="mdi mdi-content-save main-action-icon"></i>
                            </loading-button>
                        </div>

                        <position-information #positionInfoComp
                                              class="width-100"
                                              *ngIf="employmentPositionData.customerCompanyId"
                                              (validationUpdate)="setValidation($event)"
                                              [(position)]="employmentPositionData"
                                              [positionId]="positionId"
                                              (reload)="load(true)">
                        </position-information>

                    </div>
                </mat-tab>
                <mat-tab label="Matching-Config" >
                    <app-position-details-matching class="layout-padding-8" [positionId]="positionId"></app-position-details-matching>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>Stellencontent</ng-template>

                    <div fxLayout="row" fxLayoutAlign="end center" class="width-100 layout-padding">
                        <loading-button  buttonClass="hid-button primary mat-raised-button" color="primary" (clickEvent)="addSplit()" [loadingBool]="loadingAddSplit" class="">
                            <i class="mdi mdi-plus"></i> Split Hinzufügen
                        </loading-button>

                        <button [disabled]="loadingAddSplit" mat-button [matMenuTriggerFor]="menu">Split Kopie erstellen</button>
                        <mat-menu #menu="matMenu">
                            <button *ngFor="let s of employmentPositionData.contents" mat-menu-item (click)="addSplitFromOldSplit(s.id)">
                                {{s.internTitle}}
                            </button>
                        </mat-menu>
                    </div>

                        <mat-tab-group class="nested-group" *ngIf="employmentPositionData.customerCompanyId">
                            <mat-tab *ngFor="let split of employmentPositionData.contents; let i = index">
                                <ng-template mat-tab-label>
                                    <span>{{split.internTitle? split.internTitle : 'split'}}</span>
                                    <span *ngIf="split.active" class="dot green"></span>
                                    <span *ngIf="!split.active" class="dot red"></span>
                                </ng-template>

                               <div>
                                   <position-content #positionContentComp
                                                     class="width-100"
                                                     [activeSplitCount]="activeSplitCount"
                                                     (activeSplitRecalc)="calcActiveSplits()"
                                                     (deleted)="removeSplit($event)"
                                                     [split]="split">
                                   </position-content>
                               </div>
                            </mat-tab>
                        </mat-tab-group>

                </mat-tab>

                <mat-tab label="Empfehlungen">
                    <ng-template matTabContent>
                        <position-recommendations [positionId]="positionId" [positionName]="employmentPositionData.internTitle"></position-recommendations>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Bookings" *ngIf="employmentPositionData.companyPricingMode == 'Booking' || employmentPositionData.companyPricingMode == 'PrepaidBooking'">
                    <ng-template matTabContent>
                        <position-bookings [position]="employmentPositionData"></position-bookings>
                    </ng-template>
                </mat-tab>

                <!--
                  applications
                -->
                <mat-tab>
                    <ng-template mat-tab-label>Bewerbungen</ng-template>

                    <div style="padding: 10px 10px 0 10px" fxLayout="column" fxLayoutAlign="start start">

                        <position-applications
                                *ngIf="positionId"
                                [positionId]="positionId"
                                [position]="employmentPositionData"
                                style="width: 100%">
                        </position-applications>
                    </div>
                </mat-tab>

                <mat-tab label="Bewerberpersona">
                    <div style="box-sizing: border-box" fxLayout="column" fxLayoutAlign="start start" class="height-100 w-100">
                        <applicant-persona class="width-100" [positionId]="positionId"></applicant-persona>
                    </div>
                </mat-tab>

                <mat-tab label="Fragen an Talente">
                    <div style="box-sizing: border-box" fxLayout="column" fxLayoutAlign="start start" class="height-100 w-100">
                        <position-questions
                                *ngIf="employmentPositionData?.id"
                                class="width-100" [positionId]="positionId" [positionTitle]="employmentPositionData.internTitle"></position-questions>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
