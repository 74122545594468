import { Component, OnInit } from '@angular/core';
import {AdminEventData, LabelData} from "../../generated/data";
import {AdminEventResource} from "../../generated/resources";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {FileService} from "../../services/file.service";
import {UpdateEventFilterSheetComponent} from "../update-event-filter-sheet/update-event-filter-sheet.component";
import {
  CreateOrUpdateEventSheetComponent
} from "../create-or-update-event-sheet/create-or-update-event-sheet.component";

@Component({
  selector: 'app-normal-match-events',
  templateUrl: './normal-match-events.component.html',
  styleUrls: ['./normal-match-events.component.scss']
})
export class NormalMatchEventsComponent implements OnInit {


  displayedColumns = [
    'id',
    'title',
    'start',
    'showEvent',
    'companyName',
    'actions',
  ];

  data: AdminEventData[] =[];

  constructor(
      private adminEventResource: AdminEventResource,
      private dialogService: SideSheetService,
      private fileService: FileService
  ) { }

  ngOnInit(): void {
    this.getEvents()
  }

  getEvents() {
    this.adminEventResource.getNormalEvents().then(result => {
      this.data = result
    })
  }

  getCompaniesString(data: LabelData[]): string {
    return data?.map(i => i.name)?.join(",")
  }

  downloadRegistrationsExcel(eventId) {
    this.fileService.downloadTalentEventRegistrationsExcel(eventId)
  }

  openEditEventFilterDialog(eventId) {
    let cref = this.dialogService.openOverlay(UpdateEventFilterSheetComponent)
    cref.instance.eventId = eventId;
  }

  openCreateOrUpdateDialog(data?: AdminEventData) {
    let cref = this.dialogService.openOverlay(CreateOrUpdateEventSheetComponent);
    cref.instance.data = data;

    cref.instance.sideSheetRef.sheetClosed.subscribe((result) => {
      if (result) this.getEvents()
    })
  }

}