<dialog-header dialogHeadline="Neue Stelle anlegen"></dialog-header>

<div class="dialog-main-content width-100" fxLayout="column" fxLayoutAlign="start center">
  <form [formGroup]="positionDetailsForm" class="width-100">
    <div fxLayout="column" class="width-100">
      <mat-checkbox formControlName="isThirdPartyPosition">Bewerbung über Personalvermittler</mat-checkbox>

      <app-material-async-dropdown
        [control]="positionDetailsForm.controls['customerCompanyId']"
        [nullable]="true"
        [placeholder]="getCompanyPlaceholder()"
        displayProperty="name"
        [loadingFunctionConfig]="{resource: companyResource, functionName: 'getCompanies'}">
      </app-material-async-dropdown>

      <div fxLayout="column" *ngIf="positionDetailsForm.controls['isThirdPartyPosition'].value"
           style="position: relative">

        <div fxLayout="row">
          <mat-checkbox [formControl]="companyKnownControl" style="margin-right: 20px">Firma bekannt</mat-checkbox>

          <mat-checkbox formControlName="showCompany">Firma darf angezeigt werden</mat-checkbox>
        </div>

        <app-material-async-dropdown
          *ngIf="!customCompanySelected && companyKnownControl.value"
          [control]="positionDetailsForm.controls['companyId']"
          [nullable]="true"
          [placeholder]="'Unternehmen'"
          displayProperty="name"
          [loadingFunctionConfig]="{resource: companyResource, functionName: 'getCompanies'}">
        </app-material-async-dropdown>

        <div *ngIf="!customCompanySelected && companyKnownControl.value" class="hint" style="top: 80px"
             (click)="showCustomCompanyInput(true)">
          Firma manuell eingeben
        </div>

        <mat-form-field *ngIf="customCompanySelected && companyKnownControl.value"
                        style="width: 100%">
          <input matInput placeholder="Firmenname" type="text" formControlName="companyName">
        </mat-form-field>

        <div *ngIf="customCompanySelected && companyKnownControl.value" class="hint" style="top: 80px"
             (click)="showCustomCompanyInput(false)">
          Firma aus Liste wählen
        </div>

        <mat-form-field *ngIf="!companyKnownControl.value || !positionDetailsForm.controls.showCompany.value"
                        style="position: relative; top: 15px">
          <input matInput placeholder="Pseudonym der Firma" type="text" formControlName="companyAlias">
        </mat-form-field>
      </div>

      <mat-form-field
        [ngStyle]="{'margin-top.px' : positionDetailsForm.controls['isThirdPartyPosition'].value ? 15 : 0}">
        <input matInput placeholder="Stellentitel*" type="text" formControlName="title">
      </mat-form-field>

      <mat-form-field class="width-100">
        <mat-label>Kurzbeschreibung der Tätigkeit</mat-label>
        <textarea required rows="4" matInput maxlength="300" formControlName="shortDescription"></textarea>
        <mat-hint align="end">{{positionDetailsForm.controls.shortDescription.value?.length? positionDetailsForm.controls.shortDescription.value?.length : '0'}} / 300</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Link zur Stelle" type="url" formControlName="webLink">
      </mat-form-field>

    </div>

    <div class="w-100">
      <mat-form-field class="w-100">
        <mat-label>Arbeitsort Moduswahl</mat-label>
        <mat-select formControlName="locationMode">
          <mat-option [value]="'Worldwide'">Weltweit</mat-option>
          <mat-option [value]="'Nationwide'">Deutschlandweit</mat-option>
          <mat-option [value]="'SpecificStates'">Nur bestimmte Bundesländer</mat-option>
          <mat-option [value]="'SpecificCities'">Nur bestimmte Städte</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="dropdown-component w-100" *ngIf="!customCitySelected && getCurrentLocationMode() == 'SpecificCities'">
        <app-searchable-city-dropdown placeholder="Arbeitsort" [multiple]="true"
                                      [control]="positionDetailsForm.get('cities')">
        </app-searchable-city-dropdown>
        <div class="hint" (click)="showCustomCityInput(true)">Städte zur Liste hinzufügen</div>
      </div>

      <div class="input-component" *ngIf="customCitySelected">
        <mat-form-field>
          <input matInput placeholder="Städte hinzufügen" type="text" formControlName="jobSitesInput">
          <i class="mdi mdi-content-save mdi-18px"
             style="position: absolute; right: 0"
             matTooltip="Neue Städte hinzufügen"
             (click)="saveCustomCities()">
          </i>
        </mat-form-field>
        <div class="hint" (click)="showCustomCityInput(false)">Städte aus Liste auswählen</div>
      </div>

      <div class="dropdown-component w-100" *ngIf="!customCitySelected && getCurrentLocationMode() == 'SpecificStates'">
        <app-searchable-federal-sate-dropdown placeholder="Bundesland" [multiple]="true"
                                              [control]="positionDetailsForm.get('federalStates')">
        </app-searchable-federal-sate-dropdown>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" style="margin-top: 10px">
        <mat-form-field>
          <mat-label>Stellen Typ*</mat-label>
          <mat-select formControlName="workType">
            <mat-option *ngFor="let type of employmentCategories" [value]="type">{{type | workType}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="picker" formControlName="startingDate"
                 placeholder="Stelle verfügbar ab*">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput required placeholder="Volumen" type="number" formControlName="availableSlots">
        </mat-form-field>

        <mat-checkbox formControlName="prime">Primestelle</mat-checkbox>
      </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field style="width: 380px">
                <input matInput required placeholder="Wöchentliche Stunden von" type="number" formControlName="weeklyHoursFrom">
            </mat-form-field>

            <mat-form-field style="width: 380px">
                <input matInput required placeholder="Wöchentliche Stunden bis" type="number" formControlName="weeklyHoursTo">
            </mat-form-field>
        </div>

        <mat-form-field style="width: 380px">
            <mat-label>HomeOffice*</mat-label>
            <mat-select formControlName="homeOffice">
                <mat-option *ngFor="let type of homeOfficeCategories" [value]="type">{{type | homeOffice}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
  </form>

  <div *ngIf="positionDetailsForm" fxLayout="row" fxLayoutAlign="end center" class="primary-text-color width-100 button-bar">
    <loading-button [loadingBool]="saving"
                    (clickEvent)="createPosition()"
                    [disabledCondition]="!isValid()"
                    [raised]="true"
                    buttonClass="mat-primary">Speichern
    </loading-button>
  </div>
</div>



