<mat-form-field class="w-100" color="primary" [ngClass]="dropdownClass" [appearance]="appearance">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select [formControl]="control"
                [multiple]="multiple"
                [required]="required"
                [disableOptionCentering]="disableOptionCentering">
        <mat-option>
            <ngx-mat-select-search [formControl]="filterControl"></ngx-mat-select-search>
        </mat-option>
        <div *ngIf="multiple" fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button class="toggle-all-button" (click)="unselectAll()" color="warn">Alle abwählen</button>
            <button mat-button class="toggle-all-button" (click)="selectAll()" color="primary">Alle Auswählen</button>
        </div>
        <mat-option *ngIf="!required" [value]="null">
            <div style="width: 100%; text-align: center">- egal -</div>
        </mat-option>
        <mat-option *ngFor="let company of companies" [value]="company.id">
            {{company.name}}
        </mat-option>
    </mat-select>
    <div class="loading-line" *ngIf="loading"></div>
</mat-form-field>
