import {AddressData} from "./address-data"
import {LabelData} from "./label-data"

export class AdminEventData {
    address = new AddressData();
    companies: LabelData[] = null;
    end: (Date | null) = null;
    eventHeader: number | null = null;
    formatId: number | null = null;
    id: number | null = null;
    images: number[] | null = null;
    longDescription: string | null = null;
    partnerLogos: number[] | null = null;
    promotionId: number | null = null;
    shortDescription: string | null = null;
    showEvent: boolean | null = null;
    start: (Date | null) = null;
    tileText: string | null = null;
    title: string = null;
    type: string | null = null;
    videoLinkUnsafe: string | null = null;
}