import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'initial'
})
export class InitialPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string): string {
    return value.split(" ").map((word) => word[0]).join(". ") + "."
  }
}
