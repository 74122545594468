import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountResource} from "../generated/resources";
import {flatMap, map, mergeMap, tap} from 'rxjs/operators';
import {TalentAccountInfoData} from "../generated/data";
import {environment} from "../../environments/environment";

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const ACCOUNT_INFO = 'account_info';

export type LoginState =
    'LoginSuccessful'
    | 'LoginFailedCredentialsIncorrect'
    | 'LoginFailedRoleIncorrect'
    | 'LoginExpired'
    | 'Logout'
    | 'TokenUpdated';

@Injectable()
export class AuthService {

  private authenticatedSubject = new Subject<LoginState>();

  constructor(
      protected http: HttpClient,
      private accountResource: AccountResource,
      private route: ActivatedRoute,
      protected router: Router,
      private jwtHelper: JwtHelperService
  ) {

  }


  onLoginStateChange(callback: (result) => any) {
    this.authenticatedSubject.subscribe(
        (result) => callback(result)
    )
  }

  onLoginSuccess(callback: () => any) {
    this.authenticatedSubject.subscribe(
        (result) => {
          if (result == "TokenUpdated" || result == "LoginSuccessful") {
            callback()
          }
        }
    )
  }

  getToken(): string {

    if (this.isExpired()) {
      this.clearUserLoginSession()
    }

    return localStorage.getItem(ACCESS_TOKEN);
  }

  reloadAccountInfo() {
    return this.accountResource.getAccountInfo().then((accountInfo) => {

      if ((accountInfo as TalentAccountInfoData).accountLiveTimeSeconds != undefined) {
        accountInfo['regDate'] = new Date(new Date().getTime() - ((accountInfo as TalentAccountInfoData).accountLiveTimeSeconds * 1000))
      }

      localStorage.setItem(ACCOUNT_INFO, JSON.stringify(accountInfo));
    });
  }

  logout() {
    this.clearUserLoginSession();
    this.authenticatedSubject.next('Logout');
  }

  setToken(newToken) {
    localStorage.setItem(ACCESS_TOKEN, newToken);
  }

  applyLogin(loginState: LoginState) {
    this.reloadAccountInfo().then(() => {
          this.authenticatedSubject.next(loginState);
        }
    )
    return this.authenticatedSubject.asObservable();
  }

  getAccountInfo() {
    return JSON.parse(localStorage.getItem(ACCOUNT_INFO));
  }


  clearUserLoginSession() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCOUNT_INFO);
    localStorage.removeItem('ngx_talentData');
  }

  private isExpired() {
    try {
      const isExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(ACCESS_TOKEN));

      if (isExpired) {
        this.clearUserLoginSession();
        this.authenticatedSubject.next('LoginExpired');
      }
      return isExpired;
    } catch (e) {
      this.clearUserLoginSession();
    }

    return true;
  }

  isAuthenticated(): boolean {

    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token == null) {
      return false;
    }

    return !this.isExpired()
  }

  oauthLogin(code: string, codeVerifier: string | null) {
    const formData = new FormData();
    formData.append('client_id', environment.frontendUnisignClientId)
    formData.append('grant_type', "authorization_code")
    formData.append('redirect_uri', environment.appLoginRedirectUri)
    formData.append('code', code)
    if (codeVerifier) {
      formData.append('code_verifier', codeVerifier)
    }
    return this.http.post(environment.uniSignUrl + "/oauth2/token", formData).pipe(
        map((response: any) => {
            let token = response['access_token'];
            let roleFromToken = this.jwtHelper.decodeToken(token).role as string;
            let result: LoginState;
            if(roleFromToken != 'Admin') {
                result = "LoginFailedRoleIncorrect"
                this.authenticatedSubject.next(result)
            } else {
                this.setToken(token)
                result = "LoginSuccessful"
                this.applyLogin(result)
            }
            return result;
        })
    )
  }

}
