<div id="component-wrapper">
    <loading-spinner [loadingBool]="loadingPosition">
        <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column">
                <h4>{{positionData?.hiringCompanyName}}</h4>
                <h3 class="clickable" (click)="openPositionDetailsDialog()">{{positionData?.internTitle}}</h3>
            </div>

            <div fxLayout="row" fxLayoutAlign="startcenter">
                <loading-button [disabledCondition]="!selection?.selected?.length"
                                [loadingBool]="recommendingPosition"
                                buttonClass="mat-primary" [raised]="true"
                                (clickEvent)="openCreatePositionRecommendationsDialog()">
                    Stelle empfehlen
                </loading-button>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <button mat-icon-button color="primary" matTooltip="Filter einstellen" (click)="openTalentFilterDialog()">
                <mat-icon>filter_list</mat-icon>
            </button>

            <div class="filter-checkbox"
                 (click)="considerFilterCheckbox.setValue(!considerFilterCheckbox.value)"
                 [ngClass]="{'selected': considerFilterCheckbox.value == true, 'unselected': considerFilterCheckbox.value == false }">
                <mat-checkbox [disabled]="true" [formControl]="considerFilterCheckbox">Filter berücksichtigen
                </mat-checkbox>
            </div>
            <div class="filter-checkbox"
                 (click)="considerMatchCheckbox.setValue(!considerMatchCheckbox.value)"
                 [ngClass]="{'selected': considerMatchCheckbox.value == true, 'unselected': considerMatchCheckbox.value == false }">
                <mat-checkbox [disabled]="true" [formControl]="considerMatchCheckbox">Match berücksichtigen
                </mat-checkbox>
            </div>

            <div class="filter-checkbox"
                 (click)="considerProfessionFieldPreferenceCheckbox.setValue(!considerProfessionFieldPreferenceCheckbox.value)"
                 [ngClass]="{'selected': considerProfessionFieldPreferenceCheckbox.value == true, 'unselected': considerProfessionFieldPreferenceCheckbox.value == false }">
                <mat-checkbox [disabled]="true" [formControl]="considerProfessionFieldPreferenceCheckbox">Bereichswunsch
                    berücksichtigen
                </mat-checkbox>
            </div>

            <div class="filter-checkbox"
                 (click)="considerLocationPreferenceCheckbox.setValue(!considerLocationPreferenceCheckbox.value)"
                 [ngClass]="{'selected': considerLocationPreferenceCheckbox.value == true, 'unselected': considerLocationPreferenceCheckbox.value == false }">
                <mat-checkbox [disabled]="true" [formControl]="considerLocationPreferenceCheckbox">Standortwunsch
                    berücksichtigen
                </mat-checkbox>
            </div>

            <mat-form-field appearance="fill" style="width: 250px; position: relative; top: 11px">
                <mat-label>Interaktion voraussetzen</mat-label>
                <mat-select [formControl]="interactionRequiredControl">
                    <mat-option [value]="null">Egal</mat-option>
                    <mat-option *ngFor="let option of interactionOptions"
                                [value]="option">{{option | talentPositionRelationState}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-icon-button color="primary" matTooltip="Reload" (click)="loadTalents()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

        <loading-spinner [loadingBool]="loadingTalents">
            <div class="width-100" style="min-height: 300px">
                <table mat-table [dataSource]="talentData" class="w-100">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          (change)="$event ? selection.toggle(row) : null"
                                          [disabled]="row.primeRecommendation"
                                          [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="creationDate">
                        <th mat-header-cell *matHeaderCellDef>Anmeldung</th>
                        <td mat-cell *matCellDef="let row">{{row.talentCreationDate | date: 'dd.MM.yy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="isPrimeMatch">
                        <th mat-header-cell *matHeaderCellDef>Prime-Match</th>
                        <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                            <img *ngIf="element.isPrimeFilterMatch" matTooltip="Das Talent matched auf den Prime-Filter"
                                 height="20" src="../../../assets/images/star.png">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Talent</th>
                        <td mat-cell *matCellDef="let row">{{row.talentName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="subject">
                        <th mat-header-cell *matHeaderCellDef>Studiengang</th>
                        <td mat-cell *matCellDef="let row">{{row.mostRecentStudies.subjectName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="professionGroupMatch">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center">Bereichs-Match</th>
                        <td mat-cell *matCellDef="let row" style="text-align: center">
                            <i *ngIf="row.professionGroupPreferenceMatches" class="mdi mdi-check green"></i>
                            <i *ngIf="!row.professionGroupPreferenceMatches" class="mdi mdi-close red"></i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="locationPreferenceMatch">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center">Standortwunsch-Match</th>
                        <td mat-cell *matCellDef="let row" style="text-align: center">
                            <i *ngIf="row.locationPreferenceMatches" class="mdi mdi-check green"></i>
                            <i *ngIf="!row.locationPreferenceMatches" class="mdi mdi-close red"></i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="PushedInFeed">
                        <th mat-header-cell *matHeaderCellDef>Im Feed</th>
                        <td mat-cell *matCellDef="let row">{{row.pushedInFeedOn | date: 'dd.MM.yy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let row">{{row.relationState | talentPositionRelationState}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell class="right" *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                                <button mat-button mat-icon-button color="primary"
                                        matTooltip="Detailiertes Profil anzeigen"
                                        (click)="openTalentProfile(row.talentId)">
                                    <i class="mdi mdi-account dark"></i>
                                </button>
                                <button mat-button mat-icon-button color="primary"
                                        matTooltip="Match anzeigen"
                                        [disabled]="!row.matchingReport"
                                        (click)="openMatchingReport(row.matchingReport)">
                                    <i class="mdi mdi-table-search mdi-24px"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>

            <mat-paginator [pageSize]="pageSize"
                           *ngIf="!loadingTalents && talentData.length"
                           [pageSizeOptions]="pageSizeOptions"
                           [showFirstLastButtons]="true"
                           [length]="totalSize"
                           [hidePageSize]="false"
                           [pageIndex]="pageIndex"
                           (page)="handlePage($event)">
            </mat-paginator>
        </loading-spinner>
    </loading-spinner>
</div>


