import {Component, OnInit} from '@angular/core';
import {
    AdminUpdateCompanyTalentAgentLandingPageData,
    AdminUpdateTalentTalentAgentLandingPageData,
    PublicCompanyTalentAgentLandingPageData,
    PublicTalentTalentAgentLandingPageData
} from "../generated/data";
import {AdminTalentAgentLandingPageResource, PublicLandingPageResource} from "../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-landing-pages',
    templateUrl: './landing-pages.component.html',
    styleUrls: ['./landing-pages.component.scss']
})
export class LandingPagesComponent implements OnInit {

    talentLadingPage: PublicTalentTalentAgentLandingPageData
    companyLandingPage: PublicCompanyTalentAgentLandingPageData
    loading = true;
    codeMirrorHeight = 300;

    constructor(
        private adminTalentAgentLandingPageResource: AdminTalentAgentLandingPageResource,
        private publicLandingPageResource: PublicLandingPageResource,
        private matSnackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
        this.load()
    }

    load() {
        this.loading = true
        Promise.all([
            this.publicLandingPageResource.getTalentTalentAgentLandingPage().then(res => {
                this.talentLadingPage = res
            }),
            this.publicLandingPageResource.getCompanyTalentAgentLandingPage().then(res => {
                this.companyLandingPage = res
            })
        ]).then(result => {
            this.loading = false
        })

    }

    updateTalentLandingPage() {
        let data: AdminUpdateTalentTalentAgentLandingPageData = {
            buttonText: this.talentLadingPage.buttonText,
            buttonUrl: this.talentLadingPage.buttonUrl,
            content1: this.talentLadingPage.content1,
            content2: this.talentLadingPage.content2,
            subTitle: this.talentLadingPage.subTitle,
            title: this.talentLadingPage.title
        }
        this.adminTalentAgentLandingPageResource.updateTalentTalentAgentLandingPage(data).then(() => {
            this.matSnackBar.open("Änderungen Gespeichert", null, {duration: 3000});
        })
    }

    updateCompanyLandingPage() {
        let data: AdminUpdateCompanyTalentAgentLandingPageData = {
            content: this.companyLandingPage.content,
            subTitle: this.companyLandingPage.subTitle,
            title: this.companyLandingPage.title
        }
        this.adminTalentAgentLandingPageResource.updateCompanyTalentAgentLandingPage(data).then(() => {
            this.matSnackBar.open("Änderungen Gespeichert", null, {duration: 3000});
        })
    }

}
