import {LocationOptionsData} from "./location-options-data"

export class EventFilterData {
    activeFilter: boolean = null;
    location = new LocationOptionsData();
    locationRadius: number | null = null;
    registrationMax: (Date | null) = null;
    registrationMin: (Date | null) = null;
    studyEndUntil: (Date | null) = null;
    subjectGroupIds: number[] = null;
    subjectIds: number[] = null;
}