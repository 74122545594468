import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentCoachRequestDistributionData, TalentCompleteTalentProfileData, StudiesData, TalentLocationPreferenceData, ProfileAttachmentData, WorkwiseApplicantInfoData, LanguageStateData, CVInfoData, TalentProfileData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentTalentProfileController
 */

@Injectable()
@ResourceParams({})
export class TalentTalentProfileResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/profile/languages',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateLanguageState: IResourceMethodObservableStrict<LanguageStateData, null, null, null> | undefined
    createOrUpdateLanguageState(requestBody: LanguageStateData): Promise<null> {
        if (!this._createOrUpdateLanguageState) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateLanguageState(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/profile/cvUploadIntentPopupSeen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentOpenedCvUploadIntentPopUpLogEntry: IResourceMethodObservableStrict<null, null, null, null> | undefined
    createTalentOpenedCvUploadIntentPopUpLogEntry(): Promise<null> {
        if (!this._createTalentOpenedCvUploadIntentPopUpLogEntry) throw new Error("resource has not been properly initialized")
        return this._createTalentOpenedCvUploadIntentPopUpLogEntry(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/talentProfile/CurriculumVitae',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteCV: IResourceMethodObservableStrict<null, null, null, null> | undefined
    deleteCV(): Promise<null> {
        if (!this._deleteCV) throw new Error("resource has not been properly initialized")
        return this._deleteCV(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile/CurriculumVitae/info',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCVInfo: IResourceMethodObservableStrict<null, null, null, CVInfoData> | undefined
    getCVInfo(): Promise<CVInfoData> {
        if (!this._getCVInfo) throw new Error("resource has not been properly initialized")
        return this._getCVInfo(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile/data',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getContactData: IResourceMethodObservableStrict<null, null, null, TalentProfileData> | undefined
    getContactData(): Promise<TalentProfileData> {
        if (!this._getContactData) throw new Error("resource has not been properly initialized")
        return this._getContactData(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile/feedbackConsent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFeedbackConsent: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    getFeedbackConsent(): Promise<JsonWrappedValue<boolean>> {
        if (!this._getFeedbackConsent) throw new Error("resource has not been properly initialized")
        return this._getFeedbackConsent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile/locationPreference',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLocationPreference: IResourceMethodObservableStrict<null, null, null, TalentLocationPreferenceData> | undefined
    getLocationPreference(): Promise<TalentLocationPreferenceData> {
        if (!this._getLocationPreference) throw new Error("resource has not been properly initialized")
        return this._getLocationPreference(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/mostRecentStudies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMostRecentStudiesForTalent: IResourceMethodObservableStrict<null, null, null, StudiesData> | undefined
    getMostRecentStudiesForTalent(): Promise<StudiesData> {
        if (!this._getMostRecentStudiesForTalent) throw new Error("resource has not been properly initialized")
        return this._getMostRecentStudiesForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile/languages',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnLanguageSkills: IResourceMethodObservableStrict<null, null, null, LanguageStateData[]> | undefined
    getOwnLanguageSkills(): Promise<LanguageStateData[]> {
        if (!this._getOwnLanguageSkills) throw new Error("resource has not been properly initialized")
        return this._getOwnLanguageSkills(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/storedFiles/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStoredFileForTalent: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getStoredFileForTalent(id: number): Promise<Blob> {
        if (!this._getStoredFileForTalent) throw new Error("resource has not been properly initialized")
        return this._getStoredFileForTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/storedFiles',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStoredFilesForTalentProfile: IResourceMethodObservableStrict<null, null, null, ProfileAttachmentData[]> | undefined
    getStoredFilesForTalentProfile(): Promise<ProfileAttachmentData[]> {
        if (!this._getStoredFilesForTalentProfile) throw new Error("resource has not been properly initialized")
        return this._getStoredFilesForTalentProfile(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/coach/distribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentDataRelevantForCoachDistribution: IResourceMethodObservableStrict<null, null, null, TalentCoachRequestDistributionData> | undefined
    getTalentDataRelevantForCoachDistribution(): Promise<TalentCoachRequestDistributionData> {
        if (!this._getTalentDataRelevantForCoachDistribution) throw new Error("resource has not been properly initialized")
        return this._getTalentDataRelevantForCoachDistribution(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentProfile: IResourceMethodObservableStrict<null, null, null, TalentCompleteTalentProfileData> | undefined
    getTalentProfile(): Promise<TalentCompleteTalentProfileData> {
        if (!this._getTalentProfile) throw new Error("resource has not been properly initialized")
        return this._getTalentProfile(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/workwiseApplicantInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getWorkwiseApplicationInfo: IResourceMethodObservableStrict<null, null, null, WorkwiseApplicantInfoData> | undefined
    getWorkwiseApplicationInfo(): Promise<WorkwiseApplicantInfoData> {
        if (!this._getWorkwiseApplicationInfo) throw new Error("resource has not been properly initialized")
        return this._getWorkwiseApplicationInfo(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/device',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _logTalentDevice: IResourceMethodObservableStrict<null, {browser?: string, browserVersion?: string, deviceType?: string}, null, null> | undefined
    logTalentDevice(requestParams?: {browser?: string, browserVersion?: string, deviceType?: string}): Promise<null> {
        if (!this._logTalentDevice) throw new Error("resource has not been properly initialized")
        return this._logTalentDevice(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/talent/profile/languages/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeLanguageState: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    removeLanguageState(id: number): Promise<null> {
        if (!this._removeLanguageState) throw new Error("resource has not been properly initialized")
        return this._removeLanguageState(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/profile/contactData',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setContactData: IResourceMethodObservableStrict<TalentProfileData, null, null, null> | undefined
    setContactData(requestBody: TalentProfileData): Promise<null> {
        if (!this._setContactData) throw new Error("resource has not been properly initialized")
        return this._setContactData(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profile/openCvIncentivePopup',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _shouldTalentOpenCvIncentivePopup: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    shouldTalentOpenCvIncentivePopup(): Promise<boolean> {
        if (!this._shouldTalentOpenCvIncentivePopup) throw new Error("resource has not been properly initialized")
        return this._shouldTalentOpenCvIncentivePopup(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/profile/feedbackConsent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateFeedbackConsent: IResourceMethodObservableStrict<JsonWrappedValue<boolean>, null, null, null> | undefined
    updateFeedbackConsent(requestBody: JsonWrappedValue<boolean>): Promise<null> {
        if (!this._updateFeedbackConsent) throw new Error("resource has not been properly initialized")
        return this._updateFeedbackConsent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talentProfile/workwiseApplicantInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateProfileInformationRelevantForWorkwise: IResourceMethodObservableStrict<WorkwiseApplicantInfoData, null, null, null> | undefined
    updateProfileInformationRelevantForWorkwise(requestBody: WorkwiseApplicantInfoData): Promise<null> {
        if (!this._updateProfileInformationRelevantForWorkwise) throw new Error("resource has not been properly initialized")
        return this._updateProfileInformationRelevantForWorkwise(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talentProfile/coach/distribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateRelevantDataForCoachDistribution: IResourceMethodObservableStrict<TalentCoachRequestDistributionData, null, null, null> | undefined
    updateRelevantDataForCoachDistribution(requestBody: TalentCoachRequestDistributionData): Promise<null> {
        if (!this._updateRelevantDataForCoachDistribution) throw new Error("resource has not been properly initialized")
        return this._updateRelevantDataForCoachDistribution(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talentProfile/CurriculumVitae/file',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _uploadCV: IResourceMethodObservableStrict<{file: File, fileName: string}, null, null, null> | undefined
    uploadCV(requestBody: {file: File, fileName: string}): Promise<null> {
        if (!this._uploadCV) throw new Error("resource has not been properly initialized")
        return this._uploadCV(requestBody, null, null).toPromise()
    }

}