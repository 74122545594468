import {AccountInfoData} from "./account-info-data"
import {SessionAttribution} from "./session-attribution"
import {MatchingState} from "./matching-state"
import {OnboardingVersion} from "./onboarding-version"
import {Origin} from "./origin"

export class TalentAccountInfoData extends AccountInfoData {
    accountLiveTimeSeconds: number = null;
    existsEventRegistration: boolean = null;
    germanLanguageLevel: string | null = null;
    hasPlatformVisited: boolean = null;
    isGraduate: boolean | null = null;
    lastLoginOrigin: SessionAttribution | null = null;
    matchingState: MatchingState = null;
    onboardingCompleted: boolean = null;
    onboardingVersion: OnboardingVersion | null = null;
    origin: Origin = null;
    platformVisited: boolean = null;
    regDate: (Date | null) = null;
    splitVersion: number = null;
    welcomeDialogSeen: boolean = null;
}