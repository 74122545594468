import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UtilsService} from '../../utils/utils.service';
import {EmailAddressAvailabilityValidatorService} from '../../services/email-address-availability-validator.service';
import {ActivatedRoute} from '@angular/router';
import {AdminConsultantResource} from '../../generated/resources';
import {convertFormGroupToObject} from '../../utils/inno-utils/form-utils.service';
import {ConsultantData, JsonWrappedValue} from '../../generated/data';
import {UtilityService} from '../../utils/utility.service';
import {FileService} from '../../services/file.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IncreaseAssignableTalentAmountForConsultantDialogComponent} from '../increase-assignable-talent-amount-for-consultant-dialog/increase-assignable-talent-amount-for-consultant-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-consultant-edit',
  templateUrl: './consultant-edit.component.html',
  styleUrls: ['./consultant-edit.component.scss']
})
export class ConsultantEditComponent implements OnInit {

  contactForm: FormGroup;
  consultantId: number;
  consultant: ConsultantData;

  loadingChangeConsultantState: boolean = false;
  loading: boolean = false;

  public talentAgentPicture: any;
  public noTalentAgentPicture: Boolean = false;
  ratingLoading: boolean = true
  consultantRatingData

  displayedColumns: string[] = ["talent", "rating", "ratingComment"];

  pageSize: number = 15;
  pageSizeOptions: number[] = [15, 30, 60, 100];
  totalSize: number = 0;
  pageIndex: number = 0;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private emailAddressAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
    private adminConsultantResource: AdminConsultantResource,
    private utilityService: UtilityService,
    private fileService: FileService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    this.contactForm = fb.group({
      firstName: [null, Validators.required],
      lastName: [null],
      addTalents: [0],
      emailAddress: [null, [Validators.required, Validators.email], [this.emailAddressAvailabilityValidatorService.createValidator()]],
      phoneNumber: [null]
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.consultantId = params.consultantId;
      this.loadConsultant();
      this.loadConsultantRating()
    });

    this.loadProfilePicture();
  }

  loadConsultant() {
    this.adminConsultantResource.getConsultant(this.consultantId).then(
      consultant => {
        this.consultant = consultant;
        this.contactForm.patchValue({
          firstName: consultant.firstName,
          lastName: consultant.lastName,
          emailAddress: consultant.emailAddress,
          phoneNumber: consultant.phoneNumber
        });
      }
    );
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadConsultantRating();
  }

  loadConsultantRating() {
    this.ratingLoading = true
    this.adminConsultantResource.getConsultantRating(this.consultantId, {
      page: this.pageIndex,
      pageSize: this.pageSize
    }).then(result => {
      this.consultantRatingData = result.content;
      this.totalSize = result.totalElements;
      this.ratingLoading = false;
    })
  }

  loadProfilePicture() {
    this.fileService.getConsultantProfilePicture(this.consultantId).subscribe((result) => {
      if (result.size == 0) {
        this.noTalentAgentPicture = true;
      } else {
        this.talentAgentPicture = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
      }
    });
  }

  updateConsultantAccount() {
    let data: ConsultantData = convertFormGroupToObject(<ConsultantData>{}, this.contactForm);
    data.mlpCrmId = this.consultant.mlpCrmId

    this.loading = true;
    this.adminConsultantResource.updateConsultant(data, this.consultantId).then(
      () => {
        this.loading = false;
        this.utilityService.showSnackBar("Admin geändert");
      }
    )
  }

  onFileChange(event, role: string) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {

        this.noTalentAgentPicture = false;
        this.talentAgentPicture = e.target.result;

        this.fileService.uploadConsultantProfilePicture(
          this.consultantId, this.createImageFile(this.talentAgentPicture, event.target.files[0].name)).subscribe(() => {
          this.utilityService.showSnackBar('Profilbild aktualisiert');
        });

      };
    }
  }

  createImageFile(file, fileName: string) {
    const imageBlob = this.dataURItoBlob(file.replace(RegExp('data.*base64,'), ''));
    return new File([imageBlob], fileName, {type: 'image/jpeg'});
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/jpeg'});
  }
}
