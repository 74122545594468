import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {AdminCompanyData, CompanyPricingMode} from "../../../generated/data";
import {AdminCompanyResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";
import {MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector: 'searchable-company-by-pricingmode-dropdown',
  templateUrl: './searchable-company-by-pricingmode-dropdown.component.html',
  styleUrls: ['./searchable-company-by-pricingmode-dropdown.component.scss']
})
export class SearchableCompanyByPricingmodeDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() appearance: MatFormFieldAppearance = 'outline'
  @Input() control: AbstractControl
  @Input() filteredModes: CompanyPricingMode[]
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true

  companies: AdminCompanyData[] = [];

  filterControl: FormControl = new FormControl()

  loading = false;

  constructor(
      private adminCompanyResource: AdminCompanyResource
  ) {
  }

  ngOnInit() {
    this.loadCompanies();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(() => {
          this.loadCompanies()
        });
  }

  loadCompanies() {
    this.loading = true;

    this.adminCompanyResource.getCompaniesByPricing({
      q: this.filterControl.value,
      pageSize: 20,
      filteredModes: this.filteredModes,
      forcedIds: this.control?.value? [this.control.value] : null
    }).then(companies => {
      this.companies = companies.content;
    }).finally(() => {
      this.loading = false;
    })
  }

  selectAll() {
    this.control.setValue(this.companies.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}
