import {UploadedFileData} from "./uploaded-file-data"
import {CompanyData} from "./company-data"
import {DisplayRecruiterData} from "./display-recruiter-data"
import {ApplicationState} from "./application-state"
import {TalentPositionDetailsData} from "./talent-position-details-data"

export class TalentApplicationData {
    additionalFilesAddable: boolean = null;
    attachments: UploadedFileData[] | null = null;
    company = new CompanyData();
    companyWebsite: string | null = null;
    creationDate: (Date | null) = null;
    curriculumVitae: UploadedFileData | null = null;
    deniedDate: (Date | null) = null;
    failedReason: string | null = null;
    id: number = null;
    recruiter: DisplayRecruiterData | null = null;
    releaseDate: (Date | null) = null;
    state: ApplicationState = null;
    talentPositionRelation = new TalentPositionDetailsData();
    type: string = null;
    wasDraft: boolean = null;
    withdrawnDate: (Date | null) = null;
}