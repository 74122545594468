import { Component, OnInit } from '@angular/core';
import {
  LabelData,
  ProfessionData,
  ProfessionGroupData,
  ProfessionTypeData,
  SubjectData,
  SubjectGroupData
} from "../../../generated/data";
import {UtilityService} from "../../../utils/utility.service";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";
import {MatTableDataSource} from "@angular/material/table";
import {ProfessionSubGroupDetailsSheetComponent} from "../profession-sub-group-details-sheet/profession-sub-group-details-sheet.component";
import {ProfessionGroupDetailsSheetComponent} from "../profession-group-details-sheet/profession-group-details-sheet.component";
import {ProfessionMainGroupDetailsSheetComponent} from "../profession-main-group-details-sheet/profession-main-group-details-sheet.component";
import {ProfessionAreaDetailsSheetComponent} from "../profession-area-details-sheet/profession-area-details-sheet.component";

@Component({
  selector: 'app-profession-type-details-sheet',
  templateUrl: './profession-type-details-sheet.component.html',
  styleUrls: ['./profession-type-details-sheet.component.scss']
})
export class ProfessionTypeDetailsSheetComponent implements OnInit {

  public professionTypeId: number
  public professionTypeTitle: string
  public professionTypeData: ProfessionTypeData
  public professionDataSource = new MatTableDataSource()
  public loading: boolean = true

  public displayedColumns = ['agencyKey', 'professionTitle', 'numSubjectGroups', 'numSubjects']

  constructor(
      public utilityService: UtilityService,
      private professionResource: AdminProfessionResource,
      private adminSubjectResource: AdminSubjectResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.professionDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadProfession()
  }

  loadProfession(): void {
    this.loading = true
    this.professionResource.getProfessionType(this.professionTypeId).then(professionType => {
      this.professionTypeData = professionType
      this.professionDataSource.data = this.professionTypeData.professions
      this.loading = false;
    })
  }

  openProfessionDetailsSheet(profession: ProfessionData) {
    let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
    cref.instance.professionId = profession.id;
    cref.instance.professionTitle = profession.title;
  }

  openProfessionSubGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionSubGroupDetailsSheetComponent)
    cref.instance.professionSubGroupId = this.professionTypeData.subGroupId;
    cref.instance.professionSubGroupTitle = this.professionTypeData.subGroupTitle;
  }

  openProfessionGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionGroupDetailsSheetComponent)
    cref.instance.professionGroupId = this.professionTypeData.groupId;
    cref.instance.professionGroupTitle = this.professionTypeData.groupTitle;
  }

  openProfessionMainGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionMainGroupDetailsSheetComponent)
    cref.instance.professionMainGroupId = this.professionTypeData.mainGroupId;
    cref.instance.professionMainGroupTitle = this.professionTypeData.mainGroupTitle;
  }

  openProfessionAreaDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionAreaDetailsSheetComponent)
    cref.instance.professionAreaId = this.professionTypeData.areaId
    cref.instance.professionAreaTitle = this.professionTypeData.areaTitle;
  }

  applyProfessionFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.professionDataSource.filter = filterValue;
  }
}
