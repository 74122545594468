import {Component, OnInit} from '@angular/core';
import {AdminSubjectResource} from "../../../generated/resources";
import {DkzStudyAreaHierarchyTreeData} from "../../../generated/data";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'dkz-mapping-selection-dialog',
  templateUrl: './dkz-mapping-selection-dialog.component.html',
  styleUrls: ['./dkz-mapping-selection-dialog.component.scss']
})
export class DkzMappingSelectionDialogComponent implements OnInit {

  dataSource: DkzStudyAreaHierarchyTreeData[]
  clickButton: boolean = false

  constructor(
      private adminSubjectResource: AdminSubjectResource,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {

    this.adminSubjectResource.getAllDkzStudyMappings().then(result => {
      this.dataSource = result
    })
  }

  selectSubjectGroup(groupId: number) {
    this.clickButton = true
    this.sideSheetRef.sheetClosed.next(groupId)
  }

}
