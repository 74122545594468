import {Component, Inject, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AddressData, AdminEventData, LabelData} from "../../generated/data";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AdminCompanyResource, AdminEventResource, PublicEventResource} from "../../generated/resources";
import {FileService} from "../../services/file.service";
import {environment} from "../../../environments/environment";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
    selector: 'app-create-or-update-event-sheet',
    templateUrl: './create-or-update-event-sheet.component.html',
    styleUrls: ['./create-or-update-event-sheet.component.scss']
})
export class CreateOrUpdateEventSheetComponent implements OnInit {

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: false,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Text Eingeben...',
        translate: 'now',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Lato',
        toolbarHiddenButtons: [
            ['redo','undo','subscript','indent','outdent','customClasses','link','unlink','insertHorizontalRule','removeFormat','toggleEditorMode','heading','justifyRight']
        ]
    };

    data?: AdminEventData
    form: FormGroup
    today: Date
    selectedEventImage
    environmentVar

    saving: boolean = false

    constructor(
        private sideSheetRef: SideSheetRef,
        private fb: FormBuilder,
        private adminEventResource: AdminEventResource,
        public companyResource: AdminCompanyResource,
        public publicEventResource: PublicEventResource,
        private fileService: FileService,
    ) {
        this.today = new Date()
        this.today.setHours(0)
        this.today.setMinutes(0)
        this.today.setSeconds(0)

        this.environmentVar = environment
    }

    ngOnInit(): void {
        this.initForm()
    }

    initForm() {
        this.form = this.fb.group({
            title: this.data?.title,
            start: this.data?.start,
            end: this.data?.end,
            promotionId: this.data?.promotionId,
            shortDescription: this.data?.shortDescription,
            tileText: this.data?.tileText,
            longDescription: this.data?.longDescription,
            showEvent: this.data?.showEvent != null? this.data?.showEvent : false,
            videoLinkUnsafe: this.data?.videoLinkUnsafe,
            companies: null,

            streetName: this.data?.address?.streetName,
            streetNumber: this.data?.address?.streetNumber,
            cityId: this.data?.address?.cityId,
            zipCode: this.data?.address?.zipCode,
            fullAddress: this.data?.address?.fullAddress,
        })
        this.form.controls.companies.setValue(this.data?.companies.map(c => c.id))
    }


    createOrUpdateEvent() {
        this.saving = true

        let data: AdminEventData = <AdminEventData>{
            id: this.data?.id,
            title: this.form.controls.title.value,
            start: this.form.controls.start.value,
            promotionId: this.form.controls.promotionId.value,
            formatId: this.data?.formatId,
            end: this.form.controls.end.value,
            tileText: this.form.controls.tileText.value,
            shortDescription: this.form.controls.shortDescription.value,
            longDescription: this.form.controls.longDescription.value,
            showEvent: this.form.controls.showEvent.value,
            videoLinkUnsafe: this.form.controls.videoLinkUnsafe.value? this.form.controls.videoLinkUnsafe.value : null,
            address: <AddressData>{
                streetNumber: this.form.controls.streetNumber.value,
                streetName: this.form.controls.streetName.value,
                cityId: this.form.controls.cityId.value,
                fullAddress: this.form.controls.fullAddress.value
            },
            companies: this.form.controls.companies.value.map(id => <LabelData>{name: "", id: id}),
        }

        this.adminEventResource.createOrUpdateEvent(data).then(result => {
            this.sideSheetRef.sheetClosed.next(true)
        })
    }

    close(result?: boolean) {
        this.sideSheetRef.sheetClosed.next(result)
    }

    removeImage(id: number) {
        this.adminEventResource.removeImageFromEvent({id: this.data.id, fileId: id}).then(result => {
            if (result) this.data.images.splice(this.data.images.indexOf(id), 1)
        })
    }

    removePartnerLogo(id: number) {
        this.adminEventResource.removePartnerImageFromEvent({id: this.data.id, fileId: id}).then(result => {
            if (result) this.data.partnerLogos.splice(this.data.partnerLogos.indexOf(id), 1)
        })
    }

    removeHeader() {
        this.adminEventResource.removeHeaderImageFromEvent({id: this.data.id, fileId: this.data.eventHeader}).then(result => {
            if (result) this.data.eventHeader = null
        })
    }

    addHeader(fileEvent) {
        this.selectedEventImage = fileEvent.target.files[0];
        if (this.selectedEventImage) {
            return this.fileService.uploadEventHeader(this.data.id, this.selectedEventImage).toPromise().then(result => {
                if (result) this.data.eventHeader = result.value
            })
        }
    }

    addImage(fileEvent) {
        this.selectedEventImage = fileEvent.target.files[0];
        if (this.selectedEventImage) {
            return this.fileService.uploadEventImage(this.data.id, this.selectedEventImage).toPromise().then(result => {
                if (result) this.data.images.push(result.value)
            })
        }
    }

    addPartnerLogo(fileEvent) {
        this.selectedEventImage = fileEvent.target.files[0];
        if (this.selectedEventImage) {
            return this.fileService.uploadEventPartnerLogo(this.data.id, this.selectedEventImage).toPromise().then(result => {
                if (result) this.data.partnerLogos.push(result.value)
            })
        }
    }
}
