<loading-spinner [loadingBool]="loading" class="h-100 w-100">
    <div class="component-wrapper w-100 h-100">
        <mat-checkbox [formControl]="withoutSgMappingControl">ohne SG-Mapping</mat-checkbox>
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="filter m-top10" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                <mat-form-field style="width: 250px">
                    <input [formControl]="professionFilterControl" matInput placeholder="Profession">
                </mat-form-field>

                <searchable-profession-type-dropdown
                        [control]="professionTypeFilterControl"
                        [placeholder]="'ProfessionType'">
                </searchable-profession-type-dropdown>

                <searchable-profession-sub-group-dropdown
                        [control]="professionSubGroupFilterControl"
                        [placeholder]="'ProfessionSubGroup'">
                </searchable-profession-sub-group-dropdown>

                <searchable-profession-group-dropdown
                        [control]="professionGroupFilterControl"
                        [placeholder]="'ProfessionGroup'">
                </searchable-profession-group-dropdown>

                <searchable-profession-main-group-dropdown
                        [control]="professionMainGroupFilterControl"
                        [placeholder]="'ProfessionMainGroup'">
                </searchable-profession-main-group-dropdown>

                <searchable-profession-area-dropdown
                        [control]="professionAreaFilterControl"
                        [placeholder]="'ProfessionArea'">
                </searchable-profession-area-dropdown>

                <button mat-icon-button color="primary" matTooltip="Reload" (click)="loadProfessions()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <table mat-table [dataSource]="professions" class="w-100">
            <ng-container matColumnDef="professionTitle">
                <th mat-header-cell *matHeaderCellDef>Profession</th>
                <td mat-cell *matCellDef="let profession">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionDetailsSheet(profession)">
                        {{profession.title | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="professionTypeTitle">
                <th mat-header-cell *matHeaderCellDef>ProfessionType</th>
                <td mat-cell *matCellDef="let profession">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionTypeDetailsSheet(profession)">
                        {{profession.typeTitle | sliceStringLength: 40 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="professionSubGroupTitle">
                <th mat-header-cell *matHeaderCellDef>ProfessionSubGroup</th>
                <td mat-cell *matCellDef="let profession">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionSubGroupDetailsSheet(profession)">
                        {{profession.subGroupTitle | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="professionGroupTitle">
                <th mat-header-cell *matHeaderCellDef>ProfessionGroup</th>
                <td mat-cell *matCellDef="let profession">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionGroupDetailsSheet(profession)">
                        {{profession.groupTitle | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="professionMainGroupTitle">
                <th mat-header-cell *matHeaderCellDef>ProfessionMainGroup</th>
                <td mat-cell *matCellDef="let profession">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionMainGroupDetailsSheet(profession)">
                        {{profession.mainGroupTitle | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="professionAreaTitle">
                <th mat-header-cell *matHeaderCellDef>ProfessionArea</th>
                <td mat-cell *matCellDef="let profession">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionAreaDetailsSheet(profession)">
                        {{profession.areaTitle | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</loading-spinner>
