import {CompanyData} from "./company-data"
import {EmploymentCategory} from "./employment-category"
import {UploadedFileData} from "./uploaded-file-data"

export class ConsultantEmploymentPositionData {
    cities: string = null;
    company = new CompanyData();
    creationDate: (Date | null) = null;
    employmentCategory: EmploymentCategory = null;
    hasPrivacyDisclaimer: boolean = null;
    hiringCompanyName: string = null;
    hiringCompanyWebsite: string | null = null;
    id: number | null = null;
    isThirdPartyPosition: boolean = null;
    locationDescription: string = null;
    logo: UploadedFileData | null = null;
    startingDate: (Date | null) = null;
    title: string = null;
    webLink: string | null = null;
}