import {PositionDeliveryControllingData} from "./position-delivery-controlling-data"
import {EmploymentCategory} from "./employment-category"
import {EmploymentPositionState} from "./employment-position-state"

export class CustomerPositionDeliveryBoardData {
    companyId: number = null;
    companyName: string = null;
    deliveryControllingData = new PositionDeliveryControllingData();
    employmentCategory: EmploymentCategory = null;
    id: number = null;
    positionState: EmploymentPositionState = null;
    title: string = null;
}