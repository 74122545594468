import {Component, Input, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {FormControl} from "@angular/forms";
import {AdminApplicationResource} from "../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-create-application-dialog',
  templateUrl: './create-application-dialog.component.html',
  styleUrls: ['./create-application-dialog.component.scss']
})
export class CreateApplicationDialogComponent implements OnInit {

   @Input() talentId: number

  createApplicationLoading: boolean = false

  searchPositionIdControl: FormControl = new FormControl()

  constructor(
      private sideSheetRef: SideSheetRef,
      private adminApplicationResource: AdminApplicationResource,
      private matSnackbar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
  }

  createApplication() {
     this.createApplicationLoading = true
     this.adminApplicationResource.createSubsequentApplication(this.talentId, {positionId: this.searchPositionIdControl.value}).then(() => {
       this.createApplicationLoading = false
       this.matSnackbar.open('Bewerbung wurde erstellt')
       this.close(true)
     })
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

}
