import { Component, OnInit } from '@angular/core';
import {AdminFeedControlResource} from "../../generated/resources";
import {
  AdminEmploymentPositionData, AdminEmploymentPositionDisplayData,
  AdminPositionFeedPerformanceData,
  AdminSubjectGroupToFrequencyData
} from "../../generated/data";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {PositionSubjectGroupPerformanceSheetComponent} from "../position-subject-group-performance-sheet/position-subject-group-performance-sheet.component";

@Component({
  selector: 'app-feed-control',
  templateUrl: './feed-control.component.html',
  styleUrls: ['./feed-control.component.scss']
})
export class FeedControlComponent implements OnInit {

  constructor(private feedControlResource: AdminFeedControlResource, private dialogService: SideSheetService) { }
subjectGroups: AdminSubjectGroupToFrequencyData[];

  startDate = new Date();
  endDate = new Date();
  subjectGroupId;

  data: AdminPositionFeedPerformanceData[];

  showRelativeValues = true;

  ngOnInit(): void {
    this.startDate.setDate(new Date().getDate() - 28)
   this.loadSubjectGroups()
  }

  loadingSubjectGroups = false;
  loadSubjectGroups(){
    this.loadingSubjectGroups = true;
    this.feedControlResource.getSubjectGroupsByAmountOfRecentlyCreatedTalent().then(res => {
      this.subjectGroups = res;
      this.loadingSubjectGroups = false;
    })
  }

  loading = false;

  load(){
    this.loading = true;
    this.feedControlResource.getFeedpushesAndPerformancesByPosition({startDate: this.startDate, endDate: this.endDate, subjectGroupId: this.subjectGroupId}).then(res => {
      this.data = res;
      this.loading = false;
      this.analyzeData()
    })
  }

  avgApplicationRate;
  //this could be made way smarter using quartiles. this is just an example that doesnt really make much sense
  analyzeData(){
  /*  let totalApplications;
    let totalPushes;
    for(let element of this.data){
      totalApplications = element.appliedCount
      totalPushes = element.totalPushes
    }
    this.avgApplicationRate = totalApplications / totalPushes*/
  }

  openPosition(positionId:number){
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId;
  }

  openSubjectGroupPerformance(position:AdminEmploymentPositionDisplayData){
    let comp = this.dialogService.openOverlay(PositionSubjectGroupPerformanceSheetComponent, 'Huge')
    comp.instance.position = position;
  }

}
