<div class="controlling-board-info">
    Hier werden Perfect-Match Events verwaltet. Die Events werden NICHT manuell erstellt sondern von den aktiven Promotions aus Cronos jede Nacht synchronisiert. Der Sync mit Cronos kann aber auch hier manuell ausgelöst werden, dann werden alle bisher nicht in TA existierenden Events angelegt.
</div>

<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" style="width: 100%; padding: 0 8px">
    <button *ngIf="data.length" mat-flat-button color="primary" (click)="openEditEventContentSheet(data[data.length-1])">
        <i class="mdi mdi-pencil"></i>
        Perfect-Match Content bearbeiten</button>
    <button *ngIf="data.length" mat-flat-button color="primary" (click)="openEditEventSubjectTargetingSheetComponent(data[data.length-1])">
        <i class="mdi mdi-pencil"></i>
        Perfect-Match Studiengangzielgruppe bearbeiten</button>
    <loading-button color="primary" (clickEvent)="updatePerfectMatchEvents()" [loadingBool]="syncPerfectMatchEvents">
        <i class="mdi mdi-sync"></i>
        Sync mit Cronos</loading-button>
</div>

<table mat-table [dataSource]="data" class="max-table" #eventTable>

    <ng-container matColumnDef="id">
        <th  mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th  mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">
            <div style="white-space: nowrap; padding: 0 8px">{{element.title}}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="start">
        <th  mat-header-cell *matHeaderCellDef>start</th>
        <td mat-cell *matCellDef="let element"> {{element.start | date: 'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="showEvent">
        <th  mat-header-cell *matHeaderCellDef>Wird angezeigt</th>
        <td mat-cell *matCellDef="let element">
            <span>{{element.showEvent? "Ja" : "Nein"}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="companyName">
        <th  mat-header-cell *matHeaderCellDef>companyName</th>
        <td mat-cell *matCellDef="let element" style="max-width: 180px">
            <span>{{getCompaniesString(element?.companies)}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th  mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="text-align: end">
            <button matTooltip="Event bearbeiten" mat-icon-button (click)="openCreateOrUpdateDialog(element)"><i class="mdi mdi-pencil"></i></button>
            <button matTooltip="Filterdialog öffnen um targetierungs Excel herunterzuladen" mat-icon-button (click)="openEditEventFilterDialog(element)"><i class="mdi mdi-filter"></i></button>
            <button matTooltip="Anmeldungen als Excel herunterladen" mat-icon-button (click)="downloadRegistrationsExcel(element.id)"><i class="mdi mdi-download"></i></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>