import {ProfessionMatchingScore} from "./profession-matching-score"
import {AdminEmploymentPositionDisplayData} from "./admin-employment-position-display-data"
import {TalentPositionRelationState} from "./talent-position-relation-state"
import {ProfessionFieldMatchingScore} from "./profession-field-matching-score"
import {AdminTalentData} from "./admin-talent-data"

export class AdminTalentPositionRelationData {
    isBookmarked: boolean = null;
    isPrimeFilterMatch: boolean = null;
    locationSuitable: boolean | null = null;
    notReachedEmailSentDate: (Date | null) = null;
    pScore: ProfessionMatchingScore | null = null;
    position = new AdminEmploymentPositionDisplayData();
    pushedInFeedDate: (Date | null) = null;
    recommendationDate: (Date | null) = null;
    relationState: TalentPositionRelationState = null;
    tScore: ProfessionFieldMatchingScore | null = null;
    talent = new AdminTalentData();
}