import {ApiCustomerReferenceKey} from "./api-customer-reference-key"
import {IpLocationData} from "./ip-location-data"
import {OnboardingVersion} from "./onboarding-version"
import {LocationPreferenceMode} from "./location-preference-mode"

export class S2OTalentCreationData {
    apiReferenceKey: ApiCustomerReferenceKey | null = null;
    browser: string | null = null;
    browserLanguage: string | null = null;
    browserVersion: string | null = null;
    consent_facebook_oc: boolean | null = null;
    consent_google_oc: boolean | null = null;
    deviceType: string | null = null;
    email: string = null;
    fbclid: string | null = null;
    firstName: string = null;
    forcedFieldsOfActivity: number[] | null = null;
    gclid: string | null = null;
    invitedFromToken: string | null = null;
    ipLocation = new IpLocationData();
    lastName: string = null;
    onboardingVersion: OnboardingVersion | null = null;
    phoneNumber: string = null;
    preferredCityIds: number[] | null = null;
    preferredFieldIds: number[] = null;
    searchMode: LocationPreferenceMode = null;
    studyEndDate: (Date | null) = null;
    subjectId: number = null;
    utmCampaign: string | null = null;
    utmContent: string | null = null;
    utmMedium: string | null = null;
    utmSource: string | null = null;
    utmTerm: string | null = null;
}