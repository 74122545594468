<div class="standard-container mat-elevation-z6">
  <mat-tab-group>
    <mat-tab label="Beraterdaten">
      <div class="button-bar" fxLayout="row" fxLayoutAlign="end center">
        <loading-button [loadingBool]="loading"
                        [disabledCondition]="contactForm.invalid"
                        [raised]="false"
                        matTooltip="Speichern"
                        buttonClass="mat-primary"
                        (clickEvent)="updateConsultantAccount()">
          <i class="mdi mdi-content-save main-action-icon" [ngClass]="{'disabled' : contactForm.invalid}"></i>
        </loading-button>
      </div>

      <div class="content-wrapper" fxLayout="row" fxLayoutAlign="start center">
        <div class="picture-wrapper" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
          <img *ngIf="!noTalentAgentPicture && talentAgentPicture != null" [src]="talentAgentPicture"
               style="max-width: 200px; max-height: 200px">
          <img *ngIf="noTalentAgentPicture || talentAgentPicture == null"
               src="../../../assets/images/blurred_user.png"
               style="max-width: 200px; max-height: 200px">
          <input type="file" id="talent-agent-picture" class="inputfile"
                 (change)="onFileChange($event,'talentAgent')"/>
          <label for="talent-agent-picture"><i class="mdi mdi-upload"></i> Bild auswählen</label>
        </div>

        <form [formGroup]="contactForm" *ngIf="consultant">
          <div class="form-row">
            <mat-form-field class="width-320px">
              <input formControlName="firstName" matInput placeholder="Vorname">
            </mat-form-field>

            <mat-form-field class="width-320px">
              <input formControlName="lastName" matInput placeholder="Nachname">
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="width-320px">
              <input formControlName="emailAddress" matInput placeholder="Emailadresse">
            </mat-form-field>

            <mat-form-field class="width-320px">
              <input formControlName="phoneNumber" matInput placeholder="Telefonnummer">
            </mat-form-field>
          </div>

          <div class="form-row" fxLayout="row" fxLayoutAlign="start start">
            <div fxLayout="row" fxLayoutAlign="start baseline" class="width-320px">
              <div><b>{{consultant?.talentCount}}</b> Talente zugewiesen</div>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>

    <mat-tab label="Bewertung">

      <div class="standard-container" fxLayout="column" style="margin: 0">
        <div class="button-bar" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <table-download-button color="primary"
                                 excelName="Berater_Bewertungen.xlsx"
                                 [buttonClass]="'main-action-icon'"
                                 [matTable]="ratingTable">
          </table-download-button>
        </div>

        <loading-spinner [loadingBool]="ratingLoading">
          <table mat-table [dataSource]="consultantRatingData" class="w-100" #ratingTable>

            <ng-container matColumnDef="talent">
              <th mat-header-cell *matHeaderCellDef> Talent</th>
              <td mat-cell *matCellDef="let element">{{element.talentFirstName}} {{element.talentLastName}}</td>
            </ng-container>

            <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef> Sterne</th>
              <td mat-cell *matCellDef="let element">{{element.rating}}</td>
            </ng-container>

            <ng-container matColumnDef="ratingComment">
              <th mat-header-cell *matHeaderCellDef> Kommentar</th>
              <td mat-cell *matCellDef="let element" style="max-width: 320px;">
                <div style="padding: 16px 0;" *ngIf="element.ratingComment && element.ratingComment != ''">{{element.ratingComment}}</div>
                <div class="no-value" *ngIf="!element.ratingComment || element.ratingComment == ''">Kein Kommentar angegeben</div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div class="text-center" style="color:grey; padding: 36px" *ngIf="!consultantRatingData?.length">Dieser Berater hat noch keine Bewertungen erhalten</div>

          <mat-paginator [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         [showFirstLastButtons]="true"
                         [length]="totalSize"
                         [hidePageSize]="false"
                         [pageIndex]="pageIndex"
                         (page)="handlePage($event)">
          </mat-paginator>
        </loading-spinner>
      </div>

    </mat-tab>
  </mat-tab-group>

</div>
