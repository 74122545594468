import { Component, OnInit } from '@angular/core';
import {LabelData, ProfessionData, SubjectData, SubjectGroupData} from "../../../generated/data";
import {UtilityService} from "../../../utils/utility.service";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {SubjectGroupDetailsSheetComponent} from "../subject-group-details-sheet/subject-group-details-sheet.component";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";
import {MatTableDataSource} from "@angular/material/table";
import {RemoveSubjectToGroupMappingPreviewSheetComponent} from "../remove-subject-to-group-mapping-preview-sheet/remove-subject-to-group-mapping-preview-sheet.component";

@Component({
  selector: 'app-subject-details-sheet',
  templateUrl: './subject-details-sheet.component.html',
  styleUrls: ['./subject-details-sheet.component.scss']
})
export class SubjectDetailsSheetComponent implements OnInit {

  public subjectId: number
  public subjectTitle: string
  public subjectData: SubjectData
  public subjectGroupData: SubjectGroupData[] = []
  public subjectGroupDataSource = new MatTableDataSource()
  public professionData: ProfessionData[] = []
  public professionDataSource = new MatTableDataSource()
  public loading: boolean = true

  public displayedSubjectGroupColumns = ['subjectGroupTitle', 'numSubjects', 'numProfessions', 'actions']
  public displayedProfessionColumns = [ 'agencyKey', 'professionTitle','numSubjectGroups', 'numSubjects']

  constructor(
      public utilityService: UtilityService,
      private adminSubjectResource: AdminSubjectResource,
      private adminProfessionResource: AdminProfessionResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.subjectGroupDataSource.filterPredicate = (s: SubjectGroupData, filter: string) => s.title.toLocaleLowerCase().includes(filter) == true;
    this.professionDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadSubject()
  }

  loadSubject(): void {
    this.loading = true
    this.adminSubjectResource.getSubject(this.subjectId, {professionWithGroupNames: true}).then(subject => {
      this.subjectData = subject

      Promise.all([
        this.adminSubjectResource.getSubjectGroups({subjectGroupIds: subject.subjectGroups.map(sg => sg.id)}).then(subjectGroups => {
          this.subjectGroupData = subjectGroups
          this.subjectGroupDataSource.data = this.subjectGroupData
        }),
        this.adminProfessionResource.getProfessionsByIds(subject.professions.map(p => p.id)).then(professions => {
          this.professionData = professions
          this.professionData.forEach(p => p.title = subject.professions.find(p2 => p2.id == p.id).name)
          this.professionDataSource.data = this.professionData
          })
      ]).then(() => {
        this.loading = false
      })
    })
  }

  deleteSubjectGroupMapping(subjectGroup: LabelData) {
    let cref = this.dialogService.openOverlay(RemoveSubjectToGroupMappingPreviewSheetComponent)
    cref.instance.subjectId = this.subjectId
    cref.instance.subjectGroupId = subjectGroup.id

    cref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      this.loadSubject();
    })
  }

  openSubjectGroupDetailsSheet(subjectGroup: SubjectGroupData) {
    let cref = this.dialogService.openOverlay(SubjectGroupDetailsSheetComponent)
    cref.instance.subjectGroupId = subjectGroup.id;
    cref.instance.subjectGroupTitle = subjectGroup.title;
  }

  openProfessionDetailsSheet(profession: ProfessionData) {
    let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
    cref.instance.professionId = profession.id;
    cref.instance.professionTitle = profession.title;
  }

  applyFilter(dataSource: MatTableDataSource<any>, filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    dataSource.filter = filterValue;
  }
}
