import {ProfessionTreeData} from "./profession-tree-data"
import {EmploymentPositionDisplayData} from "./employment-position-display-data"
import {SubjectGroupData} from "./subject-group-data"
import {AdminTalentData} from "./admin-talent-data"
import {ProfessionMatchingLevel} from "./profession-matching-level"
import {ProfessionMatchingScore} from "./profession-matching-score"
import {ProfessionFieldMatchingScore} from "./profession-field-matching-score"

export class TalentMatchingReportData {
    isSubjectGroupMappedToProfession: boolean = null;
    manualMatch: boolean = null;
    matchedPosition = new EmploymentPositionDisplayData();
    matchedPositionProfessionTree: ProfessionTreeData | null = null;
    matchedSubjectGroup: SubjectGroupData | null = null;
    matchedSubjectProfessionTree: ProfessionTreeData | null = null;
    matchedTalent = new AdminTalentData();
    matchedViaProfessionGroupTitle: string | null = null;
    matchedViaProfessionMaingroupTitle: string | null = null;
    matchedViaProfessionSubGroupTitle: string | null = null;
    matchedViaProfessionTypeTitle: string | null = null;
    matchingLevel: ProfessionMatchingLevel = null;
    pScore: ProfessionMatchingScore | null = null;
    tScore: ProfessionFieldMatchingScore = null;
}