import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    error: string | null = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                    const authCode = params['code'] // authorization code (OAuth)
                    if (authCode) {
                        const codeVerifier = localStorage.getItem("code_verifier")
                        localStorage.removeItem("code_verifier")
                        this.authService.oauthLogin(authCode, codeVerifier)
                            .subscribe((result) => {
                                if(result == 'LoginFailedRoleIncorrect') {
                                    window.location.href = `${environment.uniSignUrl}/logout?delegate=true`
                                } else {
                                    return this.router.navigateByUrl('/talents')
                                }
                            }, (error: HttpErrorResponse) => {
                                this.error = error.error?.error ?? error.message
                            })
                    }
                }
            )
    }

    oauthLogin() {
        const codeVerifier = OAuthPKCE.generateCodeVerifier()
        localStorage.setItem("code_verifier", codeVerifier)
        OAuthPKCE.generateCodeChallenge(codeVerifier).then(codeChallenge => {
            // https://datatracker.ietf.org/doc/html/rfc7636#section-4.3
            window.location.href = `${environment.uniSignUrl}/oauth2/authorize?delegate=true&response_type=code&client_id=${environment.frontendUnisignClientId}&redirect_uri=${environment.appLoginRedirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`
        })
        return false // avoid following the href link from the <a> tag
    }
}

class OAuthPKCE {

    /**
     * Generate a code verifier.
     * This method creates a base64url-encoded random byte array of length 32.
     *
     * https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
     *
     * @returns {Promise<string>} code_verifier
     */
    static generateCodeVerifier(): string {
        const randomBytes = new Uint8Array(32);
        window.crypto.getRandomValues(randomBytes);
        return this.base64UrlEncode(randomBytes);
    }

    /**
     * Generate a code challenge from a code verifier.
     * By default, it uses S256, but it can fall back to plain if needed.
     *
     * https://datatracker.ietf.org/doc/html/rfc7636#section-4.2
     *
     * @param {string} codeVerifier
     * @param {string} method "S256" or "plain". Default is "S256"
     * @returns {Promise<string>} code_challenge
     */
    static async generateCodeChallenge(codeVerifier: string, method: string = "S256"): Promise<string> {
        if (method === "plain") {
            return codeVerifier;
        } else if (method === "S256") {
            const encoder = new TextEncoder();
            const data = encoder.encode(codeVerifier);
            const hashed = await window.crypto.subtle.digest('SHA-256', data);
            return this.base64UrlEncode(new Uint8Array(hashed));
        } else {
            throw new Error("Invalid code challenge method");
        }
    }

    /**
     * Base64Url encode a Uint8Array.
     *
     * @param {Uint8Array} data
     * @returns {string} encoded string
     */
    static base64UrlEncode(data: Uint8Array): string {
        let base64 = btoa(String.fromCharCode(...data));
        return base64
            .replace('+', '-')
            .replace('/', '_')
            .replace(/=+$/, ''); // Remove trailing '=' characters
    }
}
