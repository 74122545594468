import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    SearchableProfessionDropdownComponent
} from "../../../utils/dropdowns/searchable-profession-dropdown/searchable-profession-dropdown.component";
import {LabelData, PositionContentSplitData, UpdatePositionContentSplitData,} from "../../../generated/data";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AdminPositionContentSplitResource,} from "../../../generated/resources";
import {UtilityService} from "../../../utils/utility.service";
import {FileService} from "../../../services/file.service";
import {environment} from "../../../../environments/environment";
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
    selector: 'position-content',
    templateUrl: './position-content.component.html',
    styleUrls: ['./position-content.component.scss']
})
export class PositionContentComponent implements OnInit {

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: false,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Text Eingeben...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Lato',
        toolbarHiddenButtons: [
            ['justifyLeft', 'justifyCenter', 'justifyFull', 'strikeThrough', 'underline', 'redo', 'undo', 'subscript', 'superscript', 'indent', 'outdent', 'fontName', 'fontSize', 'textColor', 'backgroundColor', 'customClasses', 'link', 'unlink',
                'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'heading', 'justifyRight']
        ]
    };

    @ViewChild('professionDropdown') professionDropdown: SearchableProfessionDropdownComponent

    @Input() split: PositionContentSplitData
    @Input() activeSplitCount: number = 0
    @Output() deleted: EventEmitter<number> = new EventEmitter<number>()
    @Output() activeSplitRecalc: EventEmitter<any> = new EventEmitter<any>()


    splitFormGroup: FormGroup = null;
    updateContentSplitLoading: boolean = false;

    enviromentVar

    selectedSplitImage: any;
    loadingDeleteSplit: boolean = false
    formInitialized: boolean = false

    positionImage: FormControl = new FormControl(null)

    filteredBenefits: string[] = []
    benefits: string[] = []


    constructor(
        private fb: FormBuilder,
        private utilityService: UtilityService,
        private fileService: FileService,
        private adminPositionContentSplitRescource: AdminPositionContentSplitResource,
    ) {
        this.enviromentVar = environment

        this.splitFormGroup = this.fb.group({
            positionDescription: "",
            miscellaneousDescription: "",
            benefitsIntro: "",
            companyDescription: "",
            positionRequirements: "",
            positionVideoLink: null,
            usp1: [null, [Validators.maxLength(60)]],
            usp2: [null, [Validators.maxLength(60)]],
            usp3: [null, [Validators.maxLength(60)]],
            benefits: null,
            positionImage: null,
            title:null,
            internTitle: ""
        })
    }

    ngOnInit(): void {
        let benefits = this.split.benefits.map(b => b.id)
        this.splitFormGroup.patchValue({
            positionDescription: this.split.positionDescription,
            miscellaneousDescription: this.split.miscellaneousDescription,
            companyDescription: this.split.companyDescription,
            positionRequirements: this.split.positionRequirements,
            benefitsIntro: this.split.benefitsIntro,
            positionVideoLink: this.split.videoLinkUnsafe,
            usp1: this.split.keywords[0] || null,
            usp2: this.split.keywords[1] || null,
            usp3: this.split.keywords[2] || null,
            benefits: benefits,
            positionImage: null,
            title:this.split.title,
            internTitle: this.split.internTitle
        })

        this.formInitialized = true;

    }

    routeToPreview() {
        window.open(environment.talentFrontendUrl + '/position-preview/' + this.split.positionId + `?splitId=${this.split.id}`, '_blank')
    }

    changeSplitState(event) {
        this.adminPositionContentSplitRescource.changePositionContentSplitState(this.split.id, {newState: event})
        this.activeSplitRecalc.emit(true)
    }

    onFileInput(fileEvent) {
        this.selectedSplitImage = fileEvent.target.files[0];
        if (this.selectedSplitImage) {
            return this.fileService.uploadPositionContentSplitPicture(this.split.id, this.selectedSplitImage).subscribe(
                (result: LabelData) => this.split.positionCustomerImages.push({id: result.id, name: result.name})
            )
        }
    }

    deleteContentSplit() {
        this.loadingDeleteSplit = true
        this.adminPositionContentSplitRescource.deletePositionContentSplit(this.split.id).then(result => {
            this.deleted.emit(this.split.id)
            this.activeSplitRecalc.emit(true)
            this.loadingDeleteSplit = false
        })
    }

    updateContentSplit() {

        if (!this.splitFormGroup.controls['title'].value) return

        this.updateContentSplitLoading = true

        let data = <UpdatePositionContentSplitData>{
            companyDescription: this.splitFormGroup.controls['companyDescription'].value,
            keywords: [
                this.splitFormGroup.controls['usp1'].value,
                this.splitFormGroup.controls['usp2'].value,
                this.splitFormGroup.controls['usp3'].value
            ],
            miscellaneousDescription: this.splitFormGroup.controls['miscellaneousDescription'].value,
            positionDescription: this.splitFormGroup.controls['positionDescription'].value,
            positionRequirements: this.splitFormGroup.controls['positionRequirements'].value,
            splitId: this.split.id,
            title: this.splitFormGroup.controls['title'].value,
            internTitle: this.splitFormGroup.controls['internTitle'].value,
            videoLinkUnsafe: this.splitFormGroup.controls['positionVideoLink'].value,
            benefitIds: this.splitFormGroup.controls['benefits'].value,
            benefitsIntro: this.splitFormGroup.controls['benefitsIntro'].value,
            apiHeaderImage: this.split.positionApiHeader,
            pictures: this.split.positionCustomerImages,
        }

        this.adminPositionContentSplitRescource.updatePositionSplit(data, this.split.id).then(result => {
            this.utilityService.showSnackBar('Split updated')
            this.updateContentSplitLoading = false
        })
    }

    deleteFile(fileId: number, splitId: number) {
        this.adminPositionContentSplitRescource.deletePositionSplitPicture({id: splitId, fileId: fileId})
        this.split.positionCustomerImages.splice(this.split.positionCustomerImages.findIndex(img => img.id == fileId), 1)
    }

}
