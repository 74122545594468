<dialog-header [dialogHeadline]="'Rechnung anlegen / bearbeiten'"></dialog-header>

<div id="dialog-content-wrapper" class="layout-padding" *ngIf="invoice">
    <div class="content" [formGroup]="invoiceForm">
        <div class="m-bottom15"><b>Rechnung: {{this.invoice?.id}}
             <span *ngIf="invoice?.booking?.id">für Buchung: {{this.invoice?.booking.id}}</span>
             <span *ngIf="invoice?.application?.id">für Bewerbung: {{this.invoice?.application.id}}</span>
        </b>

            <div class="m-top15" *ngIf="invoice?.id">
                <div><span class="min-150">Rechnung erstellt am:</span> {{invoice.createdAt | date:'dd.MM.yy'}}</div>
            </div>

            <div class="m-top15" *ngIf="invoice?.booking?.id">
                <div><span class="min-150">Bewerbungen:</span> {{invoice.booking.applications.length}} / {{invoice.booking.size}}</div>
                <div><span class="min-150">Nachlieferungen:</span> {{invoice.booking.applications.length>invoice.booking.size? invoice.booking.applications.length - invoice.booking.size : 0}} / {{invoice.booking.resupply}}</div>
            </div>

            <div class="m-top15" *ngIf="invoice?.application?.id"><span class="min-150">Für: </span> <span>Bewerbung: {{this.invoice?.application.id}}</span>
                <div><span class="min-150">Stelle:</span> {{invoice.application.position.title}} ({{invoice.application.position.id}})</div>
                <div><span class="min-150">Talent:</span> {{invoice.application.talent.firstName}} {{invoice.application.talent.lastName}} ({{invoice.application.talent.id}})</div>
            </div>

        </div>

        <div class="m-bottom15" *ngIf="companyPricing">
            <div class="m-top15 m-bottom15"><b>Unternehmen / Pricing:</b></div>
            <div><span class="min-150">Name:</span> {{companyPricing.company.name}} ({{companyPricing.company.id}})</div>
            <div><span class="min-150">Adresse:</span>{{companyAddressData?.streetName}} {{companyAddressData?.streetNumber}} {{companyAddressData?.cityName}} {{companyAddressData?.zipCode}}</div>

            <div><span class="min-150">Unternehmens-Kommentar:</span> {{companyPricing.comment}}</div>
        </div>

        <div fxLayout="column" fxLayoutAlign="start start">
            <mat-form-field>
                <mat-label>Rechnungsbetrag</mat-label>
                <input matInput type="number" formControlName="amount">
            </mat-form-field>

            <mat-form-field>
                <input matInput [matDatepicker]="pickerFrom" placeholder="Leistungsdatum"
                       name="dateFrom" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
        </div>
        <mat-form-field class="width-100">
            <mat-label>Kommentar</mat-label>
            <textarea matInput formControlName="comment"></textarea>
        </mat-form-field>

    </div>
    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="false"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        [disabledCondition]="this.invoiceForm.invalid"
                        (clickEvent)="createInvoice()">
            Rechnung speichern
        </loading-button>
    </div>
</div>
