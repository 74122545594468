import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {AdminCompanyData, AdminEmploymentPositionDisplayData, AdminTalentData} from "../../../generated/data";
import {AdminCompanyResource, AdminEmploymentPositionResource, AdminTalentResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-talent-dropdown',
  templateUrl: './searchable-talent-dropdown.component.html',
  styleUrls: ['./searchable-talent-dropdown.component.scss']
})
export class SearchableTalentDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = false
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  talents: AdminTalentData[] = [];

  filterControl: FormControl = new FormControl()

  loadingTalents: boolean = false

  constructor(
      private adminTalentResource: AdminTalentResource
  ) {
  }

  ngOnInit() {
    this.filterControl.valueChanges.pipe(debounceTime(500))
        .subscribe(next => {
          if (this.filterControl.value.toString().length > 0) {
            this.loadTalents();
          }
        });
  }


  loadTalents() {
    this.loadingTalents = true
    this.adminTalentResource.getTalentsByQuery({q: this.filterControl.value, pageSize: 30, forcedIds: this.control.value}).then(talents => {
          this.talents = talents.content;
          this.loadingTalents = false
        }
    )
  }

  selectAll() {
    this.control.setValue(this.talents.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}


