import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AdminPrimePositionRecommendationResource} from "../../generated/resources";
import {
  AdminTalentFeedPositionRelationData,
  EmploymentPositionRecommendationData,
  LabelData
} from "../../generated/data";

@Component({
  selector: 'create-position-recommendations-dialog',
  templateUrl: './create-position-recommendations-dialog.component.html',
  styleUrls: ['./create-position-recommendations-dialog.component.scss']
})
export class CreatePositionRecommendationsDialogComponent implements OnInit {

  mode: 'SinglePositionMode' | 'SingleTalentMode' = 'SingleTalentMode'
  messagesForm: FormGroup = new FormGroup({});
  saving: boolean = false
  positions: LabelData[]
  talents: AdminTalentFeedPositionRelationData[]

  positionId: number
  talentId: number

  constructor(
      private sideSheetRef: SideSheetRef,
      private adminRecommendationResource: AdminPrimePositionRecommendationResource,
      private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if(this.mode == 'SingleTalentMode') {
      this.positions.forEach(position => {this.messagesForm.addControl(
          position.name,
          new FormControl(
              'Diese Stelle passt gut zu deinem Profil und bietet eine super Chance für dich!',
              [Validators.minLength(30), Validators.maxLength(150)]
          )
      )});
    } else {
      this.talents.forEach(relationData => {this.messagesForm.addControl(
          relationData.talentName,
          new FormControl(
              'Diese Stelle passt gut zu deinem Profil und bietet eine super Chance für dich!',
              [Validators.minLength(30), Validators.maxLength(150)]
          )
      )});
    }
  }

  getFormControl(positionName: string) {
    return this.messagesForm.controls[positionName]
  }

  close(result: EmploymentPositionRecommendationData[] = []) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  save() {
    this.saving = true
    let data: EmploymentPositionRecommendationData[]

    if(this.mode == 'SingleTalentMode') {
      data = this.positions.map(p => <EmploymentPositionRecommendationData>{positionId: p.id, talentId: this.talentId, recommendationMessage: this.getFormControl(p.name).value})
    } else {
      data = this.talents.map(t => <EmploymentPositionRecommendationData>{positionId: this.positionId, talentId: t.talentId, recommendationMessage: this.getFormControl(t.talentName).value})
    }

    this.adminRecommendationResource.createEmploymentPositionRecommendations(data).then(() => {
      this.snackBar.open("Die Stellen wurden erfolgreich empfohlen", null, {duration: 3000})
      this.close(data)
      this.saving = false
    })
  }
}
