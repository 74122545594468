import {Component, OnInit} from '@angular/core';
import {
  AdminEmploymentPositionData,
  AdminTalentFeedPositionRelationData,
  TalentFilterData,
  TalentMatchingReportData,
  TalentPositionRelationState,
  TalentTargetingFilterData
} from "../../generated/data";
import {ActivatedRoute} from "@angular/router";
import {AdminEmploymentPositionResource, AdminTalentResource} from "../../generated/resources";
import {PositionDetailsDialogComponent} from "../position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {FormControl} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {TalentFilterSheetComponent} from "./talent-filter-sheet/talent-filter-sheet.component";
import {PageEvent} from "@angular/material/paginator";
import {CreatePositionRecommendationsDialogComponent} from "../../talents/create-position-recommendations-dialog/create-position-recommendations-dialog.component";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {MatchingAnalyzeSheetComponentComponent} from "../../matching/matching-optimization/matching-analyze-sheet-component/matching-analyze-sheet-component.component";

@Component({
  selector: 'app-talent-targeting',
  templateUrl: './talent-targeting.component.html',
  styleUrls: ['./talent-targeting.component.scss']
})
export class TalentTargetingComponent implements OnInit {

  public talentFilters: TalentFilterData[] = [];
  public considerFilterCheckbox: FormControl = new FormControl(false)
  public considerMatchCheckbox: FormControl = new FormControl(false)
  public considerProfessionFieldPreferenceCheckbox: FormControl = new FormControl(false)
  public considerLocationPreferenceCheckbox: FormControl = new FormControl(false)
  public interactionRequiredControl: FormControl = new FormControl(null)
  public positionData: AdminEmploymentPositionData;
  public talentData: AdminTalentFeedPositionRelationData[] = [];
  public loadingPosition: Boolean = true;
  public loadingTalents: Boolean = false;
  public recommendingPosition: Boolean = false;
  public interactionOptions: TalentPositionRelationState[] = ["Seen", "Clicked", "Bookmarked", "CTAClicked"]
  public selection = new SelectionModel<AdminTalentFeedPositionRelationData>(true, []);
  public displayedColumns = ['select','creationDate', 'isPrimeMatch', 'name','subject', 'professionGroupMatch', 'locationPreferenceMatch', 'PushedInFeed','state','actions']

  public pageIndex: number = 0;
  public pageSize: number = 15;
  public pageSizeOptions: number[] = [15, 30, 60, 100];
  public totalSize: number = 0;

  private positionId: number;

  constructor(
      private route: ActivatedRoute,
      private positionRessource: AdminEmploymentPositionResource,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private adminTalentResource: AdminTalentResource,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.positionId = parseInt(this.route.snapshot.paramMap.get('positionId'))
    this.loadTalentFilters();
    this.positionRessource.getSingleEmploymentPositionById(this.positionId).then(result => {
      this.positionData = result
      this.loadingPosition = false
    })
  }

  loadTalents() {
    this.loadingTalents = true;

    let filterData = <TalentTargetingFilterData> {
      talentFilterData: this.talentFilters,
      considerFilterData: this.considerFilterCheckbox.value,
      considerHasMatch: this.considerMatchCheckbox.value,
      considerLocationPreference: this.considerLocationPreferenceCheckbox.value,
      considerProfessionFieldPreference: this.considerProfessionFieldPreferenceCheckbox.value,
      requiredInteraction: this.interactionRequiredControl.value,
      positionId: this.positionId,
      onlyUnrecommended: true
    }

    this.adminTalentResource.getTalentsByPositionTargetingFilter(filterData,{ page: this.pageIndex, pageSize: this.pageSize}).then(talents => {
      this.talentData = talents.content
      this.totalSize = talents.totalElements
      this.loadingTalents = false;
    })
  }

  loadTalentFilters() {
    if(this.positionId == null) return
    this.adminEmploymentPositionResource.getPrimeTalentFiltersOfPosition(this.positionId).then(filters => {
      this.talentFilters = filters
    })
  }

  openPositionDetailsDialog() {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = this.positionId
    comp.instance.tabIndex = 0
  }

  openTalentFilterDialog() {
    let comp = this.dialogService.openOverlay(TalentFilterSheetComponent, 'Huge')
    comp.instance.positionId = this.positionId
    comp.instance.filters = this.talentFilters

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(filters => {
      if(filters) this.talentFilters = filters
      subscription.unsubscribe();
    })
  }

  openCreatePositionRecommendationsDialog() {
    const comp = this.dialogService.openOverlay(CreatePositionRecommendationsDialogComponent, 'Small')
    comp.instance.talents = this.selection?.selected
    comp.instance.mode = 'SinglePositionMode'
    comp.instance.positionId = this.positionId

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) {
        this.loadTalents()
        this.selection.clear()
      }

      subscription.unsubscribe();
    });
  }

  openTalentProfile(talentId: number) {
    let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = talentId
  }

  openMatchingReport(report: TalentMatchingReportData) {
    let cref = this.dialogService.openOverlay(MatchingAnalyzeSheetComponentComponent)
    cref.instance.report = report;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.talentData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.talentData.forEach(row => this.selection.select(row));
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadTalents();
  }
}
