import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {NewCompanyDialogComponent} from './new-company-dialog/new-company-dialog.component';
import {UtilityService} from '../utils/utility.service';
import {MatTable} from '@angular/material/table';
import {ConfirmDialogComponent} from '../utils/confirm-dialog/confirm-dialog.component';
import {AdminCompanyData} from '../generated/data';
import {AdminCompanyResource, AdminResource} from '../generated/resources';
import {debounceTime} from 'rxjs/internal/operators';
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  @ViewChild('dataTable') public table: MatTable<any>;

  public customerCompanyData: AdminCompanyData[];

  public displayedColumns: string[] = ['id', 'creationDate', 'name', 'keyAccount', 'actions'];

  public loading: boolean = false;
  public openingCreateDialog: boolean = false;
  public downloadingExcel: boolean = false;

  public pageIndex: number = 0;
  public pageSize: number = 15;
  public totalSize: number = 0;
  public pageSizeOptions: number[] = [15, 30, 60, 100];

  public companySearchControl: FormControl = new FormControl(null);

  constructor(
    private adminResource: AdminResource,
    private utilityService: UtilityService,
    private adminCompanyResource: AdminCompanyResource,
    private dialogService: SideSheetService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.loadCompaniesOverviewInformation();

    this.companySearchControl.valueChanges.pipe(debounceTime(500)).subscribe(result => {
      this.loadCompaniesOverviewInformation();
      this.loading = false;
    });

  }

  loadCompaniesOverviewInformation() {
    this.loading = true;
    this.adminCompanyResource.getCompanies({
      page: this.pageIndex,
      pageSize: this.pageSize,
      q: this.companySearchControl.value,
      sortDirection: 'DESC',
      sortProperty: 'creationDate'
    }).then(result => {
      this.customerCompanyData = result.content;
      this.totalSize = result.totalElements;
      this.loading = false;
    });
  }

  createNewCompanyDialog() {
    let cref = this.dialogService.openOverlay(NewCompanyDialogComponent)

    cref.instance.sideSheetRef.sheetClosed.subscribe(() => {
      this.loadCompaniesOverviewInformation();
    })
  }

  deleteCompany(company) {
    this.downloadingExcel = true;

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminCompanyResource.setCompanyDeletedFlag(company.id, {deletedFlag: true}),
        title: `Firma deaktivieren`,
        message: `Möchtest Du die Firma ${company.name} wirklich deaktivieren? Dabei werden alle Stellen der Firma gelöscht. Das kann etwas dauern.`,
        confirmButtonText: 'Deaktivieren',
        confirmButtonColor: 'primary'
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.loadCompaniesOverviewInformation();
        this.downloadingExcel = false;
      }
    });
  }

  navigateToCompany(companyId) {
    window.open('companies/' + companyId, '_blank');
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadCompaniesOverviewInformation();
  }

  copyToClipboard(textToCopy: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.utilityService.showSnackBar('In Zwischenablage kopiert');
  }
}
