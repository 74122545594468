import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoice'
})
export class InvoicePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value){
      case 'InvoiceHiringValue': return 'Einstellung';
      case 'InvoiceHiringPercentage': return 'Bruttojahresgehalt';
      case 'InvoiceDiscount': return 'Rabatt';
      case 'InvoiceBooking': return 'Buchungsrechnung';
    }
    return value;
  }


}
