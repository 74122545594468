import {Component, Input, OnInit} from '@angular/core';
import { SideSheetService } from '../side-sheet.service';

@Component({
  selector: 'dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  @Input() dialogHeadline: string = ''
  @Input() closeDialogMessage: string = 'Soll der Dialog wirklich geschlossen werden?'
  @Input() confirmClose: boolean = false

  buttonText: string = "Dialog Schließen"
  title: string = "Dialog Schließen"

  constructor(
      private dialogService: SideSheetService
  ) {
  }

  ngOnInit(): void {
  }
}
