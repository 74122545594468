import {LabelData} from "./label-data"
import {ProfessionMatchingLevel} from "./profession-matching-level"
import {ProfessionTreeData} from "./profession-tree-data"

export class SubjectProfessionPathData {
    matchedSubjectGroup = new LabelData();
    matchedSubjectProfession = new LabelData();
    matchingLevel: ProfessionMatchingLevel = null;
    profession = new ProfessionTreeData();
    subject = new LabelData();
}