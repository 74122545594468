<div class="controlling-board-info" style="margin-left: 20px">
    Dieses Board stellt, ohne Angabe eines Zeitraums, einen Ist-Zustand der Stellenvorschläge und Bewerbungen einer Stelle dar. <br>
    Die Ergebnisse lassen sich auch auf einen bestimmten Zeitraum beschränken mit Ausnahme von Spalten, die 'Aktuell' im Titel tragen.
</div>
<div style="background-color: white; min-width: 1030px; margin: 20px; padding: 10px" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">

        <div class="button-bar" fxLayout="row" fxLayoutAlign="space-between center">

            <div style="width: 100%; margin-right: 15px;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">

                <key-accounter-dropdown
                        placeholder="Key Account Manager"
                        [multiple]="true"
                        [required]="false"
                        [control]="keyAccounterControl">
                </key-accounter-dropdown>


                <searchable-company-dropdown
                        [control]="searchCompanyIdControl"
                        [multiple]="false"
                        appearance="fill"
                        placeholder="Unternehmen">
                </searchable-company-dropdown>

                <mat-form-field appearance="fill">
                    <mat-label>Status</mat-label>
                    <mat-select [formControl]="statesControl">
                        <mat-option *ngFor="let state of positionStates" [value]="state">{{state}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Herkunft</mat-label>
                    <mat-select [formControl]="originControl">
                        <mat-option *ngFor="let origin of positionOrigins" [value]="origin">{{origin}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Zeitraum von</mat-label>
                    <input matInput [matDatepicker]="pickerFrom" [formControl]="dateFromControl" name="dateFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Zeitraum bis</mat-label>
                    <input matInput [matDatepicker]="pickerTo" [formControl]="dateToControl" name="dateTo">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>

                <loading-button matTooltip="gefilterte Stellen laden" buttonClass="mat-primary" [loadingBool]="loading"
                                (clickEvent)="loadData()">
                    <i class="mdi mdi-refresh main-action-icon filter-color"></i>
                </loading-button>
            </div>

            <table-download-button
                    [loadingBool]="downloadingExcel"
                    color="primary"
                    [buttonClass]="'main-action-icon'"
                    excelName="bewerbungsfunnel.xlsx"
                    [matTable]="table">
            </table-download-button>
        </div>
    </div>

    <loading-spinner [loadingBool]="loading">
        <table mat-table #dataTable [dataSource]="statisticsData" class="w-100" #dataTable>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Stelle</th>
                <td mat-cell *matCellDef="let row" style="font-weight: bold"> {{row.title}}</td>
            </ng-container>

            <ng-container matColumnDef="numPotential">
                <th mat-header-cell *matHeaderCellDef>Potenzial</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numPotential}}</td>
            </ng-container>

            <ng-container matColumnDef="numProposals">
                <th mat-header-cell *matHeaderCellDef>Vorschläge</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numProposals}}</td>
            </ng-container>

            <ng-container matColumnDef="numWithdrawn">
                <th mat-header-cell *matHeaderCellDef>Zurückgezogen</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numWithdrawnProposals}}</td>
            </ng-container>

            <ng-container matColumnDef="numSeen">
                <th mat-header-cell *matHeaderCellDef>Gesehen</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numSeen}}</td>
            </ng-container>

            <ng-container matColumnDef="numRejected">
                <th mat-header-cell *matHeaderCellDef>Abgelehnt (T)</th>
                <td mat-cell *matCellDef="let row" class="centered-row">
                        {{row.numRejected}}
                </td>
            </ng-container>

            <ng-container matColumnDef="numClicked">
                <th mat-header-cell *matHeaderCellDef>Geklickt</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numClicked}}</td>
            </ng-container>

            <ng-container matColumnDef="numBookmarked">
                <th mat-header-cell *matHeaderCellDef>Gemerkt</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numBookmarked}}</td>
            </ng-container>

            <ng-container matColumnDef="numCurrentlyBookmarked">
                <th mat-header-cell *matHeaderCellDef>Aktuell gemerkt</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numCurrentlyBookmarked}}</td>
            </ng-container>

            <ng-container matColumnDef="numApplications">
                <th mat-header-cell *matHeaderCellDef>Bewerbungen</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numApplications}}</td>
            </ng-container>

            <ng-container matColumnDef="numApplicationsWithoutCv">
                <th mat-header-cell *matHeaderCellDef>Bew. ohne CV</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numApplicationsWithoutCv}}</td>
            </ng-container>

            <ng-container matColumnDef="numDenied">
                <th mat-header-cell *matHeaderCellDef>Abgelehnt (QS)</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numDeniedApplications}}</td>
            </ng-container>

            <ng-container matColumnDef="numFailed">
                <th mat-header-cell *matHeaderCellDef>Abgelehnt (UN)</th>
                <td mat-cell *matCellDef="let row" class="centered-row">
                    <button mat-raised-button matTooltip="Ablehngründe anzeigen" (click)="openApplicationFailedReasons(row.id)">
                        {{row.numFailedApplications}}
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="numInteresting">
                <th mat-header-cell *matHeaderCellDef>Interessant</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numCurrentlyInteresting}}</td>
            </ng-container>

            <ng-container matColumnDef="numAppProcessEnter">
                <th mat-header-cell *matHeaderCellDef>in Bewerbung</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numCurrentlyAppProcessEnter}}</td>
            </ng-container>

            <ng-container matColumnDef="numContractSent">
                <th mat-header-cell *matHeaderCellDef>Vertrag verschickt</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numCurrentlyContractSent}}</td>
            </ng-container>

            <ng-container matColumnDef="numHired">
                <th mat-header-cell *matHeaderCellDef>Eingestellt</th>
                <td mat-cell *matCellDef="let row" class="centered-row"> {{row.numHired}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>

