<mat-tab-group>
    <mat-tab label="Subjects">
        <subjects></subjects>
    </mat-tab>

    <mat-tab label="SubjectGroups">
        <subject-groups></subject-groups>
    </mat-tab>

    <mat-tab label="Professions">
        <professions></professions>
    </mat-tab>

    <mat-tab label="Pathfinder">
        <subject-profession-pathfinder></subject-profession-pathfinder>
    </mat-tab>

    <mat-tab label="Match-Simulator">
        <app-talent-position-match-simulator></app-talent-position-match-simulator>
    </mat-tab>
</mat-tab-group>
