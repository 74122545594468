import { Component, OnInit } from '@angular/core';
import {AdminSalesProposalResource} from "../generated/resources";
import {AdminSalesProposalData} from "../generated/data";
import {PageEvent} from "@angular/material/paginator";
import {ConfirmDialogComponent} from "../utils/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {UtilityService} from "../utils/utility.service";
import {
    CreateSalesProposalExternSideSheetComponent
} from "./create-sales-proposal-side-sheet/create-sales-proposal-extern-side-sheet.component";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {
    CreateSalesProposalSideSheetComponent
} from "../companies/create-sales-proposal-side-sheet/create-sales-proposal-side-sheet.component";

@Component({
  selector: 'app-sales-proposals',
  templateUrl: './sales-proposals.component.html',
  styleUrls: ['./sales-proposals.component.scss']
})
export class SalesProposalsComponent implements OnInit {

    pageIndex: number = 0;
    pageSize: number = 30;
    totalSize: number = 0;
    totalPages: number = 0;
    pageSizeOptions: number[] = [15, 30, 60, 100];

    datasource: AdminSalesProposalData[] = []

    states: ('Open' | 'Approved' | 'Denied' | 'Withdrawn')[] = ['Open', 'Approved', 'Denied', 'Withdrawn']
    selectedStates: ('Open' | 'Approved' | 'Denied' | 'Withdrawn')[] = ['Open']

    loading: boolean = true;

    displayedColumns = [
        'type',
        'name',
        'state',
        'company',
        'size',
        'price',
        'discount',
        'totalPrice',
        'created',
        'updated',
        'actions'
    ];
  constructor(
      private adminSalesProposalResource: AdminSalesProposalResource,
      private dialog: MatDialog,
      private dialogService: SideSheetService,
      private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
      this.getSalesProposals()
  }

    openCreateSalesProposalExistingCompanyDialog() {
        let comp = this.dialogService.openOverlay(CreateSalesProposalSideSheetComponent, 'Medium')
        comp.instance.showSelectCompany = true
        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
            if (res) {
                this.utilityService.showSnackBar("Angebot erfolgreich verschickt!")
                this.getSalesProposals()
            }
            subscription?.unsubscribe()
        })
    }

    openCreateSalesProposalNewCompanyDialog() {
        let comp = this.dialogService.openOverlay(CreateSalesProposalExternSideSheetComponent, 'Medium')
        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
            if (res) {
                this.utilityService.showSnackBar("Angebot erfolgreich verschickt!")
                this.getSalesProposals()
            }
            subscription?.unsubscribe()
        })
    }

  getSalesProposals() {
      this.loading = true
      this.adminSalesProposalResource.getSalesProposals({
          companyId: null, states: this.selectedStates, pageSize: this.pageSize, pageNum: this.pageIndex
      }).then(result => {
          this.datasource = result.content
          this.totalSize = result.totalElements
          this.totalPages = result.totalPages
          this.loading = false
          }
      )
  }

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getSalesProposals();
    }


    openConfirmationDialog(proposal: AdminSalesProposalData) {
        let dialogRef =
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: `Möchtest du das Angebot wirklick zurückziehen?`,
                    confirmButtonText: 'Zurückziehen',
                    title: 'Angebot zurückziehen',
                    onConfirm: () => {return new Promise((resolve => {resolve(true)}))}
                },
                panelClass: [''],
                maxWidth: 600
            })


        let sub = dialogRef.afterClosed().subscribe(next => {
            if (next) {
                this.withdrawSalesProposal(proposal)
            }
            sub?.unsubscribe()
        })
    }

    withdrawSalesProposal(proposal: AdminSalesProposalData) {
        this.adminSalesProposalResource.withdrawSalesProposal(proposal.id).then(result => {
            proposal.state = result
            this.utilityService.showSnackBar("Das Angebot wurde zurückgezogen")
        })
    }

}
