import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.reporting.controllers.ProposalStatisticsController
 */

@Injectable()
@ResourceParams({})
export class ProposalStatisticsResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/statistics/matchingLevelForNewTalents/{positionId}',
        endpoint: 'com.uniwunder.talentagent.reporting'
    })
    private _getPositionNewTalentMatchingStatistics: IResourceMethodObservableStrict<null, null, {positionId: number}, Map<Date, Map<string, number>>> | undefined
    getPositionNewTalentMatchingStatistics(positionId: number): Promise<Map<Date, Map<string, number>>> {
        if (!this._getPositionNewTalentMatchingStatistics) throw new Error("resource has not been properly initialized")
        return this._getPositionNewTalentMatchingStatistics(null, null, {positionId: positionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/statistics/positionProposalTransitions/{toState}',
        endpoint: 'com.uniwunder.talentagent.reporting'
    })
    private _getPositionProposalTransitionStatistics: IResourceMethodObservableStrict<null, {positionIds?: number[], talentCreationOnly?: boolean}, {toState: ('New' | 'Seen' | 'Clicked' | 'Bookmarked' | 'CTAClicked' | 'Withdrawn' | 'Rejected' | 'Accepted')}, Map<Date, Map<number, number>>> | undefined
    getPositionProposalTransitionStatistics(toState: ('New' | 'Seen' | 'Clicked' | 'Bookmarked' | 'CTAClicked' | 'Withdrawn' | 'Rejected' | 'Accepted'), requestParams?: {positionIds?: number[], talentCreationOnly?: boolean}): Promise<Map<Date, Map<number, number>>> {
        if (!this._getPositionProposalTransitionStatistics) throw new Error("resource has not been properly initialized")
        return this._getPositionProposalTransitionStatistics(null, requestParams || {}, {toState: toState}).toPromise()
    }

}