import { Component, OnInit } from '@angular/core';
import {
    AddressData,
    AdminCompanyCreationData,
    AdminRoleDisplayData, CompanyInvoiceInformationData,
    CompanyPricingConfigData, RecruiterData, SalesProposalData
} from "../../generated/data";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    AdminAdminResource,
    AdminSalesProposalResource
} from "../../generated/resources";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {PublicResource as UniSignPublicResource} from "../../generated/unisign/resources";

@Component({
  selector: 'app-create-sales-proposal-extern-side-sheet',
  templateUrl: './create-sales-proposal-extern-side-sheet.component.html',
  styleUrls: ['./create-sales-proposal-extern-side-sheet.component.scss']
})
export class CreateSalesProposalExternSideSheetComponent implements OnInit {

    loading: boolean = false;
    submitted: boolean = false;
    emailIsFree: boolean = true;


    keyAccounterData: AdminRoleDisplayData[] = [];
    form: FormGroup;
    companyInvoiceAddressForm: FormGroup
    recruiterCreationForm: FormGroup
    pricing: CompanyPricingConfigData = <CompanyPricingConfigData>{}

    validProposal: Boolean = false
    salesProposal: SalesProposalData

    constructor(
        private fb: FormBuilder,
        private adminAdminResource: AdminAdminResource,
        private sideSheetRef: SideSheetRef,
        private adminSalesProposalResource: AdminSalesProposalResource,
        private uniSignPublicResource: UniSignPublicResource
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            companyNameControl: [null, Validators.required],
            companyDescriptionControl: [null, Validators.required],
            companySizeControl: [null, Validators.required],
            companyWebsiteControl: null,
            companyKeyAccounterControl: [null]
        })

        this.companyInvoiceAddressForm = this.fb.group({
            streetNameControl: [null, Validators.required],
            streetNumberControl: [null, Validators.required],
            zipCodeControl: [null, Validators.required],
            cityIdControl: [null, Validators.required],
            cityNameControl: [null],
            invoiceEmailControl: [null, Validators.required],
            invoiceHolderFirstNameControl: [null, Validators.required],
            invoiceHolderLastNameControl: [null, Validators.required],
            taxNumberControl: [null, Validators.pattern(/^DE\s?[0-9]{9}$/)],
            purchaseInfoControl: [null]
        })

        this.recruiterCreationForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            gender: [null],
            phone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            position: [''],
        });

        this.loadKeyAccounter();
    }

    loadKeyAccounter() {
        this.adminAdminResource.getAllKeyAccounter().then(keyAccounterData => {
            this.keyAccounterData = keyAccounterData;
        });
    }

    get controls() {
        return this.recruiterCreationForm.controls;
    }

    allValid():boolean {
        return this.recruiterCreationForm.valid && this.validProposal && this.companyInvoiceAddressForm.valid && this.form.valid
    }

    onSubmit() {

        this.submitted = true;

        if (!this.allValid())  return

        this.loading = true;

        this.uniSignPublicResource.getEmailAvailableForRegistration({email: this.controls.email.value})
            .then(isAvailableData => {
                if (isAvailableData.available) {

                    const createdRecruiterData: RecruiterData = {
                        firstName: this.controls.firstName.value,
                        lastName: this.controls.lastName.value,
                        gender: this.controls.gender.value,
                        phoneNumber: this.controls.phone.value,
                        emailAddress: this.controls.email.value,
                        position: this.controls.position.value,
                        emailNotification: true,
                        smsNotification: false,
                        privacyOptIn: false
                    };

                    let companyCreationData: AdminCompanyCreationData = {
                        yearlyRecruitings: 0,
                        name: this.form.controls.companyNameControl.value,
                        companySize: this.form.controls.companySizeControl.value,
                        shortCompanyInfo: this.form.controls.companyDescriptionControl.value,
                        website: this.form.controls.companyWebsiteControl.value,
                        keyAccounterId: this.form.controls.companyKeyAccounterControl.value,
                        address: <AddressData>{
                            name: null,
                            streetName: this.companyInvoiceAddressForm.controls.streetNameControl.value,
                            streetNumber: this.companyInvoiceAddressForm.controls.streetNumberControl.value,
                            zipCode: this.companyInvoiceAddressForm.controls.zipCodeControl.value,
                            cityId: this.companyInvoiceAddressForm.controls.cityIdControl.value,
                            cityName: this.companyInvoiceAddressForm.controls.cityNameControl.value,
                        },
                        invoiceInformation: <CompanyInvoiceInformationData>{
                            companyId: null,
                            invoiceEmail: this.companyInvoiceAddressForm.controls.invoiceEmailControl.value,
                            invoiceHolderFirstName: this.companyInvoiceAddressForm.controls.invoiceHolderFirstNameControl.value,
                            invoiceHolderLastName: this.companyInvoiceAddressForm.controls.invoiceHolderLastNameControl.value,
                            taxNumber: this.companyInvoiceAddressForm.controls.taxNumberControl.value,
                            additionalPurchaseInformation: this.companyInvoiceAddressForm.controls.purchaseInfoControl.value
                        }
                    };
                    this.salesProposal.companyDraftData = companyCreationData
                    this.salesProposal.recruiterDraftData = createdRecruiterData

                    this.adminSalesProposalResource.createSalesProposal(this.salesProposal, {withRecruiterCreation: true}).then(result => {
                        this.sideSheetRef.sheetClosed.next(true)
                    })

                } else {
                    this.emailIsFree = false;
                    this.loading = false;
                }
            });
    }
}
