import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {AdminEmploymentPositionDisplayData, AdminEmploymentPositionFilterData} from "../../../generated/data";
import {AdminEmploymentPositionResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-customer-positions-dropdown',
  templateUrl: './searchable-customer-positions-dropdown.component.html',
  styleUrls: ['./searchable-customer-positions-dropdown.component.scss']
})
export class SearchableCustomerPositionsDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  positions: AdminEmploymentPositionDisplayData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
      private adminPositionResource: AdminEmploymentPositionResource
  ) {
  }

  ngOnInit() {
    this.loadPositions();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          if (newValue?.length) {
            this.loadPositions()
          }
        });
  }


  loadPositions() {
    let filterData = <AdminEmploymentPositionFilterData>{
      q: this.filterControl.value,
      origin: 'Uniwunder'
    }
    this.adminPositionResource.getFilteredEmploymentPositions(filterData, {page: 0, pageSize: 30}).then(positions => {
          this.positions = positions.content;
        }
    )
  }

  selectAll() {
    this.control.setValue(this.positions.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}


