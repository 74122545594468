import {
  MapSubjectToSubjectGroupSheetComponent
} from "../map-subject-to-subject-group-sheet/map-subject-to-subject-group-sheet.component";
import {ReplaceSubjectSheetComponent} from "../replace-subject-sheet/replace-subject-sheet.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {UtilityService} from "../../../utils/utility.service";
import {SubjectGroupDetailsSheetComponent} from "../subject-group-details-sheet/subject-group-details-sheet.component";
import {SubjectDetailsSheetComponent} from "../subject-details-sheet/subject-details-sheet.component";
import {Component, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {LabelData, SubjectData} from "../../../generated/data";
import {AdminSubjectResource} from "../../../generated/resources";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss']
})
export class SubjectsComponent implements OnInit {

  public subjectFilterControl: FormControl = new FormControl();
  public subjects: SubjectData[] = [];
  public loading: boolean = true;

  public pageIndex: number = 0;
  public pageSize: number = 15;
  public totalSize: number = 0;
  public pageSizeOptions: number[] = [15, 30, 60, 100]

  public displayedColumns = ['subjectTitle', 'numSubjectGroups', 'subjectGroups', 'numProfessions', 'actions']
  constructor(
      private adminSubjectResource: AdminSubjectResource,
      private dialogService: SideSheetService,
      private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.loadSubjects();
  }

  loadSubjects(): void {
    this.loading = true;
    this.adminSubjectResource.getAllSubjects({
      query: this.subjectFilterControl.value,
      page: this.pageIndex,
      pageSize: this.pageSize,
    }).then(subjects => {
          this.subjects = subjects.content;
          this.totalSize = subjects.totalElements;
          this.loading = false;
    })
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadSubjects();
  }

  openMapSubjectSheet(subject: SubjectData) {
    let cref = this.dialogService.openOverlay(MapSubjectToSubjectGroupSheetComponent)
    cref.instance.subjectId = subject.id;
    cref.instance.subjectTitle = subject.title;

    cref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      if(next) {
        this.loadSubjects();
        this.utilityService.showSnackBar("Studiengruppen erfolgreich zugeordnet!")
      }
    })
  }

  openReplaceSubjectSheet(subject: SubjectData) {
    let cref = this.dialogService.openOverlay(ReplaceSubjectSheetComponent)
    cref.instance.subjectId = subject.id;
    cref.instance.subjectTitle = subject.title;

    cref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      if(next) {
        this.loadSubjects();
        this.utilityService.showSnackBar("Studiengang erfolgreich ausgetauscht!")
      }
    })
  }

  openSubjectDetailsSheet(subject: SubjectData) {
    let cref = this.dialogService.openOverlay(SubjectDetailsSheetComponent)
    cref.instance.subjectId = subject.id;
    cref.instance.subjectTitle = subject.title;
    cref.instance.pro = subject.title;
  }

  openSubjectGroupDetailsSheet(subjectGroup: LabelData) {
    let cref = this.dialogService.openOverlay(SubjectGroupDetailsSheetComponent)
    cref.instance.subjectGroupId = subjectGroup.id;
    cref.instance.subjectGroupTitle = subjectGroup.name;
  }
}
