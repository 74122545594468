<dialog-header dialogHeadline="Eventfilter bearbeiten"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start" *ngIf="!loadingEventFilter">

    <div class="side-sheet-content layout-padding">
        <form [formGroup]="form" class="width-100" fxLayout="column" fxLayoutAlign="start start"
              fxLayoutGap="20px">

            <mat-form-field appearance="fill">
                <input matInput formControlName="locationRadius" placeholder="Location Radius" type="number">
            </mat-form-field>

            <mat-form-field>
                <mat-select formControlName="mode" placeholder="Location Mode">
                    <mat-option *ngFor="let mode of modes" [value]="mode">
                        {{mode}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <input matInput [matDatepicker]="pickerFrom" placeholder="Registrierung von"
                       formControlName="regFrom" name="dateFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <input matInput [matDatepicker]="pickerTo" placeholder="Registrierung bis"
                       formControlName="regTo" name="pickerTo">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>

            <app-searchable-city-dropdown [placeholder]="'Städte'" [control]="form.controls['cityIds']" [multiple]="true" [required]="false">
            </app-searchable-city-dropdown>

            <div class="warn" *ngIf="eventType == 'PerfectMatchEvent'">Das veränderst du für alle Perfect-Match Events!</div>
            <searchable-subject-group-dropdown style="width: 600px;" [placeholder]="'Studiengruppen'" [control]="form.controls['subjectGroupIds']" [multiple]="true" [required]="false"
            ></searchable-subject-group-dropdown>

            <div class="warn" *ngIf="eventType == 'PerfectMatchEvent'">Das veränderst du für alle Perfect-Match Events!</div>
            <searchable-subject-dropdown  style="width: 600px" [placeholder]="'Studiengang'" [control]="form.controls['subjectIds']" [multiple]="true" [required]="false"></searchable-subject-dropdown>

            <mat-form-field>
                <input matInput [matDatepicker]="studyDateTo" placeholder="Studienende bis"
                       formControlName="studyEndUntil" name="studyDateTo">
                <mat-datepicker-toggle matSuffix [for]="studyDateTo"></mat-datepicker-toggle>
                <mat-datepicker #studyDateTo></mat-datepicker>
            </mat-form-field>

            <mat-checkbox formControlName="active">Filter aktiv für Talent-Eventübersicht</mat-checkbox>
        </form>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-icon-button (click)="openShowTalentsForFilterSheet()"><i class="mdi mdi-eye"></i></button>
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true" buttonClass="mat-primary" [loadingBool]="updating"
                        [disabledCondition]="form.invalid" (clickEvent)="updateFilter()">Speichern
        </loading-button>
    </div>
</div>