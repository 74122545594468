<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="controlling-board-info">
        Dieses Board zeigt, wie oft welche Stelle in einem bestimmten  Zeitraum gepushed wurde und wie sie performt hat. Es kann nach Studiengruppen gefiltert werden, um z.B. zu sehen, wie oft welche Stelle bei BWLern ausgespielt wurde. Es sind Ladezeiten bis etwa 5min zu erwarten (bei 8 Wochen Filterung).
    </div>
<div fxLayout="row"  class="filter-wrapper layout-padding-8" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field>
            <input matInput [matDatepicker]="pickerFrom" placeholder="Pushes von"
                   [(ngModel)]="startDate" name="dateFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="pickerTo" placeholder="Pushes bis"
                   [(ngModel)]="endDate" name="dateTo">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Studiengruppe des Ziels</mat-label>
            <mat-select [(ngModel)]="subjectGroupId" >
             <mat-option *ngIf="loadingSubjectGroups" [value]="null"><mat-progress-spinner
                        color="primary"
                        diameter="18"
                        strokeWidth="2"
                        mode="indeterminate"></mat-progress-spinner></mat-option>
                <mat-option  *ngFor="let subjectGroup of subjectGroups" [value]="subjectGroup.subjectGroupId">
                    ({{subjectGroup.frequency}}) {{subjectGroup.subjectGroupTitle}}</mat-option>
            </mat-select>
        </mat-form-field>

        <loading-button
                matTooltip="Aktualisieren"
                buttonClass="mat-primary"
                [loadingBool]="loading"
                (clickEvent)="load()">
            <i class="mdi mdi-refresh dark filter-color"></i>
        </loading-button>

</div>
    <div>

        <mat-slide-toggle [(ngModel)]="showRelativeValues">Relative Werte anzeigen</mat-slide-toggle>

    </div>
</div>
<loading-spinner [loadingBool]="loading">

    <table mat-table [dataSource]="data" class="max-table" #feedControlTable>

        <ng-container matColumnDef="positionTitle">
            <th mat-header-cell *matHeaderCellDef>Stelle</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.position.title">
                {{element.position.title | sliceStringLength: 30}}
            </td>
        </ng-container>

        <ng-container matColumnDef="totalPushes">
            <th mat-header-cell *matHeaderCellDef>Pushes</th>
            <td mat-cell *matCellDef="let element">
                {{element.totalPushes}}
            </td>

        </ng-container>
        <ng-container matColumnDef="seenCount">
            <th mat-header-cell *matHeaderCellDef>Gesehen</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!showRelativeValues">{{element.seenCount}}</span>
                <span *ngIf="showRelativeValues">{{element.seenCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="clickedCount">
            <th mat-header-cell *matHeaderCellDef>Geklickt</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!showRelativeValues">{{element.clickedCount}}</span>
                <span *ngIf="showRelativeValues">{{element.clickedCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="ctaClickCount">
            <th mat-header-cell *matHeaderCellDef>CTA-Click</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!showRelativeValues">{{element.ctaClickCount}}</span>
                <span *ngIf="showRelativeValues">{{element.ctaClickCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="appliedCount">
            <th mat-header-cell *matHeaderCellDef>Beworben</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'aboveAvg': (element.appliedCount/element.totalPushes)> avgApplicationRate,'underAvg': (element.appliedCount/element.totalPushes) < avgApplicationRate}">
                <span *ngIf="!showRelativeValues">{{element.appliedCount}}</span>
                <span *ngIf="showRelativeValues">{{element.appliedCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openPosition(element.position.id)"><i class="mdi mdi-information"></i></button>

                <button mat-icon-button (click)="openSubjectGroupPerformance(element.position)"><i class="mdi mdi-school"></i></button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="['positionTitle','totalPushes','seenCount','clickedCount','ctaClickCount','appliedCount','actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['positionTitle','totalPushes','seenCount','clickedCount','ctaClickCount','appliedCount','actions'];"></tr>

    </table>
</loading-spinner>
</div>
