import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {MatSelect} from "@angular/material/select";
import {LabelData, ProfessionGroupData, ProfessionMainGroupData} from "../../../generated/data";
import {AdminProfessionResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-profession-main-group-dropdown',
  templateUrl: './searchable-profession-main-group-dropdown.component.html',
  styleUrls: ['./searchable-profession-main-group-dropdown.component.scss']
})
export class SearchableProfessionMainGroupDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = false
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  @ViewChild('options') options: MatSelect;
  @ViewChild('scrollwrapper') scrollwrapper: ElementRef;

  professionMainGroups: LabelData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
      private professionResource: AdminProfessionResource,
  ) {
  }

  ngOnInit() {
    this.loadProfessionMainGroups();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          this.loadProfessionMainGroups()
        });
  }


  loadProfessionMainGroups() {
    this.professionResource.getProfessionMainGroups({
      query: this.filterControl.value,
      pageSize: 100,
      forcedIds: this.control.value
    }).then(professionMainGroups => {
          this.professionMainGroups = professionMainGroups.content;
        }
    )
  }

  selectAll() {
    this.control.setValue(this.professionMainGroups.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}
