<dialog-header dialogHeadline="Stelle passt nicht zu Talent"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content layout-padding">
        <div style="font-size: 12px; font-style: italic; color: #777">Du kannst die Stelle <b>"{{position?.positionTitle}}"</b> für das Talent als unpassend Markieren. Bitte verwende diese Funktion nur, wenn du
            dir auch wirklich sicher bist, dass das Talent kein Interesse an der Stelle hat.</div>

        <div class="m-top20">Gib bitte eine Begründung an, warum du dir sicher bist, dass das Talent diese Stelle nicht möchte.</div>

        <mat-form-field class="width-100 m-top20">
            <input matInput required
                   [formControl]="reasonControl"
                   placeholder="Begründung">
        </mat-form-field>

    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true" buttonClass="mat-primary" [loadingBool]="loading" [disabledCondition]="!reasonControl.valid" (clickEvent)="markPrimePositionAsUnsuitable()">Als upassend markieren</loading-button>
    </div>
</div>
