<div class="extra-padding" fxLayout="column" fxLayoutAlign="end start">
    <loading-spinner [loadingBool]="loadingMagicLinkAndIntegrationData" class="width-100">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="button-bar">
            <loading-button *ngIf="magicLink==null && integration?.integrationId ==null" [loadingBool]=creatingLink
                            [raised]="true" color="primary"
                            (clickEvent)="createLink()">ATS anbinden
            </loading-button>

            <button mat-flat-button color="primary" *ngIf="integration?.integrationId"
                    (click)="openImportAtsPositionsDialog()">Stellen importieren
            </button>

            <div fxLayout="row" fxLayoutAlign="end center">
                <loading-button *ngIf="magicLink==null && integration?.integrationId" [loadingBool]=creatingLink
                                [raised]="true" color="primary"
                                (clickEvent)="createReconnectionLink()">ATS neu verbinden
                </loading-button>

                <loading-button color="warn"
                                *ngIf="integration?.integrationId"
                                [raised]="true"
                                [loadingBool]="deletingIntegration"
                                matTooltip="Speichern"
                                (clickEvent)="deleteIntegrationOpenDialog()">
                    Integration Löschen
                    <i class="mdi mdi-delete mdi-24px"></i>
                </loading-button>
            </div>
        </div>
        <div class="content">
            <div *ngIf="errorMessage">Link erstellen fehlgeschlagen mit folgender Nachricht: <br> "{{errorMessage}}".
                <br><br>
                <span class="warn-text">Bitte kontaktiere die IT bevor du es erneut versuchst!</span>
            </div>


            <div *ngIf="magicLink && integration?.integrationId ==null">Diesen Link musst du jetzt dem Unternehmen
                zukommen lassen. Es muss an dieser Stelle dann das entsprechende ATS vom Kunden ausgewählt werden und
                dann kann das Unternehmen sein ATS mit Kombo verbinden.<br>
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="magic-link">
                    <div><b>Dein Link für das Unternehmen:</b></div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div>{{magicLink}} ich bin ein magischer Link</div>
                        <button mat-icon-button matTooltip="Kopieren"
                                (click)="utilityService.copyToClipboard(magicLink)"><i class="mdi mdi-content-copy"></i>
                        </button>
                    </div>
                </div>
                <br>
                Hier hast du weitere Informationen, wie du dem Kunden dabei unterstützen kannst: <a
                        href="https://help.kombo.dev/hc/en-us/sections/14349683218961-Connection-Guides" target="_blank">ATS mit
                    Kombo verbinden</a>
            </div>

            <div *ngIf="integration?.integrationId">
                <div class="success-message">ATS erolgreich verbunden!</div>
                <div><b>ATS Name</b></div>
                <div>{{integration.atsName}}</div>
                <br>
                <div><b>Integration ID</b></div>
                <div>{{integration.integrationId}}</div>
            </div>
        </div>
    </loading-spinner>
</div>
