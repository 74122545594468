<div class="button-bar layout-padding" fxLayout="row" fxLayoutAlign="end start">

    <loading-button [raised]="true" color="warn" (clickEvent)="openDeleteAllPositionsDialog(companyId)">Alle Stellen Deaktivieren</loading-button>
    <table-download-button color="primary" [buttonClass]="'main-action-icon'" excelName="Stellen.xlsx"
                           [matTable]="table"></table-download-button>

    <loading-button matTooltip="Neue Stelle anlegen"
                    buttonClass="mat-primary"
                    (clickEvent)="createPositionDialog()">
      <i class="mdi mdi-plus main-action-icon"></i>
    </loading-button>
</div>

<loading-spinner [loadingBool]="loading">
  <table mat-table [dataSource]="companyEmploymentPositions" matSort matSortActive="creationDate"
         matSortDisableClear
         matSortDirection="desc" class="layout-padding">

    <ng-container matColumnDef="positionId">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let position">{{position.id}}</td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>Unternehmen</th>
      <td mat-cell *matCellDef="let position" (click)="copyToClipboard(position.customerCompany.name)" class="copy">
        <span>{{position.customerCompany.name | sliceStringLength: 30}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="questions">
      <th mat-header-cell *matHeaderCellDef>Fragen aktiv/allgemein</th>
      <td mat-cell *matCellDef="let position">
        <span>{{position.activeQuestionCount}}/{{position.totalQuestionCount}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let position">
        <span>{{position.positionState}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="positionTitle">
      <th mat-header-cell *matHeaderCellDef>Titel</th>
      <td mat-cell *matCellDef="let position" (click)="copyToClipboard(position.title)"
          class="copy">
        <span *ngIf="position.deleted" style="color: red">GELÖSCHT - </span>
        <span *ngIf="position.positionState == 'Draft'" style="color: #00b8ff">DRAFT - </span>

        {{position.title | sliceStringLength: 100 }}</td>
    </ng-container>

<!--    <ng-container matColumnDef="cityNames">-->
<!--      <th mat-header-cell *matHeaderCellDef>Orte</th>-->
<!--      <td mat-cell *matCellDef="let position"> {{position.cityNames | joinToString: 3 | sliceStringLength: 50}}-->
<!--        <div *ngIf="!position.cityNames || position.cityNames == ''">keine Angabe</div>-->
<!--      </td>-->
<!--    </ng-container>-->

    <ng-container matColumnDef="positionType">
      <th mat-header-cell *matHeaderCellDef>Typ</th>
      <td mat-cell *matCellDef="let position" matTooltip="{{position.workType | workType}}">
        <i *ngIf="position.workType == 'FulltimeJob'" class="mdi mdi-briefcase green"></i>
        <i *ngIf="position.workType == 'WorkingStudent'" class="mdi mdi-school yellow"></i>
        <div class="first-letter"
             *ngIf="position.workType != 'FulltimeJob' && position.workType != 'WorkingStudent'"> {{(position.workType | workType) | slice:0:1}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef>Anlagedatum</th>
      <td mat-cell *matCellDef="let position"
          matTooltip="um {{position.creationDate | date: 'HH:mm'}} Uhr">{{position.creationDate | date: 'dd.MM.yy'}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let position">
        <div class="table-button" fxLayout="row" fxLayoutAlign="end center">
          <button mat-button mat-icon-button color="primary"
                  matTooltip="Stellendetails"
                  (click)="openPositionDetailsDialog(position.id)"
          >
            <i class="mdi mdi-information dark"></i>
          </button>

          <button mat-icon-button color="primary" matTooltip="Stelle Kopieren"
                  (click)="duplicateEmploymentPosition(position.id, position.title)">
            <i class="mdi mdi-content-copy dark"></i>
          </button>

        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let position; columns: displayedColumns;"
        [class.deactivated]="position.positionState == 'Inactive'"></tr>
  </table>

</loading-spinner>
