import { Component, OnInit } from '@angular/core';
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AddressData, AdminEventData, LabelData} from "../../generated/data";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminCompanyResource, AdminEventResource, PublicEventResource} from "../../generated/resources";
import {FileService} from "../../services/file.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-edit-event-content-sheet',
  templateUrl: './edit-event-content-sheet.component.html',
  styleUrls: ['./edit-event-content-sheet.component.scss']
})
export class EditEventContentSheetComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Text Eingeben...',
    translate: 'now',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Lato',
    toolbarHiddenButtons: [
      ['redo','undo','subscript','indent','outdent','customClasses','link','unlink','insertHorizontalRule','removeFormat','toggleEditorMode','heading','justifyRight']
    ]
  };

  data?: AdminEventData
  form: FormGroup
  selectedEventImage
  environmentVar

  saving: boolean = false

  constructor(
      private sideSheetRef: SideSheetRef,
      private fb: FormBuilder,
      private adminEventResource: AdminEventResource,
      public companyResource: AdminCompanyResource,
      public publicEventResource: PublicEventResource,
      private fileService: FileService,
  ) {
    this.environmentVar = environment
  }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.form = this.fb.group({
      title: this.data?.title,
      shortDescription: this.data?.shortDescription,
      tileText: this.data?.tileText,
      longDescription: this.data?.longDescription,
      videoLinkUnsafe: this.data?.videoLinkUnsafe,
    })
  }


  createOrUpdateEvent() {
    this.saving = true

    let data: AdminEventData = <AdminEventData>{
      id: this.data?.id,
      title: this.form.controls.title.value,
      start: this.data?.start,
      promotionId: this.data?.promotionId,
      formatId: this.data?.formatId,
      end: this.data?.end,
      tileText: this.form.controls.tileText.value,
      shortDescription: this.form.controls.shortDescription.value,
      longDescription: this.form.controls.longDescription.value,
      showEvent: this.data?.showEvent,
      videoLinkUnsafe: this.form.controls.videoLinkUnsafe.value? this.form.controls.videoLinkUnsafe.value : null,
      address: <AddressData>{
        streetNumber: this.data?.address.streetNumber,
        streetName: this.data?.address.streetName,
        cityId: this.data?.address.cityId
      },
      companies: this.data?.companies,
    }

    this.adminEventResource.createOrUpdateEvent(data).then(result => {
      this.sideSheetRef.sheetClosed.next(true)
    })
  }

  close(result?: boolean) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  removeImage(id: number) {
    this.adminEventResource.removeImageFromEvent({id: this.data?.id, fileId: id}).then(result => {
      if (result) this.data?.images.splice(this.data?.images.indexOf(id), 1)
    })
  }

  removePartnerLogo(id: number) {
    this.adminEventResource.removePartnerImageFromEvent({id: this.data?.id, fileId: id}).then(result => {
      if (result) this.data?.partnerLogos.splice(this.data?.partnerLogos.indexOf(id), 1)
    })
  }

  removeHeader() {
    this.adminEventResource.removeHeaderImageFromEvent({id: this.data?.id, fileId: this.data?.eventHeader}).then(result => {
      if (result) this.data.eventHeader = null
    })
  }

  addHeader(fileEvent) {
    this.selectedEventImage = fileEvent.target.files[0];
    if (this.selectedEventImage) {
      return this.fileService.uploadEventHeader(this.data?.id, this.selectedEventImage).toPromise().then(result => {
        if (result) this.data.eventHeader = result.value
      })
    }
  }

  addImage(fileEvent) {
    this.selectedEventImage = fileEvent.target.files[0];
    if (this.selectedEventImage) {
      return this.fileService.uploadEventImage(this.data?.id, this.selectedEventImage).toPromise().then(result => {
        if (result) this.data?.images.push(result.value)
      })
    }
  }

  addPartnerLogo(fileEvent) {
    this.selectedEventImage = fileEvent.target.files[0];
    if (this.selectedEventImage) {
      return this.fileService.uploadEventPartnerLogo(this.data?.id, this.selectedEventImage).toPromise().then(result => {
        if (result) this.data?.partnerLogos.push(result.value)
      })
    }
  }
}
