import {AddressData} from "./address-data"
import {CompanyInvoiceInformationData} from "./company-invoice-information-data"

export class RecruiterCompanyUpdateData {
    address = new AddressData();
    companyInvoiceInformationData = new CompanyInvoiceInformationData();
    id: number = null;
    name: string = null;
    shortCompanyInfo: string | null = null;
    website: string | null = null;
    yearlyRecruitings: number | null = null;
}