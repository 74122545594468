<div>
  <h1></h1>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Talente zugewiesen:</div>
<!--    <div [ngClass]="{'warn': data.talentCount >= data.maxCoachingAmount}">-->
<!--      <b>{{data.talentCount}} / {{data.maxCoachingAmount}}</b></div>-->
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    Zuweisbare Talent erhöhen um:
    <mat-form-field class="m-left20" style="width: 50px">
      <input matInput type="number" [(ngModel)]="addTalentsAmount">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="dialogRef.close(false)">Schließen</button>
<!--    <loading-button color="primary"-->
<!--                    (clickEvent)="changeConsultantAssignability()"-->
<!--                    [loadingBool]="loadingChangeConsultantAssignability"-->
<!--                    [disabledCondition]="isSaveButtonDisabled()">Aktivieren-->
<!--    </loading-button>-->
  </div>

</div>
