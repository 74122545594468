import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldPositionRelation'
})
export class FieldPositionRelationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value){
      case 'Normal': return 'Passend';
      case 'Fitting': return 'Sehr Passend';
    }
    return null;
  }

}
