import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    AdminCompanyResource,
    AdminEmploymentPositionResource,
    AdminRecruiterResource,
    AdminSalesProposalResource
} from "../../../generated/resources";
import {
    AdminCompanyData,
    AdminEmploymentPositionData,
    RecruiterData,
    SalesProposalData
} from "../../../generated/data";
import {UtilityService} from "../../../utils/utility.service";
import {
    AccountResource as UniSignAccountResource,
    AdminResource as UniSignAdminResource,
    PublicResource as UniSignPublicResource
} from '../../../generated/unisign/resources';
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {AccountDisplayData} from "../../../generated/unisign/data";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-create-recruiter-dialog',
  templateUrl: './create-recruiter-dialog.component.html',
  styleUrls: ['./create-recruiter-dialog.component.scss']
})
export class CreateRecruiterDialogComponent implements OnInit, OnDestroy {

  recruiterCreationForm: FormGroup;
  notificationForm: FormGroup;

  submitted: boolean = false;
  loading: boolean = false;
  emailIsFree: boolean = true;

    adminCompanyData: AdminCompanyData

  companyId: number = 0;
  positions: AdminEmploymentPositionData[] = [];
  loadingPositions: boolean = false;

  assignedPositions: number[] = []

  constructor(
      private adminRecruiterResource: AdminRecruiterResource,
      private utilityService: UtilityService,
      private formBuilder: FormBuilder,
      private uniSignPublicResource: UniSignPublicResource,
      private uniSignAdminResource: UniSignAdminResource,
      private sideSheetRef: SideSheetRef,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private adminCompanyResource: AdminCompanyResource
  ) {
  }

    ngOnDestroy(): void {
    }

  ngOnInit() {
      this.adminCompanyResource.getCompany(this.companyId).then(company => {
          this.adminCompanyData = company;
      })

    this.recruiterCreationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: [null],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      position: [''],
    });

    this.loadPositionsForCompany()

    this.notificationForm = this.formBuilder.group({
      emailNotification: [true],
      smsNotification: [false]
    });
  }

  loadPositionsForCompany(){
      this.loadingPositions = true
      this.adminEmploymentPositionResource.getAllEmploymentPositions(this.companyId).then(positions => {
            this.positions = positions;
            this.loadingPositions = false;
      })
  }

  get controls() {
    return this.recruiterCreationForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (!this.recruiterCreationForm.valid) {
      return
    }

    this.loading = true;

    this.uniSignPublicResource.getEmailAvailableForRegistration({email: this.controls.email.value})
      .then(isAvailableData => {
        if (isAvailableData.available) {
            this.uniSignAdminResource.createPartnerAccount(
                {
                    email: isAvailableData.email,
                    firstName: this.controls.firstName.value,
                    password: null
                }
            ).then(result => {
                this.createRecruiterOnTalentagent(result)
            })
        } else {
          this.emailIsFree = false;
          this.loading = false;
        }
      });
  }

  createRecruiterOnTalentagent(unisignResponse: AccountDisplayData): Promise<any> {
      const createdRecruiterData: RecruiterData = {
          firstName: this.controls.firstName.value,
          lastName: this.controls.lastName.value,
          gender: this.controls.gender.value,
          phoneNumber: this.controls.phone.value,
          emailAddress: this.controls.email.value,
          position: this.controls.position.value,
          emailNotification: this.notificationForm.controls.emailNotification.value,
          smsNotification: this.notificationForm.controls.smsNotification.value,
          privacyOptIn: false
      };

      return this.adminRecruiterResource.createRecruiterForCompany(createdRecruiterData, this.companyId, {unisignId: unisignResponse.id, sendWelcomeEmail: true}).then(res => {
              this.assignPositionsToRecruiter(res.id).then(() => {
              this.utilityService.showSnackBar("Recruiter erfolgreich angelegt");
              window.location.reload()
              this.sideSheetRef.sheetClosed.next();
          })
      })
  }

    updateAssignedPositions(positionId: number){
        if(this.assignedPositions.includes(positionId)){
            this.assignedPositions = this.assignedPositions.filter(p => p !== positionId)
        } else {
            this.assignedPositions.push(positionId)
        }
    }

    assignPositionsToRecruiter(recruiterId: number): Promise<any>{
        return this.adminRecruiterResource
            .assignEmploymentPositionsToRecruiter(recruiterId, {positionIds: this.assignedPositions})
    }

    toggleAllAssignedPositions(){
        if(this.assignedPositions.length === this.positions.length){
            this.assignedPositions = []
        } else {
            this.assignedPositions = this.positions.map(p => p.id)
        }
    }

    onCancel() {
        this.sideSheetRef.sheetClosed.next();
    }
}
