import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AdminApplicationDetailsData,
    ApplicationFailedData,
    ApplicationState,
    RequestedApplicationDocumentsData
} from "../../generated/data";
import {AdminApplicationResource, AdminTalentResource,} from "../../generated/resources";
import {UtilityService} from "../../utils/utility.service";
import {FileService} from "../../services/file.service";
import {MatDialog} from "@angular/material/dialog";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {Subscription} from "rxjs";
import {DemandAdditionalApplicationDocsDialogComponent} from "../demand-additional-application-docs-dialog/demand-additional-application-docs-dialog.component";
import {ConfirmDialogComponent} from "../../utils/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-application-details-dialog',
  templateUrl: './application-details-dialog.component.html',
  styleUrls: ['./application-details-dialog.component.scss']
})
export class ApplicationDetailsDialogComponent implements OnInit, OnDestroy {

  applicationId: number

  application: AdminApplicationDetailsData

  loading: boolean = false;
    loadingChangeState: boolean = false;

  cvFile: Blob = null;

    states: ApplicationState[] = ["Failed", "Interesting", "AppProcessEnter", "ContractSent", "Hired"]
    applicationStateCtr: FormControl = new FormControl()

  displayedColumns: String[] = ['date', 'event']

  requestedDocumentsData: RequestedApplicationDocumentsData[] = []

  constructor(
      private utilityService: UtilityService,
      private applicationResource: AdminApplicationResource,
      private fileService: FileService,
      private dialog: MatDialog,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) {
  }

  ngOnDestroy(): void {
    }

  ngOnInit() {
    this.fileService.getApplicationDossier(this.applicationId).subscribe((cv) => {
      this.cvFile = cv
    })


    this.getApplicationDetails()
    this.getRequestedDocumentTypesForApplication()
  }

  getApplicationDetails() {
    this.loading = true
    this.applicationResource.getApplicationDetails(this.applicationId).then(result => {
      this.application = result
        this.applicationStateCtr.setValue(this.application.state)
      this.loading = false
    })
  }

  getRequestedDocumentTypesForApplication() {
    this.applicationResource.getRequestedAdditionalDocumentsForTalentApplication(this.applicationId).then(result => {
      this.requestedDocumentsData = result
    })
  }

  openTalentProfile() {
    let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = this.application.talentId
  }

  openPositionDetailsDialog() {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = this.application.position.id
  }

  cancel(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result);
  }

  getChangeApplicationStateMessage(state: ApplicationState): string {
        let message = "Willst du die Bewerbung wirklich als " + state + " markieren?"
        if (state == "Failed") message + " Das Talent erhält eine E-Mail als Information, dass seine Bewerbung gescheitert ist."
        return message
  }

  openConfirmApplicationStateDialog(newState:ApplicationState) {
    this.loadingChangeState = true
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => {
            let requestBody: ApplicationFailedData | null = null
            if (newState == "Failed") requestBody = {failedReasonForCompany: null, applicationId: this.application.applicationId, comment: "", reason: "FailedByAdmin", sendRejectMail: true}
            return this.applicationResource.changeApplicationState(requestBody, this.application.applicationId, {newState:newState})
        },
        confirmButtonText: newState,
        message: this.getChangeApplicationStateMessage(newState),
        title: "Bewerbung als " + newState + " markieren"
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.application.state = "Hired"
        this.utilityService.showSnackBar("Bewerbung als " + newState + " markiert")
        this.getApplicationDetails()
      }
      this.loadingChangeState = false
    })
  }


  openRequestAdditionalApplicationDocumentsDialog() {
    let comp = this.dialogService.openOverlay(DemandAdditionalApplicationDocsDialogComponent, 'Small')
    comp.instance.applicationId = this.application.applicationId
    comp.instance.talentName = this.application.talentName

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      this.getRequestedDocumentTypesForApplication()
      subscription.unsubscribe();
    })
  }
}

