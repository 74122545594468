import {Component, OnInit} from '@angular/core';
import {EmploymentPositionApplicationFailedStatisticsData,} from "../../generated/data";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminControllingResource} from "../../generated/resources";


@Component({
    selector: 'app-application-failed-reasons-sheet',
    templateUrl: './application-failed-reasons-sheet.component.html',
    styleUrls: ['./application-failed-reasons-sheet.component.scss']
})
export class ApplicationFailedReasonsSheetComponent implements OnInit {

    positionId: number;
    applicationFailedStatisticsData: EmploymentPositionApplicationFailedStatisticsData
    loading: boolean = true

  constructor(
      private sideSheetRef: SideSheetRef,
      private adminControllingResource: AdminControllingResource
  ) {

  }

    ngOnInit(): void {
        this.adminControllingResource.getApplicationFailedStatisticsForEmploymentPosition(this.positionId).then(result => {
            this.applicationFailedStatisticsData = result
            this.loading = false
        })
    }
}
