<div style="background-color: white; margin: 20px; padding: 10px" class="mat-elevation-z6">
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-form-field class="quick-search">
                    <input matInput [formControl]="searchTextControl" placeholder="Schnellsuche">
                </mat-form-field>

                <div class="filter-checkbox"
                     (click)="considerPrimeStatus.setValue(!considerPrimeStatus.value); reloadPage()"
                     [ngClass]="{'selected': considerPrimeStatus.value == true, 'unselected': considerPrimeStatus.value == false }">
                    <mat-checkbox disabled [formControl]="considerPrimeStatus">Prime Unternehmen</mat-checkbox>
                </div>
                <div class="filter-checkbox"
                     (click)="considerProfileShown.setValue(!considerProfileShown.value); reloadPage()"
                     [ngClass]="{'selected': considerProfileShown.value == true, 'unselected': considerProfileShown.value == false }">
                    <mat-checkbox disabled [formControl]="considerProfileShown">Unternehmensprofil wird angezeigt</mat-checkbox>
                </div>
                <div class="filter-checkbox"
                     (click)="considerProfileNotBlank.setValue(!considerProfileNotBlank.value); reloadPage()"
                     [ngClass]="{'selected': considerProfileNotBlank.value == true, 'unselected': considerProfileNotBlank.value == false }">
                    <mat-checkbox disabled [formControl]="considerProfileNotBlank">Leere Profile ausblenden</mat-checkbox>
                </div>
            </div>

        </div>

        <div class="standard-container">
            <loading-spinner [loadingBool]="loading">
                <table mat-table [dataSource]="companyProfileData" #dataTable>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let company">{{company.id}}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let company">{{company.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="contact">
                        <th mat-header-cell *matHeaderCellDef>Kontakt</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.contactPrompt != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="slogan">
                        <th mat-header-cell *matHeaderCellDef>Slogan</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.slogan != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="intro">
                        <th mat-header-cell *matHeaderCellDef>Intro</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.introText != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="culture">
                        <th mat-header-cell *matHeaderCellDef>Kultur</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.cultureText != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef>Standort</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.locationText != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="office">
                        <th mat-header-cell *matHeaderCellDef>Büro</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.officeText != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="benefits">
                        <th mat-header-cell *matHeaderCellDef>Benefits</th>
                        <td mat-cell *matCellDef="let company">
                            <span *ngIf="company.benefitsText != null"><i class="mdi mdi-check"></i></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="showProfile">
                        <th mat-header-cell *matHeaderCellDef>Profil angezeigen</th>
                        <td mat-cell *matCellDef="let company">
                            <mat-slide-toggle [disabled]="checkForEmptyProfile(company) || (disabled && updatedCompanyId == company.id)"
                                              [(ngModel)]="company.showProfile"
                                              (change)="updateCompanyProfile(company)" [matTooltip]="getShowProfileTooltipText(company)">
                            </mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="prime">
                        <th mat-header-cell *matHeaderCellDef>Prime</th>
                        <td mat-cell *matCellDef="let company">
                            <mat-slide-toggle #primeToggle
                                              [disabled]="disabled && updatedCompanyId == company.id"
                                              [(ngModel)]="company.primeCompany"
                                              (change)="updateCompanyProfile(company)" [matTooltip]="getPrimeTooltipText(company)">
                            </mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Aktionen</th>
                        <td mat-cell *matCellDef="let company">
                            <button mat-icon-button (click)="navigateToCompany(company.id)" matTooltip="Firmendetails">
                                <i class="mdi mdi-information"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </loading-spinner>

            <mat-paginator [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           showFirstLastButtons
                           [length]="totalSize"
                           [pageIndex]="pageIndex"
                           (page)="handlePage($event)">
            </mat-paginator>
        </div>
    </div>
</div>