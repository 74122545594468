import {LabelData} from "./label-data"
import {SalesProposalState} from "./sales-proposal-state"

export class AdminSalesProposalData {
    company: LabelData | null = null;
    createdAt: (Date | null) = null;
    discount: number = null;
    id: number = null;
    originalPrice: number = null;
    product = new LabelData();
    serviceDescription: string = null;
    size: number = null;
    state: SalesProposalState = null;
    totalPriceWithoutTax: number = null;
    updatedAt: (Date | null) = null;
}