import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'workType'
})
export class WorkTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
        case 'PermanentEmployment':
            return 'Festanstellung';
        case 'Trainee':
            return 'Trainee';
        case 'DualStudies':
            return 'Duales Studium';
        case 'VocationalTraining':
            return 'Berufsausbildung';
        case 'WorkingStudent':
            return 'Werkstudent';
        case 'MandatoryInternship':
            return 'Praktikum';
        case 'VoluntaryInternship':
            return 'Unbezahltes Praktikum';
        case 'Thesis':
            return 'Abschlussarbeit';
        case 'TempoaryJob':
            return 'Aushilfe';
        case 'ProjectWork':
            return 'Projektarbeit';
    }
  }
}
