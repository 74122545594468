<dialog-header dialogHeadline="Talentfilter"></dialog-header>

<loading-spinner [loadingBool]="loading">
    <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" style=" padding: 5px">
        <div>Insgesamt <b>{{totalSize}}</b> Talente </div>
        <div fxLayout="row" fxLayoutAlign="start center">Blockiert bis: <div > {{getBlockDateString()}}</div></div>
        <loading-button [loadingBool]="creatingCampaign" buttonClass="mat-primary" color="primary" (click)="createCampaign()" [disabledCondition]="createCampaignDisabled()">Kampagne in CRONOS erstellen</loading-button>
<!--        <button mat-flat-button color="primary" (click)="downloadExcel()" [disabled]="excelDownloaded">als Excel herunterladen</button>-->
    </div>
    <div class="dialog-content" fxLayout="column">

        <table mat-table [dataSource]="data" class="w-100">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Talent</th>
                <td mat-cell *matCellDef="let row">{{row.firstName}} {{row.lastName}}</td>
            </ng-container>

            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef>Studiengang</th>
                <td mat-cell *matCellDef="let row">{{row.lastSubjectTitle}}</td>
            </ng-container>

            <ng-container matColumnDef="regDate">
                <th mat-header-cell *matHeaderCellDef>Angemeldet</th>
                <td mat-cell *matCellDef="let row">{{row.creationDate | date: "dd.MM.yy"}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</loading-spinner>
