import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AdminHiringResource} from "../generated/resources";
import {AdminHiringStatisticData} from "../generated/data";
import {FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {PositionDetailsDialogComponent} from "../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {
  BillingsHiringDetailsDialogComponent
} from "./billings-hiring-details-dialog/billings-hiring-details-dialog.component";

@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BillingsComponent implements OnInit {

  pageIndex: number = 0;
  pageSize: number = 30;
  totalSize: number = 0;
  pageSizeOptions: number[] = [15, 30, 60, 100];

  loading: boolean;
  displayedColumns = [
    'date',
    'talent',
    'company',
    'position',
    'keyAccounter',
    'talentAgent',
    'hiringDetails'
  ];

  companyControl: FormControl = new FormControl(null)
  keyAccounterControl: FormControl = new FormControl(null)
  talentAgentControl: FormControl = new FormControl(null)
  startDate: FormControl = new FormControl(null)
  endDate: FormControl = new FormControl(null)

  stats: AdminHiringStatisticData[] = []
  expandedElement: AdminHiringStatisticData | null

  constructor(
      private adminHiringResource: AdminHiringResource,
      private router: Router,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    let dateNow = new Date().getDate()
    this.startDate.setValue(new Date(new Date().setDate(dateNow - dateNow + 1)));
    this.endDate.setValue(new Date());
    this.loadData();
  }

  navigateTo(route) {
    window.open(location.origin + route, '_blank')
  }


  handlePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  openPositionDetailsDialog(positionId, tabIndex: number = 0) {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
    comp.instance.tabIndex = tabIndex
  }

  getSalaryAmount(element: AdminHiringStatisticData):any {
    return element.salaryAmount? element.salaryAmount : (element.salaryState? "nicht bekannt / wird später eingetragen" : null)
  }

  isMarkedAsUnknown(element: AdminHiringStatisticData): boolean {
    return element.salaryState == 'SalaryUnknown' && !element.salaryAmount
  }

  openHiringDetailsDialog(hiring: AdminHiringStatisticData): void {
    let comp = this.dialogService.openOverlay(BillingsHiringDetailsDialogComponent, "Small")
    comp.instance.hiringData = hiring

    let sub = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) {
        hiring.salaryState = result.salaryState
        hiring.salaryAmount = result.salaryAmount
        hiring.startingDate = result.startingDate
        hiring.signingDate = result.signingDate
        hiring.adminNote = result.adminNote
      }
      sub.unsubscribe()
    })
  }

  loadData() {
    this.loading = true;
    this.adminHiringResource.getHirings({
      page: this.pageIndex,
      pageSize: this.pageSize,

      startDate: this.startDate.value,
      endDate: this.endDate.value,
      companyId: this.companyControl.value,
      talentAgentId: this.talentAgentControl.value,
      keyAccounterId: this.keyAccounterControl.value

    }).then((result) => {
      this.stats = result.content;
      this.totalSize = result.totalElements
      this.loading = false;
    })
  }
}
