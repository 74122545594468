<dialog-header [dialogHeadline]="'Prime-Filter'"></dialog-header>

<div class="width-100">
<div fxLayout="row" fxLayoutAlign="end" class="w-100" fxLayoutGap="15px" style="padding: 20px 20px 0 20px">
    <button mat-icon-button (click)="openTalentFilterSideSheet()" matTooltip="Vorschau">
        <i class="mdi mdi-eye mdi-24px"></i>
    </button>

    <loading-button [loadingBool]="savingTalentFilters"
                    [raised]="true"
                    [color]="'primary'"
                    (clickEvent)="saveTalentFilters()"
                    style="margin-right: 20px">
        Speichern
    </loading-button>
</div>
<talent-filter-group class="width-100" *ngIf="filtersLoaded" [filters]="filters"></talent-filter-group>
</div>
