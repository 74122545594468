import {EmploymentCategory} from "./employment-category"
import {HomeOffice} from "./home-office"
import {EmploymentPositionOrigin} from "./employment-position-origin"
import {TalentPositionRelationState} from "./talent-position-relation-state"
import {PositionRelationSort} from "./position-relation-sort"

export class PositionRelationFilterData {
    companyId: number | null = null;
    employmentCategories: EmploymentCategory[] | null = null;
    homeOffice: HomeOffice | null = null;
    isInFeed: boolean | null = null;
    isMatch: boolean | null = null;
    isPrimeFilterMatch: boolean | null = null;
    isRecommended: boolean | null = null;
    keyword: string | null = null;
    minGrade: number | null = null;
    origins: EmploymentPositionOrigin[] | null = null;
    positionId: number | null = null;
    positionLocationIds: number[] | null = null;
    professionGroupIds: number[] | null = null;
    qualityScores: number[] | null = null;
    relationStates: TalentPositionRelationState[] | null = null;
    searchRadius: number | null = null;
    sort: PositionRelationSort | null = null;
    studyFieldIds: number[] | null = null;
    talentId: number | null = null;
}