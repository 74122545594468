import {AfterViewInit, Directive, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Directive()
export class Paginator implements OnInit, AfterViewInit {

  pageSize: number = 15;
  pageSizeOptions: number[] = [15, 30, 60, 100];
  totalSize: number = 0;
  pageIndex: number = 0;
  sortAsc = false;

  @ViewChild(MatSort, /* TODO: add static flag */ {}) sort: MatSort;
  @ViewChild(MatPaginator, /* TODO: add static flag */ {}) paginator: MatPaginator;

  constructor(
    protected sortBy: string,
    private func: () => void
  ) {
  }

  ngOnInit(): void {
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.func()
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    if (this.sort != null) { // if the matSort directive is not used, sort won't be initialized
      this.sort.sortChange.subscribe(sort => {
        this.sortAsc = sort.direction === "asc";
        this.sortBy = sort.active;
        this.func()
      });
    }
  }
}
