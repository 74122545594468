<dialog-header [dialogHeadline]="professionAreaTitle"></dialog-header>
<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">
        <div *ngIf="professionAreaData" class="width-100" fxLayout="column" fxLayoutGap="4px">
            <small><b>ID:</b> <span class="copy" (click)="utilityService.showSnackBar('ID kopiert')"> {{professionAreaId}}</span></small>
            <small><b>DKZ-Kennung:</b> <span class="copy" (click)="utilityService.showSnackBar('DKZ-Kennung kopiert')"> {{professionAreaData.agencyKey}}</span></small>
        </div>

        <mat-tab-group>
            <mat-tab label="ProfessionMainGroups" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(professionMainGroupDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionMainGroupDataSource" class="w-100">
                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let professionMainGroup">{{professionMainGroup.agencyKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="professionMainGroup">
                        <th mat-header-cell *matHeaderCellDef>ProfessionMainGroup</th>
                        <td mat-cell *matCellDef="let professionMainGroup">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionMainGroupDetailsSheet(professionMainGroup)">
                                {{professionMainGroup.title}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionMainGroupColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionMainGroupColumns;"></tr>
                </table>
            </mat-tab>

            <mat-tab label="ProfessionGroups" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(professionGroupDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionGroupDataSource" class="w-100">
                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let professionGroup">{{professionGroup.agencyKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="professionGroup">
                        <th mat-header-cell *matHeaderCellDef>ProfessionGroup</th>
                        <td mat-cell *matCellDef="let professionGroup">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionGroupDetailsSheet(professionGroup)">
                                {{professionGroup.title}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionGroupColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionGroupColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="ProfessionSubGroups" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(professionSubGroupDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionSubGroupDataSource" class="w-100">
                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let professionGroup">{{professionGroup.agencyKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="professionSubGroup">
                        <th mat-header-cell *matHeaderCellDef>ProfessionSubGroup</th>
                        <td mat-cell *matCellDef="let professionSubGroup">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionSubGroupDetailsSheet(professionSubGroup)">
                                {{professionSubGroup.title}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionSubGroupColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionSubGroupColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="ProfessionTypes" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(professionTypeDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionTypeDataSource" class="w-100">
                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let professionType">{{professionType.agencyKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="professionType">
                        <th mat-header-cell *matHeaderCellDef>ProfessionType</th>
                        <td mat-cell *matCellDef="let professionType">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionTypeDetailsSheet(professionType)">
                                {{professionType.title}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionTypeColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionTypeColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="Professions" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(professionDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionDataSource" class="w-100">
                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let professions">{{professions.agencyKey}}</td>
                    </ng-container>

                    <ng-container matColumnDef="profession">
                        <th mat-header-cell *matHeaderCellDef>Profession</th>
                        <td mat-cell *matCellDef="let professions">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionDetailsSheet(professions)">
                                {{professions.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjectGroups">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjectGroups}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjects">
                        <th mat-header-cell *matHeaderCellDef>Subjects</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjects}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionColumns;"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
</loading-spinner>




