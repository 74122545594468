<dialog-header dialogHeadline="Stellen vorschlagen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content layout-padding">
        <p style="font-size: 12px; font-style: italic; color: #777">
            Das Talent erhält eine Benachrichtigung über deinen Vorschlag per Mail und SMS.
            Kommentiere deine Empfehlung kurz, um sie persönlicher zu machen (30-150 Zeichen)</p>

        <div *ngIf="mode == 'SingleTalentMode'">
            <div *ngFor="let position of positions">
                <p><b>{{position.name}}</b></p>
                <mat-form-field style="width: 100%">
                    <mat-label>Warum schlägst du diese Stelle vor?</mat-label>
                    <textarea matInput required [formControl]="getFormControl(position.name)" cdkTextareaAutosize [rows]="5" style="width: 100%"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="mode == 'SinglePositionMode'">
            <div *ngFor="let talent of talents">
                <p><b>{{talent.talentName}}</b><small> ({{talent.mostRecentStudies.subjectName}})</small></p>
                <mat-form-field style="width: 100%">
                    <mat-label>Warum schlägst du {{talent.talentName}} diese Stelle vor?</mat-label>
                    <textarea matInput required [formControl]="getFormControl(talent.talentName)" cdkTextareaAutosize [rows]="5" style="width: 100%"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true" buttonClass="mat-primary" [loadingBool]="saving" [disabledCondition]="messagesForm.invalid" (clickEvent)="save()">Primestelle vorschlagen</loading-button>
    </div>
</div>
