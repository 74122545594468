import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AdminPricingResource} from "../../generated/resources";
import {CompanyPricingConfigData, CompanyPricingMode, LabelData} from "../../generated/data";
import {UtilityService} from "../../utils/utility.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-company-pricing',
    templateUrl: './company-pricing.component.html',
    styleUrls: ['./company-pricing.component.scss']
})
export class CompanyPricingComponent implements OnInit, OnDestroy {

    loading: boolean = true
    pricingValid: boolean = false
    loadingUpdate: boolean = false
    pricing: CompanyPricingConfigData


    @Input() companyId: number

    constructor(
        private adminPricingResource: AdminPricingResource,
        private utility: UtilityService,
    ) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.getPricingForCompany()
    }

    getPricingForCompany() {
        this.loading = true
        this.adminPricingResource.getPricingForCompany(this.companyId).then(result => {
            this.pricing = result
            if (!this.pricing?.company?.id) this.pricing.company = <LabelData>{id: this.companyId, name: ""}
            this.loading = false
        })
    }

    updatePricingForCompany() {
        this.loadingUpdate = true
        this.adminPricingResource.createOrUpdatePricing(this.pricing).then(result => {
            this.pricing = result
            this.loadingUpdate = false
            this.utility.showSnackBar("Pricing gespeichert!")
        })
    }
}
