<dialog-header dialogHeadline="Event-Content bearbeiten"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content layout-padding">
        <form [formGroup]="form" class="width-100" fxLayout="column" fxLayoutAlign="start start"
              fxLayoutGap="20px">

            <mat-form-field class="width-100" appearance="fill">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title">
            </mat-form-field>

            <mat-form-field class="width-100" appearance="fill">
                <mat-label>videoLinkUnsafe</mat-label>
                <input matInput formControlName="videoLinkUnsafe">
            </mat-form-field>

            <mat-form-field class="width-100" appearance="fill">
                <mat-label>tileText</mat-label>
                <textarea matInput rows="5" formControlName="tileText"></textarea>
            </mat-form-field>

            <mat-label>shortDescription</mat-label>
            <ngx-codemirror formControlName="shortDescription"
                            style="height:300px; width: 100%"
                            [options]="{
                                                lineNumbers: true,
                                                theme: 'material',
                                                mode:'htmlmixed'}"
            ></ngx-codemirror>

            <mat-label>longDescription</mat-label>
            <ngx-codemirror formControlName="longDescription"
                            style="height:300px; width: 100%"
                            [options]="{
                                                lineNumbers: true,
                                                theme: 'material',
                                                mode:'htmlmixed'}"
            ></ngx-codemirror>

            <div *ngIf="data?.id"><b>Event-Bilder</b></div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div class="image"  *ngFor="let i of data?.images"
                     [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrlMain + '/public/event/' + data?.id + '/image/' + i + ')'}">
                    <div class="delete-image" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="removeImage(i)"><i class="mdi mdi-close"></i></button>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="data?.id">
                <button mat-flat-button
                        color="accent"
                        (click)="imageInput.click()">
                    <span>Event Bild Hinzufügen</span>
                </button>
                <input
                    #imageInput type="file"
                    accept=".jpg, .jpeg, .png"
                    style="display: none"
                    (change)="addImage($event)"/>
            </div>

            <div *ngIf="data?.id"><b>Partner-Logos</b></div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div class="image"  *ngFor="let i of data?.partnerLogos"
                     [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrlMain + '/public/event/' + data?.id + '/partnerImages/' + i + ')'}">
                    <div class="delete-image" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="removePartnerLogo(i)"><i class="mdi mdi-close"></i></button>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="data?.id">
                <button mat-flat-button
                        color="accent"
                        (click)="partnerInput.click()">
                    <span>Partner-Logo Hinzufügen</span>
                </button>
                <input
                    #partnerInput type="file"
                    accept=".jpg, .jpeg, .png"
                    style="display: none"
                    (change)="addPartnerLogo($event)"/>
            </div>

            <div *ngIf="data?.id"><b>Event-Header</b></div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div class="image"  *ngIf="data?.eventHeader"
                     [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrlMain + '/public/event/' + data?.id + '/headerImage)'}">
                    <div class="delete-image" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="removeHeader()"><i class="mdi mdi-close"></i></button>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="data?.id && !data.eventHeader">
                <button mat-flat-button
                        color="accent"
                        (click)="partnerInput.click()">
                    <span>Header Hinzufügen</span>
                </button>
                <input
                    #partnerInput type="file"
                    accept=".jpg, .jpeg, .png"
                    style="display: none"
                    (change)="addHeader($event)"/>
            </div>
        </form>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true" buttonClass="mat-primary" [loadingBool]="saving"
                        [disabledCondition]="form.invalid" (clickEvent)="createOrUpdateEvent()">Speichern
        </loading-button>
    </div>
</div>