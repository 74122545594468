<dialog-header dialogHeadline="{{subjectGroupTitle}}"></dialog-header>
<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">
        <div *ngIf="subjectGroupData" fxLayout="column" fxLayoutGap="4px" class="w-100" >
            <small><b>ID: </b> <span class="copy" (click)="utilityService.showSnackBar('ID kopiert')">{{subjectGroupData.id}}</span></small>
            <small><b>DKZ-Kennung: </b> <span class="copy" (click)="utilityService.showSnackBar('DKZ-Kennung kopiert')">{{subjectGroupData.agencyKey}}</span></small>
        </div>


          <mat-tab-group>
            <mat-tab label="Subjects" class="w-100">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field>
                    <input matInput (keyup)="applySubjectFilter($event.target.value)" placeholder="Suche">
                </mat-form-field>

                <loading-button color="primary" [loadingBool]="loadClearSubjectMappings" (clickEvent)="deleteSelectedSubjectMappings()">{{selection.selected.length}} Subject-Mappings löschen</loading-button>
                </div>

                <table mat-table [dataSource]="subjectDataSource" class="w-100">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          (change)="$event ? selection.toggle(row) : null"
                                          [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="subjectTitle">
                        <th mat-header-cell *matHeaderCellDef>Subject</th>
                        <td mat-cell *matCellDef="let subject">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openSubjectDetailsSheet(subject)">
                                {{subject.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjectGroups">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                        <td mat-cell *matCellDef="let subject">{{subject.numSubjectGroups}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numProfessions">
                        <th mat-header-cell *matHeaderCellDef>Professions</th>
                        <td mat-cell *matCellDef="let subject">{{subject.numProfessions}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                        <td mat-cell *matCellDef="let subject">
                            <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                                <button mat-button mat-icon-button color="primary"
                                        matTooltip="Mapping entfernen" (click)="deleteSubjectMapping(subject)">
                                    <i class="mdi mdi-trash-can mdi-24px"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedSubjectColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedSubjectColumns;"></tr>
                </table>
            </mat-tab>

            <mat-tab label="Professions">
                <mat-form-field>
                    <input matInput (keyup)="applyProfessionFilter($event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionDataSource" class="w-100">
                    <ng-container matColumnDef="professionTitle">
                        <th mat-header-cell *matHeaderCellDef>Profession</th>
                        <td mat-cell *matCellDef="let profession">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionDetailsSheet(profession)">
                                {{profession.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjectGroups">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjectGroups}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjects">
                        <th mat-header-cell *matHeaderCellDef>Subjects</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjects}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                        <td mat-cell *matCellDef="let profession">
                            <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                                <button mat-button mat-icon-button color="primary"
                                        matTooltip="Mapping entfernen" (click)="deleteSubjectGroupToProfessionMapping(profession)">
                                    <i class="mdi mdi-trash-can mdi-24px"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionColumns;"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
</loading-spinner>
