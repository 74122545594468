<dialog-header [dialogHeadline]="'Admin bearbeiten'"></dialog-header>

<div id="dialog-content-wrapper">

    <div class="side-sheet-content">
        <mat-tab-group #matTabGroup>

            <!--Accountinformation-->
            <mat-tab label="Accountdaten">
                <form [formGroup]="contactForm" fxLayout="row" fxLayoutAlign="start center">

                    <div class="picture-wrapper" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" (click)="editProfilePicture('Admin')">
                        <img *ngIf="adminPicture != null" [src]="adminPicture"
                             style="width: 200px; height: 200px">
                        <img *ngIf="adminPicture == null"
                             src="../../../assets/images/blurred_user.png"
                             style="width: 200px; height: 200px">
                        <div class="overlay" fxLayout="row" fxLayoutAlign="center center"><div>Auswählen</div></div>

                    </div>

                    <div class="form-row" fxLayout="column" fxLayoutAlign="start start">
                        <mat-form-field>
                            <input formControlName="firstName" matInput placeholder="Vorname">
                        </mat-form-field>

                        <mat-form-field>
                            <input formControlName="lastName" matInput placeholder="Nachname">
                        </mat-form-field>
                    </div>

                    <div class="form-row" fxLayout="column" fxLayoutAlign="start start">
                        <mat-form-field>
                            <input formControlName="emailAddress" matInput placeholder="Emailadresse">
                        </mat-form-field>

                        <mat-form-field>
                            <input formControlName="phoneNumber" matInput placeholder="Telefonnummer">
                        </mat-form-field>
                    </div>
                </form>
            </mat-tab>

            <!--KeyAccountManager Role Tab-->
            <mat-tab label="Key Accounter" class="layout-padding">
                <div *ngIf="!hasRoleKeyAccounter" class="set-role-button" fxLayout="column" fxLayoutAlign="center center"
                     (click)="setRole('KeyAccounter')">
                    <loading-button
                            matTooltip="Rolle Key Accounter zuweisen"
                    >
                        <i class="mdi mdi-account-plus"></i>
                    </loading-button>
                    <h3>{{contactForm.controls['firstName'].value }} zum Key Accounter machen</h3>
                </div>

                <div *ngIf="hasRoleKeyAccounter" fxLayout="row" fxLayoutAlign="start start" style="margin: 10px 0">
                    <div class="picture-wrapper" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" (click)="editProfilePicture('keyAccounter')">
                        <img *ngIf="!noKeyAccounterRolePicture && keyAccounterPicture != null" [src]="keyAccounterPicture"
                             style="width: 200px; height: 200px">
                        <img *ngIf="noKeyAccounterRolePicture || keyAccounterPicture == null"
                             src="../../../assets/images/blurred_user.png"
                             style="width: 200px; height: 200px">
                        <div class="overlay" fxLayout="row" fxLayoutAlign="center center"><div>Auswählen</div></div>
                    </div>

                    <form [formGroup]="keyAccounterForm">
                        <div fxLayout="row">
                            <mat-form-field>
                                <mat-label>Emailadresse</mat-label>
                                <input matInput formControlName="contactEmailAddressControl">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Telefonnummer</mat-label>
                                <input matInput formControlName="contactPhoneNumberControl">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row">
                        <mat-form-field>
                            <mat-label>Demodesk Link</mat-label>
                            <input matInput formControlName="demoDeskLink">
                        </mat-form-field>
                            <mat-form-field>
                                <mat-label>SalesForce Bcc Mail</mat-label>
                                <input matInput formControlName="salesForceBccMail">
                            </mat-form-field>
                        </div>
                        <quill-editor formControlName="profileTextControl"
                                      placeholder="Key Accountant Profiltext bearbeiten"></quill-editor>
                    </form>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>


    <div class="dialog-action-bar" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
<!--        <button *ngIf="matTabGroup.selectedIndex == 1" mat-button color="primary" (click)="addContent()">Content Hinzufügen</button>-->
<!--        <button *ngIf="matTabGroup.selectedIndex == 1" mat-button color="primary" (click)="addMobileJobs()">Mobile-Jobanzeige Hinzufügen</button>-->



            <loading-button [loadingBool]="loading"
                            [disabledCondition]="contactForm.invalid"
                            [raised]="false"
                            *ngIf="matTabGroup.selectedIndex == 0"
                            matTooltip="Speichern"
                            buttonClass="mat-primary"
                            (clickEvent)="updateAdminAccountInfo()">
                Speichern <i class="mdi mdi-24px mdi-content-save"></i>
            </loading-button>


        <div *ngIf="hasRoleKeyAccounter && matTabGroup.selectedIndex == 1" fxLayout="row" fxLayoutAlign="end start">
            <div fxLayout="row" fxLayoutAlign="end">
                <loading-button [loadingBool]="loading" [raised]="false"
                                buttonClass="mat-primary"
                                (clickEvent)="updateAdminRole('KeyAccounter')">
                    Speichern <i class="mdi mdi-content-save mdi-24px"></i>
                </loading-button>
                <loading-button [loadingBool]="loading" [raised]="false"
                                buttonClass="mat-primary"
                                (clickEvent)="deleteAdminRole('KeyAccounter')">
                   Löschen <i class="mdi mdi-delete mdi-24px"></i>
                </loading-button>
            </div>
        </div>
    </div>
</div>
