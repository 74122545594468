<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="10px" class="filter-wrapper">
                    <searchable-company-by-pricingmode-dropdown
                            [filteredModes]="['PrepaidBooking', 'Booking']"
                            placeholder="Firma"
                            [multiple]="false"
                            [required]="false"
                            [control]="companyControl">
                    </searchable-company-by-pricingmode-dropdown>
                </div>

                <mat-form-field class="input-field" appearance="outline">
                    <mat-label>Booking Status</mat-label>
                    <mat-select multiple="true" [(ngModel)]="filterData.states">
                        <mat-option *ngFor="let s of bookingStates" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Titel</mat-label>
                    <input matInput [formControl]="titleControl">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Sortierung</mat-label>
                    <mat-select multiple="false" [value]="selectedSortState" [(ngModel)]="selectedSortState">
                        <mat-option *ngFor="let s of sortStates" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" >
                    <mat-label>Sortierreihenfolge</mat-label>
                    <mat-select [(ngModel)]="selectedSortOrder">
                        <mat-option *ngFor="let order of sortOrders" [value]="order">
                            {{ order }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <loading-button
                        matTooltip="Aktualisieren"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        (clickEvent)="loadData()">
                    <i class="mdi mdi-refresh dark filter-color"></i>
                </loading-button>

                <table-download-button color="primary"
                                       excelName="Buchungen.xlsx"
                                       [buttonClass]="'main-action-icon'"
                                       [matTable]="table">
                </table-download-button>
                <loading-button matTooltip="Neue Booking anlegen"
                                buttonClass="mat-primary"
                                (clickEvent)="createBooking()">
                    <i class="mdi mdi-plus main-action-icon"></i>
                </loading-button>
            </div>

        </div>
    </div>

    <loading-spinner [loadingBool]="loading">

        <table mat-table [dataSource]="datasource" class="max-table" #table multiTemplateDataRows
               #sort
        >

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!isExpanded(element)" mat-icon-button aria-label="expand row" (click)="expand(element)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <button *ngIf="isExpanded(element)" mat-icon-button aria-label="collapse row" (click)="collapse(element)">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef> Unternehmen</th>
                <td mat-cell *matCellDef="let element">
                    {{element.company.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell  *matHeaderCellDef>Titel</th>
                <td mat-cell *matCellDef="let element" style="max-width: 300px">
                    {{element.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="pricing">
                <th mat-header-cell *matHeaderCellDef> Pricing</th>
                <td mat-cell *matCellDef="let element">
                    {{element.companyPricing | companyPricingMode}}
                </td>
            </ng-container>

            <ng-container matColumnDef="positions">
                <th mat-header-cell *matHeaderCellDef>Stellen</th>
                <td mat-cell *matCellDef="let element">
                    {{element.positions.length}}
                </td>
            </ng-container>

            <ng-container matColumnDef="applications">
                <th mat-header-cell *matHeaderCellDef>Bewerbungen</th>
                <td mat-cell *matCellDef="let element">
                    {{element.applications?.length}}/{{element.size}}<b><span *ngIf="element.resupply"> +{{element.resupply}}</span></b>
                </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell  *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <span [class.active]="element.state == 'Active'">{{element.state}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="keyAccounter">
                <th mat-header-cell  *matHeaderCellDef>Key-Accounter</th>
                <td mat-cell *matCellDef="let element">
                    {{element.keyAccounter?.name}}
                </td>
            </ng-container>


            <ng-container matColumnDef="price">
                <th mat-header-cell  *matHeaderCellDef>Preis</th>
                <td mat-cell *matCellDef="let element">
                    {{element.price}}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Erstellt/<br>Closed</th>
                <td mat-cell *matCellDef="let element">
                    {{element.createdAt | date: "dd.MM.yy"}}
                    {{element.closedAt | date: "dd.MM.yy"}}
                </td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="shrink">
                    <button style="font-size: 24px" matTooltip="Buchungs Informationen/Bearbeiten" mat-icon-button (click)="openBooking(element)"><i class="mdi mdi-information dark"></i></button>
                    <ng-container *ngIf="element.companyPricing == 'Booking' || element.companyPricing == 'PrepaidBooking'">
                        <button matTooltip="Rechnung erstellen / bearbeiten" style="font-size: 24px" mat-icon-button (click)="bookingsService.createInvoice(element)"><i class="mdi mdi-cash-multiple"></i></button>
                        <button *ngIf="element.state != 'Closed'" matTooltip="Buchung schließen" style="font-size: 24px" mat-icon-button (click)="bookingsService.closeBooking(element)"><i class="mdi mdi-close-box-outline"></i></button>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length-2">
                    <div class="element-detail" [@detailsExpand]="isExpanded(element) ? 'expanded' : 'collapsed'" fxLayout="column">
                        <div *ngFor="let position of element.positions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
                            <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                <div>{{position.name}}</div>
                                <button mat-icon-button (click)="openPositionDetailsDialog(position.id)">
                                    <i class="mdi mdi-eye"></i>
                                </button>
                            </div>

                            <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center" [class.active]="position.state == 'Active'">
                                {{ position.state }}
                            </div>

                            <div fxFlex="30" fxLayout="row" fxLayoutAlign="end center">
                                <div class="grey-text">{{getApplicationCount(element, position.id)}} Bewerbung(en)</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="empty">
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="element-row"
                [class.expanded]="isExpanded(element)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['empty', 'expandedDetail', 'empty']" class="detail-row"></tr>
        </table>

        <div *ngIf="datasource?.data?.length == 0" class="nothing-found">Es wurde nichts gefunden</div>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>
