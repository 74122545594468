<form [formGroup]="form">
    <div class="action-bar" fxLayout="row" fxLayoutAlign="end center">
        <loading-button [loadingBool]="loading" (clickEvent)="save()"
                        color="primary" [raised]="true" [disabledCondition]="form.invalid">
            Speichern
        </loading-button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem" style="margin: 1rem">
        <searchable-booking-dropdown
            [companyId]="position.customerCompanyId"
            [control]="form.controls.currentBookingId"
        ></searchable-booking-dropdown>
        <div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="8px" class="info">
            <i class="mdi mdi-information-outline"></i>
            <span>
                Diese Buchung wird befüllt wenn sich auf die Stelle beworben wird.<br>
                Sollte diese Buchung geschlossen werden, wird sie von allen Stellen entfernt.
            </span>
        </div>
    </div>
</form>
