import {Component, Inject, OnInit} from '@angular/core';
import {ConsultantData, JsonWrappedValue} from '../../generated/data';
import {AdminConsultantResource} from '../../generated/resources';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-increase-assignable-talent-amount-for-consultant-dialog',
  templateUrl: './increase-assignable-talent-amount-for-consultant-dialog.component.html',
  styleUrls: ['./increase-assignable-talent-amount-for-consultant-dialog.component.scss']
})
export class IncreaseAssignableTalentAmountForConsultantDialogComponent implements OnInit {

  loadingChangeConsultantAssignability: boolean = false;

  addTalentsAmount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConsultantData,
    public dialogRef: MatDialogRef<IncreaseAssignableTalentAmountForConsultantDialogComponent>,
    private adminConsultantResource: AdminConsultantResource
  ) {
  }

  ngOnInit() {
  }

  // changeConsultantAssignability() {
  //   this.loadingChangeConsultantAssignability = true;
  //   this.adminConsultantResource.increaseMaxCoachingAmount(
  //       {value: this.addTalentsAmount},
  //     this.data.id,
  //   )
  //     .then(() => {
  //       this.dialogRef.close(true);
  //       this.loadingChangeConsultantAssignability = false;
  //     });
  // }
  //
  // isSaveButtonDisabled(): boolean {
  //   if (this.loadingChangeConsultantAssignability) {
  //     return true;
  //   }
  // }
}
