<dialog-header dialogHeadline="Frage erstellen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content layout-padding">
        <p style="font-size: 12px; font-style: italic; color: #777">
            Diese Frage wird dem Talent vor seiner Bewerbung gestellt. Jede Frage ist eine Pflichfrage.</p>

                <h4>Für {{positionTitle}}</h4>
                <h4>Bitte gib hier die Frage so ein, wie sie dem Talent angezeigt werden soll (mindestens 10 Zeichen)</h4>
                <mat-form-field class="width-100">
                    <mat-label>Fragetext</mat-label>
                    <input matInput [formControl]="questionControl" required placeholder="Wie hoch ist Ihre Gehaltsvorstellung (Brutto / Jahr)">
                </mat-form-field>

    </div>


    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button buttonClass="mat-primary" [raised]="true" [disabledCondition]="questionControl.invalid" [loadingBool]="saving" (clickEvent)="save()" >Frage erstellen</loading-button>
    </div>
</div>
