<mat-form-field class="w-100" color="primary" [ngClass]="dropdownClass">
    <mat-select [formControl]="control" [placeholder]="placeholder"
                [multiple]="multiple"
                [required]="required"
                [disabled]="disabled"
                [disableOptionCentering]="disableOptionCentering">
        <mat-option>
            <ngx-mat-select-search [formControl]="filterControl"></ngx-mat-select-search>
        </mat-option>
        <span>
            <mat-progress-spinner
                    id="input-progress-spinner"
                    *ngIf="loadingTalents"
                    color="primary"
                    diameter="18"
                    strokeWidth="2"
                    mode="indeterminate">
            </mat-progress-spinner>
        </span>
        <div *ngIf="multiple" fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button class="toggle-all-button" (click)="unselectAll()" color="warn">Alle abwählen</button>
            <button mat-button class="toggle-all-button" (click)="selectAll()" color="primary">Alle Auswählen</button>
        </div>
        <mat-option *ngIf="!required" [value]="null">
            <div style="width: 100%; text-align: center">- egal -</div>
        </mat-option>
        <mat-option *ngFor="let talent of talents" [value]="talent.id" [matTooltip]="'ID: ' + talent.id + ' / E-Mail: ' + talent.emailAddress">
            {{talent.firstName}} {{talent.lastName}}
        </mat-option>
    </mat-select>
</mat-form-field>