import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {RequestError} from "../services/request-error-interceptor.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent implements OnInit {

  error: RequestError = <RequestError>{}
errorHint;
  constructor(@Inject(MAT_SNACK_BAR_DATA)data: RequestError, private router: Router) {
    this.error = data;
    switch(this.error.statusCode){
      case 500: this.errorHint = 'Dieser Fehler wird automatisch erkannt. In dringenden Fällen an die IT wenden.';break;
    }

  }

  copyErrorReport() {
    let value = "Admin Frontend: Fehler " + this.error.statusCode +" bei Request auf URL  '" + this.error.url +"' in FE-Route '" + this.router.url + "'"


    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  ngOnInit(): void {
  }

}
