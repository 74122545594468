import {LabelData} from "./label-data"

export class CompanyInvoiceData {
    amount: number = null;
    company = new LabelData();
    date: (Date | null) = null;
    dtype: string = null;
    id: number = null;
    positions: LabelData[] | null = null;
    sevDeskInvoiceId: string | null = null;
    talent: LabelData | null = null;
}