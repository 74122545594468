<loading-spinner [loadingBool]="loading">
    <header class="width-100" style="padding: 20px" fxLayout="row"
            fxLayoutAlign="space-between center">
        <div class="filter" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field style="width: 400px">
                <input [formControl]="filterControl" matInput placeholder="Beruf-Titel Suche">
            </mat-form-field>

<!--            <mat-form-field>-->
<!--                <mat-select [formControl]="matchingLevelFilterControl" placeholder="Matchinggrad" multiple>-->
<!--                    <mat-option *ngFor="let level of matchingLevels" [value]="level">{{level}}</mat-option>-->
<!--                </mat-select>-->
<!--            </mat-form-field>-->

            <button mat-icon-button color="primary" matTooltip="Reload" (click)="load()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </header>

    <table *ngIf="!loading" mat-table [dataSource]="datasource" #dataTable class="w-100">

        <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef>Anzahl</th>
            <td mat-cell *matCellDef="let row">
                {{row.count}}
            </td>
        </ng-container>

        <ng-container matColumnDef="dkzId">
            <th mat-header-cell *matHeaderCellDef>DKZ-String</th>
            <td mat-cell *matCellDef="let row">{{row.agencyKey}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Beruf</th>
            <td mat-cell *matCellDef="let row">{{row.title}}</td>
        </ng-container>

        <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef class="center">Gruppe</th>
            <td mat-cell *matCellDef="let row">
                {{row.professionGroupTitle}}
            </td>
        </ng-container>

        <ng-container matColumnDef="fields">
            <th mat-header-cell *matHeaderCellDef class="center">Tätigkeitsfelder</th>
            <td mat-cell *matCellDef="let row" style="padding-bottom: 17px">
                <div fxLayout="row wrap" fxLayoutGap="8px">
                    <div
                            [class.fitting]="fr.qualification == 'Fitting'"
                            [class.normal]="fr.qualification == 'Normal'"
                            [class.excluded]="fr.qualification == 'Excluded'"
                            *ngFor="let fr of row.fieldOfActivityRelations" class="field-of-activity" (click)="setNextQualifiedState(row, fr)">{{fr.name}}</div>
                    <div fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z2 add-button" matRipple (click)="addFieldToProfession(row)"><i class="mdi mdi-plus"></i></div>
                </div>
            </td>
        </ng-container>

<!--        <ng-container matColumnDef="actions">-->
<!--            <th mat-header-cell *matHeaderCellDef class="right">Aktionen</th>-->
<!--            <td mat-cell *matCellDef="let row">-->
<!--                <div class="table-button" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">-->
<!--                </div>-->
<!--            </td>-->
<!--        </ng-container>-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</loading-spinner>