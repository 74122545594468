<h1 mat-dialog-title>Notiz bearbeiten</h1>

<div class="dialog-main-content">

<textarea
  class="hid-input"
  placeholder="Trage hier Notizen ein..."
  cdkTextareaAutosize
  [(ngModel)]="note"
  style="min-height: 150px; margin: auto; width: 90%;"
  maxlength="1000">
 </textarea>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-flat-button class="cancel-button" (click)="close()">Abbrechen</button>
  <loading-button [loadingBool]="loading"
                  buttonClass="primary"
                  color="primary"
                  style="margin-right: 15px;"
                  (clickEvent)="save()">
    Speichern
  </loading-button>
</div>
