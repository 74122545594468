<dialog-header dialogHeadline="Mapping entfernen"></dialog-header>

<div class="dialog-content" fxLayout="column">

    <p style="color: #7d7d7d; font-size: 14px">
        Du bist im Begriff das Mapping zwischen der Studiengruppe <b>{{subjectGroupData?.title}}</b> auf den
        Beruf <b>{{professionTitle}}</b> zu entfernen. Das bedeutet, dass alle Matches zu diesem Mapping
        aus den Warteschlangen der Talente entfernt werden. Im folgenden findest du die Anzahl der betroffenen Talente
        und die Anzahl der vernichteten Matches.
    </p>

    <div *ngIf="loadingQueues" fxLayout="row" fxLayoutAlign="center" class="width-100">
        <p  style="color: #7d7d7d; font-size: 14px"> Lade Stellen aus den Warteschlangen... </p>
    </div>

    <loading-spinner [loadingBool]="loadingQueues" style="min-height: 100px">
        <div *ngIf="subjectMappingMatchesData && subjectMappingMatchesData.length == 0">
            <p  style="color: #7d7d7d; font-size: 14px"> Zu diesem Mapping gibt es aktuell keine Matches in Warteschlangen.</p>
        </div>

        <div *ngIf="subjectMappingMatchesData && subjectMappingMatchesData.length > 0" fxLayout="column" class="w-100">
            <div *ngFor="let subject of subjectMappingMatchesData" fxLayout="column">
                <p>Studiengang {{subject.subject.name}}:</p>
                <small><b>Anzahl der Matches:</b> {{subject?.numberOfMatches}}</small>
                <small><b>Anzahl der Talente mit diesem Studiengang:</b> {{subject?.numberOfMatchableTalentsWithSubject}}</small>
                <small><b>Anzahl der Talente mit Matches in der Warteschlange:</b> {{subject?.numberOfTalentsWithMatchesFromMapping}}</small>

                <table mat-table [dataSource]="subject.positionsWithMatchesFromMapping" class="width-100 m-bottom20">
                    <ng-container matColumnDef="positionId">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let position">
                            <span class="copy">{{position.id}} </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="positionTitle">
                        <th mat-header-cell *matHeaderCellDef>Position</th>
                        <td mat-cell *matCellDef="let position">
                            <span class="copy"> {{position.name}}</span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>

        </div>

    </loading-spinner>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <loading-button
                [raised]="true"
                [color]="'primary'"
                [loadingBool]="loading"
                (clickEvent)="removeSubjectGroupToProfessionMapping()">
            Bestätigen
        </loading-button>
    </div>
</div>

