import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminTalentData} from "../../generated/data";
import {AdminEventResource} from "../../generated/resources";
import {PageEvent} from "@angular/material/paginator";
import {FileService} from "../../services/file.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-preview-talents-for-event-filter-sheet',
    templateUrl: './preview-talents-for-event-filter-sheet.component.html',
    styleUrls: ['./preview-talents-for-event-filter-sheet.component.scss']
})
export class PreviewTalentsForEventFilterSheetComponent implements OnInit {

    eventId: number

    data: AdminTalentData[]

    pageIndex: number = 0;
    pageSize: number = 50;
    pageSizeOptions: number[] = [15, 30, 50, 100];
    totalSize: number = 0;

    lastTriggeredAt: Date;

    displayedColumns = ['id', 'name', 'subject', 'regDate']

    loading: boolean = true;
    excelDownloaded: boolean = false;
    creatingCampaign: boolean = false;

    constructor(
        private sideSheetRef: SideSheetRef,
        private adminEventResource: AdminEventResource,
        private fileService: FileService,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        Promise.all([
            this.loadTalents(),
            this.loadCampaignTriggerInfo()
        ]).then(() => {
            this.loading = false
        })
    }

    downloadExcel() {
        this.excelDownloaded = true
        this.fileService.downloadTalentEventFilterExcel(this.eventId)
    }

    createCampaign() {
        this.creatingCampaign = true;
        this.adminEventResource.createMarketingCampaignFromEventFilter(this.eventId).then(() => {
            this.loadCampaignTriggerInfo();
            this.creatingCampaign = false;
        })

    }

    loadTalents(): Promise<any> {
        this.loading = true;
        return this.adminEventResource.getTalentsByEventFilter(this.eventId, {
            page: this.pageIndex,
            pageSize: this.pageSize
        }).then(talents => {
            this.data = talents.content
            this.totalSize = talents.totalElements
            this.loading = false;
        })
    }

    loadCampaignTriggerInfo(): Promise<any> {
        return this.adminEventResource.getLastTriggeredDateForEvent(this.eventId).then(result => {
                if (result.value) {
                    this.lastTriggeredAt = new Date(result.value)
                }
            }
        )
    }

    getBlockDateString() {
        if(!this.lastTriggeredAt) return '-'
        if(new Date() > this.getBlockDate()) {
            return '-'
        } else {
            return this.datePipe.transform(this.getBlockDate(), 'dd.MM.yyyy HH:mm')
        }
    }

    getBlockDate() {
       return new Date(this.lastTriggeredAt.getTime() + (10 * 60 * 1000))
    }

    createCampaignDisabled() {
        if(!this.lastTriggeredAt) return false
        return new Date() < this.getBlockDate()
    }


    handlePage(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadTalents();
    }
}
