import {CompanyCompleteInvoiceInformationData} from "./company-complete-invoice-information-data"
import {LabelData} from "./label-data"
import {SalesProposalState} from "./sales-proposal-state"

export class CompanySalesProposalData {
    assignedPositionIds: number[] = null;
    companyCompleteInvoiceInformationData = new CompanyCompleteInvoiceInformationData();
    companyId: number | null = null;
    discount: number | null = null;
    id: number = null;
    originalPrice: number | null = null;
    priceWithTax: number | null = null;
    priceWithoutTax: number | null = null;
    product = new LabelData();
    serviceDescription: string | null = null;
    size: number | null = null;
    state: SalesProposalState = null;
    taxRate: number | null = null;
    title: string = null;
}