import {Component, OnInit} from '@angular/core';
import { AdminBookingLogData, BookingData} from "../../generated/data";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {
  PositionDetailsDialogComponent
} from "../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {FileService} from "../../services/file.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {
  ApplicationDetailsDialogComponent
} from "../../applications/application-details-dialog/application-details-dialog.component";
import {AdminBookingResource} from "../../generated/resources";

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  booking?: BookingData;
  bookingLogs: AdminBookingLogData[];
  loading: boolean = false;
  profilePictures: { [talentId: number]: SafeUrl } = {};

  constructor(
      public fileService: FileService,
      private sanitizer: DomSanitizer,
      public sideSheetRef: SideSheetRef,
      private dialogService: SideSheetService,
      private adminBookingResource: AdminBookingResource,
  ) {}

  ngOnInit(): void {
    this.booking.applications.forEach(application => {
      this.loadProfilePicture(application.talentId);
    });
    this.loadHistory();
  }

  openPositionDialog(positionId: number) {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
  }
  openTalentProfileDialog(talentId: number) {
    const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge');
    comp.instance.talentId = talentId;
  }
  openApplicationDetails(applicationId: number) {
    let cref = this.dialogService.openOverlay(ApplicationDetailsDialogComponent);
    cref.instance.applicationId = applicationId;
  }
  loadProfilePicture(talentId: number): void {
    this.fileService.getTalentProfilePicture(talentId).subscribe(result => {
      if (result.size > 0)
        this.profilePictures[talentId] = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
      else
        this.profilePictures[talentId] = null;
    });
  }

  loadHistory(): void {
    this.adminBookingResource.getBookingHistory(this.booking.id).then(result => {
      this.bookingLogs = result
    });
  }
}