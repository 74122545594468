import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProfileImageService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.apiUrlMain;
  }

  uploadHeaderImage(file, fileName): Observable<Object> {
    return this.uploadFile(file, fileName, "/talent/headerImage");
  }

  uploadProfilePicture(file, fileName): Observable<Object> {
    return this.uploadFile(file, fileName, "/talent/profilePicture");
  }

  private uploadFile(imageBytes, fileName, path): Observable<Object> {

    const params = new HttpParams().set('fileName', fileName);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    //Dev note: if you save the file here it works as png! id gets destroyed somewhere on the server
    return this.http.post(this.apiUrl + path, imageBytes, options);
  }

  getOwnHeaderImage(): Observable<Blob> {
    return this.http.get(this.apiUrl + "/talent/headerImage", {responseType: "blob"});
  }

  getOwnProfilePic(): Observable<Blob> {
    return this.http.get(this.apiUrl + "/talent/profilePicture", {responseType: "blob"});
  }

  getTalentProfilePic(id): Observable<Blob> {
    return this.http.get(this.apiUrl + "/talents/" + id + "/profilePic", {responseType: "blob"});
  }
}
