import {QuartilesData} from "./quartiles-data"
import {FieldScoreQuartileSegmentationData} from "./field-score-quartile-segmentation-data"
import {ProfessionScoreQuartileSegmentationData} from "./profession-score-quartile-segmentation-data"
import {ProfessionalFieldOfActivityQuartileSegmentationData} from "./professional-field-of-activity-quartile-segmentation-data"
import {SubjectGroupQuartileSegmentationData} from "./subject-group-quartile-segmentation-data"

export class PositionPerformancesQuartilesData {
    fieldScoreSegmentations: FieldScoreQuartileSegmentationData[] = null;
    filterPerformanceQuartiles = new QuartilesData();
    lowMatchPerformanceQuartiles = new QuartilesData();
    primePerformanceQuartiles = new QuartilesData();
    professionScoreSegmentations: ProfessionScoreQuartileSegmentationData[] = null;
    professionalFieldOfActivitySegmentations: ProfessionalFieldOfActivityQuartileSegmentationData[] = null;
    snapShotDate: (Date | null) = null;
    subjectGroupSegmentations: SubjectGroupQuartileSegmentationData[] = null;
    topMatchPerformanceQuartiles = new QuartilesData();
    totalPerformanceQuartiles = new QuartilesData();
}