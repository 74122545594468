<dialog-header dialogHeadline="Ablehngründe"></dialog-header>


<div class="dialog-main-content m-top30 layout-padding" fxLayout="column" fxLayoutAlign="center center"
     fxLayoutGap="30px">
    <loading-spinner [loadingBool]="loading" style="min-height: 333.69px; height: 333.69px" class="w-100">
        <h2>Verteilung</h2>
        <table>
            <tr>
                <th>Ablehngrund</th>
                <th>Anzahl</th>
            </tr>
            <tr>
                <td> Talent nicht erreichbar</td>
                <td>{{applicationFailedStatisticsData?.numNotContactable}}</td>
            </tr>
            <tr>
                <td> Talent nicht interessiert</td>
                <td>{{applicationFailedStatisticsData?.numNotInterested}}</td>
            </tr>
            <tr>
                <td> Talent nicht geeignet</td>
                <td>{{applicationFailedStatisticsData?.numNotSuitable}}</td>
            </tr>
            <tr>
                <td> Talent hat bereits einen Job gefunden</td>
                <td>{{applicationFailedStatisticsData?.numOtherJobFound}}</td>
            </tr>
            <tr>
                <td> Die Stelle wurde gelöscht</td>
                <td>{{applicationFailedStatisticsData?.numPositionDeleted}}</td>
            </tr>
            <tr>
                <td> Die Stelle wurde bereits besetzt</td>
                <td>{{applicationFailedStatisticsData?.numPositionFilled}}</td>
            </tr>
        </table>
        <h2>Kommentare</h2>
        <div class="w-100 comment-section" fxLayout="column" fxLayoutAlign="start" style="padding: 16px">


            <span *ngIf="applicationFailedStatisticsData?.internalComments.length == 0"
                  style="color:grey; font-style: italic">
                Leider wurden keine schriftlichen Ablehngründe angegeben.
            </span>
            <div class="w-100" *ngIf="applicationFailedStatisticsData?.internalComments.length > 0">
                <user-comment *ngFor="let comment of applicationFailedStatisticsData?.internalComments"
                              [comment]="comment"></user-comment>
            </div>
            <div *ngIf="loading" class="w-100" fxLayout="row" fxLayoutAlign="center"
                 style="position: relative; bottom: 50px">
                <span class="hint">Lade User Kommentare</span>
            </div>
        </div>
    </loading-spinner>


</div>

