import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profession-fields-config-dialog',
  templateUrl: './profession-fields-config-dialog.component.html',
  styleUrls: ['./profession-fields-config-dialog.component.scss']
})
export class ProfessionFieldsConfigDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
