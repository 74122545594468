import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AdminCompanyData,
    AdminEmploymentPositionData,
    EmploymentCategory,
    HomeOffice,
    LocationOptionsMode,
} from "../../../generated/data";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdminCompanyResource,} from "../../../generated/resources";

@Component({
    selector: 'position-information',
    templateUrl: './position-information.component.html',
    styleUrls: ['./position-information.component.scss']
})
export class PositionInformationComponent implements OnInit {


    @Input() positionId: number
    @Input() position: AdminEmploymentPositionData

    @Output() reload: EventEmitter<boolean> = new EventEmitter();
    @Output() validationUpdate: EventEmitter<boolean> = new EventEmitter();
    @Output() positionChange: EventEmitter<AdminEmploymentPositionData> = new EventEmitter();

    public homeOfficeCategories: HomeOffice[] = [
        "HomeOfficeAvailable", "OnlyOffice", "OnlyRemote"
    ]

    positionFormGroup: FormGroup;
    customerCompanyData: AdminCompanyData;
    loadingCompanyData: boolean = true;

    locationModes: LocationOptionsMode[] = ['Worldwide', 'Nationwide', 'SpecificStates', 'SpecificCities'];
    employmentCategories: EmploymentCategory[] = ['PermanentEmployment', 'Trainee', 'DualStudies', 'VocationalTraining', 'WorkingStudent', 'MandatoryInternship', 'VoluntaryInternship', 'Thesis', 'TempoaryJob', 'ProjectWork'];


    constructor(
        private fb: FormBuilder,
        private adminCompanyResource: AdminCompanyResource,
    ) {
    }

    ngOnInit(): void {
        this.adminCompanyResource.getCompany(this.position.customerCompanyId).then(data => {
            this.customerCompanyData = data;
            this.loadingCompanyData = false;
        })

        this.positionFormGroup = this.fb.group({
            title: this.position.internTitle,
            prime: this.position.prime,
            draftForwarding: this.position.draftForwarding,
            applicationRequirementsInfo: this.position.applicationRequirementsInfo,
            googleJobPosting: [{
                value: this.position.googleJobPosting,
                disabled: !this.position.companyAllowGoogleJobPosting
            }],
            shortDescription: this.position.employmentPositionShortDescription,
            locationsMode: this.position.locations.mode,
            locationsCities: [this.position.locations.cityIds],
            locationsFederalStates: [this.position.locations.federalStateIds],
            employmentCategory: this.position.employmentCategory,
            startingDate: this.position.startingDate,
            companyAlias: this.position.companyAlias,
            customerCompanyId: this.position.customerCompanyId,
            hiringCompanyId: this.position.hiringCompanyId,
            hiringCompanyName: this.position.hiringCompanyName,
            weeklyHoursFrom: this.position.weeklyHoursFrom,
            weeklyHoursTo: this.position.weeklyHoursTo,
            yearlySalaryFrom: this.position.yearlySalaryFrom,
            yearlySalaryTo: this.position.yearlySalaryTo,
            professionId: this.position.professionId,
            webLink: this.position.webLink,
            showCompany: this.position.showCompany,
            priority: this.position.priority,
            availableSlots: this.position.availableSlots,
            homeOffice: this.position.locations.homeOffice,
        })

        this.validationUpdate.emit(this.positionFormGroup.valid)

        this.positionFormGroup.controls.locationsMode.valueChanges.subscribe(next => {
            if (next == "SpecificStates") {
                this.positionFormGroup.controls.locationsFederalStates.setValidators(Validators.required)
                this.positionFormGroup.controls.locationsFederalStates.updateValueAndValidity()

                this.positionFormGroup.controls.locationsCities.clearValidators()
                this.positionFormGroup.controls.locationsCities.setErrors(null)
                this.positionFormGroup.controls.locationsCities.updateValueAndValidity()
            } else if (next == "SpecificCities") {
                this.positionFormGroup.controls.locationsCities.setValidators(Validators.required)
                this.positionFormGroup.controls.locationsCities.updateValueAndValidity()

                this.positionFormGroup.controls.locationsFederalStates.clearValidators()
                this.positionFormGroup.controls.locationsFederalStates.setErrors(null)
                this.positionFormGroup.controls.locationsFederalStates.updateValueAndValidity()
            } else {
                this.positionFormGroup.controls.locationsFederalStates.clearValidators()
                this.positionFormGroup.controls.locationsFederalStates.setErrors(null)
                this.positionFormGroup.controls.locationsFederalStates.updateValueAndValidity()

                this.positionFormGroup.controls.locationsCities.clearValidators()
                this.positionFormGroup.controls.locationsCities.setErrors(null)
                this.positionFormGroup.controls.locationsCities.updateValueAndValidity()

            }
            this.validationUpdate.emit(this.positionFormGroup.valid)
        })

        this.positionFormGroup.valueChanges.subscribe(next => {
            this.updatePosition()
        })

    }

    updatePosition() {
        this.validationUpdate.emit(this.positionFormGroup.valid);

        this.position.draftForwarding = this.positionFormGroup.controls['draftForwarding'].value;
        this.position.internTitle = this.positionFormGroup.controls['title'].value;
        this.position.prime = this.positionFormGroup.controls['prime'].value;
        this.position.employmentPositionShortDescription = this.positionFormGroup.controls['shortDescription'].value;
        this.position.locations.mode = this.positionFormGroup.controls['locationsMode'].value;
        this.position.locations.federalStateIds = this.positionFormGroup.controls['locationsFederalStates'].value;
        this.position.locations.cityIds = this.positionFormGroup.controls['locationsCities'].value;
        this.position.employmentCategory = this.positionFormGroup.controls['employmentCategory'].value;
        this.position.startingDate = this.positionFormGroup.controls['startingDate'].value;
        this.position.weeklyHoursFrom = this.positionFormGroup.controls['weeklyHoursFrom'].value;
        this.position.weeklyHoursTo = this.positionFormGroup.controls['weeklyHoursTo'].value;
        this.position.yearlySalaryFrom = this.positionFormGroup.controls['yearlySalaryFrom'].value;
        this.position.yearlySalaryTo = this.positionFormGroup.controls['yearlySalaryTo'].value;
        this.position.webLink = this.positionFormGroup.controls['webLink'].value;
        this.position.showCompany = this.positionFormGroup.controls['showCompany'].value;
        this.position.googleJobPosting = this.positionFormGroup.controls['googleJobPosting'].value;
        this.position.priority = this.positionFormGroup.controls['priority'].value;
        this.position.applicationRequirementsInfo = this.positionFormGroup.controls['applicationRequirementsInfo'].value;
        this.position.availableSlots = this.positionFormGroup.controls['availableSlots'].value;
        this.position.locations.homeOffice = this.positionFormGroup.controls['homeOffice'].value;

        this.positionChange.emit(this.position);
    }
}
