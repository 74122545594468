import {Component, ViewChild} from '@angular/core';
import {saveAs} from "file-saver/FileSaver"
import {Router} from "@angular/router";
import {TalentService} from "../services/talent.service";
import {FileService} from "../services/file.service";
import {
    AdminEmploymentPositionData,
    AdminTalentData,
    AdminTalentFilterData,
    AdminTalentPositionRelationData,
    ApplicantPersonaData,
    CityData,
    CompleteTalentProfileData,
    LabelData, Origin,
    PositionRelationFilterData,
    ProfessionData,
    StudiesData,
    TalentPositionRelationState
} from '../generated/data';
import {
    AdminCareerEventResource,
    AdminEmploymentPositionResource,
    AdminProfessionResource,
    AdminResource,
    AdminTalentProfileResource,
    AdminTalentResource,
    LocationResource,
    ProfessionFieldPreferenceResource
} from "../generated/resources";
import {Paginator} from "../utils/paginator";
import {MatDialog} from "@angular/material/dialog";
import {UtilityService} from "../utils/utility.service";
import {FormControl} from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TalentProfileDialogComponent} from "./talent-profile-dialog/talent-profile-dialog.component";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {PositionDetailsDialogComponent} from "../positions/position-details-dialog/position-details-dialog.component";
import {CreatePositionRecommendationsDialogComponent} from "./create-position-recommendations-dialog/create-position-recommendations-dialog.component";
import {MatchingAnalyzeSheetComponentComponent} from "../matching/matching-optimization/matching-analyze-sheet-component/matching-analyze-sheet-component.component";
import {CompleteCoachingDataSideSheetComponent} from "./complete-coaching-data-side-sheet/complete-coaching-data-side-sheet.component";

@Component({
    selector: 'app-dasboard-talent',
    templateUrl: './talents.component.html',
    styleUrls: ['./talents.component.scss']
})
export class TalentsComponent extends Paginator {

    @ViewChild('dataTable') public table: MatTable<any>;

    filterData = <PositionRelationFilterData>{}

    allOrigins: Origin[] = ["Eu", "NotEu", "German", "Unknown"]

    displayedColumns = [
        'id',
        // 'qualityScore',
        'name',
        'numPrimePositions',
        'lastSubjectTitle',
        'onboardingComplete',
        'utmCampaign',
        'cv',
        'studyEndDate',
        'actions'
    ];

    dataSource: AdminTalentData[] = [];
    cvFile: Blob = null;

    positionDetailsMode: boolean = false
    talentDetailsMode: boolean = false

    profession: ProfessionData
    talentId: number
    positionId: number

    recommendationTalenIdSelected: boolean = false
    recommendationSelectedTalentId: number

    displayedColumnsPrime: string[] = ['primefilter', 'locationSuitable', 'position', 'company', 'score', 'state', 'action'];

    talentDataRecommendation: CompleteTalentProfileData
    loadingRecommendationTalent: boolean = false
    loadingPosition: boolean = false

    employmentPositionData: AdminEmploymentPositionData
    positionCitiesData: CityData[]

    loading: boolean = false;
    downloading: boolean = false;
    exporting: boolean = false;

    studyEndDateFrom?: Date = null
    studyEndDateTo?: Date = null

    pageIndex: number = 0;
    pageSize: number = 15;
    pageSizeOptions: number[] = [15, 30, 60, 100];
    totalSize: number = 0;

    considerCVCheckbox: FormControl = new FormControl(false)
    selectedTalentOrigins: FormControl = new FormControl(this.allOrigins)
    considerOBCCheckbox: FormControl = new FormControl(true)
    considerPrimeCheckbox: FormControl = new FormControl(false)
    considerIsUncheckedCheckbox: FormControl = new FormControl(true)
    considerCoachDatesCheckbox: FormControl = new FormControl(false)

    talentProfessionGroupPreferences: string[];
    mostRecentStudy: StudiesData
    onlySuitableForTalent: boolean = false
    isMatch: boolean = false;
    talentPositionRelationDataSource = new MatTableDataSource()

    relationState: TalentPositionRelationState

    constructor(
        private adminTalentProfileResource: AdminTalentProfileResource,
        public adminTalentResource: AdminTalentResource,
        private fileService: FileService,
        public router: Router,
        public talentService: TalentService,
        private dialog: MatDialog,
        private utilityService: UtilityService,
        private adminResource: AdminResource,
        private dialogService: SideSheetService,
        private adminCareerEventResource: AdminCareerEventResource,
        private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
        private adminEmploymentPositionResource: AdminEmploymentPositionResource,
        private sideSheetService: SideSheetService,
        private locationResource: LocationResource,
        private professionResource: AdminProfessionResource
    ) {

        super("", () => {
            this.loadData()
        })
    }

    ngOnInit() {
        this.sortAsc = false;
        this.loadData();
        this.talentPositionRelationDataSource.filterPredicate = (s: AdminTalentPositionRelationData , filter: string) => (s.position.title.toLocaleLowerCase()+s.position.customerCompany.name.toLocaleLowerCase()).indexOf(filter) != -1;
    }

    getCVFile(talentId): Promise<any> {
        return new Promise(resolve => {
            this.fileService.getCV(talentId).subscribe(cv => {
                this.cvFile = cv
                resolve()
            })
        })
    }

    loadData() {
        this.loading = true;
        this.applyFilter(this.talentPositionRelationDataSource, '')

        let filterData = <AdminTalentFilterData> {
            onlyWithAttachedCv: this.considerCVCheckbox.value ? this.considerCVCheckbox.value : null,
            onlyWithCompletedOnboarding: this.considerOBCCheckbox.value ? this.considerOBCCheckbox.value : null,
            isPrime: this.considerPrimeCheckbox.value ? this.considerPrimeCheckbox.value : null,
            isUheckedByAdmin: this.considerIsUncheckedCheckbox.value ? this.considerIsUncheckedCheckbox.value : null,
            needsCoachingCriteriaUpdate: this.considerCoachDatesCheckbox.value ? this.considerCoachDatesCheckbox.value : null,
            deleted: false,
            studyEndDateTo: this.studyEndDateTo,
            origin: this.selectedTalentOrigins.value,
            studyEndDateFrom: this.studyEndDateFrom,
        }

        this.adminTalentResource.getTalents(filterData, {
            sortAsc: this.sortAsc,
            page: this.pageIndex,
            pageSize: this.pageSize
        }).then(
            talentData => {
                this.dataSource = talentData.content;
                this.totalSize = talentData.totalElements;
                this.loading = false;
            });
    }

    applyFilter(dataSource: MatTableDataSource<any>, filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        dataSource.filter = filterValue
    }

    getMostRecentStudiesForTalent(talentId): Promise<any> {
        return this.adminTalentResource.getMostRecentStudiesForTalent(talentId).then(result => {
            this.mostRecentStudy = result;
        });
    }

    showCompleteCoachDataDialog(element){

        let comp = this.dialogService.openOverlay(CompleteCoachingDataSideSheetComponent, 'Huge')
        comp.instance.talentId = element.id

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
           element.needsCoachingCriteriaUpdate = !res;
            subscription.unsubscribe();
        })
    }

    getTalentPreferences(talentId): Promise<any> {
        return this.professionFieldPreferenceResource.getProfessionFieldPreferencesForTalentAsAdmin(talentId).then(result => {
            if (result && result.length > 0) this.talentProfessionGroupPreferences = result.filter(p => ["Interesting", "VeryInteresting"].includes(p.preferenceScore)).map(p => p.fieldName)
            else this.talentProfessionGroupPreferences = [];

        })
    }

    getTalent(talentId: number): Promise<any> {
        return this.adminTalentResource
            .getDetailedTalentForAdmin(talentId)
            .then(p => {
                this.talentDataRecommendation = p;
            });
    }

    checkTalent(talentId) {
        this.adminTalentResource.markTalentAsChecked(talentId).then(() => {
            this.dataSource[this.dataSource.findIndex(t => t.id == talentId)].wasChecked = true
        })
    }

    loadProfessions(professionId) {
        this.professionResource.getProfession(professionId).then(profession => {
                this.profession = profession
                this.loadingPosition = false;
            }
        )
    }


    private getTalentDataForRecommendation(profileId) {
        this.loadingRecommendationTalent = true;

        Promise.all([
            this.getTalent(profileId),
            this.getMostRecentStudiesForTalent(profileId),
            this.getTalentPreferences(profileId),
            this.getCVFile(profileId)
        ]).then(() => {
            this.loadingRecommendationTalent = false;
        });
    }

    selectTalentForRecommendation(talent: AdminTalentData) {
        this.recommendationSelectedTalentId = talent.id
        this.talentDetailsMode = true
        this.recommendationTalenIdSelected = true
        this.getTalentDataForRecommendation(this.recommendationSelectedTalentId)
        this.getPrimePositions(this.recommendationSelectedTalentId)
    }

    getPrimePositions(talentId: number): Promise<any> {
        this.filterData.relationStates = this.relationState? [this.relationState] : null
        this.filterData.isMatch = this.isMatch? this.isMatch : null
        this.filterData.talentId = talentId
        this.filterData.origins = this.onlySuitableForTalent? ["Uniwunder", "Campusjaeger"] : ["Uniwunder"]
        this.filterData.isPrimeFilterMatch = this.onlySuitableForTalent? this.onlySuitableForTalent : null
        this.loadingPosition = true
        return this.adminEmploymentPositionResource.getPrimePositionsForTalent(this.filterData
        ).then(result => {
            // this.primePositionsData = result
            this.talentPositionRelationDataSource.data = result
            this.loadingPosition = false
        })
    }

    persona: ApplicantPersonaData

    getApplicantPersonaForPosition(positionId) {
        this.adminEmploymentPositionResource.getApplicantPersonaForId(positionId).then(result => {
            if (result) {
                this.persona = result
            }
        })
    }

    openPositionDetailsDialog(positionId) {
        let comp = this.sideSheetService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = positionId
    }

    openRecommendPositionDialog(position) {
        const comp = this.sideSheetService.openOverlay(CreatePositionRecommendationsDialogComponent, 'Small')
        comp.instance.positions = [<LabelData>{name: position.title, id: position.id}]
        comp.instance.mode = 'SingleTalentMode'
        comp.instance.talentId = this.recommendationSelectedTalentId

        let sub = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
            if(result) {
                this.dataSource[this.dataSource.findIndex(t => t.id == this.recommendationSelectedTalentId)].wasChecked = true
                this.talentDataRecommendation.wasChecked = true

            }
        })
    }

    openMatch(talentId: number, positionId: number) {
        let cref = this.sideSheetService.openOverlay(MatchingAnalyzeSheetComponentComponent)
        cref.instance.talentId = talentId;
        cref.instance.positionId = positionId;
    }

    closePositionDetailsMode() {
        this.positionDetailsMode = false
    }

    closeTalentDetailsMode() {
        this.talentDetailsMode = false
        this.positionDetailsMode = false
        this.recommendationTalenIdSelected = false
    }

    getPositionDetails(data: AdminTalentPositionRelationData) {
        this.positionDetailsMode = true
        this.talentId = data.talent.id
        this.positionId = data.position.id
        this.loadingPosition = true;
        this.getApplicantPersonaForPosition(data.position.id)
        this.adminEmploymentPositionResource.getSingleEmploymentPositionById(data.position.id).then(position => {
            this.employmentPositionData = position;

            this.locationResource.getCitiesById({cityIds: position.locations.cityIds}).then(data => {
                this.positionCitiesData = data
            })

            this.loadProfessions(position.professionId)
        });
    }

    downloadCV(talentId) {
        this.downloading = true;
        this.talentService.downloadAndSaveCv(talentId).subscribe(
            () => {
                this.downloading = false;
            }
        )
    }

    onDownloadExcel() {
        this.exporting = true;
        this.fileService.downloadTalentExcel().subscribe(response => {
            let today = new Date().toLocaleDateString('de-DE');

            if (response.size > 0) {
                saveAs(response, `Alle_Talente_${today}.xlsx`);
            }
            this.exporting = false;
        });
    }

    openTalentProfile(talentId: number) {
        let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
        comp.instance.talentId = talentId
    }

    showTalentNoteDialog(row) {
        this.talentService.showTalentNoteDialog(row.id).afterClosed().subscribe((result) => row.note = result);
    }

    copyToClipboard(textToCopy: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = textToCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.utilityService.showSnackBar('In Zwischenablage kopiert');
    }
}
