import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {FileUploadDialogComponent} from "../utils/file-upload-dialog/file-upload-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl} from '@angular/forms';
import {AdminComponent} from "../admin.component";
import {ConfirmDialogComponent} from '../utils/confirm-dialog/confirm-dialog.component';
import {UtilityService} from '../utils/utility.service';
import {
    AdminEmploymentPositionDisplayData,
    AdminEmploymentPositionFilterData,
    EmploymentPositionState,
    JsonWrappedValue,
} from '../generated/data';
import {AdminCompanyResource, AdminEmploymentPositionResource, LocationResource} from '../generated/resources';
import {CreatePositionDialogComponent} from './create-position-dialog/create-position-dialog.component';
import {PositionPrivacyDisclaimerDialogComponent} from "./position-privacy-disclaimer-dialog/position-privacy-disclaimer-dialog.component";
import {debounceTime} from 'rxjs/internal/operators';
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {PositionDetailsDialogComponent} from "./position-details-dialog/position-details-dialog.component";

@Component({
    selector: 'app-positions',
    templateUrl: './positions.component.html',
    styleUrls: ['./positions.component.css']
})

export class PositionsComponent implements OnInit {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('dataTable', {static: true})
    public table: MatTable<any>;

    displayedColumns = [
        'positionId',
        'company',
        'positionTitle',
        'availableSlots',
        'positionType',
        'creationDate',
        'state',
        'actions'
    ];

    mediationFilterMode: "OnlyMediated" | "NotMediated" | "NotRelevant" = "NotRelevant";

    dataSource: AdminEmploymentPositionDisplayData[];

    pageSize: number = 15;
    pageSizeOptions: number[] = [15, 30, 60, 100];
    totalSize: number = 0;
    pageIndex: number = 0;

    loading: boolean = false;
    sortAsc: boolean = false;
    mediated: boolean = false;
    mediationRelevant: boolean = true;
    filterPanelIsOpen: boolean = false;

    searchQuery = new FormControl();
    includeAPIPositions = false;

    constructor(
        public companyResource: AdminCompanyResource,
        public adminEmploymentPositionResource: AdminEmploymentPositionResource,
        public locationResource: LocationResource,
        private dialog: MatDialog,
        private router: Router,
        private adminComponent: AdminComponent,
        private utilityService: UtilityService,
        private dialogService: SideSheetService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.loading = true;

        this.loadData();



        this.searchQuery.valueChanges.subscribe(() => {
            this.loading = true;
        });

        this.searchQuery.valueChanges.pipe(debounceTime(500)).subscribe(searchQuery => {
            this.filterData.q = searchQuery;

           this.loadData()
        });

        this.route.queryParams.subscribe(params => {
            if(params['positionId']){
                this.openPositionDetailsDialog(params['positionId'])

            }
        })
    }

    filterData: AdminEmploymentPositionFilterData = new AdminEmploymentPositionFilterData();

    switchPositionState(event: MatSlideToggleChange) {
        this.filterData.withInactive = event.checked;
        this.loadData();
    }

    duplicateEmploymentPosition(positionId: number, posTitle: string) {

        let dialogRef =
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: `Möchtest du die Stelle "${posTitle}" wirklick kopieren?`,
                    confirmButtonText: 'Kopieren',
                    title: 'Stelle Kopieren',
                    onConfirm: () => {return new Promise((resolve => {resolve(true)}))}
                },
                panelClass: [''],
                maxWidth: 600
            })


        dialogRef.afterClosed().subscribe(next => {
            if (next) {
                this.adminEmploymentPositionResource.duplicateEmploymentPosition(positionId).then(result => {
                    this.openPositionDetailsDialog(result.id)
                })
            }
        })
    }

    loadData() {

        this.loading = true;
        this.mediationRelevant = this.mediationFilterMode != 'NotRelevant';
        this.mediated = this.mediationFilterMode == 'OnlyMediated';
        if(this.includeAPIPositions){
            this.filterData.origin = null
        }else{
            this.filterData.origin = 'Uniwunder'
        }

        this.adminEmploymentPositionResource.getFilteredEmploymentPositions(
            this.filterData, {
                page: this.pageIndex,
                pageSize: this.pageSize,
                sortAsc: this.sortAsc
            }).then(
            positionData => {
                this.dataSource = positionData.content;
                this.totalSize = positionData.totalElements;
                this.loading = false;
            });
    }

    handlePage(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadData();
    }



    createPositionDialog() {
        let cref = this.dialogService.openOverlay(CreatePositionDialogComponent)

        cref.instance.sideSheetRef.sheetClosed.subscribe((positionId) => {
            if (positionId) {
                this.openPositionDetailsDialog(positionId, 1)
                this.adminComponent.ngOnInit();
            }
        })
    }

    createFileUploadDialog() {
        let dialogRef = this.dialog.open(FileUploadDialogComponent, {});
        dialogRef.afterClosed().subscribe((reload) => {
            if (reload) {
                this.loadData();
            }
        })
    }

    openPrivacyDisclaimerDialog(position: AdminEmploymentPositionDisplayData) {
        this.dialog.open(PositionPrivacyDisclaimerDialogComponent, {data: position.id})
    }

    openPositionDetailsDialog(positionId, tabIndex: number = 0) {
        let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = positionId
        comp.instance.tabIndex = tabIndex
    }

    openFilterPanel() {
        this.filterPanelIsOpen = !this.filterPanelIsOpen;
    }

    copyToClipboard(textToCopy: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = textToCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.utilityService.showSnackBar('In Zwischenablage kopiert');
    }

    navigateToTalentTargeting(positionId: number) {
        this.router.navigate([`positions/${positionId}/targeting`])
    }
}
