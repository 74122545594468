<div style="background-color: white; margin: 20px;" class="mat-elevation-z6">
<mat-tab-group>
    <mat-tab label="Berufe-Tätigkeitsfeld Preset">
        <app-profession-field-qualification-editor></app-profession-field-qualification-editor>
    </mat-tab>

    <mat-tab label="Stellen-Tätigkeitsfeld Editor">
        <app-position-field-qualification-editor></app-position-field-qualification-editor>
    </mat-tab>
</mat-tab-group>
</div>