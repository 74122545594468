import {AddressData} from "./address-data"
import {PublicEventCompanyData} from "./public-event-company-data"

export class PublicEventData {
    address = new AddressData();
    companies: PublicEventCompanyData[] = null;
    companyShortCompanyInfo: string | null = null;
    companyWebsite: string | null = null;
    end: (Date | null) = null;
    formatId: number | null = null;
    fullAddressText: string | null = null;
    headerImage: number | null = null;
    id: number = null;
    images: number[] | null = null;
    longDescription: string | null = null;
    partnerLogos: number[] | null = null;
    promotionId: number | null = null;
    shortDescription: string | null = null;
    showEvent: boolean = null;
    start: (Date | null) = null;
    tileText: string | null = null;
    title: string = null;
    videoEmbedLink: string | null = null;
}