<h1 mat-dialog-title>Sprach-Skill hinzufügen</h1>

<div fxLayout="column" fxLayoutAlign="space-between center" class="dialog-main-content">
  <div fxLayout="row" style="width: 100%" fxLayoutGap="10px">
    <app-material-async-dropdown
      [(model)]="currentLanguageId"
      placeholder="Sprache"
      [nullable]="false"
      [multiple]="false"
      displayProperty="name"
      [loadingFunctionConfig]="{resource: languageResource, functionName: 'getLanguagesAsync'}"
    ></app-material-async-dropdown>

    <div fxLayout="row" fxLayoutAlign="start center" class="stars-wrapper">
      <div *ngFor="let star of [0,1,2,3,4]" class="stars">
        <i class="mdi mdi-star"
           style="font-size: 25px"
           [style.color]="getStarColor(currentLevel, star)" (click)="setLevel(star)"></i>
      </div>
    </div>
  </div>

</div>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px"
     class="primary-text-color button-bar">
  <button mat-flat-button class="cancel-button" (click)="close()">Abbrechen</button>
  <loading-button [loadingBool]="saving" (clickEvent)="save()" [disabledCondition]="!currentLanguageId"
                  buttonClass="mat-primary">Speichern
  </loading-button>
</div>
