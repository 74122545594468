<dialog-header [dialogHeadline]="'Studiengang-Performance für ' + position.title"></dialog-header>
<div class="dialog-content">
<div fxLayout="row" fxLayoutAlign="end center">
<mat-slide-toggle [(ngModel)]="showRelativeValues">Relative Werte anzeigen</mat-slide-toggle>
</div>
<loading-spinner [loadingBool]="loading">
    <table mat-table [dataSource]="data" class="max-table" #feedControlTable>

        <ng-container matColumnDef="positionTitle">
            <th mat-header-cell *matHeaderCellDef>Studiengruppe</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.groupTitle">
                {{element.groupTitle | sliceStringLength: 30}}
            </td>
        </ng-container>

        <ng-container matColumnDef="totalPushes">
            <th mat-header-cell *matHeaderCellDef>Pushes</th>
            <td mat-cell *matCellDef="let element">
                {{element.totalPushes}}
            </td>

        </ng-container>
        <ng-container matColumnDef="seenCount">
            <th mat-header-cell *matHeaderCellDef>Gesehen</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!showRelativeValues">{{element.seenCount}}</span>
                <span *ngIf="showRelativeValues">{{element.seenCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="clickedCount">
            <th mat-header-cell *matHeaderCellDef>Geklickt</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!showRelativeValues">{{element.clickedCount}}</span>
                <span *ngIf="showRelativeValues">{{element.clickedCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="ctaClickCount">
            <th mat-header-cell *matHeaderCellDef>CTA-Click</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!showRelativeValues">{{element.ctaClickCount}}</span>
                <span *ngIf="showRelativeValues">{{element.ctaClickCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="appliedCount">
            <th mat-header-cell *matHeaderCellDef>Beworben</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'aboveAvg': (element.appliedCount/element.totalPushes)> avgApplicationRate,'underAvg': (element.appliedCount/element.totalPushes) < avgApplicationRate}">
                <span *ngIf="!showRelativeValues">{{element.appliedCount}}</span>
                <span *ngIf="showRelativeValues">{{element.appliedCount / element.totalPushes * 100 | number: '1.0-2'}}%</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openPosition(element.position.id)"><i class="mdi mdi-information"></i></button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="['positionTitle','totalPushes','seenCount','clickedCount','ctaClickCount','appliedCount','actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['positionTitle','totalPushes','seenCount','clickedCount','ctaClickCount','appliedCount','actions'];"></tr>
    </table>
</loading-spinner>

</div>
