import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AdminProfessionResource, ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {FormControl, Validators} from "@angular/forms";
import {MatchingResultData, ProfessionRelationData} from "../../../generated/data";

@Component({
  selector: 'app-talent-position-match-simulator',
  templateUrl: './talent-position-match-simulator.component.html',
  styleUrls: ['./talent-position-match-simulator.component.scss']
})
export class TalentPositionMatchSimulatorComponent implements OnInit {

  subjectControl = new FormControl();
  professionControl = new FormControl();

  talentControl = new FormControl();
  positionControl = new FormControl();

  loadedSubjectProfession = false
  loadedTalentPosition = false

  result: MatchingResultData = <MatchingResultData>{}

  constructor(
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private cd: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
  }

  loadSubjectProfession(){
    if (this.subjectControl.value != null && this.professionControl.value != null) {
      this.loadedSubjectProfession = false
      this.cd.detectChanges()
      this.loadedSubjectProfession = true
    }
  }

  loadTalentPosition() {
    if (this.talentControl.value != null && this.positionControl.value != null) {
      this.loadedTalentPosition = false
      this.cd.detectChanges()
      this.loadedTalentPosition = true
    }
  }
}
