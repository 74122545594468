import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationThreshold'
})
export class LocationThresholdPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 3:
      case 4: return 'Normal'
      case 5: return 'Streng'
      case 6: return 'Sehr streng'
      case 7: return 'Bedingungslos'
    }
  }

}
