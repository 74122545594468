import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {UniversityData} from "../../../generated/data";
import {UniversityResource} from "../../../generated/resources";
import {debounceTime} from 'rxjs/internal/operators';

@Component({
  selector: 'app-searchable-university-dropdown',
  templateUrl: './searchable-university-dropdown.component.html',
  styleUrls: ['./searchable-university-dropdown.component.css']
})
export class SearchableUniversityDropdownComponent implements OnInit {

  @Input() model: number;
  @Input() formControl: AbstractControl;
  @Input() placeholder: string = '';


  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>()
  searchTerm: FormControl = new FormControl()

  options: UniversityData[] = []

  constructor(private  universityResource: UniversityResource) { }

  ngOnInit() {
    if(!this.formControl){
      this.formControl = new FormControl()
      this.formControl.setValue(this.model)
    }

    this.formControl.valueChanges.subscribe(value =>{
      this.modelChange.emit(value)
    })

    this.searchTerm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.load()
      })
    this.load()
  }

  load() {
    //TODO additional Id
    this.universityResource.getUniversitiesByQuery({q: this.searchTerm.value, pageSize: 10, forcedIds: [this.formControl.value]}).then(res => {
        this.options = res.content;
      }
    )
  }

}
