<mat-tab-group>
    <mat-tab label="Subject-Profession">
        <div fxLayout="row" fxLayoutAlign="start center">
        <searchable-subject-dropdown [control]="subjectControl" placeholder="Subject" style="width: 350px; margin-left: 10px; margin-top: 10px"></searchable-subject-dropdown>
        <searchable-profession-dropdown [control]="professionControl" placeholder="Profession" style="width: 350px; margin-left: 10px; margin-top: 10px"></searchable-profession-dropdown>

        <button mat-icon-button (click)="loadSubjectProfession()"><i class="mdi mdi-refresh mdi-24px"></i></button>
        </div>

        <div *ngIf="loadedSubjectProfession">
            <profession-subject-group-relation-board [professionId]="+professionControl.value" [subjectId]="+subjectControl.value"></profession-subject-group-relation-board>
        </div>
    </mat-tab>

    <mat-tab label="Talent-Position">
        <div fxLayout="row" fxLayoutAlign="start center">
            <searchable-talent-dropdown [control]="talentControl" placeholder="Talent" style="width: 350px; margin-left: 10px; margin-top: 10px"></searchable-talent-dropdown>
            <searchable-position-dropdown [control]="positionControl" placeholder="Position" [multiple]="false" style="width: 350px; margin-left: 10px; margin-top: 10px"></searchable-position-dropdown>
            <button mat-icon-button (click)="loadTalentPosition()"><i class="mdi mdi-refresh mdi-24px"></i></button>
        </div>

        <div *ngIf="loadedTalentPosition" style="width: 100%; float: left">
            <app-matching-analyze-component id="talentPositionResults" [talentId]="+talentControl.value" [positionId]="+positionControl.value"></app-matching-analyze-component>
        </div>
    </mat-tab>
</mat-tab-group>