<div fxLayout="row">
<div fxLayout="column">
    <div><b>Fehlercode {{error.statusCode}}</b></div>
    <div *ngIf="errorHint">{{errorHint}}</div>
    <div >Ggf. Fehlerbericht kopieren und <a href="https://app.asana.com/0/1174320059362285/list" target="_blank">hier</a> einen Task erstellen</div>
</div>
    <button mat-button (click)="copyErrorReport()">
        <i class="mdi mdi-content-copy"></i>
        Fehlerbericht kopieren</button>
</div>
