<dialog-header dialogHeadline="DKZ Mappings"></dialog-header>

<mat-accordion *ngIf="dataSource">
    <mat-expansion-panel *ngFor="let area of dataSource">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{area.dkzStudyAreaName}}
            </mat-panel-title>

            <mat-panel-description>
                <span>DKZ-Area</span>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <mat-expansion-panel *ngFor="let field of area.dkzStudyFields">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{field.dkzStudyFieldName}}
                    </mat-panel-title>

                    <mat-panel-description>
                        <span>DKZ-StudyField</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <mat-expansion-panel *ngFor="let group of field.dkzSubjectGroups" [disabled]="clickButton" (click)="clickButton=false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{group.dkzSubjectGroupName}}
                            </mat-panel-title>

                            <mat-panel-description>
                                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" class="w-100">
                                    <span>DKZ-SubjectGroup</span>
                                    <button mat-flat-button
                                            color="primary"
                                            matTooltip="SubjectGroup für Zuordnung auswählen" style="color: white"
                                            (click)="selectSubjectGroup(group.dkzSubjectGroupId)">
                                        Zuordnen
                                    </button>
                                </div>

                            </mat-panel-description>

                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <mat-expansion-panel *ngFor="let subject of group.subjects" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{subject.name}}
                                    </mat-panel-title>

                                    <mat-panel-description>
                                        <span>DKZ-Subject</span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-template>
                    </mat-expansion-panel>
                </ng-template>
            </mat-expansion-panel>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>
