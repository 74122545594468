import {AccountInfoData} from "./account-info-data"
import {CompanyPricingMode} from "./company-pricing-mode"
import {OnboardingStep} from "./onboarding-step"

export class RecruiterAccountInfoData extends AccountInfoData {
    acceptLatestTermsAndConditions: boolean = null;
    companyInactive: boolean = null;
    companyName: string = null;
    companyPricingMode: CompanyPricingMode | null = null;
    fullName: string = null;
    onboardingCompleted: boolean = null;
    onboardingStep: OnboardingStep | null = null;
    privacyOptIn: boolean = null;
}