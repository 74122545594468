import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {ConfirmSideSheetComponent} from "../../../utils/confirm-side-sheet/confirm-side-sheet.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {
  JsonWrappedValue,
  LabelData, ProfessionMatchingTypeSubjectGroupData,
  ProfessionWithMappingInfoData,
  SubjectGroupData
} from "../../../generated/data";
import {SelectionModel} from "@angular/cdk/collections";
import {UtilityService} from "../../../utils/utility.service";
import {MatTableDataSource} from "@angular/material/table";
import {SubjectGroupDetailsSheetComponent} from "../subject-group-details-sheet/subject-group-details-sheet.component";
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
  selector: 'app-map-profession-to-subject-group-sheet',
  templateUrl: './map-profession-to-subject-group-sheet.component.html',
  styleUrls: ['./map-profession-to-subject-group-sheet.component.scss']
})
export class MapProfessionToSubjectGroupSheetComponent implements OnInit {

  public professionId: number
  public professionTitle: string
  public professionMappingData: ProfessionWithMappingInfoData
  public mappedSubjectGroupsDataSource: MatTableDataSource<SubjectGroupData> = new MatTableDataSource()
  public loading: boolean = false
  public subjectGroupControl: FormControl = new FormControl()

  public professionMatchingTypeSubjectGroupData: ProfessionMatchingTypeSubjectGroupData[]


  constructor(
      private professionResource: AdminProfessionResource,
      private adminSubjectResource: AdminSubjectResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private utilityService: UtilityService,
      private sideSheetRef: SideSheetRef,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mappedSubjectGroupsDataSource.filterPredicate = (s: SubjectGroupData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadData()
  }

  loadData() {
    this.professionResource.getProfessionWithMappingInfo(this.professionId).then(professionMappingData => {
      this.professionMappingData = professionMappingData
      this.professionMatchingTypeSubjectGroupData = professionMappingData.subjectGroupToMatchingType
      this.adminSubjectResource.getSubjectGroups({subjectGroupIds: this.professionMappingData.profession.matchedSubjectGroups.map(sg => sg.id)}).then(subjectGroups => {
        this.mappedSubjectGroupsDataSource.data = subjectGroups
        this.cdr.detectChanges()
      })
    })
  }

  saveMappings(): void {
    this.professionMatchingOptimizingResource.addProfessionToSubjectGroupMapping(
        {subjectGroupId: this.subjectGroupControl.value, professionId: this.professionId}
    ).then(() => this.sideSheetRef.sheetClosed.next(true)
    )
  }

  selection = new SelectionModel<SubjectGroupData>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.mappedSubjectGroupsDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.mappedSubjectGroupsDataSource.data.forEach(row => this.selection.select(row));
  }

  deleteSelectedSubjectGroupMappings(){
    let cref = this.dialogService.openOverlay(ConfirmSideSheetComponent,'Small')

    cref.instance.title = "Gewählte Mappings clearen"
    cref.instance.message = "Diese Funktion löscht alle gewählten Subjectgroup-Mappings für diesen Beruf. Dieser Vorgang ist nicht umkehrbar"
    cref.instance.buttonText = "Mappings löschen"

    cref.instance.onConfirm = () => this.doDeleteSelectedSubjectGroupMappings()
  }

  loadDeleteGroupMappings = false;
  doDeleteSelectedSubjectGroupMappings(){
    const subjectIdsToClear = this.selection?.selected?.map(it => it.id);
    this.loadDeleteGroupMappings = true;
    return  this.professionMatchingOptimizingResource.removeSubjectGroupToProfessionMappingsForProfession(<JsonWrappedValue<number[]>>{value: subjectIdsToClear}, this.professionId).then(() =>{
      this.loadDeleteGroupMappings = false;
    })
  }

  deleteSubjectGroupMapping(subjectGroup: SubjectGroupData) {
    this.mappedSubjectGroupsDataSource.data = this.mappedSubjectGroupsDataSource.data.filter(sg => sg.id != subjectGroup.id)
    this.professionMatchingOptimizingResource.removeSubjectGroupToProfessionMapping({groupId: subjectGroup.id, professionId: this.professionId}).then(() => {
      this.utilityService.showSnackBar(`${subjectGroup.title} Mapping erfolgreich entfernt.`)
    })
  }

  saveProfessionSubjectGroupMapping(groupId: number): void {
    this.professionMatchingOptimizingResource.addProfessionToSubjectGroupMapping(
        {subjectGroupId: groupId, professionId: this.professionId}
    ).then(() => {
      this.loadData()
      this.utilityService.showSnackBar("SubjectGroup Mapping erfolgreich hinzugefügt!")
    })
  }

  openSubjectGroupDetailsSheet(subjectGroup: LabelData) {
    let cref = this.dialogService.openOverlay(SubjectGroupDetailsSheetComponent)
    cref.instance.subjectGroupId = subjectGroup.id;
    cref.instance.subjectGroupTitle = subjectGroup.name;
  }

  applySubjectGroupFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.mappedSubjectGroupsDataSource.filter = filterValue;
  }

  onTabChanged(event: MatTabChangeEvent) {
    if(event.index == 1) {
      this.loadData()
    }
  }
}
