import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'careerEvent'
})
export class CareerEventPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'Apprenticeship':
        return 'Ausbildung';
      case 'WorkingStudent':
        return 'Werkstudent';
      case 'Thesis':
        return 'Abschlussarb.';
      case 'SemesterAbroad':
        return 'Auslandssemester';
      case 'Internship':
        return 'Praktikum';
      case 'IntegratedDegreeProgram':
        return 'Duales Studium';
      case 'Studies':
        return 'Studium';
      case 'Freelancing':
        return 'Freiberuflich';
      case 'Job':
        return 'Beschäftigung';
      case 'Volunteering':
        return 'Ehrenamt';
      case 'Training':
        return 'Weiterbildung';
      case 'Education':
        return 'Schulbildung';
    }
  }

}
