<dialog-header [dialogHeadline]="'Bewerbung nachtragen'"></dialog-header>

<div id="dialog-content-wrapper ">
    <div class="content">
        <searchable-customer-positions-dropdown
                [multiple]="false"
                [control]="searchPositionIdControl"
                placeholder="Stelle">
        </searchable-customer-positions-dropdown>
    </div>
    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="true"
                        buttonClass="mat-primary"
                        [loadingBool]="createApplicationLoading"
                        [disabledCondition]="!searchPositionIdControl.value"
                        (clickEvent)="createApplication()">
            Bewerbung erstellen
        </loading-button>
    </div>
</div>