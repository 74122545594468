<div class="mat-elevation-z6 filter-panel" fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>Filterblock {{index}}</h3>

        <div fxLayout="row" fxLayoutGap="10px">
            <button mat-icon-button
                            (click)="copyFilter.emit(filter)"
                            matTooltip="Filter kopieren">
                <i class="mdi mdi-content-copy dark"></i>
            </button>
            <button mat-icon-button (click)="removeFilter.emit(filter)" matTooltip="Block entfernen"
                    *ngIf="filterLength > 1">
                <i class="mdi mdi-delete dark"></i>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column" *ngIf="formGroup" class="w-100">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100">
                <searchable-study-field-dropdown class="form-field"
                                                 *ngIf="!loading"
                                                 [control]="formGroup.get('studyFieldControl')"
                                                 [placeholder]="'Studienfeld'"
                                                 [multiple]="true"
                                                 [required]="false">
                </searchable-study-field-dropdown>

                <searchable-study-area-dropdown class="form-field"
                                                *ngIf="!loading"
                                                [control]="formGroup.get('studyAreaControl')"
                                                [placeholder]="'Studienbereich'"
                                                [multiple]="true"
                                                [required]="false">
                </searchable-study-area-dropdown>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100">
                <searchable-subject-dropdown class="form-field"
                                             *ngIf="!loading"
                                             [control]="formGroup.get('subjectControl')"
                                             [placeholder]="'Studiengänge'"
                                             [multiple]="true"
                                             [required]="false">
                </searchable-subject-dropdown>

                <searchable-subject-group-dropdown class="form-field"
                                                   *ngIf="!loading"
                                                   [control]="formGroup.get('subjectGroupsControl')"
                                                   [placeholder]="'Studiengruppen'"
                                                   [multiple]="true"
                                                   [required]="false">
                </searchable-subject-group-dropdown>
            </div>

            <div *ngIf="!isSpecialTargeting" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100">
                <mat-form-field class="form-field" appearance="fill">
                    <mat-label>Mindestnote</mat-label>
                    <input matInput type="number" [(ngModel)]="filter.minGrade">
                </mat-form-field>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div class="cv-field">
                        <mat-form-field class="form-field cv-field" appearance="fill">
                            <mat-label>CV Wortindex Suche</mat-label>
                            <input matInput type="text" [ngModel]="filter.cvFullTextSearchString"
                                   (ngModelChange)="filter.cvFullTextSearchString=$event;matchingWordList=null">
                            <mat-hint>*Mehrere Schlüsselwörter sind mit einem Komma zu trennen</mat-hint>
                            <button mat-icon-button (click)="getMatchingWords()" matSuffix>
                                <i *ngIf="!matchingWordList" class="mdi mdi-eye mdi-24px"></i>
                                <i *ngIf="matchingWordList" class="mdi mdi-eye-off mdi-24px"></i>
                            </button>
                        </mat-form-field>
                        <div class="hint" *ngIf="matchingWordsLoading || matchingWordList">
                            <loading-spinner [loadingBool]="matchingWordsLoading">
                                <ul>
                                    <li *ngFor="let item of matchingWordList">{{item}}</li>
                                </ul>
                                <div *ngIf="matchingWordList.length == 0" class="text-center layout-padding">Kein Wort
                                    gefunden
                                </div>
                            </loading-spinner>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngIf="!isSpecialTargeting" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100">
                <searchable-academic-title-dropdown
                        class="form-field"
                        *ngIf="!loading"
                        [control]="formGroup.get('degreeTypeControl')"
                        [placeholder]="'Abschlüsse'"
                        [multiple]="true"
                        [required]="false">
                </searchable-academic-title-dropdown>
                <div class="form-field">
                    <!--                pseudoelement-->
                </div>
            </div>
        </div>
    </div>
</div>
