import {LabelData} from "./label-data"
import {AdminPositionMatchingConfigData} from "./admin-position-matching-config-data"
import {LocationOptionsData} from "./location-options-data"
import {CompanyPricingMode} from "./company-pricing-mode"
import {EmploymentPositionState} from "./employment-position-state"

export class AdminPositionMatchingConfigDisplayData {
    configData = new AdminPositionMatchingConfigData();
    currentBookingId: number | null = null;
    komboId: string | null = null;
    komboIntregrationId: string | null = null;
    location = new LocationOptionsData();
    numPrimeFilters: number = null;
    numSpecialTargetingFilters: number = null;
    position = new LabelData();
    pricingMode: CompanyPricingMode | null = null;
    state: EmploymentPositionState = null;
}