export class TalentDeactivationSelectionsData {
    accountTechIssues: boolean = null;
    admin: boolean = null;
    dataPrivacyConcerns: boolean = null;
    jobFoundOnOtherPlatform: boolean = null;
    jobFoundOnThisPlatform: boolean = null;
    noCoachReceived: boolean = null;
    noResponseOnApplications: boolean = null;
    notCurrentlyJobSearching: boolean = null;
    notEnoughJobs: boolean = null;
    notGraduatedYet: boolean = null;
    profileMismatch: boolean = null;
    qualityDissatisfaction: boolean = null;
    slowLoading: boolean = null;
    tooManyNotifications: boolean = null;
    twoYears: boolean = null;
    unhappyWithCareerCoaching: boolean = null;
    unintuitiveNavigation: boolean = null;
    unlikedDesign: boolean = null;
}