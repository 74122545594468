<div style="background-color: white; margin: 20px; padding: 10px" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="button-bar" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                <mat-form-field>
                    <mat-label>Zeitspanne</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input [formControl]="start" matStartDate placeholder="ab">
                        <input [formControl]="end" matEndDate placeholder="bis">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <loading-button matTooltip="gefilterte Webinare Laden"
                                buttonClass="mat-primary"
                                (clickEvent)="loadWebinars()">
                    <i class="mdi mdi-refresh main-action-icon filter-color"></i>
                </loading-button>
            </div>



            <div fxLayout="row" fxLayoutAlign="start center">
                <loading-button [loadingBool]="loading"
                                (clickEvent)="downloadWebinarRegistrationExcel()"
                                matTooltip="Excel herunterladen"
                                buttonClass="mat-primary">
                    <i class="mdi mdi-file-excel-outline main-action-icon"></i>
                </loading-button>

                <loading-button (click)="openCreateOrEditWebinarDialog()"
                                matTooltip="Neues Webinar planen"
                                buttonClass="mat-primary">
                    <i class="mdi mdi-plus main-action-icon"></i>
                </loading-button>
            </div>

        </div>

        <table mat-table [dataSource]="webinars">

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Titel</th>
                <td mat-cell *matCellDef="let webinar">{{webinar.title}}</td>
            </ng-container>

            <ng-container matColumnDef="dateTime">
                <th mat-header-cell *matHeaderCellDef>Datum und Uhrzeit</th>
                <td mat-cell *matCellDef="let webinar">{{webinar.dateTime | date: "dd.MM.yyyy hh:mm"}}</td>
            </ng-container>

            <ng-container matColumnDef="speakers">
                <th mat-header-cell *matHeaderCellDef>Speaker</th>
                <td mat-cell *matCellDef="let webinar">
                    <span *ngFor="let speaker of webinar.speakers; let index = index">
                        {{speaker.name}}
                        <span *ngIf="index + 1 < webinar.speakers.length">, </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let webinar">
                    <div class="width-100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1rem">
                        <mat-slide-toggle [(ngModel)]="webinar.active"  matTooltip="Aktiv-Status des Webinars (nur aktive Webinare werden angezeigt)"
                                          (change)="toggleActiveStatusOfWebinar(webinar.id, webinar.active)">

                        </mat-slide-toggle>

                        <button mat-icon-button matTooltip="Webinar bearbeiten" (click)="openCreateOrEditWebinarDialog(webinar)">
                            <i class="mdi mdi-pencil"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let webinar; columns: displayedColumns"></tr>

        </table>
    </div>
</div>