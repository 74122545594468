import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'homeOffice'
})
export class HomeOfficePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
      switch (value) {
          case 'HomeOfficeAvailable':
              return 'HomeOffice möglich';
          case 'OnlyOffice':
              return 'Ausschließlich im Büro';
          case 'OnlyRemote':
              return 'Ausschließlich im HomeOffice';
      }
  }

}
