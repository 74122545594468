import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {FederalStateData} from "../../../generated/data";
import {LocationResource} from "../../../generated/resources";

@Component({
  selector: 'app-searchable-federal-sate-dropdown',
  templateUrl: './searchable-federal-sate-dropdown.component.html',
  styleUrls: ['./searchable-federal-sate-dropdown.component.css']
})
export class SearchableFederalSateDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  filterControl: FormControl = new FormControl()

  federalStates: FederalStateData[];

  constructor(
    private locationResource: LocationResource
  ) {
  }

  ngOnInit() {
    this.loadFederalStates();
  }

  loadFederalStates() {
    this.locationResource.getFederalStatesByQuery({q: '', page: 0, pageSize: 16}).then(res => {
        this.federalStates = res.content;
      }
    )
  }

  selectAll() {
    this.control.setValue(this.federalStates.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}
