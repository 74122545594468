<div class="extra-padding" fxLayout="column" fxLayoutAlign="end start">

    <h1>Topjob-Slots</h1>
    <loading-spinner [loadingBool]="loadingTopjobs"  class="width-100">
        <div class="topjob-wrapper">
            <div *ngFor="let slot of topjobSlots; let i = index"  fxLayout="row" fxLayoutAlign="start center">
               <div [ngClass]="{'no-position': !slot.position}" class="slot-info width-100" fxLayout="row" fxLayoutAlign="space-between center">
                   <div fxLayout="row" fxLayoutAlign="start start">
                       <!--p>{{slot.id}}</p-->
                       <p><strong>Slot {{i + 1}}</strong></p>
                       <p *ngIf="slot.position">{{slot.position.name}}</p>
                       <p *ngIf="!slot.position">Keine Stelle zugewiesen</p>
                   </div>

                   <button [disabled]="!slot.position" [matTooltip]="'Stelle vom Slot entfernen'"
                           (click)="unsetTopjobSlot(slot.id)"
                           mat-icon-button><i class="mdi mdi-close-box"></i></button>
               </div>
                <button mat-button class="delete-button" (click)="deleteTopjobSlot(slot.id)">
                    <i class="mdi mdi-delete"></i> Slot löschen
                </button>
            </div>
        </div>

    </loading-spinner>

    <loading-button matTooltip="Neuen Topjob-Slot anlegen"
                    buttonClass="mat-primary"
                    (clickEvent)="createNewTobjobSlot()">
        <i class="mdi mdi-plus main-action-icon"></i>
        Neuen Topjob-Slot anlegen
    </loading-button>


    <h2>Stellenübersicht</h2>

    <loading-spinner [loadingBool]="loadingPositions" class="width-100">
        <div fxLayout="column" fxLayoutAlign="start start" class="width-100">
            <div *ngFor="let position of companyEmploymentPositions" class="position-info width-100" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="60" fxLayout="row" fxLayoutAlign="start center">
                    <p >{{position.title}}</p>
                </div>

                <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center" >
                    <i [matTooltip]="'Stelle gelöscht'" class="mdi mdi-cancel" *ngIf="position.deleted"></i>
                </div>

                <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center" >
                    <i *ngIf="position.active" [matTooltip]="'Stelle aktiv'" style="color: #207104" class="mdi mdi-eye" ></i>
                    <i *ngIf="!position.active" [matTooltip]="'Stelle inaktiv'" style="color: #710404" class="mdi mdi-eye-off"></i>
                </div>

                <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center" >
                    <button [matTooltip]="'Stellendetails öffnen'"
                            (click)="openPositionDetailsDialog(position.id)"
                            mat-icon-button><i class="mdi mdi-information"></i></button>
                </div>

                <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center" >
                    <button [matTooltip]="'Stelle einem Topjob-Slot zuweisen'"
                            [matMenuTriggerFor]="slotMenu"
                            [disabled]="position.deleted"
                            mat-icon-button>
                        <i *ngIf="filterForTopjob(position.id)" class="mdi mdi-star"></i>
                        <i *ngIf="!filterForTopjob(position.id)" class="mdi mdi-star-outline"></i>
                    </button>
                    <mat-menu #slotMenu="matMenu">
                        <button mat-menu-item *ngFor="let option of topjobSlots; let i = index" (click)="assignPositionToTopjobSlot(option.id, position.id)">Slot {{i + 1}}</button>
                    </mat-menu>
                </div>

            </div>
        </div>

    </loading-spinner>
</div>
