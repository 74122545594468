<dialog-header dialogHeadline="Manuell Stellenvorschlagen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content">
        <div class="width-100">
            <div fxLayout="row" fxLayoutAlign="end" class="w-100" fxLayoutGap="15px" style="padding: 20px 20px 0 20px">
                <button mat-icon-button (click)="opentTalentFilterSideSheet()" matTooltip="Vorschau">
                    <i class="mdi mdi-eye mdi-24px"></i>
                </button>
            </div>
            <talent-filter-group class="width-100" *ngIf="filtersLoaded" [filters]="filters"></talent-filter-group>
        </div>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
    </div>
</div>
