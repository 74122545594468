import {Resource, ResourceHandler} from "@ngx-resource/core";
import {Injectable} from "@angular/core";
import {environment} from "../environments/environment";

@Injectable()
export abstract class ApiResource extends Resource {

    // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
    constructor(
        requestHandler: ResourceHandler,
    ) {
        super(requestHandler)
    }

    $getUrl(options) {
        switch (options.endpoint) {
            case "com.uniwunder.talentagent.main":
                return environment.apiUrlMain;
            case "com.uniwunder.talentagent.reporting":
                return environment.apiUrlReporting;
            case "com.uniwunder.talentagent.matching":
                return environment.apiUrlMatching;
            case "com.uniwunder.unisign":
                return environment.uniSignUrl;
        }
    }

    $setRequestOptionsQuery(options) {
        for (let key in options.actionAttributes.query) {
            if (options.actionAttributes.query.hasOwnProperty(key)) {
                if (options.actionAttributes.query[key] === null || options.actionAttributes.query[key] === undefined) {
                    delete options.actionAttributes.query[key];
                }
            }
        }
        options.actionAttributes.query = this.convertDatesDeep(options.actionAttributes.query);
        return super.$setRequestOptionsQuery(options);
    }

    $setRequestOptionsBody(options) {
        options.actionAttributes.body = this.convertDatesDeep(options.actionAttributes.body);
        return super.$setRequestOptionsBody(options);
    }

    /**
     * Traverses an object deeply and converts all dates to string of the format
     * YYYY-MM-DDTHH:mm:ss while keeping the local time zone.
     * This function is not Y10k compliant.
     */
    convertDatesDeep(obj: any) {
        if (obj instanceof Date) {
            const minutesOffset = obj.getTimezoneOffset();
            const millisecondsOffset = minutesOffset * 60 * 1000;
            const local = new Date(obj.getTime() - millisecondsOffset);
            return local.toISOString().substr(0, 19);
        } else if (obj instanceof Array) {
            return obj.map(x => this.convertDatesDeep(x))
        } else if (obj instanceof Object) {
            const convertedObject = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    convertedObject[key] = this.convertDatesDeep(obj[key]);
                }
            }
            return convertedObject;
        } else {
            return obj
        }
    }

}
