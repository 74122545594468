import {LabelData} from "./label-data"
import {PlatformApplicationData} from "./platform-application-data"
import {BookingState} from "./booking-state"

export class CompanyBookingData {
    applications: PlatformApplicationData[] = null;
    closedAt: (Date | null) = null;
    company = new LabelData();
    createdAt: (Date | null) = null;
    discount: number = null;
    id: number = null;
    invoiceId: number | null = null;
    originalPrice: number = null;
    positions: LabelData[] = null;
    product: LabelData | null = null;
    serviceDescription: string = null;
    sevDeskInvoiceId: string | null = null;
    size: number = null;
    state: BookingState = null;
    totalPriceBeforeTax: number = null;
}