<div *ngIf="formInitialized">

    <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <loading-button buttonClass="mat-warn"
                            [disabledCondition]="activeSplitCount <= 1 && split.active"
                            [loadingBool]="loadingDeleteSplit"
                            (clickEvent)="deleteContentSplit()">
                <i class="mdi mdi-delete main-action-icon"></i>
            </loading-button>
            <div *ngIf="activeSplitCount <= 1 && split.active">
                Achtung, mindestens ein Split muss aktiv sein!
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="width-100">
            <mat-slide-toggle
                    [disabled]="activeSplitCount <= 1 && split.active && !splitFormGroup?.controls?.title?.value"
                    [(ngModel)]="split.active" (ngModelChange)="changeSplitState($event)">Split Aktiv
            </mat-slide-toggle>

            <button mat-icon-button color="primary" matTooltip="Stelle ansehen" (click)="routeToPreview()">
                <i class="mdi mdi-eye main-action-icon"></i>
            </button>

            <loading-button buttonClass="mat-primary"
                            [loadingBool]="updateContentSplitLoading"
                            (clickEvent)="updateContentSplit()">
                <i class="mdi mdi-content-save main-action-icon"></i>
            </loading-button>
        </div>
    </div>
    <div id="information-wrapper" fxLayout="column" class="width-100 height-100 layout-padding-24-32">
        <form [formGroup]="splitFormGroup" fxLayout="column" fxLayoutGap="10px" class="width-100">
            <div fxLayout="column" class="width-100">
                <h3>Content</h3>
                <mat-form-field style="width: 80%">
                    <input matInput placeholder="Title"
                           formControlName="title">
                </mat-form-field>

                <mat-form-field style="width: 80%">
                    <input matInput placeholder="intern Title"
                           formControlName="internTitle">
                </mat-form-field>

                <div class="angular-editor-label m-top20">
                    Stellenbeschreibung
                </div>

                <angular-editor
                        class="m-bottom20"
                        [placeholder]="'Text Eingeben...'"
                        formControlName="positionDescription"
                        [config]="editorConfig"
                ></angular-editor>

                <div class="angular-editor-label m-top20">
                    Anforderungen
                </div>

                <angular-editor
                        class="m-bottom20"
                        [placeholder]="'Text Eingeben...'"
                        formControlName="positionRequirements"
                        [config]="editorConfig"
                ></angular-editor>

                <div class="angular-editor-label m-top20">
                    Unternehmensbeschreibung
                </div>

                <angular-editor
                        class="m-bottom20"
                        [placeholder]="'Text Eingeben...'"
                        formControlName="companyDescription"
                        [config]="editorConfig"
                ></angular-editor>

                <mat-form-field>
                    <input matInput maxlength="60" formControlName="usp1"
                           placeholder="USP 1">
                </mat-form-field>
                <mat-form-field>
                    <input matInput maxlength="60" formControlName="usp2"
                           placeholder="USP 2">
                </mat-form-field>
                <mat-form-field>
                    <input matInput maxlength="60" formControlName="usp3"
                           placeholder="USP 3">
                </mat-form-field>

                <div class="angular-editor-label m-top20">
                    Benefits Einleitungstext
                </div>

                <angular-editor
                        class="m-bottom20"
                        [placeholder]="'Text Eingeben...'"
                        formControlName="benefitsIntro"
                        [config]="editorConfig"
                ></angular-editor>


                <div class="angular-editor-label m-top20">
                    Benefits
                </div>

                <app-searchable-benefit-dropdown ngDefaultControl
                        placeholder="Wähle ein oder meherere Benefits aus"
                        [formControl]="splitFormGroup?.controls?.benefits"></app-searchable-benefit-dropdown>

                <div class="angular-editor-label m-top20">
                    Sonstiges
                </div>

                <angular-editor
                        class="m-bottom20"
                        [placeholder]="'Text Eingeben...'"
                        formControlName="miscellaneousDescription"
                        [config]="editorConfig"
                ></angular-editor>


                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" class="width-100">

                    <mat-form-field>
                        <input matInput placeholder="Videocode" formControlName="positionVideoLink">
                    </mat-form-field>
                </div>

            </div>
        </form>

        <div class="angular-editor-label m-top20">
            Bilder
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" >
            <div fxLayout="row" fxLayoutAlign="start start">
                <button mat-flat-button
                        color="accent"
                        (click)="fileInput.click()">
                    <span>Auswählen</span>
                </button>
                <input
                    #fileInput type="file"
                    accept=".jpg, .jpeg, .png"
                    [formControl]="positionImage"
                    style="display: none"
                    (change)="onFileInput($event)"/>

                <div class="m-left20">
                                        <span *ngIf="positionImage.value"
                                              class="font-size-14">{{selectedSplitImage?.name}}</span>
                    <span *ngIf="!positionImage.value"
                          class="font-size-14">Keine Datei ausgewählt</span>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" class="m-top30" fxLayoutGap="20px">

                <div fxLayout="row" fxLayoutAlign="start end" *ngFor="let picture of split.positionCustomerImages">
                    <div class="position-image"
                         [ngStyle]="{'background-image': 'url(' + enviromentVar.apiUrlMain + '/public/contentSplits/' + split.id + '/picture/' + picture.id + ' )'}">
                        <button mat-icon-button matTooltip="Bild löschen"
                                (click)="deleteFile(picture.id, split.id)" class="delete-file-button"><i
                                class="mdi mdi-close-box"></i></button>
                    </div>

                    <mat-form-field class="imageComment">
                        <mat-label>Bildbeschreibung für Bildergalerie</mat-label>
                        <textarea matInput
                                  maxlength="80"
                                  cdkTextareaAutosize
                                  cdkAutosizeMinRows="5"
                                  [(ngModel)]="picture.name">
                                        </textarea>
                        <mat-hint align="end">{{picture.name?.length? picture.name?.length : 0}} / 80
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
