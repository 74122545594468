import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import {Router} from "@angular/router";
import {ComponentType} from "@angular/cdk/overlay";
import {PortalInjector} from "@angular/cdk/portal";
import {SideSheetRef} from "./sideSheetRef";

export type SidesheetMode = "Small" | "Medium" | "Large" | "Huge" | "Fullscreen"

@Injectable({
  providedIn: 'root'
})

export class SideSheetService {

  dialogCount = 0;
  dialogId = '';

  constructor(
      private resolver: ComponentFactoryResolver,
      private appRef: ApplicationRef,
      private injector: Injector,
      private router: Router
  ) {
  }


  openedSideSheets: ComponentRef<any>[] = [];



  openOverlay(component: ComponentType<any>, mode: SidesheetMode = 'Medium'): ComponentRef<any> {

    const smallSheetWidth = '600px';
    const normalSheetWidth= '800px';
    const largeSheetWidth = '1000px';
    const hugeSheetWidth = '1200px';

    let dialogWidth = normalSheetWidth;
    if(mode == 'Small'){
      dialogWidth = smallSheetWidth;
    }else if(mode == 'Large'){
      dialogWidth = largeSheetWidth;
    }else if(mode == 'Huge'){
      dialogWidth = hugeSheetWidth;
    }else if(mode == 'Fullscreen'){
      dialogWidth = '100%';
    }

    const sidesheetRef = new SideSheetRef();
    const injectionTokens = new WeakMap<any, any>([
      [SideSheetRef, sidesheetRef]
    ]);

    const injector = new PortalInjector(this.injector, injectionTokens)

    const comp = this.resolver
        .resolveComponentFactory(component)
        .create(injector);

    this.appRef.attachView(comp.hostView);

    if (this.openedSideSheets.length < 1) {
      this.createBackdrop()
    } else {

      /** create backdrop element on dialog if another dialog is openend */
      let lastVisibleDialogId = 'dialog-wrapper' + (this.openedSideSheets.length - 1);

      let overlayElement = document.createElement('div')
      overlayElement.id = 'dialog-overlay' + (this.openedSideSheets.length - 1)
      overlayElement.classList.add('dialog-overlay');
      document.getElementById(lastVisibleDialogId).appendChild(overlayElement)
    }

    this.createDialogWrapper(dialogWidth)

    const dom = (comp.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

    document.getElementById(this.dialogId).appendChild(dom)

    this.router.events.subscribe(value => {
      this.openedSideSheets = []
    });

    sidesheetRef.sheetClosed.subscribe(() => {
      this.back()
    })

    sidesheetRef.dialogClosed.subscribe(() => {
      this.closeAllSheets()
    })

    this.openedSideSheets.push(comp)
    return comp
  }


  //Closes current top layer
  back() {
    if (this.openedSideSheets.length < 2) {
      let mainWrapperElement = document.getElementById("mainwrapper")
      let backdropElement = document.getElementById("dialog-overlay-wrapper")
      mainWrapperElement.removeChild(backdropElement)
      this.openedSideSheets[0].destroy()
      this.openedSideSheets = []
    } else {
      let nextVisibleDialogId = 'dialog-wrapper' + (this.openedSideSheets.length - 2);
      let dialogId = 'dialog-wrapper' + (this.openedSideSheets.length - 1);

      let overlayId = 'dialog-overlay' + (this.openedSideSheets.length - 2)
      let overlayElement = document.getElementById(overlayId)
      document.getElementById(nextVisibleDialogId).removeChild(overlayElement)

      document.getElementById(dialogId).classList.remove('slideIn');
      document.getElementById(dialogId).classList.add('slideOut');

      this.destroyLastSheet(this.openedSideSheets[this.openedSideSheets.length - 1]);
      let dialogWrapperElement = document.getElementById(dialogId)
      let backdropElement = document.getElementById("dialog-overlay-wrapper")
      backdropElement.removeChild(dialogWrapperElement)
    }
  }

  closeAllSheets() {
    let sheets = this.openedSideSheets.slice().reverse()
    for (let comp of sheets) {
      comp.instance.sideSheetRef.sheetClosed.next()
    }
  }

  //Closes All Layer from the sheet
  destroyLastSheet(comp: any) {
    this.openedSideSheets.splice(this.openedSideSheets.indexOf(comp), 1)
    this.appRef.detachView(comp.hostView);
    comp.destroy()
  }

  createBackdrop() {
    let matSideNavContainer = document.getElementById("side-nav-container")
    let mainWrapperElement = document.getElementById("mainwrapper")
    let backdropElement = document.createElement('div')
    backdropElement.id = "dialog-overlay-wrapper";
    backdropElement.classList.add('fadeIn');
    mainWrapperElement.appendChild(backdropElement)
    matSideNavContainer.classList.add('blur-content')
  }

  createDialogWrapper(dialogWidth: string) {
    this.dialogId = 'dialog-wrapper' + this.openedSideSheets.length;
    let backdropElement = document.getElementById("dialog-overlay-wrapper")
    let dialogElement = document.createElement('div')
    dialogElement.id = this.dialogId;
    dialogElement.classList.add('dialog-wrapper', 'slideIn', 'mat-elevation-z6');
    dialogElement.style.width = dialogWidth;
    backdropElement.appendChild(dialogElement)
  }

  getLastSheet(): any {
    return this.openedSideSheets[this.openedSideSheets.length - 1].instance
  }

}
