export const environment = {
  production: true,
  apiHost: '$$API_HOST$$',
  apiUrlMain: 'https://$$API_HOST$$/main',
  apiUrlReporting: 'https://$$API_HOST$$/reporting',
  apiUrlMatching: 'https://$$API_HOST$$/matching',
  cronosFrontendUrl: '',
    talentFrontendUrl: 'https://$$TALENTFE_HOST$$',
  publicFrontendUrl: 'https://$$PUBLICFE_HOST$$',
  adminFrontendUrl: 'https://$$ADMINFE_HOST$$',
  frontendUnisignClientId: 'talentagent_admin',
  appLoginRedirectUri: 'https://$$ADMINFE_HOST$$/login',
  uniSignUrl: 'https://$$UNISIGN_HOST$$',
  uniSignHost: '$$UNISIGN_HOST$$',
};
