import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'information-property',
  templateUrl: './information-property.component.html',
  styleUrls: ['./information-property.component.scss']
})
export class InformationPropertyComponent implements OnInit {

  @Input() name: String
  @Input() text: String

  constructor() { }

  ngOnInit(): void {
  }

}
