<h1 mat-dialog-title>Upload Exceldokument</h1>
<div class="dialog-main-content" fxLayoutAlign="space-between center">
  <input type="file"
         accept=".xlsx, .xls"
         (change)="set($event)" [formControl]="file" id="file" class="inputfile">
  <label for="file">
    <span>{{getFileName()}}</span>
  </label>
  <div fxLayout="row" fxLayoutGap="15px">
    <i class="mdi mdi-information dark" (clickEvent)="downloadReadMe()"
       [matTooltip]="'ReadMe anzeigen'"></i>
    <i class="mdi mdi-file-download-outline dark" (clickEvent)="downloadExcel()"
       [matTooltip]="'Beispielexcel runterladen'"></i>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px"
     class="primary-text-color button-bar">
  <button mat-flat-button class="cancel-button" (click)="close()">Abbrechen</button>
  <loading-button buttonClass="mat-primary"
                  [loadingBool]="saving"
                  (clickEvent)="save()"
                  [disabledCondition]="!isValidFile()">Upload starten
  </loading-button>
</div>
