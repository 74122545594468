<div style="background-color: white; margin: 20px; padding: 10px" class="mat-elevation-z6">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field class="name-filter">
        <input matInput [formControl]="companySearchControl" placeholder="Schnellsuche">
      </mat-form-field>

      <div fxLayout="row">
        <loading-button style="margin-bottom: 20px;"
                        buttonClass="mat-primary"
                        (clickEvent)="createNewCompanyDialog()"
                        [loadingBool]="openingCreateDialog"
                        matTooltip="Neues Unternehmen anlegen" class="primary">
          <i class="mdi mdi-plus main-action-icon"></i>
        </loading-button>

        <table-download-button
          [loadingBool]="downloadingExcel"
          color="primary"
          [buttonClass]="'main-action-icon'"
          excelName="Firmen.xlsx"
          [matTable]="table">
        </table-download-button>
      </div>
    </div>

    <div class="standard-container">
      <loading-spinner [loadingBool]="loading">
        <table *ngIf="!loading && customerCompanyData" mat-table [dataSource]="customerCompanyData" #dataTable>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let company">{{company.id}}</td>
          </ng-container>

          <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Anlagedatum</th>
            <td mat-cell *matCellDef="let company">{{company.creationDate | date: 'dd.MM.yy'}}</td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let company" (click)="copyToClipboard(company.name)"
                class="copy">{{company.name}}</td>
          </ng-container>

          <ng-container matColumnDef="keyAccount">
            <th mat-header-cell *matHeaderCellDef>Key Accounter</th>
            <td mat-cell *matCellDef="let company">{{company.keyAccounter?.adminName}}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
            <td mat-cell *matCellDef="let company">
              <div class="table-button" fxLayout="row" fxLayoutAlign="end center">
                <button mat-button mat-icon-button color="primary"
                        matTooltip="Firmendetails"
                        (click)="navigateToCompany(company.id)">
                  <i class="mdi mdi-information dark"></i>
                </button>

                <button mat-button mat-icon-button color="primary"
                        [disabled]="false"
                        matTooltip="Firma löschen"
                        (click)="deleteCompany(company)">
                  <i class="mdi mdi-delete dark"></i>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
      </loading-spinner>
    </div>
  </div>
</div>

