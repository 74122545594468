import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {PublicSubjectResource, StudyInfoResource} from "../../../generated/resources";
import {debounceTime} from 'rxjs/internal/operators';

@Component({
  selector: 'searchable-academic-title-dropdown',
  templateUrl: './searchable-academic-title-dropdown.component.html',
  styleUrls: ['./searchable-academic-title-dropdown.component.css']
})
export class SearchableAcademicTitleDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  titles: string[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
    private publicSubjectResource: PublicSubjectResource,
  ) {
  }

  ngOnInit() {

    this.loadAcademicTitles();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          this.loadAcademicTitles()
        });
  }

  selectAll() {
    this.control.setValue(this.titles)
  }

  unselectAll() {
    this.control.setValue([])
  }

  loadAcademicTitles() {
    this.publicSubjectResource.getAcademicTitles().then(titles => {
      this.titles = titles;
    })
  }
}
