import {LabelData} from "./label-data"
import {ManualMatchRating} from "./manual-match-rating"
import {ResponseState} from "./response-state"

export class AdminTalentData {
    coachRequested: boolean | null = null;
    creationDate: (Date | null) = null;
    currentCoach: LabelData | null = null;
    cvDate: (Date | null) = null;
    cvFileName: string | null = null;
    emailAddress: string | null = null;
    firstName: string = null;
    hasDossier: boolean = null;
    hasNote: boolean = null;
    id: number = null;
    lastName: string | null = null;
    lastSubjectTitle: string | null = null;
    manualMatchRating: ManualMatchRating | null = null;
    matchingPositionCount: number | null = null;
    matchingPrimePositionCount: number | null = null;
    needsCoachingCriteriaUpdate: boolean | null = null;
    onboardingComplete: boolean = null;
    phoneNumber: string | null = null;
    qualityScore: number | null = null;
    responseState: ResponseState = null;
    studyEndDate: (Date | null) = null;
    utmCampaign: string | null = null;
    utmContent: string | null = null;
    utmMedium: string | null = null;
    utmSource: string | null = null;
    wasChecked: boolean = null;
}