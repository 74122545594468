<dialog-header dialogHeadline="Neues Webinar planen"></dialog-header>

<div class="dialog-main-content layout-padding width-100" fxLayout="column" fxLayoutAlign="start end">
   <form [formGroup]="webinarForm" class="width-100">
       <div fxLayout="column" class="width-100">
           <mat-form-field>
               <input matInput placeholder="Webinar Titel" formControlName="title">
           </mat-form-field>

           <mat-form-field>
               <textarea matInput rows="2" placeholder="Kurzbeschreibung des Webinars" formControlName="shortDescription">
               </textarea>
           </mat-form-field>

           <mat-form-field>
               <textarea matInput rows="5" placeholder="Beschreibung des Webinars" formControlName="description">
               </textarea>
           </mat-form-field>

           <mat-form-field>
               <input matInput placeholder="Link zum Betreten des Webinars" formControlName="joinLink">
           </mat-form-field>

           <mat-form-field>
               <mat-label>Speakers</mat-label>
               <mat-select multiple formControlName="speakers">
                   <mat-option *ngFor="let admin of admins" [value]="admin">
                       {{admin.name}}
                   </mat-option>
               </mat-select>
           </mat-form-field>

           <mat-form-field style="width: fit-content">
               <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="dateTime">
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <ngx-mat-datetime-picker #picker [defaultTime]="[12,0,0]"></ngx-mat-datetime-picker>
           </mat-form-field>

<!--               <div class="picture-wrapper" (click)="uploadImage()">-->
<!--                   <img [src]="webinarImage">-->

<!--                   <div class="overlay" fxLayout="row" fxLayoutAlign="center center"><div>Auswählen</div></div>-->
<!--               </div>-->

           <div fxLayout="column">
               <div class="picture-wrapper" fxLayout="column" fxLayoutAlign="start start" (click)="uploadImage()">
                   <img *ngIf="webinarPreviewImage != null" [src]="webinarPreviewImage"
                        style="width: 400px; height: 200px">
                   <div *ngIf="webinarPreviewImage == null" style="width: 400px; height: 200px; border: 1px solid" fxLayout="column" fxLayoutAlign="center center">
                       <div class="color-grey" style="font-weight: 600">Titel Bild hochladen</div>
                   </div>
                   <div class="overlay" fxLayout="row" fxLayoutAlign="center center"><div>Auswählen</div></div>

               </div>
           </div>




       </div>
   </form>

    <loading-button (clickEvent)="save()" [raised]="true" buttonClass="mat-primary">
        Speichern
    </loading-button>
</div>