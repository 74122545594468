<div class="controlling-board-info">
 Dieses Board stellt einen Ist-Zustand der Bewerbungen aus einer Kalenderwoche da. Man kann also sehen, wie viele Bewerbungen insgesamt erstellt wurden und wo diese gerade im Prozess liegen.
</div>
<div class="filter" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="filter-wrapper">
        <mat-form-field>
            <input matInput [matDatepicker]="pickerFrom" placeholder="Bewerbung erstellt von"
                   [(ngModel)]="startDate" name="dateFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="pickerTo" placeholder="Bewerbung erstellt bis"
                   [(ngModel)]="endDate" name="dateTo">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Stellenherkunft</mat-label>
            <mat-select [(ngModel)]="selectedOrigins" multiple="true">
                <mat-option *ngFor="let origin of origins" [value]="origin">{{origin}}</mat-option>
            </mat-select>
        </mat-form-field>

        <loading-button
                matTooltip="Aktualisieren"
                buttonClass="mat-primary"
                [loadingBool]="loading"
                (clickEvent)="load()">
            <i class="mdi mdi-refresh dark filter-color"></i>
        </loading-button>
    </div>

    <table-download-button color="primary"
                           excelName="Bewerbungs-Kohorten.xlsx"
                           [buttonClass]="'main-action-icon'"
                           [matTable]="applicationProcessingTable">
    </table-download-button>
</div>


<table mat-table [dataSource]="data" class="max-table" #applicationProcessingTable>

    <ng-container matColumnDef="calendarWeek">
        <th mat-header-cell *matHeaderCellDef>KW</th>
        <td mat-cell *matCellDef="let element">
            KW {{element.calendarWeek}}
        </td>
    </ng-container>

    <ng-container matColumnDef="total">
        <th  mat-header-cell *matHeaderCellDef>Bewerbungen abgeschickt</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Sent','Granted', 'Failed','Interesting','AppProcessEnter','Hired'],element)"> {{element.total }}</td>
    </ng-container>

    <ng-container matColumnDef="pending">
        <th  mat-header-cell *matHeaderCellDef>Unbearbeitet</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Sent'],element)"> {{element.pending }}</td>
    </ng-container>

    <ng-container matColumnDef="granted">
        <th  mat-header-cell *matHeaderCellDef>Freigegeben</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Granted'],element)"> {{element.granted }}</td>
    </ng-container>

    <ng-container matColumnDef="interesting">
        <th  mat-header-cell *matHeaderCellDef>Interessant</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Interesting'],element)"> {{element.interesting}}</td>
    </ng-container>
    <ng-container matColumnDef="inAppProcess">
        <th  mat-header-cell *matHeaderCellDef>Im Bewerbungsprozess</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['AppProcessEnter'],element)"> {{element.inAppProcess }} </td>
    </ng-container>
    <ng-container matColumnDef="contractSent">
        <th  mat-header-cell *matHeaderCellDef>Vertrag verschickt</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['ContractSent'],element)"> {{element.contractSent }} </td>
    </ng-container>
    <ng-container matColumnDef="hired">
        <th  mat-header-cell *matHeaderCellDef>Angestellt</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Hired'],element)"> {{element.hired }}</td>
    </ng-container>
    <ng-container matColumnDef="denied">
        <th  mat-header-cell *matHeaderCellDef>Abgelehnt (UN)</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Failed'],element)"> {{element.failed }}</td>
    </ng-container>

    <ng-container matColumnDef="withdrawn">
        <th  mat-header-cell *matHeaderCellDef>Zurückgezogen</th>
        <td mat-cell *matCellDef="let element" (click)="openApplicationSheet(['Withdrawn'],element)"> {{element.withdrawn}}</td>
    </ng-container>

    <ng-container matColumnDef="remainingPotential">
        <th  mat-header-cell *matHeaderCellDef>Verbleibendes Potential</th>
        <td mat-cell *matCellDef="let element"
            (click)="openApplicationSheet(['Sent','Granted', 'Interesting','AppProcessEnter', 'ContractSent'],element)"
        > {{element.interesting + element.inAppProcess + element.pending + element.granted + element.contractSent}}
        ({{(element.interesting + element.inAppProcess + element.pending + element.granted + element.contractSent) / element.total * 100
            | number:'1.0-2'}} %)

        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
