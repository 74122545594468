import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {BenefitData} from '../../../generated/data';
import {BenefitResource} from '../../../generated/resources';
import {debounceTime} from 'rxjs/internal/operators';

@Component({
  selector: 'app-searchable-benefit-dropdown',
  templateUrl: './searchable-benefit-dropdown.component.html',
  styleUrls: ['./searchable-benefit-dropdown.component.scss']
})
export class SearchableBenefitDropdownComponent implements OnInit {

  @Input() formControl: AbstractControl;
  @Input() placeholder: string = '';

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  searchTerm: FormControl = new FormControl();
  benefits: BenefitData[] = [];
  displayedBenefits: BenefitData[] = [];

  constructor(
    private  benefitResource: BenefitResource
  ) {
  }

  ngOnInit() {
      this.loadBenefits()

      this.formControl.valueChanges.subscribe(value => {
          this.modelChange.emit(value);
      });

    this.searchTerm.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
          this.displayedBenefits = this.benefits.filter(element => element.name.toLowerCase().includes(value.toLowerCase()));
      })
  }

  loadBenefits(): Promise<any> {
    return this.benefitResource.getBenefits({searchString: this.searchTerm.value, page: 0}).then(res => {
      this.benefits = res.content;
      this.displayedBenefits = this.benefits
      }
    );
  }
}
