import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {LabelData} from "../../../generated/data";
import {StudyInfoResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-study-field-dropdown',
  templateUrl: './searchable-study-field-dropdown.component.html',
  styleUrls: ['./searchable-study-field-dropdown.component.css']
})
export class SearchableStudyFieldDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = false
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  @ViewChild('scrollwrapper') scrollwrapper: ElementRef;

  studyFields: LabelData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
    public studyInfoResource: StudyInfoResource
  ) {
  }

  ngOnInit() {
    this.loadStudyFields();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(() => { this.loadStudyFields(); });
  }

  loadStudyFields() {
    this.studyInfoResource.getStudyFields({
      q: this.filterControl.value,
      pageSize: 100,
      additionalStudyFieldIds: this.control.value
    }).then(studyFields => {
      this.studyFields = studyFields.content;
    })
  }

  selectAll() {
    this.control.setValue(this.studyFields.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}
