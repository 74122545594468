import {Directive, ElementRef, HostListener} from '@angular/core';
import {SideSheetService} from "./side-sheet.service";

@Directive({
  selector: '[side-sheet-close-button]'
})
export class SideSheetCloseButtonDirective {

  @HostListener('click', ['$event.target'])
  closeAll() {
    this.dialogService.getLastSheet().sideSheetRef.dialogClosed.next()
  }

  constructor(private el: ElementRef, private dialogService: SideSheetService) {
  }

}
