import {Injectable} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "./auth.service";
import {ErrorSnackbarComponent} from "../error-snackbar/error-snackbar.component";


export class RequestError {
   statusCode: number;
    url: string
}

@Injectable({
  providedIn: 'root'
})
export class RequestErrorInterceptorService implements HttpInterceptor {

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, { panelClass: "error-snackbar",duration: 10000, data: {statusCode: err.status, url: req.url}})
      }
      return throwError(err)
      }
    ));
  }


}
