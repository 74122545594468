import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'talentPositionRelationState'
})
export class TalentPositionRelationStatePipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 'New':
        return 'Neu'
      case 'Seen':
        return 'Gesehen'
      case 'Clicked':
          return 'Geklickt'
      case 'Withdrawn':
        return 'Von uns zurückgezogen'
      case 'Bookmarked':
        return 'In Favoriten'
      case 'CTAClicked':
        return 'CTA geklickt'
      case 'Rejected':
        return 'Abgelehnt'
      case 'Accepted':
        return 'Beworben'
    }
  }
}
