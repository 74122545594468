import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.css']
})
export class LoadingButtonComponent implements OnInit {
  @Input() displayCondition: boolean;
  @Input() tooltip: string;
  @Input() color: string;
  @Input() buttonClass: string;
  @Input() disabledCondition: boolean;
  @Input() loadingBool: boolean;
  @Input() raised: boolean = false;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (this.raised == null || typeof this.raised == 'undefined') {
      this.raised = true
    }
  }

  clicked() {
    this.clickEvent.emit();
  }
}
