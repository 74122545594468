<div class="filter" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <searchable-subject-dropdown
            style="width: 450px"
            [control]="subjectControl"
            [placeholder]="'Subject'">
    </searchable-subject-dropdown>

    <searchable-profession-dropdown
            style="width: 450px"
            [control]="professionControl"
            [placeholder]="'Profession'">
    </searchable-profession-dropdown>

    <button mat-icon-button color="primary" matTooltip="Reload"
            [disabled]="subjectControl.value == null"
            (click)="findAllPaths()">
        <mat-icon>refresh</mat-icon>
    </button>
</div>

<loading-spinner [loadingBool]="loading">
    <div class="content-wrapper">

        <table *ngIf="subjectProfessionPaths" mat-table [dataSource]="subjectProfessionPaths" class="w-100">
            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef>Subject</th>
                <td mat-cell *matCellDef="let path">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openSubjectDetailsSheet(path.subject)">
                        {{path.subject.name | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="subjectGroup">
                <th mat-header-cell *matHeaderCellDef>SubjectGroup</th>
                <td mat-cell *matCellDef="let path">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openSubjectGroupDetailsSheet(path.matchedSubjectGroup)">
                        {{path.matchedSubjectGroup.name | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="subjectProfession">
                <th mat-header-cell *matHeaderCellDef>SubjectProfession</th>
                <td mat-cell *matCellDef="let path">
                    <span class="clickable"
                          matTooltip="Mappings ansehen"
                          (click)="openProfessionDetailsSheet(path.matchedSubjectProfession)">
                        {{path.matchedSubjectProfession.name | sliceStringLength: 100 }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="matchingLevel">
                <th mat-header-cell *matHeaderCellDef>MatchingLevel</th>
                <td mat-cell *matCellDef="let path">
                    <span>{{path.matchingLevel}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="matchingProperty">
                <th mat-header-cell *matHeaderCellDef>MatchingProperty</th>
                <td mat-cell *matCellDef="let path">
                    <span>{{getMatchingProperty(path)}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="profession">
                <th mat-header-cell *matHeaderCellDef>Profession</th>
                <td mat-cell *matCellDef="let path">
                    <span>{{path.profession.title}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</loading-spinner>

