import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AdminSalesProposalData, SalesProposalData, SalesProductData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminSalesProposalController
 */

@Injectable()
@ResourceParams({})
export class AdminSalesProposalResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/salesProposals/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createSalesProposal: IResourceMethodObservableStrict<SalesProposalData, {withRecruiterCreation: boolean}, null, SalesProposalData> | undefined
    createSalesProposal(requestBody: SalesProposalData, requestParams: {withRecruiterCreation: boolean}): Promise<SalesProposalData> {
        if (!this._createSalesProposal) throw new Error("resource has not been properly initialized")
        return this._createSalesProposal(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/salesProducts',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProducts: IResourceMethodObservableStrict<null, null, null, SalesProductData[]> | undefined
    getProducts(): Promise<SalesProductData[]> {
        if (!this._getProducts) throw new Error("resource has not been properly initialized")
        return this._getProducts(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/salesProposals',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSalesProposals: IResourceMethodObservableStrict<null, {companyId?: number, states?: ('Open' | 'Approved' | 'Denied' | 'Withdrawn')[], pageSize: number, pageNum: number}, null, PagedData<AdminSalesProposalData>> | undefined
    getSalesProposals(requestParams: {companyId?: number, states?: ('Open' | 'Approved' | 'Denied' | 'Withdrawn')[], pageSize: number, pageNum: number}): Promise<PagedData<AdminSalesProposalData>> {
        if (!this._getSalesProposals) throw new Error("resource has not been properly initialized")
        return this._getSalesProposals(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/salesProposals/{id}/withdraw',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _withdrawSalesProposal: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<('Open' | 'Approved' | 'Denied' | 'Withdrawn')>> | undefined
    withdrawSalesProposal(id: number): Promise<('Open' | 'Approved' | 'Denied' | 'Withdrawn')> {
        if (!this._withdrawSalesProposal) throw new Error("resource has not been properly initialized")
        return this._withdrawSalesProposal(null, null, {id: id}).toPromise().then((result) => result.value)
    }

}