import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {LabelData, ProfessionData, SubjectData} from "../../../generated/data";
import {AdminProfessionResource, ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {UtilityService} from "../../../utils/utility.service";
import {PageEvent} from "@angular/material/paginator";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";
import {ProfessionTypeDetailsSheetComponent} from "../profession-type-details-sheet/profession-type-details-sheet.component";
import {ProfessionSubGroupDetailsSheetComponent} from "../profession-sub-group-details-sheet/profession-sub-group-details-sheet.component";
import {ProfessionGroupDetailsSheetComponent} from "../profession-group-details-sheet/profession-group-details-sheet.component";
import {ProfessionMainGroupDetailsSheetComponent} from "../profession-main-group-details-sheet/profession-main-group-details-sheet.component";
import {ProfessionAreaDetailsSheetComponent} from "../profession-area-details-sheet/profession-area-details-sheet.component";

@Component({
    selector: 'professions',
    templateUrl: './professions.component.html',
    styleUrls: ['./professions.component.scss']
})
export class ProfessionsComponent implements OnInit {

    public withoutSgMappingControl: FormControl = new FormControl(false);
    public professionFilterControl: FormControl = new FormControl();
    public professionTypeFilterControl: FormControl = new FormControl(null);
    public professionSubGroupFilterControl: FormControl = new FormControl(null);
    public professionGroupFilterControl: FormControl = new FormControl(null);
    public professionMainGroupFilterControl: FormControl = new FormControl(null);
    public professionAreaFilterControl: FormControl = new FormControl(null);
    public professions: ProfessionData[] = [];
    public loading: boolean = true;

    public pageIndex: number = 0;
    public pageSize: number = 15;
    public totalSize: number = 0;
    public pageSizeOptions: number[] = [15, 30, 60, 100]

    public displayedColumns = [
        'professionTitle',
        'professionTypeTitle',
        'professionSubGroupTitle',
        'professionGroupTitle',
        'professionMainGroupTitle',
        'professionAreaTitle'
    ]

    constructor(
        private adminProfessionResource: AdminProfessionResource,
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private dialogService: SideSheetService,
        private utilityService: UtilityService
    ) {
    }

    ngOnInit(): void {
        this.loadProfessions();
    }

    loadProfessions(): void {
        this.loading = true;
        this.adminProfessionResource.getFilteredProfessions({
            withoutSgMapping: this.withoutSgMappingControl.value,
            query: this.professionFilterControl.value,
            professionTypeIds: this.professionTypeFilterControl.value,
            professionSubGroupIds: this.professionSubGroupFilterControl.value,
            professionGroupIds: this.professionGroupFilterControl.value,
            professionMainGroupIds: this.professionMainGroupFilterControl.value,
            professionAreaIds: this.professionAreaFilterControl.value,
            page: this.pageIndex,
            pageSize: this.pageSize,
        }).then(professions => {
            this.professions = professions.content;
            this.totalSize = professions.totalElements;
            this.loading = false;
        })
    }

    handlePage(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadProfessions();
    }

    openProfessionDetailsSheet(profession: ProfessionData) {
        let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
        cref.instance.professionId = profession.id;
        cref.instance.professionTitle = profession.title;
    }

    openProfessionTypeDetailsSheet(profession: ProfessionData) {
        let cref = this.dialogService.openOverlay(ProfessionTypeDetailsSheetComponent)
        cref.instance.professionTypeId = profession.typeId;
        cref.instance.professionTypeTitle = profession.typeTitle;
    }

    openProfessionSubGroupDetailsSheet(profession: ProfessionData) {
        let cref = this.dialogService.openOverlay(ProfessionSubGroupDetailsSheetComponent)
        cref.instance.professionSubGroupId = profession.subGroupId;
        cref.instance.professionSubGroupTitle = profession.subGroupTitle;
    }

    openProfessionGroupDetailsSheet(profession: ProfessionData) {
        let cref = this.dialogService.openOverlay(ProfessionGroupDetailsSheetComponent)
        cref.instance.professionGroupId = profession.groupId;
        cref.instance.professionGroupTitle = profession.groupTitle;
    }

    openProfessionMainGroupDetailsSheet(profession: ProfessionData) {
        let cref = this.dialogService.openOverlay(ProfessionMainGroupDetailsSheetComponent)
        cref.instance.professionMainGroupId = profession.mainGroupId;
        cref.instance.professionMainGroupTitle = profession.mainGroupTitle;
    }

    openProfessionAreaDetailsSheet(profession: ProfessionData) {
        let cref = this.dialogService.openOverlay(ProfessionAreaDetailsSheetComponent)
        cref.instance.professionAreaId = profession.areaId
        cref.instance.professionAreaTitle = profession.areaTitle;
    }
}
