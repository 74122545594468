<dialog-header dialogHeadline="Dokumente nachfordern"></dialog-header>

<div fxLayout="column" fxLayoutAlign="space-between" style="height: calc(100% - 50px)">
    <div fxLayout="column" fxLayoutGap="20px" class="layout-padding-24-32">
        <div *ngIf="talentName" class="attachments-info m-bottom10">Teile {{talentName}} mit, welche Unterlagen sie noch für
            eine bessere Einschätzung der Bewerbung benötigen.
        </div>

        <form [formGroup]="attachmentTypes">
            <div *ngFor="let inputControl of (attachmentTypes.controls | keyvalue); index as i" fxLayout="row"
                 fxLayoutAlign="start center" class="uploaded-attachements">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="width-100">
                    <mat-form-field appearance="fill" class="width-100">
                        <mat-label>Dokumenttyp</mat-label>
                        <input matInput type="text" [formControlName]="inputControl.key" [id]="inputControl.key"
                               placeholder="z.B. Abschlusszeugnis">
                    </mat-form-field>

                    <button mat-button matTooltip="Anhang entfernen" (click)="removeAttachment(inputControl.key)">
                        <i class="mdi mdi-close"></i>
                    </button>
                </div>
            </div>
            <button mat-flat-button (click)="addAttachment()"
                    fxLayout="row" fxLayoutAlign="start center"
                    style="height: 38px">
                <i class="mdi mdi-plus m-right10"></i>
                <span>Anhang hinzufügen</span>
            </button>
        </form>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" class="w-100" fxLayoutGap="20px">
        <button mat-button mat-dialog-close>Abbrechen</button>

        <loading-button [loadingBool]="requestAdditionalDocumentsLoading" color="primary"
                        (clickEvent)="requestAdditionalApplicationDocuments()"
                        buttonClass="primary mat-raised-button">
            Nachfordern
        </loading-button>
    </div>
</div>

