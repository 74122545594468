import {Component, Input, OnInit} from '@angular/core';
import {AdminEmploymentPositionQuestionResource, AdminEmploymentPositionResource} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {PositionQuestionData} from "../../../generated/data";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {CreatePositionQuestionSideSheetComponent} from "./create-position-question-side-sheet/create-position-question-side-sheet.component";

@Component({
  selector: 'position-questions',
  templateUrl: './position-questions.component.html',
  styleUrls: ['./position-questions.component.scss']
})
export class PositionQuestionsComponent implements OnInit {

  @Input() positionId: number
  @Input() positionTitle: string

  loadingQuestions: boolean = true

  questions: PositionQuestionData[] = []

  constructor(
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private adminEmploymentPositionQuestionResource: AdminEmploymentPositionQuestionResource,
      private dialogService: SideSheetService

  ) { }

  ngOnInit(): void {
    this.getEmploymentPositionQuestions()
  }

  activeQuestionsCount: number = 0

  getEmploymentPositionQuestions() {
    this.loadingQuestions = true
    this.adminEmploymentPositionQuestionResource.getPositionQuestions(this.positionId).then(result => {
      this.questions = result
      this.loadingQuestions = false
      this.updateActiveQuestionCount()

    })
  }

  toggleQuestionState(e: MatSlideToggleChange, question: PositionQuestionData) {
    this.adminEmploymentPositionQuestionResource.changePositionQuestionState(question.id, {newState: !e.checked})
    question.enabled = e.checked
    this.updateActiveQuestionCount()
  }

  updateActiveQuestionCount() {
    this.activeQuestionsCount = this.questions.filter(q => q.enabled)?.length
  }

  openCreateQuestionDialog() {
    let comp = this.dialogService.openOverlay(CreatePositionQuestionSideSheetComponent)
    comp.instance.positionId = this.positionId;
    comp.instance.positionTitle = this.positionTitle;

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      if (res) {
        this.questions.push(res)
        this.updateActiveQuestionCount()
      }
      subscription.unsubscribe();
    })
  }
}
