<h1 mat-dialog-title> Admin hinzufügen </h1>

<div class="dialog-main-content">
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start center">

    <mat-form-field style="width: 100%">
      <input formControlName="firstName" matInput placeholder="Vorname">
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <input formControlName="lastName" matInput placeholder="Nachname">
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <input formControlName="emailAddress" matInput placeholder="Emailadresse">
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <input formControlName="phoneNumber" matInput placeholder="Telefonnummer">
    </mat-form-field>

  </form>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px"
     class="primary-text-color button-bar">
  <button mat-flat-button class="cancel-button">Abbrechen</button>
  <loading-button [loadingBool]="loading"
                  [disabledCondition]="form.invalid || form.controls['phoneNumber'].value == '' || form.controls['phoneNumber'].value == null"
                  [raised]="false"
                  buttonClass="mat-primary"
                  (clickEvent)="saveAdmin()">
    Speichern
  </loading-button>
</div>
