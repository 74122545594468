import {MatchingState} from "./matching-state"

export class ConsultantTalentMinimalData {
    emailAddress: string = null;
    firstName: string = null;
    id: number = null;
    lastName: string | null = null;
    matchingState: MatchingState = null;
    phoneNumber: string | null = null;
    profilePictureId: number | null = null;
}