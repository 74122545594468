import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AdminEmploymentPositionResource,
    AdminProfessionResource,
    AdminSubjectResource,
    AdminTalentResource,
    ProfessionFieldPreferenceResource,
    ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ProfessionFieldPreferenceData, ProfessionMatchingScore, ProfessionRelationData} from "../../../generated/data";
import {UtilityService} from "../../../utils/utility.service";

@Component({
    selector: 'profession-subject-group-relation-board',
    templateUrl: './profession-subject-group-relation-board.component.html',
    styleUrls: ['./profession-subject-group-relation-board.component.scss']
})
export class ProfessionSubjectGroupRelationBoardComponent implements OnInit {

    @Input() subjectId: number
    @Input() professionId: number
    @Output() p5RelationCountEvent = new EventEmitter<number>()

    saving: boolean = false

    subjectGroupsAndProfessionMatchingType: ProfessionRelationData[];

    talentProfessionFieldPreferences: ProfessionFieldPreferenceData[]
    professionGroupPreferencesDisplayedColumns = ['groupTitle', 'preferenceState']

    originalProfessionMatchingTypes = []
    professionMatchingTypesDisplayedColumns = ['subjectGroup', 'professionMatchingType']

    loaded: boolean = false

    resultsFound: boolean = true
    errorMessage: string = ""

    constructor(
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private professionResource: AdminProfessionResource,
        private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
        private employmentPositionResource: AdminEmploymentPositionResource,
        private subjectResource: AdminSubjectResource,
        private talentResource: AdminTalentResource,
        public utilityService: UtilityService,
        private snackBar: MatSnackBar,
        private cd: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.loadProfessionSubjectGroupRelations()
    }

    loadProfessionSubjectGroupRelations() {
        this.professionMatchingOptimizingResource.getProfessionSubjectGroupRelations({
            subjectId: this.subjectId,
            professionId: this.professionId
        }).then(professionRelations => {
            this.p5RelationCountEvent.emit(professionRelations.filter(i => i.matchingScore == <ProfessionMatchingScore>"P5").length)

            if (professionRelations.length == 0) {
                this.professionResource.getProfession(this.professionId).then(profession=>{
                    this.subjectResource.getSubject(this.subjectId).then(subject=>{
                        this.resultsFound = false
                        this.errorMessage = `Keine relations zwischen ${profession.title} und ${subject.title} gefunden`
                    })
                })
            }

            this.subjectGroupsAndProfessionMatchingType = professionRelations
            this.originalProfessionMatchingTypes = professionRelations.map((relation) => {
                return relation.matchingScore
            })
            this.loaded = true
        })
    }


    updateMatchingType(element: ProfessionRelationData, event) {
        element.matchingScore = event
    }

    saveProfessionSubjectGroupMapping() {
        this.saving = true
        this.originalProfessionMatchingTypes.map((OriginalMatchingType, index)=>{
            if (OriginalMatchingType != this.subjectGroupsAndProfessionMatchingType[index].matchingScore) {
                this.professionMatchingOptimizingResource.updateProfessionSubjectGroupRelationMatchingType(
                    {value: this.subjectGroupsAndProfessionMatchingType[index].matchingScore},
                    {
                        subjectGroupId: this.subjectGroupsAndProfessionMatchingType[index].subjectGroup.id,
                        professionId: this.professionId
                    }
                )
            }
        })

        this.saving = false
        this.utilityService.showSnackBar("Änderungen erfolgreich gespeichert")
    }

}
