<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="title">AGB - (File ganz oben ist für Unternehmen als AGB hinterlegt)</div>
        <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <input type="file"
                       accept=".pdf"
                       (change)="set($event)" [formControl]="file" id="file" class="inputfile">
                <label for="file">
                    <span>{{getFileName()}}</span>
                </label>

                <loading-button
                        *ngIf="file.valid"
                        [raised]="true"
                        matTooltip="Hochladen"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        (clickEvent)="uploadTermsAndConditions()">
                    Hochladen
                </loading-button>
            </div>

                <loading-button
                        matTooltip="Aktualisieren"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        (clickEvent)="getUploadedTermsAndConditions()">
                    <i class="mdi mdi-refresh dark filter-color"></i>
                </loading-button>
        </div>
    </div>

    <loading-spinner [loadingBool]="loading">
        <table mat-table [dataSource]="datasource" class="max-table" #table>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Filename</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fileName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Hochgeladen am</th>
                <td mat-cell *matCellDef="let element">
                    {{element.uploadDate | date:'dd.MM.yyyy HH:mm'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>Hochgeladen von</th>
                <td mat-cell *matCellDef="let element">
                    {{element.uploadedBy?.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell  *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="download(element.storedFileId)" color="primary">
                        <i class="mdi mdi-download"></i>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="element-row">
            </tr>
        </table>

        <div *ngIf="datasource?.length == 0" class="nothing-found">Es wurde nichts gefunden</div>
    </loading-spinner>
</div>
