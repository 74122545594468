<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">

        <table mat-table [dataSource]="data" class="w-100">
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Anmeldung</th>
                <td mat-cell *matCellDef="let row">{{row.talentCreationDate | date: 'dd.MM.yy'}}</td>
            </ng-container>

            <ng-container matColumnDef="isPrimeMatch">
                <th mat-header-cell *matHeaderCellDef>Prime-Match</th>
                <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                    <img *ngIf="element.isPrimeFilterMatch" matTooltip="Das Talent matched auf den Prime-Filter" height="20" src="../../../assets/images/star.png">
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Talent</th>
                <td mat-cell *matCellDef="let row">{{row.talentName}}</td>
            </ng-container>

            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef>Studiengang</th>
                <td mat-cell *matCellDef="let row">{{row.mostRecentStudies.subjectName}}</td>
            </ng-container>

            <ng-container matColumnDef="recommended">
                <th mat-header-cell *matHeaderCellDef>Empfohlen am</th>
                <td mat-cell *matCellDef="let row">{{row.recommendationOccurred | date: 'dd.MM.yy'}}</td>
            </ng-container>

            <ng-container matColumnDef="bookmarked">
                <th mat-header-cell *matHeaderCellDef style="text-align: center">In Merkliste</th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <i *ngIf="row.isBookmarked" class="mdi mdi-check green"></i>
                    <i *ngIf="!row.isBookmarked" class="mdi mdi-close red"></i>
                </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let row">{{row.relationState | talentPositionRelationState}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell class="right" *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Detailiertes Profil anzeigen"
                                (click)="openTalentProfile(row.talentId)">
                            <i class="mdi mdi-account dark"></i>
                        </button>
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Match anzeigen"
                                [disabled]="!row.matchingReport"
                                (click)="openMatchingReport(row.matchingReport)">
                            <i class="mdi mdi-table-search mdi-24px"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</loading-spinner>
