import {Component, OnInit, ViewChild} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {AdminAdminResource, AdminControllingResource} from "../../generated/resources";
import {
    AdminRoleDisplayData,
    EmploymentPositionApplicationStatisticsData,
    EmploymentPositionOrigin,
    EmploymentPositionState
} from "../../generated/data";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {ApplicationFailedReasonsSheetComponent} from "../application-failed-reasons-sheet/application-failed-reasons-sheet.component";
import {MatTable} from "@angular/material/table";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-customer-applications',
    templateUrl: './customer-applications.component.html',
    styleUrls: ['./customer-applications.component.scss']
})
export class CustomerApplicationsComponent implements OnInit {

    @ViewChild('dataTable') public table: MatTable<any>;

    public loading: boolean = false
    public pageIndex: number = 0;
    public pageSize: number = 15;
    public pageSizeOptions: number[] = [15, 30, 60, 100];
    public totalSize: number = 0;

    public positionStates: EmploymentPositionState[] = ["Active", "Inactive", "Draft"]
    public positionOrigins: EmploymentPositionOrigin[] = ["Uniwunder", "Absolventa", "Campusjaeger", "Pluss"]

    public displayedColumns = [
        'title',
        'numPotential',
        'numProposals',
        'numWithdrawn',
        'numSeen',
        'numRejected',
        'numClicked',
        'numBookmarked',
        'numCurrentlyBookmarked',
        'numApplications',
        'numApplicationsWithoutCv',
        'numDenied',
        'numFailed',
        'numInteresting',
        'numAppProcessEnter',
        'numContractSent',
        'numHired'
    ];

    public searchCompanyIdControl = new FormControl(null)
    public searchQueryControl = new FormControl(null)
    public originControl = new FormControl("Uniwunder")
    public statesControl = new FormControl("Active")
    public dateFromControl = new FormControl(null)
    public dateToControl = new FormControl(null)
    public keyAccounterControl = new FormControl(null)

    public statisticsData: EmploymentPositionApplicationStatisticsData[]
    public keyAccounterData: AdminRoleDisplayData[] = [];

    public downloadingExcel: boolean = false

    constructor(
        private adminControllingResource: AdminControllingResource,
        private adminAdminResource: AdminAdminResource,
        private authService: AuthService,
        private dialogService: SideSheetService
    ) {
    }

    ngOnInit(): void {
        this.loadKeyAccounter();
    }

    loadKeyAccounter() {
        this.adminAdminResource.getAllKeyAccounter().then(keyAccounterData => {
            this.keyAccounterData = keyAccounterData;
            let adminId = this.authService.getAccountInfo().id
            if(this.keyAccounterData.map(k => k.adminId).includes(adminId)) {
                this.keyAccounterControl.setValue(this.keyAccounterData.find(k => k.adminId == adminId).adminRoleId)
                this.keyAccounterControl.updateValueAndValidity()
            }
            this.loadData();
        });
    }

    loadData() {
        this.loading = true
        this.adminControllingResource.getEmploymentPositionApplicationStatistics({
            page: this.pageIndex,
            pageSize: this.pageSize,
            query: this.searchQueryControl.value,
            origin: this.originControl.value,
            companyId: this.searchCompanyIdControl.value,
            states: this.statesControl.value,
            dateTo: this.dateToControl.value,
            dateFrom: this.dateFromControl.value,
            keyAccounterId: this.keyAccounterControl.value
        }).then(data => {
            this.statisticsData = data.content
            this.totalSize = data.totalElements
            this.loading = false
        })
    }

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadData();
    }

    openApplicationFailedReasons(positionId: Number) {
        let comp = this.dialogService.openOverlay(ApplicationFailedReasonsSheetComponent, 'Small')
        comp.instance.positionId = positionId
    }

}
