import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {
  AdminPositionFieldOfActivityData,
  AdminPositionMatchingConfigData,
  AdminPositionPerformanceFilterData,
  FieldOfActivityRelationData,
  LabelData,
  PositionProfessionFieldRelationData,
} from "../../../generated/data";
import {FormControl} from "@angular/forms";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {AdminEmploymentPositionResource, AdminMatchingResource} from "../../../generated/resources";
import {AddActivityFieldSheetComponent} from "../add-activity-field-sheet/add-activity-field-sheet.component";
import {debounceTime} from "rxjs/internal/operators";
import {PageEvent} from "@angular/material/paginator";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {
  PositionDetailsDialogComponent
} from "../../../positions/position-details-dialog/position-details-dialog.component";

@Component({
  selector: 'app-position-field-qualification-editor',
  templateUrl: './position-field-qualification-editor.component.html',
  styleUrls: ['./position-field-qualification-editor.component.scss']
})
export class PositionFieldQualificationEditorComponent implements OnInit {


  public pageIndex: number = 0;
  public pageSize: number = 15;
  public totalSize: number = 0;
  loadingPositionIds: number[] = []
  filterData: AdminPositionPerformanceFilterData = new AdminPositionPerformanceFilterData();

  sortCriteriaControl: FormControl = new FormControl('Performance')
  sortTypeControl: FormControl = new FormControl('Desc')

  public pageSizeOptions: number[] = [30, 60, 100, 300];
  private subjectMap: Map<number, BehaviorSubject<AdminPositionFieldOfActivityData>> = new Map()


  public displayedColumns: string[] = [
    'id',
      'score',
      'seen',
      'cta',
    'title',
    'origin',
      'creation',
    'profession',
    'fields',
      'actions'
  ];

  datasource: AdminPositionFieldOfActivityData[] = []
  loading = false;

  public filterControl = new FormControl(null)

  constructor(
      private dialogService: SideSheetService,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private changeDetector: ChangeDetectorRef,
      private adminMatchingResource: AdminMatchingResource
  ) {
  }

  ngOnInit(): void {
    this.load()

    this.filterControl.valueChanges.subscribe(searchQuery => {
      this.filterData.searchTitle = searchQuery;
      this.pageIndex = 0
    });
  }

  load() {
    this.loading = true;

    this.filterData.withInactive = false
    this.filterData.showDeleted = false
    this.filterData.sortCriteria = this.sortCriteriaControl.value
    this.filterData.sortType = this.sortTypeControl.value

    this.adminEmploymentPositionResource.getFilteredEmploymentPositionsForActivityFieldEditor(
        this.filterData, {
          page: this.pageIndex,
          pageSize: this.pageSize,
        }).then(
        positionData => {
          this.datasource = positionData.content
          this.totalSize = positionData.totalElements
          this.loading = false;
        });
  }

  addFieldToPosition(position: AdminPositionFieldOfActivityData) {
    let cref = this.dialogService.openOverlay(AddActivityFieldSheetComponent)
    let sub = cref.instance.sideSheetRef.sheetClosed.subscribe(res => {
      let data: FieldOfActivityRelationData = {id: res.id, qualification: "Excluded", name: res.name}
      position.fieldOfActivityRelations.push(data)
      this.changeDetector.detectChanges()
      this.setNextQualifiedState(position, data)
      sub.unsubscribe()
    })
  }

  updatePositionFieldQualification(data: FieldOfActivityRelationData[], position) {
    this.loadingPositionIds.push(position.id)
    let sendData: AdminPositionMatchingConfigData = {
      profession: <LabelData>{id: position.professionId, name: position.professionTitle},
      positionProfessionFieldRelations: data.map( rel => <PositionProfessionFieldRelationData>{
        field: <LabelData>{name: rel.name, id: rel.id},
        qualification: rel.qualification
          }
      )
    }
    this.adminMatchingResource.updatePositionMatchingConfigAndInitiateMatching(sendData, position.id).then( () => {
          this.loadingPositionIds.splice(this.loadingPositionIds.indexOf(position.id), 1)
        }
    )
  }
  updatePositionMatchingConfigAndInitiateMatching


  setNextQualifiedState(position: AdminPositionFieldOfActivityData, relation: FieldOfActivityRelationData) {

    switch (relation.qualification) {
      case "Normal": relation.qualification = "Fitting"; break;
      case "Fitting": relation.qualification = "Excluded"; break;
      case "Excluded": relation.qualification = "Normal"; break;
    }

    let oldSubject = this.subjectMap.get(position.id)

    if (oldSubject) {
      oldSubject.next(position)
    } else {

      let obser = new BehaviorSubject<AdminPositionFieldOfActivityData>(position)
      this.subjectMap.set(position.id, obser)

      this.subjectMap.get(position.id).asObservable().pipe(debounceTime(2000)).subscribe(position => {
        this.subjectMap.delete(position.id)
        this.updatePositionFieldQualification(position.fieldOfActivityRelations, position)
      })

    }
  }

  openPositionDetailsDialog(positionId, tabIndex: number = 0) {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
    comp.instance.tabIndex = tabIndex
  }

  switchPositionState(event: MatSlideToggleChange) {
    this.filterData.withInactive = event.checked;
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.load();
  }
}