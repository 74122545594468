import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {AdminCompanyInvoiceData, InvoiceData, InvoiceFilterData} from "../generated/data";
import {Router} from "@angular/router";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {PageEvent} from "@angular/material/paginator";
import {PositionDetailsDialogComponent} from "../positions/position-details-dialog/position-details-dialog.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
    BillingsHiringDetailsDialogComponent
} from "../billings/billings-hiring-details-dialog/billings-hiring-details-dialog.component";
import {
    CreateOrUpdateInvoiceSideSheetComponent
} from "./create-or-update-invoice-side-sheet/create-or-update-invoice-side-sheet.component";
import {InvoiceInformationComponent} from "./invoice-information/invoice-information.component";
import {AdminInvoiceResource} from "../generated/resources";
import {FileService} from "../services/file.service";

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

    pageIndex: number = 0;
    pageSize: number = 30;
    totalSize: number = 0;
    pageSizeOptions: number[] = [15, 30, 60, 100];
    expandedElement

    filterData = <InvoiceFilterData>{
        positionId: null,
        companyId: null,
        from: null,
        to: null
    }

    loading: boolean;
    displayedColumns = [
        'expand',
        'company',
        'comment',
        'keyaccounter',
        'invoices',
        'amount',
        // 'superExpandedDetail',
    ];

    companyControl: FormControl = new FormControl(null)
    keyAccounterControl: FormControl = new FormControl(null)
    startDate: FormControl = new FormControl(null)
    endDate: FormControl = new FormControl(null)

    dataSource: AdminCompanyInvoiceData[] = []

    constructor(
        private router: Router,
        private adminInvoiceResource: AdminInvoiceResource,
        private dialogService: SideSheetService,
        private fileService: FileService
    ) {
    }

    ngOnInit(): void {
        let dateNow = new Date().getDate()
        this.startDate.setValue(new Date(new Date().setDate(dateNow - dateNow + 1)));
        this.endDate.setValue(new Date());
        this.loadData();
    }

    editInvoice(invoice: InvoiceData) {
        const comp = this.dialogService.openOverlay(CreateOrUpdateInvoiceSideSheetComponent, 'Small')
        comp.instance.invoice = invoice
    }

    navigateTo(route) {
        window.open(location.origin + route, '_blank')
    }

    getTotalCost(): number {
        return this.dataSource.map(t => this.getInvoicesTotalAmount(t)).reduce((acc, value) => acc + value, 0);
    }

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadData();
    }

    openPositionDetailsDialog(positionId, tabIndex: number = 0) {
        let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = positionId
        comp.instance.tabIndex = tabIndex
    }

    getTotalInvoices(): number {
        return this.dataSource.map(t => t.invoices.length).reduce((acc, value) => acc + value, 0)
    }

    loadData() {
        this.loading = true;
        this.filterData.from = this.startDate.value
        this.filterData.to = this.endDate.value
        this.filterData.companyId = this.companyControl.value
        this.filterData.keyAccounterIds = this.keyAccounterControl.value
        this.adminInvoiceResource.getFilteredInvoicesPerCompany(this.filterData)
            .then((result) => {
                this.dataSource = result;
                this.loading = false;
            })
    }

    openCompanyDetailsDialog(invoice: InvoiceData) {
        this.adminInvoiceResource.getCompanyInvoiceInformation(invoice.id).then(r => {
            let comp = this.dialogService.openOverlay(InvoiceInformationComponent, "Small")
            comp.instance.adminInvoiceData = r
        })
    }

    getInvoicesTotalAmount(data: AdminCompanyInvoiceData): number {
        let totalAmount: number = 0
        data.invoices.forEach(invoice => totalAmount += (invoice.originalAmount*((100-invoice.discount)/100)))
        return totalAmount
    }

    downloadInvoice(invoice: InvoiceData) {
        this.fileService.downloadInvoicePdf(invoice.id)
    }
}

