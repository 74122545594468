<div fxLayout="column" class="dialog">

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3>
      <span *ngIf="careerEventType !== 'Studies'">Werdegangs-Eintrag</span>
      <span *ngIf="careerEventType === 'Studies'">Studium</span>
    </h3>
  </div>
  <div *ngIf="form">
    <div class="input-wrapper" fxLayout="column" *ngIf="careerEventType === 'Studies'">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>
          <div class="show">Hochschule:</div>
          <div class="show">Studiengang:</div>
          <div class="show">Angestrebter Abschluss:</div>
          <div class="show">Startdatum:</div>
          <div class="show">Enddatum:</div>
          <div class="show">Note:</div>
        </div>
        <div>
          <div class="show">{{form?.controls['universityName']?.value}}</div>
          <div class="show">{{form?.controls['subjectName']?.value}}</div>
          <div class="show">{{form?.controls['degreeType']?.value}}</div>
          <div class="show">{{form?.controls['startDate']?.value | date:'dd.MM.yyyy'}}</div>
          <div class="show">{{form?.controls['actualOrExpectedYearOfGraduation']?.value | date:'dd.MM.yyyy'}}</div>
          <div class="show">{{form?.controls['grade']?.value}}</div>
        </div>
      </div>

    </div>

    <div class="input-wrapper" fxLayout="column" *ngIf="careerEventType !== 'Studies'">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>
          <div class="show">Title:</div>
          <div class="show">Typ:</div>
          <div class="show">Beschreibung:</div>
          <div class="show">Startdatum:</div>
          <div class="show">Enddatum:</div>

        </div>
        <div>
          <div class="show">{{form?.controls['title']?.value}}</div>
          <div class="show">{{form?.controls['type']?.value.display}}</div>
          <div class="show">{{form?.controls['description']?.value}}</div>
          <div class="show">{{form?.controls['startDate']?.value | date:'dd.MM.yyyy'}}</div>
          <div class="show">{{form?.controls['endDate']?.value | date:'dd.MM.yyyy'}}</div>

        </div>
      </div>
    </div>
  </div>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="padding: 16px">
  <button mat-button (click)="close()">Abbruch</button>
</div>
