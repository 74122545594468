<dialog-header *ngIf="title" [dialogHeadline]="title"></dialog-header>

<div id="dialog-content-wrapper" *ngIf="message">

    <div class="side-sheet-content layout-padding">
        {{message}}
    </div>

    <div class="dialog-action-bar" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <loading-button
                [raised]="true"
                [color]="'primary'"
                [loadingBool]="confirming"
                (clickEvent)="confirm()">
            {{buttonText}}
        </loading-button>
    </div>
</div>



