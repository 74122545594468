import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SliceStringLengthPipe} from "../pipes/slice-string-length.pipe";

@Component({
  selector: 'app-default-text-if-empty-or-null',
  templateUrl: './default-text-if-empty-or-null.component.html',
  styleUrls: ['./default-text-if-empty-or-null.component.scss']
})
export class DefaultTextIfEmptyOrNullComponent implements OnInit, OnChanges {

  @Input() value: any;
  @Input() defaultText: string;

  newText: string;

  constructor(public sliceStringLengthPipe: SliceStringLengthPipe) {
  }

  ngOnInit() {
    this.newText = this.transform(this.value);
  }

  transform(value) {

    if (value == null) {
      return this.defaultText;
    } else {
      if (Array.isArray(value)) {

        if ((value as Array<string>).length == 0) {
          return this.defaultText
        } else {
          return value.join(', ')
        }
      }

      if (value instanceof String || typeof value === "string") {
        if (value.length == 0) {
          return this.defaultText
        }
      }

      return value
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.newText = this.transform(changes["value"].currentValue);
  }
}
