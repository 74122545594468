<loading-spinner [loadingBool]="!loadedData || tabsLoaded < 2"><br>
    <div *ngIf="loadedData" fxLayout="column">
        <div class="m-left10">
            <small><b>Talent: </b><span>{{talentData.firstName + " " + talentData.lastName + " (" + talentId + ")"}}
                <button mat-button mat-icon-button color="primary"
                        matTooltip="Talent Details"
                        (click)="openTalentProfile()">
                <i class="mdi mdi-account dark"></i>
                </button>
            </span></small><br>
            <small><b>Subject: </b><span>{{subjectData.title + " (" + subjectData.id + ")"}}
                <button mat-button mat-icon-button color="primary"
                        matTooltip="Studienfach Details"
                        (click)="openSubjectDialog()">
                <i class="mdi mdi-information dark"></i>
                </button>
                </span></small><br>
            <small><b>Stelle: </b><span>{{positionData.internTitle + " (" + positionData.id + ")"}}
                <button mat-button mat-icon-button color="primary"
                        matTooltip="Stellendetails"
                        (click)="openPositionDialog()">
                <i class="mdi mdi-information dark"></i>
                </button>
            </span></small><br>
            <small><b>Profession: </b><span>{{positionData.professionTitle + " (" + professionId + ")"}}
                <button mat-button mat-icon-button color="primary"
                        matTooltip="Professionsdetails"
                        (click)="openProfessionDialog()">
                <i class="mdi mdi-information dark"></i>
                </button>
            </span></small><br>
            <h4>Zusammenfassung</h4>
            <small>
                <span [ngClass]="{'green': p5RelationCount > 0, 'red': p5RelationCount == 0}">{{p5RelationCount}} </span>
                <span>{{p5RelationCount == 1? "P5 Beziehung":"P5 Beziehungen"}}</span>
            </small><br>
            <small>
                <span [ngClass]="{'green': minCityDistance <= maxRadiusAroundCityForGreenMatch, 'red': minCityDistance > maxRadiusAroundCityForGreenMatch}">{{minCityDistance}}km</span>
                Geringste Distanz</small><br>
            <small>
                <span [ngClass]="{'red': matchingFieldPreferences.length == 0, 'green': matchingFieldPreferences.length > 0}">{{matchingFieldPreferences.length}} </span>
                <span>{{matchingFieldPreferences.length == 1? "Übereinstimmendes Tätigkeitsfeld":"Übereinstimmende Tätigkeitsfelder"}}</span>
            </small><br>
        </div>
        <mat-tab-group>
            <mat-tab label="Kompetenzmodell">
                <div class="m-left10">
                    <div *ngIf="relationsExist">
                        <profession-subject-group-relation-board [professionId]="this.professionId" [subjectId]="this.subjectData.id" (p5RelationCountEvent)="p5RelationCount = $event"></profession-subject-group-relation-board>
                    </div>
                    <div *ngIf="!relationsExist">
                        <br>
                        <span style="padding-left: 10px">{{errorMessage}}</span>
                    </div>
                </div>
                </mat-tab>
                <mat-tab label="Standort">
                    <div class="m-left10">
                        <small>
                            <h3>Talent Städte: </h3>
                            <div *ngIf="talentData.locationPreference.searchMode == 'Worldwide'" class="green">
                                Weltweit
                            </div>
                            <div *ngIf="talentData.locationPreference.searchMode == 'Nationwide'" class="green">
                                Deutschlandweit
                            </div>
                            <div *ngIf="talentData.locationPreference.searchMode == 'SpecificCities'">
                                <div *ngFor="let city of talentData.locationPreference.specificCities">
                                    {{city.name}}
                                </div>
                            </div>
                        </small>
                        <small>
                            <h3>Stelle Städte: </h3>
                            <div *ngIf="positionData.locations.mode == 'Worldwide' " class="green">
                                Weltweit
                            </div>
                            <div *ngIf="positionData.locations.mode == 'Nationwide'" class="green">
                                Deutschlandweit
                            </div>
                            <div *ngIf="positionData.locations.mode == 'SpecificCities'">
                                <div *ngIf="positionCityDistances.length == 0">
                                    <div *ngFor="let city of positionCities">
                                        {{city.name}}
                                    </div>
                                </div>
                                <div *ngFor="let cityDistance of positionCityDistances">
                                    <b>{{cityDistance.positionCity.name}}</b> (- {{cityDistance.talentCity.name}}):
                                    <span [ngClass]="{'green': cityDistance.distance <= maxRadiusAroundCityForGreenMatch, 'red': cityDistance.distance > maxRadiusAroundCityForGreenMatch}">
                                        {{cityDistance.distance}}km
                                    </span>
                                </div>
                            </div>
                        </small><br>
                    </div>
            </mat-tab>
            <mat-tab label="Tätigkeitsfelder">
                <div class="m-left10">
                    <br>
                    <div fxLayout="row">
                        <h3>
                            <span [ngClass]="{'green': matchingFieldPreferences.length != 0, 'red': matchingFieldPreferences.length == 0}">{{matchingFieldPreferences.length}}</span>
                            <span>{{matchingFieldPreferences.length == 1? " Übereinstimmendes Tätigkeitsfeld":" Übereinstimmende Tätigkeitsfelder"}}</span>
                        </h3>
                    </div>
                    <div fxLayout="row">
                        <small class="smallOval" style="background-color: yellowgreen;"></small><small style="margin-top: 15px">Normal</small>
                        <small class="smallOval" style="background-color: green;"></small><small style="margin-top: 15px">Interessant</small>
                    </div>
                    <div fxLayout="row" fxLayoutGap="40%" style="margin-top: 20px">
                        <div fxLayout="column" style="padding-left: 55px">
                            <h2>Talent</h2>
                        </div>
                        <div fxLayout="column" style="padding-left: 120px">
                            <h2>Stelle</h2>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="40%">
                        <div fxLayout="column"><br>
                            <div *ngFor="let field of matchingFieldPreferences">
                                <div class="oval" [ngClass]="{'yellowgreenBG': field.preferenceScore == 'Normal', 'greenBG': field.preferenceScore == 'Interesting'}">
                                    <small>{{field.fieldName}}</small>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column"><br>
                            <div *ngFor="let fieldRelation of matchingFieldRelations">
                                <div class="oval" [ngClass]="{'yellowgreenBG': fieldRelation.qualification == 'Normal', 'greenBG': fieldRelation.qualification == 'Fitting'}">
                                    <small>{{fieldRelation.field.name}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <h3>{{talentProfessionFieldPreferences.length == 0 && positionProfessionFieldRelations.length == 0 ? 'Keine restlichen Tätigkeitsfelder':'Restliche Tätigkeitsfelder'}}</h3>
                    </div>
                    <div fxLayout="row" fxLayoutGap="40%">
                        <div fxLayout="column">
                            <div *ngFor="let field of talentProfessionFieldPreferences">
                                <div class="oval" [ngClass]="{'yellowgreenBG': field.preferenceScore == 'Normal', 'greenBG': field.preferenceScore == 'Interesting'}">
                                    <small>{{field.fieldName}}</small>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column">
                            <div *ngFor="let fieldRelation of positionProfessionFieldRelations">
                                <div class="oval" [ngClass]="{'yellowgreenBG': fieldRelation.qualification == 'Normal', 'greenBG': fieldRelation.qualification == 'Fitting', 'm-left200': talentProfessionFieldPreferences.length == 0}">
                                    <small>{{fieldRelation.field.name}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        </div>
</loading-spinner>