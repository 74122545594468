import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    CompanyPricingMode,
    SalesProposalData
} from "../../generated/data";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminBookingResource, AdminSalesProposalResource} from "../../generated/resources";
import {$e} from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-create-sales-proposal-side-sheet',
  templateUrl: './create-sales-proposal-side-sheet.component.html',
  styleUrls: ['./create-sales-proposal-side-sheet.component.scss']
})
export class CreateSalesProposalSideSheetComponent implements OnInit {

    loading: boolean = false
    companyId: number
    showSelectCompany: boolean = false

    validProposal: Boolean = false
    proposal: SalesProposalData

    constructor(
        public sideSheetRef: SideSheetRef,
        private adminSalesProposalResource: AdminSalesProposalResource,
    ) {}



    ngOnInit(): void {

    }

    close(result?: SalesProposalData) {
        this.sideSheetRef.sheetClosed.next(result)
    }

    createSalesProposal() {
        if (this.validProposal) {
            this.loading = true
            this.adminSalesProposalResource.createSalesProposal(this.proposal, {withRecruiterCreation: false}).then(result => {
                this.close(result)
            });
        }

    }

    protected readonly $e = $e;
}
