<mat-form-field class="no-padding no-margin w-100">
  <mat-select #select [placeholder]="placeholder" [formControl]="internalControl" [multiple]="multiple"
              [disabled]="disabled">
    <mat-option>
      <ngx-mat-select-search [formControl]="searchTerm"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="!multiple && nullable" [value]="null">{{nullOptionName}}</mat-option>
    <mat-option *ngFor="let option of options" [value]="option['id']">
      {{ getDisplayProperty(option['id']) }}
    </mat-option>
  </mat-select>
</mat-form-field>
