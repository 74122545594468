import {Component, OnInit} from '@angular/core';
import {AdminAdminResource} from "../generated/resources";
import {AdminData} from "../generated/data";
import {MatDialog} from "@angular/material/dialog";
import {AccountCreateDialogComponent} from "./account-create-dialog/account-create-dialog.component";
import {ConfirmDialogComponent} from "../utils/confirm-dialog/confirm-dialog.component";
import {Router} from "@angular/router";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {AccountEditSideSheetComponent} from "./account-edit-side-sheet/account-edit-side-sheet.component";

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  admins: AdminData[];
  displayedColumns: string[] = ["name", "emailAddress", "actions"];

  addingAdmin: Boolean = false;

  constructor(
    private  adminAdminResource: AdminAdminResource,
    private dialog: MatDialog,
    public router: Router,
    private dialogService: SideSheetService
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.adminAdminResource.getAllAdmins()
      .then(result => this.admins = result)
  }

  openCreateDialog() {
    this.addingAdmin = true;
    let dialogRef = this.dialog.open(AccountCreateDialogComponent, {
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(
      (added) => {
        this.addingAdmin = false;
        if (added) {
          this.loadData();
        }
      }
    )
  }

  openEditState(element: AdminData) {

    let comp = this.dialogService.openOverlay(AccountEditSideSheetComponent, 'Huge')
    comp.instance.adminId = element.id

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      this.loadData()
      subscription.unsubscribe();
    })
  }

  deleteAdmin(adminId: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminAdminResource.deleteAdmin(adminId),
        confirmButtonText: "Entfernen",
        message: "Willst du den Admin wirklich entfernen?",
        title: "Admin entfernen"
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(wasConfirmed => {
      if (wasConfirmed) {
        this.loadData()
      }
    });
  }
}
