import {TalentLocationPreferenceData} from "./talent-location-preference-data"

export class AnonymousProfileData {
    companyMessage: string | null = null;
    emailAddress: string | null = null;
    firstName: string = null;
    id: number = null;
    lastName: string | null = null;
    locationPreference = new TalentLocationPreferenceData();
    phoneNumber: string | null = null;
    qualityScore: number | null = null;
}