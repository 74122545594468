import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfessionMatchingScore} from "../../generated/data";

@Component({
  selector: 'profession-matching-type-selection',
  templateUrl: './profession-matching-type-selection.component.html',
  styleUrls: ['./profession-matching-type-selection.component.scss']
})
export class ProfessionMatchingTypeSelectionComponent implements OnInit {

  matchingTypes: ProfessionMatchingScore[] = ["Px", "P3", "P4", "P5"]

  @Input() matchingType: ProfessionMatchingScore
  @Output() matchingTypeChange: EventEmitter<ProfessionMatchingScore> = new EventEmitter<ProfessionMatchingScore>()

  constructor() {
  }

  ngOnInit(): void {
  }

  selectType(type: ProfessionMatchingScore) {
    this.matchingType = type
    this.matchingTypeChange.emit(this.matchingType)
  }

}
