<dialog-header dialogHeadline="Coachdaten vervollständigen"></dialog-header>

    <div class="dialog-main-content layout-padding-8"  >
        <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <mat-form-field>
                <input matInput [formControl]="zipCodeControl" placeholder="PLZ des Wohnortes">
            </mat-form-field>
        </div>
            <div fxLayout="row">
            <button mat-button (click)="sideSheetRef.sheetClosed.next(false)">Abbruch</button>
            <loading-button color="primary" [loadingBool]="saving" (clickEvent)="save()">Speichern</loading-button>
        </div>
        </div>

</div>

<p *ngIf="loading">Lebenslauf lädt</p>
    <ngx-extended-pdf-viewer
            *ngIf="!loading"
            [src]="cvFile"
            [height]="'100%'"
            [useBrowserLocale]="true">
    </ngx-extended-pdf-viewer>




