import {CompanyData} from "./company-data"
import {TopJobSlotData} from "./top-job-slot-data"

export class ConsultantTalentPositionPreviewData {
    company = new CompanyData();
    companyLogoUrl: string | null = null;
    id: number = null;
    locationDescription: string = null;
    locationShortDescription: string = null;
    title: string = null;
    topjobSlotData: TopJobSlotData | null = null;
}