import {Component, OnInit} from '@angular/core';
import {ApplicationProcessingWeeksData, ApplicationState, EmploymentPositionOrigin} from "../../../generated/data";
import {AdminControllingResource} from '../../../generated/resources';
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {ApplicationsSideSheetComponent} from "./applications-side-sheet/applications-side-sheet.component";

@Component({
    selector: 'app-application-processing-controlling',
    templateUrl: './application-processing-controlling.component.html',
    styleUrls: ['./application-processing-controlling.component.scss']
})
export class ApplicationProcessingControllingComponent implements OnInit {


    loading: boolean;
    startDate: Date = new Date();

    origins: EmploymentPositionOrigin[] = [
        "Campusjaeger",
        "Uniwunder",
        "Pluss",
        "Absolventa",
        "Joblift",
        "Jobware",
        "Stellenanzeigen",
        "Experteer",
        "Stepstone"
    ]

    selectedOrigins: EmploymentPositionOrigin[]

    endDate: Date = new Date();
    displayedColumns = [
        'calendarWeek',
        'total',
        'pending',
        'granted',
        'interesting',
        'inAppProcess',
        'contractSent',
        'hired',
        'withdrawn',
        'remainingPotential'
    ];

    data: ApplicationProcessingWeeksData[] = [];

    constructor(private controllingResource: AdminControllingResource, private sideSheet: SideSheetService) {
        this.startDate.setDate(this.startDate.getDate() - 60)
        this.selectedOrigins = this.origins
    }

    ngOnInit(): void {
    }

    load() {
        this.loading = true;
        this.controllingResource.getApplicationProcessingControlling({
            startDate: this.startDate,
            endDate: this.endDate,
            origins: this.selectedOrigins
        }).then(res => {
            this.data = res;
            this.loading = false;
        })
    }

    openApplicationSheet(states: ApplicationState[], element: ApplicationProcessingWeeksData) {
        const comp = this.sideSheet.openOverlay(ApplicationsSideSheetComponent, "Huge");
        comp.instance.filter = {
            states: states,
            dateFrom: element.weekStart,
            dateTo: element.weekEnd,
            origin: this.selectedOrigins
        };
    }
}
