import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookingState'
})
export class BookingStatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'Active':
        return 'Aktiv';
      case 'Canceled':
        return 'Buchung abgebrochen';
      case 'Fullfilled':
        return 'Buchungsanzahl erreicht';
    }

    return value;
  }
}
