<div id="component-wrapper" *ngIf="dataSource" fxLayout="column" fxLayoutAlign="start center">
    <p>Studiengänge ohne Mapping: <b>{{this.totalElements}}</b></p>

    <div class="w-100" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="w-100">
            <searchable-dkz-subject-group-dropdown class="form-field"
                                                   style="width: 400px"
                                                   [control]="manualSubjectGroupSelectionControl"
                                                   [placeholder]="'Manuelle Suche'"
                                                   [multiple]="false"
                                                   [required]="false">
            </searchable-dkz-subject-group-dropdown>

            <button mat-icon-button color="primary" matTooltip="Im Hierarchiebaum suchen" (click)="openDkzMappingSelectionDialog()">
                <i class="mdi mdi-file-tree mdi-36px"></i>
            </button>
        </div>

        <loading-button [loadingBool]="saving" [color]="'primary'" [raised]="true" (clickEvent)="addManualSearchedGroupToSubject()">Gruppe zuordnen</loading-button>
    </div>


    <div fxLayout="column" class="w-100" fxLayoutAlign="start center">
        <div fxLayout="column">
            <span class="subject-header">Studiengang</span>
            <h3>{{dataSource[0].subjectTitle}}</h3>
        </div>


        <div id="subject-group-wrapper" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px" style="width: 950px">
            <div *ngFor="let group of dataSource[0].possibleMatchinGSubjectGroups"
                 class="subject-group-chip"
                 fxLayout="row"
                 style="width: 450px"
                 fxLayoutAlign="center center"
                 (click)="addSubjectToGroupMapping(group.subjectGroupId)"
            >
                <div>({{group.similarityScore | number : '1.2-2'}})</div>&nbsp;
                <div>{{group.subjectGroupTitle}}</div>
            </div>
        </div>

        <mat-paginator *ngIf="dataSource"
                       [pageSize]="pageSize"
                       [showFirstLastButtons]="true"
                       [length]="totalElements"
                       [hidePageSize]="true"
                       [pageIndex]="page"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</div>
