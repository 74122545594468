import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyPricingConfigData, CompanyPricingMode,} from "../../generated/data";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {AdminPricingResource} from "../../generated/resources";
import {UtilityService} from "../../utils/utility.service";

@Component({
  selector: 'app-company-pricing-content',
  templateUrl: './company-pricing-content.component.html',
  styleUrls: ['./company-pricing-content.component.scss']
})
export class CompanyPricingContentComponent implements OnInit {

  pricingModes: CompanyPricingMode[]
  pricingForm: FormGroup

  formSub: Subscription
  modeSub: Subscription

  @Input() pricing: CompanyPricingConfigData
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() pricingChange: EventEmitter<CompanyPricingConfigData> = new EventEmitter<CompanyPricingConfigData>()

  constructor(
      private adminPricingResource: AdminPricingResource,
      private utility: UtilityService,
      private fb: FormBuilder
  ) { }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe()
    this.modeSub?.unsubscribe()
  }

  ngOnInit(): void {
    this.initForm()
    this.getPricingModes()
  }

  getPricingModes() {
    this.adminPricingResource.getPricingModes().then(result => {
      this.pricingModes = result.value
    })
  }
  initForm() {
    this.pricingForm = this.fb.group({
      mode: [this.pricing.mode, Validators.required],
      hiringPercentage: [this.pricing.hiringPercentage],
      hiringAmount: [this.pricing.hiringAmount],
      comment: [this.pricing.comment],
    })

    this.updateFormValidators(this.pricing.mode)

    this.formSub = this.pricingForm.valueChanges.subscribe(next => {
      this.updateValidity()
    })

    this.modeSub = this.pricingForm.controls.mode.valueChanges.subscribe(next => {
      this.updateFormValidators(next)
    })
  }

  updateValidity() {
    this.pricing.mode = this.pricingForm.controls.mode.value
    this.pricing.hiringPercentage = this.pricingForm.controls.hiringPercentage.value
    this.pricing.hiringAmount = this.pricingForm.controls.hiringAmount.value
    this.pricing.comment = this.pricingForm.controls.comment.value
    this.valid.emit(this.pricingForm.valid)
  }

  updateFormValidators(mode: CompanyPricingMode) {
    if (mode == 'HiringValue') {
      this.pricingForm.controls.hiringAmount.setValidators([Validators.required, Validators.min(0)])
      this.pricingForm.controls.hiringPercentage.setValidators(null)
      this.pricingForm.controls.hiringPercentage.setErrors(null)

    }
    if (mode == 'HiringPercentage') {
      this.pricingForm.controls.hiringPercentage.setValidators([Validators.required, Validators.min(0), Validators.max(100)])
      this.pricingForm.controls.hiringAmount.setValidators(null)
      this.pricingForm.controls.hiringAmount.setErrors(null)
    }
    if (mode == 'Booking') {
      this.pricingForm.controls.hiringAmount.setValidators(null)
      this.pricingForm.controls.hiringPercentage.setValidators(null)
      this.pricingForm.controls.hiringAmount.setErrors(null)
      this.pricingForm.controls.hiringPercentage.setErrors(null)

    }
    this.pricingForm.updateValueAndValidity()
  }
}
