import {Component, OnInit} from '@angular/core';
import {AdminFeedControlResource} from "../../generated/resources";
import {AdminEmploymentPositionDisplayData, AdminSubjectGroupFeedPerformanceData} from "../../generated/data";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'app-position-subject-group-performance-sheet',
  templateUrl: './position-subject-group-performance-sheet.component.html',
  styleUrls: ['./position-subject-group-performance-sheet.component.scss']
})
export class PositionSubjectGroupPerformanceSheetComponent implements OnInit {

  //Input
  position:AdminEmploymentPositionDisplayData; //if you wanna use this with different data class just input ID and load

  showRelativeValues: boolean;

  data: AdminSubjectGroupFeedPerformanceData[]
  constructor(private feedControlResource: AdminFeedControlResource, private sideSheetRef: SideSheetRef) { }

  ngOnInit(): void {
    this.load()
  }

  loading = false;
  load(){
    this.loading = true;
    this.feedControlResource.getPositionPerformanceBySubjectGroup(this.position.id).then(res =>{
      this.data  = res;
      this.loading = false;
    })
  }

}
