<mat-form-field class="w-100" color="primary" [ngClass]="dropdownClass" [appearance]="appearance">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select [formControl]="control"
              [multiple]="multiple"
              [required]="required"
              [disabled]="disabled"
              [disableOptionCentering]="disableOptionCentering"
              #options>
    <mat-option>
      <ngx-mat-select-search [formControl]="filterControl"></ngx-mat-select-search>
    </mat-option>
    <div *ngIf="multiple" fxLayout="row" fxLayoutAlign="space-around center">
      <button mat-button class="toggle-all-button" (click)="unselectAll()" color="warn">Alle abwählen</button>
      <button mat-button class="toggle-all-button" (click)="selectAll()" color="primary">Alle Auswählen</button>
    </div>
    <div id="scroll-wrapper" #scrollwrapper>
    <mat-option *ngFor="let profession of professions" [value]="profession.id" [matTooltip]="profession.groupTitle + ' / ' + profession.subGroupTitle">
      {{profession.title}} <small style="opacity: 50%;">[{{profession.typeTitle}}]</small>
    </mat-option>
    </div>
  </mat-select>
</mat-form-field>
