import {Component, Input, OnInit} from '@angular/core';
import {SideSheetRef} from "../../../../utils/side-sheet/sideSheetRef";
import {SideSheetService} from "../../../../utils/side-sheet/side-sheet.service";
import {TalentFilterData} from "../../../../generated/data";
import {AdminEmploymentPositionResource} from "../../../../generated/resources";
import {TalentFilterResultListSheetComponent} from "../../talent-filter-group/talent-filter-result-list-sheet/talent-filter-result-list-sheet.component";

@Component({
  selector: 'app-manual-talent-filter-dialog',
  templateUrl: './manual-talent-filter-dialog.component.html',
  styleUrls: ['./manual-talent-filter-dialog.component.scss']
})
export class ManualTalentFilterDialogComponent implements OnInit {

  @Input() positionId: number;

  filters: TalentFilterData[] = []
  filtersLoaded: Boolean = false;

  constructor(
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private sideSheetService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    //preload prime filters for better ux
      this.loadTalentFilters();
  }

  loadTalentFilters() {
    this.adminEmploymentPositionResource.getPrimeTalentFiltersOfPosition(this.positionId).then(filters => {
      this.filters = filters
      this.filtersLoaded = true
    })
  }

  close(result = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  opentTalentFilterSideSheet(): void {
    let sref = this.sideSheetService.openOverlay(TalentFilterResultListSheetComponent, 'Large')
    sref.instance.positionId = this.positionId
    sref.instance.filters = this.filters
  }

}
