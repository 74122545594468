import {LabelData} from "./label-data"
import {ChatMemberData} from "./chat-member-data"
import {ChatMessageData} from "./chat-message-data"

export class ChatMinimalData {
    company: LabelData | null = null;
    existsUnseen: boolean = null;
    id: number = null;
    lastMessage = new ChatMessageData();
    members: ChatMemberData[] = null;
    type: string = null;
}