import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AdminEmploymentPositionResource} from "../../../generated/resources";
import {ApplicantPersonaData} from "../../../generated/data";
import {UtilsService} from "../../../utils/utils.service";
import {UtilityService} from "../../../utils/utility.service";

@Component({
  selector: 'applicant-persona',
  templateUrl: './applicant-persona.component.html',
  styleUrls: ['./applicant-persona.component.scss']
})
export class ApplicantPersonaComponent implements OnInit {

  @Input() positionId: number

  oldPersona: ApplicantPersonaData
  saveing: boolean = false
  applicantPersonaForm: FormGroup

  constructor(
      private fb: FormBuilder,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.getApplicantPersonaForPosition()
  }

  initForm() {
    this.applicantPersonaForm = this.fb.group({
      differencesToOthers: null,
      preferredStudySubjects: null,
      minimumGrade: null,
      minimumGraduation: null,
      blacklistedUniversities: null,
      workingExperience: null,
      requirements: null,
      requiredLanguageSkills: null,
      perfectApplicant: null
    })
  }

  getApplicantPersonaForPosition() {
    this.adminEmploymentPositionResource.getApplicantPersonaForId(this.positionId).then(result => {
      if (result) {
        this.oldPersona = result
        this.updateApplicantPersona(result)
      }
    })
  }

  updateApplicantPersona(result: ApplicantPersonaData) {
    this.applicantPersonaForm.patchValue({
      differencesToOthers: result.differencesToOthers,
      preferredStudySubjects: result.preferredStudySubjects,
      minimumGrade: result.minimumGrade,
      minimumGraduation: result.minimumGraduation,
      blacklistedUniversities: result.blacklistedUniversities,
      workingExperience: result.workingExperience,
      requirements: result.requirements,
      requiredLanguageSkills: result.requiredLanguageSkills,
      perfectApplicant: result.perfectApplicant
        }
    )
  }

  savePersona() {
    let data: ApplicantPersonaData = {
      positionId: this.positionId,
      id: this.oldPersona?.id,
      differencesToOthers: this.applicantPersonaForm.controls.differencesToOthers.value,
      preferredStudySubjects: this.applicantPersonaForm.controls.preferredStudySubjects.value,
      minimumGrade: this.applicantPersonaForm.controls.minimumGrade.value,
      minimumGraduation: this.applicantPersonaForm.controls.minimumGraduation.value,
      blacklistedUniversities: this.applicantPersonaForm.controls.blacklistedUniversities.value,
      workingExperience: this.applicantPersonaForm.controls.workingExperience.value,
      requirements: this.applicantPersonaForm.controls.requirements.value,
      requiredLanguageSkills: this.applicantPersonaForm.controls.requiredLanguageSkills.value,
      perfectApplicant: this.applicantPersonaForm.controls.perfectApplicant.value
    }
    this.adminEmploymentPositionResource.createOrUpdateApplicantPersona(data).then(result => {
      if (result) this.utilityService.showSnackBar("Bewerberpersona wurde erfolgreich gepspeichert!")
    })
  }

}
