import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {
    AdminEmploymentPositionResource,
    AdminMatchingResource,
    AdminProfessionResource,
    AdminTalentResource,
    LocationResource,
    ProfessionFieldPreferenceResource,
    ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
    AdminEmploymentPositionData,
    CityData,
    CityDistanceData,
    CompleteTalentProfileData,
    PositionProfessionFieldRelationData,
    ProfessionFieldPreferenceData,
    SubjectData
} from "../../../generated/data";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {UtilityService} from "../../../utils/utility.service";
import {TalentProfileDialogComponent} from "../../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {PositionDetailsDialogComponent} from "../../../positions/position-details-dialog/position-details-dialog.component";
import {ProfessionDetailsSheetComponent} from "../../mapping-explorer/profession-details-sheet/profession-details-sheet.component";
import {SubjectDetailsSheetComponent} from "../../mapping-explorer/subject-details-sheet/subject-details-sheet.component";

@Component({
    selector: 'app-matching-analyze-component',
    templateUrl: './matching-analyze-component.component.html',
    styleUrls: ['./matching-analyze-component.component.scss']
})
export class MatchingAnalyzeComponentComponent implements OnInit {

    //Input
    @Input() talentId: number;
    @Input() positionId: number;

    professionId: number

    saving: boolean = false

    talentData: CompleteTalentProfileData
    positionData: AdminEmploymentPositionData
    subjectData: SubjectData

    loadedData: boolean = false
    tabsLoaded: number = 0

    relationsExist: boolean = false
    errorMessage: string = ""

    positionCities: CityData[] = []
    positionCityDistances: CityDistanceData[] = []
    maxRadiusAroundCityForGreenMatch: number = 0

    p5RelationCount: number = 0
    minCityDistance: number = 0

    talentProfessionFieldPreferences: ProfessionFieldPreferenceData[] = []
    positionProfessionFieldRelations: PositionProfessionFieldRelationData[] = []
    matchingFieldPreferences: ProfessionFieldPreferenceData[] = []
    matchingFieldRelations: PositionProfessionFieldRelationData[] = []


    constructor(
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private professionResource: AdminProfessionResource,
        private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
        private employmentPositionResource: AdminEmploymentPositionResource,
        private talentResource: AdminTalentResource,
        private locationResource: LocationResource,
        private matchingResource: AdminMatchingResource,
        public utilityService: UtilityService,
        private dialogService: SideSheetService,
        private snackBar: MatSnackBar,
        private cd: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        Promise.all([
            this.talentResource.getDetailedTalentForAdmin(this.talentId).then(talentData=>{
                this.talentData = talentData
            }),
            this.talentResource.getTalentSubject(this.talentId).then(subjectData=>{
                this.subjectData = subjectData
            }),
            this.employmentPositionResource.getSingleEmploymentPositionById(this.positionId).then(position=>{
                this.positionData = position
                this.professionId = position.professionId
            })]
        ).then(()=>{
            this.loadedData = true
            this.loadProfessionSubjectGroupRelations()
            this.loadLocationData()
            this.loadPreferenceFields()
        })
    }

    loadProfessionSubjectGroupRelations() {
        if (this.professionId == null) {
            this.errorMessage = `${this.positionData.internTitle} hat keine profession`
        } else if (this.subjectData.id == null) {
            this.errorMessage = `${this.talentData.firstName} ${this.talentData.lastName} hat kein subject`
        } else this.relationsExist = true
    }

    loadLocationData() {
        Promise.all([
            this.locationResource.getTalentPositionCityDistances({talentId: this.talentId, positionId: this.positionId}).then(cityDistances=>{
                    this.positionCityDistances = cityDistances
            }),
            this.locationResource.getCitiesById({cityIds: this.positionData.locations.cityIds}).then(cities=>{
                this.positionCities = cities
            }),
            this.locationResource.getMaxRadiusAroundCityForGreenMatch().then(radius=>{
                this.maxRadiusAroundCityForGreenMatch = radius.value
            })
        ]).then(()=>{
            if (this.positionCityDistances.length == 0) {
                this.minCityDistance = 0
            } else {
                this.minCityDistance = this.positionCityDistances[0].distance
            }
            this.tabsLoaded++
        })

    }

    loadPreferenceFields() {
        Promise.all([
            this.professionFieldPreferenceResource.getProfessionFieldPreferencesForTalentAsAdmin(this.talentId).then(fields=>{
                fields = fields.filter((field)=>{
                    return field.preferenceScore != 'NotInteresting'
                })
                fields = fields.sort((field1, field2)=>{
                    return +(field1.preferenceScore == 'Interesting')
                })

                this.talentProfessionFieldPreferences = fields
            }),
            this.matchingResource.getProfessionFieldRelations(this.positionId).then(fields=>{
                fields = fields.filter((field)=>{
                    return field.qualification != 'Excluded'
                })
                fields = fields.sort((field1, field2)=>{
                    return +(field1.qualification == 'Fitting')
                })

                this.positionProfessionFieldRelations = fields
            })
        ]).then(()=>{
            // Filter fields that match
            this.talentProfessionFieldPreferences.forEach((field)=> {
                this.positionProfessionFieldRelations.forEach((relation) => {
                    if (relation.field.id == field.fieldId) {
                        this.matchingFieldPreferences.push(field)
                        this.matchingFieldRelations.push(relation)
                    }
                })
            })
            this.talentProfessionFieldPreferences = this.talentProfessionFieldPreferences.filter((field)=>{
                return !this.matchingFieldPreferences.includes(field)
            })
            this.positionProfessionFieldRelations = this.positionProfessionFieldRelations.filter((relation)=>{
                return !this.matchingFieldRelations.includes(relation)
            })

            this.tabsLoaded++
        })
    }

    openTalentProfile() {
        let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
        comp.instance.talentId = this.talentId
    }

    openSubjectDialog() {
        let comp = this.dialogService.openOverlay(SubjectDetailsSheetComponent)
        comp.instance.subjectId = this.subjectData.id
        comp.instance.subjectTitle = this.subjectData.title
    }

    openPositionDialog() {
        let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = this.positionId
    }

    openProfessionDialog() {
        let comp = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
        comp.instance.professionId = this.professionId
        comp.instance.professionTitle = this.positionData.professionTitle
    }
}
