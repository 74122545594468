import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {AdminApplicationResource, AdminCompanyResource, AdminTalentResource,} from '../generated/resources';
import {AdminApplicationData, ApplicationFilterData, ApplicationState, CompanyData} from '../generated/data';
import {FormControl} from '@angular/forms';
import {UtilityService} from '../utils/utility.service';
import {MatTable} from '@angular/material/table';
import {ApplicationOverviewDialogComponent} from "./application-overview-dialog/application-overview-dialog.component";
import {debounceTime} from 'rxjs/internal/operators';
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {saveAs} from 'file-saver/FileSaver';
import {ApplicationDetailsDialogComponent} from "./application-details-dialog/application-details-dialog.component";
import {RejectApplicationDialogComponent} from "./reject-application-dialog/reject-application-dialog.component";


@Component({
    selector: 'app-requests',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {
    requestStates: ApplicationState[] = ["Draft", "Sent", "Denied", "Withdrawn", "Granted", "Failed", "Interesting", "AppProcessEnter" , "ContractSent" , "Hired"];

    filter: ApplicationFilterData = <ApplicationFilterData>{};
    companies: CompanyData[];
    actualAId: number = null

    displayedColumns = ['creationDate', 'talentName', 'talentSubjectName', 'company', 'positionTitle', 'state', 'origin', 'actions'];

    loading: Boolean = false;
    downloadExcel: Boolean = false;
    filterPanelIsOpen: Boolean = false;
    searchQuery: FormControl = new FormControl();

    pageIndex: number = 0;
    pageSize: number = 15;
    pageSizeOptions: number[] = [15, 30, 60, 100];
    totalSize: number = 0;

    tableData: AdminApplicationData[];

    @ViewChild('dataTable', {static: true})
    public table: MatTable<any>;

    constructor(
        private talentResource: AdminTalentResource,
        private dialog: MatDialog,
        private adminApplicationResource: AdminApplicationResource,
        private adminCompanyResource: AdminCompanyResource,
        private adminTalentResource: AdminTalentResource,
        private utilityService: UtilityService,
        private dialogService: SideSheetService,
        private http: HttpClient,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.filter.states = ['Sent'];
        this.filter.origin = ['Uniwunder']

        this.loadData();

        this.searchQuery.valueChanges.subscribe(searchQuery => {
            this.loading = true;
            this.filter.searchQuery = searchQuery;
        });

        this.searchQuery.valueChanges.pipe(debounceTime(500)).subscribe(searchQuery => {
            this.loadData();
        });
    }

    downloadApplication(): Observable<Blob> {
        return this.http.post(environment.apiUrlMain + '/admin/applications/export', this.filter, {
            headers: new HttpHeaders(),
            responseType: 'blob'
        });
    }

    downloadApplicationExcel() {
        this.downloadExcel = true
        this.downloadApplication().subscribe(blob => {
            saveAs(blob, 'Bewerbungen.xlsx')
            this.downloadExcel = false;
        })
    }

    loadData() {
        this.loading = true;

        if (this.searchQuery.value != null || this.searchQuery.value != '') {
            this.filter.name = null;
            this.filter.position = null;
            this.filter.emailAddress = null;
        }

        this.adminApplicationResource.getAllApplications(this.filter, {
            pageSize: this.pageSize,
            page: this.pageIndex
        }).then(result => {
            this.tableData = result.content;
            this.totalSize = result.totalElements;
            this.loading = false;
        })
    }

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadData();
    }

    openApplicationDetails(application: AdminApplicationData) {
        let cref = this.dialogService.openOverlay(ApplicationDetailsDialogComponent);
        cref.instance.applicationId = application.applicationId;
    }

    openApplicationOverview(application: AdminApplicationData) {
        let cref = this.dialogService.openOverlay(ApplicationOverviewDialogComponent);
        cref.instance.application = application;

        cref.instance.sideSheetRef.sheetClosed.subscribe((result) => {
            if (!result) return

            switch (result.type) {
                case 'Accept':
                    this.utilityService.showSnackBar('Die Bewerbung wurde erfolgreich freigegeben.');
                    break;
                case 'Deny':
                    this.utilityService.showSnackBar('Die Bewerbung wurde abgelehnt.');
                    break;
            }

            if (result.success) this.loadData()
        })
    }

    denyApplication(application: AdminApplicationData) {
        this.setActualApplicationId(application.applicationId)
        let cref = this.dialogService.openOverlay(RejectApplicationDialogComponent);
        cref.instance.application = application

        cref.instance.sideSheetRef.sheetClosed.subscribe(result => {
            if(result) this.removeFromDataTable(application)
        })
    }

    acceptApplication(application: AdminApplicationData) {
        this.setActualApplicationId(application.applicationId)
        this.adminApplicationResource.acceptTalentApplication(application.applicationId).then(success => {
            if (success) this.removeFromDataTable(application)
        })
    }

    setActualApplicationId(newId: number) {
        this.actualAId = newId
    }

    removeFromDataTable(application: AdminApplicationData) {
        this.tableData.splice(this.tableData.findIndex(a => a.applicationId == application.applicationId),1)
        this.table.renderRows()
    }

    copyToClipboard(textToCopy: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = textToCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.utilityService.showSnackBar('In Zwischenablage kopiert');
    }
}
