import { Component, OnInit } from '@angular/core';
import {AdminProfessionResource} from "../../generated/resources";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {LabelData} from "../../generated/data";
import {ProfessionAreaDetailsSheetComponent} from "../mapping-explorer/profession-area-details-sheet/profession-area-details-sheet.component";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'app-mapping-explorer-entry-sheet',
  templateUrl: './mapping-explorer-entry-sheet.component.html',
  styleUrls: ['./mapping-explorer-entry-sheet.component.scss']
})
export class MappingExplorerEntrySheetComponent implements OnInit {

  constructor(private professionResource: AdminProfessionResource, private vierlipNenntAllesFalsch: SideSheetService, private sideSheetRef:SideSheetRef) { }

  professionAreas: LabelData[]
  loading = true;
  ngOnInit(): void {
    this.professionResource.getProfessionAreas().then(res => {
      this.professionAreas = res.content;
      this.loading =false;
    })
  }

  openArea(data:LabelData){
    let comp = this.vierlipNenntAllesFalsch.openOverlay(ProfessionAreaDetailsSheetComponent, 'Medium')
    comp.instance.professionAreaId = data.id;
  comp.instance.professionAreaTitle = data.name;
  }

}
