import {LabelData} from "./label-data"
import {ChatMessageData} from "./chat-message-data"

export class ConsultantChatData {
    coaching = new LabelData();
    createdAt: (Date | null) = null;
    deleted: boolean = null;
    id: number = null;
    members: LabelData[] = null;
    messages: ChatMessageData[] = null;
}