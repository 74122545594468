import {LabelData} from "./label-data"
import {ApplicationState} from "./application-state"

export class PlatformApplicationData {
    creationDate: (Date | null) = null;
    id: number = null;
    position = new LabelData();
    state: ApplicationState = null;
    talentId: number = null;
    talentName: string = null;
}