import {Component, OnInit} from '@angular/core';
import {ProfessionData, SubjectData, SubjectGroupData, SubjectMappingMatchesData} from "../../../generated/data";
import {AdminSubjectResource, ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";

@Component({
    selector: 'app-remove-subject-group-to-profession-preview-sheet',
    templateUrl: './remove-subject-group-to-profession-preview-sheet.component.html',
    styleUrls: ['./remove-subject-group-to-profession-preview-sheet.component.scss']
})
export class RemoveSubjectGroupToProfessionPreviewSheetComponent implements OnInit {

    public subjectGroupId: number
    public subjectGroupData: SubjectGroupData
    public professionId: number
    public professionTitle: string
    public subjectMappingMatchesData: SubjectMappingMatchesData[] = []
    public loading: boolean = false
    public loadingQueues: boolean = true

    public displayedColumns = [ 'positionId', 'positionTitle']

    constructor(
        private adminSubjectResource: AdminSubjectResource,
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private snackBar: MatSnackBar,
        private sideSheetRef: SideSheetRef
    ) {
    }

    ngOnInit(): void {
        this.professionMatchingOptimizingResource.getSubjectGroupToProfessionMappingQueuedMatches({
            groupId: this.subjectGroupId,
            professionId: this.professionId
        })
            .then(result => {
                this.subjectMappingMatchesData = result
                this.loadingQueues = false
            })

        this.adminSubjectResource.getSubjectGroup(this.subjectGroupId).then(sg => {
            this.subjectGroupData = sg
        })
    }

    removeSubjectGroupToProfessionMapping() {
        this.professionMatchingOptimizingResource.removeSubjectGroupToProfessionMapping({
            groupId: this.subjectGroupId,
            professionId: this.professionId
        }).then(() => {
            this.snackBar.open("Zuordnung entfernt")
            this.sideSheetRef.sheetClosed.next(true)
        })
    }
}
