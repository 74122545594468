import {AddressData} from "./address-data"
import {ApplicationGrantStrategy} from "./application-grant-strategy"
import {CompanyInvoiceInformationData} from "./company-invoice-information-data"

export class AdminCompanyUpdateData {
    address = new AddressData();
    applicationGrantStrategy: ApplicationGrantStrategy = null;
    companyInvoiceInformationData = new CompanyInvoiceInformationData();
    consideredForReverseRecruiting: boolean = null;
    googleJobPosting: boolean = null;
    id: number = null;
    keyAccounterId: number | null = null;
    name: string = null;
    primeCompany: boolean = null;
    reverseRecruitingCount: number = null;
    shortCompanyInfo: string | null = null;
    showProfile: boolean = null;
    website: string | null = null;
    yearlyRecruitings: number | null = null;
}