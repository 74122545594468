<loading-spinner [loadingBool]="!loaded"><br>
    <div *ngIf="loaded" fxLayout="column">
        <div *ngIf="resultsFound">
            <h2 style="padding-left: 10px">Beziehungen</h2>
            <span fxLayout="row">
                <loading-button buttonClass="mat-primary"
                                [loadingBool]="saving"
                                matTooltip="Speichern"
                                (clickEvent)="saveProfessionSubjectGroupMapping()">
                    <i class="mdi mdi-content-save main-action-icon"></i>
                </loading-button>
            </span>
            <table class="relationTable" mat-table [dataSource]="subjectGroupsAndProfessionMatchingType">
                <ng-container matColumnDef="subjectGroup">
                    <th mat-header-cell *matHeaderCellDef>Subject Group</th>
                    <td style="padding: 0 10px" class="subjectGroup" mat-cell *matCellDef="let element">{{element.subjectGroup.name}}</td>
                </ng-container>
                <ng-container class="matchingType" matColumnDef="professionMatchingType">
                    <th mat-header-cell *matHeaderCellDef>Matching Type</th>
                    <td style="padding: 0 10px" class="matchingType" mat-cell *matCellDef="let element">
                        <profession-matching-type-selection
                                [matchingType]="element.matchingScore"
                                (matchingTypeChange)="updateMatchingType(element, $event)"
                        ></profession-matching-type-selection>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="professionMatchingTypesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: professionMatchingTypesDisplayedColumns;"></tr>
            </table>
        </div>
        <div *ngIf="!resultsFound">
            <br>
            <span style="padding-left: 10px">{{this.errorMessage}}</span>
        </div>
    </div>
</loading-spinner>