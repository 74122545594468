<dialog-header dialogHeadline="Mapping erstellen"></dialog-header>

<div class="dialog-content" fxLayout="column">

    <p style="color: #7d7d7d; font-size: 14px">
        Mappe den Studiengang <b>{{subjectTitle}}</b> auf eine oder mehrere Studiengruppen.<br>
        Anschließend werden alle Talente mit diesem Studiengang noch einmal neu gematched.
    </p>

    <searchable-subject-group-dropdown placeholder="Studiengruppe" [control]="subjectGroupsControl" [multiple]="true"></searchable-subject-group-dropdown>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <loading-button
                [raised]="true"
                [color]="'primary'"
                [loadingBool]="loading"
                [disabledCondition]="subjectGroupsControl.invalid"
                (clickEvent)="saveMappings()">
            Speichern
        </loading-button>
    </div>
</div>
