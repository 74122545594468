import {AdminCompanyData} from "./admin-company-data"
import {EmploymentCategory} from "./employment-category"
import {EmploymentPositionOrigin} from "./employment-position-origin"
import {EmploymentPositionState} from "./employment-position-state"
import {PositionPriority} from "./position-priority"

export class AdminEmploymentPositionDisplayData {
    active: boolean = null;
    activeQuestionCount: number = null;
    availableSlots: number = null;
    creationDate: (Date | null) = null;
    customerCompany = new AdminCompanyData();
    deleted: boolean = null;
    employmentCategory: EmploymentCategory = null;
    id: number = null;
    isPrime: boolean = null;
    positionOrigin: EmploymentPositionOrigin = null;
    positionState: EmploymentPositionState = null;
    priority: PositionPriority | null = null;
    title: string = null;
    totalQuestionCount: number = null;
    webLink: string | null = null;
}