import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {MatSelect} from "@angular/material/select";
import {ProfessionData, SubjectGroupData} from "../../../generated/data";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-subject-group-dropdown',
  templateUrl: './searchable-subject-group-dropdown.component.html',
  styleUrls: ['./searchable-subject-group-dropdown.component.scss']
})
export class SearchableSubjectGroupDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = false
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false


  @ViewChild('options') options: MatSelect;
  @ViewChild('scrollwrapper') scrollwrapper: ElementRef;

  subjectGroups: SubjectGroupData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
      private adminSubjectResource: AdminSubjectResource,
  ) {
  }

  ngOnInit() {
    this.loadSubjectGroups();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          this.loadSubjectGroups()
        });
  }


  loadSubjectGroups() {

    this.adminSubjectResource.getAllSubjectGroups({
      query: this.filterControl.value,
      pageSize: 100,
      forcedIds: this.control.value
    }).then(subjectGroups => {
          this.subjectGroups = subjectGroups.content;
        }
    )
  }

  selectAll() {
    this.control.setValue(this.subjectGroups.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }

}
