import {Component, ElementRef, Input, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {MatTable} from "@angular/material/table";

@Component({
  selector: 'table-download-button',
  templateUrl: './table-download-button.component.html',
  styleUrls: ['./table-download-button.component.css']
})


export class TableDownloadButtonComponent implements OnInit {

  @Input() matTable: MatTable<any>;
  @Input() table: ElementRef;
  @Input() color: string = "primary";
  @Input() excelName: string = "download.xlsx";
  @Input() disabled: boolean;
  @Input() buttonClass: String;
  @Input() loadingBool: boolean;

  constructor() {
  }

  download() {
    if (this.matTable === undefined && this.table === undefined)
      throw new Error('matTable or Table input not set');

    let element;
    if (this.matTable !== undefined) {
      element = this.matTable['_elementRef'].nativeElement;
    } else {
      element = this.table.nativeElement;

      if (element == undefined) {
        element = this.table;
      }
    }

    let ws = this.generateCells(element);

    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Export");
    XLSX.writeFile(wb, this.excelName);
  }

  ngOnInit() {
  }


  generateCells(element) {
    let wsObject = {};
    let columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];

    let j = 0;

    for (let rowEl of element.rows) {

      //this is here to handle custom header rows with html features
      j++;
      // if(j <= c) continue;

      let i = 0;

      for (let cell of rowEl.cells) {
        cell.set;
        let value = cell.innerHTML.trim().replace(',', '.');
        let column = columns[i];
        let cellName = (column + j).toString();

        let text: string;

        if (cell.innerText.includes('<')) {
          while (cell.innerText.includes('<')) {
            text = cell.innerText.substring(cell.innerText.indexOf('>') + 1, cell.innerText.substring(cell.innerText.indexOf('<')));
          }
        } else text = cell.innerText;

        wsObject[cellName] = {
          v: text,
          t: 's'
        };
        i++;
      }
    }
    let firstKey = Object.keys(wsObject)[0]
    let lastKey = Object.keys(wsObject)[Object.keys(wsObject).length - 1]
    wsObject["!ref"] = (firstKey + ":" + lastKey).toString()
    return wsObject;
  }

}
