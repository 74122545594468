import {EmploymentPositionOrigin} from "./employment-position-origin"

export class AdminEmploymentPositionFilterData {
    origin: EmploymentPositionOrigin | null = null;
    positionOrder: string | null = null;
    q: string | null = null;
    searchCityId: number | null = null;
    searchCompanyId: number | null = null;
    searchPositionId: number | null = null;
    searchTitle: string | null = null;
    showDeleted: boolean = null;
    withInactive: boolean | null = null;
}