import {AfterViewInit, Component, ComponentRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {
    AdminBookingData,
    AdminBookingFilterData,
    BookingFilterData,
    BookingState
} from "../generated/data";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {PageEvent} from "@angular/material/paginator";
import {PositionDetailsDialogComponent} from "../positions/position-details-dialog/position-details-dialog.component";
import {
  CreateOrUpdateBookingSideSheetComponent
} from "./create-booking-dialog/create-or-update-booking-side-sheet.component";
import {BookingService} from "../services/booking.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {AdminBookingResource} from "../generated/resources";
import {BookingComponent} from "./booking/booking.component";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  animations: [
    trigger('detailsExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit, AfterViewInit {

  bookingStates: BookingState[] = ["Active", "Closed"]
  sortStates: String[] = ["Datum", "Unternehmensname"];
  sortOrders: String[] = ["Aufsteigend", "Absteigend"];

  selectedSortState: string = "Unternehmensname";
  selectedSortOrder: string = "Aufsteigend";


  pageIndex: number = 0;
  pageSize: number = 30;
  totalSize: number = 0;
  pageSizeOptions: number[] = [15, 30, 60, 100];

  filterData: AdminBookingFilterData = {
    partialTitle: null,
    companyId: null,
    states: ["Active"],
    sortBy: "Datum",
    sortOrder: "asc",
  };
  loading: boolean;
  displayedColumns = [
    'expand',
    'company',
    'title',
    'positions',
    'pricing',
    'applications',
    'state',
    'keyAccounter',
    'price',
    'date',
    'actions'
  ];

  companyControl = new FormControl(null);
  titleControl = new FormControl(null);

  _datasource?: MatTableDataSource<AdminBookingData>;

  get datasource() { return this._datasource; }
  set datasource(value: MatTableDataSource<AdminBookingData>) {
    this._datasource = value;

    if (this.sort) {
      this._datasource.sort = this.sort;
      this.sort.sort({id: "company", start: "desc", disableClear: true}); // doesn't work without this...
      this.sort.sort({id: "company", start: "asc", disableClear: true});
    }
  }



  private expandedBookingIds = new Set<number>();

  @ViewChild("table", {read: MatSort}) sort: MatSort;

  constructor(
      private adminBookingResource: AdminBookingResource,
      public bookingsService: BookingService,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    if (this.datasource) this.datasource.sort = this.sort;
  }

  handlePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  openPositionDetailsDialog(positionId: number, tabIndex: number = 0) {
    let comp: ComponentRef<PositionDetailsDialogComponent> = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
    comp.instance.tabIndex = tabIndex

    const subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
      this.loadData();
      subscription.unsubscribe();
    });
  }

  expand(booking: AdminBookingData) {
    this.expandedBookingIds.add(booking.id);
  }

  collapse(booking: AdminBookingData) {
    this.expandedBookingIds.delete(booking.id);
  }

  isExpanded(booking: AdminBookingData): boolean {
    return this.expandedBookingIds.has(booking.id);
  }

  getApplicationCount(booking: AdminBookingData, positionId: number): number {
    return booking.applications.filter(a => a.position.id === positionId).length;
  }

    openBooking(booking: AdminBookingData) {
    let comp = this.dialogService.openOverlay(BookingComponent, 'Medium')
    comp.instance.booking = booking;

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      if (res) this.loadData();

      subscription.unsubscribe();
    })
  }
  createBooking(){
    let comp = this.dialogService.openOverlay(CreateOrUpdateBookingSideSheetComponent, 'Small')

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      if (res) this.loadData();

      subscription.unsubscribe();
    })
  }

  loadData() {
    this.loading = true;

    this.filterData.partialTitle = this.titleControl.value;
    this.filterData.companyId = this.companyControl.value;

    switch (this.selectedSortState) {
      case "Datum":
        this.filterData.sortBy = "date";
        break;
      case "Unternehmensname":
        this.filterData.sortBy = "CompanyName";
        break;
    }

    this.filterData.sortOrder = this.selectedSortOrder === "Aufsteigend" ? "asc" : "desc";

    this.adminBookingResource.getFilteredBookingsForAdmin(this.filterData, {
      pageNum: this.pageIndex,
      pageSize: this.pageSize
    }).then((result) => {
      this.datasource = new MatTableDataSource(result.content);

      this.totalSize = result.totalElements;
      this.loading = false;
    });
  }
}
