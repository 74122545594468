import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyPricingMode'
})
export class CompanyPricingModePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'HiringValue':
        return 'Festbetrag pro Einstellung';
      case 'HiringPercentage':
        return 'Anteil von Einstellungsgehalt';
      case 'Booking':
        return 'Bewerberpakete';
        case 'PrepaidBooking':
            return 'Prepaid-Bewerberpakete';
      default: return value;
    }
  }

}
