<dialog-header [dialogHeadline]="'Sondertargetierung'"></dialog-header>


<div class="width-100">
    <div fxLayout="row" fxLayoutAlign="end" class="w-100" fxLayoutGap="15px" style="padding: 20px 20px 0 20px">
        <button mat-icon-button (click)="openTalentFilterSideSheet()" matTooltip="Vorschau">
            <i class="mdi mdi-eye mdi-24px"></i>
        </button>

        <loading-button [loadingBool]="savingSpecialTargeting"
                        [raised]="true"
                        [color]="'primary'"
                        (clickEvent)="saveSpecialTargeting()"
                        style="margin-right: 20px">
            Speichern
        </loading-button>
    </div>

    <div class="w-100 m-left20" *ngIf="targetingData">
        <mat-form-field appearance="fill" style="width: 800px">
            <mat-label>Erklärtext zur Sondertargetierung (Warum diese Stelle zu dir passt:)</mat-label>
            <textarea [(ngModel)]="targetingData.specialTargetingDescription" matInput rows="3"></textarea>
        </mat-form-field>
    </div>

    <talent-filter-group
            class="width-100"
            *ngIf="filtersLoaded"
            [filters]="targetingData.specialTargetingFilters"
            [isSpecialTargeting]="true">
    </talent-filter-group>
</div>
