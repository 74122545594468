<div class="controlling-board-info">
    Dieses Board ermöglicht den Vergleich der einzelnen Contentsplits einer Kundenstelle in einem gewünschtem Zeitraum
</div>

<div class="filter layout-padding-8" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="filter-wrapper" >
        <mat-form-field>
            <input matInput [matDatepicker]="pickerFrom" placeholder="Events von"
                   [(ngModel)]="startDate" name="dateFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="pickerTo" placeholder="Events bis"
                   [(ngModel)]="endDate" name="dateTo">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <div style="width: 350px">
            <searchable-customer-positions-dropdown
                    [multiple]="false"
                    [control]="searchPositionIdControl"
                    placeholder="Stelle">
            </searchable-customer-positions-dropdown>
        </div>

        <mat-form-field>
            <mat-label>Splits</mat-label>
            <mat-select [(ngModel)]="selectedSplitIds" [multiple]="true">
                <mat-option *ngFor="let split of splits" [value]="split.id">
                    {{split.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <loading-button
                matTooltip="Aktualisieren"
                buttonClass="mat-primary"
                [disabledCondition]="loadingSplits || !selectedSplitIds?.length"
                [loadingBool]="loading"
                (clickEvent)="load()">
            <i class="mdi mdi-refresh dark filter-color"></i>
        </loading-button>
    </div>

    <div fxLayoutAlign="end center" fxLayout="row">
        <table-download-button color="primary"
                               excelName="ContentSplitsControlling.xlsx"
                               [buttonClass]="'main-action-icon'"
                               [matTable]="controllingTable">
        </table-download-button>
    </div>
</div>

<loading-spinner [loadingBool]="loading">

    <table mat-table [dataSource]="data" class="max-table" #controllingTable>

        <ng-container matColumnDef="split">
            <th mat-header-cell *matHeaderCellDef>Split</th>
            <td mat-cell *matCellDef="let element">
                {{element.split.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="seen">
            <th  mat-header-cell *matHeaderCellDef>Gesehen</th>
            <td mat-cell *matCellDef="let element">{{element.seenCount}}</td>
        </ng-container>


        <ng-container matColumnDef="clicked">
            <th  mat-header-cell *matHeaderCellDef>Geklickt</th>
            <td mat-cell *matCellDef="let element"> {{element.clickedCount}} {{getRelValueString(element.clickedCount, element.seenCount)}}</td>
        </ng-container>

        <ng-container matColumnDef="ctaClicked">
            <th  mat-header-cell *matHeaderCellDef> CTA-Klick</th>
            <td mat-cell *matCellDef="let element"> {{element.ctaCount}} {{getRelValueString(element.ctaCount, element.seenCount)}}</td>
        </ng-container>

        <ng-container matColumnDef="applied">
            <th  mat-header-cell *matHeaderCellDef> Beworben</th>
            <td mat-cell *matCellDef="let element"> {{element.appliedCount}} {{getRelValueString(element.appliedCount, element.seenCount)}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['split','seen','clicked','ctaClicked','applied'];sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: ['split','seen','clicked','ctaClicked','applied'];"></tr>
    </table>
</loading-spinner>
