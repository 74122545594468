import {Injectable} from '@angular/core';
import {MediaMatcher} from "@angular/cdk/layout";
import {delay} from "./general-utils.service";

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;


  //TODO does not yet react to resizes
  constructor(
    media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
  }

  isMobile() {
    return this.mobileQuery.matches
  }

  forceRerender() {
    document.getElementsByTagName("router-outlet")[0].setAttribute("style", "display: none");
    delay(100).then(() => {
      document.getElementsByTagName("router-outlet")[0].setAttribute("style", "display: initial");
    })
  }

  deviceIsCancerous() {
    return this.isIOs()
  }

  isIOs(): boolean {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }
}
