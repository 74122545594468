<h2>Aktuelle Buchung</h2>

<mat-radio-group [formControl]="control" class="booking-radio-group">
    <mat-radio-button [disableRipple]="true" class="booking-radio-item no-booking" (click)="control.setValue(null); $event.stopPropagation()">
        Keine Buchung
    </mat-radio-button>

    <div *ngFor="let booking of bookings" class="booking-radio-item">
        <mat-radio-button [value]="booking.id" [disableRipple]="true">
            {{ booking.title }}
            <mat-icon class="info-icon" (click)="$event.stopPropagation(); $event.preventDefault(); openBooking(booking)">info</mat-icon>
        </mat-radio-button>
    </div>

    <div class="new-booking booking-radio-item" (click)="createNewBooking()">
        <mat-icon>add</mat-icon> Neue Buchung erstellen
    </div>
</mat-radio-group>

