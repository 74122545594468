<dialog-header [dialogHeadline]="'Buchung'"></dialog-header>

<mat-tab-group>
    <!-- Edit Booking-->
    <mat-tab label="Bearbeiten" *ngIf="booking.state != 'Closed'">
        <app-create-booking-dialog [dialog]="false" [booking]="booking"></app-create-booking-dialog>
    </mat-tab>

    <!-- Details for Booking-->
    <mat-tab label="Details">
        <div class="layout-padding">
            <h2>{{ booking.title }}</h2>
            <div class="grid-view">
                <dialog-data-display
                        label="Status"
                        [data]="booking.state"
                        alternative="Nicht definiert">
                </dialog-data-display>

                <dialog-data-display
                        label="Erstellt am"
                        [data]="booking.createdAt | date:'dd.MM.yy'"
                        alternative="Nicht verfügbar">
                </dialog-data-display>

                <dialog-data-display
                        label="Key Accounter"
                        [data]="booking.keyAccounter?.name"
                        alternative="Kein Key Accounter">
                </dialog-data-display>

                <dialog-data-display
                        label="Bewerbungen / Buchungsgröße"
                        [data]="booking.applications?.length + '/' + booking.size + (booking.resupply ? ' +' + booking.resupply : '')"
                        alternative="Keine Daten">
                </dialog-data-display>

            </div>
            <br>
            <br>
            <h2>Stellen</h2>
            <p *ngIf="booking.positions.length == 0" class="nothing-to-show-here">Zu dieser Buchung gibt es noch keine Stellen</p>
            <table mat-table [dataSource]="booking.positions" *ngIf="booking.positions.length > 0">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let position"
                        (click)="openPositionDialog(position.id)"><div class="profile-container">
                        {{ position.name }}</div></td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let position" [class.active]="position.state == 'Active'">{{ position.state }}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let position">
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Stelle öffnen"
                                (click)="openPositionDialog(position.id)">
                            <i class="mdi mdi-information dark"></i>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['name', 'status', 'action']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['name', 'status', 'action']"></tr>
            </table>

            <br>
            <br>
            <h2>Bewerber</h2>
            <p *ngIf="booking.applications.length == 0" class="nothing-to-show-here">Zu dieser Buchung gibt es noch keine Bewerbungen</p>
            <table mat-table [dataSource]="booking.applications" *ngIf="booking.applications.length > 0">
                <ng-container matColumnDef="talent">
                    <th mat-header-cell *matHeaderCellDef>Talent</th>
                    <td mat-cell *matCellDef="let application">
                        <div class="profile-container" (click)="openTalentProfileDialog(application.talentId)">
                            <img *ngIf="profilePictures[application.talentId] != null"
                                 [src]="profilePictures[application.talentId]"
                                 class="profile-pic" alt="">
                            <img *ngIf="profilePictures[application.talentId] == null"
                                 src="../../../assets/images/blurred_user.png"
                                 class="profile-pic" alt="">
                            <div>
                                {{ application.talentName }}
                                <application-state [state]="application.state"></application-state>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="creation">
                    <th mat-header-cell *matHeaderCellDef>Beworben</th>
                    <td mat-cell *matCellDef="let application" [title]="'Erstellt: ' + (application.creationDate | date:'dd.MM.yy')">
                        {{ application.creationDate | date:'dd.MM.yy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let application">
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Talent öffnen"
                                (click)="openTalentProfileDialog(application.talentId)">
                            <i class="mdi mdi-account-circle dark"></i>
                        </button>
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Bewerbungsdetails öffnen"
                                (click)="openApplicationDetails(application.id)">
                            <i class="mdi mdi-file-document dark"></i>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['talent', 'creation', 'action']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['talent', 'creation', 'action']"></tr>
            </table>

        </div>
    </mat-tab>
    <mat-tab label="History">
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="bookingLogs" class="mat-table">
                <ng-container matColumnDef="occurred">
                    <th mat-header-cell *matHeaderCellDef> Zeit </th>
                    <td mat-cell *matCellDef="let log"> {{ log.occurred | date:'dd.MM.yy - HH:mm' }} </td>
                </ng-container>
                <ng-container matColumnDef="person">
                    <th mat-header-cell *matHeaderCellDef> Person </th>
                    <td mat-cell *matCellDef="let log">
                        <div *ngIf="log.actingUserName">
                            {{ log.actingUserName }}
                        </div>
                        <div class="nothing" *ngIf="!log.actingUserName">
                            -
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Aktion </th>
                    <td mat-cell *matCellDef="let log"> {{ log.eventType | bookingEventType }} </td>
                </ng-container>
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> Stelle </th>
                    <td mat-cell *matCellDef="let log">
                        <div *ngIf="log.positionId">
                            {{ log.positionName }}
                            <button mat-button mat-icon-button color="primary" matTooltip="Stelle öffnen" (click)="openPositionDialog(log.positionId)">
                                <i class="mdi mdi-information dark"></i>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['occurred', 'person', 'action', 'position']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['occurred', 'person', 'action', 'position']"></tr>
            </table>
        </div>
    </mat-tab>



</mat-tab-group>
