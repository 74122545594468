import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminCompanyProfileData, AdminEmploymentPositionData, AdminCompanyData, PagedData, AdminCompanyProfileQuickUpdateData, AdminCompanyUpdateData, AdminCompanyCreationData, AddressData, AdminCompanyProfileFilterData, MinimalEmploymentPositionData, AdminApplicationData, AdminRoleDisplayData, AdminEmploymentPositionDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminCompanyController
 */

@Injectable()
@ResourceParams({})
export class AdminCompanyResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/companies/company',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createCompany: IResourceMethodObservableStrict<AdminCompanyCreationData, null, null, AdminCompanyData> | undefined
    createCompany(requestBody: AdminCompanyCreationData): Promise<AdminCompanyData> {
        if (!this._createCompany) throw new Error("resource has not been properly initialized")
        return this._createCompany(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsForCompanyFilteredByState: IResourceMethodObservableStrict<null, {state?: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired'), page?: number, pageSize?: number}, {id: number}, PagedData<AdminApplicationData>> | undefined
    getApplicationsForCompanyFilteredByState(id: number, requestParams?: {state?: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired'), page?: number, pageSize?: number}): Promise<PagedData<AdminApplicationData>> {
        if (!this._getApplicationsForCompanyFilteredByState) throw new Error("resource has not been properly initialized")
        return this._getApplicationsForCompanyFilteredByState(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanies: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[], sortDirection?: ('ASC' | 'DESC'), sortProperty?: string}, null, PagedData<AdminCompanyData>> | undefined
    getCompanies(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[], sortDirection?: ('ASC' | 'DESC'), sortProperty?: string}): Promise<PagedData<AdminCompanyData>> {
        if (!this._getCompanies) throw new Error("resource has not been properly initialized")
        return this._getCompanies(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/pricing',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompaniesByPricing: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[], sortDirection?: ('ASC' | 'DESC'), filteredModes: ('HiringValue' | 'HiringPercentage' | 'Booking' | 'PrepaidBooking')[], sortProperty?: string}, null, PagedData<AdminCompanyData>> | undefined
    getCompaniesByPricing(requestParams: {q?: string, page?: number, pageSize?: number, forcedIds?: number[], sortDirection?: ('ASC' | 'DESC'), filteredModes: ('HiringValue' | 'HiringPercentage' | 'Booking' | 'PrepaidBooking')[], sortProperty?: string}): Promise<PagedData<AdminCompanyData>> {
        if (!this._getCompaniesByPricing) throw new Error("resource has not been properly initialized")
        return this._getCompaniesByPricing(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/companyProfiles',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompaniesWithProfiles: IResourceMethodObservableStrict<AdminCompanyProfileFilterData, {page?: number, pageSize?: number}, null, PagedData<AdminCompanyProfileData>> | undefined
    getCompaniesWithProfiles(requestBody: AdminCompanyProfileFilterData, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<AdminCompanyProfileData>> {
        if (!this._getCompaniesWithProfiles) throw new Error("resource has not been properly initialized")
        return this._getCompaniesWithProfiles(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompany: IResourceMethodObservableStrict<null, null, {id: number}, AdminCompanyData> | undefined
    getCompany(id: number): Promise<AdminCompanyData> {
        if (!this._getCompany) throw new Error("resource has not been properly initialized")
        return this._getCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/address',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyAddress: IResourceMethodObservableStrict<null, null, {id: number}, AddressData> | undefined
    getCompanyAddress(id: number): Promise<AddressData> {
        if (!this._getCompanyAddress) throw new Error("resource has not been properly initialized")
        return this._getCompanyAddress(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/keyAccount',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyKeyAccount: IResourceMethodObservableStrict<null, null, {id: number}, AdminRoleDisplayData> | undefined
    getCompanyKeyAccount(id: number): Promise<AdminRoleDisplayData> {
        if (!this._getCompanyKeyAccount) throw new Error("resource has not been properly initialized")
        return this._getCompanyKeyAccount(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionsForCompany: IResourceMethodObservableStrict<null, null, {id: number}, AdminEmploymentPositionDisplayData[]> | undefined
    getEmploymentPositionsForCompany(id: number): Promise<AdminEmploymentPositionDisplayData[]> {
        if (!this._getEmploymentPositionsForCompany) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionsForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/positions/minimal',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMinimalEmploymentPositionsForCompany: IResourceMethodObservableStrict<null, null, {id: number}, PagedData<MinimalEmploymentPositionData>> | undefined
    getMinimalEmploymentPositionsForCompany(id: number): Promise<PagedData<MinimalEmploymentPositionData>> {
        if (!this._getMinimalEmploymentPositionsForCompany) throw new Error("resource has not been properly initialized")
        return this._getMinimalEmploymentPositionsForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/positions/paged',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPagedEmploymentPositionsForCompany: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, {id: number}, PagedData<AdminEmploymentPositionData>> | undefined
    getPagedEmploymentPositionsForCompany(id: number, requestParams: {page: number, pageSize: number}): Promise<PagedData<AdminEmploymentPositionData>> {
        if (!this._getPagedEmploymentPositionsForCompany) throw new Error("resource has not been properly initialized")
        return this._getPagedEmploymentPositionsForCompany(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/companies/{companyId}/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setCompanyDeletedFlag: IResourceMethodObservableStrict<null, {deletedFlag: boolean}, {companyId: number}, null> | undefined
    setCompanyDeletedFlag(companyId: number, requestParams: {deletedFlag: boolean}): Promise<null> {
        if (!this._setCompanyDeletedFlag) throw new Error("resource has not been properly initialized")
        return this._setCompanyDeletedFlag(null, requestParams, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/companies/{id}/api/searchable',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateApiPositionSearchabilityForCompany: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    updateApiPositionSearchabilityForCompany(id: number): Promise<null> {
        if (!this._updateApiPositionSearchabilityForCompany) throw new Error("resource has not been properly initialized")
        return this._updateApiPositionSearchabilityForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/companies/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyDetails: IResourceMethodObservableStrict<AdminCompanyUpdateData, null, null, null> | undefined
    updateCompanyDetails(requestBody: AdminCompanyUpdateData): Promise<null> {
        if (!this._updateCompanyDetails) throw new Error("resource has not been properly initialized")
        return this._updateCompanyDetails(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/companyProfiles/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyShowProfile: IResourceMethodObservableStrict<AdminCompanyProfileQuickUpdateData, null, null, null> | undefined
    updateCompanyShowProfile(requestBody: AdminCompanyProfileQuickUpdateData): Promise<null> {
        if (!this._updateCompanyShowProfile) throw new Error("resource has not been properly initialized")
        return this._updateCompanyShowProfile(requestBody, null, null).toPromise()
    }

}