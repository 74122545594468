import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {PageEvent} from "@angular/material/paginator";
import {AdminProfessionResource} from "../../../generated/resources";
import {FieldOfActivityRelationData, ProfessionAssigmentCountData} from "../../../generated/data";
import {BehaviorSubject} from "rxjs";
import {debounceTime} from "rxjs/internal/operators";
import {AddActivityFieldSheetComponent} from "../add-activity-field-sheet/add-activity-field-sheet.component";

@Component({
  selector: 'app-profession-field-qualification-editor',
  templateUrl: './profession-field-qualification-editor.component.html',
  styleUrls: ['./profession-field-qualification-editor.component.scss']
})
export class ProfessionFieldQualificationEditorComponent implements OnInit {

  public pageIndex: number = 0;
  public pageSize: number = 15;
  public totalSize: number = 0;
  loadingProfessionIds: number[] = []

  public pageSizeOptions: number[] = [30, 60, 100, 300];
  private subjectMap: Map<number, BehaviorSubject<ProfessionAssigmentCountData>> = new Map()

  public displayedColumns: string[] = [
      'count',
    'dkzId',
    'name',
    'group',
    'fields'];

  datasource: ProfessionAssigmentCountData[] = []
  loading = false;

  public filterControl = new FormControl(null)

  constructor(
      private dialogService: SideSheetService,
      private adminProfessionResource: AdminProfessionResource,
      private changeDetector: ChangeDetectorRef
      ) {
  }

  ngOnInit(): void {
    this.load()
  }

  load() {
    this.loading = true;
    this.adminProfessionResource.getProfessionsForFieldOptimazation({
      q: this.filterControl.value,
      page: this.pageIndex,
      pageSize: this.pageSize
    }).then(result => {
      this.datasource = result.content
      this.totalSize = result.totalElements
      this.loading = false;
    })
  }

  addFieldToProfession(profession: ProfessionAssigmentCountData) {
    let cref = this.dialogService.openOverlay(AddActivityFieldSheetComponent)
    let sub = cref.instance.sideSheetRef.sheetClosed.subscribe(res => {
      let data: FieldOfActivityRelationData = {id: res.id, qualification: "Excluded", name: res.name}
      profession.fieldOfActivityRelations.push(data)
      this.changeDetector.detectChanges()
      this.setNextQualifiedState(profession, data)
      sub.unsubscribe()
    })
  }

  updateProfessionFieldQualification(data: FieldOfActivityRelationData[], professionId: number) {
    this.loadingProfessionIds.push(professionId)
    this.adminProfessionResource.updateProfessionFieldQualification(data, {professionId: professionId}).then( () => {
      this.loadingProfessionIds.splice(this.loadingProfessionIds.indexOf(professionId), 1)
    }
    )
  }



  setNextQualifiedState(profession: ProfessionAssigmentCountData, relation: FieldOfActivityRelationData) {

    switch (relation.qualification) {
      case "Normal": relation.qualification = "Fitting"; break;
      case "Fitting": relation.qualification = "Excluded"; break;
      case "Excluded": relation.qualification = "Normal"; break;
    }

    let oldSubject = this.subjectMap.get(profession.id)

    if (oldSubject) {
      oldSubject.next(profession)
    } else {

      let obser = new BehaviorSubject<ProfessionAssigmentCountData>(profession)
      this.subjectMap.set(profession.id, obser)

      this.subjectMap.get(profession.id).asObservable().pipe(debounceTime(2000)).subscribe(profession => {
        this.subjectMap.delete(profession.id)
        this.updateProfessionFieldQualification(profession.fieldOfActivityRelations, profession.id)
      })

    }
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.load();
  }
}