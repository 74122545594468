import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {AdminCompanyData, ConsultantData} from "../../../generated/data";
import {AdminCompanyResource, AdminConsultantResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-talent-agent-dropdown',
  templateUrl: './searchable-talent-agent-dropdown.component.html',
  styleUrls: ['./searchable-talent-agent-dropdown.component.scss']
})
export class SearchableTalentAgentDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  consultants: ConsultantData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
      private adminConsultantController: AdminConsultantResource
  ) {
  }

  ngOnInit() {
    this.loadCompanies();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          this.loadCompanies()
        });
  }


  loadCompanies() {
    this.adminConsultantController.getFilteredActiveConsultants({q: this.filterControl.value, pageSize: 30, forcedIds: this.control.value}).then(companies => {
          this.consultants = companies.content;
        }
    )
  }

  selectAll() {
    this.control.setValue(this.consultants.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}


