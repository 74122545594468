import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'primePositionMatchingMode'
})
export class PrimePositionMatchingModePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {

      case 'Unknown':
        return 'Unbekannt'
      case 'MatchedByMatching':
        return 'Matching'
      case 'MatchedByTalentagentAdmin':
        return 'Admin'
      case 'MatchedByMlpConsultant':
        return 'Karrierecoach'
      default: return 'Unbekannt'
    }
  }
}
