import {AnonymousProfileData} from "./anonymous-profile-data"
import {AddressData} from "./address-data"
import {Gender} from "./gender"
import {MatchingState} from "./matching-state"
import {StudiesData} from "./studies-data"
import {ContactOption} from "./contact-option"

export class CompleteTalentProfileData extends AnonymousProfileData {
    address = new AddressData();
    advertisingForEventsEnabled: boolean = null;
    birthDate: (Date | null) = null;
    deleted: boolean = null;
    gender: Gender | null = null;
    hasCv: boolean = null;
    matchingState: MatchingState = null;
    mostRecentStudies: StudiesData | null = null;
    preferredContactOption: ContactOption = null;
    profileId: number = null;
    recommendedCount: number | null = null;
    utmCampaign: string | null = null;
    utmContent: string | null = null;
    utmMedium: string | null = null;
    utmSource: string | null = null;
    wasChecked: boolean | null = null;
}