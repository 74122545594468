import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employmentPositionOrigin'
})
export class EmploymentPositionOriginPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'Uniwunder':
        return 'Uniwunder';
      case 'Campusjaeger':
        return 'Campusjäger';
      case 'Pluss':
        return 'pluss'
      case 'Absolventa':
        return 'Absolventa'
      case 'Joblift':
        return 'Joblift'
      case 'Stellenanzeigen':
        return 'Stellenanzeigen'
      default: return value
    }
  }
}
