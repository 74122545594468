import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TalentFilterData} from "../../../generated/data";

;

@Component({
  selector: 'talent-filter-group',
  templateUrl: './talent-filter-group.component.html',
  styleUrls: ['./talent-filter-group.component.scss']
})
export class TalentFilterGroupComponent implements OnInit {

  @Input() filters: TalentFilterData[] = []
  @Input() isSpecialTargeting: boolean = false
  @Output() filtersChange: EventEmitter<TalentFilterData[]> = new EventEmitter()

  constructor(
  ) {
  }

  ngOnInit() {

    if (this.filters.length == 0) {
      this.addFilter()
    }
  }

  addFilter() {
    let talentFilter = new TalentFilterData()
    talentFilter.onlyTalentsConsideredForMatching = true
    talentFilter.onlyTalentsWithCompleteData = true
    talentFilter.onlyWithCompletedOnboarding = true
    talentFilter.deleted = false
    this.filters.push(talentFilter)
  }

  removeFilter(filter: TalentFilterData) {
    this.filters.splice(this.filters.indexOf(filter), 1)
  }

  emit() {
    this.filtersChange.emit(this.filters);
  }

  copyFilter(filter) {
    let copiedFilter = {...filter}
    this.filters.push(copiedFilter);
  }

  onFilterChange(filter: TalentFilterData, filterData: TalentFilterData) {
    this.filters[this.filters.indexOf(filter)] = filterData
  }
}

