<div class="button-bar" fxLayout="row" fxLayoutAlign="space-between center">
  <mat-form-field class="name-filter" *ngIf="!filterPanelIsOpen">
    <input matInput [formControl]="requestSearchQuery" placeholder="Schnellsuche">
  </mat-form-field>


  <div class="filter" *ngIf="filterPanelIsOpen"
       [ngClass]="{'openFilterPanel' : filterPanelIsOpen, 'closeFilterPanel' : !filterPanelIsOpen}"
       fxLayout="column" fxLayoutAlign="start start">

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <searchable-company-dropdown
        [control]="searchCompanyIdControl"
        [multiple]="false"
        placeholder="Unternehmen">
      </searchable-company-dropdown>

      <searchable-position-dropdown
        [control]="searchPositionIdControl"
        placeholder="Stelle">
      </searchable-position-dropdown>

      <mat-slide-toggle (change)="switchPositionState($event)">Inaktive anzeigen</mat-slide-toggle>
      <loading-button style="margin-right: 10px"
                      matTooltip="gefilterte Stellen laden"
                      buttonClass="mat-primary"
                      [loadingBool]="loading"
                      (clickEvent)="loadRequestContent()">
        <i class="mdi mdi-refresh main-action-icon filter-color"></i>
      </loading-button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">

    <button color="primary" mat-icon-button (click)="openCreateApplicationDialog()" matTooltip="Bewerbung eintragen">
      <i style="font-size: 24px" class="mdi mdi-plus"></i>
    </button>

    <loading-button style="margin: 0 10px; overflow: hidden"
                    matTooltip="Filter anzeigen"
                    buttonClass="mat-primary"
                    (clickEvent)="openFilterPanel()">
      <i class="mdi mdi-filter-variant main-action-icon"
         [ngStyle]="{'color' : filterPanelIsOpen ? 'var(--secondary)' : ''}"></i>
    </loading-button>
  </div>
</div>
<div>

  <loading-spinner [loadingBool]="loading">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef>Anfragedatum</th>
        <td mat-cell *matCellDef="let row"> {{row.creationDate | date: 'dd.MM.yy'}}</td>
      </ng-container>

      <ng-container matColumnDef="releaseDate">
        <th mat-header-cell *matHeaderCellDef>Freigabedatum</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.releaseDate != null">{{row.releaseDate | date: 'dd.MM.yy'}}</div>
          <div *ngIf="row.releaseDate == null">N/A</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>Unternehmen</th>
        <td mat-cell *matCellDef="let row">{{row.company | sliceStringLength: 30}}</td>
      </ng-container>

      <ng-container matColumnDef="positionTitle">
        <th mat-header-cell *matHeaderCellDef>Stelle</th>
        <td mat-cell *matCellDef="let row">{{row.position.internTitle | sliceStringLength: 100}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" class="request-state">
          <application-state [state]="row.state" [markedHired]="row.applicationMarkedAsHired" ></application-state>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Aktionen</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="openApplicationDetails(row)"
                  matTooltip="Bewerbungsdetails öffnen">
            <i class="mdi mdi-file dark"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="applicationColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: applicationColumns;"></tr>
    </table>
  </loading-spinner>

  <mat-paginator [pageSize]="applicationsPageSize"
                 [pageSizeOptions]="applicationsPageSizeOptions"
                 [showFirstLastButtons]="true"
                 [length]="applicationsTotalSize"
                 [hidePageSize]="false"
                 [pageIndex]="applicationsPageIndex"
                 (page)="handlePage($event)">
  </mat-paginator>
</div>
