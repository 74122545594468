import {Component, Input, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {
  AdminInvoiceData,
  InvoiceData
} from "../../generated/data";
import {
  PositionDetailsDialogComponent
} from "../../positions/position-details-dialog/position-details-dialog.component";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {AdminApplicationResource, AdminInvoiceResource} from "../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Clipboard} from "@angular/cdk/clipboard";


@Component({
  selector: 'app-company-information',
  templateUrl: './invoice-information.component.html',
  styleUrls: ['./invoice-information.component.scss']
})
export class InvoiceInformationComponent implements OnInit {
  formGroup: FormGroup
  adminInvoiceData: AdminInvoiceData
  updateHiringLoading: boolean = false

  constructor(
      private fb: FormBuilder,
      private sideSheetRef: SideSheetRef,
      private sideSheetService: SideSheetService,
      private snackbar: MatSnackBar,
      private adminApplicationResource: AdminApplicationResource,
      private clipboard: Clipboard,
  ) {

  }

  ngOnInit(): void {
    this.openPositionDetailsDialog = this.openPositionDetailsDialog.bind(this);
    this.openTalentProfile = this.openTalentProfile.bind(this);
    this.initForm()
  }

  close(result?: InvoiceData) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  openPositionDetailsDialog() {
    let comp = this.sideSheetService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = this.adminInvoiceData.positionId
  }

  openTalentProfile() {
    let comp = this.sideSheetService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = this.adminInvoiceData.talentId
  }


  initForm(): void {
    this.formGroup = this.fb.group({
      signingDate: [this.adminInvoiceData.signingDate],
      startingDate: [this.adminInvoiceData.startDate],
      salaryState: [this.adminInvoiceData.salaryState ? this.adminInvoiceData.salaryState : "SalaryGiven", Validators.required],
      salaryText: new FormControl({value: this.adminInvoiceData.salary, disabled: true}, [Validators.required]),
      notes: [this.adminInvoiceData.adminNote],
    })
  }



  updateHiring(): Promise<any> {
    this.updateHiringLoading = true
    return this.adminApplicationResource.updateHiringInformationForApplication(
        {
          salaryAmount: this.formGroup.controls.salaryText.value,
          startingDate: this.formGroup.controls.startingDate.value,
          signingDate: this.formGroup.controls.signingDate.value,
          adminNote: this.formGroup.controls.notes.value,
          salaryState: this.formGroup.controls.salaryState.value
        },
        this.adminInvoiceData.application.id
    ).then(result => {
      if (result) {

        this.adminInvoiceData.signingDate = this.formGroup.controls.signingDate.value
        this.adminInvoiceData.startDate = this.formGroup.controls.startingDate.value
        this.adminInvoiceData.salaryState = this.formGroup.controls.salaryState.value
        this.adminInvoiceData.salary = this.formGroup.controls.salaryText.value
        this.adminInvoiceData.adminNote = this.formGroup.controls.notes.value

        this.snackbar.open("Vertragsinformationen erfolgreich gespeichert!", null, {duration: 2000})
        this.sideSheetRef.sheetClosed.next(result)
      }
      this.updateHiringLoading = false
    })
  }

  toString(value: number): string {
    return value === null ? "" : String(value);
  }

  copyValueToClipboard(data: string) {
    const textToCopy = data
    if (textToCopy) {
      this.clipboard.copy(textToCopy);
      this.snackbar.open(textToCopy + ", wurde kopiert!", null, {duration: 2000})
    }
  }
}
