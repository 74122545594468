import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {AdminSubjectResource, ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {UtilityService} from "../../../utils/utility.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SubjectData, SubjectGroupData, SubjectMappingMatchesData} from "../../../generated/data";

@Component({
    selector: 'app-remove-mapping-preview-sheet',
    templateUrl: './remove-subject-to-group-mapping-preview-sheet.component.html',
    styleUrls: ['./remove-subject-to-group-mapping-preview-sheet.component.scss']
})
export class RemoveSubjectToGroupMappingPreviewSheetComponent implements OnInit {

    public subjectId: number
    public subjectData: SubjectData
    public subjectGroupId: number
    public subjectGroupData: SubjectGroupData
    public subjectMappingMatchesData: SubjectMappingMatchesData
    public loading: boolean = false
    public loadingQueues: boolean = true

    public displayedColumns = [ 'positionId', 'positionTitle']

    constructor(
        private adminSubjectResource: AdminSubjectResource,
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private snackBar: MatSnackBar,
        private sideSheetRef: SideSheetRef
    ) {
    }

    ngOnInit(): void {
        this.professionMatchingOptimizingResource.getSubjectToGroupMappingQueuedMatches({
            subjectId: this.subjectId,
            groupId: this.subjectGroupId
        })
            .then(result => {
                this.subjectMappingMatchesData = result
                this.loadingQueues = false
            })

        this.adminSubjectResource.getSubject(this.subjectId).then(s => {
            this.subjectData = s
        })

        this.adminSubjectResource.getSubjectGroup(this.subjectGroupId).then(sg => {
            this.subjectGroupData = sg
        })
    }

    removeSubjectToGroupMapping() {
        this.professionMatchingOptimizingResource.removeSubjectToGroupMapping({
            subjectId: this.subjectId,
            groupId: this.subjectGroupId
        }).then(() => {
            this.snackBar.open("Zuordnung entfernt")
            this.sideSheetRef.sheetClosed.next(true)
        })
    }
}
