<loading-spinner [loadingBool]="loading">
    <header class="width-100" style="padding: 20px" fxLayout="row"
            fxLayoutAlign="space-between center">
        <div class="filter" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field style="width: 400px">
                <input [formControl]="filterControl" matInput placeholder="Stellen suche (titel, tätigkeitsfeld,...)">
            </mat-form-field>

<!--            <mat-slide-toggle (change)="switchPositionState($event)">Inaktive & Drafts anzeigen-->
<!--            </mat-slide-toggle>-->

            <mat-select [placeholder]="'Sortier Kriterium'" [formControl]="sortCriteriaControl">
                <mat-option [value]="'Performance'">Performance</mat-option>
                <mat-option [value]="'Seen'">Seen Count</mat-option>
                <mat-option [value]="'Cta'">Cta Count</mat-option>
            </mat-select>

            <mat-select [placeholder]="'Sortier-Typ'" [formControl]="sortTypeControl">
                <mat-option [value]="'Asc'">Asc</mat-option>
                <mat-option [value]="'Desc'">Desc</mat-option>
            </mat-select>

            <button mat-icon-button color="primary" matTooltip="Reload" (click)="load()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </header>

    <table *ngIf="!loading" mat-table [dataSource]="datasource" #dataTable class="w-100">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let row">
                {{row.id}}
            </td>
        </ng-container>

        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef>Performance</th>
            <td mat-cell *matCellDef="let row">
                <div style="padding: 0 8px">{{row.performance | number:'1.0-3'}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="seen">
            <th mat-header-cell *matHeaderCellDef>Seen</th>
            <td mat-cell *matCellDef="let row">
                <div style="padding: 0 8px">{{row.seen}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="cta">
            <th mat-header-cell *matHeaderCellDef>Cta</th>
            <td mat-cell *matCellDef="let row">
                <div style="padding: 0 8px">{{row.cta}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Stellentitel</th>
            <td mat-cell *matCellDef="let row">{{row.title}}</td>
        </ng-container>

        <ng-container matColumnDef="origin">
            <th mat-header-cell *matHeaderCellDef class="center">Origin</th>
            <td mat-cell *matCellDef="let row">
                {{row.origin | employmentPositionOrigin}}
            </td>
        </ng-container>

        <ng-container matColumnDef="profession">
            <th mat-header-cell *matHeaderCellDef>Beruf</th>
            <td mat-cell *matCellDef="let row">{{row.professionTitle}}</td>
        </ng-container>

        <ng-container matColumnDef="creation">
            <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
            <td mat-cell *matCellDef="let row">{{row.creationDate | date: 'dd.MM.yy'}}</td>
        </ng-container>

        <ng-container matColumnDef="fields">
            <th mat-header-cell *matHeaderCellDef class="center">Tätigkeitsfelder</th>
            <td mat-cell *matCellDef="let row" style="padding-bottom: 17px">
                <div fxLayout="row wrap" fxLayoutGap="8px">
                    <div
                            [class.fitting]="fr.qualification == 'Fitting'"
                            [class.normal]="fr.qualification == 'Normal'"
                            [class.excluded]="fr.qualification == 'Excluded'"
                            *ngFor="let fr of row.fieldOfActivityRelations" class="field-of-activity" (click)="setNextQualifiedState(row, fr)">{{fr.name}}</div>
                    <div fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z2 add-button" matRipple (click)="addFieldToPosition(row)"><i class="mdi mdi-plus"></i></div>
                </div>
            </td>
        </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="right">Aktionen</th>
                    <td mat-cell *matCellDef="let row">
                        <div class="table-button" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                            <button mat-button mat-icon-button color="primary"
                                    matTooltip="Stellendetails"
                                    (click)="openPositionDetailsDialog(row.id)">
                                <i class="mdi mdi-information dark"></i>
                            </button>
                        </div>
                    </td>
                </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</loading-spinner>