import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TermsAndConditionsFileData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminTermsAndConditionsController
 */

@Injectable()
@ResourceParams({})
export class AdminTermsAndConditionsResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/termsAndConditions/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllUploadedTermsAndConditions: IResourceMethodObservableStrict<null, null, null, TermsAndConditionsFileData[]> | undefined
    getAllUploadedTermsAndConditions(): Promise<TermsAndConditionsFileData[]> {
        if (!this._getAllUploadedTermsAndConditions) throw new Error("resource has not been properly initialized")
        return this._getAllUploadedTermsAndConditions(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/termsAndConditions/{id}/download',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUploadedTermsAndCondition: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getUploadedTermsAndCondition(id: number): Promise<Blob> {
        if (!this._getUploadedTermsAndCondition) throw new Error("resource has not been properly initialized")
        return this._getUploadedTermsAndCondition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admins/termsAndConditions/new',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _uploadNewTermsAndConditions: IResourceMethodObservableStrict<{fileName: string, file: File}, null, null, Blob> | undefined
    uploadNewTermsAndConditions(requestBody: {fileName: string, file: File}): Promise<Blob> {
        if (!this._uploadNewTermsAndConditions) throw new Error("resource has not been properly initialized")
        return this._uploadNewTermsAndConditions(requestBody, null, null).toPromise()
    }

}