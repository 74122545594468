import {IpLocationData} from "./ip-location-data"
import {OnboardingVersion} from "./onboarding-version"
import {LocationPreferenceMode} from "./location-preference-mode"

export class TalentCochingOnboardingCreationData {
    browser: string | null = null;
    browserLanguage: string | null = null;
    browserVersion: string | null = null;
    coachingFieldIds: number[] | null = null;
    consent_facebook_oc: boolean | null = null;
    consent_google_oc: boolean | null = null;
    deviceType: string | null = null;
    emailAddress: string = null;
    fbclid: string | null = null;
    firstName: string = null;
    forcedFieldsOfActivity: number[] | null = null;
    gclid: string | null = null;
    ipLocation = new IpLocationData();
    lastName: string | null = null;
    onboardingVersion: OnboardingVersion | null = null;
    password: string | null = null;
    phoneNumber: string | null = null;
    preferredCityId: number | null = null;
    preferredFieldIds: number[] | null = null;
    searchMode: LocationPreferenceMode | null = null;
    studyEndDate: (Date | null) = null;
    subjectId: number | null = null;
    utmCampaign: string | null = null;
    utmContent: string | null = null;
    utmMedium: string | null = null;
    utmSource: string | null = null;
    utmTerm: string | null = null;
}