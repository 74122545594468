import {Component, Input, OnInit} from '@angular/core';
import {AdminCompanyData, AdminSalesProposalData, DisplayRecruiterData} from "../../generated/data";
import {AccountResource, AdminRecruiterResource, AdminSalesProposalResource} from "../../generated/resources";
import {MatDialog} from "@angular/material/dialog";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {
    CreateRecruiterDialogComponent
} from "../company-recruiters/create-recruiter-dialog/create-recruiter-dialog.component";
import {
    EditRecruiterDialogComponent
} from "../company-recruiters/edit-recruiter-dialog/edit-recruiter-dialog.component";
import {ConfirmDialogComponent} from "../../utils/confirm-dialog/confirm-dialog.component";
import {
    CreateSalesProposalSideSheetComponent
} from "../create-sales-proposal-side-sheet/create-sales-proposal-side-sheet.component";
import {UtilityService} from "../../utils/utility.service";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'company-sales-proposals',
  templateUrl: './company-sales-proposals.component.html',
  styleUrls: ['./company-sales-proposals.component.scss']
})
export class CompanySalesProposalsComponent implements OnInit {

    @Input() companyId: number

    public loading: boolean = false;

    pageIndex: number = 0;
    pageSize: number = 30;
    totalSize: number = 0;
    totalPages: number = 0;
    pageSizeOptions: number[] = [15, 30, 60, 100];

    datasource: AdminSalesProposalData[] = []
    recruiters: DisplayRecruiterData[] = []


    states: ('Open' | 'Approved' | 'Denied' | 'Withdrawn')[] = ['Open', 'Approved', 'Denied', 'Withdrawn']
    selectedStates: ('Open' | 'Approved' | 'Denied' | 'Withdrawn')[] = ['Open']

    displayedColumns = [
        'name',
        'state',
        'size',
        'price',
        'discount',
        'totalPrice',
        'created',
        'updated',
        'actions'
    ];

    constructor(
        private adminSalesProposalResource: AdminSalesProposalResource,
        private dialogService: SideSheetService,
        private utilityService: UtilityService,
        private dialog: MatDialog,
        private adminRecruiterResource: AdminRecruiterResource
    ) {
    }

    ngOnInit(): void {
        this.getSalesProposals()
        this.adminRecruiterResource.getRecruitersByCustomerCompanyId(this.companyId, {showDeleted: false}).then(result  => {
                this.recruiters = result
            }
        )
    }

    createNewSalesProposal() {
        let comp = this.dialogService.openOverlay(CreateSalesProposalSideSheetComponent, 'Small')
        comp.instance.companyId = this.companyId
        comp.instance.showSelectCompany = false
        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
            if (res) {
                this.utilityService.showSnackBar("Angebot erfolgreich verschickt!")
                this.getSalesProposals()
            }
            subscription?.unsubscribe()
        })
    }

    getSalesProposals() {
        this.loading = true
        this.adminSalesProposalResource.getSalesProposals({
            companyId: this.companyId, states: this.selectedStates, pageSize: this.pageSize, pageNum: this.pageIndex
        }).then(result => {
                this.datasource = result.content
                this.totalSize = result.totalElements
                this.totalPages = result.totalPages
                this.loading = false
            }
        )
    }

    openConfirmationDialog(proposal: AdminSalesProposalData) {
        let dialogRef =
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: `Möchtest du das Angebot wirklick zurückziehen?`,
                    confirmButtonText: 'Zurückziehen',
                    title: 'Angebot zurückziehen',
                    onConfirm: () => {return new Promise((resolve => {resolve(true)}))}
                },
                panelClass: [''],
                maxWidth: 600
            })


        let sub = dialogRef.afterClosed().subscribe(next => {
            if (next) {
                this.withdrawSalesProposal(proposal)
            }
            sub?.unsubscribe()
        })
    }

    withdrawSalesProposal(proposal: AdminSalesProposalData) {
        this.adminSalesProposalResource.withdrawSalesProposal(proposal.id).then(result => {
            proposal.state = result
            this.utilityService.showSnackBar("Das Angebot wurde zurückgezogen")
        })
    }

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getSalesProposals();
    }
}
