import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {CompaniesComponent} from './companies/companies.component';
import {environment} from '../environments/environment';
import {JwtModule} from '@auth0/angular-jwt';
import {AdminComponent} from './admin.component';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_FORMATS, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TalentsComponent} from './talents/talents.component';
import {PositionsComponent} from './positions/positions.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CompanyDetailsComponent} from './companies/company-details/company-details.component';
import {
    SearchableCityDropdownComponent
} from './utils/dropdowns/searchable-city-dropdown/searchable-city-dropdown.component';
import {FileUploadDialogComponent} from './utils/file-upload-dialog/file-upload-dialog.component';
import {WorkTypePipe} from './utils/pipes/work-type.pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {
    SearchableAcademicTitleDropdownComponent
} from './utils/dropdowns/searchable-academic-title-dropdown/searchable-academic-title-dropdown.component';
import {
    SearchableStudyAreaDropdownComponent
} from './utils/dropdowns/searchable-study-area-dropdown/searchable-study-area-dropdown.component';
import {
    SearchableStudyFieldDropdownComponent
} from './utils/dropdowns/searchable-study-field-dropdown/searchable-study-field-dropdown.component';
import {
    SearchableStudySubjectDropdownComponent
} from './utils/dropdowns/searchable-study-subject-dropdown/searchable-study-subject-dropdown.component';
import {TalentQualityScorePipe} from './utils/pipes/talent-quality-score.pipe';
import {TalentMatchingScorePipe} from './utils/pipes/talent-matching-score.pipe';
import {ApplicationsComponent} from './applications/applications.component';
import {ShortDatePipe} from './utils/pipes/short-date.pipe';
import {JoinToStringPipe} from './utils/pipes/slice-list.pipe';
import {DefaultIfNullPipe} from './utils/pipes/default-if-null.pipe';
import {TalentNoteDialogComponent} from './talents/talent-note-dialog/talent-note-dialog.component';
import bugsnag from '@bugsnag/js';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {
    SearchableUniversityDropdownComponent
} from './utils/dropdowns/searchable-university-dropdown/searchable-university-dropdown.component';
import {QuillModule} from 'ngx-quill';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LoadingButtonComponent} from './utils/loading-button/loading-button.component';
import {LoadingSpinnerComponent} from './utils/loading-spinner/loading-spinner.component';
import {ConfirmDialogComponent} from './utils/confirm-dialog/confirm-dialog.component';
import {SliceStringLengthPipe} from './utils/pipes/slice-string-length.pipe';
import {ProfileImageService} from './services/profile-image.service';
import {SkillPipe} from './utils/pipes/skill.pipe';
import {
    AccountResource as UniSignAccountResource,
    AdminResource as UniSignAdminResource,
    PublicResource as UniSignPublicResource,
} from './generated/unisign/resources';
import {
    AccountResource,
    AdminAdminResource,
    AdminApplicationResource,
    AdminBookingResource,
    AdminCareerEventResource,
    AdminCompanyResource,
    AdminConsultantResource,
    AdminControllingResource,
    AdminEmploymentPositionQuestionResource,
    AdminEmploymentPositionResource,
    AdminEventResource,
    AdminFeedControlResource,
    AdminHiringResource,
    AdminInvoiceResource,
    AdminKomboResource,
    AdminMatchingCacheResource,
    AdminMatchingResource,
    AdminPositionContentSplitResource,
    AdminPositionProposalResource,
    AdminPricingResource,
    AdminPrimePositionRecommendationResource,
    AdminProfessionResource,
    AdminRecruiterResource,
    AdminResource, AdminSalesProposalResource,
    AdminSubjectResource,
    AdminTalentAgentLandingPageResource,
    AdminTalentPositionRelationResource,
    AdminTalentProfileResource,
    AdminTalentResource, AdminTermsAndConditionsResource,
    AdminTopJobResource,
    AdminWebinarResource,
    BenefitResource,
    CompanyApplicationResource,
    CompanyResource,
    DemoResource,
    LanguageResource,
    LocationResource,
    NotificationResource,
    PositionPerformanceResource,
    ProfessionFieldPreferenceResource,
    ProfessionMatchingOptimizingResource,
    ProposalStatisticsResource,
    PublicEventResource,
    PublicLandingPageResource,
    PublicSubjectResource,
    StudyInfoResource,
    TalentApplicationResource,
    TalentImageResource,
    TalentOnboardingResource,
    TalentTalentProfileResource,
    UniversityResource
} from './generated/resources';
import {EditImageDialogComponent} from './utils/edit-image-dialog/edit-image-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import {EmailAddressAvailabilityValidatorService} from './services/email-address-availability-validator.service';
import localeDe from '@angular/common/locales/de';
import {
    CreateRecruiterDialogComponent
} from './companies/company-recruiters/create-recruiter-dialog/create-recruiter-dialog.component';
import {
    EditRecruiterDialogComponent
} from './companies/company-recruiters/edit-recruiter-dialog/edit-recruiter-dialog.component';
import {RequestStatePipe} from './utils/pipes/request-state.pipe';
import {
    CompanyEmploymentPositionsComponent
} from './companies/company-employment-positions/company-employment-positions.component';
import {
    SearchableBenefitDropdownComponent
} from './utils/dropdowns/searchable-benefit-dropdown/searchable-benefit-dropdown.component';
import {
    MaterialAsyncDropdownComponent
} from './utils/dropdowns/material-async-dropdown/material-async-dropdown.component';
import {InnoUtilsModule} from './utils/inno-utils/inno-utils.module';
import {
    SearchableFederalSateDropdownComponent
} from './utils/dropdowns/searchable-federal-sate-dropdown/searchable-federal-sate-dropdown.component';
import {TableDownloadButtonComponent} from './utils/table-download-button/table-download-button.component';
import {AccountsComponent} from './accounts/accounts.component';
import {AccountCreateDialogComponent} from './accounts/account-create-dialog/account-create-dialog.component';
import {BooleanPipe} from './utils/pipes/boolean.pipe';
import {TextIfEmptyPipe} from './utils/pipes/text-if-empty.pipe';
import {InitialPipe} from './utils/pipes/initial-pipe';
import {BooleanIconComponent} from './utils/boolean-icon/boolean-icon.component';
import {
    DefaultTextIfEmptyOrNullComponent
} from './utils/default-text-if-empty-or-null/default-text-if-empty-or-null.component';
import {NewCompanyDialogComponent} from './companies/new-company-dialog/new-company-dialog.component';
import {EducationTypePipe} from './utils/pipes/education-type.pipe';
import {CareerEventPipe} from './utils/pipes/career-event.pipe';
import {CreatePositionDialogComponent} from './positions/create-position-dialog/create-position-dialog.component';
import {AuthGuardService} from './services/auth-guard.service';
import {
    ApplicationOverviewDialogComponent
} from './applications/application-overview-dialog/application-overview-dialog.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {TalentCreationStatsComponent} from './controlling/talent-creations-stats/talent-creation-stats.component';
import {ConsultantsComponent} from './consultants/consultants.component';
import {ConsultantEditComponent} from './consultants/consultant-edit/consultant-edit.component';
import {
    PositionPrivacyDisclaimerDialogComponent
} from './positions/position-privacy-disclaimer-dialog/position-privacy-disclaimer-dialog.component';
import {
    PositionApplicationsComponent
} from './positions/position-details-dialog/position-applications/position-applications.component';
import {
    IncreaseAssignableTalentAmountForConsultantDialogComponent
} from './consultants/increase-assignable-talent-amount-for-consultant-dialog/increase-assignable-talent-amount-for-consultant-dialog.component';
import {
    AllocatedTalentCreationsComponent
} from './controlling/talent-creations-stats/allocated-talent-creations/allocated-talent-creations.component';
import {CompanyRecruitersComponent} from './companies/company-recruiters/company-recruiters.component';
import {CompanyApplicationsComponent} from './companies/company-applications/company-applications.component';
import {LocationPreferencesModePipe} from './utils/pipes/location-preferences-mode.pipe';
import {
    SearchableProfessionDropdownComponent
} from './utils/dropdowns/searchable-profession-dropdown/searchable-profession-dropdown.component';
import {SideSheetModule} from './utils/side-sheet/side-sheet.module';
import {
    MatchingAnalyzeSheetComponentComponent
} from './matching/matching-optimization/matching-analyze-sheet-component/matching-analyze-sheet-component.component';
import {LandingPagesComponent} from './landing-pages/landing-pages.component';
import {BillingsComponent} from './billings/billings.component';
import {KeyAccounterDropdownComponent} from './utils/dropdowns/key-accounter-dropdown/key-accounter-dropdown.component';
import {
    SearchableCompanyDropdownComponent
} from './utils/dropdowns/searchable-company-dropdown/searchable-company-dropdown.component';
import {
    SearchableTalentAgentDropdownComponent
} from './utils/dropdowns/searchable-talent-agent-dropdown/searchable-talent-agent-dropdown.component';
import {SalesControllingComponent} from './controlling/sales-controlling/sales-controlling.component';
import {
    ApplicationProcessingControllingComponent
} from './controlling/sales-controlling/application-processing-controlling/application-processing-controlling.component';
import {
    ApplicationsSideSheetComponent
} from './controlling/sales-controlling/application-processing-controlling/applications-side-sheet/applications-side-sheet.component';
import {ApplicationStatePipe} from './utils/pipes/application-state.pipe';
import {
    PositionInformationComponent
} from './positions/position-details-dialog/position-information/position-information.component';
import {
    PositionContentComponent
} from './positions/position-details-dialog/position-content/position-content.component';
import {
    SearchablePositionDropdownComponent
} from "./utils/dropdowns/searchable-position-dropdown/searchable-position-dropdown.component";
import {ClickOutsideModule} from "ng-click-outside";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {
    AddLanguageSkillDialogComponent
} from "./talents/talent-profile/add-language-skill-dialog/add-language-skill-dialog.component";
import {
    CareerEventDetailsDialogComponent
} from "./talents/talent-profile/career-event-details-dialog/career-event-details-dialog.component";
import {TalentApplicationsComponent} from "./talents/talent-profile/talent-applications/talent-applications.component";
import {TalentProfileDialogComponent} from './talents/talent-profile-dialog/talent-profile-dialog.component';
import {PositionDetailsDialogComponent} from './positions/position-details-dialog/position-details-dialog.component';
import {LocationThresholdPipe} from './utils/pipes/location-threshold.pipe';
import {ProfessionGroupPreferencePipe} from './utils/pipes/profession-group-preference.pipe';
import {AuthService} from './services/auth.service';
import {AuthErrorHandlerInterceptor} from "./services/auth-error-handler-interceptor.service";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MappingExplorerComponent} from './matching/mapping-explorer/mapping-explorer.component';
import {SubjectsComponent} from './matching/mapping-explorer/subjects/subjects.component';
import {SubjectGroupsComponent} from './matching/mapping-explorer/subject-groups/subject-groups.component';
import {ProfessionsComponent} from './matching/mapping-explorer/professions/professions.component';
import {
    SearchableProfessionTypeDropdownComponent
} from './utils/dropdowns/searchable-profession-type-dropdown/searchable-profession-type-dropdown.component';
import {
    SearchableProfessionSubGroupDropdownComponent
} from './utils/dropdowns/searchable-profession-sub-group-dropdown/searchable-profession-sub-group-dropdown.component';
import {
    SearchableProfessionGroupDropdownComponent
} from './utils/dropdowns/searchable-profession-group-dropdown/searchable-profession-group-dropdown.component';
import {
    SearchableProfessionMainGroupDropdownComponent
} from './utils/dropdowns/searchable-profession-main-group-dropdown/searchable-profession-main-group-dropdown.component';
import {
    SearchableProfessionAreaDropdownComponent
} from './utils/dropdowns/searchable-profession-area-dropdown/searchable-profession-area-dropdown.component';
import {
    SubjectGroupDetailsSheetComponent
} from './matching/mapping-explorer/subject-group-details-sheet/subject-group-details-sheet.component';
import {
    SubjectDetailsSheetComponent
} from './matching/mapping-explorer/subject-details-sheet/subject-details-sheet.component';
import {
    ProfessionDetailsSheetComponent
} from './matching/mapping-explorer/profession-details-sheet/profession-details-sheet.component';
import {
    ProfessionTypeDetailsSheetComponent
} from './matching/mapping-explorer/profession-type-details-sheet/profession-type-details-sheet.component';
import {
    ProfessionSubGroupDetailsSheetComponent
} from './matching/mapping-explorer/profession-sub-group-details-sheet/profession-sub-group-details-sheet.component';
import {
    ProfessionGroupDetailsSheetComponent
} from './matching/mapping-explorer/profession-group-details-sheet/profession-group-details-sheet.component';
import {
    ProfessionMainGroupDetailsSheetComponent
} from './matching/mapping-explorer/profession-main-group-details-sheet/profession-main-group-details-sheet.component';
import {
    ProfessionAreaDetailsSheetComponent
} from './matching/mapping-explorer/profession-area-details-sheet/profession-area-details-sheet.component';
import {
    RemoveSubjectToGroupMappingPreviewSheetComponent
} from "./matching/mapping-explorer/remove-subject-to-group-mapping-preview-sheet/remove-subject-to-group-mapping-preview-sheet.component";
import {
    SubjectProfessionPathfinderComponent
} from './matching/mapping-explorer/subject-profession-pathfinder/subject-profession-pathfinder.component';
import {
    RemoveSubjectGroupToProfessionPreviewSheetComponent
} from './matching/mapping-explorer/remove-subject-group-to-profession-preview-sheet/remove-subject-group-to-profession-preview-sheet.component';
import {
    MapProfessionToSubjectGroupSheetComponent
} from './matching/mapping-explorer/map-profession-to-subject-group-sheet/map-profession-to-subject-group-sheet.component';
import {
    MapSubjectToSubjectGroupSheetComponent
} from "./matching/mapping-explorer/map-subject-to-subject-group-sheet/map-subject-to-subject-group-sheet.component";
import {
    SearchableSubjectDropdownComponent
} from "./utils/dropdowns/searchable-subject-dropdown/searchable-subject-dropdown.component";
import {
    ReplaceSubjectSheetComponent
} from "./matching/mapping-explorer/replace-subject-sheet/replace-subject-sheet.component";
import {
    SearchableSubjectGroupDropdownComponent
} from "./utils/dropdowns/searchable-subject-group-dropdown/searchable-subject-group-dropdown.component";
import {OnboardingCompletePipe} from './utils/pipes/onboarding-complete.pipe';
import {ConfirmSideSheetComponent} from './utils/confirm-side-sheet/confirm-side-sheet.component';
import {
    TalentFilterGroupComponent
} from "./positions/position-details-dialog/talent-filter-group/talent-filter-group.component";
import {
    TalentFilterComponent
} from "./positions/position-details-dialog/talent-filter-group/talent-filter/talent-filter.component";
import {
    TalentFilterResultListSheetComponent
} from './positions/position-details-dialog/talent-filter-group/talent-filter-result-list-sheet/talent-filter-result-list-sheet.component';
import {
    MarkPrimePositionAsUnsuitableDialogComponent
} from './talents/mark-prime-position-as-unsuitable-dialog/mark-prime-position-as-unsuitable-dialog.component';
import {CallOrderTypePipe} from './utils/pipes/call-order-type.pipe';
import {DateTimeSelectComponent} from "./utils/date-time-select/date-time-select.component";
import {PrimePositionMatchingModePipe} from "./utils/pipes/prime--position-matching-mode.pipe";
import {AccountEditSideSheetComponent} from './accounts/account-edit-side-sheet/account-edit-side-sheet.component';
import {
    ManualTalentFilterDialogComponent
} from './positions/position-details-dialog/position-proposals/manual-talent-filter-dialog/manual-talent-filter-dialog.component';
import {
    PrimeTalentFiltersComponent
} from './positions/position-details-dialog/prime-talent-filters/prime-talent-filters.component';
import {
    SpecialTargetingFiltersComponent
} from "./positions/position-details-dialog/special-targeting-filters/special-targeting-filters.component";
import {PlotlyViaCDNModule} from "angular-plotly.js";
import {
    RejectApplicationDialogComponent
} from './applications/reject-application-dialog/reject-application-dialog.component';
import {FileValueAccessorDirective} from "./utils/directives/file-value-accessor.directive";
import {CustomerApplicationsComponent} from './customer-service/customer-applications/customer-applications.component';
import {UserCommentComponent} from './customer-service/user-comment/user-comment.component';
import {
    ApplicationFailedReasonsSheetComponent
} from './customer-service/application-failed-reasons-sheet/application-failed-reasons-sheet.component';
import {
    ApplicantPersonaComponent
} from './positions/position-details-dialog/applicant-persona/applicant-persona.component';
import {EmploymentPositionOriginPipe} from './utils/pipes/employment-position-origin.pipe';
import {
    CreatePositionRecommendationsDialogComponent
} from "./talents/create-position-recommendations-dialog/create-position-recommendations-dialog.component";
import {RequestErrorInterceptorService} from "./services/request-error-interceptor.service";
import {ErrorSnackbarComponent} from './error-snackbar/error-snackbar.component';
import {TalentPositionRelationStatePipe} from "./utils/pipes/talent-position-relation-state.pipe";
import {TalentTargetingComponent} from './positions/talent-targeting/talent-targeting.component';
import {
    TalentFilterSheetComponent
} from "./positions/talent-targeting/talent-filter-sheet/talent-filter-sheet.component";
import {
    PositionRecommendationsComponent
} from "./positions/position-details-dialog/position-recommendations/position-recommendations.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ManualSubjectMappingComponent} from './matching/manual-subject-mapping/manual-subject-mapping.component';
import {
    SearchableDkzSubjectGroupDropdownComponent
} from "./utils/dropdowns/searchable-dkz-subject-group-dropdown/searchable-dkz-subject-group-dropdown.component";
import {
    DkzMappingSelectionDialogComponent
} from './matching/manual-subject-mapping/dkz-mapping-selection-dialog/dkz-mapping-selection-dialog.component';
import {
    PositionQuestionsComponent
} from './positions/position-details-dialog/position-questions/position-questions.component';
import {
    CreatePositionQuestionSideSheetComponent
} from './positions/position-details-dialog/position-questions/create-position-question-side-sheet/create-position-question-side-sheet.component';
import {
    ApplicationDetailsDialogComponent
} from './applications/application-details-dialog/application-details-dialog.component';
import {
    InformationPropertyComponent
} from "./applications/application-details-dialog/information-property/information-property.component";
import {ApplicationHistoryPipe} from './utils/pipes/application-history.pipe';
import {ApplicationStateComponent} from './utils/application-state/application-state.component';
import {
    DemandAdditionalApplicationDocsDialogComponent
} from './applications/demand-additional-application-docs-dialog/demand-additional-application-docs-dialog.component';
import {SafeHtmlPipe} from "./utils/pipes/safe-html.pipe";
import {FeedControlComponent} from './matching/feed-control/feed-control.component';
import {MatchingConfigReviewComponent} from './matching/matching-config-review/matching-config-review.component';
import {
    ProfessionSelectionSheetComponent
} from './matching/profession-selection-sheet/profession-selection-sheet.component';
import {
    MappingExplorerEntrySheetComponent
} from './matching/mapping-explorer-entry-sheet/mapping-explorer-entry-sheet.component';
import {MatChipsModule} from "@angular/material/chips";
import {
    PositionSubjectGroupPerformanceSheetComponent
} from './matching/position-subject-group-performance-sheet/position-subject-group-performance-sheet.component';
import {
    ConsultantHiringsDialogComponent
} from './consultants/consultant-hirings-dialog/consultant-hirings-dialog.component';
import {
    CreateApplicationDialogComponent
} from './applications/create-application-dialog/create-application-dialog.component';
import {
    SearchableCustomerPositionsDropdownComponent
} from './utils/dropdowns/searchable-customer-positions-dropdown/searchable-customer-positions-dropdown.component';
import {
    TalentPositionMatchSimulatorComponent
} from './matching/mapping-explorer/talent-position-match-simulator/talent-position-match-simulator.component';
import {
    PositionDetailsMatchingComponent
} from './positions/position-details-dialog/position-details-matching/position-details-matching.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {
    PositionContentControllingComponent
} from './controlling/sales-controlling/position-content-controlling/position-content-controlling.component';
import {
    CompleteCoachingDataSideSheetComponent
} from './talents/complete-coaching-data-side-sheet/complete-coaching-data-side-sheet.component';
import {
    ProfessionFieldsConfigDialogComponent
} from './matching/profession-fields-config-dialog/profession-fields-config-dialog.component';
import {
    ProfessionFieldConfigSheetComponent
} from "./matching/profession-fields-relations-table/profession-field-config-sheet.component";
import {FieldPositionRelationPipe} from './utils/pipes/field-position-relation.pipe';
import {EventComponent} from "./event/event/event.component";
import {
    CreateOrUpdateEventSheetComponent
} from "./event/create-or-update-event-sheet/create-or-update-event-sheet.component";
import {FieldQualificationComponent} from './matching/field-qualification/field-qualification.component';
import {
    PositionFieldQualificationEditorComponent
} from './matching/field-qualification/position-field-qualification-editor/position-field-qualification-editor.component';
import {
    ProfessionFieldQualificationEditorComponent
} from './matching/field-qualification/profession-field-qualification-editor/profession-field-qualification-editor.component';
import {
    AddActivityFieldSheetComponent
} from './matching/field-qualification/add-activity-field-sheet/add-activity-field-sheet.component';
import {UpdateEventFilterSheetComponent} from './event/update-event-filter-sheet/update-event-filter-sheet.component';
import {
    PreviewTalentsForEventFilterSheetComponent
} from './event/preview-talents-for-event-filter-sheet/preview-talents-for-event-filter-sheet.component';
import {
    ProfessionMatchingTypeSelectionComponent
} from './utils/profession-matching-type-selection/profession-matching-type-selection.component';
import {
    SearchableTalentDropdownComponent
} from "./utils/dropdowns/searchable-talent-dropdown/searchable-talent-dropdown.component";
import {
    MatchingAnalyzeComponentComponent
} from "./matching/matching-optimization/matching-analyze-component/matching-analyze-component.component";
import {
    ProfessionSubjectGroupRelationBoardComponent
} from "./matching/matching-optimization/professsion-subject-group-relation-board-component/profession-subject-group-relation-board.component";
import {InfoBoxComponent} from './utils/info-box/info-box.component';
import {CompanyTopjobsComponent} from './companies/company-topjobs/company-topjobs.component';
import {CompanyProfilesComponent} from './company-profiles/company-profiles.component';
import {PerfectMatchEventsComponent} from './event/perfect-match-events/perfect-match-events.component';
import {NormalMatchEventsComponent} from './event/normal-match-events/normal-match-events.component';
import {EditEventContentSheetComponent} from './event/edit-event-content-sheet/edit-event-content-sheet.component';
import {
    EditEventSubjectTargetingSheetComponent
} from './event/edit-event-subject-targeting-sheet/edit-event-subject-targeting-sheet.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {BookingsComponent} from './bookings/bookings.component';
import {
    PositionBookingsComponent
} from './positions/position-details-dialog/position-bookings/position-bookings.component';
import {
    CreateOrUpdateBookingSideSheetComponent
} from './bookings/create-booking-dialog/create-or-update-booking-side-sheet.component';
import {CompanyPricingComponent} from './companies/company-pricing/company-pricing.component';
import {CompanyPricingModePipe} from './utils/pipes/company-pricing-mode.pipe';
import {
    BillingsHiringDetailsDialogComponent
} from './billings/billings-hiring-details-dialog/billings-hiring-details-dialog.component';
import {CompanyPricingContentComponent} from './companies/company-pricing-content/company-pricing-content.component';
import {InvoicePipe} from './utils/pipes/invoice.pipe';
import {BookingStatePipe} from "./utils/pipes/booking-state.pipe";
import {WebinarComponent} from "./webinar/webinar/webinar.component";
import {
    CreateOrUpdateInvoiceSideSheetComponent
} from './invoices/create-or-update-invoice-side-sheet/create-or-update-invoice-side-sheet.component';
import {WebinarCreateDialogComponent} from './webinar/webinar-create-dialog/webinar-create-dialog.component';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule} from "@angular-material-components/datetime-picker";
import {DemoComponent} from './demo/demo.component';
import {HomeOfficePipe} from './utils/pipes/home-office.pipe';
import {
    SearchableBookingDropdownComponent
} from './utils/dropdowns/searchable-booking-dropdown/searchable-booking-dropdown.component';
import {InvoiceInformationComponent} from './invoices/invoice-information/invoice-information.component';
import {DialogDataDisplayComponent} from './utils/dialog-data-display/dialog-data-display.component';
import {CompanyAtsComponent} from "./companies/ats/company-ats/company-ats.component";
import {
    ImportAtsPositionsDialogComponent
} from './companies/ats/import-ats-positions-dialog/import-ats-positions-dialog.component';
import {
    SearchableCompanyByPricingmodeDropdownComponent
} from "./utils/dropdowns/searchable-booking-company-dropdown/searchable-company-by-pricingmode-dropdown.component";
import { MatchingHistoryComponent } from './matching/matching-history/matching-history.component';
import { BookingComponent } from './bookings/booking/booking.component';
import {BookingEventTypePipe} from "./utils/pipes/booking-event-type.pipe";
import { SalesProposalsComponent } from './sales-proposals/sales-proposals.component';
import { CreateSalesProposalSideSheetComponent } from './companies/create-sales-proposal-side-sheet/create-sales-proposal-side-sheet.component';
import { CompanySalesProposalsComponent } from './companies/company-sales-proposals/company-sales-proposals.component';
import { CreateSalesProposalComponent } from './companies/create-sales-proposal/create-sales-proposal.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CreateSalesProposalExternSideSheetComponent } from './sales-proposals/create-sales-proposal-side-sheet/create-sales-proposal-extern-side-sheet.component';
import {MatStepperModule} from '@angular/material/stepper';

PlotlyViaCDNModule.setPlotlyVersion('latest'); // can be `latest` or any version number (i.e.: '1.40.0')
PlotlyViaCDNModule.setPlotlyBundle(null); // optional: can be null (for full) or 'basic', 'cartesian', 'geo', 'gl3d', 'gl2d', 'mapbox' or 'finance'


registerLocaleData(localeDe);

export function errorHandlerFactory() {
    let releaseStage = 'production';
    if (!environment.production) {
        return new ErrorHandler()
    }
    return new BugsnagErrorHandler(bugsnag.start({
        apiKey: '6c7dadaf96e0ef7a65350a41de530075',
        releaseStage: releaseStage
    }));
}

export const APP_DATE_FORMATS =
    {
        parse: {
            dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
        },
        display: {
            dateInput: 'input',
            monthYearLabel: {year: 'numeric', month: 'numeric'},
            dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
            monthYearA11yLabel: {year: 'numeric', month: 'long'},
        }
    };

const appRoutes: Routes = [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent, data: {title: 'Admin Talentagent'}, canActivate: [AuthGuardService]},
    {path: 'webinar', component: WebinarComponent, data: {title: 'Webinare'}, canActivate: [AuthGuardService]},
    {path: 'accounts', component: AccountsComponent, data: {title: 'Zugänge'}, canActivate: [AuthGuardService]},
    {
        path: 'landingpages',
        component: LandingPagesComponent,
        data: {title: 'LandingPages'},
        canActivate: [AuthGuardService]
    },
    {path: 'companies', component: CompaniesComponent, data: {title: 'Firmen'}, canActivate: [AuthGuardService]},
    {path: 'talents', component: TalentsComponent, data: {title: 'Talente'}, canActivate: [AuthGuardService]},
    {
        path: 'applications',
        component: ApplicationsComponent,
        data: {title: 'Bewerbungen'},
        canActivate: [AuthGuardService]
    },
    {path: 'positions', component: PositionsComponent, data: {title: 'Stellen'}, canActivate: [AuthGuardService]},
    {path: 'salesProposals', component: SalesProposalsComponent, data: {title: 'Angebote'}, canActivate: [AuthGuardService]},
    {path: 'demo', component: DemoComponent, data: {title: 'Demosystem'}, canActivate: [AuthGuardService]},
    {path: 'positions/:positionId/targeting', component: TalentTargetingComponent, data: {title: 'Talent Targetierung'}, canActivate: [AuthGuardService]},
    {path: 'customers/applications', component: CustomerApplicationsComponent, data: {title: 'Kundenbewerbungen'}, canActivate: [AuthGuardService]},
    {path: 'companies/:id', component: CompanyDetailsComponent, canActivate: [AuthGuardService]},
    {path: 'companyProfiles', component: CompanyProfilesComponent, data: {title: 'Firmenprofile'}, canActivate: [AuthGuardService]},
    {path: 'events', component: EventComponent, canActivate: [AuthGuardService]},
    {path: 'fieldOfActivityEditor', component: FieldQualificationComponent, canActivate: [AuthGuardService]},
    {path: 'mapping-optimization', component: MappingExplorerComponent, canActivate: [AuthGuardService]},
    {path: 'subject-mapping', component: ManualSubjectMappingComponent, canActivate: [AuthGuardService]},
    {path: 'controlling/acquisition', component: TalentCreationStatsComponent, data: {title: 'Anmeldestatistik'}, canActivate: [AuthGuardService]},
    {path: 'controlling/sales', component: SalesControllingComponent, data: {title: 'Vertriebscontrolling'}, canActivate: [AuthGuardService]},
    {path: 'feedcontrol', component: FeedControlComponent, data: {title: 'Feedcontrol'}, canActivate: [AuthGuardService]},
    {path: 'matching/configreview', component: MatchingConfigReviewComponent, data: {title: 'Matchingconfig Review'}, canActivate: [AuthGuardService]},
    {path: 'hirings', component: BillingsComponent, data: {title: 'Einstellungen'}, canActivate: [AuthGuardService]},
    {path: 'bookings', component: BookingsComponent, data: {title: 'Buchungen'}, canActivate: [AuthGuardService]},
    {path: 'invoices', component: InvoicesComponent, data: {title: 'Abbrechnungsboard'}, canActivate: [AuthGuardService]},
    {path: 'consultants', component: ConsultantsComponent, data: {title: 'Beraterverwaltung'}, canActivate: [AuthGuardService]},
    {path: 'customers/agb', component: TermsAndConditionsComponent, data: {title: 'AGB Verwaltung'}, canActivate: [AuthGuardService]},
    {
        path: 'consultants/:consultantId',
        component: ConsultantEditComponent,
        data: {title: 'Beraterverwaltung'},
        canActivate: [AuthGuardService]
    },
];

export function tokenGetter() {
    return localStorage.getItem('access_token');
}


@NgModule({
    declarations: [
        FileValueAccessorDirective,
        InitialPipe,
        WorkTypePipe,
        SafeHtmlPipe,
        PrimePositionMatchingModePipe,
        TalentPositionRelationStatePipe,
        ShortDatePipe,
        JoinToStringPipe,
        DefaultIfNullPipe,
        SliceStringLengthPipe,
        LocationPreferencesModePipe,
        SkillPipe,
        BooleanPipe,
        TextIfEmptyPipe,
        AdminComponent,
        LoginComponent,
        CompaniesComponent,
        TalentsComponent,
        PositionsComponent,
        NewCompanyDialogComponent,
        CompanyDetailsComponent,
        SearchableCityDropdownComponent,
        SearchableFederalSateDropdownComponent,
        FileUploadDialogComponent,
        FileUploadDialogComponent,
        SearchableAcademicTitleDropdownComponent,
        SearchableStudyAreaDropdownComponent,
        SearchableStudyFieldDropdownComponent,
        SearchableStudySubjectDropdownComponent,
        SearchablePositionDropdownComponent,
        SearchableTalentDropdownComponent,
        PositionApplicationsComponent,
        TalentQualityScorePipe,
        TalentMatchingScorePipe,
        ApplicationsComponent,
        TalentNoteDialogComponent,
        SearchableUniversityDropdownComponent,
        LoadingButtonComponent,
        LoadingSpinnerComponent,
        ConfirmDialogComponent,
        EditImageDialogComponent,
        CreateRecruiterDialogComponent,
        EditRecruiterDialogComponent,
        RequestStatePipe,
        TalentApplicationsComponent,
        CompanyEmploymentPositionsComponent,
        SearchableBenefitDropdownComponent,
        MaterialAsyncDropdownComponent,
        TableDownloadButtonComponent,
        BooleanIconComponent,
        DefaultTextIfEmptyOrNullComponent,
        TableDownloadButtonComponent,
        AccountsComponent,
        WebinarComponent,
        AccountCreateDialogComponent,
        DefaultTextIfEmptyOrNullComponent,
        EducationTypePipe,
        CareerEventPipe,
        AddLanguageSkillDialogComponent,
        CreatePositionDialogComponent,
        EducationTypePipe,
        TalentCreationStatsComponent,
        ApplicationOverviewDialogComponent,
        ConsultantsComponent,
        ConsultantEditComponent,
        PositionPrivacyDisclaimerDialogComponent,
        IncreaseAssignableTalentAmountForConsultantDialogComponent,
        CompanyRecruitersComponent,
        CompanyApplicationsComponent,
        AllocatedTalentCreationsComponent,
        CareerEventDetailsDialogComponent,
        SearchableProfessionDropdownComponent,
        MatchingAnalyzeSheetComponentComponent,
        MatchingAnalyzeComponentComponent,
        ProfessionSubjectGroupRelationBoardComponent,
        LandingPagesComponent,
        BillingsComponent,
        KeyAccounterDropdownComponent,
        SearchableCompanyDropdownComponent,
        SearchableCompanyByPricingmodeDropdownComponent,
        SearchableTalentAgentDropdownComponent,
        SalesControllingComponent,
        ApplicationProcessingControllingComponent,
        ApplicationsSideSheetComponent,
        ApplicationStatePipe,
        PositionInformationComponent,
        PositionContentComponent,
        TalentProfileDialogComponent,
        PositionDetailsDialogComponent,
        CreatePositionRecommendationsDialogComponent,
        LocationThresholdPipe,
        ProfessionGroupPreferencePipe,
        MapSubjectToSubjectGroupSheetComponent,
        SearchableSubjectGroupDropdownComponent,
        ReplaceSubjectSheetComponent,
        SearchableSubjectDropdownComponent,
        MappingExplorerComponent,
        SubjectsComponent,
        SubjectGroupsComponent,
        ProfessionsComponent,
        SearchableProfessionTypeDropdownComponent,
        SearchableProfessionSubGroupDropdownComponent,
        SearchableProfessionGroupDropdownComponent,
        SearchableProfessionMainGroupDropdownComponent,
        SearchableProfessionAreaDropdownComponent,
        SubjectGroupDetailsSheetComponent,
        SubjectDetailsSheetComponent,
        ProfessionDetailsSheetComponent,
        ProfessionTypeDetailsSheetComponent,
        ProfessionSubGroupDetailsSheetComponent,
        ProfessionGroupDetailsSheetComponent,
        ProfessionMainGroupDetailsSheetComponent,
        ProfessionAreaDetailsSheetComponent,
        SubjectGroupDetailsSheetComponent,
        RemoveSubjectToGroupMappingPreviewSheetComponent,
        SubjectProfessionPathfinderComponent,
        RemoveSubjectGroupToProfessionPreviewSheetComponent,
        MapProfessionToSubjectGroupSheetComponent,
        OnboardingCompletePipe,
        OnboardingCompletePipe,
        MapProfessionToSubjectGroupSheetComponent,
        ConfirmSideSheetComponent,
        TalentFilterGroupComponent,
        TalentFilterComponent,
        TalentFilterResultListSheetComponent,
        CreatePositionRecommendationsDialogComponent,
        MarkPrimePositionAsUnsuitableDialogComponent,
        CallOrderTypePipe,
        DateTimeSelectComponent,
        AccountEditSideSheetComponent,
        ManualTalentFilterDialogComponent,
        PrimeTalentFiltersComponent,
        SpecialTargetingFiltersComponent,
        RejectApplicationDialogComponent,
        CustomerApplicationsComponent,
        UserCommentComponent,
        ApplicationFailedReasonsSheetComponent,
        ApplicantPersonaComponent,
        EmploymentPositionOriginPipe,
        ErrorSnackbarComponent,
        PositionRecommendationsComponent,
        TalentTargetingComponent,
        TalentFilterSheetComponent,
        ManualSubjectMappingComponent,
        SearchableDkzSubjectGroupDropdownComponent,
        DkzMappingSelectionDialogComponent,
        PositionQuestionsComponent,
        CreatePositionQuestionSideSheetComponent,
        ApplicationDetailsDialogComponent,
        InformationPropertyComponent,
        ApplicationHistoryPipe,
        BookingEventTypePipe,
        ApplicationStateComponent,
        DemandAdditionalApplicationDocsDialogComponent,
        FeedControlComponent,
        MatchingConfigReviewComponent,
        MatchingConfigReviewComponent,
        ProfessionSelectionSheetComponent,
        MappingExplorerEntrySheetComponent,
        PositionSubjectGroupPerformanceSheetComponent,
        ConsultantHiringsDialogComponent,
        CreateApplicationDialogComponent,
        SearchableCustomerPositionsDropdownComponent,
        TalentPositionMatchSimulatorComponent,
        PositionDetailsMatchingComponent,
        ProfessionFieldConfigSheetComponent,
        PositionContentControllingComponent,
        CompleteCoachingDataSideSheetComponent,
        ProfessionFieldsConfigDialogComponent,
        FieldPositionRelationPipe,
        CreateOrUpdateEventSheetComponent,
        EventComponent,
        FieldQualificationComponent,
        PositionFieldQualificationEditorComponent,
        ProfessionFieldQualificationEditorComponent,
        AddActivityFieldSheetComponent,
        UpdateEventFilterSheetComponent,
        PreviewTalentsForEventFilterSheetComponent,
        ProfessionMatchingTypeSelectionComponent,
        InfoBoxComponent,
        CompanyTopjobsComponent,
        CompanyAtsComponent,
        CompanyProfilesComponent,
        PerfectMatchEventsComponent,
        NormalMatchEventsComponent,
        EditEventContentSheetComponent,
        EditEventSubjectTargetingSheetComponent,
        InvoicesComponent,
        BookingsComponent,
        PositionBookingsComponent,
        CreateOrUpdateBookingSideSheetComponent,
        CompanyPricingComponent,
        CompanyPricingModePipe,
        BillingsHiringDetailsDialogComponent,
        CompanyPricingContentComponent,
        InvoicePipe,
        BookingStatePipe,
        CreateOrUpdateInvoiceSideSheetComponent,
        WebinarCreateDialogComponent,
        HomeOfficePipe,
        DemoComponent,
        SearchableBookingDropdownComponent,
        InvoiceInformationComponent,
        DialogDataDisplayComponent,
        ImportAtsPositionsDialogComponent,
        MatchingHistoryComponent,
        BookingComponent,
        SalesProposalsComponent,
        CreateSalesProposalSideSheetComponent,
        CompanySalesProposalsComponent,
        CreateSalesProposalComponent,
        TermsAndConditionsComponent,
        CreateSalesProposalExternSideSheetComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        SideSheetModule,
        FlexLayoutModule,
        MatMenuModule,
        MatListModule,
        MatButtonToggleModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatChipsModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatInputModule,
        MatButtonModule,
        ImageCropperModule,
        InnoUtilsModule,
        MatSelectModule,
        HttpClientModule,
        MatNativeDateModule,
        MatChipsModule,
        MatSortModule,
        MatExpansionModule,
        MatDialogModule,
        MatInputModule,
        ClickOutsideModule,
        HttpClientModule,
        AngularEditorModule,
        DragDropModule,
        NgxMatSelectSearchModule,
        MatTabsModule,
        CodemirrorModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        ResourceModule.forRoot(),
        QuillModule.forRoot(),
        RouterModule.forRoot(appRoutes),
        JwtModule.forRoot({ // https://github.com/auth0/angular2-jwt
                config: {
                    tokenGetter: tokenGetter,
                    allowedDomains: [environment.apiHost, environment.uniSignHost],
                    disallowedRoutes: [environment.uniSignUrl + '/oauth2/token']
                }
            }
        ),
        MatCardModule,
        MatBadgeModule,
        MatIconModule,
        NgxExtendedPdfViewerModule,
        PlotlyViaCDNModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        MatStepperModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestErrorInterceptorService,
            multi: true,
        },
        FileValueAccessorDirective,
        AuthGuardService,
            {
                provide: 'env', // you can also use InjectionToken
                useValue: environment
            },
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: ErrorHandler, useFactory: errorHandlerFactory},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        ProfileImageService,
        AdminComponent,
        EventComponent,
        CreateOrUpdateEventSheetComponent,
        AdminApplicationResource,
        AdminMatchingResource,
        CompanyApplicationResource,
        AdminFeedControlResource,
        AuthService,
        EmailAddressAvailabilityValidatorService,
        RequestErrorInterceptorService,
        CompanyResource,
        AdminKomboResource,
        DatePipe,
        EducationTypePipe,
        LanguageResource,
        ProfessionMatchingOptimizingResource,
        UniversityResource,
        SliceStringLengthPipe,
        TalentPositionRelationStatePipe,
        TalentOnboardingResource,
        TalentTalentProfileResource,
        TalentImageResource,
        StudyInfoResource,
        LocationResource,
        LanguageResource,
        TalentApplicationResource,
        AccountResource,
        AdminControllingResource,
        AdminApplicationResource,
        NotificationResource,
        AdminResource,
        AdminTalentResource,
        AdminCareerEventResource,
        AdminTalentProfileResource,
        AdminEmploymentPositionResource,
        AdminCompanyResource,
        AdminRecruiterResource,
        AdminAdminResource,
        BenefitResource,
        PublicEventResource,
        TalentMatchingScorePipe,
        CareerEventPipe,
        AdminConsultantResource,
        PublicLandingPageResource,
        AdminTalentAgentLandingPageResource,
        AdminProfessionResource,
        AdminHiringResource,
        AdminCompanyResource,
        ProfessionGroupPreferencePipe,
        AdminPositionProposalResource,
        AdminSubjectResource,
        AdminWebinarResource,
        AdminPrimePositionRecommendationResource,
        AdminEventResource,
        ProposalStatisticsResource,
        AdminTalentPositionRelationResource,
        AdminEmploymentPositionQuestionResource,
        PublicSubjectResource,
        ProfessionFieldPreferenceResource,
        ProfessionFieldPreferenceResource,
        AdminMatchingCacheResource,
        AdminPositionContentSplitResource,
        PositionPerformanceResource,
        AdminTopJobResource,
        AdminInvoiceResource,
        AdminBookingResource,
        AdminPricingResource,
        UniSignAccountResource,
        UniSignPublicResource,
        UniSignAdminResource,
        DemoResource,
        AdminSalesProposalResource,
        AdminTermsAndConditionsResource
    ],
    bootstrap: [AdminComponent],
    exports: [
        LoadingButtonComponent,
        WorkTypePipe,
        ApplicationStatePipe,
        ShortDatePipe,
        BookingStatePipe,
        HomeOfficePipe
    ]
})
export class AdminModule {
}
