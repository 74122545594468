import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callOrderType'
})
export class CallOrderTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'FirstContact':
        return 'Erstkontakt';
      case 'FollowUp':
        return 'Wiedervorlage';
      case 'Appointment':
        return 'Termin';
      case 'Custom':
        return 'Sonstiges';
      default:
        return value
    }
  }
}
