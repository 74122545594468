export class CompanyTalentEventData {
    emailAddress: string | null = null;
    firstName: string | null = null;
    id: number = null;
    lastName: string | null = null;
    locationName: string | null = null;
    phoneNumber: string | null = null;
    studyEndYear: number | null = null;
    subjectName: string | null = null;
    talentId: number | null = null;
}