import { Component, OnInit } from '@angular/core';
import {ProfessionMatchingOptimizingResource} from "../../../generated/resources";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {FormControl} from "@angular/forms";
import {SubjectLastOccurredData} from "../../../generated/data";

@Component({
  selector: 'app-replace-subject-sheet',
  templateUrl: './replace-subject-sheet.component.html',
  styleUrls: ['./replace-subject-sheet.component.scss']
})
export class ReplaceSubjectSheetComponent implements OnInit {

  public subjectId: number
  public subjectTitle: string
  public loading: boolean = false
  public subjectControl: FormControl = new FormControl()

  constructor(
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
  }

  replaceSubject(): void {
    this.professionMatchingOptimizingResource.replaceSubjectWithAnotherSubject(
        {oldSubjectId: this.subjectId, newSubjectId: this.subjectControl.value}
    ).then(() => this.sideSheetRef.sheetClosed.next(true)
    )
  }
}
