import {ApiCustomerReferenceKey} from "./api-customer-reference-key"
import {IpLocationData} from "./ip-location-data"
import {OnboardingVersion} from "./onboarding-version"
import {LocationPreferenceMode} from "./location-preference-mode"
import {StudiesCreationData} from "./studies-creation-data"

export class TalentCreationData {
    apiReferenceKey: ApiCustomerReferenceKey | null = null;
    browser: string | null = null;
    browserLanguage: string | null = null;
    browserVersion: string | null = null;
    cityIds: number[] | null = null;
    consent_facebook_oc: boolean | null = null;
    consent_google_oc: boolean | null = null;
    deviceType: string | null = null;
    emailAddress: string = null;
    fbclid: string | null = null;
    firstName: string = null;
    forcedFieldsOfActivity: number[] | null = null;
    gclid: string | null = null;
    invitedFromToken: string | null = null;
    ipLocation = new IpLocationData();
    lastName: string | null = null;
    markedAsInterestingFieldIds: number[] | null = null;
    onboardingVersion: OnboardingVersion | null = null;
    password: string | null = null;
    phoneNumber: string | null = null;
    positionId: number | null = null;
    searchMode: LocationPreferenceMode | null = null;
    studiesCreationData: StudiesCreationData | null = null;
    utmCampaign: string | null = null;
    utmContent: string | null = null;
    utmMedium: string | null = null;
    utmSource: string | null = null;
    utmTerm: string | null = null;
}