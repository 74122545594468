<div fxLayout="column" fxLayoutAlign="start start" class="custom-information">
    <div class="title">{{ label }}</div>
    <div class="value-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="value" (click)="copyValueToClipboard()"
             [ngClass]="{ 'no-recipient': !data || data === '' }">
            {{ data && data !== '' ? data : alternative }}
        </div>
        <div class="relative">
            <button mat-icon-button *ngIf="onButtonClick && data && data != ''" (click)="onButtonClick()" class="i-button">
                <i class="mdi mdi-information-outline"></i>
            </button>
        </div>

    </div>
</div>
