<dialog-header dialogHeadline="{{professionTitle}}"></dialog-header>
<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">
        <div *ngIf="professionData" class="width-100" fxLayout="column" fxLayoutGap="4px">
            <small><b>ID:</b> <span class="copy"
                                    (click)="utilityService.showSnackBar('ID kopiert')"> {{professionData.id}}</span></small>
            <small><b>DKZ-Kennung:</b> <span class="copy"
                                             (click)="utilityService.showSnackBar('DKZ-Kennung kopiert')"> {{professionData.agencyKey}}</span></small>

            <small><b>Specialized: </b>
                <mat-slide-toggle [(ngModel)]="professionData.specialized"
                                  (click)="updateSpecializedFlag()"></mat-slide-toggle>
            </small>

            <small>
                <b>Blacklisted: </b>
                <mat-slide-toggle [(ngModel)]="professionData.blacklisted"
                                  (click)="blacklistProfession()"></mat-slide-toggle>
            </small>
            <br>
            <small><b>ProfessionType:</b> <span class="clickable"
                                                (click)="openProfessionTypeDetailsSheet()"> {{professionData.typeTitle}}</span></small>
            <small><b>ProfessionSubGroup:</b> <span class="clickable"
                                                    (click)="openProfessionSubGroupDetailsSheet()"> {{professionData.subGroupTitle}}</span></small>
            <small><b>ProfessionGroup:</b> <span class="clickable"
                                                 (click)="openProfessionGroupDetailsSheet()"> {{professionData.groupTitle}}</span></small>
            <small><b>ProfessionMainGroup:</b> <span class="clickable"
                                                     (click)="openProfessionMainGroupDetailsSheet()"> {{professionData.mainGroupTitle}}</span></small>
            <small><b>ProfessionArea:</b> <span class="clickable"
                                                (click)="openProfessionAreaDetailsSheet()"> {{professionData.areaTitle}}</span></small>
        </div>

        <mat-tab-group>

            <mat-tab label="Matching SubjectGroups">
                <div fxLayout="row" fxLayoutAlign="end center">
                    <loading-button buttonClass="mat-primary"
                                    [loadingBool]="saving"
                                    matTooltip="Speichern"
                                    (clickEvent)="saveProfessionSubjectGroupMapping()">
                        <i class="mdi mdi-content-save main-action-icon"></i>
                    </loading-button>
                </div>
                <table mat-table [dataSource]="professionMatchingTypeSubjectGroupData" class="w-100">

                    <ng-container matColumnDef="groupName">
                        <th mat-header-cell *matHeaderCellDef>Studiengruppe</th>
                        <td mat-cell *matCellDef="let element">{{element.subjectGroup.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="amountOfCurrentTalents">
                        <th mat-header-cell *matHeaderCellDef>Talente verfügbar</th>
                        <td mat-cell *matCellDef="let element">{{element.amountOfCurrentTalents}}</td>
                    </ng-container>

                    <ng-container matColumnDef="matchingType">
                        <th mat-header-cell *matHeaderCellDef>MatchingType</th>
                        <td mat-cell *matCellDef="let element">
                            <profession-matching-type-selection
                                    [matchingType]="element.matchingScore"
                                    (matchingTypeChange)="updateMatchingType(element, $event)"
                            ></profession-matching-type-selection>
                        </td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="['groupName', 'amountOfCurrentTalents','matchingType']"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: ['groupName', 'amountOfCurrentTalents','matchingType']"></tr>
                </table>
            </mat-tab>


            <mat-tab label="Gemappte SubjectGroups" class="w-100">
                <ng-template matTabContent>
                    <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field>
                            <input matInput (keyup)="applySubjectGroupFilter($event.target.value)" placeholder="Suche">
                        </mat-form-field>

                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Mapping hinzufügen" (click)="addSubjectGroupMapping()">
                            <i class="mdi mdi-plus mdi-24px"></i>
                        </button>
                    </div>
                    <loading-button color="primary" [loadingBool]="loadDeleteGroupMappings"
                                    (clickEvent)="deleteSelectedSubjectGroupMappings()">{{selection.selected.length}}
                        Subject-Mappings löschen
                    </loading-button>


                    <table mat-table [dataSource]="subjectGroupDataSource" class="w-100">

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row) : null"
                                              [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="subjectGroupTitle">
                            <th mat-header-cell *matHeaderCellDef>SubjectGroup</th>
                            <td mat-cell *matCellDef="let subjectGroup">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openSubjectGroupDetailsSheet(subjectGroup)">
                                {{subjectGroup.title}}
                            </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="numProfessions">
                            <th mat-header-cell *matHeaderCellDef>Professions</th>
                            <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numProfessions}}</td>
                        </ng-container>

                        <ng-container matColumnDef="numSubjects">
                            <th mat-header-cell *matHeaderCellDef>Subjects</th>
                            <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numSubjects}}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                            <td mat-cell *matCellDef="let subjectGroup">
                                <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
<!--                                    <button mat-button mat-icon-button color="primary"-->
<!--                                            matTooltip="Mapping entfernen"-->
<!--                                            (click)="deleteSubjectGroupMapping(subjectGroup)">-->
<!--                                        <i class="mdi mdi-trash-can mdi-24px"></i>-->
<!--                                    </button>-->
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedSubjectGroupColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedSubjectGroupColumns;"></tr>
                    </table>
                </ng-template>
            </mat-tab>

            <mat-tab label="Gemappte Subjects">
                <mat-form-field>
                    <input matInput (keyup)="applySubjectFilter($event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="subjectDataSource" class="w-100">
                    <ng-container matColumnDef="subjectTitle">
                        <th mat-header-cell *matHeaderCellDef>Subject</th>
                        <td mat-cell *matCellDef="let subject">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openSubjectDetailsSheet(subject)">
                                {{subject.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjectGroups">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                        <td mat-cell *matCellDef="let subject">{{subject.numSubjectGroups}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numProfessions">
                        <th mat-header-cell *matHeaderCellDef>Professions</th>
                        <td mat-cell *matCellDef="let subject">{{subject.numProfessions}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedSubjectColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedSubjectColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="Tätigkeitsfelder">


                <table mat-table [dataSource]="fields" class="w-100">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Tätigkeitsfeld</th>
                        <td mat-cell *matCellDef="let field">
                            {{field.name}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="['name']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['name']"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>

    </div>
</loading-spinner>
