import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {MediaMatcher} from '@angular/cdk/layout';
import {UtilityService} from './utils/utility.service';
import {Title} from '@angular/platform-browser';
import {AdminEmploymentPositionResource, AdminTalentResource} from './generated/resources';
import {AuthService} from "./services/auth.service";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {AdminTalentData} from "./generated/data";
import {TalentProfileDialogComponent} from "./talents/talent-profile-dialog/talent-profile-dialog.component";
import {SideSheetService} from "./utils/side-sheet/side-sheet.service";

@Component({
  selector: 'app-root',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('snav') sideNav: MatSidenav;
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.dialogService.openedSideSheets.length > 0) {
      this.dialogService.getLastSheet().sideSheetRef.sheetClosed.next()
    }
  }


  mobileQuery: MediaQueryList;
  navOpen: boolean = true;
  togglePositionNavTabs: boolean;
  adminId;

  public showSearchResults: boolean = false;
  public inputFocused: boolean = false;
  public showNoItemInfo: boolean = false;
  public talentControl: FormControl = new FormControl();
  public talentsData: AdminTalentData[] = [];
  public loadingTalents: boolean = false;
  public page: number = 0;

  private _mobileQueryListener: () => void;

  constructor(
      public authService: AuthService,
      public router: Router,
      public adminTalentResource: AdminTalentResource,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private utils: UtilityService,
      private activatedRoute: ActivatedRoute,
      private titleService: Title,
      changeDetectorRef: ChangeDetectorRef,
      media: MediaMatcher,
      private dialogService: SideSheetService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1180px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.togglePositionNavTabs = false;

    this.adminId = this.authService.getAccountInfo().id;

    this.talentControl.valueChanges
        .subscribe(() => {
          if (this.talentControl.value.toString().length < 1) {
            this.showNoItemInfo = false;
            this.talentsData = [];
          }

          this.loadingTalents = this.talentControl.value.toString().length > 0;
        });

    this.talentControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(next => {
          if (this.talentControl.value.toString().length > 0) {
            this.loadingTalents = true;
            this.page = 0;
            this.getTalentsByQuery();
          }
        });
  }

  getTalentsByQuery() {
    this.adminTalentResource.getTalentsByQuery({q: this.talentControl.value, page: this.page}).then(result => {
      this.showNoItemInfo = result.content.length < 1;
      this.showSearchResults = true;
      this.talentsData = result.content;
      this.loadingTalents = false;
    });
  }

  resetSearchInput() {
    this.talentControl.setValue('')
    this.searchInput.nativeElement.focus()
  }

  endFocus() {
    setTimeout(() => {
      this.inputFocused = false
    }, 400)
  }

  logout() {
    this.authService.logout();
    localStorage.setItem("loggedOut", "true");
    this.router.navigateByUrl('/login');
    this.titleService.setTitle(event['title'])
  }

  getAccounterLink() {
    if (this.authService.isAuthenticated() && this.authService.getAccountInfo() != null)
      return `accounts/${this.authService.getAccountInfo().id}`;
    else
      return "";
  }

  getImageSource() {
    return '../assets/logo-talentagent.png';
  }

  setTabTitle(title: string) {
    this.titleService.setTitle(title);
  }

  openTalentProfile(talentId: number) {
    let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = talentId
  }
}
