import {Component, Inject, OnInit} from '@angular/core';
import {AdminTalentProfileResource, LanguageResource} from '../../../generated/resources';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LanguageStateData} from '../../../generated/data';

@Component({
  selector: 'app-add-language-skill-dialog',
  templateUrl: './add-language-skill-dialog.component.html',
  styleUrls: ['./add-language-skill-dialog.component.scss']
})
export class AddLanguageSkillDialogComponent implements OnInit {

  languageData: LanguageStateData = new LanguageStateData();
  saving: Boolean = false;
  loading: Boolean = false;

  currentLevel: string = 'E';
  currentLanguageId: number = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddLanguageSkillDialogComponent>,
    private adminTalentProfileResource: AdminTalentProfileResource,
    public languageResource: LanguageResource,
  ) {
  }

  ngOnInit() {
  }

  save() {
    this.saving = true;

    this.languageData.languageId = this.currentLanguageId;
    this.languageData.level = this.currentLevel;
    this.languageData.languageName = '';

    this.adminTalentProfileResource.createOrUpdateLanguageState(this.languageData, this.data.id).then(() => {
      this.saving = false;
      this.dialogRef.close(true);
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  getStarColor(level, starNumber) {
    switch (level) {
      case 'A':
        return '#5fc3f7';
      case 'B':
        if (starNumber <= 3) {
          return '#5fc3f7';
        } else return '#e8e8e8';
      case 'C':
        if (starNumber <= 2) {
          return '#5fc3f7';
        } else return '#e8e8e8';
      case 'D':
        if (starNumber <= 1) {
          return '#5fc3f7';
        } else return '#e8e8e8';
      case 'E':
        if (starNumber == 0) {
          return '#5fc3f7';
        } else return '#e8e8e8';
    }
  }

  setLevel(starNumber) {
    switch (starNumber) {
      case 0:
        this.currentLevel = 'E';
        break;
      case 1:
        this.currentLevel = 'D';
        break;
      case 2:
        this.currentLevel = 'C';
        break;
      case 3:
        this.currentLevel = 'B';
        break;
      case 4:
        this.currentLevel = 'A';
        break;
    }
  }

}
