<dialog-header [dialogHeadline]="'Berufs-Explorer'"></dialog-header>

<div class="dialog-main-content">
<table mat-table [dataSource]="professionAreas" class="max-table" #table>

    <ng-container matColumnDef="areaTitle">
        <th mat-header-cell *matHeaderCellDef>Alle Berufsbereiche</th>
        <td mat-cell *matCellDef="let element" (click)="openArea(element)">
            <span class="clickable">{{element.name}}</span>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="['areaTitle']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['areaTitle'];"></tr>
</table>
</div>

