import {Gender} from "./gender"
import {TalentLocationPreferenceData} from "./talent-location-preference-data"
import {StudiesData} from "./studies-data"

export class CompanyTalentData {
    campaign: string | null = null;
    emailAddress: string = null;
    firstName: string = null;
    gender: Gender | null = null;
    hasCV: boolean = null;
    id: number = null;
    lastName: string | null = null;
    locationPreference = new TalentLocationPreferenceData();
    mostRecentStudies: StudiesData | null = null;
    phoneNumber: string | null = null;
    qualityScore: number | null = null;
}