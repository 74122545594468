<dialog-header [dialogHeadline]="'Matching-Config History'"></dialog-header>

<div class="log-wrapper">
    <h1>Matching History</h1>
    <loading-spinner [loadingBool]="loading">

        <div *ngFor="let group of groupedLogs" class="year-group">
            <h2 class="year-title">{{ group.year }}</h2>

            <div *ngFor="let log of group.entries" class="log-entry">


                <!-- Typ 1: Profession Changes -->
                <div *ngIf="log.newProfessionTitle" class="log-item">
                    <mat-card class="profession-card">
                        <span class="panel-title">
                          {{ formatDate(log.occurred) }}
                        </span>
                        <span class="panel-title new-profession">{{ "+ " + log.newProfessionTitle }}</span>
                        <span class="panel-title old-profession" *ngIf="log.oldProfessionTitle">
                          {{ "- " + log.oldProfessionTitle }}
                        </span>
                    </mat-card>
                </div>



                <!-- Typ 2: Field of Activity Details -->
                <div *ngIf="log.fieldOfActivityDetails && !log.primeFilter" class="log-item no-padding">
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                {{ formatDate(log.occurred) }} Tätigkeitsfelder
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-list class="custom-list">
                            <mat-list-item *ngFor="let activity of log.fieldOfActivityDetails">
                                <div class="activity-content">
                                    {{ activity.title }}
                                    <span class="qualification">{{ activity.qualification | fieldPositionRelation }}</span>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>
                </div>



                <!-- Typ 3: Prime Filter -->
                <div *ngIf="log.primeFilter" class="log-item">
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ formatDate(log.occurred) }} Prime Filter
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block-container">
                            <div *ngFor="let filter of log.primeFilter"  class="prime-grid">

                                <!-- Study-Group -->
                                <mat-card>
                                    <mat-card-title>Studiengruppe</mat-card-title>
                                    <div [class.empty-field]="!filter.studyGroupNames || filter.studyGroupNames.length === 0">
                                        <p *ngFor="let name of filter.studyGroupNames">{{ name }}</p>
                                    </div>
                                </mat-card>

                                <!-- Study-Field -->
                                <mat-card>
                                    <mat-card-title>Studienfeld</mat-card-title>
                                    <div [class.empty-field]="!filter.studyFieldNames || filter.studyFieldNames.length === 0">
                                        <p *ngFor="let field of filter.studyFieldNames">{{ field }}</p>
                                    </div>
                                </mat-card>

                                <!-- Study-Area -->
                                <mat-card>
                                    <mat-card-title>Studienbereich</mat-card-title>
                                    <div [class.empty-field]="!filter.studyAreaNames || filter.studyAreaNames.length === 0">
                                        <p *ngFor="let area of filter.studyAreaNames">{{ area }}</p>
                                    </div>
                                </mat-card>

                                <!-- Grade -->
                                <mat-card>
                                    <mat-card-title>Mindestnote</mat-card-title>
                                    <div [class.empty-field]="!filter.minGrade">
                                        <p>{{ filter.minGrade }}</p>
                                    </div>
                                </mat-card>

                                <!-- CV -->
                                <mat-card>
                                    <mat-card-title>CV-Volltextsuche</mat-card-title>
                                    <div [class.empty-field]="!filter.cvSearchString">
                                        <p>{{ filter.cvSearchString }}</p>
                                    </div>
                                </mat-card>

                                <!-- Degrees -->
                                <mat-card>
                                    <mat-card-title>Abschlüsse</mat-card-title>
                                    <div [class.empty-field]="!filter.degreeTypes || filter.degreeTypes.length === 0">
                                        <p *ngFor="let degree of filter.degreeTypes">{{ degree }}</p>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>
    </loading-spinner>
</div>
