import {CompanyInvoiceInformationData} from "./company-invoice-information-data"

export class CompanyData {
    companyInvoiceInformation = new CompanyInvoiceInformationData();
    id: number = null;
    name: string = null;
    shortCompanyInfo: string | null = null;
    showCompanyProfile: boolean = null;
    website: string | null = null;
    yearlyRecruitings: number | null = null;
}