<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
  <div class="standard-container" fxLayout="column">
    <div class="button-bar" fxLayout="row" fxLayoutAlign="end center">
      <loading-button [loadingBool]="addingAdmin"
                      (click)="openCreateDialog()"
                      matTooltip="Admin hinzufügen"><i class="mdi mdi-plus main-action-icon"></i>
      </loading-button>
    </div>
    <table mat-table [dataSource]="admins" style="width: 100%">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef> Emailadresse</th>
        <td mat-cell *matCellDef="let element">{{element.emailAddress}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Admin bearbeiten" (click)="openEditState(element)">
            <i class="mdi mdi-pencil"></i>
          </button>
          <!--          <button mat-icon-button matTooltip="Admin löschen" (click)="deleteAdmin(element.id)">-->
          <!--            <i class="mdi mdi-delete"></i>-->
          <!--          </button>-->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
