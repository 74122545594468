<dialog-header dialogHeadline="Bewerbungen"></dialog-header>
<div id="dialog-content-wrapper">
    <loading-spinner [loadingBool]="loading" fxLayout="column">
        <table mat-table [dataSource]="applications" class="max-table" #applicationsTable>


            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Beworben am</th>
                <td mat-cell *matCellDef="let element">
                    {{element.creationDate | date: 'dd.MM.yyyy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="talentName">
                <th mat-header-cell *matHeaderCellDef>Talent</th>
                <td mat-cell *matCellDef="let element">
                    {{element.talentName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef>Firma</th>
                <td mat-cell *matCellDef="let element">
                    {{element.company}}
                </td>
            </ng-container>

            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Stelle</th>
                <td mat-cell *matCellDef="let element">
                    {{element.position.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    {{element.state | applicationState}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </loading-spinner>
</div>


