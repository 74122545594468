import {Component, Inject, OnInit} from '@angular/core';
import {AdminRecruiterResource, DemoResource} from "../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

    resetDemoSystemLoading: boolean = false
    setUpDemoSystemLoading: boolean = false

    demoAcc1RecruiterId: number
    demoAcc2RecruiterId: number

  constructor(
      private demoResource: DemoResource,
      private matSnackBar: MatSnackBar,
      private adminRecruiterResource: AdminRecruiterResource,
      private http: HttpClient,
      @Inject('env') private env
  ) { }

  ngOnInit(): void {
        this.getRecruiterIdsForLogin()
  }

  getRecruiterIdsForLogin(): Promise<any> {
        return Promise.all([
            this.adminRecruiterResource.getRecruiterIdByMail("demoAccountRecruiter-melina.scholz1@demosystem").then(result => {
                this.demoAcc1RecruiterId = result.value
            }),
            this.adminRecruiterResource.getRecruiterIdByMail("demoAccountRecruiter-melina.scholz2@demosystem").then(result => {
                this.demoAcc2RecruiterId = result.value
            })
        ])
  }

    resetDemoAccount(demoaccountId: string) {
      this.resetDemoSystemLoading = true
        this.demoResource.resetDemoAccount(demoaccountId).then(result => {
            this.getRecruiterIdsForLogin().then(() => {
                this.matSnackBar.open("Account wurde erfolgreich zurückgesetzt!", null, {duration: 5000})
                this.resetDemoSystemLoading = false
            })
        })
    }

    setUpDemoAccount(demoaccountId: string) {
        this.setUpDemoSystemLoading = true
        this.demoResource.setUpAccount(demoaccountId).then(result => {
            this.getRecruiterIdsForLogin().then(() => {
                this.matSnackBar.open("Account wurde erfolgreich erstellt!", null, {duration: 5000})
                this.setUpDemoSystemLoading = false
            })
        })
    }

    redirectToCompanyFrontendWithTokenForRecruiter(recruterId: number) {
        this.adminRecruiterResource.loginAsRecruiter(recruterId).then(result => {
            window.open(result, '_blank')
        })
    }

    uploadDemoFile(file: any) {

        let blobFile = new Blob([file[0]], { type: "application/octet-stream" });
        let formData: FormData = new FormData();
        formData.append("file", blobFile);

        let sub = this.http.post(this.env.apiUrlMain + '/demo/uploadFile', formData).subscribe(next => {
            sub?.unsubscribe()
        })
    }

}
