import {CompanyData} from "./company-data"
import {UploadedFileData} from "./uploaded-file-data"
import {TalentPositionRelationState} from "./talent-position-relation-state"

export class ConsultantTalentPositionProposalData {
    company = new CompanyData();
    locationDescription: string = null;
    logo: UploadedFileData | null = null;
    positionId: number = null;
    positionTitle: string = null;
    relationState: TalentPositionRelationState = null;
    startingDate: (Date | null) = null;
}