import {ChatMessageSeenLogData} from "./chat-message-seen-log-data"
import {ChatMemberData} from "./chat-member-data"

export class ChatMessageData {
    chatId: number = null;
    id: number = null;
    occurred: (Date | null) = null;
    seenList: ChatMessageSeenLogData[] = null;
    sender = new ChatMemberData();
    senderFirstName: string = null;
    text: string = null;
}