import {Component, OnInit} from '@angular/core';
import {AdminAcquisitionControllingFilterData, TalentCreationStatisticsData} from "../../../generated/data";
import {AdminControllingResource} from "../../../generated/resources";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-allocated-talent-creations',
    templateUrl: './allocated-talent-creations.component.html',
    styleUrls: ['./allocated-talent-creations.component.scss']
})
export class AllocatedTalentCreationsComponent implements OnInit {

    loading: boolean = true;
    devicesActive: boolean = false;
    utmSourceActive: boolean;
    utmCampaignActive: boolean;
    startDate: Date = new Date();
    endDate: Date = new Date();

    selectedCampaigns: string[] = []
    filteredCampaigns: string[] = []
    selectedUtmSources: string[] = []
    utmCampaigns: string[] = []
    utmSources: string[] = []

    devices = [
        'mobile',
        'tablet',
        'desktop'
    ]
    filterControl: FormControl = new FormControl()


    allColumns = [
        {value: 'date', display: 'Tag'},
        {value: 'numTalentCreations', display: 'Anmeldungen'},
        {value: 'numTalentLanguageOk', display: 'Sprache OK'},
        {value: 'numTalentLanguageNotOk', display: 'Sprache nicht OK'},
        {value: 'numTalentLanguageUnknown', display: 'Sprache Unbekannt'},
        {value: 'numOnboardingCompleted', display: 'OBC'},
        {value: 'onboardingCompletedQuota', display: 'OBC Quote'},
        {value: 'averageTalentQualityScore', display: 'Ø Qualityscore'},
        {value: 'qualitytiers', display: ' S/A/B/C/D/E'},
        {value: 'numCvUploads', display: 'CV Uploads'},
        {value: 'cvUploadQuota', display: 'CV Upload Quote'},
        {value: 'numCoachRequests', display: 'Coach angefordert'},
        {value: 'coachRequestQuota', display: 'Coach angefordert Quote'},
        {value: 'primeStates', display: 'PrimeLevel 3/2/1/ None'},
        {value: 'numApplicants', display: 'Bewerber'},
        {value: 'applicantsQuota', display: 'Bewerberquote'},
        {value: 'numApplications', display: 'Bewerbungen'}
    ];

    displayedColumns = [
        'date',
        'numTalentCreations',
        'numInvites',
        'numTalentLanguageOk',
        'numOnboardingCompleted',
        'averageTalentQualityScore',
        'numCvUploads',
        'numCoachRequests',
        'numCoachAssigned',
        'primeStates',
        'numApplicants',
        'numApplications'
    ];

    stats: TalentCreationStatisticsData[] = [];
    sum: TalentCreationStatisticsData;

    twentyFourHourWindow = false;

    constructor(private adminControllingResource: AdminControllingResource) {
    }

    ngOnInit() {
        Promise.all([
            this.getUtmSources(),
            this.getUtmCampains()
        ]).then(() => {
            this.loading = false
            this.filterControl.valueChanges.subscribe(value => {
                this.filteredCampaigns = this.utmCampaigns.filter(
                    item => item.toLowerCase().indexOf(value.toLowerCase()) > -1)
            })
            })


        this.startDate.setDate(new Date().getDate() - 7);
        this.endDate.setDate(new Date().getDate());
    }

    selectAll() {
        this.selectedCampaigns = this.utmCampaigns
    }

    unselectAll() {
        this.selectedCampaigns = []
    }

    loadData(): Promise<any> {
        this.loading = true;
        let data = <AdminAcquisitionControllingFilterData>{
            startDate: this.startDate,
            endDate: this.endDate,
            devices: this.devicesActive? this.devices : null,
            utmCampaigns: this.utmCampaignActive? this.selectedCampaigns : null,
            utmSources: this.utmSourceActive? this.selectedUtmSources : null
        }

        return this.adminControllingResource.getAcquisitionControlling(data).then((result) => {
            this.stats = result;

            this.loading = false;
        })
    }

    getUtmSources(): Promise<any> {
        return this.adminControllingResource.getAllTalentUtmSources().then(result => {
            this.utmSources = result.value
        })
    }

    getUtmCampains():Promise<any> {
        return this.adminControllingResource.getAllTalentUtmCampaigns().then(result => {
            this.utmCampaigns = result.value
            this.filteredCampaigns = result.value
        })
    }


    getQuota(quota) {
        if (Number.isNaN(quota)) return 0
        else return quota
    }

    getAverageQualityScore(score) {
        return Math.round(score)
    }

}
