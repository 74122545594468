<div style="margin: 20px" class="mat-elevation-z6">
    <mat-tab-group>

        <mat-tab>
            <div class="details-wrapper">
                <loading-spinner [loadingBool]="loading">
                    <ng-template mat-tab-label>Details</ng-template>

                    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="adminCompanyData">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <h2 *ngIf="!loading">{{adminCompanyData.name}}</h2>
                            <loading-button buttonClass="mat-primary"
                                            style="position: relative; top: 3px"
                                            [loadingBool]="loading"
                                            matTooltip="Speichern"
                                            (clickEvent)="updateCompany()">
                                <i class="mdi mdi-content-save main-action-icon"
                                   style="position: relative; bottom: 5px"></i>
                            </loading-button>
                        </div>

                        <loading-button buttonClass="mat-primary"
                                        [raised]="true"
                                        [loadingBool]="updateApiPositionSearchabilityLoading"
                                        *ngIf="[6296155,5421349,975878,16062525,16191081,16062647,4055135].includes(adminCompanyData.id)"
                                        (click)="updateApiPositionSearchability()">
                            {{adminCompanyData.searchEnabledForApiPositions ? 'Importierte Stellen für Suche deaktivieren' : 'Importierte Stellen für Suche aktivieren'}}
                        </loading-button>
                    </div>

                    <div *ngIf="!loading" class="main-details-wrapper" fxLayoutAlign="space-between start">
                        <div fxLayout="column">
                            <div class="company-logo-wrapper" fxLayout="column" fxLayoutAlign="start start"
                                 fxLayoutGap="80px"
                                 (click)="onEditImage()">
                                <img *ngIf="companyLogo != null"
                                     [src]="companyLogo"
                                     class="company-logo">
                                <img *ngIf="companyLogo == null"
                                     src="../../../assets/images/blurred_user.png"
                                     class="company-logo">
                                <div class="company-logo-overlay"></div>
                            </div>


                            <key-accounter-dropdown
                                    placeholder="Key Account Manager"
                                    [multiple]="false"
                                    [required]="false"
                                    [control]="keyAccounterControl">
                            </key-accounter-dropdown>


                        </div>

                        <form class="company-form" [formGroup]="companyForm" fxLayout="column">
                            <mat-form-field>
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="nameControl">
                            </mat-form-field>

                            <div fxLayout="row">
                                <mat-form-field style="width: 90%; margin-right: 10px">
                                    <mat-label>Straße</mat-label>
                                    <input matInput formControlName="streetNameControl">
                                </mat-form-field>

                                <mat-form-field style="width: 10%">
                                    <mat-label>Nr.</mat-label>
                                    <input matInput formControlName="streetNumberControl">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row">
                                <mat-form-field style="width: 20%; margin-right: 10px">
                                    <mat-label>PLZ</mat-label>
                                    <input matInput formControlName="zipCodeControl">
                                </mat-form-field>

                                <div *ngIf="!customCitySelected && !loading" style="position:relative; width: 100%;">
                                    <app-searchable-city-dropdown
                                            placeholder="Stadt"
                                            [multiple]="false"
                                            [control]="companyForm.get('cityIdControl')">
                                    </app-searchable-city-dropdown>
                                    <div class="hint" (click)="showCustomCityInput(true)">Städte zur Liste hinzufügen
                                    </div>
                                </div>

                                <div *ngIf="customCitySelected" class="w-100" style="position:relative;">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Städte hinzufügen" type="text"
                                               formControlName="cityNameControl">
                                        <i class="mdi mdi-content-save mdi-18px"
                                           style="position: absolute; right: 0"
                                           matTooltip="Neue Städte hinzufügen"
                                           (click)="saveCustomCities()">
                                        </i>
                                    </mat-form-field>
                                    <div class="hint" (click)="showCustomCityInput(false)">Städte aus Liste auswählen
                                    </div>
                                </div>

                            </div>

                            <mat-form-field>
                                <mat-label>Website</mat-label>
                                <input matInput formControlName="websiteControl">
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Unternehmen Kurzbeschreibung</mat-label>
                                  <textarea matInput
                                            placeholder="Comment"
                                            formControlName="shortCompanyInfoControl">
                                  </textarea>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Rechnungsemail</mat-label>
                                <input matInput formControlName="invoiceEmailControl">
                            </mat-form-field>

                            <div fxLayout="row" fxLayoutGap="1rem">
                                <mat-form-field class="w-100">
                                    <mat-label>Rechnungsträger Vorname</mat-label>
                                    <input matInput formControlName="invoiceHolderFirstNameControl">
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label>Rechnungsträger Nachname</mat-label>
                                    <input matInput formControlName="invoiceHolderLastNameControl">
                                </mat-form-field>
                            </div>

                            <mat-form-field>
                                <mat-label>Umsatzsteuer-Identifikationsnummer (USt-ID)</mat-label>
                                <input matInput formControlName="taxNumberControl">
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Extra Informationen (PO/ Bestellnummer/ Kostenstelle)</mat-label>
                                <input matInput formControlName="purchaseInfoControl">
                            </mat-form-field>
                        </form>
                    </div>

                </loading-spinner>
            </div>
        </mat-tab>



        <mat-tab>
            <div class="details-wrapper">
                <loading-spinner [loadingBool]="loading">
                    <ng-template mat-tab-label>Einstellungen</ng-template>

                    <div fxLayout="row" fxLayoutAlign="end center" *ngIf="adminCompanyData">
                        <div fxLayout="row" fxLayoutAlign="end center">
                                                        <loading-button buttonClass="mat-primary"
                                            style="position: relative; top: 3px"
                                            [loadingBool]="loading"
                                            matTooltip="Speichern"
                                            (clickEvent)="updateCompany()">
                                                            <span style="position: relative;top: -4px;margin-right: 10px;">Speichern</span>
                                <i class="mdi mdi-content-save main-action-icon"
                                   style="position: relative; bottom: 5px"></i>
                            </loading-button>
                        </div>
                    </div>

                    <div *ngIf="!loading" class="main-details-wrapper" fxLayoutAlign="space-between start">

                        <form class="company-form" [formGroup]="companyForm" fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <mat-checkbox formControlName="consideredForReverseRecruiting">für Reverse Recruiting
                                    freigegeben
                                </mat-checkbox>

                                <mat-form-field>
                                    <mat-label>Reverse Recruiting Kontingent (täglich)</mat-label>
                                    <input type="number"
                                           matInput
                                           formControlName="reverseRecruitingCount">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="m-bottom15">
                                <mat-checkbox formControlName="googleJobPosting">GoogleJobPosting erlauben / neue Stellen Automatisch aktivieren</mat-checkbox>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="m-bottom15">
                                <mat-checkbox formControlName="primeCompany">Prime Unternehmen</mat-checkbox>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="m-bottom15">
                                <mat-checkbox formControlName="showProfile">Unternehmensprofil anzeigen</mat-checkbox>
                            </div>

                            <mat-form-field>
                                <mat-label>Bewerber-Qualitätssicherung</mat-label>
                                <mat-select formControlName="applicationGrantStrategy">
                                    <mat-option value="AutomaticInstantly">Immer Automatisch</mat-option>
                                    <mat-option value="AutoGermanC1">Automatisch - Deutsch min. C1 oder unbekannt</mat-option>
                                    <mat-option value="AutoGermanB2">Automatisch - Deutsch min. B2 oder unbekannt</mat-option>
                                    <mat-option value="PurelyManual">Nur manuelle Prüfung</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="controlling-board-info">
                               Diese Einstellung legt fest, wie Bewerber vor der Weitergabe ans UN geprüft werden.
                               Beim Automatischen Check wird die Bewerbung direkt freigegeben. Alternativ kann es auch ein Bewerber auf einen Primevorschlag von einem Recruiter sein.
                                Bei Deutsch min. C1 und Deutsch min. B2 wird auf die Sprachkenntnisse geprüft. Passen die Sprachkenntnisse oder das Talent hat keine angegeben, wird die Bewerbung freigegeben,
                               wenn nicht, kommt sie in das Review-Board und muss manuell freigegeben werden.<br>
                               Beim Manuellen Check muss immer über den Review-Bereich freigegeben werden.<br>
                           </span>
                        </form>
                    </div>

                    <div *ngIf="adminCompanyData && adminCompanyData.uniwunderApiUrl" fxLayout="column">
                        <span style="font-weight: bold">Uniwunder Api Link:</span>
                        <a [href]="adminCompanyData.uniwunderApiUrl"
                           target="_blank">{{adminCompanyData.uniwunderApiUrl}}</a>
                    </div>


                </loading-spinner>
            </div>
        </mat-tab>

        <mat-tab *ngIf="adminCompanyData?.companyPricing == 'PrepaidBooking'">
            <ng-template mat-tab-label>Angebote</ng-template>
            <company-sales-proposals *ngIf="adminCompanyData?.id" [companyId]="adminCompanyData?.id"></company-sales-proposals>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>Stellen der Firma</ng-template>
            <app-company-employment-positions #positionsCmp [companyId]="companyId"></app-company-employment-positions>
        </mat-tab>

        <mat-tab *ngIf="adminCompanyData">
            <ng-template mat-tab-label>Recruiter</ng-template>
            <company-recruiters [company]="adminCompanyData"></company-recruiters>
        </mat-tab>

        <mat-tab *ngIf="adminCompanyData">
            <ng-template mat-tab-label>Bewerbungen</ng-template>
            <company-applications [companyId]="adminCompanyData?.id"></company-applications>
        </mat-tab>

        <mat-tab *ngIf="adminCompanyData">
            <ng-template mat-tab-label>Topjobs</ng-template>
            <company-topjobs [companyId]="adminCompanyData?.id"></company-topjobs>
        </mat-tab>

        <mat-tab *ngIf="adminCompanyData">
            <ng-template mat-tab-label>ATS</ng-template>
            <company-ats [companyId]="adminCompanyData?.id"></company-ats>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>Pricing</ng-template>
            <app-company-pricing [companyId]="companyId"></app-company-pricing>
        </mat-tab>

    </mat-tab-group>
</div>

