<div fxLayout="column" class="dialog">

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3>{{data.title || 'Bild festlegen'}}</h3>
    <i class="mdi mdi-close-box mdi-24px clickable" matTooltip="Abbrechen" (click)="close()"></i>
  </div>

  <div fxLayout="column" fxLayoutAlign="start center" style="margin: 20px 0">
    <label for="image-input" class="hid-button secondary" *ngIf="file.value">
      <i class="mdi mdi-pencil"></i>
      {{file.value[0].name}}
    </label>
    <label for="image-input" class="hid-button secondary" *ngIf="!file.value">
      <i class="mdi mdi-upload"></i>
      Bitte auswählen
    </label>

    <!--{{file.value[0].name || 'Bild auswählen'}}-->
    <input type="file" style="visibility: hidden; width: 0" id="image-input" [formControl]="file"
           accept="image/png, image/jpeg, image/jpg, image/gif"
           (change)="fileChangeEvent($event)"/>
    <div>
      <small style="color: red" *ngIf="file.errors != null && file.errors['sizeTooBig']">Die Datei ist größer als 1MB,
        Bitte wähle ein kleineres Bild
      </small>
    </div>

    <!--    <small  style="color: red" *ngIf="file.errors != null && file.errors['isNull']">
          isNull
        </small>-->
    <div>
      <small style="color: red" *ngIf="file.errors != null && file.errors['wrongExtension']">
        Falsche Dateiendung. Bitte wähle ein Bild mit .jpg, .gif oder .png als Format.
      </small>
    </div>

    <!--accept="image/png, image/jpeg, image/jpg, image/gif"-->
    <!--(change)="fileChangeEvent($event)"-->
    <!--<button (click)="log()">test</button>-->
  </div>

  <div class="picture-preview">
    <image-cropper class="image-cropper" *ngIf="imageFileName"
                   [imageChangedEvent]="imageChangedEvent"
                   [maintainAspectRatio]="true"
                   [aspectRatio]="data.aspectRatio"
                   [containWithinAspectRatio]="true"
                   format="png"
                   [roundCropper]="data.roundCropping"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoaded()"
                   (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="button-bar primary-text-color">
    <button mat-flat-button class="cancel-button" (click)="close()">Abbrechen</button>
    <loading-button (clickEvent)="finish()"
                    [loadingBool]="saving"
                    buttonClass="mat-primary"
                    [disabledCondition]="file.invalid">
      Speichern
    </loading-button>
  </div>
</div>
