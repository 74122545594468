<dialog-header dialogHeadline="{{subjectTitle}}"></dialog-header>
<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">
        <div *ngIf="subjectId" class="width-100" fxLayout="column" fxLayoutGap="4px">
            <small><b>ID: </b><span class="copy"
                                    (click)="utilityService.showSnackBar('ID kopiert')">{{subjectId}}</span></small>
        </div>

        <mat-tab-group>
            <mat-tab label="SubjectGroups" class="w-100">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(subjectGroupDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="subjectGroupDataSource" class="w-100">
                    <ng-container matColumnDef="subjectGroupTitle">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroup</th>
                        <td mat-cell *matCellDef="let subjectGroup">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openSubjectGroupDetailsSheet(subjectGroup)">
                                {{subjectGroup.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjects">
                        <th mat-header-cell *matHeaderCellDef>Subjects</th>
                        <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numSubjects}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numProfessions">
                        <th mat-header-cell *matHeaderCellDef>Professions</th>
                        <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numProfessions}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                        <td mat-cell *matCellDef="let subjectGroup">
                            <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                                <button mat-button mat-icon-button color="primary"
                                        matTooltip="Mapping entfernen"
                                        (click)="deleteSubjectGroupMapping(subjectGroup)">
                                    <i class="mdi mdi-trash-can mdi-24px"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedSubjectGroupColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedSubjectGroupColumns;"></tr>
                </table>
            </mat-tab>

            <mat-tab label="Professions">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter(professionDataSource, $event.target.value)" placeholder="Suche">
                </mat-form-field>

                <table mat-table [dataSource]="professionDataSource" class="w-100">
                    <ng-container matColumnDef="professionTitle">
                        <th mat-header-cell *matHeaderCellDef>Profession</th>
                        <td mat-cell *matCellDef="let profession">
                            <span class="clickable"
                                  matTooltip="Mappings ansehen"
                                  (click)="openProfessionDetailsSheet(profession)">
                                {{profession.title}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjectGroups">
                        <th mat-header-cell *matHeaderCellDef>SubjectGroups</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjectGroups}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numSubjects">
                        <th mat-header-cell *matHeaderCellDef>Subjects</th>
                        <td mat-cell *matCellDef="let profession">{{profession.numSubjects}}</td>
                    </ng-container>

                    <ng-container matColumnDef="agencyKey">
                        <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                        <td mat-cell *matCellDef="let profession">
                            <span class="copy">{{profession.agencyKey}}</span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedProfessionColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedProfessionColumns;"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
</loading-spinner>
