import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'skill'
})
export class SkillPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'Novice':
        return 'Einsteiger';
      case 'Beginner':
        return 'Grundlagen';
      case 'Intermediate':
        return 'Fortgeschritten';
      case 'Proficient':
        return 'Erfahren';
      case 'Advanced':
        return 'Experte';
    }
    return null;
  }

}
