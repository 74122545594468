<dialog-header dialogHeadline="Neues Unternehmen erstellen"></dialog-header>

<div class="dialog-content">
  <form [formGroup]="form" fxLayout="column">
    <mat-form-field class="form-field">
      <input matInput placeholder="Firmenname" type="text" formControlName="companyNameControl">
    </mat-form-field>

    <mat-form-field class="form-field">
      <textarea matInput placeholder="Kurzinfo (max. 30 Zeichen)" rows="3" formControlName="companyDescriptionControl" maxlength="30"></textarea>
    </mat-form-field>

    <mat-form-field class="form-field">
      <mat-select formControlName="companySizeControl" placeholder="Unternehmensgröße">
        <mat-option value="Small">1 - 50 Mitarbeiter</mat-option>
        <mat-option value="Medium">50 - 500 Mitarbeiter</mat-option>
        <mat-option value="Big">mehr als 500 Mitarbeiter</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-field">
      <input matInput placeholder="Webseite" type="text" formControlName="companyWebsiteControl">
    </mat-form-field>

      <key-accounter-dropdown
              placeholder="Key Account Manager"
              [multiple]="false"
              [required]="false"
              [control]="form.controls.companyKeyAccounterControl">
      </key-accounter-dropdown>
  </form>

  <app-company-pricing-content [(pricing)]="pricing" (valid)="pricingValid = $event"></app-company-pricing-content>

    <h3>Adresse & Rechnungsinformationen</h3>

    <form class="company-form" [formGroup]="companyInvoiceAddressForm" fxLayout="column">
        <div fxLayout="row">
            <mat-form-field style="width: 90%; margin-right: 10px">
                <mat-label>Straße</mat-label>
                <input matInput formControlName="streetNameControl">
            </mat-form-field>

            <mat-form-field style="width: 25%">
                <mat-label>Nr.</mat-label>
                <input matInput formControlName="streetNumberControl">
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field style="width: 20%; margin-right: 10px">
                <mat-label>PLZ</mat-label>
                <input matInput formControlName="zipCodeControl">
            </mat-form-field>

            <div *ngIf="!loading" style="position:relative; width: 100%;">
                <app-searchable-city-dropdown
                        placeholder="Stadt"
                        [multiple]="false"
                        [control]="companyInvoiceAddressForm.get('cityIdControl')">
                </app-searchable-city-dropdown>
                </div>
            </div>

        <mat-form-field>
            <mat-label>Rechnungsemail</mat-label>
            <input matInput formControlName="invoiceEmailControl">
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="1rem">
            <mat-form-field class="w-100">
                <mat-label>Rechnungsträger Vorname</mat-label>
                <input matInput formControlName="invoiceHolderFirstNameControl">
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Rechnungsträger Nachname</mat-label>
                <input matInput formControlName="invoiceHolderLastNameControl">
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>Umsatzsteuer-Identifikationsnummer (USt-ID)</mat-label>
            <input matInput formControlName="taxNumberControl">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Extra Informationen (PO/ Bestellnummer/ Kostenstelle)</mat-label>
            <input matInput formControlName="purchaseInfoControl">
        </mat-form-field>
    </form>
</div>

<div fxLayout="row" fxLayoutAlign="end start" class="width-100">
  <loading-button buttonClass="form-field mat-primary"
                  [raised]="false"
                  [loadingBool]="loading"
                  [disabledCondition]="!form.valid || !pricingValid || !companyInvoiceAddressForm.valid"
                  (clickEvent)="createCompany()">Speichern
  </loading-button>
</div>
