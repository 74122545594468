<mat-expansion-panel [disabled]="disabled">
  <mat-expansion-panel-header>
    Studienfächer ({{studySubjects.length}} ausgewählt)
  </mat-expansion-panel-header>
  <mat-panel-description>
    <div fxLayout="row wrap" style="width: 100%">
      <app-material-async-dropdown style="width: 100%"
                                   [loadingFunctionConfig]="{resource: studyInfoResource, functionName: 'getSubjects'}"
                                   (modelChange)="add($event)"
                                   displayProperty="title"
                                   [nullable]="false"
                                   placeholder="Hinzufügen"
      >

      </app-material-async-dropdown>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="field " *ngFor="let field of studySubjects">
        <button mat-icon-button><i class="mdi mdi-close" (click)="remove(field)"></i></button>
        {{field.title}}
      </div>
    </div>
  </mat-panel-description>
</mat-expansion-panel>
