import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {FormControl} from "@angular/forms";
import {AdminEmploymentPositionResource} from "../../generated/resources";

@Component({
  selector: 'app-mark-prime-position-as-unsuitable-dialog',
  templateUrl: './mark-prime-position-as-unsuitable-dialog.component.html',
  styleUrls: ['./mark-prime-position-as-unsuitable-dialog.component.scss']
})
export class MarkPrimePositionAsUnsuitableDialogComponent implements OnInit {

  position
  talentId: number
  loading: boolean = false

  reasonControl: FormControl = new FormControl()

  constructor(
      private sideSheetRef: SideSheetRef,
      private adminEmploymentPositionResource: AdminEmploymentPositionResource
  ) { }

  ngOnInit(): void {
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  markPrimePositionAsUnsuitable() {
    this.loading = true
    this.adminEmploymentPositionResource.markPrimePositionAsUnsuitable(this.reasonControl.value, {
          talentId: this.talentId, employmentPositionId: this.position.positionId
        }
    ).then(result => {
      this.close(true)
      this.loading = false
    })
  }

}
