import {Component, OnInit} from '@angular/core';
import {AdminWebinarResource} from "../../generated/resources";
import {FormControl} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileService} from "../../services/file.service";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {WebinarCreateDialogComponent} from "../webinar-create-dialog/webinar-create-dialog.component";
import {WebinarData} from "../../generated/data";
import {last} from "rxjs/operators";

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss']
})
export class WebinarComponent implements OnInit {

    loading: boolean = false
    start: FormControl = new FormControl()
    end: FormControl = new FormControl()

    webinars: WebinarData[] = []

    displayedColumns:string[] =  ['title', 'dateTime', 'speakers', 'actions']

  constructor(
      private snackBar: MatSnackBar,
      private fileService: FileService,
      private sideSheet: SideSheetService,
      private snackbar: MatSnackBar,
      private webinarResource: AdminWebinarResource
  ) {

        let now = new Date()
      this.start.setValue(new Date())
      now.setMonth(new Date().getMonth() +1 )
      this.end.setValue(now)
  }

  ngOnInit(): void {
        this.loadWebinars()
  }

    downloadWebinarRegistrationExcel() {
      this.loading = true

        this.fileService.downLoadWebinarRegistrations(this.start.value, this.end.value).then(result => {
          this.loading = false
          this.snackBar.open("Excel heruntergeladen!")
      })

    }

    openCreateOrEditWebinarDialog(webinar: WebinarData | null = null) {
        let dialog = this.sideSheet.openOverlay(WebinarCreateDialogComponent)
        dialog.instance.webinar = webinar

        let sub = dialog.instance.sideSheetRef.sheetClosed.subscribe(webinarId => {
            if (webinarId) {
                this.snackbar.open("Webinar gespeichert", null, {duration: 1500})
            }
            sub.unsubscribe()
        })
    }

    toggleActiveStatusOfWebinar(webinarId: number, active: boolean) {

        this.webinarResource.updateActiveStatusOfWebinar(webinarId, {activeStatus: active}).then()
    }

    loadWebinars() {
        this.webinarResource.getAllWebinars({start: this.start.value, end: this.end.value}).then(result => {
            this.webinars = result
        })
    }

    protected readonly last = last;
}
