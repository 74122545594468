import {Component, OnInit} from '@angular/core';
import {SideSheetService} from "../../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../../utils/side-sheet/sideSheetRef";
import {AdminTalentResource} from "../../../../generated/resources";
import {
  AdminTalentFeedPositionRelationData,
  TalentFilterData,
  TalentMatchingReportData
} from "../../../../generated/data";
import {PageEvent} from "@angular/material/paginator";
import {MatchingAnalyzeSheetComponentComponent} from "../../../../matching/matching-optimization/matching-analyze-sheet-component/matching-analyze-sheet-component.component";
import {TalentProfileDialogComponent} from "../../../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-talent-filter-result-list-sheet',
  templateUrl: './talent-filter-result-list-sheet.component.html',
  styleUrls: ['./talent-filter-result-list-sheet.component.scss']
})
export class TalentFilterResultListSheetComponent implements OnInit {

  positionId: number
  filters: TalentFilterData[]

  data: AdminTalentFeedPositionRelationData[]

  pageIndex: number = 0;
  pageSize: number = 15;
  pageSizeOptions: number[] = [15, 30, 60, 100];
  totalSize: number = 0;

  displayedColumns = ['creationDate','name','subject','qualityScore','hasMatch','actions']

  loading: boolean = true;

  constructor(
      private sideSheetRef: SideSheetRef,
      private adminTalentFilterResource: AdminTalentResource,
      private dialogService: SideSheetService,
      private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loadTalents()
  }

  loadTalents() {
    this.loading = true;
    this.filters.forEach(filter => {
      filter.employmentPositionId = this.positionId
    })
    this.adminTalentFilterResource.getFilteredTalentsForPosition(this.filters, this.positionId,{page: this.pageIndex, pageSize: this.pageSize}).then(talents => {
      this.data = talents.content
      this.totalSize = talents.totalElements
      this.loading = false;
    })
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadTalents();
  }

  openMatchingReport(report: TalentMatchingReportData) {
    let cref = this.dialogService.openOverlay(MatchingAnalyzeSheetComponentComponent)
    cref.instance.report = report;
  }

  openTalentProfile(talentId: number) {
    let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = talentId
  }

}
