<div class="headline" fxLayout="row" fxLayoutAlign="space-between center">

    <div style="width: 40px; height: 40px"></div>

    <div>
        <h1>{{dialogHeadline}}</h1>
    </div>

    <button side-sheet-back-arrow-button mat-icon-button *ngIf="dialogHeadline">
        <i class="mdi mdi-24px mdi-close"></i>
    </button>
</div>
