import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {TalentFilterData} from "../../../generated/data";
import {AdminEmploymentPositionResource} from "../../../generated/resources";

@Component({
  selector: 'talent-filter-sheet',
  templateUrl: './talent-filter-sheet.component.html',
  styleUrls: ['./talent-filter-sheet.component.scss']
})
export class TalentFilterSheetComponent implements OnInit {

  public positionId: number = null;
  public filters: TalentFilterData[]
  public filtersLoaded: Boolean = false;

  constructor(
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.filtersLoaded = true
  }

  loadTalentFilters() {
    if(this.positionId == null) return
    this.adminEmploymentPositionResource.getPrimeTalentFiltersOfPosition(this.positionId).then(filters => {
      this.filters = filters
      this.filtersLoaded = true
    })
  }

  resetTalentFilters() {
    this.filters = [new TalentFilterData()]
  }

  applyFilter() {
    this.sideSheetRef.sheetClosed.next(this.filters)
  }

}
