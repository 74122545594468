import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AdminAcquisitionControllingFilterData, ApplicationProcessingWeeksData, TalentCreationStatisticsData, EmploymentPositionApplicationFailedStatisticsData, EmploymentPositionApplicationStatisticsData, ContentSplitControllingData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminControllingController
 */

@Injectable()
@ResourceParams({})
export class AdminControllingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/controlling/acquisition',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAcquisitionControlling: IResourceMethodObservableStrict<AdminAcquisitionControllingFilterData, null, null, TalentCreationStatisticsData[]> | undefined
    getAcquisitionControlling(requestBody: AdminAcquisitionControllingFilterData): Promise<TalentCreationStatisticsData[]> {
        if (!this._getAcquisitionControlling) throw new Error("resource has not been properly initialized")
        return this._getAcquisitionControlling(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/controlling/talents/utmCampaigns',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllTalentUtmCampaigns: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string[]>> | undefined
    getAllTalentUtmCampaigns(): Promise<JsonWrappedValue<string[]>> {
        if (!this._getAllTalentUtmCampaigns) throw new Error("resource has not been properly initialized")
        return this._getAllTalentUtmCampaigns(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/controlling/talents/utmSources',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllTalentUtmSources: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string[]>> | undefined
    getAllTalentUtmSources(): Promise<JsonWrappedValue<string[]>> {
        if (!this._getAllTalentUtmSources) throw new Error("resource has not been properly initialized")
        return this._getAllTalentUtmSources(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/controlling/positions/{id}/failedReasons',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationFailedStatisticsForEmploymentPosition: IResourceMethodObservableStrict<null, null, {id: number}, EmploymentPositionApplicationFailedStatisticsData> | undefined
    getApplicationFailedStatisticsForEmploymentPosition(id: number): Promise<EmploymentPositionApplicationFailedStatisticsData> {
        if (!this._getApplicationFailedStatisticsForEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._getApplicationFailedStatisticsForEmploymentPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/controlling/applicationProcessing',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationProcessingControlling: IResourceMethodObservableStrict<null, {startDate: Date, origins: ('Uniwunder' | 'Campusjaeger' | 'Pluss' | 'Absolventa' | 'Joblift' | 'Jobware' | 'Stellenanzeigen' | 'Experteer' | 'JobFeed' | 'Instaffo' | 'Stepstone')[], endDate: Date}, null, ApplicationProcessingWeeksData[]> | undefined
    getApplicationProcessingControlling(requestParams: {startDate: Date, origins: ('Uniwunder' | 'Campusjaeger' | 'Pluss' | 'Absolventa' | 'Joblift' | 'Jobware' | 'Stellenanzeigen' | 'Experteer' | 'JobFeed' | 'Instaffo' | 'Stepstone')[], endDate: Date}): Promise<ApplicationProcessingWeeksData[]> {
        if (!this._getApplicationProcessingControlling) throw new Error("resource has not been properly initialized")
        return this._getApplicationProcessingControlling(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/controlling/splitControlling',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getContentSplitControllingForSplits: IResourceMethodObservableStrict<null, {splitIds: number[], startDate: Date, endDate: Date}, null, ContentSplitControllingData[]> | undefined
    getContentSplitControllingForSplits(requestParams: {splitIds: number[], startDate: Date, endDate: Date}): Promise<ContentSplitControllingData[]> {
        if (!this._getContentSplitControllingForSplits) throw new Error("resource has not been properly initialized")
        return this._getContentSplitControllingForSplits(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/controlling/customers/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionApplicationStatistics: IResourceMethodObservableStrict<null, {page: number, pageSize: number, origin: ('Uniwunder' | 'Campusjaeger' | 'Pluss' | 'Absolventa' | 'Joblift' | 'Jobware' | 'Stellenanzeigen' | 'Experteer' | 'JobFeed' | 'Instaffo' | 'Stepstone'), companyId: number, query: string, states: ('Draft' | 'Inactive' | 'SearchableOnly' | 'Active')[], dateFrom: Date, dateTo: Date, keyAccounterId: number}, null, PagedData<EmploymentPositionApplicationStatisticsData>> | undefined
    getEmploymentPositionApplicationStatistics(requestParams: {page: number, pageSize: number, origin: ('Uniwunder' | 'Campusjaeger' | 'Pluss' | 'Absolventa' | 'Joblift' | 'Jobware' | 'Stellenanzeigen' | 'Experteer' | 'JobFeed' | 'Instaffo' | 'Stepstone'), companyId: number, query: string, states: ('Draft' | 'Inactive' | 'SearchableOnly' | 'Active')[], dateFrom: Date, dateTo: Date, keyAccounterId: number}): Promise<PagedData<EmploymentPositionApplicationStatisticsData>> {
        if (!this._getEmploymentPositionApplicationStatistics) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionApplicationStatistics(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/controlling/positionContentsSplits',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionContentSplitsForPosition: IResourceMethodObservableStrict<null, {id: number}, null, LabelData[]> | undefined
    getPositionContentSplitsForPosition(requestParams: {id: number}): Promise<LabelData[]> {
        if (!this._getPositionContentSplitsForPosition) throw new Error("resource has not been properly initialized")
        return this._getPositionContentSplitsForPosition(null, requestParams, null).toPromise()
    }

}