import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';


export interface EditImageDialogData {
  title: string;
  aspectRatio: number,
  roundCropping: true; //Optional
}

@Component({
  selector: 'app-edit-image-dialog',
  templateUrl: './edit-image-dialog.component.html',
  styleUrls: ['./edit-image-dialog.component.css']
})
export class EditImageDialogComponent implements OnInit {


  whiteListedExtensions = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

  saving: false;
  image: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageFileName = '';
  imageFileType = '';
  imageFileSize = '';
  imageBase64 = '';
  finishing = false;
  file = new FormControl(null, this.validateImage());


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditImageDialogData, public dialogRef: MatDialogRef<EditImageDialogComponent>) {
  }

  ngOnInit() {
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;

    if (this.file.value && this.file.valid) {
      this.imageFileName = this.file.value[0].name;
    }
  }

  imageCropped(event) {
    const imageBlob = this.dataURItoBlob(event.base64.replace("data:image/png;base64,", ""));
    this.image = new File([imageBlob], this.imageFileName, {type: 'image/jpeg'});
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }


  finish() {
    if (this.image) {
      this.convertFileToArrayBuffer(this.image);
    } else {
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

  convertFileToArrayBuffer(file) {
    let myReader: FileReader = new FileReader();

    myReader.onloadend = () => {
      this.dialogRef.close({file: myReader.result, fileName: file.name});
    };

    myReader.readAsArrayBuffer(file);
  }

  validateImage(): ValidatorFn {
    //3145728 Bytes = 3MB
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return {isNull: true};
      }

      if (control.value[0].size >= 1000000) {
        return {sizeTooBig: true};
      }

      // if (this.whiteListedExtensions.indexOf(control.value[0].type) == -1) {
      //   return {wrongExtension: true};
      // }

      return null;
    }
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/jpeg'});
  }

}
