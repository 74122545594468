import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookingEventType'
})
export class BookingEventTypePipe  implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    switch(value) {
      case 'POSITION_ADDED': return 'Stelle hinzugefügt';
      case 'POSITION_REMOVED': return 'Stelle entfernt';
      case 'CREATED': return 'Erstellt';
      case 'CLOSED': return 'Geschlossen';
      case 'REACTIVATED': return 'Reaktiviert';
      case 'PAUSED': return 'Pausiert';
    }
  }
}
