import {Component, OnInit} from '@angular/core';
import {AdminSubjectResource} from "../../generated/resources";
import {DKZSubjectMappingData} from "../../generated/data";
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {DkzMappingSelectionDialogComponent} from "./dkz-mapping-selection-dialog/dkz-mapping-selection-dialog.component";

@Component({
  selector: 'app-manual-subject-mapping',
  templateUrl: './manual-subject-mapping.component.html',
  styleUrls: ['./manual-subject-mapping.component.scss']
})
export class ManualSubjectMappingComponent implements OnInit {

  page: number = 0;
  pageSize: number = 1;
  totalElements: number = 0;
  totalPages: number = 0;

  dataSource: DKZSubjectMappingData[]

  manualSubjectGroupSelectionControl = new FormControl(null)
  saving: boolean = false

  constructor(
      private adminSubjectResource: AdminSubjectResource,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.adminSubjectResource.getAllDKZSubjectsWithoutMapping({page: this.page, pageSize: this.pageSize}).then(result => {
      this.dataSource = result.content
      this.totalElements = result.totalElements
      this.totalPages = result.totalPages
    })
  }

  openDkzMappingSelectionDialog() {
    let ref = this.dialogService.openOverlay(DkzMappingSelectionDialogComponent)

    ref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      this.manualSubjectGroupSelectionControl.setValue(next)
      this.manualSubjectGroupSelectionControl.updateValueAndValidity()
    })
  }


  addSubjectToGroupMapping(groupId: number) {
    if(this.saving) return

    this.saving = true
    this.adminSubjectResource.addSubjectToGroupMapping({subjectId: this.dataSource[0].subjectId, groupId: groupId}).then(() => {
      this.manualSubjectGroupSelectionControl.setValue(null)
      this.manualSubjectGroupSelectionControl.updateValueAndValidity()
      this.page = this.page + 1 == this.totalPages  ? this.page - 1 : this.page
      this.saving = false
      this.loadData();
    })
  }

  addManualSearchedGroupToSubject() {
    if(this.manualSubjectGroupSelectionControl.value == null) return
    this.addSubjectToGroupMapping(this.manualSubjectGroupSelectionControl.value)
  }


  handlePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }
}
