import {LabelData} from "./label-data"

export class ProfessionData {
    agencyKey: string = null;
    areaId: number = null;
    areaTitle: string = null;
    blacklisted: boolean = null;
    groupId: number = null;
    groupTitle: string = null;
    id: number = null;
    mainGroupId: number = null;
    mainGroupTitle: string = null;
    matchedSubjectGroups: LabelData[] = null;
    matchedSubjects: LabelData[] = null;
    numSubjectGroups: number = null;
    numSubjects: number = null;
    specialized: boolean = null;
    subGroupId: number = null;
    subGroupTitle: string = null;
    title: string = null;
    typeId: number = null;
    typeTitle: string = null;
}