import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AdminCompanyResource, AdminEmploymentPositionResource, AdminMatchingResource} from '../../generated/resources';
import {AdminEmploymentPositionDisplayData, EmploymentPositionState, JsonWrappedValue} from '../../generated/data';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {UtilityService} from '../../utils/utility.service';
import {ConfirmDialogComponent} from '../../utils/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AdminComponent} from '../../admin.component';
import {MatTable} from '@angular/material/table';
import {CreatePositionDialogComponent} from '../../positions/create-position-dialog/create-position-dialog.component';
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";

@Component({
  selector: 'app-company-employment-positions',
  templateUrl: './company-employment-positions.component.html',
  styleUrls: ['./company-employment-positions.component.scss']
})
export class CompanyEmploymentPositionsComponent implements OnInit {

  @Input() companyId: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('dataTable') public table: MatTable<any>;

  public companyEmploymentPositions: AdminEmploymentPositionDisplayData[];

  public displayedColumns = ['positionId', 'positionTitle', 'company', 'questions', 'active', 'positionType', 'creationDate', 'actions'];

  public loading: boolean = false;
  public deactivateAllPositionsLoading: boolean = false;

  constructor(
    private adminCompanyResource: AdminCompanyResource,
    private adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private router: Router,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private dialogService: SideSheetService,
    private adminComponent: AdminComponent,
    private adminMatchingResource: AdminMatchingResource
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;

    this.adminCompanyResource.getEmploymentPositionsForCompany(this.companyId).then(result => {
      this.companyEmploymentPositions = result;
      this.loading = false;
    })
  }

    openDeleteAllPositionsDialog(companyId: number) {
                this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        message: `Möchtest du wirklich alle Stellen des Unternehmens deaktivieren? (noch aktive Bewerbungen von Talenten werder als gescheitert markiert. Das kann einige Zeit dauern, du kannst dann auch diesen dialog zwischendurch schließen`,
                        confirmButtonText: 'alle Stellen deaktivieren',
                        title: 'Alle Stellen deaktivieren',
                        onConfirm: () => {return this.deactivateAllPositionsForCompany(companyId)}
                    },
                    panelClass: [''],
                    maxWidth: 600
                })
    }

    deactivateAllPositionsForCompany(companyId: number) {
        this.deactivateAllPositionsLoading = true
      this.adminMatchingResource.deactivateAllPositionsForCompany(companyId).then(result => {
          this.deactivateAllPositionsLoading = false
      })
    }
  openPositionDetailsDialog(positionId, tabIndex: number = 0) {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
    comp.instance.tabIndex = tabIndex

    comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
      this.loadData()
    })
  }




  copyToClipboard(textToCopy: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.utilityService.showSnackBar('In Zwischenablage kopiert');
  }

  duplicateEmploymentPosition(positionId: number, posTitle: string) {

    let dialogRef =
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `Möchtest du die Stelle "${posTitle}" wirklick kopieren?`,
            confirmButtonText: 'Kopieren',
            title: 'Stelle Kopieren',
            onConfirm: () => {return new Promise((resolve => {resolve(true)}))}
          },
          panelClass: [''],
          maxWidth: 600
        })


    dialogRef.afterClosed().subscribe(next => {
      if (next) {
        this.adminEmploymentPositionResource.duplicateEmploymentPosition(positionId).then(result => {
          this.openPositionDetailsDialog(result.id)
        })
      }
    })

  }

  createPositionDialog() {
    let cref = this.dialogService.openOverlay(CreatePositionDialogComponent)

    cref.instance.sideSheetRef.sheetClosed.subscribe((positionId) => {
      if(positionId) {
        this.openPositionDetailsDialog(positionId, 1)
        this.adminComponent.ngOnInit();
      }
    })
  }
}
