<dialog-header *ngIf="dialog" [dialogHeadline]="this.booking.title ? this.booking.title + ' bearbeiten' : 'Buchung anlegen'"></dialog-header>

<div id="dialog-content-wrapper" class="layout-padding" *ngIf="booking && formReady">
    <div class="content" [formGroup]="bookingForm">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div fxLayout="row" fxLayoutGap="16px" class="top-section">
                <div fxLayout="column" fxLayoutAlign="start start">
                    <div *ngIf="booking.company" class="customer-company">
                        <span class="small">Kunde (Unternehmen):</span>
                        <span>{{booking.company.name || '(ID: ' + booking.company.id + ')'}}</span>
                    </div>

                    <searchable-company-by-pricingmode-dropdown
                            [filteredModes]="['PrepaidBooking', 'Booking']"
                            *ngIf="!booking.company"
                            placeholder="Firma"
                            [multiple]="false"
                            [required]="true"
                            [control]="bookingForm.controls['companyId']">
                    </searchable-company-by-pricingmode-dropdown>

                    <mat-form-field>
                        <mat-label>Produkt</mat-label>
                        <mat-select formControlName="product" [multiple]="false">

                            <mat-option *ngFor="let product of products" [value]="product">
                                {{product.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Interner Buchungstitel</mat-label>
                        <input matInput type="text" formControlName="title">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Buchungsgröße (Bewerbungen)</mat-label>
                        <input matInput type="number" formControlName="size">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Nachlieferungen (Bewerbungen)</mat-label>
                        <input matInput type="number" formControlName="resupply">
                    </mat-form-field>
                </div>
            </div>

            <info-box message="Hier kann die Menge an Bewerbungen angegeben werden, welche wir nachliefern wollen falls bspw. die Qualität der Bewerber nicht gestimmt hat"></info-box>

            <mat-form-field>
                <mat-label>Preis bei vollständiger erfüllung (€)</mat-label>
                <input matInput type="number" formControlName="price">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Rabatt (%)</mat-label>
                <input matInput type="number" formControlName="discount">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>Leistungsbeschreibung</mat-label>
                <input matInput type="text" formControlName="serviceDescription">
            </mat-form-field>

            <mat-form-field>
                <input matInput [matDatepicker]="pickerFrom" placeholder="Bewerbungen ab ... zuordnen"
                       name="dateFrom" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>

            <info-box message="Damit werden Bewerbungen ab dem gewünschtem Datum auf diese Stelle, welche noch keiner Buchung zugewiesen wurden, automatisch dieser Buchung zugewiesen. "></info-box>

        </div>

    </div>
    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [raised]="false"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        [disabledCondition]="this.bookingForm.invalid"
                        (clickEvent)="createOrUpdateBooking()">
            Buchung speichern
        </loading-button>
    </div>
</div>
