import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AdminBookingData,
    AdminEmploymentPositionData,
    CompanyPricingConfigData,
    EmploymentCategory,
    HomeOffice,
    LabelData,
    LocationOptionsData,
} from '../../generated/data';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminCompanyResource, AdminEmploymentPositionResource, AdminPricingResource,} from '../../generated/resources';
import {Subscription} from "rxjs";
import {
    SearchableProfessionDropdownComponent
} from "../../utils/dropdowns/searchable-profession-dropdown/searchable-profession-dropdown.component";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {
    CreateOrUpdateBookingSideSheetComponent
} from "../../bookings/create-booking-dialog/create-or-update-booking-side-sheet.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";

@Component({
    selector: 'app-create-position-dialog',
    templateUrl: 'create-position-dialog.component.html',
    styleUrls: ['create-position-dialog.component.scss']
})
export class CreatePositionDialogComponent implements OnInit, OnDestroy {

    @ViewChild('professionDropdown') professionDropdown: SearchableProfessionDropdownComponent

    public employmentCategories: EmploymentCategory[] = [
        'PermanentEmployment', 'Trainee', 'DualStudies', 'VocationalTraining', 'WorkingStudent', 'MandatoryInternship', 'VoluntaryInternship', 'Thesis', 'TempoaryJob', 'ProjectWork'
    ];

    public homeOfficeCategories: HomeOffice[] = [
        "HomeOfficeAvailable" ,"OnlyOffice" , "OnlyRemote"
    ]

    positionId: number
    oldCompanyId: number = null

    public customCitySelected: boolean = false;
    public customCompanySelected: boolean = false;
    public saving: Boolean = false;

    public positionDetailsForm: FormGroup;
    public companyKnownControl = new FormControl(true)
    companyPricing: CompanyPricingConfigData
    private locationModeSubscription: Subscription;
    private companySub: Subscription;

    constructor(
        public companyResource: AdminCompanyResource,
        private fb: FormBuilder,
        private adminEmploymentPositionResource: AdminEmploymentPositionResource,
        private sideSheetRef: SideSheetRef,
        private adminPricingResource : AdminPricingResource,
        private sideSheetService: SideSheetService
    ) {
        this.positionDetailsForm = this.fb.group({
            isThirdPartyPosition: this.fb.control(false),
            prime: this.fb.control(false),
            availableSlots: this.fb.control(1, Validators.required),
            shortDescription: this.fb.control(null, Validators.required),
            customerCompanyId: this.fb.control(null, Validators.required),
            companyId: this.fb.control(null),
            googleJobPosting: this.fb.control(false),
            companyName: this.fb.control(null),
            companyAlias: this.fb.control(null),
            title: this.fb.control(null, Validators.required),
            startingDate: this.fb.control(new Date(), Validators.required),
            locationMode: this.fb.control('SpecificCities'),
            homeOffice: this.fb.control(null, Validators.required),
            weeklyHoursFrom: this.fb.control(null, Validators.required),
            weeklyHoursTo: this.fb.control(null, Validators.required),
            federalStates: this.fb.control(null),
            cities: this.fb.control(null, Validators.required),
            jobSitesInput: this.fb.control(null),
            workType: this.fb.control('FulltimeJob', Validators.required),
            webLink: this.fb.control(null),
            showCompany: this.fb.control(true)
        });
    }

    loadPricingForCompany() {
        this.companyPricing = null
        this.adminPricingResource.getPricingForCompany(this.positionDetailsForm.controls.customerCompanyId.value).then(result => {
            this.companyPricing = result
        })
    }

    ngOnInit() {

        this.companySub = this.positionDetailsForm.controls.customerCompanyId.valueChanges.subscribe(next => {
            if (next == this.oldCompanyId) return
            this.oldCompanyId = next
            this.loadPricingForCompany()
        })

        this.locationModeSubscription = this.positionDetailsForm.controls.locationMode.valueChanges.subscribe((mode) => {
            switch (mode) {
                case 'Worldwide':
                case 'Nationwide':
                    this.positionDetailsForm.controls.federalStates.setValidators(null);
                    this.positionDetailsForm.controls.federalStates.setErrors(null);
                    this.positionDetailsForm.controls.cities.setValidators(null);
                    break;
                case 'SpecificStates':
                    this.positionDetailsForm.controls.federalStates.setValidators(Validators.required);
                    this.positionDetailsForm.controls.cities.setValidators(null);
                    break;
                case 'SpecificCities':
                    this.positionDetailsForm.controls.federalStates.setValidators(null);
                    this.positionDetailsForm.controls.cities.setValidators(Validators.required);
                    break;
            }
            this.positionDetailsForm.controls.federalStates.updateValueAndValidity();
            this.positionDetailsForm.controls.cities.updateValueAndValidity();
        })
    }

    ngOnDestroy(): void {
        this.locationModeSubscription.unsubscribe();
        this.companySub?.unsubscribe()
    }


    isValid() {
        if (this.positionDetailsForm.invalid) return false

        if (this.positionDetailsForm.controls.isThirdPartyPosition.value == false)
            return true

        if (this.companyKnownControl.value && this.positionDetailsForm.controls.showCompany.value && (this.positionDetailsForm.controls.companyId.value != null || this.positionDetailsForm.controls.companyName.value != null))
            return true

        if (this.companyKnownControl.value && !this.positionDetailsForm.controls.showCompany.value && this.positionDetailsForm.controls.companyAlias.value != null)
            return true

        return !this.companyKnownControl.value && this.positionDetailsForm.controls.companyAlias.value != null;
    }

    createPosition() {
        this.saving = true;

        if (this.positionDetailsForm.controls.jobSitesInput.value != null) {
            this.positionDetailsForm.controls.jobSitesInput.setValue(this.positionDetailsForm.controls.jobSitesInput.value.split(","))
        }

        let creationData: AdminEmploymentPositionData = this.generateRequestBodyDataClass()

        this.adminEmploymentPositionResource.createPosition(creationData).then(positionId => {
            this.saving = false;
            if (this.companyPricing.mode == "Booking") {
                this.positionId = positionId.value
                this.createOrEditBookingDialog()
            } else {
                this.sideSheetRef.sheetClosed.next(positionId.value);
            }
        });
    }


    createOrEditBookingDialog(booking?: AdminBookingData) {

        let comp = this.sideSheetService.openOverlay(CreateOrUpdateBookingSideSheetComponent, 'Small')
        if(booking) {
            comp.instance.booking = booking
        } else {
            comp.instance.booking =  <AdminBookingData>{positions: [{name: this.positionDetailsForm.controls.title.value, id: this.positionId}]};
        }

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
            subscription.unsubscribe();
            this.sideSheetRef.sheetClosed.next(this.positionId);
        })
    }

    generateRequestBodyDataClass(): AdminEmploymentPositionData {

        return <AdminEmploymentPositionData>{
            internTitle: this.positionDetailsForm.controls.title.value,
            employmentPositionShortDescription: this.positionDetailsForm.controls.shortDescription.value,
            title: this.positionDetailsForm.controls.title.value,
            contents: [],
            googleJobPosting: this.positionDetailsForm.controls.googleJobPosting.value,
            customerCompanyId: this.positionDetailsForm.controls.customerCompanyId.value,
            hiringCompanyId: this.positionDetailsForm.controls.companyId.value,
            hiringCompanyName: this.positionDetailsForm.controls.companyName.value,
            webLink: this.positionDetailsForm.controls.webLink.value,
            employmentCategory: this.positionDetailsForm.controls.workType.value,
            startingDate: this.positionDetailsForm.controls.startingDate.value,
            isThirdPartyPosition: this.positionDetailsForm.controls.isThirdPartyPosition.value,
            companyAlias: this.positionDetailsForm.controls.companyAlias.value,
            showCompany: this.positionDetailsForm.controls.showCompany.value,
            prime: this.positionDetailsForm.controls.prime.value,
            locations: <LocationOptionsData>{
                cityIds: this.positionDetailsForm.controls.cities.value,
                federalStateIds: this.positionDetailsForm.controls.federalStates.value == null ? [] : this.positionDetailsForm.controls.federalStates.value,
                mode: this.positionDetailsForm.controls.locationMode.value
            },
            careerChanger: false,
            availableSlots: this.positionDetailsForm.controls.availableSlots.value,
            origin: "Uniwunder",
            id: null,
            companyAllowGoogleJobPosting: false,
            adminNote: null,
            applicationRequirementsInfo: null,
            careerChangerDescription: null,
            priority: null,
            professionId: null,
            professionTitle: null,
            state: null,
            weeklyHoursFrom: this.positionDetailsForm.controls.weeklyHoursFrom.value,
            weeklyHoursTo: this.positionDetailsForm.controls.weeklyHoursTo.value,
            yearlySalaryFrom: null,
            yearlySalaryTo: null,
            homeOffice: this.positionDetailsForm.controls.homeOffice.value,
            draftForwarding: false,
            currentBookingId: null,
            komboJobId: null,
            companyPricingMode: null
        }
    }

    showCustomCityInput(show: boolean) {
        this.customCitySelected = show;
        if (show) {
            this.positionDetailsForm.controls.cities.clearValidators();
            this.positionDetailsForm.controls.cities.setValue(null)
            this.positionDetailsForm.controls.cities.updateValueAndValidity();
            this.positionDetailsForm.controls.jobSitesInput.setValidators(Validators.required);
        } else {
            this.positionDetailsForm.controls.jobSitesInput.clearValidators();
            this.positionDetailsForm.controls.jobSitesInput.setValue(null);
            this.positionDetailsForm.controls.jobSitesInput.updateValueAndValidity();
            this.positionDetailsForm.controls.cities.setValidators(Validators.required);
        }
    }

    showCustomCompanyInput(show: boolean) {
        this.customCompanySelected = show;
        if (show) {
            this.positionDetailsForm.controls.companyId.clearValidators();
            this.positionDetailsForm.controls.companyId.setValue(null);
            this.positionDetailsForm.controls.companyId.updateValueAndValidity();
            this.positionDetailsForm.controls.companyName.setValidators(Validators.required);
        } else {
            this.positionDetailsForm.controls.companyName.clearValidators();
            this.positionDetailsForm.controls.companyName.setValue(null);
            this.positionDetailsForm.controls.companyName.updateValueAndValidity();
            this.positionDetailsForm.controls.companyId.setValidators(Validators.required);
        }
    }

    saveCustomCities() {
        if (this.positionDetailsForm.controls.jobSitesInput.value == null || this.positionDetailsForm.controls.jobSitesInput.value == '') return
        this.positionDetailsForm.controls.jobSitesInput.setValue(this.positionDetailsForm.controls.jobSitesInput.value.split(","))
    }

    getCompanyPlaceholder() {
        return this.positionDetailsForm.controls.isThirdPartyPosition.value ? "Personalvermittler" : "Unternehmen";
    }

    getCurrentLocationMode() {
        return this.positionDetailsForm.controls.locationMode.value
    }

}
