<loading-spinner [loadingBool]="loadingCompanyData">
    <div id="information-wrapper" fxLayout="column" class="width-100 height-100" *ngIf="customerCompanyData">
        <form [formGroup]="positionFormGroup" fxLayout="column" fxLayoutGap="10px" class="width-100">
            <h3>Stelle</h3>
            <div fxLayout="row" fxLayoutGap="20px" class="width-100" fxLayoutAlign="start center">
                <mat-form-field style="width: 80%">
                    <input matInput type="text" placeholder="Stellentitel" formControlName="title">
                </mat-form-field>

                <mat-checkbox formControlName="prime">Primestelle</mat-checkbox>
            </div>

            <div fxLayout="row" fxLayoutGap="20px" class="width-100">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" formControlName="startingDate"
                           placeholder="Stelle verfügbar ab">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Einstellungsart</mat-label>
                    <mat-select formControlName="employmentCategory">
                        <mat-option *ngFor="let category of employmentCategories" [value]="category">
                            {{category | workType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <input matInput required placeholder="Volumen" type="number" formControlName="availableSlots">
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="20px" class="width-100">
                <mat-form-field>
                    <input matInput placeholder="Jahresgehalt von" type="number" formControlName="yearlySalaryFrom">
                    <span matSuffix>€</span>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Jahresgehalt bis" type="number" formControlName="yearlySalaryTo">
                    <span matSuffix>€</span>
                </mat-form-field>
            </div>

            <info-box
                    [message]="'Es können auch Gesamtvergütungen (z. B. bei Praktika) oder Stundenlöhne (z. B. bei Werkstudententätigkeiten) angegeben werden. Bitte in solch einem Fall die Vergütung in eines der Felder eintragen und das andere leer lassen.'">
            </info-box>


            <div fxLayout="row" fxLayoutGap="20px" class="width-100">
                <mat-form-field>
                    <input matInput placeholder="Link zur Stelle" type="text" formControlName="webLink">
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutGap="20px" class="width-100">
                <mat-form-field>
                    <mat-label>Ortsangabemodus</mat-label>
                    <mat-select matInput placeholder="Modus" formControlName="locationsMode">
                        <mat-option *ngFor="let option of locationModes" [value]="option">
                            {{option | locationPreferencesMode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <app-searchable-city-dropdown
                        class="width-100"
                        [multiple]="true"
                        *ngIf="positionFormGroup?.controls['locationsMode'].value == 'SpecificCities' && position"
                        [control]="positionFormGroup.controls['locationsCities']"
                        [placeholder]="'Städte'">
                </app-searchable-city-dropdown>

                <app-searchable-federal-sate-dropdown
                        class="width-100"
                        [multiple]="true"
                        [placeholder]="'Bundesländer'"
                        [control]="positionFormGroup.controls['locationsFederalStates']"
                        *ngIf="positionFormGroup?.controls['locationsMode'].value == 'SpecificStates'">
                </app-searchable-federal-sate-dropdown>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field style="width: 380px">
                    <input matInput required placeholder="Wöchentliche Stunden von" type="number"
                           formControlName="weeklyHoursFrom">
                </mat-form-field>

                <mat-form-field style="width: 380px">
                    <input matInput required placeholder="Wöchentliche Stunden bis" type="number"
                           formControlName="weeklyHoursTo">
                </mat-form-field>

                <mat-form-field style="width: 380px">
                    <mat-label>HomeOffice*</mat-label>
                    <mat-select formControlName="homeOffice">
                        <mat-option *ngFor="let type of homeOfficeCategories"
                                    [value]="type">{{type | homeOffice}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <mat-form-field>
                <textarea matInput placeholder="Kurzbeschreibung der Tätigkeit" rows="3"
                          formControlName="shortDescription" maxlength="300"></textarea>
                <mat-hint
                        align="end">{{positionFormGroup.controls.shortDescription.value?.length ? positionFormGroup.controls.shortDescription.value?.length : '0'}}
                    / 300
                </mat-hint>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-checkbox formControlName="draftForwarding">Bewerbungen ohne CV akzeptieren</mat-checkbox>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-checkbox formControlName="googleJobPosting">GoogleJobPosting erlauben</mat-checkbox>
            </div>

            <info-box *ngIf="!position?.companyAllowGoogleJobPosting"
                      [message]="'Du musst erst am Unternehmenn einstellen, dass es allgemein Google Job Postings erlaubt'">
            </info-box>

            <mat-form-field>
                <textarea matInput placeholder="Geforderte Unterlagen für die Bewerbung (vom Unternehmen)" rows="2"
                          formControlName="applicationRequirementsInfo"></textarea>
            </mat-form-field>


            <div fxLayout="column" class="width-100">
                <h3>Unternehmen</h3>

                <div fxLayout="row">
                    <b *ngIf="!position.isThirdPartyPosition">Kunde: </b>
                    <b *ngIf="position.isThirdPartyPosition">Personalvermittler: </b>
                    <span class="company">{{customerCompanyData.name}}</span>

                    <div *ngIf="position.isThirdPartyPosition" class="m-left20">
                        <b>Externes Unternehmen: </b><span id="company">{{position.hiringCompanyName}}</span>
                    </div>
                </div>

                <mat-checkbox class="margin-top20 m-bottom10" formControlName="showCompany">Unternehmen anzeigen
                </mat-checkbox>

                <mat-form-field *ngIf="!positionFormGroup.controls['showCompany'].value">
                    <input matInput placeholder="Firmenpseudonym" type="text" formControlName="companyAlias">
                </mat-form-field>

                <div *ngIf="position.komboJobId">
                    <div style="color: green">Stelle ist mit Kombo verbunden</div>
                    <b>Kombo Stellen-ID: </b><span>{{position.komboJobId}}</span>
                </div>

            </div>
        </form>
    </div>
</loading-spinner>
