import {CompanyData} from "./company-data"
import {EmploymentCategory} from "./employment-category"
import {EmploymentPositionOrigin} from "./employment-position-origin"
import {ProfessionTreeData} from "./profession-tree-data"
import {EmploymentPositionState} from "./employment-position-state"

export class EmploymentPositionDisplayData {
    apiPositionLogoUrl: string | null = null;
    company = new CompanyData();
    creationDate: (Date | null) = null;
    employmentCategory: EmploymentCategory = null;
    hiringCompanyName: string | null = null;
    hiringCompanyWebsite: string | null = null;
    id: number | null = null;
    isThirdPartyPosition: boolean = null;
    locationDescription: string = null;
    origin: EmploymentPositionOrigin | null = null;
    primePosition: boolean = null;
    professionTree: ProfessionTreeData | null = null;
    startingDate: (Date | null) = null;
    state: EmploymentPositionState = null;
    title: string = null;
    webLink: string | null = null;
}