import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StudyInfoResource} from '../../../generated/resources';

@Component({
  selector: 'app-career-event-details-dialog',
  templateUrl: './career-event-details-dialog.component.html',
  styleUrls: ['./career-event-details-dialog.component.scss']
})
export class CareerEventDetailsDialogComponent implements OnInit {

  form: FormGroup;
  careerEventType: string;

  customCareerEventTypes: any[] = [
    {value: 'Job', display: 'Beschäftigung'},
    {value: 'Internship', display: 'Praktikum'},
    {value: 'Freelancing', display: 'Freiberufliche Tätigkeit'},
    {value: 'Apprenticeship', display: 'Ausbildung'},
    {value: 'Education', display: 'Schulausbildung'},
    {value: 'Training', display: 'Weiterbildung'},
    {value: 'SemesterAbroad', display: 'Auslandssemester'},
    {value: 'Volunteering', display: 'Ehrenamt'}
  ];

  saving;
  studiId: any;

  educationGraduationType = [
    {value: 'highschoolDiploma', display: 'Abitur'},
    {value: 'advancedTechnicalCertificate', display: 'Fachhochschulreife'},
    {value: 'secondarySchoolCertificate', display: 'Realschulabschluss'},
    {value: 'lowerSecondarySchoolCertificate', display: 'Hauptschulabschluss'},
  ];

  cantFindSubject = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CareerEventDetailsDialogComponent>,
    private fb: FormBuilder,
    private studyInfoResource: StudyInfoResource
  ) {
    this.careerEventType = data.type;

    this.studiId = data.id;

    switch (data.type) {
      case 'Studies':
        this.form = this.fb.group({
          universityName: [data.universityName, Validators.required],
          subjectName: [data.subjectName, Validators.required],
          degreeType: [data.degreeType, Validators.required],
          actualOrExpectedYearOfGraduation: [new Date(data.endDate), Validators.required],
          startDate: [new Date(data.startDate), Validators.required],
          grade: [data.grade, [Validators.required, this.validateGrade()]]
        });
        break;
      default:
        this.form = this.fb.group({
          description: [data.description],
          endDate: [new Date(data.endDate), Validators.required],
          startDate: [new Date(data.startDate), Validators.required],
          title: [data.title, [Validators.required]],
          type: [this.customCareerEventTypes.filter(e => e.value == data.type)[0], Validators.required]
        });
        break;
    }
  }

  ngOnInit() {
  }

  validateGrade(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value < 1.0 || control.value > 4.5) {
        return {invalidGrade: true};
      }

      return null;
    };

  }


  close() {
    this.dialogRef.close(false);
  }

  toggleSubjecthelp() {
    if (this.cantFindSubject) {
      this.subjectHelpOff();
    } else {
      this.subjectHelpOn();
    }
  }

  subjectHelpOff() {
    this.cantFindSubject = false;
    this.form.controls.subjectId.setValidators(Validators.required);
    this.form.controls.subjectName.setValidators(null);
  }

  subjectHelpOn() {
    this.cantFindSubject = true;
    this.form.controls.subjectId.setValidators(null);
    this.form.controls.subjectName.setValidators(Validators.required);
  }
}
