import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'talentMatchingScore'
})
export class TalentMatchingScorePipe implements PipeTransform {

  transform(value: number): string {

    let matchingString: string = '';

    if (value >= 2 && value <= 5) {
      matchingString = (5 - value + 1) + ". Grad"
    } else {
      matchingString = "> 4. Grad"
    }

    return matchingString;
  }
}
