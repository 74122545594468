import {AdminBookingData, BookingCreateOrUpdateData, SalesProductData} from "../../generated/data";
import {AdminBookingResource, AdminSalesProposalResource} from "../../generated/resources";
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-create-booking-dialog',
  templateUrl: './create-or-update-booking-side-sheet.component.html',
  styleUrls: ['./create-or-update-booking-side-sheet.component.scss']
})
export class CreateOrUpdateBookingSideSheetComponent implements OnInit, OnDestroy {

    @Input()
    booking?: AdminBookingData;

    @Input()
    dialog: boolean = true


  loading: boolean = false
    formReady: boolean = false
  bookingForm: FormGroup
    products: SalesProductData[] = []

    bookingFormSub: Subscription


  constructor(
    public sideSheetRef: SideSheetRef,
    private adminBookingResource: AdminBookingResource,
    private fb: FormBuilder,
    private adminSalesProposalResource: AdminSalesProposalResource
  ) {}

    ngOnDestroy(): void {
        this.bookingFormSub?.unsubscribe()
    }

  ngOnInit(): void {
    this.initBookingData()
    this.getProducts()
  }

    getProducts() {
        this.adminSalesProposalResource.getProducts().then(result => {
            this.products = result
            this.initForm()
        })
    }

  initForm() {
    this.bookingForm = this.fb.group({
      title: [{value: this.booking?.title, disabled: this.booking.id}, [Validators.required, Validators.pattern(/\S/)]], // must include a non-whitespace character
      size: [{value: this.booking?.size ?? 15, disabled: this.booking.id}, Validators.required],
      resupply: [{value: this.booking?.resupply ?? 0, disabled: false}, Validators.required],
      price: [{value: this.booking?.price ?? 1500, disabled: this.booking.id}, Validators.required],
      companyId: [{value: this.booking?.company?.id, disabled: this.booking.id}, Validators.required],
      discount: [{value: this.booking?.discount, disabled: this.booking.id}, Validators.required],
      product: [{value: this.products.find(i => i.id == this.booking?.productId),disabled: this.booking.id}, Validators.required],
      serviceDescription: [{value: this.booking?.serviceDescription, disabled: this.booking.id}, Validators.required],
      startDate: [null]
    });

      this.bookingFormSub = this.bookingForm.controls.product.valueChanges.subscribe(next => {
          this.bookingForm.patchValue({
              title: next.title,
              size: next.size,
              price: next.originalPrice,
              discount: next.discount,
              serviceDescription: next.serviceDescription
          })
      })

      this.formReady = true
  }

  close(result?: AdminBookingData) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  initBookingData() {
    if (!this.booking) {
      this.booking = <AdminBookingData>{positions: []};
    }
  }

  createOrUpdateBooking() {
    this.loading = true

    const booking: BookingCreateOrUpdateData = {
      id: this.booking?.id,
        companyId: this.bookingForm.value.companyId,
      title: this.bookingForm.controls.title.value,
      price: this.bookingForm.controls.price.value,
      size: this.bookingForm.controls.size.value,
      resupply: this.bookingForm.controls.resupply.value,
        discount: this.bookingForm.controls.discount.value,
        productId: this.bookingForm.controls.product.value.id,
        serviceDescription: this.bookingForm.controls.serviceDescription.value,
      assignedPositionIds: []
    }

    this.adminBookingResource.createOrUpdateBooking(booking, {dateFrom: this.bookingForm.controls.startDate.value}).then(result => {
      this.close(result)
    });
  }
}
