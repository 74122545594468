<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="10px" class="filter-wrapper">
                <mat-form-field>
                    <mat-label>Zeitspanne</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input [formControl]="startDate" matStartDate placeholder="Eingestellt ab">
                        <input [formControl]="endDate" matEndDate placeholder="Eingestellt bis">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <searchable-company-dropdown
                        placeholder="Firma"
                        [multiple]="false"
                        [required]="false"
                        [control]="companyControl">
                </searchable-company-dropdown>

                <searchable-talent-agent-dropdown
                        placeholder="Talentagent"
                        [multiple]="false"
                        [required]="false"
                        [control]="talentAgentControl">
                </searchable-talent-agent-dropdown>

                <key-accounter-dropdown
                        placeholder="Key Accounter"
                        [multiple]="false"
                        [required]="false"
                        [control]="keyAccounterControl"
                ></key-accounter-dropdown>


            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <loading-button
                        matTooltip="Aktualisieren"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        (clickEvent)="loadData()">
                    <i class="mdi mdi-refresh dark filter-color"></i>
                </loading-button>

                <table-download-button color="primary"
                                       excelName="Anmeldungen.xlsx"
                                       [buttonClass]="'main-action-icon'"
                                       [matTable]="hiredStatisticsTable">
                </table-download-button>
            </div>

        </div>
        <div style="width: 100%; text-align: end">
            <div>Einstellungen Gefunden: <b>{{totalSize}}</b></div>
        </div>
    </div>

    <loading-spinner [loadingBool]="loading">

    <table mat-table [dataSource]="stats" class="max-table" #hiredStatisticsTable>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Tag</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.date != null">{{element.date | date: 'dd.MM.yy'}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="talent">
            <th mat-header-cell *matHeaderCellDef>Talent</th>
            <td mat-cell *matCellDef="let element"><span class="link" target="_blank" (click)="navigateTo('/talents/' + element.talentId)">{{element.talentName}}</span></td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Unternehmen</th>
            <td mat-cell *matCellDef="let element"><span class="link" target="_blank" (click)="navigateTo('/companies/' + element.companyId)">{{element.companyName}}</span></td>
        </ng-container>

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Stelle</th>
            <td mat-cell *matCellDef="let element">
                <button mat-button (click)="openPositionDetailsDialog(element.positionId)">{{element.positionTitle}}</button>
            </td>
        </ng-container>

        <ng-container matColumnDef="keyAccounter">
            <th mat-header-cell *matHeaderCellDef>Key Accounter</th>
            <td mat-cell *matCellDef="let element"> {{element.keyAccounterName}}</td>
        </ng-container>

        <ng-container matColumnDef="talentAgent">
            <th mat-header-cell *matHeaderCellDef>Talentagent</th>
            <td mat-cell *matCellDef="let element">
                {{element.talentAgentName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="hiringDetails">
            <th mat-header-cell *matHeaderCellDef>Vertrag</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openHiringDetailsDialog(element)">
                    <i class="mdi mdi-file-document-edit"></i>
                </button>

                <i class="mdi mdi-file-alert-outline" matTooltip="Für diese Einstellung existiert noch keine Abrechnung!" *ngIf="!element.invoiceId"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === row"></tr>
    </table>

        <div *ngIf="stats.length == 0" class="nothing-found">Es wurde nichts gefunden</div>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>

