import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'date-time-select',
  templateUrl: './date-time-select.component.html',
  styleUrls: ['./date-time-select.component.scss']
})
export class DateTimeSelectComponent implements OnInit {

  dateTimeFormGroup: FormGroup;
  loading = true;
  initializeWithoutValue = false;

  @Input() control: AbstractControl;
  @Input() placeholder: string = 'Wähle ein Datum';
  @Input() minDate: Date = new Date(1970, 0, 1)
  @Input() maxDate: Date = new Date(2120, 0, 1)


  @ViewChild('minuteInput') minuteInput: ElementRef;
  @ViewChild('hourInput') hourInput: ElementRef;

  hourAndMinuteStartValue: { hour: number, minute: number } = {hour: null, minute: null};

  constructor(
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {

    if (this.control.value) {
      this.initializeFormWithValue();
    } else {
      this.initializeFormWithoutValue()
    }

    this.hourAndMinuteStartValue = {
      hour: this.dateTimeFormGroup.controls.innerHourControl.value,
      minute: this.dateTimeFormGroup.controls.innerMinuteControl.value
    }

    if (this.hourAndMinuteStartValue.hour != null && (this.hourAndMinuteStartValue.hour == 0 || this.hourAndMinuteStartValue.hour < 10)) {
      this.dateTimeFormGroup.controls['innerHourControl'].setValue("0" + this.hourAndMinuteStartValue.hour)
    } else {
      this.dateTimeFormGroup.controls['innerHourControl'].setValue(this.hourAndMinuteStartValue.hour)
    }

    if (this.hourAndMinuteStartValue.hour != null && (this.hourAndMinuteStartValue.minute == 0 || this.hourAndMinuteStartValue.minute < 10)) {
      this.dateTimeFormGroup.controls['innerMinuteControl'].setValue("0" + this.hourAndMinuteStartValue.minute)
    } else {
      this.dateTimeFormGroup.controls['innerMinuteControl'].setValue(this.hourAndMinuteStartValue.minute)
    }

    this.checkMinuteInput();
    this.checkHourInput();
  }

  initializeFormWithValue() {
    let date = new Date(this.control.value)
    this.dateTimeFormGroup = this.fb.group({
      date: new FormControl({value: this.control.value, disabled: this.control.disabled}, [Validators.required]),
      innerMinuteControl: new FormControl({
        value: date.getMinutes(),
        disabled: this.control.disabled
      }, [Validators.pattern('^[0-9]{2}'), Validators.required]),
      innerHourControl: new FormControl({
        value: date.getHours(),
        disabled: this.control.disabled
      }, [Validators.pattern('^[0-9]{2}'), Validators.required])
    });
    this.subscribeOnChanges()
  }

  initializeFormWithoutValue() {
    this.initializeWithoutValue = true;
    this.dateTimeFormGroup = this.fb.group({
      date: new FormControl({value: null, disabled: this.control.disabled}, [Validators.required]),
      innerMinuteControl: new FormControl({
        value: null,
        disabled: this.control.disabled
      }, [Validators.pattern('^[0-9]{2}'), Validators.required]),
      innerHourControl: new FormControl({
        value: null,
        disabled: this.control.disabled
      }, [Validators.pattern('^[0-9]{2}'), Validators.required])
    })
    this.subscribeOnChanges()
  }

  subscribeOnChanges() {
    this.dateTimeFormGroup.valueChanges.subscribe(() => {
      if (this.dateTimeFormGroup.valid) {
        this.control.setErrors(null)
        this.control.updateValueAndValidity()
        let newDate = new Date(this.dateTimeFormGroup.controls['date'].value)
        newDate.setHours(this.dateTimeFormGroup.controls['innerHourControl'].value, this.dateTimeFormGroup.controls['innerMinuteControl'].value, 0, 0);
        this.control.setValue(newDate)
      } else {
        this.control.setValue(null)
        this.control.updateValueAndValidity()
      }
      this.loading = false;
    })
  }

  //time input

  checkMinuteInput() {
    this.dateTimeFormGroup.controls['innerMinuteControl'].valueChanges.subscribe(() => {
      if (this.dateTimeFormGroup.controls['innerMinuteControl'].value > 59) {
        this.dateTimeFormGroup.controls['innerMinuteControl'].setValue(59)
      }

      if (this.dateTimeFormGroup.controls['innerMinuteControl'].value < 0) {
        this.dateTimeFormGroup.controls['innerMinuteControl'].setValue(0)
      }

      if (this.dateTimeFormGroup.controls['innerMinuteControl'].value != 0 && (!this.dateTimeFormGroup.controls['innerMinuteControl'].value || this.dateTimeFormGroup.controls['innerMinuteControl'].value.toString().length <= 0)) {
        this.hourInput.nativeElement.focus();
      }
    })
  }

  checkHourInput() {
    this.dateTimeFormGroup.controls['innerHourControl'].valueChanges.subscribe(() => {

      if (this.dateTimeFormGroup.controls['innerHourControl'].value > 23) {
        this.dateTimeFormGroup.controls['innerHourControl'].setValue(23)
      }
      if (this.dateTimeFormGroup.controls['innerHourControl'].value < 0) {
        this.dateTimeFormGroup.controls['innerHourControl'].setValue(0)
      }

      if (this.dateTimeFormGroup.controls['innerHourControl'].value?.toString().length >= 2) {
        this.dateTimeFormGroup.controls['innerMinuteControl'].setValue(null)
        this.minuteInput.nativeElement.focus();
      }
    })
  }

  onBlurHourInput() {
    if (this.dateTimeFormGroup.controls['innerHourControl'].value < 10 && this.dateTimeFormGroup.controls['innerHourControl'].value && this.dateTimeFormGroup.controls['innerHourControl'].value.toString().length < 2) {
      this.dateTimeFormGroup.controls['innerHourControl'].setValue("0" + this.dateTimeFormGroup.controls['innerHourControl'].value)
    }
  }

  onBlurMinuteInput() {
    if (this.dateTimeFormGroup.controls['innerMinuteControl'].value < 10 && this.dateTimeFormGroup.controls['innerMinuteControl'].value && this.dateTimeFormGroup.controls['innerMinuteControl'].value.toString().length < 2) {
      this.dateTimeFormGroup.controls['innerMinuteControl'].setValue("0" + this.dateTimeFormGroup.controls['innerMinuteControl'].value)
    }
  }

  ifEmptyFoucsHourInput() {
    if (this.dateTimeFormGroup.controls.innerMinuteControl.value?.toString().length <= 0) {
      this.hourInput.nativeElement.focus()
    }
  }
}
