import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdminAdminResource, AdminWebinarResource} from "../../generated/resources";
import {AdminData, LabelData, WebinarData} from "../../generated/data";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {MatDialog} from "@angular/material/dialog";
import {EditImageDialogComponent} from "../../utils/edit-image-dialog/edit-image-dialog.component";
import {FileService} from "../../services/file.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-webinar-create-dialog',
  templateUrl: './webinar-create-dialog.component.html',
  styleUrls: ['./webinar-create-dialog.component.scss']
})
export class WebinarCreateDialogComponent implements OnInit {

  webinarForm: FormGroup
  admins: LabelData[] = []

  webinar: WebinarData | null = null
  webinarPreviewImage: any = null
  webinarImageData: any = null

  constructor(
      private fb: FormBuilder,
      private webinarResource: AdminWebinarResource,
      private sideSheetRef: SideSheetRef,
      private adminResource: AdminAdminResource,
      private dialog: MatDialog,
      private fileService: FileService,
      private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    this.initForm()
    this.loadSpeakers()
    this.getImage()
  }

  initForm() {
    this.webinarForm = this.fb.group({
      title: this.fb.control(this.webinar?.title, Validators.required),
      shortDescription: this.fb.control(this.webinar?.shortDescription, Validators.required),
      description: this.fb.control(this.webinar?.description, Validators.required),
      dateTime: this.fb.control(this.webinar?.dateTime, Validators.required),
      joinLink: this.fb.control(this.webinar?.joinLink, Validators.required),
      speakers: this.fb.control(this.webinar?.speakers, Validators.required),
    })

  }

  loadSpeakers() {
    this.adminResource.getAllAdmins().then(result => {
       this.admins = result.map(admin => ({
         id: admin.id,
         name: admin.firstName + ' ' + admin.lastName
      }))

      const speakerIds = this.webinar.speakers.map(speaker => speaker.id)
      const selectedSpeakers = this.admins.filter(admin => speakerIds.includes(admin.id))

      this.webinarForm.controls.speakers.setValue(selectedSpeakers)
    })
  }

  save() {
    if(this.webinarForm.invalid) return


    this.webinarResource.createOrUpdateWebinar({
      id: this.webinar?.id,
      title: this.webinarForm.controls.title.value,
      shortDescription: this.webinarForm.controls.shortDescription.value,
      description: this.webinarForm.controls.description.value,
      dateTime: new Date(this.webinarForm.controls.dateTime.value),
      joinLink: this.webinarForm.controls.joinLink.value,
      speakers: this.webinarForm.controls.speakers.value,
      active: this.webinar?.active ? this.webinar.active : false
    }).then(result => {
      if(result.id) {
        if (this.webinarImageData) this.fileService.uploadWebinarImage(result.id, this.webinarImageData).subscribe()
        this.sideSheetRef.sheetClosed.next(result.id)
      }
    })
  }

  uploadImage() {
    let dialog = this.dialog.open(EditImageDialogComponent, {
      data: {
        title: 'Header Bild hochladen',
        aspectRatio: 2,
        roundCropping: false
      },
      panelClass: 'custom-dialog-container'
    })

    let sub = dialog.afterClosed().subscribe(data => {
      if(data) {
        this.webinarImageData = data
        const blob = new Blob([data.file])
        this.webinarPreviewImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
      }
      sub.unsubscribe()
    })
  }

  getImage() {
    if (!this.webinar?.id) return
    this.fileService.getWebinarImage(this.webinar.id).subscribe(result => {
      this.webinarPreviewImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result))
    })
  }

}
