import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    AddressData,
    AdminCompanyCreationData,
    AdminRoleDisplayData, CompanyInvoiceInformationData,
    CompanyPricingConfigData,
    LabelData
} from "../../generated/data";
import {AdminAdminResource, AdminCompanyResource, AdminPricingResource} from "../../generated/resources";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {Router} from "@angular/router";

@Component({
    selector: 'app-new-company-dialog',
    templateUrl: './new-company-dialog.component.html',
    styleUrls: ['./new-company-dialog.component.css']
})
export class NewCompanyDialogComponent implements OnInit {

    loading: boolean = false;
    pricingValid: boolean = false;
    companyCreationData: AdminCompanyCreationData = <AdminCompanyCreationData>{};
    keyAccounterData: AdminRoleDisplayData[] = [];
    form: FormGroup;
    companyInvoiceAddressForm: FormGroup
    pricing: CompanyPricingConfigData = <CompanyPricingConfigData>{}

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private adminCompanyResource: AdminCompanyResource,
        private adminAdminResource: AdminAdminResource,
        private sideSheetRef: SideSheetRef,
        private adminPricingResource: AdminPricingResource
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            companyNameControl: [null, Validators.required],
            companyDescriptionControl: [null, Validators.required],
            companySizeControl: [null, Validators.required],
            companyWebsiteControl: null,
            companyKeyAccounterControl: [null]
        })

        this.companyInvoiceAddressForm = this.fb.group({
            streetNameControl: [null, Validators.required],
            streetNumberControl: [null, Validators.required],
            zipCodeControl: [null, Validators.required],
            cityIdControl: [null, Validators.required],
            cityNameControl: [null],
            invoiceEmailControl: [null, Validators.required],
            invoiceHolderFirstNameControl: [null, Validators.required],
            invoiceHolderLastNameControl: [null, Validators.required],
            taxNumberControl: [null, Validators.pattern(/^DE\s?[0-9]{9}$/)],
            purchaseInfoControl: [null]
        })

        this.loadKeyAccounter();
    }

    loadKeyAccounter() {
        this.adminAdminResource.getAllKeyAccounter().then(keyAccounterData => {
            this.keyAccounterData = keyAccounterData;
        });
    }

    createCompany() {
        this.loading = true;

        this.companyCreationData.name = this.form.controls.companyNameControl.value
        this.companyCreationData.companySize = this.form.controls.companySizeControl.value
        this.companyCreationData.shortCompanyInfo = this.form.controls.companyDescriptionControl.value
        this.companyCreationData.website = this.form.controls.companyWebsiteControl.value
        this.companyCreationData.keyAccounterId = this.form.controls.companyKeyAccounterControl.value

        this.companyCreationData.address = <AddressData>{
            name: null,
            streetName: this.companyInvoiceAddressForm.controls.streetNameControl.value,
            streetNumber: this.companyInvoiceAddressForm.controls.streetNumberControl.value,
            zipCode: this.companyInvoiceAddressForm.controls.zipCodeControl.value,
            cityId: this.companyInvoiceAddressForm.controls.cityIdControl.value,
            cityName: this.companyInvoiceAddressForm.controls.cityNameControl.value,
        }

        this.companyCreationData.invoiceInformation = <CompanyInvoiceInformationData>{
            companyId: null,
            invoiceEmail: this.companyInvoiceAddressForm.controls.invoiceEmailControl.value,
            invoiceHolderFirstName: this.companyInvoiceAddressForm.controls.invoiceHolderFirstNameControl.value,
            invoiceHolderLastName: this.companyInvoiceAddressForm.controls.invoiceHolderLastNameControl.value,
            taxNumber: this.companyInvoiceAddressForm.controls.taxNumberControl.value,
            additionalPurchaseInformation: this.companyInvoiceAddressForm.controls.purchaseInfoControl.value
        }

        this.adminCompanyResource.createCompany(this.companyCreationData).then(company => {

            this.pricing.company = <LabelData>{name: company.name, id: company.id}
            this.adminPricingResource.createOrUpdatePricing(this.pricing).then(result => {
                this.router.navigate([`companies/${company.id}`])
                this.sideSheetRef.sheetClosed.next();
            })
        });
    }
}
