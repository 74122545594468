import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    AdminEmploymentPositionResource,
    AdminProfessionResource,
    AdminTalentResource,
    ProfessionFieldPreferenceResource,
    ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {UtilityService} from "../../../utils/utility.service";

@Component({
    selector: 'app-matching-analyze-sheet-component',
    templateUrl: './matching-analyze-sheet-component.component.html',
    styleUrls: ['./matching-analyze-sheet-component.component.scss']
})
export class MatchingAnalyzeSheetComponentComponent implements OnInit {

    //Input
    talentId: number;
    positionId: number;

    constructor(
        private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
        private professionResource: AdminProfessionResource,
        private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
        private employmentPositionResource: AdminEmploymentPositionResource,
        private talentResource: AdminTalentResource,
        public utilityService: UtilityService,
        private snackBar: MatSnackBar,
        private cd: ChangeDetectorRef,
        private dialogService: SideSheetService,
        private sideSheetRef: SideSheetRef,
    ) {
    }

    ngOnInit(): void {
    }

}
