<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <mat-tab-group class="layout-padding">
            <mat-tab label="Talent Talentagent-LandingPage">
                <div fxLayout="column" class="layout-padding" style="min-height: 100%" *ngIf="talentLadingPage">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field>
                            <mat-label>Title</mat-label>
                            <input matInput type="text" [(ngModel)]="talentLadingPage.title">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Subtitle</mat-label>
                            <input matInput type="text" [(ngModel)]="talentLadingPage.subTitle">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>ButtonText</mat-label>
                            <input matInput type="text" [(ngModel)]="talentLadingPage.buttonText">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>ButtonUrl (Https required!)</mat-label>
                            <input matInput type="text" [(ngModel)]="talentLadingPage.buttonUrl">
                        </mat-form-field>

                        <loading-button buttonClass="mat-primary"
                                        [loadingBool]="loading"
                                        (clickEvent)="updateTalentLandingPage()"
                                        matTooltip="Speichern">
                            <i class="mdi mdi-content-save main-action-icon"></i>
                        </loading-button>
                    </div>
                    <loading-spinner [loadingBool]="loading">
                        <div>Content 1 (oben)</div>
                        <ngx-codemirror [(ngModel)]="talentLadingPage.content1"
                                        [ngStyle]="{'height': codeMirrorHeight + 'px'}"
                                        [options]="{
                lineNumbers: true,
                height: '50px',
                theme: 'material',
                mode:'htmlmixed'
}"></ngx-codemirror>

                        <div style="margin-top: 30px">Content 2 (unten)</div>
                        <ngx-codemirror [(ngModel)]="talentLadingPage.content2"
                                        [ngStyle]="{'height': codeMirrorHeight + 'px'}"
                                        [options]="{
                lineNumbers: true,
                theme: 'material',
                mode:'htmlmixed'
}"></ngx-codemirror>

                    </loading-spinner>
                </div>
            </mat-tab>
            <mat-tab label="Company Talentagent-LandingPage">
                <div fxLayout="column" class="layout-padding" style="min-height: 100%" *ngIf="companyLandingPage">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field>
                            <mat-label>Title</mat-label>
                            <input matInput type="text" [(ngModel)]="companyLandingPage.title">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Subtitle</mat-label>
                            <input matInput type="text" [(ngModel)]="companyLandingPage.subTitle">
                        </mat-form-field>

                        <loading-button buttonClass="mat-primary"
                                        [loadingBool]="loading"
                                        (clickEvent)="updateCompanyLandingPage()"
                                        matTooltip="Speichern">
                            <i class="mdi mdi-content-save main-action-icon"></i>
                        </loading-button>
                    </div>
                    <loading-spinner [loadingBool]="loading">
                        <div>Content</div>
                        <ngx-codemirror [(ngModel)]="companyLandingPage.content"
                                        [ngStyle]="{'height': codeMirrorHeight + 'px'}"
                                        [options]="{
                lineNumbers: true,
                height: '50px',
                theme: 'material',
                mode:'htmlmixed'
}"></ngx-codemirror>

                    </loading-spinner>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>
