import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AdminResource} from '../../generated/resources';

@Component({
  selector: 'app-talent-note-dialog',
  templateUrl: './talent-note-dialog.component.html',
  styleUrls: ['./talent-note-dialog.component.css']
})
export class TalentNoteDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private adminResource: AdminResource,
    private dialogRef: MatDialogRef<TalentNoteDialogComponent>
  ) {

    this.adminResource.getTalentNote(this.data.talentId).then(
      (noteJson) => this.note = noteJson.value
    )
  }

  loading = false;
  note: string = "";

  save() {
    this.loading = true;
    this.adminResource.setTalentNote({value: this.note}, this.data.talentId).then(() => {
      this.loading = false;
      this.dialogRef.close(this.note);
    })
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }
}
