<dialog-header dialogHeadline="Talentfilter"></dialog-header>

<loading-spinner [loadingBool]="loading">
    <div class="dialog-content" fxLayout="column">

        <table mat-table [dataSource]="data" class="w-100">
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Anmeldung</th>
                <td mat-cell *matCellDef="let row">{{row.talentCreationDate | date: 'dd.MM.yy'}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Talent</th>
                <td mat-cell *matCellDef="let row">{{row.talentName}}</td>
            </ng-container>

            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef>Studiengang</th>
                <td mat-cell *matCellDef="let row">{{row.mostRecentStudies.subjectName}}</td>
            </ng-container>

            <ng-container matColumnDef="qualityScore">
                <th mat-header-cell *matHeaderCellDef>Qualität</th>
                <td mat-cell *matCellDef="let row">{{row.talentQualityScore | talentQualityScore}}</td>
            </ng-container>

            <ng-container matColumnDef="hasMatch">
                <th mat-header-cell *matHeaderCellDef>Im Feed</th>
                <td mat-cell *matCellDef="let row">{{row.pushedInFeedOn | date: 'dd.MM.yy'}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell class="right" *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <div class="table-button" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Detailiertes Profil anzeigen"
                                (click)="openTalentProfile(row.talentId)">
                            <i class="mdi mdi-account dark"></i>
                        </button>
                        <button mat-button mat-icon-button color="primary"
                                matTooltip="Match anzeigen"
                                [disabled]="!row.matchingReport"
                                (click)="openMatchingReport(row.matchingReport)">
                            <i class="mdi mdi-table-search mdi-24px"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</loading-spinner>

