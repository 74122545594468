<mat-form-field class="w-100" color="primary" [ngClass]="dropdownClass">
  <mat-select [formControl]="control" [placeholder]="placeholder"
              [multiple]="multiple"
              [required]="required"
              [disabled]="disabled"
              [disableOptionCentering]="disableOptionCentering">
    <mat-option>
      <ngx-mat-select-search [formControl]="filterControl"></ngx-mat-select-search>
    </mat-option>
    <div *ngIf="multiple" fxLayout="row" fxLayoutAlign="space-around center">
      <button mat-button class="toggle-all-button" (click)="unselectAll()" color="warn">Alle abwählen</button>
      <button mat-button class="toggle-all-button" (click)="selectAll()" color="primary">Alle Auswählen</button>
    </div>
    <mat-option *ngFor="let state of federalStates" [value]="state.id">
      {{state.name}}
    </mat-option>
  </mat-select>
</mat-form-field>

