<div class="loading-button-wrapper">
  <button class="loading-button" [ngClass]="buttonClass" [color]="color" mat-raised-button (click)="clicked()"
          *ngIf="raised"
          [disabled]="disabledCondition || loadingBool" matTooltip="{{tooltip}}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
  <button class="loading-button" [ngClass]="buttonClass" [color]="color" mat-button (click)="clicked()"
          *ngIf="!raised"
          [disabled]="disabledCondition || loadingBool" matTooltip="{{tooltip}}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
  <div class="loading-line" *ngIf="loadingBool"></div>
</div>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
