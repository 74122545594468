import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../side-sheet/sideSheetRef";

@Component({
  selector: 'app-confirm-side-sheet',
  templateUrl: './confirm-side-sheet.component.html',
  styleUrls: ['./confirm-side-sheet.component.scss']
})
export class ConfirmSideSheetComponent implements OnInit {

  onConfirm: () => Promise<any>;

  title: string
  message: string
  buttonText: string

  confirming: boolean = false

  constructor(
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
  }

  confirm() {
    this.confirming = true;
    this.onConfirm().then(() => {
      this.confirming = false;
      this.sideSheetRef.sheetClosed.next(true);
    })
  }

}
