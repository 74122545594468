import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicationHistory'
})
export class ApplicationHistoryPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value){
      case "ApplicationOutcomeReportedByTalentLogEntry": return "Talent hat Bewerbungsergebnis gemeldet"
      case "ApplicationInterestingLogEntry": return "Vom Unternehmen als Interessant markiert"
      case "ApplicationSeenLogEntry": return "Vom Unternehmen gesehen"
      case "ApplicationProcessEnterLogEntry": return "Vom Unternehmen als in Bewerbungsprozess verschoben"
      case "ApplicationContractSentLogEntry": return "Unternehmen hat Arbeitsvertrag zugeschickt"
      case "ApplicationGrantedLogEntry": return "Bewerbung vom Admin freigegeben"
      case "ApplicationEmailSentLogEntry": return "Bewerbungsmail Versand"
      case "ApplicationSentLogEntry": return "Bewerbung erstellt"
      case "ApplicationDraftCreatedLogEntry": return "Bewerbung als Entwurf (Draft) erstellt"
      case "ApplicationHiredLogEntry": return "Talent wurde eingestellt"
      case "ApplicationFailedLogEntry": return "Bewerbung vom Unternehmen abgelehnt"
      case "ApplicationBlockedByAdmin": return "Bewerbung wurde vom Admin nicht freigegeben"
      case "ApplicationWithdrawnLogEntry": return "Bewerbung zurückgezogen"
      default: return value
    }
  }

}
