import {Component, OnInit} from '@angular/core';
import {AdminControllingResource} from "../../generated/resources";
import {TalentCreationStatisticsData} from "../../generated/data";

@Component({
  selector: 'talent-creation-stats',
  templateUrl: './talent-creation-stats.component.html',
  styleUrls: ['./talent-creation-stats.component.scss']
})
export class TalentCreationStatsComponent implements OnInit {


  constructor(private adminControllingResource: AdminControllingResource) {
  }

  ngOnInit() {

  }


}
