import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  constructor(private pipe: DatePipe) {
  }

  transform(value: any): any {

    if (new Date(value).getFullYear() == new Date().getFullYear()) {
      return this.pipe.transform(value, 'dd.MM.')
    } else {
      return this.pipe.transform(value, 'dd.MM.yyyy')
    }
  }
}
