import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TalentFilterResultListSheetComponent} from "../talent-filter-group/talent-filter-result-list-sheet/talent-filter-result-list-sheet.component";
import {AdminEmploymentPositionResource} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {AdminEmploymentPositionSpecialTargetingData} from "../../../generated/data";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'special-targeting-filters',
  templateUrl: './special-targeting-filters.component.html',
  styleUrls: ['./special-targeting-filters.component.scss']
})
export class SpecialTargetingFiltersComponent implements OnInit {

  @Input() positionId: number;

  targetingData: AdminEmploymentPositionSpecialTargetingData

  savingSpecialTargeting: Boolean = false;
  filtersLoaded: Boolean = false;

  constructor(
      private adminEmploymentPositionResource: AdminEmploymentPositionResource,
      private sideSheetService: SideSheetService,
      private sideSheetRef: SideSheetRef,
      private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadTalentFilters();
  }

  loadTalentFilters() {
    this.adminEmploymentPositionResource.getSpecialTargetingFiltersOfPosition(this.positionId).then(targetingData => {
      this.targetingData = targetingData
      this.filtersLoaded = true
    })
  }

  saveSpecialTargeting() {
    this.savingSpecialTargeting = true
    this.adminEmploymentPositionResource.updateSpecialTargetingFiltersForPosition(this.targetingData, this.positionId).then(() => {
      this.loadTalentFilters();
      this.savingSpecialTargeting = false;
    })
  }

  openTalentFilterSideSheet(): void {
    let sref = this.sideSheetService.openOverlay(TalentFilterResultListSheetComponent, 'Large')
    sref.instance.positionId = this.positionId
    sref.instance.filters = this.targetingData.specialTargetingFilters
  }

}
