import {CompanyData} from "./company-data"
import {TalentPositionRelationData} from "./talent-position-relation-data"
import {TopJobSlotData} from "./top-job-slot-data"

export class TalentPositionPreviewData {
    company = new CompanyData();
    companyLogoUrl: string | null = null;
    id: number = null;
    locationDescription: string = null;
    locationShortDescription: string = null;
    relationData: TalentPositionRelationData | null = null;
    title: string = null;
    topjobSlotData: TopJobSlotData | null = null;
}