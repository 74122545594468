<div class="component-wrapper mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div *ngIf="waitingTalents" class="button-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div><b>Wartende Talente:</b></div>
                <div>{{waitingTalents.numTalentsWaitingForFirstCoachAssignment}} (Erstzuweisung)</div>
                <div>{{waitingTalents.numTalentsWaitingForNewCoachAssignment}} (Erneutzuweisung)</div>
            </div>

            <table-download-button color="primary" [buttonClass]="'main-action-icon'" excelName="CareerCoaches.xlsx"
                                   [matTable]="coachesTable"></table-download-button>
        </div>
        <div style="padding: 0 20px">
            <mat-form-field style="width: 400px">
                <input matInput [formControl]="searchControl" placeholder="Schnellsuche">
            </mat-form-field>
        </div>
        <loading-spinner [loadingBool]="loading">
            <table mat-table [dataSource]="consultantData" #coachesTable class="w-100">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name</th>
                    <td mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}}</td>
                </ng-container>

                <ng-container matColumnDef="emailAddress">
                    <th mat-header-cell *matHeaderCellDef> Emailadresse</th>
                    <td mat-cell *matCellDef="let element">{{element.emailAddress}}</td>
                </ng-container>

                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef> Telefonnummer</th>
                    <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
                </ng-container>

                <ng-container matColumnDef="talentCount">
                    <th mat-header-cell *matHeaderCellDef> Talente</th>
                    <td mat-cell *matCellDef="let element" matTooltip="zugewiesen (davon deaktiviert)">
                        <span>{{element.talentCount}} ({{element.matchingDeniedCount}})</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="countHiring">
                    <th mat-header-cell *matHeaderCellDef>Anzahl Vermittlungen</th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                            <span>{{element.countHiring}}</span>
                            <button (click)="openConsultantHiringsInfoDialog(element.hirings)" *ngIf="element.countHiring > 0" mat-icon-button>
                                <i style="font-size: 20px; color: #808080" class="mdi mdi-information"></i>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="deleted">
                    <th mat-header-cell *matHeaderCellDef>Gelöscht</th>
                    <td mat-cell *matCellDef="let element">{{element.active? "Ja" : "Nein"}}</td>
                </ng-container>

                <ng-container matColumnDef="creation">
                    <th mat-header-cell *matHeaderCellDef>Coach seit</th>
                    <td mat-cell *matCellDef="let element">{{element.creation | date: "dd.MM.yyyy"}}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef style="text-align: end">Aktionen</th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button mat-button mat-icon-button
                                    matTooltip="Als Berater einloggen"
                                    (click)="redirectToMlprontendWithTokenForUser(element.emailAddress)">
                                <i class="mdi mdi-account-switch dark-important"></i>
                            </button>
                            <button mat-icon-button matTooltip="Berater bearbeiten" (click)="editConsultant(element.id)">
                                <i class="mdi mdi-pencil"></i>
                            </button>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           [showFirstLastButtons]="true"
                           [length]="totalSize"
                           [hidePageSize]="false"
                           [pageIndex]="pageIndex"
                           (page)="handlePage($event)">
            </mat-paginator>
        </loading-spinner>
    </div>
</div>
