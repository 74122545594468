<loading-spinner style="width: 100%" [loadingBool]="loading">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <info-box *ngIf="position.komboJobId" [message]="'Bewerbungen werden an das ATS des Unternehmens weitergeleitet'"></info-box>
    <div fxLayout="row" fxLayoutAlign="end center">
      <table-download-button color="primary" [buttonClass]="'main-action-icon'" excelName="Bewerbungen.xlsx"
                             [matTable]="table"></table-download-button>
    </div>
  </div>

  <table #table class="w-100" mat-table [dataSource]="dataSource" matSort matSortActive="cvDate"
         matSortDisableClear
         matSortDirection="asc">

    <ng-container matColumnDef="talentName">
      <th mat-header-cell *matHeaderCellDef>Talent</th>
      <td mat-cell *matCellDef="let row">{{row.talentName}}</td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef>beworben am</th>
      <td mat-cell *matCellDef="let row">{{row.creationDate | date: 'dd.MM.yy, h:mm a'}}</td>
    </ng-container>

    <ng-container matColumnDef="failedReasonTalent">
      <th mat-header-cell *matHeaderCellDef>Fehlgeschlagen weil (Talent)</th>
      <td style="max-width: 125px;" mat-cell *matCellDef="let row">{{row.failedReasonTalent? row.failedReasonTalent : ' - '}}</td>
    </ng-container>

    <ng-container matColumnDef="failedReasonUniwunder">
      <th mat-header-cell *matHeaderCellDef>Fehlgeschlagen weil (Uniwunder)</th>
      <td style="max-width: 125px;" mat-cell *matCellDef="let row">{{row.failedReasonUniwunder? row.failedReasonUniwunder : ' - '}}</td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row" class="request-state">
        <application-state [state]="row.state" [markedHired]="row.applicationMarkedAsHired" ></application-state>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastStateUpdate">
      <th mat-header-cell *matHeaderCellDef>letzte Statusänderung</th>
      <td mat-cell *matCellDef="let row">{{row.lastStateUpdate | date: 'dd.MM.yy, h:mm a'}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="right">Aktionen</th>
      <td mat-cell *matCellDef="let row">

        <div class="table-button" fxLayout="row" fxLayoutAlign="end center">
          <button mat-icon-button color="primary" (click)="openApplicationDetails(row)"
                  matTooltip="Bewerbungsdetails öffnen">
            <i class="mdi mdi-file dark"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</loading-spinner>
