import { Component, OnInit } from '@angular/core';
import {
  LabelData,
  PositionProfessionFieldRelationData,
} from "../../../generated/data";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl} from "@angular/forms";
import {AdminProfessionResource} from "../../../generated/resources";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-add-activity-field-sheet',
  templateUrl: './add-activity-field-sheet.component.html',
  styleUrls: ['./add-activity-field-sheet.component.scss']
})
export class AddActivityFieldSheetComponent implements OnInit {


  searchControl = new FormControl('')
  fields: LabelData[] =[]

  constructor(private adminProfessionResource: AdminProfessionResource, private sideSheetRef:SideSheetRef ) { }

  ngOnInit(): void {

    this.searchControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {

          if(newValue.length > 0) {
            this.load(newValue)
          }else{
            this.fields = []
          }
        })
  }

  load(searchQuery: string){
    this.adminProfessionResource.getProfessionFields({searchTerm: searchQuery}).then(res => {
      this.fields = res;
    })
  }

  selectField(field: LabelData) {
    this.sideSheetRef.sheetClosed.next(field)
  }

}