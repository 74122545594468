<dialog-header [dialogHeadline]="'Berufsauswahl-Assistent'"></dialog-header>

<div class="dialog-main-content layout-padding-8" >

    <div fxLayout="row" fxLayoutAlign="space-between start">
    <div fxLayout="column" fxFlex="50">
        <span>Stelle: <b>{{position?.name}}</b></span>
        <span>Aktuell eingestellt: <b>{{config?.profession?.name || 'Noch nichts'}}</b></span>
    </div>
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="start end">
            <span *ngIf="config.profession"><b>Neue Auswahl:</b> {{config?.profession?.name}}</span>
            <button mat-flat-button    color="primary" (click)="selectAndClose()" [disabled]="!config.profession">Auswahl übernehmen</button>
        </div>
    </div>
<hr>
    <h3>Vorschläge</h3>
    <mat-progress-spinner mode="indeterminate" diameter="30" *ngIf="suggestionsLoading"></mat-progress-spinner>
    <div *ngIf="!suggestionsLoading && suggestions.length == 0" style="font-style: italic">
        Keine Vorschläge gefunden
    </div>
    <mat-chip-list *ngIf="!suggestionsLoading" fxLayout="row wrap"
         style="overflow-wrap: break-word">
        <mat-chip *ngFor="let profession of suggestions"
             class="profession-suggestion"
             (click)="selectProfession(profession)"
             matTooltip="Auswählen"
        [selected]="config.profession?.id == profession.id"
        >
            <i class="mdi mdi-plus"></i>
            {{ profession.title}}
        </mat-chip>
    </mat-chip-list>
<hr>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field>
            <input placeholder="Stichwortsuche" matInput [formControl]="filterControl"/>
        </mat-form-field>
        <button mat-flat-button color="secondary" (click)="openExplorer()">Berufe-Explorer öffnen</button>
    </div>

    <table mat-table [dataSource]="professions" class="max-table" #table>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selectProfession(element.profession) :null"
                              [checked]="config?.profession?.id == element.profession.id">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="professionTitle">
            <th mat-header-cell *matHeaderCellDef>Beruf</th>
            <td mat-cell *matCellDef="let element" (click)="selectProfession(element.profession)">
                <span class="clickable"> {{element?.profession?.title}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="groupTitle">
            <th mat-header-cell *matHeaderCellDef>Berufsgruppe</th>
            <td mat-cell *matCellDef="let element">
                {{element.groupTitle}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openProfessionDetails(element)">
                    <i class="mdi mdi-open-in-new"></i>
                </button>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="['select','professionTitle','groupTitle','actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['select','professionTitle','groupTitle','actions'];"></tr>
    </table>
    <mat-paginator [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   [length]="totalElements"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>



