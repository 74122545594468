<dialog-header [dialogHeadline]="'Tätigkeitsfelder-Auswahl'"></dialog-header>

<div  class="dialog-main-content layout-padding-8">
    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button    color="primary" (click)="applyAndClose()" [disabled]="!config.profession">Auswahl übernehmen</button>
    </div>
    <h4>Derzeit eingestellt</h4>


    <table mat-table [dataSource]="dataSource" style="width: 100%" *ngIf="config && config?.positionProfessionFieldRelations?.length > 0">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Tätigkeitsfeld</th>
        <td mat-cell *matCellDef="let element">{{element.field.name}}</td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Bewertung</th>
        <td mat-cell *matCellDef="let element">
            <div fxLayout="row" style="padding: 4px;" fxLayoutAlign="space-between center">
                <mat-button-toggle-group [ngModel]="element.qualification" (ngModelChange)="updateQualification(element, $event)">
                    <mat-button-toggle value="Normal" [ngClass]="{'blue-bg':element.qualification === 'Normal'}">Passt
                    </mat-button-toggle>
                    <mat-button-toggle value="Fitting" [ngClass]="{'green-bg':element.qualification === 'Fitting'}">Sehr
                        passend
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <button mat-icon-button (click)="removeField(element.field.id)" matTooltip="Feld entfernen"><i class="mdi mdi-close mdi-18px red"></i></button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['name','state']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['name','state'];"></tr>
</table>

<div *ngIf="config?.positionProfessionFieldRelations?.length == 0" class="controlling-board-info">
   Momentan nichts zugeordnet
</div>

<h4>Tätigkeitsfelder hinzufügen</h4>


        <mat-accordion *ngIf="recommendedFields.length  > 0">
            <mat-expansion-panel hideToggle [expanded]="true">
                <mat-expansion-panel-header >
                    <mat-panel-title>Vorschläge</mat-panel-title>
                    <mat-panel-description>Tätigkeitsfelder, die zum Beruf {{config.profession.name}} passen
                    </mat-panel-description>
                </mat-expansion-panel-header>

        <table mat-table [dataSource]="recommendedFields" style="width: 100%">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Tätigkeitsfeld</th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button color="accent" (click)="addField(element)"><i class="mdi mdi-plus"></i>Hinzufügen</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['name','state']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name','state'];"></tr>
        </table>
            </mat-expansion-panel>
        </mat-accordion>

<mat-form-field style="margin-top:30px">
    <input matInput [formControl]="searchControl" placeholder="Tätigkeitsfeld suchen">
</mat-form-field>

    <div class="controlling-board-info" *ngIf="searchControl.value.length == 0">
        Gib einen Suchbegriff ein
    </div>

<table mat-table [dataSource]="fields" style="width: 100%" *ngIf="searchControl.value?.length > 0">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Tätigkeitsfeld</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-button color="accent" (click)="addField(element)"><i class="mdi mdi-plus"></i>Hinzufügen</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['name','state']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['name','state'];"></tr>
</table>
</div>


