import {Component, Input, OnInit} from '@angular/core';
import {AdminTalentFeedPositionRelationData, TalentMatchingReportData} from "../../../generated/data";
import {AdminEmploymentPositionResource} from "../../../generated/resources";
import {PageEvent} from "@angular/material/paginator";
import {MatchingAnalyzeSheetComponentComponent} from "../../../matching/matching-optimization/matching-analyze-sheet-component/matching-analyze-sheet-component.component";
import {TalentProfileDialogComponent} from "../../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {CreatePositionRecommendationsDialogComponent} from "../../../talents/create-position-recommendations-dialog/create-position-recommendations-dialog.component";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'position-recommendations',
  templateUrl: './position-recommendations.component.html',
  styleUrls: ['./position-recommendations.component.scss']
})
export class PositionRecommendationsComponent implements OnInit {

  @Input() positionId: number;
  @Input() positionName: string;

  public data: AdminTalentFeedPositionRelationData[] = []
  public loading: boolean = true
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public pageSizeOptions: number[] = [15, 30, 60, 100];
  public totalSize: number = 0;
  public displayedColumns = ['recommended', 'isPrimeMatch', 'name','subject', 'bookmarked', 'state', 'actions']

  public selection = new SelectionModel<AdminTalentFeedPositionRelationData>(true, []);

  constructor(
      private adminPositionResource: AdminEmploymentPositionResource,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.loadTalents();
  }

  loadTalents() {
    this.loading = true;
    this.adminPositionResource.getAllRecommendationsForPosition(this.positionId, { page: this.pageIndex, pageSize: this.pageSize}).then(talents => {
      this.data = talents.content
      this.totalSize = talents.totalElements
      this.loading = false;
    })
  }

  openMatchingReport(report: TalentMatchingReportData) {
    let cref = this.dialogService.openOverlay(MatchingAnalyzeSheetComponentComponent)
    cref.instance.report = report;
  }

  openTalentProfile(talentId: number) {
    let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = talentId
  }

  openCreatePositionRecommendationsDialog() {
    const comp = this.dialogService.openOverlay(CreatePositionRecommendationsDialogComponent, 'Small')
    comp.instance.mode = 'SinglePositionMode'
    comp.instance.positionId = this.positionId
    comp.instance.talents = this.selection?.selected?.filter(entry => !entry.primeRecommendation)

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      result.forEach((e) => {
        this.data.find(d => d.talentId == e.talentId).primeRecommendation = true
      })

      subscription.unsubscribe();
    });
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadTalents();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }
}
