import {ApplicationCommentData} from "./application-comment-data"
import {MinimalEmploymentPositionData} from "./minimal-employment-position-data"
import {CompanyPositionQuestionAnswersData} from "./company-position-question-answers-data"
import {HiringSalaryInformation} from "./hiring-salary-information"
import {ApplicationState} from "./application-state"
import {CompanyTalentData} from "./company-talent-data"

export class ApplicationData {
    adminNote: string = null;
    comments: ApplicationCommentData[] = null;
    companyName: string = null;
    creationDate: (Date | null) = null;
    cvAvailable: boolean = null;
    id: number = null;
    position = new MinimalEmploymentPositionData();
    positionQuestionAnswerData: CompanyPositionQuestionAnswersData[] = null;
    recommendedByConsultant: string | null = null;
    salaryAmount: number | null = null;
    salaryState: HiringSalaryInformation | null = null;
    seenByRecruiter: boolean = null;
    signingDate: (Date | null) = null;
    startingDate: (Date | null) = null;
    state: ApplicationState = null;
    talent: CompanyTalentData | null = null;
    talentMessage: string | null = null;
}