import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../utils/utils.service";
import {EmailAddressAvailabilityValidatorService} from "../../services/email-address-availability-validator.service";
import {convertFormGroupToObject} from "../../utils/inno-utils/form-utils.service";
import {AdminAdminResource} from "../../generated/resources";
import {AdminDetailedData} from "../../generated/data";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-account-create-dialog',
  templateUrl: './account-create-dialog.component.html',
  styleUrls: ['./account-create-dialog.component.scss']
})
export class AccountCreateDialogComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private emailAddressAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
    private adminAdminResource: AdminAdminResource,
    private dialogRef: MatDialogRef<AccountCreateDialogComponent>
  ) {

    this.form = fb.group({
      firstName: [null, Validators.required],
      lastName: [null],
      emailAddress: [null, [Validators.required, Validators.email], [this.emailAddressAvailabilityValidatorService.createValidator()]],
      phoneNumber: [null, [Validators.required, this.utilsService.validatePhoneNumber()]]
    })
  }

  ngOnInit() {
  }

  saveAdmin() {
    let data = convertFormGroupToObject(<AdminDetailedData>{}, this.form);

    this.loading = true;
    this.adminAdminResource.createAdmin(data).then(
      () => {
        this.loading = false;
        this.dialogRef.close(true);
      }
    )
  }
}
