import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {

  constructor() {
  }
}

//Usage: Pass new Dataclass()
//Will populate all keys of a data object with values of formcontrol of the formgroup that has the same name
export function convertFormGroupToObject(object: any, formGroup: FormGroup): any {

  for (let control in formGroup.controls) {
    object[control] = formGroup.controls[control].value;
  }

  return object
}


//Will set all values of the formgroups controls to the value of key with the same name in your data object
export function populateForm(formGroupToPopulate: FormGroup, dataObject: any) {
  for (let key of Object.keys(dataObject)) {
    try {
      formGroupToPopulate.controls[key].setValue(dataObject[key])
    } catch (e) {
      console.warn("Could not populate. Possible cause: there is an object key " + key + " in your Data object that has no control with the same name in your FormGroup")
    }
  }
}
