import {Pipe, PipeTransform} from '@angular/core';
import {AdminTalentData} from "../../generated/data";

@Pipe({
    name: 'onboardingComplete'
})
export class OnboardingCompletePipe implements PipeTransform {

    transform(value: AdminTalentData): String {
        if (value.onboardingComplete) return 'Abgeschlossen';
        else return 'Abgebrochen';
    }

}
