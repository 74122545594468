<dialog-header [dialogHeadline]="'Tätigkeitsfelder-Auswahl'"></dialog-header>

<div  class="dialog-main-content layout-padding-8">

    <mat-form-field style="width: 100%">
        <input matInput [formControl]="searchControl" placeholder="Tätigkeitsfeld suchen">
    </mat-form-field>

    <div class="controlling-board-info" *ngIf="searchControl.value.length == 0">
        Gib einen Suchbegriff ein
    </div>

    <table mat-table [dataSource]="fields" style="width: 100%" *ngIf="searchControl.value?.length > 0">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Tätigkeitsfeld</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-button color="accent" (click)="selectField(element)"><i class="mdi mdi-plus"></i>Auswählen</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name','state']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name','state'];"></tr>
    </table>
</div>


