<dialog-header dialogHeadline="Bewerbungsübersicht"></dialog-header>

<div class="dialog-main-content" style="height: calc(100% - 50px)">
    <div style="padding: 10px 32px 0 32px; height: 225px" fxLayout="column">
        <!--        <span style="line-height: 36px">-->
        <!--            <b>Unternehmen: </b> {{application.position.hiringCompanyName}}-->
        <!--        </span>-->
        <span>
            <b>Bewerber: </b>
            <button matTooltip="Profil öffnen"
                    mat-button
                    (click)="openTalentProfile(application.talentId)">{{application.talentName}}
            </button>
        </span>
        <span *ngIf="application.position"><b>Stelle: </b>
            <button mat-button matTooltip="Stelle öffnen" (click)="openPositionDetailsDialog(application.position.id)">
                {{application.position.internTitle}} ({{application.position.hiringCompanyName}})
            </button>
        </span>
        <div style="background-color: #efefef; padding: 8px; max-height: 100px; overflow-y: auto">
            <div style="margin-bottom: 10px; font-size: 15px;color: gray;">Anforderungen an die Stelle
            </div>
            <div *ngIf="content?.positionRequirements?.length > 0">{{content?.positionRequirements}}</div>
            <div *ngIf="content?.positionRequirements?.length == 0"><span style="font-style: italic">keine Angabe</span>
            </div>
        </div>
    </div>


    <mat-tab-group>
        <mat-tab label="Lebenslauf">
            <p *ngIf="!cvFile ||cvFile.size == 0">Kein Lebenslauf vorhanden</p>

            <ngx-extended-pdf-viewer
                    *ngIf="cvFile && cvFile.size != 0"
                    [src]="cvFile"
                    [height]="'100%'"
                    [useBrowserLocale]="true">
            </ngx-extended-pdf-viewer>

        </mat-tab>
        <!--            <mat-tab label="Anschreiben">-->
        <!--                <p style="padding: 20px">{{application.applicationMessage}}</p>-->
        <!--            </mat-tab>-->

        <mat-tab label="Studium" style="min-height: 500px">
            <div fxLayout="column" class="layout-padding" style="width: 100%;">
                <table mat-table [dataSource]="careerEvents" class="max-table" style="width: 100%;">

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>Typ</th>
                        <td class="tabel-jobtitle-cell" mat-cell
                            *matCellDef="let element"> {{element.type | careerEvent}}</td>
                    </ng-container>

                    <ng-container matColumnDef="university">
                        <th mat-header-cell *matHeaderCellDef>Universität</th>
                        <td class="tabel-jobtitle-cell" mat-cell
                            *matCellDef="let element"> {{element.universityName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="subject">
                        <th mat-header-cell *matHeaderCellDef>Studiengang</th>
                        <td class="tabel-jobtitle-cell" mat-cell
                            *matCellDef="let element"> {{element.subjectName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th style="text-align: center" mat-header-cell *matHeaderCellDef> Aktionen</th>
                        <td mat-cell *matCellDef="let element">
                            <div fxLayout="row" fxLayoutAlign="center start">
                                <i class="mdi mdi-delete dark" matTooltip="Löschen"
                                   (click)="deleteCareerEvent(element)"></i>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedCareerEventColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedCareerEventColumns;"></tr>

                </table>

            </div>
        </mat-tab>

    </mat-tab-group>

    <div class="action-bar" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button color="warn" class="red-text" (click)="cancel()">Abbrechen</button>

        <div *ngIf="application.state == 'Sent'" class="application-actions" fxLayout="row"
             fxLayoutAlign="end center">
            <loading-button [raised]="true" (clickEvent)="denyApplication()"
                            [loadingBool]="loadingDenyApplication"
                            class="reject">
                <i class="mdi mdi-close-circle-outline cancel-icon"></i> Ablehnen
            </loading-button>
            <loading-button [raised]="true" (click)="acceptApplication()"
                            [loadingBool]="loadingAcceptApplication"
                            class="accept">
                <i class="mdi mdi-checkbox-marked-circle-outline accept-icon"></i> Freigeben
            </loading-button>
        </div>
    </div>
</div>

