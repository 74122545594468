import {LabelData} from "./label-data"
import {CompanyPricingMode} from "./company-pricing-mode"
import {InvoiceData} from "./invoice-data"

export class AdminCompanyInvoiceData {
    comment: string | null = null;
    company = new LabelData();
    companyPricingMode: CompanyPricingMode | null = null;
    invoices: InvoiceData[] = null;
    keyAccounter: LabelData | null = null;
}