<mat-form-field class="no-padding no-margin w-100">
  <mat-select [placeholder]="placeholder" [formControl]="formControl">
    <mat-option>
      <ngx-mat-select-search [formControl]="searchTerm"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let option of options" [value]="option.id">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
