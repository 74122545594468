import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {ApiResource} from '../../../api-resource';
import {MatSelect} from '@angular/material/select';
import {debounceTime} from 'rxjs/internal/operators';
import {Subscription} from "rxjs";

export class LoadingFunctionConfiguration {
  constructor(
    public resource: ApiResource,
    public functionName: string) {
  }
}

//If this does not work, fix it, dont replace it. this pattern works but was hastily implemented
@Component({
  selector: 'app-material-async-dropdown',
  templateUrl: './material-async-dropdown.component.html',
  styleUrls: ['./material-async-dropdown.component.scss']
})
export class MaterialAsyncDropdownComponent implements OnInit, OnDestroy {

//Either of 2 blocks below is required. Model in any case has to be a list of ids
  //in case of modelbinding. Warning: Model binding ist not recommended for this component
  @Input() model: any;
  @Input() required: boolean;

  //in case of reactive form usage
  @Input() control: AbstractControl;

  // Function could be specified in outer component as follows (and passed into this component)
  // loadingFun = (q: string, currentPage: number, pageSize: number, forcedIds: [number]) => {
  //   return this.locationResource.getCities({q: q, federalStateId: 232, page: currentPage, pageSize: pageSize, forcedIds: forcedIds})
  // };
  //
  // Declaration in this component would be:
  // @Input() loadingFunc: ((x:string, y:number, z: number, a: [number]) => Promise<any>);

  //Has to be loadingFunction that supports requestparams q,pageSize, page and forcedIds. It has to return dataclasses with IDs in content field of the response (like standard for PagedData)
  //For usability reasons it is recommended that the forced elements are on top of the list
  @Input() loadingFunctionConfig: LoadingFunctionConfiguration;

  //All below here is optional
  @Input() displayProperty: string; //Null means the model itself will get displayed

  @Input() multiple: boolean = false;

  @Input() placeholder: string;

  @Input() disabled: boolean = false;

  @Input() nullable: boolean = false;
  @Input() nullOptionName: string = 'Keine';

  /*  @Input() searchable = true; Option is not supported yet since there was no use case where you would be async but not allow search*/

  @Input() showFallbackHint: boolean = false;

  @Input() showFullName: boolean = false; //for talent

  @Input() fallbackHintText: string = 'Keine passende Option gefunden?';

  @Output() modelChange: EventEmitter<number> = new EventEmitter();

  options = [];

  loading = false;

  searchTerm = new FormControl('');

  //Holds id of option
  internalControl = new FormControl(null);

  currentPage = 0;

  @ViewChild('select', { static: true }) selectElement: MatSelect;

  totalElements = 0;

  changeInternal = false;

  internalControlSubscription: Subscription;
  controlSubscription: Subscription;
  searchTermSubscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    this.selectElement.openedChange.subscribe(open => {
      if (open) {
        this.registerPanelScrollEvent()
      }
    })

    //populate internalControl
    this.setFromFormControlChange();

    //Need to initialize empty array when nothing was given. You could also throw error when model is undefined or null in that case
    if (this.multiple && !this.internalControl.value) {
      this.internalControl.setValue([], {emitEvent: false});
      //don't emit on initial value set
    }

    this.internalControlSubscription = this.internalControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.changeInternal = true;
        this.setModel();
        this.changeInternal = false;
      });

    //Handling of external changes to provided formcontrol, e.g. through population
    if (this.control) {
      this.controlSubscription = this.control.valueChanges
        .subscribe(newValue => {
          this.setFromFormControlChange();
          //reload here because if formcontrol has externalchange, e.g. on external initial loads, you need to force the selected option. Care to not load if the change is triggered internally
          if (!this.changeInternal) this.load(false);
        });
    }

    this.load(false);

    this.searchTermSubscription = this.searchTerm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.currentPage = 0;
        this.load(false);
      })
  }

  ngOnDestroy() {
    this.internalControlSubscription.unsubscribe();
    this.searchTermSubscription.unsubscribe();

    if (this.control) this.controlSubscription.unsubscribe();
  }

  setFromFormControlChange() {
    if (this.control) {
      this.internalControl.setValue(this.control.value);
    } else {
      this.internalControl.setValue(this.model);
      if (this.required) {
        this.internalControl.setValidators(Validators.required)
      }
    }
  }

  load(setToBottomAfterLoad) {
    this.loading = true;

    let params = {q: this.searchTerm.value, page: this.currentPage, pageSize: 20, forcedIds: null};

    //if loading page one, options get resetted
    if (this.currentPage == 0) {
      this.options = [];
      this.totalElements = 0;
      //Force current selection to be loaded on the first page
      if (!this.multiple && this.internalControl.value != null) {
        params.forcedIds = [this.internalControl.value];
      } else if (this.multiple) {
        params.forcedIds = this.internalControl.value;
      }
    }
    this.loadingFunctionConfig.resource[this.loadingFunctionConfig.functionName](params).then(res => {
      for (let o of res.content) {
        this.options.push(o)
      }
      this.totalElements = res.totalElements;
      this.loading = false;
      if (setToBottomAfterLoad) {

        //TODO doing it like this ofc triggers the scrolltobottomthingy and just reloads again. you would need to scroll to the last element you knew, but i dont have time for this rn
        /* delay(200).then(() => {
           this.selectElement.panel.nativeElement.scrollTop = this.selectElement.panel.nativeElement.scrollHeight;
         })*/
      }
    })
  }

  loadNextPageIfPopulated() {
    // if initial load is not ready, options are empty anyway or a loading process is running, stop triggerin. Or if everythign is loaded already
    if (this.options.length == 0 || this.loading || this.options.length >= this.totalElements) return
    this.currentPage++;
    this.load(true);
  }

  setModel() {
    if (this.control) {
      this.control.setValue(this.internalControl.value);
    }
    this.modelChange.emit(this.internalControl.value);
  }

  getFullOptionForID(id: number) {
    for (let o of this.options) {
      if (o['id'] === id) return o;
    }
  }

  getDisplayProperty(optionId: number) {
    if (!optionId) return '';
    const option = this.getFullOptionForID(optionId);
    if (!option) return '';
    if (this.showFullName) return option['firstName'] + ' ' + option['lastName'];
    if (this.displayProperty) {
      return option[this.displayProperty]
    }
    return option
  }

  private readonly SCROLLBOTTOMOFFSET = 20;

  registerPanelScrollEvent() {
    const panel = this.selectElement.panel.nativeElement;
    if (!this.selectElement.panel.nativeElement) return;
    panel.addEventListener('scroll', event => {
      if (event.target.scrollTop === (event.target.scrollHeight - event.target.offsetHeight)) {
        this.loadNextPageIfPopulated()
      }
    });
  }
}
