import {AdminApplicationHistoryData} from "./admin-application-history-data"
import {AdminEmploymentPositionData} from "./admin-employment-position-data"
import {AdminPositionQuestionAnswersData} from "./admin-position-question-answers-data"
import {ApplicationState} from "./application-state"

export class AdminApplicationDetailsData {
    adminNote: string = null;
    applicationId: number = null;
    company: string = null;
    creationDate: (Date | null) = null;
    cvId: number | null = null;
    failedReasonTalent: string | null = null;
    failedReasonUniwunder: string | null = null;
    historyEntry: AdminApplicationHistoryData[] = null;
    lastStateUpdate: (Date | null) = null;
    position = new AdminEmploymentPositionData();
    positionQuestions: AdminPositionQuestionAnswersData[] | null = null;
    state: ApplicationState = null;
    talentEmailAddress: string = null;
    talentId: number = null;
    talentName: string = null;
}