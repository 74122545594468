import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'educationType'
})
export class EducationTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    var res = '';
    switch (value) {
      case 'highschoolDiploma':
        res = 'Abitur';
        break;
      case 'advancedTechnicalCertificate':
        res = 'Fachhochschulreife';
        break;
      case 'secondarySchoolCertificate':
        res = 'Realschulabschluss';
        break;
      case 'lowerSecondarySchoolCertificate':
        res = 'Hauptschulabschluss';
        break;
    }

    return res;
  }

}
