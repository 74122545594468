<loading-spinner [loadingBool]="loading" class="h-100 w-100">
    <div class="component-wrapper w-100 h-100">
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="filter m-top10" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                <mat-form-field style="width: 400px">
                    <input [formControl]="subjectGroupFilterControl" matInput placeholder="SubjectGroupsuche">
                </mat-form-field>

                <button mat-icon-button color="primary" matTooltip="Reload" (click)="loadSubjectGroups()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <table mat-table [dataSource]="subjectGroups" class="w-100">

            <ng-container matColumnDef="subjectGroupTitle">
                <th mat-header-cell *matHeaderCellDef>SubjectGroup</th>
                <td mat-cell *matCellDef="let subjectGroup">
                    <span class="clickable"
                          matTooltip="Detailansicht öffnen"
                          (click)="openSubjectGroupDetailsSheet(subjectGroup)">
                        {{subjectGroup.title}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="numSubjects">
                <th mat-header-cell *matHeaderCellDef>Subjects</th>
                <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numSubjects}}</td>
            </ng-container>

            <ng-container matColumnDef="numProfessions">
                <th mat-header-cell *matHeaderCellDef>Professions</th>
                <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.numProfessions}}</td>
            </ng-container>

            <ng-container matColumnDef="agencyKey">
                <th mat-header-cell *matHeaderCellDef>DKZ-Kennung</th>
                <td mat-cell *matCellDef="let subjectGroup">{{subjectGroup.agencyKey}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                <td mat-cell *matCellDef="let subject">
                    <div class="table-button" fxLayout="row" fxLayoutAlign="start center"></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [length]="totalSize"
                       [hidePageSize]="false"
                       [pageIndex]="pageIndex"
                       (page)="handlePage($event)">
        </mat-paginator>
    </div>
</loading-spinner>
