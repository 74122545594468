import { Component, OnInit } from '@angular/core';
import {
  ContentSplitControllingData, LabelData
} from "../../../generated/data";
import {AdminControllingResource} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {FormControl} from "@angular/forms";
import {retry} from "rxjs/operators";

@Component({
  selector: 'position-content-controlling',
  templateUrl: './position-content-controlling.component.html',
  styleUrls: ['./position-content-controlling.component.scss']
})
export class PositionContentControllingComponent implements OnInit {

  startDate: Date = new Date();
  endDate: Date = new Date();
  loading = false;
  loadingSplits = false;
  selectedSplitIds: number[] = []

  splits: LabelData[] = []

  data: ContentSplitControllingData[] = []

  searchPositionIdControl: FormControl = new FormControl()

  constructor(
      private controllingResource: AdminControllingResource) {
    this.startDate.setDate(this.startDate.getDate() - 30)
  }


  ngOnInit(): void {
    this.subscribeOnPositionChanges()
  }

  subscribeOnPositionChanges() {
    this.searchPositionIdControl.valueChanges.subscribe(next => {
      this.splits = []
      this.selectedSplitIds = []
      this.loadSplits()
    })
  }

  loadSplits() {
    this.loadingSplits = true
    this.controllingResource.getPositionContentSplitsForPosition({id: this.searchPositionIdControl.value}).then(result => {
      this.splits = result
      this.loadingSplits = false
    })
  }

  getRelValueString(num: number, den: number): string {
    return den == 0? "(0%)" : `(${((num/den) * 100).toFixed(2)}%)`
  }

  load(){
    this.loading = true;
    this.controllingResource.getContentSplitControllingForSplits({startDate: this.startDate, endDate: this.endDate, splitIds: this.selectedSplitIds}).then(res => {
      this.data = res;
      this.loading = false;
    })
  }

}
