<div style="background-color: white; margin: 20px; padding: 10px" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="button-bar" fxLayout="column" fxLayoutAlign="start start">

            <div style="width: 100%" fxLayout="row" fxLayoutAlign="space-between start"
                 [class.filter-border]="filterPanelIsOpen">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <mat-form-field class="name-filter" *ngIf="!filterPanelIsOpen"
                                    [ngClass]="{'openFilterPanel' : !filterPanelIsOpen, 'closeFilterPanel' : filterPanelIsOpen}">
                        <input matInput [formControl]="searchQuery" placeholder="Schnellsuche">
                    </mat-form-field>

                    <mat-slide-toggle [ngModel]="includeAPIPositions"
                                      (ngModelChange)="includeAPIPositions = $event; loadData()">Partnerstellen anzeigen
                    </mat-slide-toggle>

                    <mat-slide-toggle (change)="switchPositionState($event)">Inaktive & Drafts anzeigen
                    </mat-slide-toggle>
                </div>

                <div fxLayout="row">


                    <table-download-button color="primary" [buttonClass]="'main-action-icon'" excelName="Stellen.xlsx"
                                           [matTable]="table"></table-download-button>

                    <loading-button style="margin: 0 10px; overflow: hidden"
                                    matTooltip="Stellen als Excel hochladen"
                                    buttonClass="mat-primary"
                                    (clickEvent)="createFileUploadDialog()">
                        <i class="mdi mdi-upload main-action-icon"></i>
                    </loading-button>

                    <loading-button matTooltip="Neue Stelle anlegen"
                                    buttonClass="mat-primary"
                                    (clickEvent)="createPositionDialog()">
                        <i class="mdi mdi-plus main-action-icon"></i>
                    </loading-button>
                </div>

            </div>

            <loading-spinner [loadingBool]="loading" style="width: 100%">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="creationDate" matSortDisableClear
                       #dataTable
                       matSortDirection="desc">

                    <ng-container matColumnDef="positionId">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let position">{{position.id}}</td>
                    </ng-container>

                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef>Company</th>
                        <td mat-cell *matCellDef="let position" (click)="copyToClipboard(position.customerCompany.name)"
                            class="copy"> {{position.customerCompany.name | sliceStringLength: 30}}</td>
                    </ng-container>

                    <ng-container matColumnDef="positionTitle">
                        <th mat-header-cell *matHeaderCellDef>Titel</th>
                        <td mat-cell *matCellDef="let position" (click)="copyToClipboard(position.title)"
                            class="copy"> {{position.title | sliceStringLength: 100 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="availableSlots">
                        <th mat-header-cell *matHeaderCellDef>Volumen</th>
                        <td mat-cell *matCellDef="let position"
                            class="copy"> {{position.availableSlots}}</td>
                    </ng-container>

                    <ng-container matColumnDef="positionType">
                        <th mat-header-cell *matHeaderCellDef>Typ</th>
                        <td mat-cell *matCellDef="let position" matTooltip="{{position.employmentCategory | workType}}">
                            <i *ngIf="position.employmentCategory == 'FulltimeJob'" class="mdi mdi-briefcase green"></i>
                            <i *ngIf="position.employmentCategory == 'WorkingStudent'"
                               class="mdi mdi-school yellow"></i>
                            <div class="first-letter"
                                 *ngIf="position.employmentCategory != 'FulltimeJob' && position.employmentCategory != 'WorkingStudent'"> {{(position.employmentCategory | workType) | slice:0:1}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="creationDate">
                        <th mat-header-cell *matHeaderCellDef>Anlagedatum</th>
                        <td mat-cell *matCellDef="let position"
                            matTooltip="um {{position.creationDate | date: 'HH:mm'}} Uhr">{{position.creationDate | date: 'dd.MM.yy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let position"
                            [ngStyle]="{'color': position.active ? 'green' : 'red'}">
                            {{position.positionState}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell class="right" *matHeaderCellDef>Aktionen</th>
                        <td mat-cell *matCellDef="let position">
                            <div class="table-button" fxLayout="row" fxLayoutAlign="end center">
                                <button mat-button mat-icon-button color="primary"
                                        matTooltip="Stellendetails"
                                        (click)="openPositionDetailsDialog(position.id)">
                                    <i class="mdi mdi-information dark"></i>
                                </button>
                                <button mat-icon-button color="primary" matTooltip="Talent Targetierung"
                                        (click)="navigateToTalentTargeting(position.id)">
                                    <i class="mdi mdi-target main-action-icon"></i>
                                </button>
                                <button mat-icon-button color="primary" matTooltip="Datenschutzerklärung"
                                        (click)="openPrivacyDisclaimerDialog(position)">
                                    <i class="mdi mdi-format-section dark"></i>
                                </button>

                                <button mat-icon-button color="primary" matTooltip="Stelle Kopieren"
                                        (click)="duplicateEmploymentPosition(position.id, position.title)">
                                    <i class="mdi mdi-content-copy dark"></i>
                                </button>


                            </div>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let position; columns: displayedColumns;"
                        [class.deactivated]="position.state == 'Inactive'"
                    ></tr>
                </table>


                <mat-paginator [pageSize]="pageSize"
                               [pageSizeOptions]="pageSizeOptions"
                               [showFirstLastButtons]="true"
                               [length]="totalSize"
                               [hidePageSize]="false"
                               [pageIndex]="pageIndex"
                               (page)="handlePage($event)">
                </mat-paginator>
            </loading-spinner>
        </div>
    </div>
</div>
