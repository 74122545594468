export class ProfessionTreeData {
    id: number = null;
    professionAreaTitle: string = null;
    professionBlacklisted: boolean = null;
    professionGroupBlacklisted: boolean = null;
    professionGroupTitle: string = null;
    professionMainGroupTitle: string = null;
    professionSubGroupTitle: string = null;
    professionTypeTitle: string = null;
    title: string = null;
}