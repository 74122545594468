import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'professionGroupPreference'
})
export class ProfessionGroupPreferencePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'NotInteresting': return 'Uninteressant';
      case 'NotFittingButInteresting': return 'Interessant ';
      case 'Interesting': return 'Interessant';
    }
  }

}
