import {LabelData} from "./label-data"
import {SlotContentData} from "./slot-content-data"
import {SlotSettingsData} from "./slot-settings-data"

export class TopJobSlotData {
    company = new LabelData();
    content: SlotContentData | null = null;
    id: number = null;
    position: LabelData | null = null;
    setting = new SlotSettingsData();
}