<div class="loading-spinner-content-wrapper">
  <div class="loading-spinner-overlay" *ngIf="loadingBool" fxLayoutAlign="center center">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
