import {ApplicationMode} from "./application-mode"
import {CompanyData} from "./company-data"
import {PositionContentSplitData} from "./position-content-split-data"
import {EmploymentCategory} from "./employment-category"
import {EmploymentPositionOrigin} from "./employment-position-origin"
import {HomeOffice} from "./home-office"
import {CityData} from "./city-data"
import {TopJobSlotData} from "./top-job-slot-data"

export class PublicEmploymentPositionDetailsData {
    applicationMode: ApplicationMode = null;
    applicationRequirementsInfo: string | null = null;
    bmsReference: string | null = null;
    company = new CompanyData();
    companyLogoUrl: string | null = null;
    content: PositionContentSplitData | null = null;
    creationDate: (Date | null) = null;
    employmentCategory: EmploymentCategory = null;
    employmentPositionOrigin: EmploymentPositionOrigin | null = null;
    googleJobPosting: boolean = null;
    hasPrivacyDisclaimer: boolean = null;
    hiringCompanyName: string = null;
    hiringCompanyWebsite: string | null = null;
    homeOffice: HomeOffice | null = null;
    id: number | null = null;
    isPrimePosition: boolean = null;
    isThirdPartyPosition: boolean = null;
    locationDescription: string = null;
    locationShortDescription: string = null;
    locations: CityData[] = null;
    positionShortDescription: string | null = null;
    preferences: string[] | null = null;
    primaryColor: string | null = null;
    professionId: number | null = null;
    salaryFrom: number | null = null;
    salaryText: string | null = null;
    salaryTo: number | null = null;
    secondaryColor: string | null = null;
    showCompany: boolean = null;
    specialTargetingDescription: string | null = null;
    startingDate: (Date | null) = null;
    title: string = null;
    topJobSlot: TopJobSlotData | null = null;
    webLink: string | null = null;
    weeklyHoursFrom: number | null = null;
    weeklyHoursTo: number | null = null;
}