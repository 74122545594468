import { Component, OnInit } from '@angular/core';
import {
  AdminTalentPositionRelationData, JsonWrappedValue,
  LabelData,
  ProfessionData,
  SubjectData,
  SubjectGroupData
} from "../../../generated/data";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {MapSubjectToSubjectGroupSheetComponent} from "../map-subject-to-subject-group-sheet/map-subject-to-subject-group-sheet.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {UtilityService} from "../../../utils/utility.service";
import {SubjectDetailsSheetComponent} from "../subject-details-sheet/subject-details-sheet.component";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";
import {MatTableDataSource} from "@angular/material/table";
import {RemoveSubjectToGroupMappingPreviewSheetComponent} from "../remove-subject-to-group-mapping-preview-sheet/remove-subject-to-group-mapping-preview-sheet.component";
import {RemoveSubjectGroupToProfessionPreviewSheetComponent} from "../remove-subject-group-to-profession-preview-sheet/remove-subject-group-to-profession-preview-sheet.component";
import {ConfirmSideSheetComponent} from "../../../utils/confirm-side-sheet/confirm-side-sheet.component";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-subject-group-details-sheet',
  templateUrl: './subject-group-details-sheet.component.html',
  styleUrls: ['./subject-group-details-sheet.component.scss']
})
export class SubjectGroupDetailsSheetComponent implements OnInit {

  public subjectGroupId: number
  public subjectGroupTitle: string
  public subjectGroupData: SubjectGroupData
  public subjectData: SubjectData[] = []
  public subjectDataSource = new MatTableDataSource()
  public professionData: ProfessionData[] = []
  public professionDataSource = new MatTableDataSource()
  public loading: boolean = true

  public displayedSubjectColumns = ['select','subjectTitle', 'numSubjectGroups', 'numProfessions', 'actions']
  public displayedProfessionColumns = ['professionTitle', 'numSubjectGroups', 'numSubjects', 'actions']


  constructor(
      public utilityService: UtilityService,
      private adminSubjectResource: AdminSubjectResource,
      private adminProfessionResource: AdminProfessionResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) { }


  selection = new SelectionModel<SubjectData>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.subjectData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.subjectData.forEach(row => this.selection.select(row));
  }


  ngOnInit(): void {
    this.subjectDataSource.filterPredicate = (s: SubjectData, filter: string) => s.title.toLocaleLowerCase().includes(filter) == true;
    this.professionDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadSubjectGroup()
  }

  loadSubjectGroup(): void {
    this.loading = true
    this.adminSubjectResource.getSubjectGroup(this.subjectGroupId).then(sgroup => {
      this.subjectGroupData = sgroup

      Promise.all([
        this.adminSubjectResource.getSubjects(this.subjectGroupData.subjects.map(s => s.id)).then(subjects => {
          this.subjectData = subjects
          this.subjectDataSource.data = this.subjectData
        }),
        this.adminProfessionResource.getProfessionsByIds(this.subjectGroupData.professions.map(p => p.id)).then(professions => {
          this.professionData = professions
          this.professionDataSource.data = this.professionData
        })
      ]).then(() => {
        this.loading = false
      })
    })
  }


  deleteSelectedSubjectMappings(){
    let cref = this.dialogService.openOverlay(ConfirmSideSheetComponent,'Small')

    cref.instance.title = "Alle Subjectmappings clearen"
    cref.instance.message = "Diese Funktion löscht alle Subject-Mappings für diese Studiengruppe. Dieser Vorgang ist nicht umkehrbar"
    cref.instance.buttonText = "Mappings löschen"

    cref.instance.onConfirm = () => this.doDeleteSelectedSubjectMappings()
  }

  loadClearSubjectMappings = false;
  doDeleteSelectedSubjectMappings(){
    var subjectIdsToClear = this.selection?.selected?.map(it => it.id)
    this.loadClearSubjectMappings = true;
   return  this.professionMatchingOptimizingResource.removeSubjectMappingsForGroup(<JsonWrappedValue<number[]>>{value: subjectIdsToClear}, this.subjectGroupId).then(() =>{
      this.loadSubjectGroup();
      this.loadClearSubjectMappings = false;
    })
  }


  deleteSubjectMapping(subject: LabelData) {
    let cref = this.dialogService.openOverlay(RemoveSubjectToGroupMappingPreviewSheetComponent)
    cref.instance.subjectId = subject.id
    cref.instance.subjectGroupId = this.subjectGroupId

    cref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      this.loadSubjectGroup();
    })
  }

  deleteSubjectGroupToProfessionMapping(profession: LabelData) {
    let cref = this.dialogService.openOverlay(RemoveSubjectGroupToProfessionPreviewSheetComponent)
    cref.instance.professionId = profession.id
    cref.instance.professionTitle = profession.name
    cref.instance.subjectGroupId = this.subjectGroupId

    cref.instance.sideSheetRef.sheetClosed.subscribe(next => {
      this.loadSubjectGroup();
    })
  }

  openSubjectDetailsSheet(subject: SubjectData) {
      let cref = this.dialogService.openOverlay(SubjectDetailsSheetComponent)
      cref.instance.subjectId = subject.id;
      cref.instance.subjectTitle = subject.title;
  }

  openProfessionDetailsSheet(profession: ProfessionData) {
    let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
    cref.instance.professionId = profession.id;
    cref.instance.professionTitle = profession.title;
  }



  applySubjectFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.subjectDataSource.filter = filterValue;
  }

  applyProfessionFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.professionDataSource.filter = filterValue;
  }
}
