import { Component, OnInit } from '@angular/core';
import {EventFilterData, LocationOptionsData, LocationOptionsMode} from "../../generated/data";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminEventResource} from "../../generated/resources";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {
  PreviewTalentsForEventFilterSheetComponent
} from "../preview-talents-for-event-filter-sheet/preview-talents-for-event-filter-sheet.component";

@Component({
  selector: 'app-edit-event-subject-targeting-sheet',
  templateUrl: './edit-event-subject-targeting-sheet.component.html',
  styleUrls: ['./edit-event-subject-targeting-sheet.component.scss']
})
export class EditEventSubjectTargetingSheetComponent implements OnInit {


  updating: boolean = false
  loadingEventFilter: boolean = true
  eventId: number
  data: EventFilterData
  form: FormGroup


  constructor(
      private sideSheetRef: SideSheetRef,
      private fb: FormBuilder,
      private adminEventResource: AdminEventResource,
      private sideSheetService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.getFilterForEvent()
  }


  getFilterForEvent() {
    this.loadingEventFilter = true
    this.adminEventResource.getEventFilterForEvent(this.eventId).then(result => {
      this.data = result
      this.initForm()
      this.loadingEventFilter = false
    })
  }

  updateFilter() {
    this.updating = true
    let data: EventFilterData = {
      locationRadius: this.data?.locationRadius,
      registrationMin: this.data?.registrationMin,
      registrationMax: this.data?.registrationMax,
      studyEndUntil: this.data?.studyEndUntil,
      subjectGroupIds: this.form.controls.subjectGroupIds.value,
      subjectIds: this.form.controls.subjectIds.value,
      activeFilter: this.data?.activeFilter,
      location: <LocationOptionsData>{
        cityIds: this.data?.location.cityIds,
        mode: this.data?.location.mode,
        federalStateIds: []
      }
    }

    this.adminEventResource.updateEventFilter(data, this.eventId).then(() => {
      this.updating = false
    })
  }

  openShowTalentsForFilterSheet() {
    let comp = this.sideSheetService.openOverlay(PreviewTalentsForEventFilterSheetComponent)
    comp.instance.eventId = this.eventId
  }

  initForm() {
    this.form = this.fb.group({
      subjectIds: [this.data?.subjectIds],
      subjectGroupIds: [this.data?.subjectGroupIds],
    })
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

}
