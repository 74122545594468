import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentMatchingReportData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminMatchingCacheController
 */

@Injectable()
@ResourceParams({})
export class AdminMatchingCacheResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matchingCache/talents/{talentId}/matches',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionsInTalentMatchingCache: IResourceMethodObservableStrict<null, null, {talentId: number}, TalentMatchingReportData[]> | undefined
    getPositionsInTalentMatchingCache(talentId: number): Promise<TalentMatchingReportData[]> {
        if (!this._getPositionsInTalentMatchingCache) throw new Error("resource has not been properly initialized")
        return this._getPositionsInTalentMatchingCache(null, null, {talentId: talentId}).toPromise()
    }

}