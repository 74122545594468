<dialog-header dialogHeadline="Bewerber ablehnen"></dialog-header>

<div class="dialog-main-content layout-padding-24-32" style="height: calc(100% - 50px)" fxLayout="column" fxLayoutAlign="space-between">

    <div class="w-100" fxLayout="column" fxLayoutGap="20px">
        <p style="font-size: 12px; font-style: italic; color: #777">
            Warum möchtest du die Bewerbung von {{application.talentName}} auf die Stelle {{application.position.internTitle}} ablehnen?</p>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="9px">
            <div class="outcome-option blue-color" (click)="blockedReason = 'NotEnoughExperience'" [ngClass]="{'selected': blockedReason == 'NotEnoughExperience'}">
                Zu wenig Erfahrung
            </div>

            <div class="outcome-option yelow-color" (click)="blockedReason = 'WrongEducation'"  [ngClass]="{'selected': blockedReason == 'WrongEducation'}">
                Unpassende Ausbildung
            </div>

            <div class="outcome-option red-color" (click)="blockedReason = 'BadGrades'" [ngClass]="{'selected': blockedReason == 'BadGrades'}">
                Zu schlechte Noten
            </div>

            <div class="outcome-option grey-color" style="opacity: 0">
                Sonstiges
            </div>

            <div class="outcome-option grey-color" (click)="blockedReason = 'OtherReason'" [ngClass]="{'selected': blockedReason == 'OtherReason'}">
                Sonstiges
            </div>

            <div class="outcome-option grey-color" style="opacity: 0">
                Sonstiges
            </div>
        </div>

        <mat-form-field class="w-100" appearance="fill" >
            <mat-label>Begründung</mat-label>
            <textarea matInput [(ngModel)]="comment" rows="4"></textarea>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="primary-text-color width-100 button-bar">
        <button mat-button color="warn" class="red-text" (click)="cancel()">Abbrechen</button>

        <loading-button [loadingBool]="loading"
                        (clickEvent)="denyApplication()"
                        [disabledCondition]="blockedReason == null"
                        [raised]="true"
                        buttonClass="mat-primary">Bewerbung ablehnen
        </loading-button>
    </div>
</div>
