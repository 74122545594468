import {AddressData} from "./address-data"
import {CompanySizeCategorization} from "./company-size-categorization"
import {CompanyInvoiceInformationData} from "./company-invoice-information-data"

export class AdminCompanyCreationData {
    address = new AddressData();
    companySize: CompanySizeCategorization = null;
    invoiceInformation = new CompanyInvoiceInformationData();
    keyAccounterId: number | null = null;
    name: string = null;
    shortCompanyInfo: string = null;
    website: string | null = null;
    yearlyRecruitings: number | null = null;
}