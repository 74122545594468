import { Component, OnInit } from '@angular/core';
import {AddressData, AdminCompanyData, BookingData, CompanyPricingConfigData, InvoiceData} from "../../generated/data";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminCompanyResource, AdminInvoiceResource, AdminPricingResource} from "../../generated/resources";

@Component({
  selector: 'app-create-or-update-invoice-side-sheet',
  templateUrl: './create-or-update-invoice-side-sheet.component.html',
  styleUrls: ['./create-or-update-invoice-side-sheet.component.scss']
})
export class CreateOrUpdateInvoiceSideSheetComponent implements OnInit {


    invoice: InvoiceData
    bookingData: BookingData
    loading: boolean = false
    invoiceForm: FormGroup
    companyPricing: CompanyPricingConfigData
    companyId: number
    companyAddressData: AddressData

    constructor(
        private sideSheetRef: SideSheetRef,
        private adminInvoiceResource: AdminInvoiceResource,
        private fb: FormBuilder,
        private adminPricingResource: AdminPricingResource,
        private adminCompanyResource: AdminCompanyResource
    ) {
    }

    ngOnInit(): void {
        this.companyId = this.bookingData?.company?.id? this.bookingData?.company?.id : this.invoice.company.id
        this.initInvoiceData()
        this.initForm()
        this.getCompanyPricing()
        this.getCompanyInfo()
    }

    getCompanyPricing() {
        this.adminPricingResource.getPricingForCompany(this.companyId).then(pricing => {
            this.companyPricing = pricing
        })
    }

    getCompanyInfo() {
        this.adminCompanyResource.getCompanyAddress(this.companyId).then(result => {
            this.companyAddressData = result
        })
    }
    initForm() {
        this.invoiceForm = this.fb.group({
            amount: [this.invoice.originalAmount == undefined? this.bookingData.price : this.invoice.originalAmount, Validators.required],
            date: [this.invoice.date? this.invoice.date : new Date(), Validators.required],
            comment: [this.invoice.invoiceComment || '', Validators.required]
        })
    }

    close(result?: InvoiceData) {
        this.sideSheetRef.sheetClosed.next(result)
    }

    initInvoiceData() {
        if (!this.invoice) {
            this.invoice = <InvoiceData>{
                booking: this.bookingData
            }
        }
    }

    createInvoice() {
        this.loading = true
        this.invoice.originalAmount = this.invoiceForm.controls.amount.value
        this.invoice.date = this.invoiceForm.controls.date.value
        this.invoice.invoiceComment = this.invoiceForm.controls.comment.value;

        this.adminInvoiceResource.createOrUpdateInvoice(this.invoice).then(result => {
            this.close(result)
        })
    }
}

