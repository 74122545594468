import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'locationPreferencesMode'
})
export class LocationPreferencesModePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'Worldwide':
        return 'Weltweit'
      case 'Nationwide':
        return 'Deutschlandweit'
      case 'SpecificStates':
        return 'Bestimmte Bundesländer'
      case 'SpecificCities':
        return 'Bestimmte Städte'
    }
  }

}
