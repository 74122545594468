<dialog-header [dialogHeadline]="'Einstellungen des Karrierecoaches'"></dialog-header>

<div id="dialog-content-wrapper">

    <table mat-table [dataSource]="data" matSort matSortActive="creationDate" matSortDisableClear class="width-100"
           matSortDirection="desc" #dataTable>

        <ng-container matColumnDef="talent">
            <th mat-header-cell *matHeaderCellDef>Talent</th>
            <td mat-cell *matCellDef="let row"> {{row.fullName}}</td>
        </ng-container>

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Position</th>
            <td mat-cell *matCellDef="let row"> {{row.positonName}}</td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Firma</th>
            <td mat-cell *matCellDef="let row">{{row.companyName}}</td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Datum</th>
            <td mat-cell *matCellDef="let row">
                {{row.date | date: "dd.MM.yyyy"}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="right">Aktionen</th>
            <td mat-cell *matCellDef="let row">
                <div class="table-button" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-button mat-icon-button color="primary"
                            matTooltip="Detailiertes Profil anzeigen"
                            (click)="openTalentProfile(row.talentId)">
                        <i class="mdi mdi-account dark"></i>
                    </button>
                </div>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>