<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="layout-padding-8">

            <mat-slide-toggle [(ngModel)]="showOnlyCustomerPositions">Nur Kundenstellen anzeigen</mat-slide-toggle>

            <loading-button
                    matTooltip="Aktualisieren"
                    buttonClass="mat-primary"
                    [loadingBool]="loading"
                    (clickEvent)="load()">
                <i class="mdi mdi-refresh dark filter-color"></i>
            </loading-button>

        </div>



    <loading-spinner [loadingBool]="loading">

        <table mat-table [dataSource]="data" class="max-table" #feedControlTable>

            <ng-container matColumnDef="positionTitle">
                <th mat-header-cell *matHeaderCellDef>Stelle</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.positionTitle">
                    {{element.positionTitle | sliceStringLength: 60}}
                </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef>Unternehmen</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.companyName">
                    {{element.companyName | sliceStringLength: 60}}
                </td>
            </ng-container>

            <ng-container matColumnDef="professionTitle">
                <th mat-header-cell *matHeaderCellDef>Beruf</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.professionTitle">
                    {{element.professionTitle | sliceStringLength: 60}}
                </td>
            </ng-container>

            <ng-container matColumnDef="hasPrimeFilter">
                <th mat-header-cell *matHeaderCellDef>Prime-Filter</th>
                <td mat-cell *matCellDef="let element" >
                    <i *ngIf="element.hasPrimeFilter" class="mdi mdi-check green-color"></i>
                    <i *ngIf="!element.hasPrimeFilter" class="mdi mdi-close red-color"></i>
                </td>
            </ng-container>

            <ng-container matColumnDef="hasSpecialTargeting">
                <th mat-header-cell *matHeaderCellDef>Sondertargetierung</th>
                <td mat-cell *matCellDef="let element" >
                    <i *ngIf="element.hasSpecialTargeting" class="mdi mdi-check green-color"></i>
                    <i *ngIf="!element.hasSpecialTargeting" class="mdi mdi-close red-color"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="openPosition(element.positionId)"><i class="mdi mdi-information"></i></button>
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="['positionTitle','professionTitle', 'companyName','hasPrimeFilter','hasSpecialTargeting','actions']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['positionTitle','professionTitle','companyName','hasPrimeFilter','hasSpecialTargeting','actions'];"></tr>

        </table>


    </loading-spinner>
    <mat-paginator [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   [length]="totalElements"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>
