<form fxLayout="row" fxLayoutAlign="start center" *ngIf="!loading" [formGroup]="dateTimeFormGroup">
  <mat-form-field class="m-right40">
    <input matInput
           [placeholder]="placeholder"
           [matDatepicker]="picker"
           [min]="minDate"
           [max]="maxDate"
           required
           formControlName="date">
    <mat-datepicker
      #picker
      [rangeMode]="false"
      required
      selectFirstDateOnClose="true"
    ></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="start center" id="wrapper">
    <i class="mdi mdi-alarm"></i>
    <mat-form-field class="input first">

      <input matInput required formControlName="innerHourControl" (blur)="onBlurHourInput()" #hourInput
             max="23"
             min="0" autocomplete="off">
    </mat-form-field>

    <div id="point">:</div>


    <mat-form-field class="input">
      <input matInput formControlName="innerMinuteControl" (blur)="onBlurMinuteInput()" #minuteInput
             max="59" min="0" autocomplete="off" (keydown.backspace)="ifEmptyFoucsHourInput()">
    </mat-form-field>
  </div>
</form>
