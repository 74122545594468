import {Component, Input, OnInit} from '@angular/core';
import {AdminCompanyData, DisplayRecruiterData} from "../../generated/data";
import {CreateRecruiterDialogComponent} from "./create-recruiter-dialog/create-recruiter-dialog.component";
import {EditRecruiterDialogComponent} from "./edit-recruiter-dialog/edit-recruiter-dialog.component";
import {ConfirmDialogComponent} from "../../utils/confirm-dialog/confirm-dialog.component";
import {AccountResource, AdminRecruiterResource} from "../../generated/resources";
import {MatDialog} from "@angular/material/dialog";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";

@Component({
  selector: 'company-recruiters',
  templateUrl: './company-recruiters.component.html',
  styleUrls: ['./company-recruiters.component.scss']
})
export class CompanyRecruitersComponent implements OnInit {

  @Input() company: AdminCompanyData

  public recruiterData: DisplayRecruiterData[];
  public loading: boolean = false;

  displayedColumns: string[] = ['id', 'firstName', 'lastName', 'position', 'email', 'phone', 'actions'];

  constructor(
    private adminRecruiterResource: AdminRecruiterResource,
    private accountResource: AccountResource,
    private dialog: MatDialog,
    private dialogService: SideSheetService
  ) {
  }

  ngOnInit(): void {
    this.loadRecruiters();
  }

  loadRecruiters() {
    this.adminRecruiterResource.getRecruitersByCustomerCompanyId(this.company.id, {showDeleted: true}).then(result => {
      this.recruiterData = result
    })
  }

  createNewRecruiterDialog() {
    let comp = this.dialogService.openOverlay(CreateRecruiterDialogComponent, 'Medium')
    comp.instance.companyId = this.company.id

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      if (res == true) {
        this.loadRecruiters()
      }
    })
  }



  editRecruiter(recruiter: DisplayRecruiterData) {
    let comp = this.dialogService.openOverlay(EditRecruiterDialogComponent, 'Medium')
    comp.instance.recruiterData = recruiter
    comp.instance.companyId = this.company.id


    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(res => {
      if (res == true) {
        this.loadRecruiters()
      }
    })
  }

  redirectToCompanyFrontendWithTokenForRecruiter(recruterId: number) {
      this.adminRecruiterResource.loginAsRecruiter(recruterId).then(result => {
          window.open(result, '_blank')
      })
  }

  redirectToCompanyFrontendWithTokenForAdminRecruiter(companyId: number) {
      this.adminRecruiterResource.loginAsAdminRecruiter(companyId).then(result => {
          window.open(result, '_blank')
      })
  }

  deleteRecruiter(recruiter: DisplayRecruiterData) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminRecruiterResource.deleteRecruiterById(recruiter.id),
        title: `Recruiter löschen`,
        message: `Möchten Sie Recruiter ${recruiter.firstName} ${recruiter.lastName} wirklich löschen?`,
        confirmButtonText: "Löschen",
        confirmButtonColor: "primary"
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.loadRecruiters()
      }
    })
  }

  reactivateRecruiter(recruiter: DisplayRecruiterData) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminRecruiterResource.activateRecruiter(recruiter.id),
        title: `Recruiter aktivieren`,
        message: `Möchten Sie Recruiter ${recruiter.firstName} ${recruiter.lastName} wirklich wieder aktivieren?`,
        confirmButtonText: "Aktivieren",
        confirmButtonColor: "primary"
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.loadRecruiters()
      }
    })
  }

}
