import {AdminCompanyCreationData} from "./admin-company-creation-data"
import {LabelData} from "./label-data"
import {RecruiterData} from "./recruiter-data"
import {SalesProposalState} from "./sales-proposal-state"

export class SalesProposalData {
    assignedPositionIds: number[] = null;
    companyDraftData: AdminCompanyCreationData | null = null;
    companyId: number | null = null;
    discount: number | null = null;
    id: number = null;
    originalPrice: number | null = null;
    priceWithTax: number | null = null;
    priceWithoutTax: number | null = null;
    product = new LabelData();
    recruiterDraftData: RecruiterData | null = null;
    serviceDescription: string | null = null;
    size: number | null = null;
    state: SalesProposalState = null;
    taxRate: number | null = null;
    title: string = null;
}