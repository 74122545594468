import {TalentMatchingReportData} from "./talent-matching-report-data"
import {StudiesData} from "./studies-data"
import {AdminEmploymentPositionData} from "./admin-employment-position-data"
import {PositionRejectedReason} from "./position-rejected-reason"
import {TalentPositionRelationState} from "./talent-position-relation-state"

export class AdminTalentFeedPositionRelationData {
    adminRecommendation: string | null = null;
    isBookmarked: boolean = null;
    isPrimeFilterMatch: boolean = null;
    locationPreferenceMatches: boolean = null;
    matchingReport: TalentMatchingReportData | null = null;
    mostRecentStudies: StudiesData | null = null;
    position = new AdminEmploymentPositionData();
    primeRecommendation: boolean = null;
    professionGroupPreferenceMatches: boolean = null;
    pushedInFeedOn: (Date | null) = null;
    recommendable: boolean = null;
    recommendationOccurred: (Date | null) = null;
    rejectReasons: PositionRejectedReason[] | null = null;
    relationState: TalentPositionRelationState = null;
    talentAgentAvailable: boolean = null;
    talentAgentName: string | null = null;
    talentCreationDate: (Date | null) = null;
    talentCvAvailable: boolean = null;
    talentEmailAddress: string = null;
    talentId: number = null;
    talentName: string = null;
    talentPhoneNumber: string | null = null;
    talentQualityScore: number | null = null;
}