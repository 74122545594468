import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, AccountRegistrationData, AccountDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.unisign.api.AdminController
 */

@Injectable()
@ResourceParams({})
export class AdminResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/by-email-address/{emailAddress}/anonymization',
        endpoint: 'com.uniwunder.unisign'
    })
    private _anonymizeAccount: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {emailAddress: string}, AccountDisplayData> | undefined
    anonymizeAccount(requestBody: JsonWrappedValue<string>, emailAddress: string): Promise<AccountDisplayData> {
        if (!this._anonymizeAccount) throw new Error("resource has not been properly initialized")
        return this._anonymizeAccount(requestBody, null, {emailAddress: emailAddress}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/by-id/{id}/anonymization',
        endpoint: 'com.uniwunder.unisign'
    })
    private _anonymizeAccountById: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {id: number}, AccountDisplayData> | undefined
    anonymizeAccountById(requestBody: JsonWrappedValue<string>, id: number): Promise<AccountDisplayData> {
        if (!this._anonymizeAccountById) throw new Error("resource has not been properly initialized")
        return this._anonymizeAccountById(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/by-email-address/{emailAddress}/email-address',
        endpoint: 'com.uniwunder.unisign'
    })
    private _changeAccountEmailAddress: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {emailAddress: string}, AccountDisplayData> | undefined
    changeAccountEmailAddress(requestBody: JsonWrappedValue<string>, emailAddress: string): Promise<AccountDisplayData> {
        if (!this._changeAccountEmailAddress) throw new Error("resource has not been properly initialized")
        return this._changeAccountEmailAddress(requestBody, null, {emailAddress: emailAddress}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/by-id/{id}/email-address',
        endpoint: 'com.uniwunder.unisign'
    })
    private _changeAccountEmailAddressById: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {id: number}, AccountDisplayData> | undefined
    changeAccountEmailAddressById(requestBody: JsonWrappedValue<string>, id: number): Promise<AccountDisplayData> {
        if (!this._changeAccountEmailAddressById) throw new Error("resource has not been properly initialized")
        return this._changeAccountEmailAddressById(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/admin',
        endpoint: 'com.uniwunder.unisign'
    })
    private _createAdminAccount: IResourceMethodObservableStrict<AccountRegistrationData, null, null, AccountDisplayData> | undefined
    createAdminAccount(requestBody: AccountRegistrationData): Promise<AccountDisplayData> {
        if (!this._createAdminAccount) throw new Error("resource has not been properly initialized")
        return this._createAdminAccount(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/partner',
        endpoint: 'com.uniwunder.unisign'
    })
    private _createPartnerAccount: IResourceMethodObservableStrict<AccountRegistrationData, null, null, AccountDisplayData> | undefined
    createPartnerAccount(requestBody: AccountRegistrationData): Promise<AccountDisplayData> {
        if (!this._createPartnerAccount) throw new Error("resource has not been properly initialized")
        return this._createPartnerAccount(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/by-email-address/{emailAddress}/enabled',
        endpoint: 'com.uniwunder.unisign'
    })
    private _enableOrDisableAccount: IResourceMethodObservableStrict<JsonWrappedValue<boolean>, null, {emailAddress: string}, AccountDisplayData> | undefined
    enableOrDisableAccount(requestBody: JsonWrappedValue<boolean>, emailAddress: string): Promise<AccountDisplayData> {
        if (!this._enableOrDisableAccount) throw new Error("resource has not been properly initialized")
        return this._enableOrDisableAccount(requestBody, null, {emailAddress: emailAddress}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/by-id/{id}/enabled',
        endpoint: 'com.uniwunder.unisign'
    })
    private _enableOrDisableAccountById: IResourceMethodObservableStrict<JsonWrappedValue<boolean>, null, {id: number}, AccountDisplayData> | undefined
    enableOrDisableAccountById(requestBody: JsonWrappedValue<boolean>, id: number): Promise<AccountDisplayData> {
        if (!this._enableOrDisableAccountById) throw new Error("resource has not been properly initialized")
        return this._enableOrDisableAccountById(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/by-email-address/{emailAddress}',
        endpoint: 'com.uniwunder.unisign'
    })
    private _getAccountData: IResourceMethodObservableStrict<null, null, {emailAddress: string}, AccountDisplayData> | undefined
    getAccountData(emailAddress: string): Promise<AccountDisplayData> {
        if (!this._getAccountData) throw new Error("resource has not been properly initialized")
        return this._getAccountData(null, null, {emailAddress: emailAddress}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/by-id/{id}',
        endpoint: 'com.uniwunder.unisign'
    })
    private _getAccountDataById: IResourceMethodObservableStrict<null, null, {id: number}, AccountDisplayData> | undefined
    getAccountDataById(id: number): Promise<AccountDisplayData> {
        if (!this._getAccountDataById) throw new Error("resource has not been properly initialized")
        return this._getAccountDataById(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/by-email-address/{emailAddress}/setPasswordCode',
        endpoint: 'com.uniwunder.unisign'
    })
    private _getSetPasswordLinkForAccountByEmail: IResourceMethodObservableStrict<null, {clientId: string}, {emailAddress: string}, JsonWrappedValue<string>> | undefined
    getSetPasswordLinkForAccountByEmail(emailAddress: string, requestParams: {clientId: string}): Promise<JsonWrappedValue<string>> {
        if (!this._getSetPasswordLinkForAccountByEmail) throw new Error("resource has not been properly initialized")
        return this._getSetPasswordLinkForAccountByEmail(null, requestParams, {emailAddress: emailAddress}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/by-email-address/{emailAddress}/locked',
        endpoint: 'com.uniwunder.unisign'
    })
    private _lockOrUnlockAccount: IResourceMethodObservableStrict<JsonWrappedValue<boolean>, null, {emailAddress: string}, AccountDisplayData> | undefined
    lockOrUnlockAccount(requestBody: JsonWrappedValue<boolean>, emailAddress: string): Promise<AccountDisplayData> {
        if (!this._lockOrUnlockAccount) throw new Error("resource has not been properly initialized")
        return this._lockOrUnlockAccount(requestBody, null, {emailAddress: emailAddress}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/by-id/{id}/locked',
        endpoint: 'com.uniwunder.unisign'
    })
    private _lockOrUnlockAccountById: IResourceMethodObservableStrict<JsonWrappedValue<boolean>, null, {id: number}, AccountDisplayData> | undefined
    lockOrUnlockAccountById(requestBody: JsonWrappedValue<boolean>, id: number): Promise<AccountDisplayData> {
        if (!this._lockOrUnlockAccountById) throw new Error("resource has not been properly initialized")
        return this._lockOrUnlockAccountById(requestBody, null, {id: id}).toPromise()
    }

}