<mat-form-field class="no-padding no-margin w-100">
  <mat-select [placeholder]="placeholder" [formControl]="formControl" multiple="true">
    <mat-option>
      <ngx-mat-select-search [formControl]="searchTerm"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let benefit of displayedBenefits" [value]="benefit.id">
      {{benefit.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
