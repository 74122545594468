<dialog-header dialogHeadline="Neues Angebot und Unternehmen erstellen"></dialog-header>

<div class="dialog-content">
    <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="form">
            <ng-template matStepLabel>Unternehmen</ng-template>
            <div [formGroup]="form" fxLayout="column" class="step-content">
                <mat-form-field class="form-field">
                    <input matInput placeholder="Firmenname" required type="text" formControlName="companyNameControl">
                </mat-form-field>

                <mat-form-field class="form-field">
            <textarea matInput placeholder="Kurzinfo (max. 30 Zeichen)" rows="3"
                      formControlName="companyDescriptionControl" required maxlength="30"></textarea>
                </mat-form-field>

                <mat-form-field class="form-field">
                    <mat-select formControlName="companySizeControl" required placeholder="Unternehmensgröße">
                        <mat-option value="Small">1 - 50 Mitarbeiter</mat-option>
                        <mat-option value="Medium">50 - 500 Mitarbeiter</mat-option>
                        <mat-option value="Big">mehr als 500 Mitarbeiter</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="form-field">
                    <input matInput placeholder="Webseite" type="text" formControlName="companyWebsiteControl">
                </mat-form-field>

                <key-accounter-dropdown
                        placeholder="Key Account Manager"
                        [multiple]="false"
                        [required]="false"
                        [control]="form.controls.companyKeyAccounterControl">
                </key-accounter-dropdown>
            </div>
            <div class="actions-box">
                <button mat-button matStepperPrevious>Zurück</button>
                <button mat-flat-button color="primary" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step [stepControl]="companyInvoiceAddressForm">
            <ng-template matStepLabel>Rechnungsinformationen</ng-template>

            <div class="company-form step-content" [formGroup]="companyInvoiceAddressForm" fxLayout="column">
                <div fxLayout="row">
                    <mat-form-field style="width: 90%; margin-right: 10px">
                        <mat-label>Straße</mat-label>
                        <input matInput formControlName="streetNameControl" required>
                    </mat-form-field>

                    <mat-form-field style="width: 25%">
                        <mat-label>Nr.</mat-label>
                        <input matInput formControlName="streetNumberControl" required>
                    </mat-form-field>
                </div>

                <div fxLayout="row">
                    <mat-form-field style="width: 20%; margin-right: 10px">
                        <mat-label>PLZ</mat-label>
                        <input matInput formControlName="zipCodeControl" required>
                    </mat-form-field>

                    <div *ngIf="!loading" style="position:relative; width: 100%;">
                        <app-searchable-city-dropdown
                                placeholder="Stadt"
                                [multiple]="false"
                                [control]="companyInvoiceAddressForm.get('cityIdControl')">
                        </app-searchable-city-dropdown>
                    </div>
                </div>

                <mat-form-field>
                    <mat-label>Rechnungsemail</mat-label>
                    <input matInput formControlName="invoiceEmailControl" required>
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="1rem">
                    <mat-form-field class="w-100">
                        <mat-label>Rechnungsträger Vorname</mat-label>
                        <input required matInput formControlName="invoiceHolderFirstNameControl">
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <mat-label>Rechnungsträger Nachname</mat-label>
                        <input required matInput formControlName="invoiceHolderLastNameControl">
                    </mat-form-field>
                </div>

                <mat-form-field>
                    <mat-label>Umsatzsteuer-Identifikationsnummer (USt-ID bspw. DE123456789)</mat-label>
                    <input matInput formControlName="taxNumberControl">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Extra Informationen (PO/ Bestellnummer/ Kostenstelle)</mat-label>
                    <input matInput formControlName="purchaseInfoControl">
                </mat-form-field>
            </div>
            <div class="actions-box">
                <button mat-button matStepperPrevious>Zurück</button>
                <button mat-flat-button color="primary" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step [stepControl]="recruiterCreationForm">
            <ng-template matStepLabel>Recruiter</ng-template>

            <div [formGroup]="recruiterCreationForm" class="step-content">

                <div class="form-group" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between start">
                    <div fxLayout="column" class="width-50">
                        <mat-form-field >
                            <input required formControlName="firstName" class="form-control" matInput placeholder="Vorname"
                                   [ngClass]="{ 'is-invalid': submitted && controls.firstName.errors }">
                        </mat-form-field>
                        <div *ngIf="submitted && controls.firstName.errors" class="invalid-feedback">
                            <div class="error" *ngIf="controls.firstName.errors.required">Vorname fehlt</div>
                        </div>
                    </div>

                    <div fxLayout="column" class="width-50">
                        <mat-form-field>
                            <input required formControlName="lastName" class="form-control" matInput placeholder="Nachname"
                                   [ngClass]="{ 'is-invalid': submitted && controls.lastName.errors }">
                        </mat-form-field>
                        <div *ngIf="submitted && controls.lastName.errors" class="invalid-feedback">
                            <div class="error" *ngIf="controls.lastName.errors.required">Nachname fehlt</div>
                        </div>
                    </div>
                </div>

                <div class="form-group" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
                    <div fxLayout="column" class="width-50">
                        <mat-form-field>
                            <mat-select formControlName="gender" placeholder="Geschlecht">
                                <mat-option value="Male">Männlich</mat-option>
                                <mat-option value="Female">Weiblich</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="submitted && controls.gender.errors" class="invalid-feedback">
                            <div class="error" *ngIf="controls.gender.errors.required">Geschlecht fehlt</div>
                        </div>
                    </div>

                    <div fxLayout="column" class="width-50">
                        <mat-form-field>
                            <input formControlName="position" class="form-control" matInput placeholder="Position">
                        </mat-form-field>
                        <div *ngIf="submitted && controls.position.errors" class="invalid-feedback">
                            <div class="error" *ngIf="controls.position.errors.required">Position fehlt</div>
                        </div>
                    </div>
                </div>

                <div class="form-group" fxLayout="column" fxLayoutAlign="start  start">
                    <div fxLayout="column">
                        <mat-form-field>
                            <input required formControlName="email" class="form-control" matInput placeholder="Emailadresse"
                                   [ngClass]="{ 'is-invalid': submitted && controls.email.errors }">
                        </mat-form-field>
                        <div *ngIf="submitted && !emailIsFree" class="invalid-feedback">
                            <div class="error">E-Mail Adresse bereits vergeben</div>
                        </div>
                        <div *ngIf="submitted && controls.email.errors" class="invalid-feedback">
                            <div class="error" *ngIf="controls.email.errors.required">E-Mail Adresse fehlt</div>
                        </div>
                    </div>

                    <div fxLayout="column">
                        <mat-form-field>
                            <input required formControlName="phone" class="form-control" matInput placeholder="Telefonnummer">
                        </mat-form-field>
                        <div *ngIf="submitted && controls.phone.errors" class="invalid-feedback">
                            <div class="error" *ngIf="controls.phone.errors.required">Telefonnummer fehlt</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions-box">
                <button mat-button matStepperPrevious>Zurück</button>
                <button mat-flat-button color="primary" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Angebot</ng-template>
            <div class="step-content">
                <create-sales-proposal (valid)="validProposal = $event"
                                       [showSelectCompany]="false"
                                       (salesProposal)="salesProposal = $event"></create-sales-proposal>
            </div>
            <div class="actions-box">
                <button mat-button matStepperPrevious>Zurück</button>
                <loading-button buttonClass="form-field mat-primary"
                                [raised]="false"
                                [loadingBool]="loading"
                                [disabledCondition]="!allValid()"
                                (clickEvent)="onSubmit()">Speichern
                </loading-button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>



