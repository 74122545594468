<dialog-header [dialogHeadline]="'Talentfilter einstellen'"></dialog-header>

<div class="layout-padding-24-32">
    <div fxLayout="row" fxLayoutAlign="space-between" style="margin: 0 18px 0 18px">
        <div fxLayout="row" fxLayoutGap="15px">
            <button mat-raised-button color="accent" style="color: white;" (click)="loadTalentFilters()">
                <i class="mdi mdi-sync mdi-24px"></i> Prime-Filter laden
            </button>
            <button mat-raised-button color="accent" style="color: white;" (click)="resetTalentFilters()">
                <i class="mdi mdi-undo mdi-24px"></i>Filter zurücksetzen
            </button>
        </div>

        <button mat-raised-button color="primary" style="color: white;" (click)="applyFilter()">
            <i class="mdi mdi-content-save mdi-24px"></i>  Filter übernehmen
        </button>
    </div>


    <talent-filter-group class="width-100" *ngIf="filtersLoaded" [filters]="filters"></talent-filter-group>
</div>

