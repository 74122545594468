import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'defaultIfNull'
})
export class DefaultIfNullPipe implements PipeTransform {

  constructor() {
  }

  transform(value?: any): string {

    if (value == null) {
      return "n.a."
    } else {
      return value
    }
  }
}
