import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
    AdminApplicationResource,
    AdminEmploymentPositionResource,
    CompanyResource,
} from '../../../generated/resources';
import {
  AdminApplicationData,
  ApplicationFilterData,
  CompanyData,
  EmploymentPositionOrigin
} from '../../../generated/data';
import {Subject, Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {debounceTime} from 'rxjs/internal/operators';
import {ApplicationDetailsDialogComponent} from "../../../applications/application-details-dialog/application-details-dialog.component";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {
  CreateApplicationDialogComponent
} from "../../../applications/create-application-dialog/create-application-dialog.component";

@Component({
  selector: 'talent-applications',
  templateUrl: './talent-applications.component.html',
  styleUrls: ['./talent-applications.component.scss']
})
export class TalentApplicationsComponent implements OnInit, OnDestroy {

  @Input() talentId: number;

  applicationsPageSize: number = 15;
  applicationsPageSizeOptions: number[] = [15, 30, 60, 100];
  applicationsTotalSize: number = 0;
  applicationsPageIndex: number = 0;

  applicationColumns = ['creationDate', 'releaseDate', 'company', 'positionTitle', 'state', 'actions'];
  positionOrigins: EmploymentPositionOrigin[] = ["Campusjaeger","Uniwunder","Absolventa","Experteer","Joblift","Pluss","Stellenanzeigen"]

  applyFilter: Subject<boolean> = new Subject();

  requestSearchQuery = new FormControl(null);
  searchCompanyIdControl = new FormControl(null);
  searchPositionIdControl = new FormControl(null);

  dataSource: AdminApplicationData[] = [];
  applicationFilterData: ApplicationFilterData = <ApplicationFilterData>{};

  companies: CompanyData[];

  loading: Boolean = false;
  filterPanelIsOpen: Boolean = false;

  searchQuerySubscription: Subscription

  constructor(
    public adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private formBuilder: FormBuilder,
    private adminApplicationResource: AdminApplicationResource,
    private companyResource: CompanyResource,
    private dialogService: SideSheetService
  ) {
  }

  ngOnDestroy(): void {
        this.searchQuerySubscription.unsubscribe();
    }

  ngOnInit() {
    this.applicationFilterData.origin = [...this.positionOrigins]
    this.companyResource.getCompaniesByQuery().then(result => this.companies = result.content);

    this.loadRequestContent();

    this.searchQuerySubscription = this.requestSearchQuery.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.loadRequestContent();
    });
  }

  get controls() { return this.requestsFormGroup.controls; }

  requestsFormGroup: FormGroup = this.formBuilder.group({
    state: [null],
    name: [''],
    position: [null]
  });

  handlePage(event: PageEvent) {
    this.applicationsPageIndex = event.pageIndex;
    this.applicationsPageSize = event.pageSize;
    this.loadRequestContent();
  }

  loadRequestContent() {
    this.loading = true;

    this.applicationFilterData.searchQuery = this.requestSearchQuery.value;
    this.applicationFilterData.searchCompanyId = this.searchCompanyIdControl.value;
    this.applicationFilterData.positionId = this.searchPositionIdControl.value;
    this.applicationFilterData.talentId = this.talentId;

    this.adminApplicationResource.getAllApplications(this.applicationFilterData, {
      pageSize: this.applicationsPageSize,
      page: this.applicationsPageIndex
    }).then(result => {
      this.dataSource = result.content;
      this.applicationsTotalSize = result.totalElements;
      this.loading = false;
    });
  }

  switchPositionState(event) {
    this.applicationFilterData.showInactive = event.checked;
    this.loadRequestContent();
  }

  openCreateApplicationDialog() {
    let cref = this.dialogService.openOverlay(CreateApplicationDialogComponent, "Small");
    cref.instance.talentId = this.talentId;

    cref.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if(result) {
        this.loadRequestContent()
      }
    })
  }

  openApplicationDetails(application: AdminApplicationData) {
    let cref = this.dialogService.openOverlay(ApplicationDetailsDialogComponent);
    cref.instance.applicationId = application.applicationId;
  }

  openFilterPanel() {
    if (this.filterPanelIsOpen) {
      this.filterPanelIsOpen = false;
      this.searchPositionIdControl.setValue(null)
      this.searchCompanyIdControl.setValue(null)
      this.requestSearchQuery.setValue(null)
    } else this.filterPanelIsOpen = true;
  }
}
