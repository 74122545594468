import {Component, OnInit} from '@angular/core';
import {
  AdminApplicationData,
  CareerEventData,
  CompleteTalentProfileData,
  PositionContentSplitData
} from '../../generated/data';
import {FileService} from '../../services/file.service';
import {MatDialog} from '@angular/material/dialog';
import {
  AdminApplicationResource,
  AdminCareerEventResource,
  AdminTalentResource,
  StudyInfoResource
} from '../../generated/resources';
import {UtilityService} from '../../utils/utility.service';
import {ConfirmDialogComponent} from '../../utils/confirm-dialog/confirm-dialog.component';
import {CareerEventPipe} from '../../utils/pipes/career-event.pipe';
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {RejectApplicationDialogComponent} from "../reject-application-dialog/reject-application-dialog.component";

@Component({
  selector: 'application-overview-dialog',
  templateUrl: './application-overview-dialog.component.html',
  styleUrls: ['./application-overview-dialog.component.scss']
})
export class ApplicationOverviewDialogComponent implements OnInit {

  application: AdminApplicationData;
  talent: CompleteTalentProfileData;

  careerEvents: CareerEventData[];
  displayedCareerEventColumns = [
    'type',
    'university',
    'subject',
    'actions',
  ];

  loading: boolean = false;

  cvFile: Blob = null;
  content: PositionContentSplitData
  loadingAcceptApplication: boolean = false;
  loadingDenyApplication: boolean = false;

  constructor(
    public studyInfoResource: StudyInfoResource,
    private adminTalentResource: AdminTalentResource,
    private utilityService: UtilityService,
    private applicationResource: AdminApplicationResource,
    private talentResource: AdminTalentResource,
    private fileService: FileService,
    private dialog: MatDialog,
    private adminCareerEventResource: AdminCareerEventResource,
    private utils: UtilityService,
    private careerTypePipe: CareerEventPipe,
    private dialogService: SideSheetService,
    private sideSheetRef: SideSheetRef
  ) {
  }

  ngOnInit() {

    this.content = this.application.position.contents.filter(it => it.active)[0]
    this.fileService.getApplicationDossier(this.application.applicationId).subscribe((cv) => {
      this.cvFile = cv
    })

    this.loadProfile(this.application.talentId);
    this.loadCareerEvents();
  }

  private loadProfile(talentId) {
    this.loading = true;
    this.adminTalentResource
      .getDetailedTalentForAdmin(talentId)
      .then(p => {
        this.talent = p;
        this.loading = false;
      });
  }

  openTalentProfile(talentId: number) {
    let comp = this.dialogService.openOverlay(TalentProfileDialogComponent, 'Huge')
    comp.instance.talentId = talentId
  }

  openPositionDetailsDialog(positionId, tabIndex: number = 0) {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
    comp.instance.tabIndex = tabIndex
  }

  deleteCareerEvent(event) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminCareerEventResource.removeCareerEvent({
          talentId: this.application.talentId,
          id: event.id
        }),
        title: `Ereignis löschen`,
        message: `Möchtest du das Werdegangsereignis \"${this.careerTypePipe.transform(event.type)}\" wirklich löschen?`,
        confirmButtonText: 'Löschen',
        confirmButtonColor: 'primary'
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.loadCareerEvents();
        this.utils.showSnackBar('Der Eintrag wurde gelöscht');
      }
    });
  }

  loadCareerEvents() {
    this.adminCareerEventResource.getTalentCareerEvents(this.application.talentId).then(result => {
      this.careerEvents = result;
    });
  }

  cancel() {
    this.sideSheetRef.sheetClosed.next({type: 'Abort', success: false});
  }

  acceptApplication() {
    this.applicationResource.acceptTalentApplication(this.application.applicationId).then((success) => {
      this.sideSheetRef.sheetClosed.next({type: 'Accept', success: success});
    })
  }

  denyApplication() {
    let cref = this.dialogService.openOverlay(RejectApplicationDialogComponent);
    cref.instance.application = this.application

    cref.instance.sideSheetRef.sheetClosed.subscribe((result) => {
      if(result) this.sideSheetRef.sheetClosed.next(result);
    })
  }
}
