<loading-spinner [loadingBool]="loading">
    <div fxLayout="column" class="layout-padding-8" fxLayoutGap="4px">
        <div fxLayout="column" class="mat-elevation-z1 layout-padding">

            <h4>Auto-Matching</h4>

            <div fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" class="width-100" fxLayoutAlign="space-between center" fxLayoutGap="4px">
                <span>Status: <b>{{config?.state}}</b></span>
                    <div fxLayout="row">
                        <button color="warn" *ngIf="config?.state ==='Active'" mat-button (click)="deactivatePosition()">
                            Matching stoppen
                        </button>
                        <button (click)="openHistory()" mat-button>
                            <mat-icon>history</mat-icon>
                            Matching-Verlauf
                        </button>
                        <button color="primary" [disabled]="!canSave()"
                                mat-flat-button (click)="saveAndRematch()">Speichern & Matchen
                        </button>
                </div>
            </div>
            <div *ngIf="(this.config?.pricingMode == 'Booking' || this.config?.pricingMode == 'PrepaidBooking')" class="error-message">
                Der Stelle muss einer Buchung zugewiesen sein,<br> damit sie Online gehen kann</div>
            <div class="error-message" *ngIf="this.config?.komboIntregrationId && !this.config.komboId">
                Du hast diese Stelle noch nicht mit einer Stelle bei Kombo verknüpft!</div>

            </div>

            <div fxLayout="row" class="width-100" fxLayoutAlign="space-between center" fxLayoutGap="4px">
                <div>
                    <span>Beruf für Matching: <b
                                            [ngStyle]="{'font-style:italic': !config?.configData?.profession}">{{config?.configData?.profession?.name || 'Nicht konfiguriert'}}</b></span>
                    <button mat-flat-button (click)="openEditProfessionSheet()"><i class="mdi mdi-24px mdi-pencil"></i>
                    </button>
                </div>
                <div *ngIf="!locationValid()">
                    <span style="color: red">Es muss eine valider Standort ausgewält sein!</span>
                </div>
            </div>

            <mat-accordion>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Tätigkeitsfelder
                        <i class="mdi mdi-alert red mdi-18px" *ngIf="config?.configData?.positionProfessionFieldRelations.length == 0" matTooltip="Keine Tätigkeitsfelder gesetzt, Matching eingeschränkt"></i>
                        </mat-panel-title>
                        <mat-panel-description>Feintuning des Matchings über Ausschluss / Highlighting
                            passender/unpassender Tätigkeitsfelder
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="controlling-board-info">
                        Hier werden alle Tätigkeitsfelder angezeigt, die zum derzeit aktiven Beruf passen könnten.
                        Markiere solche, die besonders gut oder besonders schlecht passen. So kann das System besser auf
                        Wünsche und Studiengänge aussteuern
                    </div>
                   <div fxLayout="row" fxLayoutAlign="end center"><button mat-button color="accent" (click)="openEditProfessionFieldSheet()"><i class="mdi mdi-pencil"></i>Bearbeiten</button></div>
                    <table mat-table *ngIf="config?.configData" [dataSource]="professionFieldDataSource" class="w-100">
                        <ng-container matColumnDef="fieldName">
                            <th mat-header-cell *matHeaderCellDef>Tätigkeitsfeld</th>
                            <td mat-cell *matCellDef="let element">{{element.field.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="qualification">
                            <th mat-header-cell *matHeaderCellDef>Stufe</th>
                            <td mat-cell *matCellDef="let element">{{element.qualification | fieldPositionRelation}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="['fieldName','qualification']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['fieldName','qualification'];"></tr>
                    </table>


                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div fxLayout="column" class="mat-elevation-z1 layout-padding">
            <h4>Sondertargetierung</h4>
            <div class="controlling-board-info">
                Die Sondertargetierung ergänzt das automatische Matching. Sie kann dazu verwendet werden, um
                Studiengänge, die eigentlich gar nicht zum Beruf passen würden, mit zu targetieren.
                z.B. kann man auf diese Weise Sportwissenschaftler für eine Beraterstelle matchen, die die Stelle
                normalerweise nicht erhalten würden.
            </div>
            <div fxLayout="row" class="width-100" fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>Sondertargetierung:
            <span *ngIf="config?.numSpecialTargetingFilters == 0" style="font-style: italic">Inaktiv</span>
            <span *ngIf="config?.numSpecialTargetingFilters > 0">{{config?.numSpecialTargetingFilters}} Filter konfiguriert</span>
        </span>
                <button mat-flat-button (click)="openSpecialTargeting()"><i class="mdi mdi-24px mdi-pencil"></i>
                </button>
            </div>
        </div>


        <div fxLayout="column" class="mat-elevation-z1 layout-padding">
            <h4>Prime-Filter</h4>
            <div class="controlling-board-info">
                Die Prime-Filter legen fest, welche Talente Prime-Matches für diese Stelle sind. Prime-Matches erhalten
                ggf. automatisch einen Prime-Vorschlag zur Stelle.
                Außerdem werden sie im Active-Sourcing-Modul der Firmen und im Targetierungsmodul für den Admin
                angezeigt, um manuelle Prime-Vorschläge machen zu können.
            </div>
            <div fxLayout="row" class="width-100" fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>Prime-Filter:
            <span *ngIf="config?.numPrimeFilters == 0" style="font-style: italic">Inaktiv</span>
            <span *ngIf="config?.numPrimeFilters > 0">{{config?.numPrimeFilters}} Filter konfiguriert</span>
        </span>
                <button mat-flat-button (click)="openPrimeFilter()"><i class="mdi mdi-24px mdi-pencil"></i></button>
            </div>

        </div>

        <div>

        </div>
    </div>
</loading-spinner>
