<dialog-header dialogHeadline="ATS Stellen importieren"></dialog-header>
<mat-dialog-content>
    <div class="content-wrapper">
        <mat-progress-spinner mode="indeterminate" *ngIf="loading"></mat-progress-spinner>
        <div class="filter-bar" fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field>
                <input matInput
                       [formControl]="searchControl"
                       placeholder="Suche nach Stellentitel">
            </mat-form-field>
        </div>

        <div>
            <table mat-table [dataSource]="positionsDatasource" matSort
                   class="width-100">

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      [disabled]="(row.uniwunderPositionId != null)"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> Stellentitel</th>
                    <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                        {{element.title}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> Kombo ID</th>
                    <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                        {{element.komboJobId}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="content">
                    <th mat-header-cell *matHeaderCellDef> content</th>
                    <td mat-cell *matCellDef="let element" style="max-width: 440px; "><div class="job-content">{{element.content}}
                    </div></td>
                </ng-container>

                <ng-container matColumnDef="ourPosition">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center">Uniwunder-Stelle</th>
                    <td mat-cell *matCellDef="let element" style="text-align: center; padding: 10px 0 0 0">
                        <searchable-position-dropdown *ngIf="element.uniwunderPositionId == null" [required]="false" [multiple]="false" [companyId]="companyId" [control]="element.control" [placeholder]="'Stellenfilter'">
                        </searchable-position-dropdown>

                        <div *ngIf="element.uniwunderPositionId != null">
                            {{element.uniwunderPositionId}}
                            <button mat-button mat-icon-button color="primary"
                                    matTooltip="Stellendetails"
                                    (click)="openPositionDetailsDialog(element.uniwunderPositionId)"
                            >
                                <i class="mdi mdi-information dark"></i>
                            </button>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="m-right10" (click)="close()">Abbrechen</button>

    <loading-button [loadingBool]=importPositionsLoading [raised]="true" color="primary"
                    (clickEvent)="importPositions()">Stellen von ATS importieren</loading-button>

</mat-dialog-actions>
