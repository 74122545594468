import { Component, OnInit } from '@angular/core';
import {AdminMatchingResource} from "../../generated/resources";
import {PositionMatchingConfigData} from "../../generated/data";
import {PageEvent} from "@angular/material/paginator";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";

@Component({
  selector: 'app-matching-config-review',
  templateUrl: './matching-config-review.component.html',
  styleUrls: ['./matching-config-review.component.scss']
})
export class MatchingConfigReviewComponent implements OnInit {

  pageSize = 30;
  pageIndex = 0;
  totalElements;
  showOnlyCustomerPositions = true;
  data: PositionMatchingConfigData[] = []

  constructor(private adminMatchingResource: AdminMatchingResource, private dialogService: SideSheetService) { }

  ngOnInit(): void {
    this.load()
  }

  loading = false;
  load(){
    this.loading = true;
    this.adminMatchingResource.getActivePositionsWithMatchingConfigurations({pageNum: this.pageIndex, pageSize: this.pageSize, onlyCustomerPositions: this.showOnlyCustomerPositions}).then(res => {
      this.data = res.content;
      this.totalElements = res.totalElements;
this.loading = false;
    })
  }


  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.load()
  }

  openPosition(positionId:number){
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId;
  }

}
