import {Component, OnInit} from '@angular/core';
import {
  AdminTalentCoachRequestCountsData,
  ConsultantData,
  ConsultantHiringData,
  JsonWrappedValue
} from '../generated/data';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {
    AccountResource,
    AdminConsultantResource,
    AdminRecruiterResource,
    AdminTalentResource
} from '../generated/resources';
import {PageEvent} from "@angular/material/paginator";
import {ConfirmSideSheetComponent} from "../utils/confirm-side-sheet/confirm-side-sheet.component";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {ConsultantHiringsDialogComponent} from "./consultant-hirings-dialog/consultant-hirings-dialog.component";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss']
})
export class ConsultantsComponent implements OnInit {
  consultantData: ConsultantData[];
  waitingTalents: AdminTalentCoachRequestCountsData;
  displayedColumns: string[] = ["name", "emailAddress", "phoneNumber", "talentCount", "countHiring", "deleted", "creation", "actions"];

  searchControl: FormControl = new FormControl()
  loading = false;

  pageSize: number = 15;
  pageSizeOptions: number[] = [15, 30, 60, 2000];
  totalSize: number = 0;
  pageIndex: number = 0;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private adminConsultantResource: AdminConsultantResource,
    private talentResource: AdminTalentResource,
    private accountResource: AccountResource,
    private dialogService: SideSheetService,
  ) {
  }

  ngOnInit() {
    this.loadData();

    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(next => {
      this.loadData()
    })
  }

  loadData() {
    this.loading= true;
    this.adminConsultantResource.getActiveConsultants({
      page: this.pageIndex, pageSize: this.pageSize, q: this.searchControl.value == ""? null : this.searchControl.value
    }).then(result => {
      this.consultantData = result.content;
      this.totalSize = result.totalElements;
      this.loading = false;
    })
    this.talentResource.getNumTalentsWaitingForAgent().then(result => this.waitingTalents = result)
  }

  redirectToMlprontendWithTokenForUser(consultantEmail: string) {
          this.adminConsultantResource.loginAsConsultant({email: consultantEmail}).then(result => {
              window.open(result, '_blank')
          })
  }

  openConsultantHiringsInfoDialog(data: ConsultantHiringData[]) {
    let comp = this.dialogService.openOverlay(ConsultantHiringsDialogComponent, 'Huge')
    comp.instance.data = data
  }

  editConsultant(id: number) {
    this.router.navigateByUrl('/consultants/' + id);
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }
}
