import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {AdminCompanyData, AdminEmploymentPositionDisplayData} from "../../../generated/data";
import {AdminCompanyResource, AdminEmploymentPositionResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'searchable-position-dropdown',
  templateUrl: './searchable-position-dropdown.component.html',
  styleUrls: ['./searchable-position-dropdown.component.scss']
})
export class SearchablePositionDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false
  @Input() companyId: number

  positions: AdminEmploymentPositionDisplayData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
      private adminPositionResource: AdminEmploymentPositionResource
  ) {
  }

  ngOnInit() {
    this.loadCompanies();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          this.loadCompanies()
        });
  }


  loadCompanies() {
    this.adminPositionResource.getEmploymentPositionsByQuery({q: this.filterControl.value, companyId: this.companyId, pageSize: 30, forcedIds: this.control.value}).then(companies => {
          this.positions = companies.content;
        }
    )
  }

  selectAll() {
    this.control.setValue(this.positions.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}


