<div [formGroup]="proposalForm" class="width-100">
    <div fxLayout="column" fxLayoutAlign="start start" class="width-100">
        <div fxLayout="row" fxLayoutGap="16px" class="width-100">
            <div fxLayout="column" fxLayoutAlign="start start" class="width-100">

                <searchable-company-by-pricingmode-dropdown
                        *ngIf="showSelectCompany"
                        [filteredModes]="['PrepaidBooking']"
                        placeholder="Firma"
                        [multiple]="false"
                        [required]="true"
                        [control]="proposalForm.controls['companyId']">
                </searchable-company-by-pricingmode-dropdown>

                <mat-form-field class="width-50">
                    <mat-label>Produkt</mat-label>
                    <mat-select required formControlName="product" [multiple]="false">

                        <mat-option *ngFor="let product of products" [value]="product">
                            {{product.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="width-50">
                    <mat-label>Buchungsgröße (Bewerbungen)</mat-label>
                    <input required matInput type="number" formControlName="size">
                </mat-form-field>

                <mat-form-field class="width-50">
                    <mat-label>Original Preis bei vollständiger erfüllung OHNE Rabatt(€)</mat-label>
                    <input matInput required type="number" formControlName="price">
                </mat-form-field>

                <info-box message="Der Gesamt Preis ist dann Original-Preis - Rabatt"></info-box>

                <mat-form-field>
                    <mat-label>Rabatt (%)</mat-label>
                    <input required matInput type="number" formControlName="discount">
                </mat-form-field>


                <mat-form-field class="width-100">
                    <mat-label>Leistungsbeschreibung</mat-label>
                    <textarea required matInput cdkTextareaAutosize
                              cdkAutosizeMinRows="4"
                              cdkAutosizeMaxRows="10" formControlName="serviceDescription"></textarea>
                </mat-form-field>

                <div>Preis inkl. Rabatt: {{getTotalPrice() | number:'1.2'}} €</div>
                <div>Preis inkl. {{proposalForm.controls.product.value?.salesTax}}%
                    Umsatzsteuer: {{getTotalPriceWithTax() | number:'1.2'}} €
                </div>

                <div *ngIf="positions?.length" class="m-top15">
                    <div
                            style="color: black;
               font-size: 15px;
               margin-bottom: 1rem;
               font-weight: 600;">
                        Welche Stellen sollen direkt dem Bewerberpaket zugeordnet werden?
                    </div>

                    <mat-progress-spinner *ngIf="loadingPositions"></mat-progress-spinner>

                    <mat-checkbox
                            *ngIf="!loadingPositions"
                            [checked]="assignedPositions.length === positions.length"
                            (change)="toggleAllAssignedPositions()">
                        Alle Stellen
                    </mat-checkbox>

                    <div *ngIf="!loadingPositions">
                        <div *ngFor="let position of positions" class="m-left20">
                            <mat-checkbox [checked]="assignedPositions.includes(position.id)"
                                          (change)="updateAssignedPositions(position.id)">
                                {{position.internTitle}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
