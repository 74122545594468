import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'user-comment',
  templateUrl: './user-comment.component.html',
  styleUrls: ['./user-comment.component.scss']
})
export class UserCommentComponent implements OnInit {

  @Input() comment: string

  constructor() { }

  ngOnInit(): void {
  }

}
