import {Component, Input, OnInit} from '@angular/core';
import {AdminCompanyResource, AdminTopJobResource} from "../../generated/resources";
import {AdminEmploymentPositionDisplayData, EmploymentPositionDisplayData, TopJobSlotData} from "../../generated/data";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";

@Component({
  selector: 'company-topjobs',
  templateUrl: './company-topjobs.component.html',
  styleUrls: ['./company-topjobs.component.scss']
})
export class CompanyTopjobsComponent implements OnInit {

  @Input() companyId: number = 0
  topjobSlots: TopJobSlotData[] = []

  loadingPositions: boolean = false
  loadingTopjobs: boolean = false

  public companyEmploymentPositions: AdminEmploymentPositionDisplayData[] = []

  constructor(
      private adminTopjobRessource: AdminTopJobResource,
      private adminCompanyResource: AdminCompanyResource,
      private dialogService: SideSheetService
  ) { }

  ngOnInit(): void {
    this.loadTopJobSlots()
    this.loadEmploymentPositions()
  }


  loadTopJobSlots(){
    this.loadingTopjobs = true
    this.adminTopjobRessource.getTopJobSlotsForCompany(this.companyId).then(res => {
      this.topjobSlots = res
      this.loadingTopjobs = false
    })
  }

  createNewTobjobSlot(){
    this.adminTopjobRessource.createNewSlotForCompany(this.companyId).then(res => {
      //TODO Notification
      this.loadTopJobSlots()
    })
  }

  loadEmploymentPositions(){
    this.loadingPositions = true
    this.adminCompanyResource.getEmploymentPositionsForCompany(this.companyId).then(result => {
      this.companyEmploymentPositions = result;
      this.loadingPositions = false;
    })
  }

  assignPositionToTopjobSlot(id: number, positionId: number){
    this.adminTopjobRessource.updateSlotPosition(id, {positionId: positionId}).then(res => {
      //TODO Notification
      this.loadTopJobSlots()
    })
  }

  deleteTopjobSlot(id: number){
    this.adminTopjobRessource.deleteSlot(id).then(res => {
      //TODO Notification
      this.loadTopJobSlots()
    })
  }

  unsetTopjobSlot(id: number){
    this.adminTopjobRessource.updateSlotPosition(id, {positionId: null}).then(res => {
      //TODO Notification
      this.loadTopJobSlots()
    })
  }

  filterForTopjob(positionId: number): boolean{
    let tobjob = this.topjobSlots.filter(slot => {
      if(slot.position?.id == positionId){
        return true
      }
    })
    return tobjob.length > 0;
  }

  openPositionDetailsDialog(positionId, tabIndex: number = 0) {
    let comp = this.dialogService.openOverlay(PositionDetailsDialogComponent, 'Huge')
    comp.instance.positionId = positionId
    comp.instance.tabIndex = tabIndex

    comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
      this.loadEmploymentPositions()
    })
  }
}
