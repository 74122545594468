import { Component, OnInit } from '@angular/core';
import {
  MinimalProfessionSubGroupData, MinimalProfessionTypeData,
  ProfessionData,
  ProfessionGroupData,
  ProfessionSubGroupData,
  ProfessionTypeData
} from "../../../generated/data";
import {MatTableDataSource} from "@angular/material/table";
import {UtilityService} from "../../../utils/utility.service";
import {
  AdminProfessionResource,
  AdminSubjectResource,
  ProfessionMatchingOptimizingResource
} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {ProfessionTypeDetailsSheetComponent} from "../profession-type-details-sheet/profession-type-details-sheet.component";
import {ProfessionSubGroupDetailsSheetComponent} from "../profession-sub-group-details-sheet/profession-sub-group-details-sheet.component";
import {ProfessionMainGroupDetailsSheetComponent} from "../profession-main-group-details-sheet/profession-main-group-details-sheet.component";
import {ProfessionAreaDetailsSheetComponent} from "../profession-area-details-sheet/profession-area-details-sheet.component";
import {ProfessionDetailsSheetComponent} from "../profession-details-sheet/profession-details-sheet.component";

@Component({
  selector: 'app-profession-group-details-sheet',
  templateUrl: './profession-group-details-sheet.component.html',
  styleUrls: ['./profession-group-details-sheet.component.scss']
})
export class ProfessionGroupDetailsSheetComponent implements OnInit {

  public professionGroupId: number
  public professionGroupTitle: string
  public professionGroupData: ProfessionGroupData
  public professionSubGroupDataSource = new MatTableDataSource()
  public professionTypeDataSource = new MatTableDataSource()
  public professionDataSource = new MatTableDataSource()
  public loading: boolean = true

  public displayedProfessionSubGroupColumns = ['agencyKey', 'professionSubGroup']
  public displayedProfessionTypeColumns = ['agencyKey', 'professionType']
  public displayedProfessionColumns = ['agencyKey', 'profession','numSubjectGroups', 'numSubjects']

  constructor(
      public utilityService: UtilityService,
      private professionResource: AdminProfessionResource,
      private adminSubjectResource: AdminSubjectResource,
      private professionMatchingOptimizingResource: ProfessionMatchingOptimizingResource,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.professionDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.professionTypeDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.professionSubGroupDataSource.filterPredicate = (s: ProfessionData, filter: string) => s.title.toLocaleLowerCase().indexOf(filter) != -1;
    this.loadProfession()
  }

  loadProfession(): void {
    this.loading = true
    this.professionResource.getProfessionGroup(this.professionGroupId).then(group => {
      this.professionGroupData = group
      this.professionSubGroupDataSource.data = this.professionGroupData.professionSubGroups
      this.professionTypeDataSource.data = this.professionGroupData.professionTypes
      this.professionDataSource.data = this.professionGroupData.professions
      this.loading = false;

    })
  }

  openProfessionMainGroupDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionMainGroupDetailsSheetComponent)
    cref.instance.professionMainGroupId = this.professionGroupData.mainGroupId;
    cref.instance.professionMainGroupTitle = this.professionGroupData.mainGroupTitle;
  }

  openProfessionAreaDetailsSheet() {
    let cref = this.dialogService.openOverlay(ProfessionAreaDetailsSheetComponent)
    cref.instance.professionAreaId = this.professionGroupData.areaId
    cref.instance.professionAreaTitle = this.professionGroupData.areaTitle;
  }

  openProfessionSubGroupDetailsSheet(professionSubGroup: MinimalProfessionSubGroupData) {
    let cref = this.dialogService.openOverlay(ProfessionSubGroupDetailsSheetComponent)
    cref.instance.professionSubGroupId = professionSubGroup.id;
    cref.instance.professionSubGroupTitle = professionSubGroup.title;
  }

  openProfessionTypeDetailsSheet(professionType: MinimalProfessionTypeData) {
    let cref = this.dialogService.openOverlay(ProfessionTypeDetailsSheetComponent)
    cref.instance.professionTypeId = professionType.id;
    cref.instance.professionTypeTitle = professionType.title;
  }

  openProfessionDetailsSheet(profession: ProfessionData) {
    let cref = this.dialogService.openOverlay(ProfessionDetailsSheetComponent)
    cref.instance.professionId = profession.id;
    cref.instance.professionTitle = profession.title;
  }

  applyFilter(dataSource: MatTableDataSource<any>, filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    dataSource.filter = filterValue;
  }
}
