<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <span *ngIf="markedHired"
          matTooltip="Zu dieser Bewerbung wurde angegeben, dass das Talent eingestellt wurde"
          class="green">
        Talent eingestellt
    </span>

    <span *ngIf="!markedHired"
          [ngClass]="{
          'yellow': state === 'Sent',
          'grey': state === 'Draft',
          'blue': state === 'Interesting' || state === 'AppProcessEnter' || state === 'Hired' || state === 'ContractSent',
          'green': state === 'Granted',
          'red': state === 'Denied' || state === 'Withdrawn' || state === 'Failed'
        }">
        {{ state | requestState }}
    </span>
</div>
