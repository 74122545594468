<h1 mat-dialog-title>{{data.title}}</h1>

<div class="dialog-main-content">
  <p>{{data.message}}</p>
  <p style="font-size: small; color: gray">{{data.description}}</p>
</div>

<div fxLayout="row" fxLayoutAlign="end center" class="button-bar primary-text-color">
  <button mat-flat-button class="cancel-button" (click)="cancel()">Abbrechen</button>
  <loading-button [raised]="false"
                  buttonClass="mat-primary {{data.confirmButtonColor}}"
                  (clickEvent)="confirm()"
                  [loadingBool]="confirming">{{data.confirmButtonText}}</loading-button>
</div>

