import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../../../utils/side-sheet/sideSheetRef";
import {FormControl, Validators} from "@angular/forms";
import {AdminEmploymentPositionQuestionResource} from "../../../../generated/resources";
import {SideSheetService} from "../../../../utils/side-sheet/side-sheet.service";
import {UtilityService} from "../../../../utils/utility.service";

@Component({
  selector: 'app-create-position-question-side-sheet',
  templateUrl: './create-position-question-side-sheet.component.html',
  styleUrls: ['./create-position-question-side-sheet.component.scss']
})
export class CreatePositionQuestionSideSheetComponent implements OnInit {

  //input
  positionId: number
  positionTitle: string

  saving: boolean = false

  questionControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(10)])

  constructor(
      private sideSheetRef: SideSheetRef,
      private utilityService: UtilityService,
      private adminEmploymentPositionQuestionResource: AdminEmploymentPositionQuestionResource
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.questionControl.invalid) return
    this.saving = true

    this.adminEmploymentPositionQuestionResource.createPositionQuestion(this.positionId, {positionQuestion: this.questionControl.value}).then(result => {
      if (result) {
        this.utilityService.showSnackBar("Frage erfolgreich erstellt")
        this.sideSheetRef.sheetClosed.next(result)
        this.saving = false
      }
    })
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

}
