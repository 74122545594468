<div *ngIf="pricingForm" class="main-details-wrapper" fxLayoutAlign="space-between start">

    <form class="company-form" [formGroup]="pricingForm" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="m-bottom15" *ngIf="pricingModes">
            <mat-form-field style="width: 400px">
                <mat-label>Welchen Vertragsmodus hat das Unternehmen</mat-label>
                <mat-select [multiple]="false" formControlName="mode">
                    <mat-option *ngFor="let mode of pricingModes" [value]="mode">{{mode | companyPricingMode}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="pricingForm.controls.mode.value == 'HiringPercentage'">
            <mat-form-field  style="width: 400px">
                <mat-label>Prozent am Einstellungsgehalt</mat-label>
                <input type="number"
                       matInput
                       formControlName="hiringPercentage">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="pricingForm.controls.mode.value == 'HiringValue'">
            <mat-form-field  style="width: 400px">
                <mat-label>Festpreis pro Einstellung</mat-label>
                <input type="number"
                       matInput
                       formControlName="hiringAmount">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field  style="width: 100%">
                <mat-label>Kommentar</mat-label>
                <textarea  matInput
                       formControlName="comment"></textarea>
            </mat-form-field>
        </div>

    </form>
</div>
