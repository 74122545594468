<dialog-header dialogHeadline="Mapping entfernen"></dialog-header>

<div class="dialog-content" fxLayout="column">

    <p style="color: #7d7d7d; font-size: 14px">
        Du bist im Begriff das Mapping zwischen dem Studiengang <b>{{subjectData?.title}}</b> auf die
        Studiengruppe <b>{{subjectGroupData?.title}}</b> zu entfernen. Das bedeutet, dass alle Matches zu diesem Mapping
        aus den Warteschlangen der Talente entfernt werden. Im folgenden findest du die Anzahl der betroffenen Matches und Talente,
        sowie die Stellen, welche aus den Warteschlangen gelöscht werden.
    </p>

    <div *ngIf="!subjectMappingMatchesData" fxLayout="row" fxLayoutAlign="center" class="width-100">
        <p  style="color: #7d7d7d; font-size: 14px"> Lade Stellen aus den Warteschlangen... </p>
    </div>

    <loading-spinner [loadingBool]="loadingQueues" style="min-height: 250px">
        <div *ngIf="subjectMappingMatchesData" fxLayout="column" class="w-100">
            <small><b>Anzahl der Matches:</b> {{subjectMappingMatchesData?.numberOfMatches}}</small>
            <small><b>Anzahl der Talente mit diesem Studiengang:</b> {{subjectMappingMatchesData?.numberOfMatchableTalentsWithSubject}}</small>
            <small><b>Anzahl der Talente mit Matches in der Warteschlange:</b> {{subjectMappingMatchesData?.numberOfTalentsWithMatchesFromMapping}}</small>

            <table mat-table [dataSource]="subjectMappingMatchesData.positionsWithMatchesFromMapping" class="width-100 m-bottom20">
                <ng-container matColumnDef="positionId">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let position">
                        <span class="copy">{{position.id}} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="positionTitle">
                    <th mat-header-cell *matHeaderCellDef>Position</th>
                    <td mat-cell *matCellDef="let position">
                        <span class="copy"> {{position.name}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>

    </loading-spinner>


    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <loading-button
                [raised]="true"
                [color]="'primary'"
                [loadingBool]="loading"
                (clickEvent)="removeSubjectToGroupMapping()">
            Bestätigen
        </loading-button>
    </div>
</div>

