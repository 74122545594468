import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminHiringStatisticData, InvoiceData} from "../../generated/data";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminApplicationResource, AdminInvoiceResource} from "../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {
    PositionDetailsDialogComponent
} from "../../positions/position-details-dialog/position-details-dialog.component";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";

@Component({
    selector: 'app-billings-hiring-details-dialog',
    templateUrl: './billings-hiring-details-dialog.component.html',
    styleUrls: ['./billings-hiring-details-dialog.component.scss']
})
export class BillingsHiringDetailsDialogComponent implements OnInit, OnDestroy {

    formGroup: FormGroup
    formSub: Subscription

    createInvoiceLoading: boolean = false

    hiringData: AdminHiringStatisticData
    updateHiringLoading: boolean = false

    constructor(
        private fb: FormBuilder,
        private sideSheetRef: SideSheetRef,
        private sideSheetService: SideSheetService,
        private adminApplicationResource: AdminApplicationResource,
        private snackbar: MatSnackBar,
        private adminInvoiceResource: AdminInvoiceResource
    ) {
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe()
    }

    ngOnInit(): void {
        this.initForm()
        // this.initFormSub()
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            signingDate: [this.hiringData.signingDate],
            startingDate: [this.hiringData.startingDate],
            salaryState: [this.hiringData.salaryState ? this.hiringData.salaryState : "SalaryGiven", Validators.required],
            salaryText: [this.hiringData.salaryAmount, Validators.required],
            notes: [this.hiringData.adminNote]
        })
    }



    openPositionDetailsDialog() {
        let comp = this.sideSheetService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = this.hiringData.positionId
    }

    createInvoice() {
        this.createInvoiceLoading = true
        this.updateHiring(false).then(() => {
            this.adminInvoiceResource.createHiringPercentageInvoice({applicationId: this.hiringData.applicationId}).then(result => {
                    this.hiringData.invoiceId = result
                    this.createInvoiceLoading = false
                }
            )
        })

    }

    openTalentProfile() {
        let comp = this.sideSheetService.openOverlay(TalentProfileDialogComponent, 'Huge')
        comp.instance.talentId = this.hiringData.talentId
    }

    updateHiring(close: boolean = true): Promise<any> {
        this.updateHiringLoading = true
        return this.adminApplicationResource.updateHiringInformationForApplication(
            {
                salaryAmount: this.formGroup.controls.salaryText.value,
                startingDate: this.formGroup.controls.startingDate.value,
                signingDate: this.formGroup.controls.signingDate.value,
                adminNote: this.formGroup.controls.notes.value,
                salaryState: this.formGroup.controls.salaryState.value
            },
            this.hiringData.applicationId
        ).then(result => {
            if (result) {

                this.hiringData.signingDate = this.formGroup.controls.signingDate.value
                this.hiringData.startingDate = this.formGroup.controls.startingDate.value
                this.hiringData.salaryState = this.formGroup.controls.salaryState.value
                this.hiringData.salaryAmount = this.formGroup.controls.salaryText.value
                this.hiringData.adminNote = this.formGroup.controls.notes.value

                if (close) {
                    this.snackbar.open("Vertragsinformationen erfolgreich gespeichert!", null, {duration: 2000})
                    this.sideSheetRef.sheetClosed.next(result)
                }
            }
        })
    }

}
