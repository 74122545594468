<div class="width-100" fxLayout="column" fxLayoutGap="10px">
    <div class="width-100 layout-padding" fxLayout="row" fxLayoutAlign="space-between center">
        <div><span *ngIf="activeQuestionsCount >= 4" class="warn">Mehr als 3 aktive Fragen sollten vermieden werden!</span></div>
        <button style="color: white" mat-flat-button color="primary" (click)="openCreateQuestionDialog()">neue Frage erstellen</button>
    </div>

    <div class="width-100" fxLayout="column" fxLayoutGap="10px">
        <loading-spinner [loadingBool]="loadingQuestions">
            <div *ngFor="let question of questions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="question">
                <div>{{question.question}}</div>
                <mat-slide-toggle [checked]="question.enabled" (change)="toggleQuestionState($event, question)">Frage aktiv</mat-slide-toggle>
            </div>

            <div *ngIf="!questions.length" class="width-100 text-center" style="color: grey">
                Zu dieser Stelle gibt es noch keine Fragen
            </div>
        </loading-spinner>

    </div>
</div>
