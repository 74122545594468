import { Component, OnInit } from '@angular/core';
import {AdminApplicationData, ApplicationFilterData} from "../../../../generated/data";
import {SideSheetRef} from "../../../../utils/side-sheet/sideSheetRef";
import {AdminApplicationResource} from "../../../../generated/resources";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-applications-side-sheet',
  templateUrl: './applications-side-sheet.component.html',
  styleUrls: ['./applications-side-sheet.component.scss']
})
export class ApplicationsSideSheetComponent implements OnInit {

  constructor(private sideSheetRef: SideSheetRef, private applicationResource: AdminApplicationResource) { }

  filter: ApplicationFilterData;

  pageIndex: number = 0;
  pageSize: number = 30;
  pageSizeOptions: number[] = [15, 30, 60, 100];
  totalSize: number = 0;

  ngOnInit(): void {
    this.load();
  }

  applications:AdminApplicationData[] = [];
  loading = false;

  displayedColumns = ['creationDate','talentName','company','position','state']

  handlePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.load();
  }

  load(){
    this.loading = true;
    this.applicationResource.getAllApplications(this.filter, {
      pageSize: this.pageSize,
      page: this.pageIndex
    }).then(res => {
      this.applications = res.content;
      this.totalSize = res.totalElements;
      this.loading = false;
    })
  }
}
