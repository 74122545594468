<dialog-header [dialogHeadline]="'Admin bearbeiten'"></dialog-header>

<div id="dialog-content-wrapper" >

  <div class="side-sheet-content" style="padding: 1rem;">
    <form [formGroup]="recruiterEditForm">

      <div class="form-group" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <mat-form-field>
            <input formControlName="firstName" class="form-control" matInput placeholder="Vorname"
                   [ngClass]="{ 'is-invalid': submitted && controls.firstName.errors }">
          </mat-form-field>
          <div *ngIf="submitted && controls.firstName.errors" class="invalid-feedback">
            <div class="error" *ngIf="controls.firstName.errors.required">Vorname fehlt</div>
          </div>
        </div>

        <div fxLayout="column">
          <mat-form-field>
            <input formControlName="lastName" class="form-control" matInput placeholder="Nachname"
                   [ngClass]="{ 'is-invalid': submitted && controls.lastName.errors }">
          </mat-form-field>
          <div *ngIf="submitted && controls.lastName.errors" class="invalid-feedback">
            <div class="error" *ngIf="controls.lastName.errors.required">Nachname fehlt</div>
          </div>
        </div>
      </div>

      <div class="form-group" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <mat-form-field>
            <mat-select formControlName="gender" placeholder="Geschlecht">
              <mat-option value="Male">Männlich</mat-option>
              <mat-option value="Female">Weiblich</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="submitted && controls.gender.errors" class="invalid-feedback">
            <div class="error" *ngIf="controls.gender.errors.required">Geschlecht fehlt</div>
          </div>
        </div>

        <mat-form-field>
          <input formControlName="position" class="form-control" matInput placeholder="Position">
        </mat-form-field>
      </div>

      <div class="form-group" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <mat-form-field>
            <input formControlName="email" class="form-control" matInput placeholder="Email"
                   [ngClass]="{ 'is-invalid': submitted && controls.email.errors }">
          </mat-form-field>
          <div *ngIf="controls.email.errors" class="invalid-feedback">
            <div class="error">E-Mail Adresse bereits vergeben</div>
          </div>
          <div *ngIf="submitted && controls.email.errors" class="invalid-feedback">
            <div class="error" *ngIf="controls.email.errors.required">E-Mail Adresse fehlt</div>
          </div>
        </div>

        <mat-form-field>
          <input formControlName="phone" class="form-control" matInput placeholder="Telefon">
        </mat-form-field>
      </div>

      <div style="color: black; font-size: 15px;">Benachrichtigungseinstellungen</div>
      <div style="margin-bottom: 10px">
        <span style="color: grey; font-size: 12px;"> Auf welche Art soll der Recruiter kontaktiert werden? </span>
      </div>

      <div fxLayout="row" fxLayoutAlign="start space-around" fxLayoutGap="5px">
        <form [formGroup]="notificationForm">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-slide-toggle class="toggles" formControlName="emailNotification">
              Per E-Mail
            </mat-slide-toggle>

            <mat-slide-toggle class="toggles" formControlName="smsNotification">
              Per SMS
            </mat-slide-toggle>

          </div>
        </form>
      </div>
    </form>

    <div style="padding: 1rem 0;">
      <div
              style="color: black;
               font-size: 15px;
               margin-bottom: 1rem;
               font-weight: 600;">
        Für welche Stellen ist der Recruiter zuständig?</div>

      <mat-progress-spinner *ngIf="loadingPositions"></mat-progress-spinner>

      <mat-checkbox
              *ngIf="!loadingPositions"
              [checked]="assignedPositions.length == positions.length"
              (change)="toggleAllAssignedPositions()">
        Alle Stellen
      </mat-checkbox>

      <ul *ngIf="!loadingPositions" style="max-height: 300px; overflow-y: auto;">
        <li *ngFor="let position of positions">
          <mat-checkbox [checked]="assignedPositions.includes(position.id)"
                        (change)="updateAssignedPositions(position.id)">
            {{position.internTitle}}}
          </mat-checkbox>
        </li>
      </ul>
    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-flat-button class="cancel-button" (click)="onCancel()">Abbrechen</button>
    <loading-button buttonClass="mat-primary"
                    [raised]="false"
                    [loadingBool]="loading"
                    [disabledCondition]="!recruiterEditForm.valid"
                    (click)="onSubmit()">Speichern
    </loading-button>
  </div>
</div>
