import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {Subject} from "rxjs";
import {LabelData, StudyAreaData} from "../../../generated/data";
import {AdminProfessionResource, StudyInfoResource} from "../../../generated/resources";
import {debounceTime} from 'rxjs/internal/operators';
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'searchable-study-area-dropdown',
  templateUrl: './searchable-study-area-dropdown.component.html',
  styleUrls: ['./searchable-study-area-dropdown.component.css']
})
export class SearchableStudyAreaDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = false
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  @ViewChild('options') options: MatSelect;
  @ViewChild('scrollwrapper') scrollwrapper: ElementRef;

  studyAreas: LabelData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(private studyInfoResource: StudyInfoResource) {
  }

  ngOnInit() {
    this.loadProfessionTypes();

    this.filterControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {
          this.loadProfessionTypes()
        });
  }

  loadProfessionTypes() {
    this.studyInfoResource.getStudyAreas({
      q: this.filterControl.value,
      pageSize: 100,
      forcedIds: this.control.value
    }).then(areas => { this.studyAreas = areas.content; })
  }

  selectAll() {
    this.control.setValue(this.studyAreas.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}
