import {Component, OnInit} from '@angular/core';
import {MatchingConfigHistoryEntryData, MatchingConfigHistoryYearlyGroupData} from "../../generated/data";
import {AdminMatchingResource} from "../../generated/resources";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'app-matching-history',
  templateUrl: './matching-history.component.html',
  styleUrls: ['./matching-history.component.scss']
})
export class MatchingHistoryComponent implements OnInit {
  logs: MatchingConfigHistoryEntryData[];
  groupedLogs: MatchingConfigHistoryYearlyGroupData[] = [];
  positionId: number;
  loading = true;

  constructor(
      private sideSheetRef: SideSheetRef,
      private adminMatchingResource: AdminMatchingResource,) {
  }

  ngOnInit(): void {
    this.load()
  }

  load() {
    this.adminMatchingResource.getPositionMatchingConfigHistory(this.positionId).then(res => {
      this.groupedLogs = res;
      this.loading = false
    })
  }

  close() {
    this.sideSheetRef.sheetClosed.next()
  }


  formatDate(dateString: Date): string {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month} - ${hours}:${minutes}`;
  }
}