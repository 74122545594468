import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  /**
   * generate readable recital from stringlist
   * @param list
   * @param lastSeperator
   */
  generateRecital(list: string[], lastSeperator: string = 'und'): string {
    if (list.length == 0) return "";
    if (list.length == 1) return list[0];

    return `${list.slice(0, list.length - 1).join(', ')} ${lastSeperator} ${list[list.length - 1]}`;
  }

  /**
   * shrink text and add ...
   * @param text
   * @param length
   */
  shrinkText(text: string, length: number = 200) {
    if (text.length <= length) {
      return text;
    }

    return `${text.substring(0, length)}...`;
  }

  /**
   * Check password for security
   * @param password
   */
  requirementsPasswordMatched(password: string, minScore: number = 3, minlength: number = 8) {
    if (password == null) {
      return {passwordEmpty: true};
    }
    if (password.length < minlength) {
      return {passwordTooShort: true};
    }

    let securityScore = 0;

    if (/[a-z]/.test(password)) securityScore++; // at least one lowercase letter
    if (/[A-Z]/.test(password)) securityScore++; // at least one uppercase letter
    if (/[0-9]/.test(password)) securityScore++; // at least one digit
    if (/[^A-Za-z0-9]/.test(password)) securityScore++; // at least one special char

    if (securityScore < minScore) {
      return {securityRequirementsNotMatched: true};
    }

    return null;
  }

  validatePhoneNumber(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      let regExp = RegExp('^(\\(?([\\d \\-\\)\\–\\+\\/\\(]+){6,}\\)?([ .\\-–\\/]?)([\\d]+))$');
      if (!control.value) {
        return {isNull: true};
      }
      if (regExp.test(control.value) == false) {
        return {isNotPhoneNumber: true};
      }
      return null;
    }

  }

}


export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function deepclone(object: any): any {
  return JSON.parse(JSON.stringify(object));
}
