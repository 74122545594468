import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {CityData} from "../../../generated/data";
import {LocationResource} from "../../../generated/resources";
import {debounceTime} from 'rxjs/internal/operators';

@Component({
  selector: 'app-searchable-city-dropdown',
  templateUrl: './searchable-city-dropdown.component.html',
  styleUrls: ['./searchable-city-dropdown.component.css']
})
export class SearchableCityDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  cities: CityData[] = [];
  filteredCities;

  filterControl: FormControl = new FormControl()

  constructor(
    private locationResource: LocationResource,
  ) {
  }

  ngOnInit() {
    this.loadCities();

    this.filterControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(newValue => {
        this.loadCities()
    });
  }


  loadCities() {
    this.locationResource.getCities({q: this.filterControl.value, pageSize: 10, forcedIds: this.control.value}).then(cities => {
        this.cities = cities.content;
      }
    )
  }

  selectAll() {
    this.control.setValue(this.cities.map(o => o.id))
  }

  unselectAll() {
    this.control.setValue([])
  }
}


