<div class="details-wrapper">
    <loading-spinner [loadingBool]="loading">
        <div fxLayout="row" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutAlign="end center">
                <loading-button buttonClass="mat-primary"
                                style="position: relative; top: 3px"
                                [loadingBool]="loadingUpdate"
                                matTooltip="Speichern"
                                [disabledCondition]="!pricingValid"
                                (clickEvent)="updatePricingForCompany()">
                    <span style="position: relative;top: -4px;margin-right: 10px;">Speichern</span>
                    <i class="mdi mdi-content-save main-action-icon"
                       style="position: relative; bottom: 5px"></i>
                </loading-button>
            </div>
        </div>

        <app-company-pricing-content *ngIf="!loading" [(pricing)]="pricing" (valid)="pricingValid = $event"></app-company-pricing-content>

    </loading-spinner>
</div>
