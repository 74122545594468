<div fxLayout="row" fxLayoutAlign="end center" class="layout-padding">
  <button mat-button mat-icon-button color="primary"
          matTooltip="Als Admin-Recruiter anmelden"
          (click)="redirectToCompanyFrontendWithTokenForAdminRecruiter(company.id)">
    <i class="mdi mdi-account-switch main-action-icon"></i>
  </button>
  <loading-button buttonClass="mat-primary"
                  (clickEvent)="createNewRecruiterDialog()"
                  [loadingBool]="loading"
                  matTooltip="Neuen Recruiter anlegen" class="primary">
    <i class="mdi mdi-plus main-action-icon"></i>
  </loading-button>
</div>

<loading-spinner [loadingBool]="loading">
  <table class="w-100" mat-table [dataSource]="recruiterData" *ngIf="recruiterData != null">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>Vorname</th>
      <td mat-cell *matCellDef="let row">{{row.firstName}}</td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Nachname</th>
      <td mat-cell *matCellDef="let row">{{row.lastName}}</td>
    </ng-container>
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Position</th>
      <td mat-cell *matCellDef="let row">{{row.position}}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-Mail</th>
      <td mat-cell *matCellDef="let row">{{row.emailAddress}}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Telefon</th>
      <td mat-cell *matCellDef="let row">{{row.phoneNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let recruiter">
        <div class="table-button" fxLayout="row" fxLayoutAlign="end center">
          <button *ngIf="recruiter.activated" mat-button mat-icon-button
                  matTooltip="Recruiter bearbeiten"
                  (click)="editRecruiter(recruiter)">
            <i class="mdi mdi-pencil dark"></i>
          </button>
          <button *ngIf="recruiter.activated" mat-button mat-icon-button
                  matTooltip="Recruiter löschen"
                  (click)="deleteRecruiter(recruiter)">
            <i class="mdi mdi-delete dark"></i>
          </button>
          <button *ngIf="recruiter.activated == false" mat-button mat-icon-button
                  matTooltip="Recruiter aktivieren"
                  (click)="reactivateRecruiter(recruiter)">
            <i class="mdi mdi-account-key dark-important"></i>
          </button>

          <button mat-button mat-icon-button
                  matTooltip="Als Recruiter einloggen"
                  (click)="redirectToCompanyFrontendWithTokenForRecruiter(recruiter.id)">
            <i class="mdi mdi-account-switch dark-important"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'deactivated': row.activated==false}"></tr>
  </table>
</loading-spinner>

<div *ngIf="!recruiterData">
  <p style="margin-left: 10px">Aktuell sind keine Recruiter vorhanden.</p>
</div>
