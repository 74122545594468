import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface ConfirmDialogData {
  message: string;

  onConfirm(): Promise<any>;

  confirmButtonText: string; //Optional
  title: string //optional

  confirmButtonColor: string // Optional

  description: string // Optional
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  confirming: Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    if (data.title == null) data.title = "Bestätigen";
    if (data.confirmButtonText == null) data.confirmButtonText = "Bestätigen";
    if (data.confirmButtonColor == null) data.confirmButtonColor = "accent";
  }

  ngOnInit() {
  }

  //TODO Validate form
  confirm() {
    this.confirming = true;
    this.data.onConfirm().then(() => {
      this.confirming = false;
      this.dialogRef.close(true);
    })
  }

  cancel() {
    this.dialogRef.close(false)
  }
}
