import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideSheetBackArrowDirective} from "./side-sheet-back-arrow.directive";
import {SideSheetCloseButtonDirective} from './side-sheet-close-button.directive';
import {SideSheetService} from "./side-sheet.service";
import {MatIconModule} from "@angular/material/icon";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {DialogHeaderComponent} from "./dialog-header/dialog-header.component";


@NgModule({
  declarations: [
    SideSheetBackArrowDirective,
    SideSheetCloseButtonDirective,
    DialogHeaderComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FlexModule,
    MatButtonModule
  ],
  providers:[SideSheetService],
  exports: [
    SideSheetBackArrowDirective,
    SideSheetCloseButtonDirective,
    DialogHeaderComponent
  ]
})
export class SideSheetModule {
}
