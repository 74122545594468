<dialog-header dialogHeadline="Studiengang ersetzen"></dialog-header>

<div class="dialog-content" fxLayout="column">

    <p style="color: #7d7d7d; font-size: 14px">
        Ersetze <b>{{subjectTitle}}</b> durch einen anderen Studiengang.<br>
        Der neue Studiengang wird dann bei allen Talenten mit diesem Studiengang ersetzt.<br>
        Danach werden diese Talente noch einmal mit dem neuen Studiengang gematched.<br>
        Abschließend wird <b>{{subjectTitle}}</b> als Studiengang gelöscht.
    </p>

    <searchable-subject-dropdown placeholder="Studiengang" [control]="subjectControl" [multiple]="true"></searchable-subject-dropdown>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <loading-button
                [raised]="true"
                [color]="'primary'"
                [loadingBool]="loading"
                [disabledCondition]="subjectControl.invalid"
                (clickEvent)="replaceSubject()">
            Speichern
        </loading-button>
    </div>
</div>
