import {SubjectData, TalentFilterData} from "../../../../generated/data";
import {AdminEmploymentPositionResource, StudyInfoResource} from "../../../../generated/resources";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'talent-filter',
    templateUrl: './talent-filter.component.html',
    styleUrls: ['./talent-filter.component.scss']
})
export class TalentFilterComponent implements OnInit {

    @Input()
    filter: TalentFilterData = <TalentFilterData>{};

  @Input()
  index: number;

  @Input()
  filterLength: number;

  @Input()
  isSpecialTargeting: boolean = false

  @Output() filterChange: EventEmitter<any> = new EventEmitter();
  @Output() copyFilter: EventEmitter<any> = new EventEmitter();
  @Output() removeFilter: EventEmitter<any> = new EventEmitter();

  formGroup: FormGroup
  matchingWordList: string[] = null
  showMatchingWordsList: boolean = false
  matchingWordsLoading: boolean = false
  loading = false;

  constructor(
    public studyInfoResource: StudyInfoResource,
    public adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private fb: FormBuilder
  ) {
  }

  subjects: SubjectData[] = [];

  ngOnInit() {
    this.formGroup = this.fb.group({
      studyFieldControl: this.filter?.studyFieldIds?.length > 0 ? [this.filter.studyFieldIds] : [],
      studyAreaControl: this.filter?.studyAreaIds?.length > 0 ? [this.filter.studyAreaIds] : [],
      subjectControl: this.filter?.studySubjectIds?.length > 0 ? [this.filter.studySubjectIds] : [],
      subjectGroupsControl: this.filter?.subjectGroupIds?.length > 0 ? [this.filter.subjectGroupIds]: [],
      citiesControl: this.filter?.cityIds?.length > 0 ? [this.filter.cityIds] : [],
      federalStatesControl: this.filter?.federalStateIds?.length > 0 ? [this.filter.federalStateIds] : [],
        degreeTypeControl: this.filter?.degreeTypes?.length > 0 ? [this.filter.degreeTypes] : []
    })

    this.formGroup.valueChanges.subscribe(next => {
      this.filter.studyFieldIds = this.formGroup.controls.studyFieldControl.value
      this.filter.studyAreaIds = this.formGroup.controls.studyAreaControl.value
      this.filter.studySubjectIds = this.formGroup.controls.subjectControl.value
      this.filter.subjectGroupIds = this.formGroup.controls.subjectGroupsControl.value
      this.filter.cityIds = this.formGroup.controls.citiesControl.value
        this.filter.federalStateIds = this.formGroup.controls.federalStatesControl.value
        this.filter.degreeTypes = this.formGroup.controls.degreeTypeControl.value
        this.filterChange.emit(this.filter);
    })

  }


  getMatchingWords() {
    if(this.matchingWordsLoading) return
    if(this.matchingWordList == null) {
      this.matchingWordsLoading = true
      return this.adminEmploymentPositionResource.getMatchingWordsForSearchTermInTalentFilter({searchTerms: this.filter.cvFullTextSearchString}).then(result => {
        this.matchingWordList = result
        this.matchingWordsLoading = false
      })
    } else this.matchingWordList = null;
  }

  emit() {
    this.filterChange.emit(this.filter);
  }
}

