<dialog-header [dialogHeadline]="talent?.firstName + ' ' + talent?.lastName + ' (' + talent?.id + ')'"></dialog-header>

<div class="dialog-main-content">

    <div class="width-100 height-100">

        <div style="background-color: white" class="mat-elevation-z6">
            <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="onTabChange($event)" #tabGroup>

                <mat-tab>
                    <div class="details-wrapper">
                        <loading-spinner [loadingBool]="loading" style="display: block; height: 100%">
                            <ng-template mat-tab-label>Talentdetails</ng-template>

                            <div fxLayout="row" [fxLayoutAlign]="talent?.deleted ? 'space-between center' : 'end start'"
                                 class="width-100">
                                <div *ngIf="talent && talent?.deleted" style="color: red; margin-left: 45px">Account
                                    gelöscht
                                </div>

                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <loading-button buttonClass="mat-primary"
                                                    [loadingBool]="loading"
                                                    matTooltip="Speichern"
                                                    (clickEvent)="save()">
                                        <i class="mdi mdi-content-save main-action-icon"></i>
                                    </loading-button>

                                    <loading-button buttonClass="mat-warn"
                                                    [loadingBool]="loadingDeactivateTalent"
                                                    matTooltip="Talent Löschen"
                                                    [disabledCondition]="talent?.deleted"
                                                    (clickEvent)="deactivateTalent()">
                                        <i class="mdi mdi-delete main-action-icon"></i>
                                    </loading-button>
                                </div>
                            </div>

                            <div *ngIf="!loading && talent" class="main-details-wrapper" fxLayoutAlign="start center"
                                 fxLayoutGap="40px">
                                <div class="picture-wrapper" fxLayout="column" fxLayoutAlign="start start"
                                     fxLayoutGap="10px" (click)="editProfilePicture()">
                                    <img *ngIf="profilePicture != null"
                                         [src]="profilePicture"
                                         class="profile-picture">
                                    <img *ngIf="profilePicture == null"
                                         src="../../../assets/images/blurred_user.png"
                                         class="profile-picture">
                                    <div class="profile-picture-overlay"></div>
                                    <img src="assets/images/star.png" matTooltip="Prime-Talent!" width="50"
                                         class="prime-star" *ngIf="talent?.prime">
                                </div>

                                <div fxLayout="column" fxLayoutGap="15px">
                                    <div fxLayout="row" fxLayoutGap="15px">
                                        <div fxLayout="column" fxLayoutGap="5px">
                                            <span class="value-read-only">Vorname</span>
                                            <span>{{talent.firstName}}</span>
                                        </div>

                                        <div fxLayout="column" fxLayoutGap="5px">
                                            <span class="value-read-only">Nachname</span>
                                            <span>{{talent.lastName}}</span>
                                        </div>
                                    </div>

                                    <div fxLayout="column" fxLayoutGap="5px">
                                        <span class="value-read-only">Telefonnummer</span>
                                        <span>{{talent.phoneNumber}}</span>
                                    </div>

                                    <div fxLayout="column" fxLayoutGap="5px">
                                        <span class="value-read-only">Emailadresse</span>
                                        <span>{{talent.emailAddress}}</span>
                                    </div>

                                    <div fxLayout="column" fxLayoutGap="5px">
                                        <span class="value-read-only">Kontaktweg</span>
                                        <span>{{talent.preferredContactOption}}</span>
                                    </div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="end" style="width: 55%">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Über mich</mat-label>
                                        <textarea matInput [(ngModel)]="talent.aboutMe" name="aboutMe" rows="3">
                                        </textarea>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>Bemerkung (nur für Admin)</mat-label>
                                        <textarea matInput [(ngModel)]="adminNote" rows="3"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="width-100" fxLayout="row" fxLayoutGap="15px">
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <span class="value-read-only">utmCampaign</span>
                                    <span>{{talent?.utmCampaign}}</span>
                                </div>

                                <div fxLayout="column" fxLayoutGap="5px">
                                    <span class="value-read-only">utmContent</span>
                                    <span>{{talent?.utmContent}}</span>
                                </div>

                                <div fxLayout="column" fxLayoutGap="5px">
                                    <span class="value-read-only">utmMedium</span>
                                    <span>{{talent?.utmMedium}}</span>
                                </div>


                                <div fxLayout="column" fxLayoutGap="5px" style="min-width: 101px">
                                    <span class="value-read-only">utmSource</span>
                                    <span>{{talent?.utmSource}}</span>
                                </div>
                            </div>

                            <h3>Matching-Einstellungen</h3>

                            <div fxLayout="column" fxLayoutGap="15px">
                                <div class="width-100" fxLayout="row" fxLayoutGap="15px">
                                    <div fxLayout="column" fxLayoutGap="5px">
                                        <span class="value-read-only">Standort</span>
                                        <span>{{talent?.locationPreference?.talentLocation?.name}}</span>
                                    </div>

                                    <div fxLayout="column" fxLayoutGap="5px">
                                        <span class="value-read-only">Suchmodus</span>
                                        <span>{{talent?.locationPreference?.searchMode}}</span>
                                    </div>

                                    <div fxLayout="row wrap" style="min-width: 200px"
                                         *ngIf="talent?.locationPreference?.searchMode == 'SpecificCities'">
                                        <span class="value-read-only" style="min-width: 200px">In den Städten</span>
                                        <span *ngFor="let city of talent.locationPreference.specificCities">{{city.name}}
                                            ,&nbsp;</span>
                                    </div>

                                    <div fxLayout="column" fxLayoutGap="5px" style="min-width: 101px">
                                        <span class="value-read-only">Suchradius-Modus</span>
                                        <span>{{talent?.locationPreference?.locationMatchingThreshhold | locationThreshold}}</span>
                                    </div>

                                    <div class="width-100" fxLayout="column" fxLayoutGap="5px">
                                        <span class="value-read-only">Ausgeschlossene Städte</span>
                                        <div fxLayout="row wrap" fxLayoutGap="5px">
                                            <span *ngIf="talentBlacklistedCities?.length == 0">keine Städte ausgeschlossen</span>
                                            <div *ngFor="let city of talentBlacklistedCities"
                                                 class="tag"
                                                 fxLayout="row"
                                                 fxLayoutAlign="center center">
                                                <span>{{city.name}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="talent">
                                        <mat-checkbox [(ngModel)]="talent.advertisingForEventsEnabled">Talent für Event Werbemails aktiv</mat-checkbox>
                                    </div>
                                </div>

                                <mat-accordion class="width-100">
                                    <mat-expansion-panel class="width-100">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Präferenzen: Tätigkeitsfelder</mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <table mat-table [dataSource]="talentProfessionFieldPreferences" class="w-100">
                                            <ng-container matColumnDef="groupTitle">
                                                <th mat-header-cell *matHeaderCellDef>Berufsgruppe</th>
                                                <td mat-cell
                                                    *matCellDef="let element">{{element.fieldName}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="preferenceState">
                                                <th mat-header-cell *matHeaderCellDef>Präferenz</th>
                                                <td mat-cell
                                                    *matCellDef="let element">{{element.preferenceScore | professionGroupPreference}}</td>
                                            </ng-container>

                                            <tr mat-header-row
                                                *matHeaderRowDef="professionGroupPreferencesDisplayedColumns"></tr>
                                            <tr mat-row
                                                *matRowDef="let row; columns: professionGroupPreferencesDisplayedColumns;"></tr>
                                        </table>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>

                            <h3>Werdegang</h3>
                            <table mat-table [dataSource]="careerEvents" class="max-table" style="width: 100%;">

                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef>Typ</th>
                                    <td class="tabel-jobtitle-cell" mat-cell
                                        *matCellDef="let element"> {{element.type | careerEvent}}</td>
                                </ng-container>

                                <ng-container matColumnDef="title">
                                    <th mat-header-cell *matHeaderCellDef>Studiengang</th>
                                    <td class="tabel-jobtitle-cell" mat-cell
                                        *matCellDef="let element"> {{element.subjectName}} {{element.subjectName ? element.subjectName : 'Keine Angabe'}}</td>
                                </ng-container>

                                <ng-container matColumnDef="study">
                                    <th mat-header-cell *matHeaderCellDef>University/Organisation</th>
                                    <td class="tabel-jobtitle-cell" mat-cell
                                        *matCellDef="let element"> {{element.universityName}} {{element.organizationName}}</td>
                                </ng-container>

                                <ng-container matColumnDef="academicTitle">
                                    <th mat-header-cell *matHeaderCellDef>Abschluss</th>
                                    <td class="tabel-jobtitle-cell" mat-cell
                                        *matCellDef="let element"> {{element.academicTitle ? element.academicTitle : 'Keine Angabe'}}</td>
                                </ng-container>

                                <ng-container matColumnDef="endDate">
                                    <th mat-header-cell *matHeaderCellDef>Ende</th>
                                    <td class="tabel-jobtitle-cell" mat-cell
                                        *matCellDef="let element"> {{element.endDate ? (element.endDate | date: 'dd.MM.yyyy') : 'Keine Angabe'}}</td>
                                </ng-container>

                                <ng-container matColumnDef="mark">
                                    <th mat-header-cell *matHeaderCellDef>Note</th>
                                    <td class="tabel-jobtitle-cell" mat-cell
                                        *matCellDef="let element"> {{element.grade ? element.grade : 'n.a.'}}</td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th style="text-align: center" mat-header-cell *matHeaderCellDef> Aktionen
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div fxLayout="row" fxLayoutAlign="space-around start">
                                            <i class="mdi mdi-delete dark" matTooltip="Löschen"
                                               (click)="deleteCareerEvent(element)"></i>
                                            <i class="mdi mdi-information" matTooltip="Informationen"
                                               (click)="openCareerEventDetails(element)"></i>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedCareerEventColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedCareerEventColumns;"></tr>

                            </table>
                        </loading-spinner>
                    </div>
                </mat-tab>

                <mat-tab label="Lebenslauf">
                    <ng-template matTabContent>
                        <p *ngIf="!cvFile ||cvFile.size == 0">Kein Lebenslauf vorhanden</p>

                        <ngx-extended-pdf-viewer
                                *ngIf="cvFile && cvFile.size != 0 && tabGroup.selectedIndex == 1"
                                [src]="cvFile"
                                [height]="'100%'"
                                [useBrowserLocale]="true">
                        </ngx-extended-pdf-viewer>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Bewerbungen">
                    <ng-template matTabContent>
                        <div style="padding: 10px 10px 0 10px">
                            <talent-applications *ngIf="talentId" [talentId]="talentId"></talent-applications>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>Matching-Vorschläge</ng-template>
                    <ng-template matTabContent>
                            <div class="controlling-board-info">
                                Hier werden ausschließlich Vorschläge aus dem automatischen Matching angezeigt!
                            </div>
                            <div class="layout-padding" fxLayout="row" fxLayoutAlign="end center">
                                <loading-button color="primary" [loadingBool]="currentlyMatching"
                                                (click)="forceMatching()">
                                    <i class="mdi mdi-refresh mdi-24px"></i>
                                    Matching forcieren
                                </loading-button>
                            </div>

                            <mat-tab-group>
                                <mat-tab>
                                    <ng-template mat-tab-label> Cache ({{cacheDataSource?.length}})</ng-template>
                                    <loading-spinner style="width: 100%" [loadingBool]="cachedMatchesLoading">
                                        <table mat-table [dataSource]="cacheDataSource" style="width: 100%">

                                            <ng-container matColumnDef="matchingScore">
                                                <th mat-header-cell *matHeaderCellDef>Score</th>
                                                <td mat-cell *matCellDef="let element"> {{element.matchingScore}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="position">
                                                <th mat-header-cell *matHeaderCellDef>Stelle</th>
                                                <td mat-cell
                                                    *matCellDef="let element"> {{element.matchedPosition.title}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="company">
                                                <th mat-header-cell *matHeaderCellDef>Firma</th>
                                                <td mat-cell
                                                    *matCellDef="let element"> {{element.matchedPosition.hiringCompanyName}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="actions">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let element">
                                                    <button mat-icon-button
                                                            (click)="openPositionDetailsDialog(element.matchedPosition.id)">
                                                        <i class="mdi mdi-information mdi-24px"></i>
                                                    </button>
                                                    <button mat-icon-button
                                                            (click)="openMatch(element.matchedPosition.id)">
                                                        <i class="mdi mdi-account-search mdi-24px"></i>
                                                    </button>
                                                </td>
                                            </ng-container>


                                            <tr mat-header-row
                                                *matHeaderRowDef="['matchingScore','position','company','actions']"></tr>
                                            <tr mat-row
                                                *matRowDef="let element; columns: ['matchingScore','position','company','actions'];"></tr>
                                        </table>
                                    </loading-spinner>
                                </mat-tab>
                                <mat-tab disabled>
                                    <ng-template mat-tab-label>
                                        <button mat-icon-button (click)="loadPositionMatchesFromTalentCache()"
                                                color="primary" matTooltip="Aktualisieren">
                                            <mat-icon>refresh</mat-icon>
                                        </button>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Empfehlungen">
                    <ng-template matTabContent>
                        <div fxLayout="column">
                            <div fxLayout="column" fxLayoutAlign="start start" class="width-100" style="padding: 16px"
                                 fxLayoutGap="10px">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxFlex="80" style="font-size: 12px">Hier findest du zum Talent passende
                                        Prime-Stellen, die direkt über Talentagent vermittelt werden. Das Talent kann
                                        sich nicht ohne deine Empfehlung auf diese Stellen bewerben. Wähle passende
                                        Positionen aus und schlage sie dem Talent exklusiv vor. Verfolge den Status des
                                        Vorschlags jederzeit im Reiter "Stellenvorschläge". Wenn du auf Emails
                                        abschicken klickst, wird eine email mit allen Ausgewählten(!) Stellenvorschlägen
                                        abgeschickt.
                                    </div>
                                    <loading-button [disabledCondition]="!selection?.selected?.length"
                                                    [loadingBool]="sendingPrimePositionNotification"
                                                    buttonClass="mat-primary" [raised]="true"
                                                    (clickEvent)="openCreatePositionRecommendationsDialog()">
                                        Markierte Stellen empfehlen
                                    </loading-button>

                                    <loading-button [disabledCondition]="!selection?.selected?.length"
                                                    [loadingBool]="sendingPrimePositionNotification"
                                                    buttonClass="mat-primary" [raised]="true"
                                                    (clickEvent)="sendNotReachedEmail()">
                                        Nicht erreicht Mail abschicken
                                    </loading-button>
                                </div>

                                <div *ngIf="talent.prime" fxLayout="row" fxLayoutAlign="start center"
                                     fxLayoutGap="30px">
                                    <div class="prime-matching-mode"><b>Erhält Prime-Stellen
                                        vom:</b> {{talent.primeMatchingMode | primePositionMatchingMode}}</div>
                                    <mat-slide-toggle [(ngModel)]="onlySuitablePrimePositions"
                                                      (change)="getPrimePositions()">nur Passende Primestellen
                                    </mat-slide-toggle>
                                </div>

                                <div *ngIf="talent.matchingState == 'MatchingPaused'" class="warn">Achtung! Dieses
                                    Talent ist seit mindestens 10 Wochen inaktiv.<br>Dadurch kann es sein, dass nicht
                                    alle aktuellen Stellen verfügbar sind
                                </div>

                                <mat-form-field>
                                    <input matInput
                                           (keyup)="applyFilter(talentPositionRelationDataSource, $event.target.value)"
                                           placeholder="Suche">
                                </mat-form-field>
                            </div>

                            <loading-spinner [loadingBool]="loadingPrime">
                                <table mat-table [dataSource]="talentPositionRelationDataSource" matSort
                                       class="width-100">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                          [checked]="selection.hasValue() && isAllSelected()"
                                                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                          (change)="$event ? selection.toggle(row) : null"
                                                          [disabled]="row.recommendationDate != null"
                                                          [checked]="selection.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="prime">
                                        <th mat-header-cell *matHeaderCellDef> Prime</th>
                                        <td mat-cell *matCellDef="let element" style="padding-left: 5px">
                                            <img *ngIf="element.position.isPrime" height="20"
                                                 src="../../../assets/images/star.png"></td>
                                    </ng-container>

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="position">
                                        <th mat-header-cell *matHeaderCellDef> Stelle</th>
                                        <td mat-cell *matCellDef="let element"> {{element.position.title}} </td>
                                    </ng-container>

                                    <!-- Company Column -->
                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef> Unternehmen</th>
                                        <td mat-cell *matCellDef="let element">
                                            <div fxLayout="row" fxLayoutAlign="start center">
                                                <img *ngIf="element.position.positionOrigin == 'Uniwunder'"
                                                     src="assets/images/uniwunder-logo.jpeg"
                                                     width="25" height="25"
                                                     class="m-right5" matTooltip="Vermittlung über Uniwunder GmbH">

                                                <img *ngIf="element.position.positionOrigin == 'Campusjaeger'"
                                                     src="assets/images/campusjaeger-logo.jpg"
                                                     width="25" height="25"
                                                     class="m-right5" matTooltip="Vermittlung über Campusjäger GmbH">

                                                <img *ngIf="element.position.positionOrigin == 'Pluss'"
                                                     src="assets/images/pluss.png"
                                                     width="25" height="25"
                                                     class="m-right5" matTooltip="Vermittlung über Pluss GmbH">

                                                <img *ngIf="element.position.positionOrigin == 'Absolventa'"
                                                     src="assets/images/absolventa-gmb-h-logo-xl.png"
                                                     width="25" height="25"
                                                     class="m-right5" matTooltip="Vermittlung über Absolventa GmbH">
                                                {{element.position.customerCompany.name}}
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="matchReceived">
                                        <th mat-header-cell *matHeaderCellDef style="text-align: center">Im Feed</th>
                                        <td mat-cell *matCellDef="let element" style="text-align: center">
                                            <div *ngIf="element.pushedInFeedDate != null">{{element.pushedInFeedDate | date: 'dd.MM.yy HH:mm'}}</div>
                                            <i *ngIf="element.pushedInFeedDate == null" class="mdi mdi-close red"></i>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="state">
                                        <th mat-header-cell *matHeaderCellDef> Status</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.relationState | talentPositionRelationState}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="mailSent">
                                        <th mat-header-cell *matHeaderCellDef style="text-align: center">Empfehlungs
                                            Mail
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="text-align: center">
                                            <div *ngIf="element.recommendationDate != null">{{element.recommendationDate | date: 'dd.MM.yy HH:mm'}}</div>
                                            <i *ngIf="element.recommendationDate == null" class="mdi mdi-close red"></i>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="notReachedMailSent">
                                        <th mat-header-cell *matHeaderCellDef style="text-align: center">nicht erreicht
                                            Mail
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="text-align: center">
                                            <div *ngIf="element.notReachedEmailSentDate != null">{{element.notReachedEmailSentDate | date: 'dd.MM.yy HH:mm'}}</div>
                                            <i *ngIf="element.notReachedEmailSentDate == null"
                                               class="mdi mdi-close red"></i>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef style="text-align: center"></th>
                                        <td mat-cell *matCellDef="let element" style="text-align: center">
                                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                                <button mat-icon-button
                                                        (click)="openPositionDetailsDialog(element.position.id)"
                                                        matTooltip="Stellendetails">
                                                    <i style="font-size: 20px" class="mdi mdi-eye"></i></button>
                                                <button [disabled]="element.existsRecommendation || element.existsNotSuitableLog"
                                                        mat-icon-button
                                                        (click)="markPrimePositionAsUnsuitable(element.position)"
                                                        matTooltip="Stelle als unpassend markieren">
                                                    <i style="font-size: 20px"
                                                       class="mdi mdi-close-octagon-outline"></i></button>
                                            </div>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPrime"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsPrime;"></tr>
                                </table>
                            </loading-spinner>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Log-Historie">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" style="margin-left: 10px">
                        <mat-form-field appearance="outline" style="position: relative; top: 12px;">
                            <input matInput [matDatepicker]="pickerFrom" placeholder="Datum Beginn"
                                   [(ngModel)]="logDateFrom" name="dateFrom">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="position: relative; top: 12px;">
                            <input matInput [matDatepicker]="pickerTo" placeholder="Datum Ende"
                                   [(ngModel)]="logDateTo" name="dateTo">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                        <loading-button matTooltip="Gefilterte Logs laden"
                                        buttonClass="mat-primary"
                                        [loadingBool]="loadingLogs"
                                        (clickEvent)="loadLogEntries()">
                            <i class="mdi mdi-refresh main-action-icon filter-color"></i>
                        </loading-button>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="m-left10">
                            <mat-checkbox
                                    [checked]="applicationCheckbox.value && coachingCheckbox.value && onAndOffBoardingCheckbox.value && othersCheckbox.value"
                                    [indeterminate]="(applicationCheckbox.value || coachingCheckbox.value || onAndOffBoardingCheckbox.value || profileChangeCheckbox.value || othersCheckbox.value)
                                                    && !(applicationCheckbox.value && coachingCheckbox.value && onAndOffBoardingCheckbox.value && profileChangeCheckbox.value && othersCheckbox.value)"
                                    (change)="applicationCheckbox.setValue($event.checked);
                                            coachingCheckbox.setValue($event.checked);
                                            onAndOffBoardingCheckbox.setValue($event.checked);
                                            profileChangeCheckbox.setValue($event.checked);
                                            othersCheckbox.setValue($event.checked)"
                            >Alle</mat-checkbox>
                            <mat-checkbox [formControl]="applicationCheckbox">Bewerbungen</mat-checkbox>
                            <mat-checkbox [formControl]="coachingCheckbox">Coaching</mat-checkbox>
                            <mat-checkbox [formControl]="onAndOffBoardingCheckbox">On- und Offboarding</mat-checkbox>
                            <mat-checkbox [formControl]="profileChangeCheckbox">Profiländerungen</mat-checkbox>
                            <mat-checkbox [formControl]="othersCheckbox">Sonstige</mat-checkbox>
                    </div>
                    <loading-spinner [loadingBool]="loadingLogs">
                        <table mat-table [dataSource]="logEntries" class="m-left10" style="width: 95%">
                            <ng-container matColumnDef="occurred">
                                <th mat-header-cell *matHeaderCellDef>Datum</th>
                                <td mat-cell style="padding: 0 10px; white-space: nowrap;"
                                    *matCellDef="let row">{{row.occurred | date: 'dd.MM.yy | HH:mm'}}</td>
                            </ng-container>

                            <ng-container matColumnDef="logName">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: 20px">Log Name</th>
                                <td mat-cell style="padding: 0 10px"
                                    *matCellDef="let row">{{row.internalName}}</td>
                            </ng-container>

                            <ng-container matColumnDef="message">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: 20px">Beschreibung</th>
                                <td mat-cell style="padding: 0 10px"
                                    *matCellDef="let row">{{row.message}}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedLogEntryColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedLogEntryColumns;"></tr>
                        </table>
                    </loading-spinner>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

