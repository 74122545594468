import {EventRegistrationState} from "./event-registration-state"
import {CompanyTalentEventData} from "./company-talent-event-data"

export class CompanyEventRegistrationData {
    checkedByCompany: boolean = null;
    eventId: number = null;
    eventTitle: string = null;
    findsCompanyInteresting: boolean = null;
    id: number = null;
    markedAsInteresting: boolean = null;
    registrationDate: (Date | null) = null;
    registrationState: EventRegistrationState = null;
    talent = new CompanyTalentEventData();
}