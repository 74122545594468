import {AdminEmploymentPositionData} from "./admin-employment-position-data"
import {ApplicationState} from "./application-state"

export class AdminApplicationData {
    applicationId: number = null;
    applicationMarkedAsHired: boolean = null;
    applicationMessage: string | null = null;
    company: string = null;
    creationDate: (Date | null) = null;
    cvId: number | null = null;
    failedReasonTalent: string | null = null;
    failedReasonUniwunder: string | null = null;
    lastStateUpdate: (Date | null) = null;
    position = new AdminEmploymentPositionData();
    state: ApplicationState = null;
    talentEmailAddress: string = null;
    talentId: number = null;
    talentName: string = null;
    talentSubjectName: string = null;
}