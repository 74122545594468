<div class="width-100 layout-padding-24-32">
    <div fxLayout="row" fxLayoutAlign="end" class="w-100" fxLayoutGap="15px" style="padding: 20px 20px 0 20px">

        <loading-button [loadingBool]="saveing"
                        [raised]="true"
                        [color]="'primary'"
                        (clickEvent)="savePersona()"
                        style="margin-right: 20px">
            Speichern
        </loading-button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="30px" [formGroup]="applicantPersonaForm">
        <mat-form-field style="width: 100%">
            <mat-label>Was unterscheidet Sie von anderen Arbeitgebern?</mat-label>
            <textarea matInput formControlName="differencesToOthers" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Studienbereich/-fächer, die gewünscht sind?</mat-label>
            <textarea matInput formControlName="preferredStudySubjects" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="30px">
            <mat-form-field class="mat-form-field-long">
                <mat-label>Mindestnotendurchschnitt?</mat-label>
                <input matInput type="number" formControlName="minimumGrade">
            </mat-form-field>

            <mat-form-field class="mat-form-field-long">
                <mat-label>Mindestabschluss? (Bachelorabschluss / Masterabschluss)</mat-label>
                <input matInput formControlName="minimumGraduation">
            </mat-form-field>
        </div>

        <mat-form-field style="width: 100%">
            <mat-label>Sollen bestimmte Universitäten oder FHs ausgeschlossen werden?</mat-label>
            <textarea matInput formControlName="blacklistedUniversities" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Arbeitserfahrung in Form von Praktika und/oder Werkstudententätigkeiten gefordert?</mat-label>
            <textarea matInput formControlName="workingExperience" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Anforderungen?</mat-label>
            <textarea matInput formControlName="requirements" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Sprache & erforderliches Niveau</mat-label>
            <textarea matInput formControlName="requiredLanguageSkills" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Wie sieht ihr perfekter Kandidat aus?</mat-label>
            <textarea matInput formControlName="perfectApplicant" cdkTextareaAutosize cdkAutosizeMinRows="4" style="width: 100%"></textarea>
        </mat-form-field>

    </div>
</div>
