import {DegreeType} from "./degree-type"
import {HomeOffice} from "./home-office"
import {Origin} from "./origin"

export class TalentFilterData {
    advertisingForEventsEnabled: boolean | null = null;
    bookmarkedPositionId: number | null = null;
    cityIds: number[] | null = null;
    createDateEnd: (Date | null) = null;
    createDateStart: (Date | null) = null;
    cvFullTextSearchString: string | null = null;
    degreeTypes: DegreeType[] | null = null;
    deleted: boolean | null = null;
    emailAddressQuery: string | null = null;
    employmentPositionId: number | null = null;
    federalStateIds: number[] | null = null;
    firstNameQuery: string | null = null;
    homeOffice: HomeOffice | null = null;
    lastNameQuery: string | null = null;
    minGrade: number | null = null;
    needsCoachingCriteriaUpdate: boolean | null = null;
    onlyPrimeTalents: boolean | null = null;
    onlyTalentsConsideredForMatching: boolean = null;
    onlyTalentsWithCompleteData: boolean | null = null;
    onlyWithAttachedCv: boolean = null;
    onlyWithCompletedOnboarding: boolean = null;
    origin: Origin[] | null = null;
    searchQuery: string | null = null;
    studyAreaIds: number[] | null = null;
    studyEndDateFrom: (Date | null) = null;
    studyEndDateTo: (Date | null) = null;
    studyFieldIds: number[] | null = null;
    studySubjectIds: number[] | null = null;
    subjectGroupIds: number[] | null = null;
    supervisedByOperations: boolean | null = null;
    talentIds: number[] | null = null;
    talentQualityScores: number[] | null = null;
    utmCampaign: string | null = null;
    utmContent: string | null = null;
    wasCheckedByAdmin: boolean | null = null;
}