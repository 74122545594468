import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AdminCompanyInvoiceData, InvoiceFilterData, InvoiceData, AdminInvoiceData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminInvoiceController
 */

@Injectable()
@ResourceParams({})
export class AdminInvoiceResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/createOrUpdate/hiringPercentage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createHiringPercentageInvoice: IResourceMethodObservableStrict<null, {applicationId: number}, null, JsonWrappedValue<number>> | undefined
    createHiringPercentageInvoice(requestParams: {applicationId: number}): Promise<number> {
        if (!this._createHiringPercentageInvoice) throw new Error("resource has not been properly initialized")
        return this._createHiringPercentageInvoice(null, requestParams, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/createOrUpdate/booking',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateInvoice: IResourceMethodObservableStrict<InvoiceData, null, null, InvoiceData> | undefined
    createOrUpdateInvoice(requestBody: InvoiceData): Promise<InvoiceData> {
        if (!this._createOrUpdateInvoice) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateInvoice(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/companyInvoiceInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyInvoiceInformation: IResourceMethodObservableStrict<number, null, null, AdminInvoiceData> | undefined
    getCompanyInvoiceInformation(requestBody: number): Promise<AdminInvoiceData> {
        if (!this._getCompanyInvoiceInformation) throw new Error("resource has not been properly initialized")
        return this._getCompanyInvoiceInformation(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredInvoices: IResourceMethodObservableStrict<InvoiceFilterData, {pageNum?: number, pageSize?: number}, null, PagedData<InvoiceData>> | undefined
    getFilteredInvoices(requestBody: InvoiceFilterData, requestParams?: {pageNum?: number, pageSize?: number}): Promise<PagedData<InvoiceData>> {
        if (!this._getFilteredInvoices) throw new Error("resource has not been properly initialized")
        return this._getFilteredInvoices(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/company/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredInvoicesPerCompany: IResourceMethodObservableStrict<InvoiceFilterData, null, null, AdminCompanyInvoiceData[]> | undefined
    getFilteredInvoicesPerCompany(requestBody: InvoiceFilterData): Promise<AdminCompanyInvoiceData[]> {
        if (!this._getFilteredInvoicesPerCompany) throw new Error("resource has not been properly initialized")
        return this._getFilteredInvoicesPerCompany(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/invoices/{id}/pdf',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getInvoiceAsPdf: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getInvoiceAsPdf(id: number): Promise<Blob> {
        if (!this._getInvoiceAsPdf) throw new Error("resource has not been properly initialized")
        return this._getInvoiceAsPdf(null, null, {id: id}).toPromise()
    }

}