import {Component, OnInit} from '@angular/core';
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AdminApplicationResource} from "../../generated/resources";

@Component({
  selector: 'app-demand-additional-application-docs-dialog',
  templateUrl: './demand-additional-application-docs-dialog.component.html',
  styleUrls: ['./demand-additional-application-docs-dialog.component.scss']
})
export class DemandAdditionalApplicationDocsDialogComponent implements OnInit {

  public applicationId: number
  public talentName: string

  public controlCount: number = 0
  public attachmentTypes: FormGroup

  public requestAdditionalDocumentsLoading: boolean = false

  constructor(
      private fb: FormBuilder,
      private dialogService: SideSheetService,
      private sideSheetRef: SideSheetRef,
      private adminApplicationResource: AdminApplicationResource
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.attachmentTypes = this.fb.group({})
    this.addAttachment()
  }


  addAttachment() {
    this.controlCount += 1
    this.attachmentTypes.addControl(`control${this.controlCount}`, new FormControl(null, Validators.required))
  }

  removeAttachment(key: string) {
    this.attachmentTypes.removeControl(key)
  }

  requestAdditionalApplicationDocuments() {
    this.requestAdditionalDocumentsLoading = true
    if(this.attachmentTypes.invalid || !this.attachmentTypes.controls["control1"] || !this.applicationId) return

    let documentTypes: string[] = []

    Object.keys(this.attachmentTypes.controls).forEach((controlName, id) => {
      if (this.attachmentTypes.controls[controlName].value) {
        documentTypes.push(this.attachmentTypes.controls[controlName].value)
      }
    })

    this.adminApplicationResource.requestAdditionalDocumentsForTalentApplication(documentTypes,this.applicationId).then(() => {
      this.requestAdditionalDocumentsLoading = false
      this.sideSheetRef.sheetClosed.next()
    })
  }
}
