<div style="background-color: white; margin: 20px; padding: 10px" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div fxLayout="row">
            <div class="width-50">
                <h1>Demoaccount 1</h1>
                <button mat-flat-button mat-icon-button
                        matTooltip="Als Recruiter einloggen"
                        (click)="redirectToCompanyFrontendWithTokenForRecruiter(demoAcc1RecruiterId)">
                    <i class="mdi mdi-account-switch dark-important"></i> Als Recruiter einloggen
                </button>

                <loading-button
                        style="display:none"
                        [raised]="true"
                        color="primary"
                        (clickEvent)="setUpDemoAccount('1')"
                        [loadingBool]="setUpDemoSystemLoading">
                    setUp Demo-Account
                </loading-button>

                <div class="m-top70">
                    <loading-button
                            [raised]="true"
                            color="warn"
                            (clickEvent)="resetDemoAccount('1')"
                            [loadingBool]="resetDemoSystemLoading">
                        Demo-Account zurücksetzen
                    </loading-button>
                </div>
            </div>

            <div class="width-50">
                <h1>Demoaccount 2</h1>
                <button mat-flat-button mat-icon-button
                        matTooltip="Als Recruiter einloggen"
                        (click)="redirectToCompanyFrontendWithTokenForRecruiter(demoAcc2RecruiterId)">
                    <i class="mdi mdi-account-switch dark-important"></i> Als Recruiter einloggen
                </button>

                <loading-button
                        style="display:none"
                        [raised]="true"
                        color="primary"
                        (clickEvent)="setUpDemoAccount('2')"
                        [loadingBool]="setUpDemoSystemLoading">
                    setUp Demo-Account
                </loading-button>

                <div class="m-top70">
                    <loading-button
                            [raised]="true"
                            color="warn"
                            (clickEvent)="resetDemoAccount('2')"
                            [loadingBool]="resetDemoSystemLoading">
                        Demo-Account zurücksetzen
                    </loading-button>
                </div>
            </div>
        </div>

<!--        <div fxLayout="row" fxLayoutGap="10px" style="display: none !important;">-->
<!--            <button type="button" mat-flat-button (click)="fileInput.click()">Choose File</button>-->
<!--            <input hidden #fileInput type="file" id="file">-->

<!--            <button mat-flat-button (click)="uploadDemoFile(fileInput.files)">upload file</button>-->
<!--        </div>-->
    </div>
</div>
